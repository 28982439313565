import React from "react";
import { convertToLowerCase } from "utils/validation";
import RadioCheck from "Component/Form/CommonRadioBox";
import { cusCallType } from "Pages/NewCall/BasicInfo/commonBasicInfo";

const CustomerCallType = (props: any = {}) => {
  const {
    callType = null,
    readOnly = false,
    inModelView = false,
    columnView = false, // for column view style
    callTypeHandle = () => {}
  } = props;
  return (
    <div
      className={`form-group relative mb-2 w-full  mt-1 px-4  ${inModelView && !columnView ? "sm:w-1/2 xl:w-1/4" : " "}
            ${inModelView && columnView ? " lg:w-full xl:w-1/2" : " "}`}
    >
      <label className="block text-xs input-label mb-1 font-bold" htmlFor="">
        Call Type<sup style={{ color: "red" }}>*</sup>
      </label>
      <div className="flex">
        {cusCallType.map((ele: any, i: number) => {
          return (
            <React.Fragment key={convertToLowerCase(i + "cusCallType-efd")}>
              <RadioCheck
                key={ele.id}
                label={true}
                readOnly={readOnly}
                labelText={ele.label}
                id={`CallType-${ele.id}`}
                name={`imeiDetailsHistory`}
                parentClass={"p-1.5 pl-0 w-52"}
                checked={ele.value === callType}
                onChange={() => callTypeHandle(ele.value)}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};
export default CustomerCallType;
