import { Get } from "ApiProcess/httpRestServices";
import { crmApiBaseUrl, encodeGetParams, loginApi } from "ApiProcess/httpRestServices/apiManage";
import store from "store";
import { getRegisterUserDetailsAction, registerStatusActiveInactiveChangeAction } from "store/action/loginAction";
import { nullToObject } from "utils/validation";

/**
 * get register user details
 */
export const getRegisterUserLit = (data = {}) => {
  store.dispatch(getRegisterUserDetailsAction(data));
  store.dispatch({ type: "GET_REGISTER_USER_INITIAL_LOADER", getRegisterUserLoader: true }); //loader_on
};

/**
 * register user avtive and inactive
 */
export const editRegisterStatus = (data = {}) => {
  store.dispatch(registerStatusActiveInactiveChangeAction(data)); //create action
  store.dispatch({ type: "REGISTER_USER_STATUS_CHANGE_LOADER", registerUsrStatusChangeloader: true }); //loader_on
};

/**
 * reset reload status
 * after register creat or update reload list
 */
export const afterSuccessMakeReloadStateOriginal = (value: any = null) => {
  store.dispatch({ type: "RELOAD_USER_GET_LIST_DATA", reloadUserGetRegisterData: value }); //loader_on
};

/**
 * get register user details
 */
export const getRegisterUserDetailApiService = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    branch = [],
    role = [],
    allowedRadius = "",
    pricingDetails = "",
    excludeLocation = "",
    ignoreCurrentUserCheck = "",
    checkAllowedEnquires = ""
  } = nullToObject(obj);

  const newObj = {
    page,
    size,
    search,
    branch,
    role: role,
    pricingDetails,
    excludeLocation,
    allowedRadius: "" + allowedRadius,
    ignoreCurrentUserCheck: ignoreCurrentUserCheck,
    checkAllowedEnquires: checkAllowedEnquires
  };

  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${loginApi.getRegisterUser}${consvertString}`, "crmToken");
};
