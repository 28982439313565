import { moment } from "utils/momentConst";
import React, { useState } from "react";
import { _isEmpty } from "utils/lodashConst";
import OfferList from "./ComboFreeDisplay";
import "./styles.scss";
import { IconInfo } from "assets/images";

const OfferItems = (props: any) => {
  const { offer_details, index, total_count, product } = props;
  const [showTc, setShowTc] = useState<boolean>(false);
  const [showBan, setShowBan] = useState<boolean>(true);
  const [showOffer, setShowOffer] = useState<boolean>(false);
  const [showStores, setShowStores] = useState<boolean>(false);

  return (
    <div className="px-4 py-2">
      <div className="flex justify-between font-semibold text-white p-2 text bg-primary-gradient rounded-sm">
        <span>
          {index === 0 ? "Best Offer" : "Max Offer" + index}
          <p className="dateText">{`(Ends on ${moment(offer_details.endDate).format("DD-MMM-YYYY hh:mm A")})`}</p>
        </span>
        {offer_details.offerType !== "Offer Text" && offer_details.offerType !== "Standard EMI" && (
          <span>{`Max benefit Rs.${offer_details?.maxBenefites || 0} `}</span>
        )}
      </div>

      <div className="offer-content border border-gray-300 px-2">
        <div className="flex justify-between p-2">
          <div className="font-bold mr-1 capitalize">
            <span>{offer_details?.offerName}</span>
            <button
              type="button"
              className="text-blue-500 underline ml-1"
              onClick={() => {
                setShowBan(false);
                setShowTc(!showTc);
              }}
            >{`Terms & Conditions`}</button>
            <button
              type="button"
              className="text-blue-500 underline ml-1"
              onClick={() => {
                setShowTc(false);
                setShowBan(!showBan);
              }}
            >{`Show Banners`}</button>
          </div>
          <div className="text-green-500 font-bold text-sm">
            {offer_details.offerType !== "Offer Text" && offer_details.offerType !== "Standard EMI" && (
              <span>{`₹ ${offer_details?.maxBenefites || 0} `}</span>
            )}
          </div>
        </div>
        {showTc && (
          <div
            className="show-tc overflow-auto max-w-full p-5"
            dangerouslySetInnerHTML={{ __html: offer_details?.termsConditions }}
          />
        )}
        {showBan && (
          <div className="show-tc px-2" dangerouslySetInnerHTML={{ __html: offer_details?.bannerDiscription }} />
        )}

        {(!_isEmpty(offer_details.comboOffers) || !_isEmpty(offer_details.freebies)) && (
          <div className="newdiv">
            <div className="w-full mt-2">
              <div className="flex justify-between font-semibold text-white p-2 text bg-primary-gradient rounded-sm">
                <span className="offer-type">{offer_details.offerType}</span>
                <button type="button" onClick={() => setShowOffer(!showOffer)}>
                  {" "}
                  {!showOffer ? "Show Product(s) + " : "Hide Product(s) - "}
                </button>
              </div>

              {showOffer && (
                <div className="offer-expanded">
                  {!_isEmpty(offer_details.comboOffers) && (
                    <OfferList offers={offer_details.comboOffers[product?.erp_item_code]} />
                  )}

                  {!_isEmpty(offer_details.freebies) && (
                    <OfferList offers={offer_details.freebies[product?.erp_item_code]} />
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <div className="bg-blue-50 rounded-sm p-4 py-2 mb-4 text-sm text-blue-600 m-2" role="alert">
          <IconInfo />
          <div className="inline-flex flex-wrap">
            <span className="font-medium mr-1">Applicable on:</span>
            {offer_details?.channel?.find((ch: any) => ch.name === "Online" || ch.name === "All") && (
              <p>{`Online${offer_details?.state?.length > 0 ? ", " : ""}`} </p>
            )}

            {offer_details?.state?.length > 0 && (
              <p>
                {offer_details?.state?.map((st: any) => (
                  <span key={st?.id}>
                    {st?.name}
                    {offer_details?.state?.at(-1).name === st.name ? "/" : ", "}
                  </span>
                ))}
                {offer_details?.storeId?.length ? offer_details?.storeId[0]?.name : ""}
                {offer_details?.storeId?.length > 1 && ", "}{" "}
                {offer_details?.storeId?.length > 1 && (
                  <button className="cursor-pointer underline" type="button" onClick={() => setShowStores(!showStores)}>
                    more
                  </button>
                )}
              </p>
            )}
          </div>
        </div>
        {showStores && (
          <div className="offer-expanded">
            {offer_details?.storeId?.map((store: any) => <p key={store?.index}>{store?.name}</p>)}
          </div>
        )}
      </div>
      {total_count !== index + 1 && <span className=" text-center w-full block mt-3">OR</span>}
    </div>
  );
};

export default OfferItems;
