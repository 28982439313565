import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { nullToObject } from "utils/validation";
import { XIcon } from "@heroicons/react/outline";
import ModalFixed3 from "Component/Modal/ModalFixed3";
import OutsideClickHandler from "react-outside-click-handler";

const AnimateSlideForm = (props: any = {}) => {
  const {
    setShow = false,
    heading = "",
    children = "",
    cancelText = "",
    actionText = "",
    customChildClass = "",
    handleAction = () => { },
    handleClosePopup = () => { }
  } = nullToObject(props);

  const [popupAnimateParent, setPopupAnimateParent] = useState(false);
  const [stateAnimateSlideForm, setStateAnimateSlideForm] = useState({
    popupAnimate: false,
    popupAnimateParent: true
  });

  const { popupAnimate = false } = stateAnimateSlideForm;

  const handleAnimateSlideForm = (name = "", value: any = "") => {
    setStateAnimateSlideForm({
      ...stateAnimateSlideForm,
      [name]: value
    });
  };

  useEffect(() => {
    if (setShow) {
      setTimeout(() => setPopupAnimateParent(true), 400);
    }
    return () => {
      setPopupAnimateParent(false);
    };
  }, [setShow]);

  return (
    <Transition
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      show={popupAnimateParent}
      enter="transition-opacity duration-300"
      leave="transition-opacity duration-300"
      afterEnter={() => handleAnimateSlideForm("popupAnimate", true)}
      beforeLeave={() => handleAnimateSlideForm("popupAnimate", false)}
    >
      <ModalFixed3>
        <div className="bg-black bg-opacity-20 w-full h-full">
          <OutsideClickHandler onOutsideClick={handleClosePopup}>
            <Transition show={popupAnimate}>
              <Transition.Child
                enterTo="translate-x-0"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
                enterFrom="translate-x-full"
                enter="transition ease-in-out duration-300 transform"
                leave="transition ease-in-out duration-300 transform"
                className={` ${customChildClass ? customChildClass : " max-w-2xl "
                  } register fixed z-10 top-0 bottom-0 right-0  w-full shadow-lg bg-white`}
              >
                <div className="form-wrapper h-full flex flex-col">
                  <div className="flex justify-between heading px-4 h-12 text-center border border-red-500 bg-primary-gradient flex-shrink-0">
                    <h2 className="text-base font-bold text-white flex items-center">{heading}</h2>
                    <button type="button" className="text-white" onClick={handleClosePopup}>
                      <XIcon className="w-6 h-6" />
                    </button>
                  </div>
                  {children}
                  {cancelText || actionText ? (
                    <div className="flex justify-end space-x-2 p-2 border-t">
                      {cancelText && (
                        <button
                          type="button"
                          onClick={handleClosePopup}
                          className=" capitalize mb-2 md:mb-0 border px-5 py-2 text-sm shadow-sm font-bold tracking-wider rounded hover:shadow-lg
                                                bg-white border-white-500 hover:bg-gray-100 text-gray-600 mx-2"
                        >
                          {cancelText}
                        </button>
                      )}

                      {actionText && (
                        <button
                          type="button"
                          onClick={handleAction}
                          className=" capitalize mb-2 md:mb-0 border px-5 py-2 text-sm shadow-sm font-bold tracking-wider rounded hover:shadow-lg
                                                        bg-gradient-to-r from-green-500 to-green-600  border-green-500 hover:bg-green-600 text-white mx-2"
                        >
                          {actionText}
                        </button>
                      )}
                    </div>
                  ) : null}
                </div>
              </Transition.Child>
            </Transition>
          </OutsideClickHandler>
        </div>
      </ModalFixed3>
    </Transition>
  );
};

export default AnimateSlideForm;
