import { nullToString, paisaToAmount } from "utils/validation";
import { fetchSalesOfflineEmiDetails } from "utils/services/emiService";

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24;

  return `${hours > 9 ? padTo2Digits(hours) : hours}h
     ${minutes > 9 ? padTo2Digits(minutes) : minutes}m ${seconds > 9 ? padTo2Digits(seconds) : seconds}s`;
}

export const decodeMobileNo = ({ phone = "", isShowSensInfo = true }) => {
  const returnVal = phone + "";
  if (nullToString(phone)?.length >= 10 && !isShowSensInfo) return `91XXXXXXXX${phone.slice(nullToString(phone)?.length - 2, nullToString(phone)?.length)}`;
  return returnVal;
};

export const getQRPoorvikaPrice = (price = "") => {
  const amount = String(price).replaceAll(",", "");
  if (Number(amount) >= 2000) return "Price - ???";
  return price;
};

export const isPoorvikaPriceEnable = (price = "") => {
  const amount = String(price).replaceAll(",", "");
  if (Number(amount) >= 2000)  return false;
  return true;
};

export const getProtectionExtendedCheckBox = (data, obj = { protectPack: false, extWarranty: false }) => {
  data.forEach((item) => {
    if (item.type === "INSURANCE" && (item.description.includes("One") || item.description.includes("1"))) {
      obj.protectPack = true;
    }
    if (item.type === "WARRANTY" && (item.description.includes("One") || item.description.includes("1"))) {
      obj.extWarranty = true;
    }
  });
  return obj;
};

export const fetchEmiDetails = async ({ channel_shapes = [], sp = 0, mrp = 0 }) => {
  const initialState = { cashback: "", offer: "", emiAmount: "", minAmount: "", code: 0 };
  const pine_product_id =
    channel_shapes?.find((element) => element.code === "pinepg-product-id" && element.value) || {};
  const parameter = {
    payment_gateway: "pinelabs",
    product_code: pine_product_id?.value || "",
    product_amount: `${Number(sp > 0 ? sp : mrp > 0 ? mrp : 0) * 100}`
  };
  const respose = await fetchSalesOfflineEmiDetails(parameter);
  const { data: { code = 0, data = {} } = {} } = respose;
  if (code === 200) {
    const { lowestEMIOfferType = "", lowestEMIOfferedBy = "", lowestEMIAmount = "", emiDetails = [] } = data;
    const emiDetailsIndex = emiDetails?.findIndex((dataVal) => dataVal.type === lowestEMIOfferType);
    const providersIndex = emiDetails[emiDetailsIndex]?.providers?.findIndex(
      (bankType) => bankType.name === lowestEMIOfferedBy
    );
    const bankDetails = emiDetails[emiDetailsIndex]?.providers[providersIndex]?.tenureDetails.find(
      (value) => value.monthlyInstallment === lowestEMIAmount
    );
    const { instantDiscount = "", totalCashbackOffered = "" } = bankDetails || {};
    const cashback = Math.round(instantDiscount);
    const offer = Math.round(totalCashbackOffered);
    const minAmount = Math.round(lowestEMIAmount);
    const [, emiName] = (minAmount + "").split("-");
    const emiAmount = paisaToAmount(minAmount + "00") + "-" + lowestEMIOfferedBy;
    const cashbackVal = cashback === 0 ? "" : `${paisaToAmount(cashback + "00")}${emiName ? "-" + emiName : ""} `;
    const offerVal = offer === 0 ? "" : `${paisaToAmount(offer + "00")}${emiName ? "-" + emiName : ""} `;
    return { cashback: cashbackVal, offer: offerVal, emiAmount, minAmount, code };
  } else {
    return { ...initialState };
  }
};
