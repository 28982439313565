import React from "react";
import OutdatedModel from "../../../OutdatedModel";

const DealingsStatusUpdate = (props: any = {}) => {
  const { customerData = {}, columnView = false, closePopup = () => {} } = props;
  return (
    <OutdatedModel
      fromPage="edit"
      inModelView={true}
      closePopup={closePopup}
      columnView={columnView}
      customerData={customerData}
    />
  );
};

export default DealingsStatusUpdate;
