import React from "react";
import { thClass } from "utils/ClassContent";

const EnquiryFeedbackTableHeader = (props: any) => {
  return (
    <thead>
      <tr className="border">
        <th className={thClass}>Created On</th>
        <th className={thClass}>Ticket ID</th>
        <th className={thClass}>Language</th>
        <th className={thClass}>Customer Name</th>
        <th className={thClass}>Customer Mobile</th>
        <th className={thClass}>Customer Email</th>
        <th className={thClass}>Brand Name</th>
        <th className={thClass}>Product Name</th>
        <th className={thClass}>Product Code</th>
        <th className={thClass}>Enquiry For</th>
        <th className={thClass}>Employee ID</th>
        <th className={thClass}>Employee Name</th>
        <th className={thClass}>Employee Branch</th>
        <th className={thClass}>Employee Role</th>
        <th className={thClass}>Find It Easy</th>
        <th className={thClass}>Inform EMI</th>
        <th className={thClass}>Price Offer</th>
        <th className={thClass}>Rating</th>
        <th className={thClass}>Note</th>
        <th className={thClass}>Status</th>
        <th className={thClass}>Current Status</th>
        <th className={thClass}>Notify</th>
      </tr>
    </thead>
  );
};

export default EnquiryFeedbackTableHeader;
