import React from "react";
import OrderSummary from "../OrderSummary";
import { IsFullView } from "utils/ClassContent";
import { ProductDetailsProps } from "../../types";
import TrackingByCard from "../TrackingByCard";
import { convertToLowerCase, nullToArray } from "utils/validation";

const ProductDetails = (props: ProductDetailsProps) => {
  const { productCardDatas: { productDetails = [], total = "", itemCount = "", shipping_method = "" } = {} } =
    props || {};
  const { orderStyle1 = true } = props;
  return (
    <div className="flex-1 shadow-sm px-1 lg:w-96 border">
      <div className="py-2 border-b space-y-2">
        {nullToArray(productDetails).map((ele: any, i: number) => {
          return (
            <React.Fragment key={convertToLowerCase(i + "productDetails-dfdi")}>
              <div className={` rounded my-1 py-2 flex flex-wrap`}>
                <div className={`flex ${IsFullView() ? " " : " flex-col"} w-full lg:w-1/2 flex-grow `}>
                  <div className={`flex flex-col  px-4 ${IsFullView() ? " lg:w-1/2 " : " flex-1"}`}>
                    <h4 className="uppercase tracking-wide text-sm font-semibold text-gray-700 my-2">
                      Product Details
                    </h4>
                    <div className={`flex justify-between border-b border-gray-200 pb-4`}>
                      <div className="flex flex-grow flex-col col-span-3 pt-2 pr-3">
                        <span className="text-gray-600 text-sm font-semi-bold">{ele.product_name}</span>
                        <span className="text-gray-400 text-xs inline-block pt-1">Qty :{ele.quantity}</span>
                      </div>
                      <div className="col-span-2 pt-2">
                        <div className="flex items-center space-x-2 text-sm justify-between">
                          {/* <span className="text-gray-400">{ele.quantity}x{ele.price}</span> */}
                          <span className="c-primary font-semibold inline-block w-24 text-right">₹{ele.price * 1}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <OrderSummary data={ele} />
                </div>
                <div className=" w-full lg:w-1/2">
                  <TrackingByCard trackingData={ele} orderStyle1={orderStyle1} />
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="px-4 py-1 border-b -mx-1 text-sm">
        <div className="flex justify-between py-1 text-gray-600">
          <span>Payment method</span>
          <span className="font-semibold text-gray-600">{shipping_method ? shipping_method : "--"}</span>
        </div>
        <div className="flex justify-between py-1 text-gray-600">
          <span>Total Item</span>
          <span className="font-semibold text-gray-600">{itemCount}</span>
        </div>
        {/* <div className="flex justify-between py-1 text-gray-600">
                        <span>Discount Charges</span>
                        <span className="font-semibold text-gray-600">{deivery_charges}</span>
                    </div> */}
      </div>
      <div className=" -mx-1 bg-gray-100 font-semibold text-base px-4 flex justify-between py-4 c-primary">
        <span>Cart Total</span>
        <span>₹{total}</span>
      </div>
    </div>
  );
};

export default ProductDetails;
