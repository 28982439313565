import React, { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";

const PageAnimate = (props: any = {}) => {
  const {
    children,
    className = "",
    leaveTo = "transition-opacity duration-300 opacity-0",
    enterFrom = "transition-opacity duration-300 opacity-0",
    enterTo = "transition-opacity duration-300 opacity-100",
    leaveFrom = "transition-opacity duration-300 opacity-10",
    enter = "transition-opacity duration-300 opacity-100",
    leave = "transition-opacity duration-300 opacity-1"
  } = props;
  const [popupAnimate, setPopupAnimate] = useState(false);

  useEffect(() => {
    setPopupAnimate(true);
    return () => {
      setPopupAnimate(false);
    };
  }, []);

  return (
    <Transition
      show={popupAnimate}
      leaveTo={leaveTo}
      enterFrom={enterFrom}
      enterTo={enterTo}
      leaveFrom={leaveFrom}
      enter={enter}
      leave={leave}
      afterEnter={() => setPopupAnimate(true)}
      beforeLeave={() => setPopupAnimate(false)}
      className={className ? className : "w-full h-full"}
    >
      {children}
    </Transition>
  );
};

export default PageAnimate;
