import store from "store";
import { Decrypt } from "utils/secureFile";
import { emiDetailsGetActionType } from "./types";
import { emiDetailsGetAction, emiProductDatagetAction, getTypesenseAccessTokenAction } from "store/action/emiAction";

//accessToken get
export const getAccessTokenForEmoProdect = () => {
  store.dispatch(getTypesenseAccessTokenAction());
  store.dispatch({ type: "EMI_TOKEN_LOADR", tokenLoader: true }); //loader turn on
};

// emiProduct
export const emiProductDataGet = (modalName: string | number) => {
  store.dispatch(emiProductDatagetAction(modalName));
  store.dispatch({ type: "EMI_PRODUCT_LOADR", productLoader: true }); //loader turn on
};

// emiProduct
export const getEmiDataBankDetails = (data: emiDetailsGetActionType) => {
  store.dispatch(emiDetailsGetAction(data));
  store.dispatch({ type: "EMI_SELETECTED_MODAL", selectedModal: data }); //loader turn on
  store.dispatch({ type: "EMI_PRODUCT_OFFER_LOADER", emiBackOfferLoader: true }); //loader turn on
};

export const removeShowedBankDetails = () => {
  store.dispatch({ type: "EMI_PRODUCT_BANK_OFFER_AVAILABLE", emiBackOfferIsAvailable: null }); //empty
  store.dispatch({ type: "EMI_SELETECTED_MODAL", selectedModal: {} }); //loader turn on
};

//get typesense Token here
export const emiTokenGet = () => {
  const token = Decrypt("typesenseToken");
  if (token) {
    return token;
  }
  return "";
  // U2FsdGVkX18ay9MJ8iCrdlr5+IaZNlYKAt7dN6xHY9sYPCS+AK0PD/X6HSNsQSmS expire token sample
};
