import React, { useState } from "react";
import { OrderSummaryCardProps } from "./types";
import { IsFullView } from "utils/ClassContent";
import AWBTracking from "../AWBTracking";
import ModalFixed3 from "Component/Modal/ModalFixed3";

const OrderSummary = (props: OrderSummaryCardProps) => {
  const {
    data: {
      price = 0,
      quantity = 0,
      ew_price = 0,
      adld_price = 0,
      awb_no = "",
      courier_name = "",
      payment_status = "",
      delivery_method = "",
      tax = 0,
      order_charges = 0,
      current_status = "",
      oneassist_charges = 0
    } = {}
  } = props || {};

  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <>
      <div className={`rounded-md w-full flex-1 px-4 ${IsFullView() ? "lg:w-1/2 " : ""}`}>
        <div className="">
          <h4 className="uppercase tracking-wide text-sm font-semibold text-gray-700 my-2">Product Summary</h4>
          <div className="">
            <section>
              <fieldset className="mb-3 rounded text-gray-600">
                <label className="flex border border-gray-100 py-2 items-start">
                  <span className="text-left px-2 text-sm w-36">Delivery Type</span>
                  <span className="flex-1 px-2 text-sm">{delivery_method ? delivery_method : "--"}</span>
                </label>

                <label className="flex border border-gray-100 py-2 items-start">
                  <span className="text-left px-2 text-sm w-36">Payment Status</span>
                  <span className="flex-1 px-2 text-sm">{payment_status ? payment_status : "--"}</span>
                </label>

                <label className="flex border border-gray-100 py-2 items-start">
                  <span className="text-left px-2 text-sm w-36">Current Status</span>
                  <span className="flex-1 px-2 text-sm">{current_status ? current_status : "--"}</span>
                </label>

                {awb_no && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">AWB No</span>
                    <span
                      onClick={() => {
                        setShowPopup(true);
                      }}
                      data-jest-id={"jestSetShowPopupOrderSummary"}
                      className="flex-1 px-2 text-sm text-blue-500 cursor-pointer"
                    >
                      {awb_no}
                    </span>
                  </label>
                )}

                {courier_name && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">Courier Name</span>
                    <span className="flex-1 px-2 text-sm">{courier_name}</span>
                  </label>
                )}

                {oneassist_charges !== 0 && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">OneAssist Charges</span>
                    <span className="flex-1 px-2 text-sm">₹{oneassist_charges}</span>
                  </label>
                )}

                {adld_price !== 0 && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">ADLD Price</span>
                    <span className="flex-1 px-2 text-sm">₹{adld_price}</span>
                  </label>
                )}
                {ew_price !== 0 && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">EW Price</span>
                    <span className="flex-1 px-2 text-sm">₹{ew_price}</span>
                  </label>
                )}
                {tax * 1 !== 0 && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">Tax</span>
                    <span className="flex-1 px-2 text-sm">₹{tax}</span>
                  </label>
                )}
                {order_charges * 1 !== 0 && (
                  <label className="flex border border-gray-100 py-2 items-start">
                    <span className="text-left px-2 text-sm w-36">Order Charges</span>
                    <span className="flex-1 px-2 text-sm">₹{order_charges}</span>
                  </label>
                )}
                {/* <label className="flex border border-gray-100 py-2 items-start">
                                    <span className="text-left px-2 text-sm w-36">Product Price</span>
                                    <span className="flex-1 px-2 text-sm">₹{price * 1}</span>
                                </label>

                                <label className="flex border border-gray-100 py-2 items-start">
                                    <span className="text-left px-2 text-sm w-36">Product Quantity</span>
                                    <span className="flex-1 px-2 text-sm">{quantity}</span>
                                </label> */}

                <label className="flex border border-gray-100 py-2 items-start">
                  <span className="text-left px-2 text-sm w-36">Total</span>
                  <span className="flex-1 px-2 text-sm">₹{quantity * price}</span>
                </label>
              </fieldset>
            </section>
          </div>
        </div>
      </div>
      {/* invoice call details */}
      {showPopup && (
        <ModalFixed3>
          <AWBTracking orderData={{ awb_no, courier_name }} closePopup={handleClosePopup} />
        </ModalFixed3>
      )}
    </>
  );
};

export default OrderSummary;
