import React, { useEffect, useState } from "react";
import { _get, _isEmpty } from "utils/lodashConst";
import MailReport from "./index";
import { useSelector } from "react-redux";
import { tabBasedAccess } from "helperRole";
import { CogIcon } from "@heroicons/react/solid";
import FilterSettingsDrop from "Component/Form/FilterSettingsDrop";
import { mailReportFilter, sendEmailAttachments } from "../commonServiceCalls";
import { nullToObject } from "utils/validation";

const MailReportAndSetting = (props: any) => {
  const { userSearch = {}, permisstion = 0 } = props || {};
  const mailServiceCreateAccess = tabBasedAccess(permisstion); // dynamic pass page number

  const [showMailPopup, setShowMailPopup] = useState(false);
  const [mailReportFilterList, setMailReportFilterList] = useState<any>([]);
  const globalStore = useSelector((states: any) => states) || {};
  const { serviceCallDetailsReducer = {} } = nullToObject(globalStore);
  const { customerServiceEmailSuccess = false } = serviceCallDetailsReducer;

  const handleActionCancel = () => {
    setShowMailPopup(!showMailPopup);
  };
  //mail
  const handleShowMailPopup = () => {
    setShowMailPopup(true);
  };

  /**
   * send mail
   * @param data
   */
  const handleMailSubmit = (mailData: any = {}) => {
    let filterObj = {};
    mailReportFilterList.forEach((eachFilter: any) => {
      filterObj = {
        ...filterObj,
        [`excel_${_get(eachFilter, "optionData", "")}`]: _get(eachFilter, "isSelect", false)
      };
    });
    sendEmailAttachments({ ...mailData, ...userSearch, excelColumnFilter: filterObj });
  };

  /**
   * Handle's table column filter
   * @param selectedItem selected colunmn
   */
  const handleColumnSelectValue = (selectedItem: any) => {
    const { isSelect = false, optionData = "" } = selectedItem;
    const tempmailReportFilterList = [...mailReportFilterList];
    const index = tempmailReportFilterList.findIndex((item: any) => _get(item, "optionData", "") === optionData);
    tempmailReportFilterList[index] = { isSelect: !isSelect, optionData: optionData };
    setMailReportFilterList(tempmailReportFilterList);
  };

  /**
   * Reset table column filter
   */
  const handleResetColumn = () => {
    const resetedList = mailReportFilterList.map((ele: any) => {
      const updatedName = _get(ele, "optionData", "");
      return { isSelect: true, optionData: updatedName };
    });
    setMailReportFilterList(resetedList);
  };

  // assign values to mail filter based on list
  useEffect(() => {
    if (_isEmpty(mailReportFilterList)) {
      const updatedList = mailReportFilter.map((filterval: any) => {
        return { isSelect: true, optionData: filterval };
      });
      setMailReportFilterList(updatedList);
    }
  }, []);

  useEffect(() => {
    if (customerServiceEmailSuccess) {
      setShowMailPopup(false);
    }
  }, [customerServiceEmailSuccess]);

  return (
    <React.Fragment>
      {mailServiceCreateAccess && false && (
        <MailReport
          showMailPopup={showMailPopup}
          handleMailSubmit={handleMailSubmit}
          handleActionCancel={handleActionCancel}
          handleShowMailPopup={handleShowMailPopup}
        />
      )}

      {/* click based table column view */}
      <FilterSettingsDrop
        name="ResetColumn"
        parentClass={"ml-2"}
        enableCheckbox={true}
        arrayData={mailReportFilterList}
        handleResetFilter={handleResetColumn}
        handleParentDropVal={handleColumnSelectValue}
      >
        <CogIcon className="h-5 w-5 fill-current" />
      </FilterSettingsDrop>
    </React.Fragment>
  );
};

export default MailReportAndSetting;
