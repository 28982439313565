import { _get } from "utils/lodashConst";

type emiReducerTypes = {
  emiProductData?: any;
  tokenLoader?: boolean;
  selectedModal?: Object;
  productLoader?: boolean;
  emiBackOfferLoader?: boolean;
  availableEmiProductData?: any;
  emiBackOfferIsAvailable?: any;
  emiBackOfferDetails?: Array<any>;
};

export const emiInitState = {
  selectedModal: {},
  emiProductData: [],
  tokenLoader: false,
  productLoader: false,
  emiBackOfferDetails: [],
  emiBackOfferLoader: false,
  availableEmiProductData: [],
  emiBackOfferIsAvailable: null
};

export const emiReducer = (state = emiInitState, action: emiReducerTypes = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "EMI_INITIAL_STAGE": {
      return {
        ...state,
        ...emiInitState
      };
    }

    case "EMI_TOKEN_LOADR": {
      //loader
      return {
        ...state,
        tokenLoader: action.tokenLoader
      };
    }

    case "EMI_PRODUCT_LOADR": {
      //loader
      return {
        ...state,
        productLoader: action.productLoader
      };
    }

    case "EMI_PRODUCT_OFFER_LOADER": {
      //loader
      return {
        ...state,
        emiBackOfferLoader: action.emiBackOfferLoader
      };
    }

    case "EMI_SELETECTED_MODAL": {
      //loader
      return {
        ...state,
        selectedModal: action.selectedModal
      };
    }

    case "EMI_PRODUCT_DATA": {
      //emi sku id and ,more details non-filter all data
      return {
        ...state,
        emiProductData: action.emiProductData
      };
    }

    case "AVAILABLE_EMI_PRODUCT_DATA": {
      //emi sku id and ,more details filter
      return {
        ...state,
        availableEmiProductData: action.availableEmiProductData
      };
    }
    case "EMI_PRODUCT_BANK_OFFER_DETAILS": {
      //bank offer store
      return {
        ...state,
        emiBackOfferDetails: action.emiBackOfferDetails
      };
    }

    case "EMI_PRODUCT_BANK_OFFER_AVAILABLE": {
      //bank offer store
      return {
        ...state,
        emiBackOfferIsAvailable: action.emiBackOfferIsAvailable
      };
    }

    default:
      return {
        ...state
      };
  }
};
