import * as comListTypes from "../actionTypes/comListTypes";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { sendComplainEmailService } from "utils/services/comListService";

/**
 * send email
 */
function* sendComplainEmail(obj: object = {}): any {
  try {
    const response = yield call(sendComplainEmailService, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 200) {
      successToast("Email sent Successfully");
      yield all([
        put({ type: "COMPLAIN_SEND_EMAIL_SUCCESS", complainEmailSuccess: true }),
        put({ type: "COMPLAIN_EMAIL_SEND_LOADER", complainEmailLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "COMPLAIN_SEND_EMAIL_SUCCESS", complainEmailSuccess: false }),
        put({ type: "COMPLAIN_EMAIL_SEND_LOADER", complainEmailLoader: false })
      ]);
    }
  } catch (error) {
    failToast("Error in sending email");
    yield all([
      put({ type: "COMPLAIN_SEND_EMAIL_SUCCESS", complainEmailSuccess: false }),
      put({ type: "COMPLAIN_EMAIL_SEND_LOADER", complainEmailLoader: false })
    ]);
  }
}
export default function* comListSaga() {
  yield takeLatest(comListTypes.COMPLAIN_EMAIL_SEND, sendComplainEmail);
}
