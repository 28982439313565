import React, { useState, useEffect } from "react";
import { Decrypt } from "utils/secureFile";
import { nullToObject } from "utils/validation";
import { useLocation } from "react-router-dom";
import { tabBasedAccess, pageNumber } from "helperRole";
import OutsideClickHandler from "react-outside-click-handler";
import { DropArrow, MenuIconSm, subMenuIcon, subMenuClass } from "utils/ClassContent";
import {
  SalesMenu,
  Salesorder,
  AllOrders,
  HomeNewCall,
  AdvocateSideBar,
  FeedbackContestMenu,
  Download,
  AllCallMyCall,
  SalesReturnMenu,
  NewCallSideBar,
  NotifyMeSideBar,
  ServiceSideMenu,
  CampaignsSideBar,
  ComplaintSideMenu,
  NonDealingSideBar,
  AllEnquiryBasedEmpId,
  JustDialMenu,
  SideBarSizeHandle,
  SuperAdminSideBar,
  ServiceCenterDetails,
  OnlineQueriesSideMenu,
  QualityScorecard,
  ProductQRGeneratorMenu
} from "./index";
import PreRegisterMenu from "./PreRegisterMenu";
import EnquiryFeedBackMenu from "./EnquiryFeedBackMenu";
import MisDashboardMenu from "./MisDashboardMenu";

const Sidebar = (props: any = {}) => {
  const { sideMenuAnimate = false, handleSideMenuClose = () => { } } = props;

  const location = useLocation();
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { roleId = 0 } = {} } = nullToObject(userLoginDetails);

  const { pathname = "", state: pathState } = nullToObject(location);

  //super admin menu oly show
  const [SideMenu, setSideMenu] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState("");
  const [activeSubMenu2, setActiveSubMenu2] = useState("");

  const handleMenu = (value = "") => {
    handleSideMenuClose();
  };

  const handleSubMenu = (value = "") => {
    setActiveSubMenu(value);
  };

  const handleSubMenu2 = (value = "") => {
    setActiveSubMenu2(value);
  };

  const handleHideSideMenu = () => {
    setSideMenu(!SideMenu);
    const sidebar = document && document.getElementById("sidebar");
    sidebar && sidebar.classList.remove("sidebarHover");
  };

  useEffect(() => {
    const sidebar = document && document.getElementById("sidebar");
    sidebar && sidebar.classList.remove("sidebarHover");
    const handlehover = () => {
      sidebar && sidebar.classList.add("sidebarHover");
    };

    const handlehoverEnd = () => {
      sidebar && sidebar.classList.remove("sidebarHover");
    };

    sidebar && sidebar.addEventListener("mouseover", handlehover, false);
    sidebar && sidebar.addEventListener("mouseleave", handlehoverEnd, false);
  }, []);

  const empEnquiryDetails = () => {
    return (
      tabBasedAccess(pageNumber.Employee_Enquiry_Details) && ( //6
        <AllEnquiryBasedEmpId pathname={pathname} pathState={pathState} DropArrow={DropArrow} handleMenu={handleMenu} />
      )
    );
  };

  useEffect(() => {
    handleSideMenuClose();
    setSideMenu(false);
  }, [pathname]);

  return (
    <OutsideClickHandler onOutsideClick={() => handleSideMenuClose()}>
      <div
        id="sidebar"
        className={`${sideMenuAnimate ? " mobileSideMenu " : ""} sidebar relative h-full bg-white ${SideMenu ? " sidemenu-active h-full" : "  sidemenu-less "
          }`}
      >
        <div
          className={` flex flex-col h-full justify-between w-full shadow-lg ${SideMenu ? "" : "absolute sidemenu-less-inner"
            }`}
        >
          <ul
            id="sideMenuList"
            className="flex flex-col py-4 overflow-x-hidden overflow-y-auto sidebar-menu relative flex-auto "
          >
            {/* Home and myCall tab */}
            {tabBasedAccess(pageNumber.Home) && ( //1
              <HomeNewCall pathname={pathname} pathState={pathState} handleMenu={handleMenu} DropArrow={DropArrow} />
            )}

            {/* for all roles */}
            {/* emp enq details */}
            {roleId !== 1 && empEnquiryDetails()}

            {tabBasedAccess(pageNumber.New_Call) && ( //2
              <NewCallSideBar pathname={pathname} pathState={pathState} handleMenu={handleMenu} DropArrow={DropArrow} />
            )}

            {/* myCall and allCall */}
            {tabBasedAccess(pageNumber.Call_History) && ( //3
              <AllCallMyCall
                roleId={roleId}
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
              />
            )}
            {/* salesReturn */}
            {tabBasedAccess(pageNumber.Sales_Return) && ( //7
              <SalesReturnMenu
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
              />
            )}

            {/* sales */}
            {tabBasedAccess(pageNumber.Sales) && ( //8
              <SalesMenu
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                pathname={pathname}
                subMenuIcon={subMenuIcon}
                subMenuClass={subMenuClass}
                activeSubMenu={activeSubMenu}
                pageObj={tabBasedAccess(pageNumber.Sales)}
              />
            )}

            {/* service side menu */}
            {tabBasedAccess(pageNumber.Service) && ( //11
              <ServiceSideMenu
                roleId={roleId}
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                activeSubMenu={activeSubMenu}
                handleSubMenu={handleSubMenu}
              />
            )}

            {/* complaint side menu */}
            {tabBasedAccess(pageNumber.Complaints) && ( //26
              <ComplaintSideMenu
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                activeSubMenu={activeSubMenu}
                handleSubMenu={handleSubMenu}
              >
                {/* advocate */}
                {tabBasedAccess(pageNumber.Complaints_Advocates) && ( //29
                  <AdvocateSideBar
                    pathname={pathname}
                    pathState={pathState}
                    DropArrow={DropArrow}
                    handleMenu={handleMenu}
                    MenuIconSm={MenuIconSm}
                  />
                )}
              </ComplaintSideMenu>
            )}

            {roleId === 1 && empEnquiryDetails()}

            {/* nonDealing */}
            {tabBasedAccess(pageNumber.Non_ealing) && ( //30
              <NonDealingSideBar
                pathname={pathname}
                pathState={pathState}
                MenuIconSm={MenuIconSm}
                handleMenu={handleMenu}
              />
            )}

            {/* campaign */}
            {tabBasedAccess(pageNumber.Campaigns) && ( //31
              <CampaignsSideBar
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                MenuIconSm={MenuIconSm}
              />
            )}

            {/* online */}
            {tabBasedAccess(pageNumber.Online_Queries) && ( //32
              <OnlineQueriesSideMenu
                pathname={pathname}
                pathState={pathState}
                MenuIconSm={MenuIconSm}
                handleMenu={handleMenu}
              />
            )}
            {tabBasedAccess(pageNumber.All_Orders) && ( //33
              <AllOrders
                roleId={roleId}
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
              />
            )}

            {/* justDial */}
            {tabBasedAccess(pageNumber.Just_Dial) && ( //35
              <JustDialMenu pathname={pathname} pathState={pathState} MenuIconSm={MenuIconSm} handleMenu={handleMenu} />
            )}

            {/* service center list details */}
            {tabBasedAccess(pageNumber.SERVICE_CENTERS) && ( //36
              <ServiceCenterDetails
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                subMenuIcon={subMenuIcon}
                subMenuClass={subMenuClass}
                activeSubMenu={activeSubMenu}
                handleSubMenu={handleSubMenu}
              />
            )}

            {tabBasedAccess(pageNumber.DOWNLOAD) && ( //92
              <Download pathname={pathname} pathState={pathState} DropArrow={DropArrow} handleMenu={handleMenu} />
            )}

            {tabBasedAccess(pageNumber.PRODUCT_QR_GENERATOR) && ( //92
              <ProductQRGeneratorMenu
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                pathname={pathname}
                subMenuIcon={subMenuIcon}
                subMenuClass={subMenuClass}
                activeSubMenu={activeSubMenu}
                pageObj={tabBasedAccess(pageNumber.PRODUCT_QR_GENERATOR)}
              />
            )}
            {tabBasedAccess(pageNumber.PRE_REGISTER_BOOKING) && ( // 136
              <PreRegisterMenu pathname={pathname} />
            )}

            {tabBasedAccess(pageNumber.ENQUIRY_FEEDBACK) && ( // 138
              <EnquiryFeedBackMenu pathname={pathname} />
            )}

            {tabBasedAccess(pageNumber.NOTIFY_ME) && ( // 89
              <NotifyMeSideBar pathname={pathname} />
            )}

            {tabBasedAccess(pageNumber.Feedback_Contest) && ( //38
              <FeedbackContestMenu
                pathname={pathname}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
              />
            )}

            {tabBasedAccess(pageNumber.SALES_ORDER) && ( //140
              <Salesorder pathname={pathname} pathState={pathState} DropArrow={DropArrow} handleMenu={handleMenu} />
            )}

            {/* CustomerInfoMenu */}
            {tabBasedAccess(pageNumber.Quality_Scorecard) && ( //40
              <QualityScorecard
                roleId={roleId}
                pathState={pathState}
                DropArrow={DropArrow}
                handleMenu={handleMenu}
                pathname={pathname}
                activeSubMenu={activeSubMenu}
                handleSubMenu={handleSubMenu}
                activeSubMenu2={activeSubMenu2}
                handleSubMenu2={handleSubMenu2}
              />
            )}
            {/* super admin access handle */}
            {tabBasedAccess(pageNumber.Super_Access) && ( //64
              <SuperAdminSideBar
                pathname={pathname}
                pathState={pathState}
                MenuIconSm={MenuIconSm}
                handleMenu={handleMenu}
              />
            )}
            {tabBasedAccess(pageNumber.MIS_DASHBOARD) && (
              <MisDashboardMenu
                pathname={pathname}
                pathState={pathState}
                MenuIconSm={MenuIconSm}
                handleMenu={handleMenu}
              />
            )}
            {/* {tabBasedAccess(pageNumber.Chat) && ( //67
                <ChatServicesSideMenu
                  pathname={pathname}
                  pathState={pathState}
                  MenuIconSm={MenuIconSm}
                  handleMenu={handleMenu}
                />
              )} */}
          </ul>
          {/* sideBar size handle */}
          <SideBarSizeHandle SideMenu={SideMenu} DropArrow={DropArrow} handleHideSideMenu={handleHideSideMenu} />
        </div>
      </div>
      <div
        style={{ zIndex: 3 }}
        onClick={handleSideMenuClose}
        className={`bg-black ${sideMenuAnimate ? " " : "hidden"} bg-opacity-10 w-full h-full absolute right-0`}
      />
    </OutsideClickHandler>
  );
};

export default Sidebar;
