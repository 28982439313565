import React from "react";
import { _get } from "utils/lodashConst";
import CommonDropHeading from "Component/CommonDropHeading";
import CommonTextarea from "Component/Form/CommonTextarea";
import { bg_GeneralInfo, inputStyle } from "utils/ClassContent";
import CommonCheck from "Component/Form/CommonCheck";

/**
 * Problem Info Drop Heading
 * @param props props
 * @returns Shows problem info on enquiry popup
 */
const GeneralInfoDropHeading = (props: any = {}) => {
  const { editView = false, customerData = {}, popParentTabs = "", createInputFields = () => {} } = props || {};

  return (
    <CommonDropHeading
      customClass={bg_GeneralInfo}
      id="GeneralInfo"
      heading="General Info"
      tabActiveDefault={popParentTabs === "GeneralInfo" ? true : false}
    >
      <div className="w-full flex flex-wrap">
        {createInputFields("Enquiry For", "enquiryFor", customerData)}
        {createInputFields("Sub Category", "subCategory", customerData)}
        {createInputFields("Options", "solution", customerData["solution"])}
        {createInputFields("Status", "status", customerData)}
        {createInputFields("Current Status", "currentStatus", customerData)}
        <CommonTextarea
          setShow={true}
          inputError={false}
          inputFieldClass=""
          readOnly={!editView}
          name={"sharedDetails"}
          parentClass={inputStyle}
          labelTextClass={"font-bold"}
          labelText={"Shared Details"}
          placeholder={"Shared Details"}
          value={_get(_get(customerData, "solution", {}), "sharedDetails", "")}
        />
        <CommonTextarea
          setShow={true}
          inputError={false}
          inputFieldClass=""
          readOnly={!editView}
          parentClass={inputStyle}
          value={customerData["remarks"]}
          labelTextClass={"font-bold"}
          placeholder={"remarks"}
          name={"remarks"}
          labelText={"Remarks"}
        />
        <div className="flex flex-col form-group relative  mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4">
          <label className="block text-xs input-label mb-1 font-bold">Shared via </label>
          <div className="flex">
            <CommonCheck
              id="sms"
              labelText="sms"
              checked={_get(_get(customerData, "informVia", {}), "sms", null)}
              parentClass={"mb-4 sm:mb-4 w-full lg:w-full mt-2 sm:pr-4"}
            />
            <CommonCheck
              id="mail"
              labelText="mail"
              checked={_get(_get(customerData, "informVia", {}), "mail", null)}
              parentClass={"mb-4 sm:mb-4 w-full lg:w-full mt-2 sm:pr-4"}
            />
            <CommonCheck
              id="whatsapp"
              labelText="Whatsapp"
              checked={_get(_get(customerData, "informVia", {}), "whatsapp", null)}
              parentClass={"mb-4 sm:mb-4 w-full lg:w-full mt-2 sm:pr-4"}
            />
          </div>
        </div>
      </div>
    </CommonDropHeading>
  );
};

export default GeneralInfoDropHeading;
