import React from "react";
import { nullToObject } from "utils/validation";
import CommonTable from "Component/CommonTable";
import GeneralInfoStatusUpdate from "../GeneralInfoStatusUpdate";
import ServiceCallsStatusUpdate from "../ServiceCallsStatusUpdate";
import { GeneralInfo, ServiceSupport } from "utils/VariableConstant";
import { bg_EnquiryStatusUpdate } from "utils/ClassContent";

/**
 * Basic Info Drop Heading
 * @param props props
 * @returns Shows basic customer info on enquiry popup
 */
const ServiceStatusForm = (props: any = {}) => {
  const {
    cusId = "",
    enquiryFor = "",
    subCategory = "",
    customerData = {},
    columnView = false,
    selectedMailData = {},
    handleOnStatusChange = () => {}
  } = nullToObject(props);

  const generalInfoSubCat = GeneralInfo;
  const serviceSupportSubCat = ServiceSupport;

  return (
    (subCategory === serviceSupportSubCat || subCategory === generalInfoSubCat) && (
      <CommonTable
        setShow={true}
        id="tab7"
        parentClass={"w-full"}
        columnView={columnView}
        heading={"Service Status Update"}
        customClass={bg_EnquiryStatusUpdate}
      >
        <div id="tab7" className="w-full border-dashed flex transition flex-wrap mb-4 h-auto mt-2">
          {subCategory === serviceSupportSubCat && (
            <ServiceCallsStatusUpdate
              _id={cusId}
              enquiryFor={enquiryFor}
              columnView={columnView}
              subCategory={subCategory}
              customerData={customerData}
              selectedMailData={selectedMailData}
              handleChange={handleOnStatusChange}
            />
          )}
          {subCategory === generalInfoSubCat && (
            <GeneralInfoStatusUpdate
              _id={cusId}
              enquiryFor={enquiryFor}
              columnView={columnView}
              subCategory={subCategory}
              customerData={customerData}
              selectedMailData={selectedMailData}
              handleChange={handleOnStatusChange}
            />
          )}
        </div>
      </CommonTable>
    )
  );
};

export default ServiceStatusForm;
