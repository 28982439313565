import React, { useEffect } from "react";
import Login from "./Login";
import { Decrypt } from "utils/secureFile";
import { nullToObject } from "utils/validation";
import { useNavigate } from "react-router-dom";
import { ImgLoginBanner } from "assets/images";
import PoorvikaLoginHeader from "./PoorvikaLoginHeader";
import PoorvikaCopyFileContent from "./PoorvikaCopyFileContent";

const LoginPanel = () => {
  const loginData = Decrypt("userLoginData");
  const navigate = useNavigate();
  const { data: { roleId = 0 } = {} } = nullToObject(loginData);

  const routeToRedirect = () => {
    const loginDetails = sessionStorage.getItem("userLoginData");
    if (loginDetails) {
      if (roleId === 1) {
        navigate("/home");
      } else {
        navigate("/emp-enquiry-details");
      }
    }
  };

  useEffect(() => {
    routeToRedirect();
  }, [roleId]);

  return (
    <div className="flex h-full max-h-full">
      <div className="hidden w-1/2 md:block">
        <picture className="w-full h-full object-cover object-center">
          <source srcSet={ImgLoginBanner} media="(min-width: 768px)" />
          <source
            media="(min-width: 0px)"
            srcSet="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
          />
          <img
            className="w-full h-full object-cover object-center"
            width="100%"
            height="100%"
            src={ImgLoginBanner}
            alt="feature_banner"
          />
        </picture>
      </div>
      <div className="w-full md:w-1/2 flex flex-col h-full justify-center sm:p-4 ">
        <div className="flex flex-col items-center sm:justify-center overflow-y-auto py-2 sm:p-0 h-full ">
          <PoorvikaLoginHeader fromPage={"LoginPanel"} />
          <div className="flex w-full items-center justify-center form-panel">
            <Login />
          </div>
        </div>
        <PoorvikaCopyFileContent />
      </div>
    </div>
  );
};

export default LoginPanel;
