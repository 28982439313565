import { TableClass, tdClass, thClass, thSticky } from "utils/ClassContent";
import { convertToLowerCase } from "utils/validation";

const BlueDartTrackingTable = (props: any) => {
  const { scanDetails = [] } = props || {};
  return (
    <div
      className="block w-full overflow-x-auto relative scroll_1px"
      style={{ minHeight: "250px", maxHeight: "calc(100vh - 100px)" }}
    >
      <table className={TableClass}>
        <thead>
          <tr>
            <th className={thClass + thSticky}>Scan</th>
            <th className={thClass + thSticky}>Scan Code</th>
            <th className={thClass + thSticky}>Scan Type</th>
            <th className={thClass + thSticky}>Scan Group Type</th>
            <th className={thClass + thSticky}>Scan Date</th>
            <th className={thClass + thSticky}>Scan Time</th>
            <th className={thClass + thSticky}>Scanned Location</th>
            <th className={thClass + thSticky}>Scanned Location Code</th>
          </tr>
        </thead>
        {scanDetails.map((ele: any, i: number) => {
          const {
            Scan = [],
            ScanCode = [],
            ScanType = [],
            ScanGroupType = [],
            ScanDate = [],
            ScanTime = [],
            ScannedLocation = [],
            ScannedLocationCode = []
          } = ele;
          return (
            <tbody key={convertToLowerCase(i + "scanDetails-dfdi")}>
              <tr tabIndex={1} className="hover:bg-gray-600 hover:bg-opacity-10 cursor-pointer">
                <td style={{ minWidth: "250px" }} className={tdClass}>
                  {Scan[0]}
                </td>
                <td className={tdClass}>{ScanCode[0]}</td>
                <td className={tdClass}>{ScanType[0]}</td>
                <td className={tdClass}>{ScanGroupType[0]}</td>
                <td className={tdClass}>{ScanDate[0]}</td>
                <td className={tdClass}>{ScanTime[0]}</td>
                <td style={{ minWidth: "200px" }} className={tdClass}>
                  {ScannedLocation[0]}
                </td>
                <td className={tdClass}>{ScannedLocationCode[0]}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default BlueDartTrackingTable;
