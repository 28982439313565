import React, { Fragment, useState } from "react";
import { FilterIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";

const MobileFilterMenu = (props: any) => {
  const {
    id = "",
    children = "",
    MobileMenuName = "Filter",
    MobileMenuIcon = <FilterIcon className="w-full h-full" />
  } = props;

  const [enableFilter, setEnableFilter] = useState(false);

  const handleShaowFilter = () => {
    setEnableFilter(!enableFilter);
  };

  return (
    <Fragment>
      <div className="md:hidden flex items-center text-xs p-2 justify-end">
        <span className="mr-2">{MobileMenuName}</span>
        <button
          onClick={() => handleShaowFilter()}
          className="border md:hidden w-6 h-6 text-gray-600 p-1 text-sm flex justify-between items-center hover:bg-black
                     hover:bg-opacity-5 transition duration-200  hover:text-yellow-600 rounded"
          type="button"
        >
          {MobileMenuIcon}
        </button>
      </div>
      <OutsideClickHandler onOutsideClick={() => setEnableFilter(false)}>
        <div
          id={id}
          className={`${enableFilter ? " showFilterInMobile " : " showFilterInWeb "} hidden flex-col md:flex md:flex-row flex-wrap justify-end
             md:space-x-2 px-2 py-1`}
        >
          {children}
        </div>
      </OutsideClickHandler>
    </Fragment>
  );
};

export default MobileFilterMenu;
