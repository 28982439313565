import { useEffect, useState } from "react";
import { _get, _isEmpty } from "utils/lodashConst";

import { XIcon } from "@heroicons/react/outline";
import { DotsHorizontalIcon } from "@heroicons/react/solid";
import { ImgAvatarMale4 } from "assets/images";
import parse from "html-react-parser";
import ImageUploadReturn from "Component/ImageUploadReturn";
import { convertToLowerCase, EmailValidate } from "utils/validation";

const EnquiryMailReply = (props: any) => {
  const {
    selectedMailData = {},
    passValue = () => {},
    showReplyMail = false,
    passAttachmants = () => {},
    passMailInfo = () => {}
  } = props || {};

  const { mailData = {}, mailData: { from: mailFrom = [], html: mailHtml = "" } = {} } = selectedMailData || {};

  const [selectCC, setSelectCC] = useState(false);
  const [selectBCC, setSelectBCC] = useState(false);
  const [sendAttachments, setSendAttachments] = useState([]);
  const [openReplyMail, setOpenReplyMail] = useState(showReplyMail);
  const [mailContent, setMailContent] = useState({
    from: [],
    to: [],
    cc: [],
    bcc: []
  });

  const handleOpenReplyMail = () => {
    setOpenReplyMail(!openReplyMail);
  };

  /**
   * get the selected input file
   * @param event selected file
   */
  const onChangeFile = (event: any = {}) => {
    const target = _get(event, "target", {});
    if (!_isEmpty(_get(target, "files", []))) {
      const file = _get(target, "files[0]", {});
      const { name = "" } = file;
      const newObj = {
        file: file,
        fileName: name,
        url: URL.createObjectURL(file),
        type: file.name.split(".").pop()?.toLowerCase()
      };

      const newArray: any = [...sendAttachments, newObj];
      passAttachmants(newArray);
      setSendAttachments(newArray);
    }
  };

  /**
   * Remove the selected file
   * @param index index of selected file
   */
  const onCloseImage = (index = -1) => {
    const newArray: any = [...sendAttachments];
    newArray.splice(index, 1);
    passAttachmants(newArray);
    setSendAttachments(newArray);
  };

  /**
   * On key press
   * @param event
   */
  const onKeyPressFrom = (event: any = {}) => {
    if (event.key === "Enter" || event.key === "Tab") {
      if (event.key === "Enter") {
        event.preventDefault();
      }
      const { textContent = "", id = "" } = _get(event, "target", {});
      if (textContent !== "") {
        if (Object.keys(mailContent || {}).includes(id)) {
          setMailContent({
            ...mailContent,
            [id]: [textContent]
          });
          const div = document && (document.getElementById(id) as HTMLElement);
          div.textContent = "";
        }
      }
    }
  };

  /**
   * On key press
   * @param event
   */
  const onKeyPress = (event: any = {}) => {
    if (event.key === "Enter" || event.key === "Tab") {
      event.preventDefault();
      const { textContent = "", id = "" } = _get(event, "target", {});
      if (textContent !== "") {
        if (Object.keys(mailContent || {}).includes(id)) {
          let newArray = mailContent[id];
          if (id === "from") {
            newArray = [textContent];
          } else {
            newArray.push(textContent);
          }
          setMailContent({
            ...mailContent,
            [id]: newArray
          });
          const div = document && (document.getElementById(id) as HTMLElement);
          div.textContent = "";
        }
      }
    }
  };

  // handle cc
  const handleCCSelect = () => {
    setSelectCC(!selectCC);
  };

  const handleBccSelect = () => {
    setSelectBCC(!selectBCC);
  };

  const handleRemoveRecipient = (listId = "", removeingItem = "") => {
    const newArray = mailContent[listId];
    const index = newArray.indexOf(removeingItem);
    newArray.splice(index, 1);
    setMailContent({
      ...mailContent,
      [listId]: newArray
    });
  };

  useEffect(() => {
    passMailInfo(mailContent);
  }, [mailContent]);

  return (
    <div className="pt-3 px-5 overflow-hidden bg-white w-full">
      {/* </CommonInput> */}

      <section className="border-t my-5 py-5 pl-5">
        <div className="flex space-x-4 items-center mb-5">
          <div className="h-8 w-8 rounded-full overflow-hidden">
            <img src={ImgAvatarMale4} alt="avatar" className="h-full w-full object-cover" />
          </div>
        </div>
        <div className="flex items-center form-group relative w-full mt-4 mb-5">
          <label htmlFor="from" className="block text-xs input-label mr-2 font-bold whitespace-nowrap w-14 text-right">
            From
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <label htmlFor="from" className="rounded w-full contentEditable px-3 flex flex-wrap">
            {_get(mailContent, "from", []) &&
              _get(mailContent, "from", []).map((from, i) => {
                return (
                  <button
                    key={convertToLowerCase(i + "mailContent-tendk")}
                    type="button"
                    className={`whitespace-nowrap h-6 bg-${
                      EmailValidate(from) ? "gray" : "red"
                    }-200 text-black pl-2 pr-1.5 py-0.5 
                                        rounded-sm my-1.5 mx-1 leading-none inline-flex items-center`}
                  >
                    {from}
                    <span className="bg-white rounded text-gray-400 ml-2 w-4 h-4">
                      <XIcon
                        onClick={() => {
                          handleRemoveRecipient("from", from);
                        }}
                      />
                    </span>
                  </button>
                );
              })}
            <div
              style={{ minWidth: "100px" }}
              id="from"
              tabIndex={0}
              contentEditable={true}
              onKeyPress={onKeyPressFrom}
              onKeyDown={onKeyPressFrom}
              className="text-sm outline-none pl-1 pr-1.5 py-1.5"
            />
          </label>
        </div>

        <div className="flex items-center form-group relative w-full mt-4">
          <label htmlFor="to" className="block text-xs input-label mr-2 font-bold whitespace-nowrap w-14 text-right">
            To
            <sup style={{ color: "red" }}>*</sup>
          </label>
          <label htmlFor="to" className="rounded w-full contentEditable px-3 flex flex-wrap">
            {_get(mailContent, "to", []) &&
              _get(mailContent, "to", []).map((to, i) => {
                return (
                  <button
                    key={convertToLowerCase(i + "mailContent-to-tendk")}
                    type="button"
                    className={`whitespace-nowrap h-6 bg-${
                      EmailValidate(to) ? "gray" : "red"
                    }-200 text-black pl-2 pr-1.5 py-0.5 
                                        rounded-sm my-1.5 mx-1 leading-none inline-flex items-center`}
                  >
                    {to}
                    <span className="bg-white rounded text-gray-400 ml-2 w-4 h-4">
                      <XIcon
                        onClick={() => {
                          handleRemoveRecipient("to", to);
                        }}
                      />
                    </span>
                  </button>
                );
              })}
            <div
              style={{ minWidth: "100px" }}
              id="to"
              tabIndex={0}
              contentEditable={true}
              onKeyPress={onKeyPress}
              onKeyDown={onKeyPress}
              className="text-sm outline-none pl-1 pr-1.5 py-1.5"
            />
          </label>
          <div className="absolute -top-6 right-1">
            <button
              type="button"
              onClick={handleCCSelect}
              className="font-bold px-2 text-gray-700 text-sm hover:text-yellow-600 transition-all"
            >
              Cc
            </button>
            <button
              type="button"
              onClick={handleBccSelect}
              className="font-bold px-2 text-gray-700 text-sm hover:text-yellow-600 transition-all"
            >
              Bcc
            </button>
          </div>
        </div>

        {selectCC && (
          <div className="flex items-center form-group relative w-full mt-4">
            <label htmlFor="cc" className="block text-xs input-label mr-2 font-bold whitespace-nowrap w-14 text-right ">
              Cc
            </label>
            <label htmlFor="cc" className="rounded w-full contentEditable px-3 flex flex-wrap">
              {_get(mailContent, "cc", []) &&
                _get(mailContent, "cc", []).map((cc, i) => {
                  return (
                    <button
                      type="button"
                      key={convertToLowerCase(i + "mailContent-cc-tendk")}
                      className={`whitespace-nowrap h-6 bg-${EmailValidate(cc) ? "gray" : "red"}-200
                                                 text-black pl-2 pr-1.5 py-0.5 rounded-sm my-1.5 mx-1 leading-none inline-flex items-center`}
                    >
                      {cc}
                      <span className="bg-white rounded text-gray-400 ml-2 w-4 h-4">
                        <XIcon
                          onClick={() => {
                            handleRemoveRecipient("cc", cc);
                          }}
                        />
                      </span>
                    </button>
                  );
                })}
              <div
                style={{ minWidth: "100px" }}
                id="cc"
                tabIndex={0}
                contentEditable={true}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyPress}
                className="text-sm outline-none pl-1 pr-1.5 py-1.5"
              />
            </label>
          </div>
        )}

        {selectBCC && (
          <div className="flex items-center form-group relative w-full mt-4">
            <label
              htmlFor="bcc"
              className="block text-xs input-label mr-2 font-bold whitespace-nowrap w-14 text-right "
            >
              bcc
            </label>
            <label htmlFor="bcc" className="rounded w-full contentEditable px-3 flex flex-wrap">
              {_get(mailContent, "bcc", []) &&
                _get(mailContent, "bcc", []).map((bcc, i) => {
                  return (
                    <button
                      key={convertToLowerCase(i + "mailContent-bcc-tendk")}
                      type="button"
                      className={`whitespace-nowrap h-6 bg-${EmailValidate(bcc) ? "gray" : "red"}-200 text-black 
                                                pl-2 pr-1.5 py-0.5 rounded-sm my-1.5 mx-1 leading-none inline-flex items-center`}
                    >
                      {bcc}{" "}
                      <span className="bg-white rounded text-gray-400 ml-2 w-4 h-4">
                        {" "}
                        <XIcon
                          onClick={() => {
                            handleRemoveRecipient("bcc", bcc);
                          }}
                        />
                      </span>
                    </button>
                  );
                })}
              <div
                style={{ minWidth: "100px" }}
                tabIndex={0}
                id="bcc"
                contentEditable={true}
                onKeyPress={onKeyPress}
                onKeyDown={onKeyPress}
                className="text-sm outline-none pl-1 pr-1.5 py-1.5"
              />
            </label>
          </div>
        )}
        <ImageUploadReturn onClosImage={onCloseImage} imagRenderArry={sendAttachments} />
        <button
          onClick={() => handleOpenReplyMail()}
          type="button"
          className="w-8 h-4 rounded-lg bg-gray-300 hover:bg-gray-500
                        hover:text-white transition-all duration-200"
        >
          <DotsHorizontalIcon className="w-full h-full" />
        </button>
        <div className={`border-l pl-5 mt-5 bg-gray-100 shadow-inner py-3 ${openReplyMail ? "" : "hidden"}`}>
          <div className="flex space-x-4 items-center mb-5">
            <div className="h-8 w-8 rounded-full overflow-hidden flex-shrink-0">
              <img src={ImgAvatarMale4} alt="avatar" className="h-full w-full object-cover" />
            </div>
            <div className="flex flex-col">
              <h3 className="font-semibold text-lg">
                {mailFrom
                  ? mailFrom.map((eachFrom: any) => {
                      return _get(eachFrom, "name", "") ? _get(eachFrom, "name", "") : _get(eachFrom, "address", "");
                    })
                  : null}
              </h3>
              <p className="text-light text-gray-400">
                {mailFrom
                  ? mailFrom.map((eachFrom: any) => {
                      return _get(eachFrom, "address", "");
                    })
                  : null}
              </p>
            </div>
          </div>
          <article className="text-sm mt-8 text-gray-800 leading-6 tracking-wider mail_style">
            {mailHtml
              ? parse(mailHtml)
              : _get(mailData, "text", "")
                  .split("\n")
                  .map((eachLine: any) => {
                    return eachLine === "" ? <br /> : <p>{eachLine}</p>;
                  })}
            <br />
          </article>
        </div>
      </section>
    </div>
  );
};

export default EnquiryMailReply;
