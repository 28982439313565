import React, { useState, useEffect } from "react";
import Navbar from "Pages/Navbar/index";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import Sidebar from "Pages/Sidebar/Sidebar";
import { userRolePermission } from "helperRole";
import RegisterNewUser from "Pages/RegisterNewUser";
import { Decrypt, encryptData } from "utils/secureFile";
import useGlobalDOMEvents from "hooks/useGlobalDOMEvents";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import { envPath } from "ApiProcess/httpRestServices/apiManage";
import { convertToLowerCase, nullToObject } from "utils/validation";
import MyErrorBoundary from "Component/MyErrorBoundary/MyErrorBoundary";

const ProtectedRoute = (prop: any = {}) => {
  const { trackPageView, pushInstruction } = useMatomo();
  const { componentName = "", component: Component, pageNumber = [0] } = nullToObject(prop);

  const globalStore = useSelector((state: any) => state);
  const { themeReducer: { isFullView = false } = {} } = nullToObject(globalStore);

  const [newUserPopup, setNewUserPopup] = useState(false);
  const [sideMenuAnimate, setSideMenuAnimate] = useState(false);
  const allowPage = userRolePermission(pageNumber); //userRole base navigate

  useGlobalDOMEvents({});

  const handleNewUserPopup = () => setNewUserPopup(!newUserPopup);

  const handleSideMenu = () => setSideMenuAnimate(!sideMenuAnimate);

  const handleSideMenuClose = () => {
    const sidebar = document && document.getElementById("sidebar");
    sidebar && sidebar.classList.remove("sidebarHover");
    setSideMenuAnimate(false);
  };

  useEffect(() => {
    if (envPath !== "prod") {
      sessionStorage.setItem("pageNamedev", pageNumber);
    }
    encryptData("pageNumberBasedRouting", pageNumber);

    const matomoIdSet = Decrypt("matomoIdSet") || false;
    if (!matomoIdSet) {
      const loginUserDetails = Decrypt("userLoginData") || {};
      const { data: { empId = "" } = {} } = loginUserDetails;
      empId && pushInstruction("setUserId", empId);
      encryptData("matomoIdSet", true);
    }
    trackPageView({});
  }, [pageNumber]);

  return sessionStorage.getItem("userLoginData") && allowPage ? (
    <div className="wrapper flex flex-col h-full overflow-hidden relative">
      <Navbar handleSideMenu={handleSideMenu} handleNewuserPopup={handleNewUserPopup} />

      <div id="wrapperBody" className=" wrapper-body flex h-full ">
        <div className="wrapper-content flex w-full ">
          <Sidebar sideMenuAnimate={sideMenuAnimate} handleSideMenuClose={handleSideMenuClose} />
          <div id="wrapper_content_scroll" className="wrapper-content-body flex sm:px-3 xl:px-5 sm:pt-3 flex-wrap">
            <MyErrorBoundary component={convertToLowerCase(componentName)}>
              <Component />
            </MyErrorBoundary>

            <div
              id="modal"
              className={`wrapper-content-right flex-auto w-full h-auto ${isFullView ? " xl:w-full " : " xl:w-6/12 2xl:w-7/12"}`}
            />
            <div id="modalWrapperBottom" className={`wrapper-content-Bottom w-full flex-1`} />
          </div>

          {newUserPopup ? ( //create
            <MyErrorBoundary component={convertToLowerCase("RegisterNewUser")}>
              <RegisterNewUser
                editUserDetails={{}}
                popupState={newUserPopup}
                globalStore={globalStore}
                handleNewuserPopup={handleNewUserPopup}
              />
            </MyErrorBoundary>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <MyErrorBoundary component={convertToLowerCase(componentName)}>
      <Navigate to="/" />
    </MyErrorBoundary>
  );
};
export default ProtectedRoute;
