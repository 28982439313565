import { _get } from "utils/lodashConst";

export const salesTransactionInitState = {
  salesTransactionList: {},
  salesTransactionLoader: false
};

export const salesTransactionReducer = (state = salesTransactionInitState, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "STORE_INITIAL_STAGE": {
      return {
        ...state,
        ...salesTransactionInitState
      };
    }
    //all reasult data store here
    case "SET_SALES_TRANSACTION_LIST": {
      return {
        ...state,
        salesTransactionList: _get(action, "salesTransactionList", {})
      };
    }
    //sales transaction loader
    case "SET_SALES_TRANSACTION_LOADER": {
      return {
        ...state,
        salesTransactionLoader: _get(action, "salesTransactionLoader", false)
      };
    }
    default:
      return {
        ...state
      };
  }
};
