import { _get } from "utils/lodashConst";
import { moment } from "utils/momentConst";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";

export const commonDatePickerFnc = (event: any = {}, userSearch: any = {}) => {
  const { name = "", value = null, nameEdate = "", nameEvalue = null } = _get(event, "target", {});
  let newObj: any = {};
  if (value && !nameEvalue) {
    newObj = {
      ...userSearch,
      page: 1,
      [name]: value ? value : null,
      [nameEdate]: nameEvalue ? nameEvalue : null
    };
  } else if (nameEvalue && !value) {
    newObj = {
      ...userSearch,
      page: 1,
      [name]: null,
      [nameEdate]: nameEvalue
    };
  } else {
    newObj = {
      ...userSearch,
      page: 1,
      [name]: value,
      [nameEdate]: nameEvalue
    };
  }
  return newObj;
};

export const commonDatePickerFnction = (sDate: any = {}, eDate: any = {}) => {
  const fromDate = DateTimeFormeterStart(sDate);
  const toDate = DateTimeFormeterEnd(eDate);
  let newObj: any = {};
  if (fromDate && !toDate) {
    newObj = {
      target: {
        name: "startDate",
        value: moment(fromDate),
        nameEdate: "endDate",
        nameEvalue: null
      }
    };
  } else if (toDate && !fromDate) {
    newObj = {
      target: {
        name: "startDate",
        value: null,
        nameEdate: "endDate",
        nameEvalue: moment(toDate)
      }
    };
  } else if (toDate && fromDate) {
    newObj = {
      target: {
        name: "startDate",
        value: moment(fromDate),
        nameEdate: "endDate",
        nameEvalue: moment(toDate)
      }
    };
  }
  return newObj;
};
