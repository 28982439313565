import { Get, Post, Put } from "ApiProcess/httpRestServices";
import {
  crmApiBaseUrl,
  encodeGetParams,
  netCoreMail,
  omsBasePath,
  onlineEnq,
  orderTracking
} from "ApiProcess/httpRestServices/apiManage";
import store from "store";
import { getCourierTrackingAction, getPurchesOrderIdDetailsAction } from "store/action/dashBoardAction";
import { DateTimeFormeterEnd, DateTimeFormeterStart, nullToObject } from "utils/validation";

// Order ID based on data
export const orderIDApiCall = (orderID: object = {}) => {
  store.dispatch({ type: "PURCHES_ORDER_HISTORY_DETAILS_LOADER", purchesOrderLoader: true }); //loader
  store.dispatch(getPurchesOrderIdDetailsAction(orderID)); //dispatch the imei number action
};

/**
 * get tracking info
 */
export const getCourierTrackingInfoApiService = (awb_no: any = "") => {
  const newObj = {
    awb_no: awb_no
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${omsBasePath}${orderTracking.courierTracking}?${queryString}`, "omsHistoryToken");
};

//get action for get order history details
export const getCourierTrackingApiCall = (data = {}) => {
  store.dispatch({ type: "PURCHES_ORDER_HISTORY_DETAILS_LOADER", purchesOrderLoader: true }); //loader
  store.dispatch(getCourierTrackingAction(data));
};

/**
 * phone number store
 */
export const searchPhoneNumberStoreInState = (phoneNum = "") => {
  store.dispatch({ type: "NEW_CALL_PAGE_CUSTOMER_PHONE_NUMBER", newCallPagePhoneNum: phoneNum });
};

export const saveOnlineEnquiryApiService = (obj) => {
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;

  const {
    basicInfoReducer: { basicInfoUserinfoData: { customerId = "", name = "", mobile = "", email = "" } = {} } = {}
  } = storeData;

  const {
    mailId = "",
    options = "",
    orderId = "",
    enquiryFor = "",
    callType = true,
    statusInfo = {},
    subCategory = ""
  } = nullToObject(obj);

  const { status = "", currentStatus = "", remarks = "" } = statusInfo;

  let onlineData = {
    status: status,
    options: options,
    orderId: orderId,
    remarks: remarks,
    callType: callType,
    customerName: name,
    enquiryFor: enquiryFor,
    subCategory: subCategory,
    currentStatus: currentStatus,
    email: email === null ? "" : email,
    mobileNo: mobile === null ? "" : mobile,
    leadDetails: justDialLeadDetails, //justDial lead create
    isQuery: "t",
    cusId: customerId
  };
  if (mailId) {
    onlineData = { ...onlineData, ...{ mailId: mailId } };
  }
  const queryString = encodeGetParams(onlineData);
  return Post(`${crmApiBaseUrl}${onlineEnq.saveOnline}?${queryString}`, {}, "crmToken");
};

/**
 * Update mail with created enquiry
 * @returns updated mail from server
 */
export const updateMailByIdServiceCall = (obj: any = {}) => {
  const { id = "", enquiryId = "", enquiryFor = "", subCategory = "" } = nullToObject(obj);

  const enquiryData = {
    id: id,
    enquiryId: enquiryId,
    enquiryFor: enquiryFor,
    subCategory: subCategory
  };
  const queryString = encodeGetParams(enquiryData);
  return Put(`${crmApiBaseUrl}${netCoreMail.updateEnquiry}?${queryString}`, enquiryData, "crmToken");
};

/**
 * Get online enq call details from server
 * @param obj search details
 * @returns online list upload to Excel Document
 */
export const uploadOnlineDetailsService = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    status = [],
    search = "",
    endDate = "",
    options = [],
    callType = "",
    startDate = "",
    subCategory = [],
    currentStatus = []
  } = nullToObject(obj);

  const newObj = {
    page,
    size,
    search,
    status,
    options,
    callType,
    subCategory,
    currentStatus,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    isQuery: "t"
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${onlineEnq.uploadOnlinedataToExcel}?${queryString}`, {}, "crmToken");
};

/**
 * Set popup to true/ to show popup
 */
export const setShowDetailsOnlinePopupCall = () => {
  store.dispatch({ type: "SET_TRANSACTION_POPUP", transactionPopup: true });
};

/**
 *reset save service success
 */
export const resetOrderTrackingUserData = () => {
  store.dispatch({ type: "OT_GET_DETAILS_BASED_ORDERID_BASED_DATA", orderTrackingUserData: {} });
};
