import React, { Fragment } from "react";
import PageAnimate from "./PageAnimate";
import { IconTrash } from "assets/images";
import { XIcon } from "@heroicons/react/solid";
import { nullToObject } from "utils/validation";
import { TrashIcon } from "@heroicons/react/outline";
import OutsideClickHandler from "react-outside-click-handler";
import { actionPopupBtnStyleRed, actionPopupBtnStyleWhite } from "utils/ClassContent";

type ActionPopupProps = {
  children?: any;
  moreButton?: any;
  PopupClass?: any;
  isFullView?: boolean;
  CustomHeading?: any;
  handleCancel?: any;
  handleAction?: any;
  isOverFlow?: boolean;
  customWidth?: string;
  enableDesc?: boolean;
  customMWidth?: string;
  actionHeading?: string;
  enableHeader?: boolean;
  enableFullView?: boolean;
  textHeaderHeading?: any;
  actionIconComponent?: any;
  handleActionText?: string;
  handleCancelText?: string;
  styleHandleAction?: string;
  styleHandleCancel?: string;
  enableActionIcon?: boolean;
  actionDescription?: string;
  isDisbaleOutSideClick?: boolean;
};

const ActionPopup = (props: ActionPopupProps) => {
  const {
    children,
    moreButton,
    PopupClass = "",
    isFullView = true,
    isOverFlow = true,
    CustomHeading = "",
    actionHeading = "",
    enableDesc = false,
    customMWidth = "100%",
    customWidth = "450px",
    enableHeader = false,
    handleActionText = "",
    handleCancelText = "",
    textHeaderHeading = "",
    handleCancel = () => { },
    styleHandleCancel = "",
    handleAction = () => { },
    styleHandleAction = "",
    actionDescription = "",
    enableActionIcon = true,
    actionIconComponent = true,
    enableFullView = false,
    isDisbaleOutSideClick = false
  } = nullToObject(props);
  return (
    <Fragment>
      <div className="absolute bg-black opacity-50 inset-0 z-0 next-mw-full" />
      <OutsideClickHandler onOutsideClick={isDisbaleOutSideClick ? () => { } : () => handleCancel()}>
        <PageAnimate
          leaveTo="transition-all duration-1000 opacity-0 transform -translate-y-2"
          enterFrom="transition-all duration-1000 opacity-0 transform translate-y-0"
          enterTo="transition-all duration-1000 opacity-100 transform -translate-y-0"
          leaveFrom="transition-all duration-1000 opacity-10 transform -translate-y-2"
          enter="transition-all duration-1000 opacity-100 transform translate-y-2"
          leave="transition-all duration-1000 opacity-1 transform -translate-y-0"
        >
          <div
            style={{
              width: `${customWidth ? customWidth : ""}`,
              maxWidth: `${customMWidth ? customMWidth : ""}`,
              marginTop: `${enableFullView ? 0 : "5%"}`,
              maxHeight: `calc(100vh - ${enableFullView ? 0 : "10%"})`
            }}
            className={`${PopupClass ? PopupClass : " "} ${enableFullView ? " md:p-5" : ""} ActionPopup w-full relative mx-auto rounded-lg shadow-lg 
                     ${isOverFlow ? " overflow-hidden " : " overflow-visible "}`}
          >
            <div className={`${isFullView ? "h-full" : ""} bg-white rounded-lg max-h-full`}>
              {enableDesc && (
                <div className="text-center p-5 flex-auto justify-center">
                  {enableActionIcon &&
                    (actionIconComponent ? (
                      actionIconComponent
                    ) : (
                      <Fragment>
                        <IconTrash />
                        <TrashIcon className="w-10 h-10 flex items-center text-red-500 mx-auto" />
                      </Fragment>
                    ))}
                  {actionHeading ? ( //body Header
                    <h2 className="text-base font-bold py-4 ">{actionHeading}</h2>
                  ) : null}

                  {actionDescription && ( //body discription
                    <p className="text-sm text-gray-500 px-8">{actionDescription}</p>
                  )}
                </div>
              )}

              {enableHeader && (
                <div className="flex justify-between items-center header p-3 mb-5 font-bold text-base text-white  bg-primary-gradient rounded-t-lg">
                  <h3 className="flex-1">{textHeaderHeading}</h3>
                  <XIcon
                    onClick={handleCancel ? handleCancel : null}
                    className="w-5 h-5 text-white opacity-70 hover:opacity-100
                                 transition-all duration-200 cursor-pointer "
                  />
                </div>
              )}

              {CustomHeading}

              {children}

              <div className="px-3 pt-3 pb-4 mt-2 text-center md:block">
                {handleCancelText && (
                  <button
                    type="button"
                    onClick={handleCancel ? handleCancel : null}
                    className={`${styleHandleCancel ? styleHandleCancel : actionPopupBtnStyleWhite} mx-2`}
                  >
                    {handleCancelText}
                  </button>
                )}

                {handleActionText && (
                  <button
                    type="button"
                    onClick={handleAction ? handleAction : null}
                    className={`${styleHandleAction ? styleHandleAction : actionPopupBtnStyleRed} mx-2`}
                  >
                    {handleActionText}
                  </button>
                )}
                {moreButton}
              </div>
            </div>
          </div>
        </PageAnimate>
      </OutsideClickHandler>
    </Fragment>
  );
};

export default ActionPopup;
