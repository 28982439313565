import React, { useCallback, useEffect, useState } from "react";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import { convertToLowerCase } from "utils/validation";
import { SalesCustomerBasicInfoPropsType } from "./types";
import { _get, _isEmpty, _toLower } from "utils/lodashConst";
import { statusUpdateSalesApiCall } from "../commonSalesProduct";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import IsSpecificBrandCheck from "../SalesProductDetails/ProductStatusUpdate/IsSpecificBrandCheck";

/**
 * Show's customer basic info fields
 * @param props properties for customer basic info
 * @returns input fields to get customer info
 */
const SalesCustomerBasicInfo = (props: SalesCustomerBasicInfoPropsType = {}) => {
  const {
    status: enqStatus = "",
    customerData = {},
    enableViewMode = true,
    isSpecificProduct = [],
    handleEditView = () => {},
    getEnquiryInformation = () => {}
  } = props || {};

  const [payload, setPayload] = useState<any>({});
  /**
   * Update the value of input field
   * @param event event for selected input field
   */
  const onChange = (event: any = {}) => {
    // const { value = "", name = "" } = _get(event, "target", {});
  };

  const passLeadSource = useCallback((inPutDetails = {}, validationDetails = {}) => {
    const newObj = {
      inputData: {
        ...inPutDetails,
        existingUser: _toLower(_get(inPutDetails, "existingUser", "")) === "yes" ? true : false
      },
      validate: validationDetails
    };
    setPayload(newObj);
  }, []);

  const handleSubmit = async () => {
    let respose: any = {};
    const { existingUser = "", leadClassification = "", leadClassificationReason = "" } = payload.inputData || {};
    const data = {
      isQuery: "t",
      enquiryFor: _get(customerData, "enquiryFor", ""),
      subCategory: _get(customerData, "subCategory", ""),
      customerId: _get(customerData, "_id", ""),
      existingUser,
      leadClassification: _isEmpty(leadClassification)
        ? _get(customerData, "leadClassification", "")
        : leadClassification,
      leadClassificationReason: _isEmpty(leadClassificationReason)
        ? _get(customerData, "leadClassificationReason", "")
        : leadClassificationReason
    };
    const bool = true;
    if (bool) {
      respose = await statusUpdateSalesApiCall(data);
      const { data: { status = 0 } = {} } = respose;
      if (status === 200) {
        getEnquiryInformation({ ticketId: respose?.data?.data?.ticketId || "" });
        successToast("Updated successfully");
        handleEditView();
      } else {
        failToast(_get(respose, "data.message", ""));
      }
    }
  };

  useEffect(() => {
    // passProduct(_get(customerData, "itemGroupName", ""));
  }, [customerData]);

  return (
    <React.Fragment>
      <CommonInput
        readOnly={true}
        placeholder={""}
        mustField={true}
        inputError={false}
        name={"enquiryFor"}
        onChange={onChange}
        inputFieldClass="h-9"
        labelText={"Enquiry For"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={_get(customerData, "enquiryFor", "")}
      />
      <CommonInput
        readOnly={true}
        mustField={true}
        placeholder={""}
        inputError={false}
        onChange={onChange}
        name={"subCategory"}
        inputFieldClass="h-9"
        labelText={"Sub Category"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={_get(customerData, "subCategory", "")}
      />
      <CommonInput
        placeholder={""}
        readOnly={true}
        mustField={true}
        name={"itemCode"}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        labelText={"Item Code"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={_get(customerData, "itemCode", "")}
      />
      <CommonInput
        name={"product"}
        readOnly={true}
        placeholder={""}
        mustField={true}
        inputError={false}
        onChange={onChange}
        labelText={"Product"}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={_get(customerData, "category", "")}
      />
      <CommonInput
        name={"brand"}
        readOnly={true}
        placeholder={""}
        mustField={true}
        inputError={false}
        labelText={"Brand"}
        onChange={onChange}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={_get(customerData, "itemBrandName", "")}
      />
      <CommonInput
        name={"model"}
        readOnly={true}
        placeholder={""}
        mustField={true}
        inputError={false}
        labelText={"Model"}
        onChange={onChange}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={_get(customerData, "itemName", "")}
      />
      <>
        <IsSpecificBrandCheck
          currentPage="sales"
          passLeadSource={passLeadSource}
          enableViewMode={enableViewMode}
          isSpecificProduct={isSpecificProduct}
          existUser={_get(customerData, "existingUser", "")}
          leadCls={_get(customerData, "leadClassification", "")}
          reason={_get(customerData, "leadClassificationReason", "")}
        />
        {convertToLowerCase(enqStatus) !== "closed" && !enableViewMode ? (
          <div className="flex flex-col sm:flex-row items-center justify-center w-full">
            <div>
              <button
                type="button"
                onClick={handleSubmit}
                className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
              >
                {"Update"}
              </button>
            </div>
          </div>
        ) : null}
      </>
    </React.Fragment>
  );
};

export default SalesCustomerBasicInfo;
