import React from "react";
import { moment } from "utils/momentConst";
import { _get } from "utils/lodashConst";
import { ImgAvatarMale4 } from "assets/images";
import OutsideClickHandler from "react-outside-click-handler";

/**
 * Mail envelop details
 * @param props props
 * @returns mail envelop details
 */
const MailInfo = (props: any) => {
  const { mailData = {}, handleClose = () => {} } = props;

  const { from: mailFrom = [], to: mailTo = [], cc: mailCC = [], bcc: mailBCC = [] } = mailData || {};

  return (
    <OutsideClickHandler
      onOutsideClick={(event: any) => {
        if (event.target.classList.contains("open")) return;
        handleClose(false);
      }}
    >
      <div className="flex flex-wrap place-items-center absolute top-full mt-2 left-5 max-h-72 overflow-hidden overflow-y-auto">
        <div className="shadow-lg rounded w-72 md:w-96 p-4 bg-white relative overflow-hidden border">
          <div className="w-full h-full block">
            <div className="flex items-center border-b-2 mb-2 py-2">
              <img className="w-10 h-10 object-cover rounded-full" alt="User avatar" src={ImgAvatarMale4} />
              <div className="pl-3">
                <div className="font-medium">
                  {mailFrom
                    ? mailFrom.map((eachFrom: any) => {
                        return _get(eachFrom, "name", "") ? _get(eachFrom, "name", "") : _get(eachFrom, "address", "");
                      })
                    : null}
                </div>
                <div className="text-gray-600 text-sm">
                  {mailFrom
                    ? mailFrom.map((eachFrom: any) => {
                        return _get(eachFrom, "address", "");
                      })
                    : null}
                </div>
              </div>
            </div>
            <div className="text-sm">
              <div className="w-full flex">
                <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">from:</p>
                <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                  {mailFrom
                    ? mailFrom.map((eachFrom: any) => {
                        return _get(eachFrom, "name", "") + " <" + _get(eachFrom, "address", "") + ">";
                      })
                    : null}
                </p>
              </div>
              <div className="w-full flex">
                <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">reply-to:</p>
                <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                  {mailFrom
                    ? mailFrom.map((eachFrom: any) => {
                        return _get(eachFrom, "address", "");
                      })
                    : null}
                </p>
              </div>
              <div className="w-full flex">
                <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">to:</p>
                <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                  {mailTo
                    ? mailTo.map((eachTo: any) => {
                        return _get(eachTo, "address", "") + " ";
                      })
                    : null}
                </p>
              </div>
              {mailCC.length !== 0 && (
                <div className="w-full flex">
                  <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">cc</p>
                  <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                    {mailCC
                      ? mailCC.map((eachCC: any) => {
                          return _get(eachCC, "address", "") + " ";
                        })
                      : null}
                  </p>
                </div>
              )}
              {mailBCC.length !== 0 && (
                <div className="w-full flex">
                  <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">bcc</p>
                  <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                    {mailBCC
                      ? mailBCC.map((eachBcc: any) => {
                          return _get(eachBcc, "address", "") + " ";
                        })
                      : null}
                  </p>
                </div>
              )}
              <div className="w-full flex">
                <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">date:</p>
                <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                  {moment(_get(mailData, "date", "")).format("DD/MM/YYYY hh:mm a")}
                </p>
              </div>
              <div className="w-full flex">
                <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">subject:</p>
                <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">{_get(mailData, "subject", "")}</p>
              </div>
              <div className="w-full flex">
                <p className="text-gray-400 font-medium mb-2 w-24 flex-shrink-0">mailed-by:</p>
                <p className="text-gray-600 font-medium mb-2 whitespace-pre-wrap">
                  {mailFrom
                    ? mailFrom.map((eachFrom: any) => {
                        return _get(eachFrom, "address", "").split("@").pop();
                      })
                    : null}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default MailInfo;
