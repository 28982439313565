import React, { useEffect, useState } from "react";
import "./styles.scss";
import { moment } from "utils/momentConst";
import { _isEmpty } from "utils/lodashConst";
import DateInput from "Component/Form/DateInput";
import PriceRangeOffer from "../../PriceRangeOffer";
import { convertToLowerCase } from "utils/validation";
import RadioCheck from "Component/Form/CommonRadioBox";
import { CalendarIcon } from "@heroicons/react/outline";
import CommonDropDownInput from "Component/CommonTable/CommonTableSortDropdown/CommonDropDownInput";

const RoundOff = (value: number) => {
  const temp = Math.floor(value / 100) * 100;
  return temp >= value ? temp : temp + 100;
};

const SideMenuOffer = (props: any) => {
  const {
    offersFilterOptions,
    offerList,
    SetFilteredOffers,
    setOffersFilterOptions,
    offersFilter,
    setOffersFilter,
    mobileToggle,
    setMobileToggle
  } = props;

  const [updateValue, setUpdateValue] = useState<boolean>(false);
  const [updateMax, setUpdateMax] = useState<boolean>(false);
  const [maxBenefits, setMaxBenefits] = useState<number>(offersFilterOptions?.maxBenefits || 0);
  const [offerFilterHeading, setOfferFilterHeading] = useState<any>(
    offersFilterOptions ? Object.keys(offersFilterOptions) : []
  );
  const [searchAmount, setSearchAmount] = useState<any>({});
  const [searchVal, setSearchVal] = useState<string>("");

  const setPriceRange = (val: any) => {
    if (val[0] !== val[1]) {
      setSearchAmount({ minAmount: val[0], mxAmount: val[1] });
      SetFilteredOffers(offerList.filter((item: any) => item.maxBenefites >= val[0] && item.maxBenefites <= val[1]));
      setUpdateMax(true);
    }
  };

  const filterSelectionHandler = (status: boolean, filterVal: any, index: number, item: any) => {
    const newoffersFilterOptions = offersFilterOptions;
    if (status)
      setOffersFilter((prevFilter: any) => ({
        ...prevFilter,
        filters: [
          ...prevFilter.filters.filter((preVal: any) => Object.keys(filterVal.filter)[0] !== Object.keys(preVal)[0]),
          filterVal.filter
        ]
      }));
    else {
      setOffersFilter((prevFilter: any) => ({
        ...prevFilter,
        filters: prevFilter.filters.filter((preVal: any) => JSON.stringify(preVal) !== JSON.stringify(filterVal.filter))
      }));
    }
    newoffersFilterOptions[item] = newoffersFilterOptions[item].map((option: any) => {
      if (Object.keys(option || {}).includes("checked")) delete option.checked;
      return option;
    });

    newoffersFilterOptions[item][index]["checked"] = status;
    setOffersFilterOptions(newoffersFilterOptions);
  };

  const clearFilter = () => {
    setOffersFilter({ filters: [], startDate: "", endDate: "" });
    SetFilteredOffers(offerList);
    setUpdateMax(false);
    const offersFilterOptionsClr: any = [];
    offerFilterHeading?.map((item: any, index: number) => {
      offersFilterOptionsClr[item] = offersFilterOptions[item]?.map((offerOption: any, i: number) => {
        offerOption.checked = false;
        return offerOption;
      });
      return "";
    });
    setOffersFilterOptions(offersFilterOptionsClr);
  };

  const setStartDate = (event: any) => {
    setOffersFilter((prevFilter: any) => ({
      ...prevFilter,
      startDate: event.target.value
    }));
  };

  const setEndDate = (event: any) => {
    setOffersFilter((prevFilter: any) => ({
      ...prevFilter,
      endDate: event.target.value
    }));
  };

  const handleSearchfilter = (e: any) => {
    setSearchVal(e?.target?.value);
    if (e?.target?.value?.length) {
      setOfferFilterHeading(
        Object.keys(offersFilterOptions).filter((value: any) =>
          convertToLowerCase(value).replaceAll(" ", "").includes(e.target.value.toLowerCase().replaceAll(" ", ""))
        )
      );
    } else {
      setOfferFilterHeading(Object.keys(offersFilterOptions));
    }
  };

  const handleClearOptionSearch = () => {
    setSearchVal("");
    setOfferFilterHeading(Object.keys(offersFilterOptions));
  };

  useEffect(() => {
    setMaxBenefits(offersFilterOptions?.maxBenefits || 0);
    setOfferFilterHeading(offersFilterOptions ? Object.keys(offersFilterOptions) : []);
    setUpdateValue(!updateValue);
  }, [offersFilterOptions]);

  useEffect(() => {
    if (!_isEmpty(offerList)) {
      const [item] = offerList;
      setMaxBenefits(item.maxBenefites);
      setUpdateValue(!updateValue);
    }
  }, [offerList]);

  return (
    <div className={mobileToggle ? "view-side-con active" : "view-side-con"}>
      <div className="overlay" onClick={() => setMobileToggle(false)} />
      <div className="view-side">
        <div className="side-child">
          <div className="child-span">
            <div className="side-filter-header">
              <h3 className=" px-4 flow-root bg-gray-200">
                <button
                  type="button"
                  onClick={() => {}}
                  className="py-2 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                >
                  <span className="font-smi text-gray-900">{"Filter"}</span>
                  {(JSON.stringify(offersFilter) !== JSON.stringify({ filters: [], startDate: "", endDate: "" }) ||
                    updateMax) && (
                    <span style={{ cursor: "pointer" }} onClick={() => clearFilter()} className="side-filter-clear">
                      Clear
                    </span>
                  )}
                </button>
              </h3>

              <div className={` flex flex-col`}>
                <CommonDropDownInput
                  autoFocus={false}
                  value={searchVal}
                  handleSearchfilter={(e: any) => handleSearchfilter(e)}
                  handleClearOptionSearch={() => handleClearOptionSearch()}
                  name={"Search"}
                />
              </div>
              <h3 className=" px-4 flow-root bg-gray-200">
                <button
                  type="button"
                  onClick={() => {}}
                  className="py-2 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                >
                  <span className="font-smi text-gray-900">{"Start Date"}</span>
                </button>
              </h3>
              <div className={` flex flex-col`}>
                <DateInput
                  name={"startDate"}
                  label={false}
                  placeholderText={""}
                  inputWithIcon={true}
                  inputFieldClass="h-8"
                  closButtonShow={true}
                  enableDatePicker={true}
                  isIconLabel={true}
                  labelTextClass={"font-bold"}
                  parentClass={"w-full px-3 py-2"}
                  onChange={(event: any) => setStartDate(event)}
                  iconComponent={<CalendarIcon className="w-5 h-5" />}
                  value={offersFilter?.startDate ? offersFilter.startDate : ""}
                />
              </div>
              <h3 className=" px-4 flow-root bg-gray-200">
                <button
                  type="button"
                  onClick={() => {}}
                  className="py-2 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                >
                  <span className="font-smi text-gray-900">{"End Date"}</span>
                </button>
              </h3>
              <div className={` flex flex-col`}>
                <DateInput
                  value={offersFilter?.endDate ? offersFilter.endDate : ""}
                  name={"endDate"}
                  label={false}
                  placeholderText={""}
                  minDate={offersFilter?.startDate ? moment(offersFilter.startDate) : ""}
                  inputWithIcon={true}
                  inputFieldClass="h-8"
                  closButtonShow={true}
                  enableDatePicker={true}
                  iconClickAction={() => {}}
                  onChange={(event: any) => setEndDate(event)}
                  labelTextClass={"font-bold"}
                  parentClass={"w-full px-3 py-2"}
                  iconComponent={<CalendarIcon className="w-5 h-5" />}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="border-gray-200 mb-2 ">
          <h3 className=" px-4 flow-root bg-gray-200">
            <button
              type="button"
              onClick={() => {}}
              className="py-2 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
            >
              <span className="font-smi text-gray-900">{"Max Benefites"}</span>
            </button>
          </h3>
          <div className="pt-5 pb-9 px-4">
            <div className="">
              <PriceRangeOffer
                min={1}
                max={RoundOff(maxBenefits)}
                searchAmount={searchAmount}
                pricerangeBarSeach={(val: any) => setPriceRange(val)}
                update={updateValue}
              />
            </div>
          </div>
          {offerFilterHeading?.map((item: any, index: number) => {
            return (
              <div key={convertToLowerCase(index + "test-key")}>
                <h3 className=" px-4 bg-gray-200">
                  <button
                    type="button"
                    className="py-2 w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500"
                  >
                    <span className="font-smi text-gray-900">{item}</span>
                  </button>
                </h3>
                <div className={` flex flex-col `}>
                  <ul
                    className={` py-2 space-y-1 text-sm font-medium px-4 text-gray-900 border-bborder-gray-200 overflow-hidden`}
                  >
                    {offersFilterOptions[item]?.map((value: any, i: number) => {
                      return (
                        typeof value.name === "string" && (
                          <li key={convertToLowerCase(i + "offer-key")}>
                            <div className="space-y-4">
                              <div className="flex items-center w-full">
                                <RadioCheck
                                  id={`${index + "-" + value.name.toLowerCase().replaceAll(" ", "")}`}
                                  name={value.name}
                                  checked={value?.checked}
                                  parentClass={"right-0 top-0"}
                                  onChange={(event: any) =>
                                    filterSelectionHandler(event.target.checked, value, i, item)
                                  }
                                />
                                <label
                                  htmlFor={`check-${index + "-" + value.name.toLowerCase().replaceAll(" ", "")}`}
                                  className="text-sm text-gray-600 flex-grow cursor-pointer hover:text-gray-900"
                                >
                                  {value.name}
                                </label>
                              </div>
                            </div>
                          </li>
                        )
                      );
                    })}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SideMenuOffer;
