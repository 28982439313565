import { Get, Post, Put } from "ApiProcess/httpRestServices";
import {
  crmApiBaseUrl,
  comListPageApi,
  encodeGetParams,
  orgBaseUrl
} from "../../ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * get cuslist data
 */
export const getComplainById = async (id: any = "") => {
  return await Get(`${crmApiBaseUrl}${comListPageApi.comList}/${id}`, "crmToken");
};

/**
 * @param  {object} obj;
 * upload excel cuslist data
 */
export const uploadcomListServicesData = (obj: any = {}) => {
  const { callType = null } = obj;
  const newObj = {
    ...obj,
    callType: callType === null ? "" : callType
  };
  const consvertString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${comListPageApi.uploadComList}${consvertString}`, {}, "crmToken");
};

/**
 * @param {id} string
 * @param {object} object
 * update complain data
 */
export const updateComplainServices = (id: any = "", data: any = {}) => {
  const formData = new FormData();
  const imageArray = data.additionalAttachments;

  if (imageArray.length > 0) {
    for (const file of imageArray) {
      formData.append("additionalAttachments", file.file); // appending every file to formdata
    }
  }
  delete data.additionalAttachments;
  data.isQuery = "t";
  const convertString = encodeGetParams(data);
  return Put(`${crmApiBaseUrl}${comListPageApi.updateComplain}${id}?${convertString}`, formData, "crmToken");
};

/**
 * call attachment export api
 * @param obj
 */
export const sendComplainEmailService = (obj: any = {}) => {
  const {
    data: {
      cc = "",
      bcc = "",
      status = "",
      search = "",
      toEmail = "",
      subject = "",
      content = "",
      endDate = "",
      fromEmail = "",
      startDate = "",
      subCategory = "",
      currentStatus = "",
      evaluate = "",
      excelColumnFilter = {}
    } = {}
  } = obj || {};

  const newObj = {
    cc,
    bcc,
    search,
    status,
    toEmail,
    subject,
    content,
    fromEmail,
    subCategory,
    currentStatus,
    evaluate,
    endDate: endDate,
    startDate: startDate
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${comListPageApi.attachEmail}?${queryString}`, excelColumnFilter, "crmToken");
};

/**
 *  @param {object} object
 * get followup user details
 */

export const getFollowUpCustomerDetails = () => {
  return Get(`${crmApiBaseUrl}${comListPageApi.followUpCustomerDetails}`, "crmToken");
};
