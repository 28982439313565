import React from "react";
import { PaginationListShowMock } from "Component/MockData";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/solid";
import PageSizeDropdown from "Component/Form/PageSizeDropdown";

const PaginationControl = (props: any = {}) => {
  const {
    pager = {},
    allTableDetails = {},
    buttonPress = () => {}, //click
    paginationDetails = true,
    pageSizeHandel = () => {} //click
  } = nullToObject(props);

  const { pageSize = 10, totalPages = 0 } = pager;
  const { count = 0, page: currentPage = 0, size = "" } = allTableDetails;
  const pageSelection = +currentPage === 1 ? 1 : (+currentPage - 1) * +size + 1;
  const currentTotalPage = totalPages === +currentPage ? count : +size * +currentPage;

  return (
    <div className="flex flex-col lg:justify-between justify-center flex-wrap space-y-2 items-center py-2 px-2 lg:flex-row lg:space-y-0">
      <div className="flex items-center mb-1">
        <span className="text-sm mr-2">Page size</span>
        <PageSizeDropdown
          name="size"
          label={false}
          value={pageSize}
          dropOnTop={true}
          parentClass={""}
          removeCursor={true}
          inputFieldClass="h-8 text-sm"
          handleOnChange={pageSizeHandel}
          GetData={PaginationListShowMock}
          inputClass="flex justify-end w-16"
          inputvalid={pageSize !== 10 ? true : false}
        />
      </div>

      {paginationDetails ? (
        <div>
          <div className="text-center mb-1">
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{pageSelection}</span> to{" "}
              <span className="font-medium">{currentTotalPage}</span> of <span className="font-medium">{count}</span>{" "}
              results
            </p>
          </div>
        </div>
      ) : null}

      <ul className="pagination relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
        <button
          disabled={pager.currentPage === 1 ? true : false}
          onClick={() => buttonPress(1)}
          data-jest-id={"jestButtonPress"}
          type="button"
          className={`${pager.currentPage === 1 ? " cursor-not-allowed opacity-50 " : " "}
                                    relative inline-flex items-center px-2 py-1 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
        >
          <span>First</span>
        </button>
        <button
          disabled={pager.currentPage === 1 ? true : false}
          onClick={() => buttonPress(pager.currentPage - 1)}
          type="button"
          data-jest-id={"jestButtonPress1"}
          className={`${pager.currentPage === 1 ? " cursor-not-allowed opacity-50 " : " "}
                         relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </button>
        {(pager.pages || []).map((page: any, index: any) => (
          <button
            key={convertToLowerCase(index + "pager.pages-dgd")}
            type="button"
            onClick={() => buttonPress(page)}
            data-jest-id={"jestButtonPress2"}
            className={
              pager.currentPage === page
                ? "z-10 bg-yellow-500 border-yellow-600 text-white relative inline-flex items-center px-3 py-1 h-8 border text-sm font-medium"
                : `bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center
                                px-3 py-1 border text-sm font-medium`
            }
          >
            {page}
          </button>
        ))}
        <button
          type="button"
          onClick={() => buttonPress(pager.currentPage + 1)}
          data-jest-id={"jestButtonPress3"}
          disabled={pager.currentPage === pager.totalPages ? true : false}
          className={`${pager.currentPage === pager.totalPages ? " cursor-not-allowed opacity-50 " : "  "}
                        relative inline-flex items-center px-2 py-1 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </button>
        <button
          type="button"
          onClick={() => buttonPress(pager.totalPages)}
          data-jest-id={"jestButtonPress4"}
          disabled={pager.currentPage === pager.totalPages ? true : false}
          className={`${pager.currentPage === pager.totalPages ? " cursor-not-allowed opacity-50 " : " "}
                        relative inline-flex items-center px-2 py-1 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`}
        >
          <span>Last</span>
        </button>
      </ul>
    </div>
  );
};
export default PaginationControl;
