import { _get } from "utils/lodashConst";

export const cusListInitState = {
  cusListData: {},
  cusDeleteLoader: false,
  cusListgetLoader: false,
  cusDeletePopUpClose: null,
  getAllCallByIdLoader: false,
  dashBoardToFollowUpCallList: {}
};

export const cusListReducer = (state = cusListInitState, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "CUSLIST_INITIAL_STAGE": {
      return {
        ...state,
        ...cusListInitState
      };
    }

    //dashboard to followup to call
    case "DASH_BOARD_TO_CUSTOMER_LIST": {
      return {
        ...state,
        dashBoardToFollowUpCallList: _get(action, "dashBoardToFollowUpCallList", "")
      };
    }

    case "STORE_CUS_LIST_DATA": {
      return {
        ...state,
        cusListData: _get(action, "cusListData", {})
      };
    }

    case "CUS_DELETE_LOADER": {
      // cus loader
      return {
        ...state,
        cusDeleteLoader: _get(action, "cusDeleteLoader", false)
      };
    }

    case "GET_ALL_CALL_BY_ID_LOADER": {
      // cus loader
      return {
        ...state,
        getAllCallByIdLoader: _get(action, "getAllCallByIdLoader", false)
      };
    }

    case "CUS_DELETE_POP_UP_CLOSE": {
      //after delete popUp close
      return {
        ...state,
        cusDeletePopUpClose: _get(action, "cusDeletePopUpClose", null)
      };
    }

    default:
      return {
        ...state
      };
  }
};
