import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { replacementFormProps } from "./types";
import { nullToObject, numOnlyAllow } from "utils/validation";
import { StyleForminput } from "utils/ClassContent";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { checkSRNValue, getEmpDetailsForReplaceMent } from "../../../../commonSalesReturn";
import ImageUploadReturn from "Component/ImageUploadReturn";
import FormFile from "Component/Form/FormFile";
import CommonInput from "Component/Form/CommonInput";

const ReplacementFormUpdate = (props: replacementFormProps) => {
  const {
    fromPage = "",
    genralInfo = {},
    setShow = true,
    globalStore = {},
    convertLowerCase = "",
    srSubCategoryState = {},
    replacePassdata = () => {}
  } = props || {};

  const { invoiceAmount = 0, itemName = "", itemGroupName } = genralInfo;

  const { srSubCategory = "" } = srSubCategoryState;
  const { salesReturn: { empDetailsBasedEmpId = {} } = {} } = nullToObject(globalStore); //redux store

  const checkApprovalStatus = checkSRNValue(convertLowerCase);

  const [relaceEmpId, setRelaceEmpId] = useState("");
  const [relaceProduct, setRelaceProduct] = useState("");
  const [relaceEmpName, setRelaceEmpName] = useState("");
  const [imagRenderArry, setImagRenderArry] = useState([]);
  const [relaceDepartment, setRelaceDepartment] = useState("");
  const [relaceDesignation, setRelaceDesignation] = useState("");
  const [relaceSellingPrice, setRelaceSellingPrice] = useState("");

  const [relaceFormData, setRelaceFormData] = useState({
    relaceEmpId: "",
    relaceEmpName: "",
    relaceProduct: "",
    relaceDepartment: "",
    relaceDesignation: "",
    relaceSellingPrice: "",
    relaceimagRenderArry: []
  });

  const onImageChange = (event: any = {}) => {
    if (_get(event, "target.files", [])?.length > 0) {
      const file = _get(event, "target.files[0]", {});
      const { name = "", type = "" } = file;
      const newObj = {
        file: file,
        fileName: name,
        type: type,
        url: URL.createObjectURL(file) //conevrt blob formate
      };
      const newArray: any = [...imagRenderArry, newObj];
      const newObject = {
        ...relaceFormData,
        relaceimagRenderArry: newArray
      };
      setImagRenderArry(newArray);
      setRelaceFormData(newObject);
      replacePassdata(newObject, true); //pass data here to parent index
    }
  };

  const onClosImage = (index = 0) => {
    //remove image
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    const newObject = {
      ...relaceFormData,
      relaceimagRenderArry: newArray
    };
    setImagRenderArry(newArray);
    setRelaceFormData(newObject);
    replacePassdata(newObject, true); //pass data here to parent index
  };

  /**
   * onUpdate main state and error validate
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const relaceForm = relaceFormData;
    switch (name) {
      case "relaceProduct":
        relaceForm.relaceProduct = value;
        setRelaceProduct(value);
        break;
      case "relaceEmpId":
        relaceForm.relaceEmpId = value;
        setRelaceEmpId(value);
        value && getEmpDetailsForReplaceMent(value); //emp id based search details
        break;
      case "relaceEmpName":
        relaceForm.relaceEmpName = value;
        setRelaceEmpName(value);
        break;
      case "relaceDepartment":
        relaceForm.relaceDepartment = value;
        setRelaceDepartment(value);
        break;
      case "relaceDesignation":
        relaceForm.relaceDesignation = value;
        setRelaceDesignation(value);
        break;
      case "relaceSellingPrice":
        if (invoiceAmount >= value) {
          relaceForm.relaceSellingPrice = value;
          setRelaceSellingPrice(value);
        } else {
          failToast("Replacement Selling Price to be equal amout or less then Product value");
        }
        break;
      default:
        break;
    }
    setRelaceFormData(relaceForm);
    replacePassdata(relaceForm, true); //pass data here to parent index
  };

  const dynamicClass = () => {
    return StyleForminput();
  };

  useEffect(() => {
    const { employee_name = "", employee_department = "", employee_designation = "" } = empDetailsBasedEmpId;
    onChange({ target: { name: "relaceEmpName", value: employee_name } });
    onChange({ target: { name: "relaceDepartment", value: employee_department } });
    onChange({ target: { name: "relaceDesignation", value: employee_designation } });
  }, [empDetailsBasedEmpId]);

  useEffect(() => {
    if (relaceEmpId === "") {
      //emp search is empty ,input box will clear
      onChange({ target: { name: "relaceEmpName", value: "" } });
      onChange({ target: { name: "relaceDepartment", value: "" } });
      onChange({ target: { name: "relaceDesignation", value: "" } });
    }
  }, [relaceEmpId]);

  useEffect(() => {
    if (itemName) {
      if (fromPage === "convertToSRN") {
        //from convert page appent groupName
        onChange({ target: { name: "relaceProduct", value: itemGroupName } });
      } else {
        onChange({ target: { name: "relaceProduct", value: itemName } });
      }
    } else {
      onChange({ target: { name: "relaceProduct", value: "" } });
    }
  }, [itemName]);

  return (
    <React.Fragment>
      {/* will show only not a srn number */}
      {srSubCategory !== "SRN" && (
        <React.Fragment>
          <CommonInput
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            labelText={"Product"}
            name={"relaceProduct"}
            value={relaceProduct}
            inputFieldClass="h-9"
            placeholder={"Product"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />

          <CommonInput
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            inputFieldClass="h-9"
            value={relaceSellingPrice}
            name={"relaceSellingPrice"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
            labelText={"Replacement Selling Price"}
            placeholder={"Replacement Selling Price"}
            onKeyPress={numOnlyAllow}
          />
        </React.Fragment>
      )}

      {checkApprovalStatus && (
        <React.Fragment>
          <CommonInput
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            value={relaceEmpId}
            name={"relaceEmpId"}
            inputFieldClass="h-9"
            labelText={"Employee Id"}
            labelTextClass={"font-bold"}
            placeholder={"Employee Id"}
            parentClass={dynamicClass()}
          />

          <CommonInput
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            readOnly={true}
            value={relaceEmpName}
            inputFieldClass="h-9"
            name={"relaceEmpName"}
            labelText={"Employee Name"}
            placeholder={"Employee Name"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />

          <CommonInput
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            readOnly={true}
            inputFieldClass="h-9"
            labelText={"Department"}
            name={"relaceDepartment"}
            value={relaceDepartment}
            placeholder={"Department"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />

          <CommonInput
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            readOnly={true}
            inputFieldClass="h-9"
            labelText={"Designation"}
            value={relaceDesignation}
            name={"relaceDesignation"}
            placeholder={"Designation"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />
        </React.Fragment>
      )}
      <Fragment>
        <FormFile
          type="file"
          label={true}
          onChange={onImageChange}
          labelText="Attach Image"
          labelTextClass={"font-bold"}
          parentClass={"  mb-1 w-full md:w-1/2 mt-1 px-4 "}
          accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .odt, .txt, .xlsx, .xls"
        />
        {imagRenderArry.length >= 1 && (
          <div className="w-full flex flex-wrap justify-center px-3">
            <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
          </div>
        )}
      </Fragment>
    </React.Fragment>
  );
};
export default ReplacementFormUpdate;
