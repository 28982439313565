import { Get, Put } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart, nullToObject } from "utils/validation";
import {
  onlineEnq,
  omsBasePath,
  crmApiBaseUrl,
  orderTracking,
  encodeGetParams
} from "ApiProcess/httpRestServices/apiManage";

/**
 * Get online enq call details from server
 * @param obj search details
 * @returns online list as response from server
 */
export const getOnlineDetailsApiService = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    status = [],
    search = "",
    endDate = "",
    options = [],
    callType = "",
    startDate = "",
    subCategory = [],
    currentStatus = []
  } = nullToObject(obj);

  const newObj = {
    page,
    size,
    search,
    status,
    options,
    callType,
    subCategory,
    isQuery: "t",
    currentStatus,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${onlineEnq.getOnline}?${queryString}`, "crmToken");
};

/**
 * Update online enq call details
 * @param  {object} obj online enq details
 */
export const updateOnlineEnqStatusApiService = (data: any = {}) => {
  const {
    _id = "",
    status = "",
    remarks = "",
    callType = "",
    enquiryFor = "",
    followUpDate = "",
    currentStatus = ""
  } = data;

  const userData = {
    status: status,
    callType: callType,
    enquiryFor: enquiryFor,
    statusRemarks: remarks,
    followUpDate: followUpDate,
    currentStatus: currentStatus,
    isQuery: "t"
  };
  const queryString = encodeGetParams(userData);
  return Put(`${crmApiBaseUrl}${onlineEnq.updateOnline}/${_id}?${queryString}`, {}, "crmToken");
};

export const onlineInitialSearch = {
  page: 1,
  size: 10,
  search: "",
  options: [],
  endDate: null,
  callType: null,
  startDate: null,
  subCategory: [],
  currentStatus: [],
  status: ["Open", "Pending"]
};

/**
 * get order details history
 */
export const getUserPurchesOrderIdApiService = (obj: any = {}) => {
  const { orderId = "" } = obj;
  const newObj = {
    order_id: orderId
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${omsBasePath}${orderTracking.omsDetails}?${queryString}`, "omsHistoryToken");
};
