import React from "react";
import { StarIcon } from "@heroicons/react/solid";

const ProductReview = (props: any = {}) => {
  const { productDetails = {} } = props;

  const { rating = 0, reviews_v1: { review_total = 0 } = {} } = productDetails;
  return (
    <React.Fragment>
      {/* {_get(productDetails, "color", "") !== "" &&
                <span className="mb-2">
                    {"Color"} : <strong>{_get(productDetails, "color", "")}</strong>
                </span>
            } */}
      <div className="flex flex-wrap items-center mb-2 text-sm">
        <span className="flex text-gray-600">Rating:</span>
        <div className="flex justify-center items-center text-yellow-600 ml-1 text-xs">
          <strong className="ml-1">{rating} </strong>
          <StarIcon className="w-4 h-4 mx-1" />({review_total} {` `} Reviews)
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductReview;
