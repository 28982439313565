import React, { Fragment, useEffect, useState } from "react";
import Loader from "Component/Loader";
import { _get } from "utils/lodashConst";
import { Decrypt } from "utils/secureFile";
import { TableClass } from "utils/ClassContent";
import { pageNumber, tabBasedAccess } from "helperRole";
import Pagination from "Component/CommonTable/Pagination";
import MobileFilterMenu from "Component/MobileFilterMenu";
import CommonLayoutHeader from "Component/CommonLayoutHeader";
import EnquiryFeedbackTableBody from "./EnquiryFeedbackTableBody";
import EnquiryFeedbackTableHeader from "./EnquiryFeedbackTableHeader";
import MyErrorBoundary from "Component/MyErrorBoundary/MyErrorBoundary";
import { leadingAndTrailingSpaceAvoid, nullToObject } from "utils/validation";
import DatePickerCommon from "Component/CommonTable/CommonStartToEndDatePic/DatePickerCommon";
import { enquiryFeedBackApiCallList, downLoadEnquiryFeedBackList } from "./helperEnquiryFeedback";
import { commonDatePickerFnc } from "Component/CommonTable/CommonStartToEndDatePic/commonDatePickerFnc";

const EnquiryFeedback = (props: any = {}) => {
  //excel download controller
  const enableExcelDownload = tabBasedAccess(pageNumber.ENQUIRY_FEEDBACK_EXCEL_DOWNLOAD);
  const [enquirySearch, setEnquirySearch] = useState(""); //search input box
  const [responseData, setResponseData] = useState<any>({});
  const [loader, setLoader] = useState(false);
  const { count = 0, enquiryFeedbacks = [] } = responseData;
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { role = "", empId = "" } = {} } = nullToObject(userLoginDetails);
  const requestParams =
    role === "CHAT_SUPPORT" || role === "CHAT_ORDER" || role === "CALL_CENTER_STAFF" ? { employeeId: empId } : {};
  const [userSearch, setUserSearch] = useState({
    page: 1,
    size: 10,
    search: "",
    endDate: null,
    startDate: null,
    ...requestParams
  });

  const paginationChange = (value = 0) => {
    setUserSearch({
      ...userSearch,
      page: !value ? 1 : value
    });
  };

  //input search
  const searchChangeInput = (event: any = {}) => {
    //search input
    const { target: { value = "" } = {} } = event;
    setEnquirySearch(value);
  };

  /**
   * range picker
   * @param event
   */
  const _onDateChange = (event: any = {}) => {
    const newObj = commonDatePickerFnc(event, userSearch);
    setUserSearch(newObj);
  };

  const handleOnchange = (event: any = {}) => {
    const { name = "", value = "" } = _get(event, "target", {});
    setUserSearch({
      ...userSearch,
      page: 1,
      [name]: value
    });

    if (name === "search") {
      setEnquirySearch("");
    }
  };

  const searchUserDetaisl = (event: any = {}) => {
    //search input
    const { value = "" } = event?.target;
    setUserSearch({
      ...userSearch,
      page: 1,
      search: leadingAndTrailingSpaceAvoid(value) //leading and trailing space remove
    });
  };

  /**
   * page size handle
   * @param event object
   */
  const pageSizeHandel = (event: any = {}) => {
    const { target: { value = 10 } = {} } = event;
    setUserSearch({
      ...userSearch,
      page: 1,
      size: value
    });
  };

  const getPreRegisterListApi = async () => {
    setLoader(true);
    const response = await enquiryFeedBackApiCallList(userSearch);
    const { data: { data = {}, status = 0 } = {} } = response;
    setLoader(false);
    if (status === 200) {
      setResponseData(data);
    } else {
      setResponseData({});
    }
  };

  useEffect(() => {
    getPreRegisterListApi();
  }, [userSearch]);

  return (
    <Fragment>
      {loader ? <Loader /> : null}

      <div className="flex flex-col w-full h-auto">
        <CommonLayoutHeader
          Enablesearch={true}
          heading={"Enquiry FeedBack"}
          tableId={"UserDetailsTable"}
          enquirySearch={enquirySearch}
          enableTableColumnSetting={true}
          handleSearch={searchChangeInput}
          handleSearchClear={handleOnchange}
          searchUserDetais={searchUserDetaisl}
          enableExcelDownload={enableExcelDownload}
          tableColumnSettingId={"enquiryFeedBackTable"}
          handleExcelDownload={() => downLoadEnquiryFeedBackList(userSearch)}
        />
        <MyErrorBoundary component="Enquiry-Feedback-Filter">
          <MobileFilterMenu>
            {/* date picker */}
            <DatePickerCommon userSearch={userSearch} _onDateChange={_onDateChange} />
          </MobileFilterMenu>
        </MyErrorBoundary>
        <div className="overflow-x-auto shadow bg-white relative" style={{ minHeight: "500px" }}>
          <table id="UserDetailsTable" className={TableClass}>
            <EnquiryFeedbackTableHeader />
            <EnquiryFeedbackTableBody enquiryFeedbacks={enquiryFeedbacks} />
          </table>
        </div>

        {count > 10 ? (
          <Pagination
            items={count}
            pageSize={userSearch.size}
            initialPage={+userSearch.page}
            onChangePage={paginationChange}
            allTableDetails={{ ...responseData, ...userSearch }}
            pageSizeHandel={pageSizeHandel}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default EnquiryFeedback;
