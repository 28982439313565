import { Get, Post } from "ApiProcess/httpRestServices";
import { apxBaseUrlPath, crmApiBaseUrl, duplicateInv } from "../../ApiProcess/httpRestServices/apiManage";

type imeiType = {
  imei?: string;
  data?: any;
};

/**
 * @param  {object} obj;
 * imei number passed
 */

export const getDuplicateInvImeiNumberBasedServices = (obj: imeiType) => {
  const { imei = "" } = obj || {};
  return Get(`${apxBaseUrlPath}${duplicateInv.imeiDetail}${imei}`, "apxToken");
};

//RemarkUpdate Object Data
type dupRemarkUpdate = {
  status?: string;
  remarks?: string;
};

/**
 * @param  {object} obj;
 * Remaks passed
 */
export const getDuplicateInvRemarkUpdateServices = async (obj: dupRemarkUpdate) => {
  return await Post(`${crmApiBaseUrl}${duplicateInv.dupInvRemarkUpdate}`, obj, "crmToken");
};

// /**
//  * @param  {object} obj;
//  * Smart Link passed via Whatsapp
//  */
// export const sendWhatsappDuplicateInvBasedServices = async (obj: smsType) => {
//     return await Post(`${crmApiBaseUrl}${duplicateInv.smartLinkWhatsappSMS}`, obj, "crmToken");
// };

/**
 * @param  {object} obj;
 * Duplicate Inv StatusUpdate All Data
 */

export const getDuplicateStatusUpdatedBasedServices = (obj: any = {}) => {
  const { obj: { serviceID = "" } = {} } = obj;
  return Get(`${crmApiBaseUrl}${duplicateInv.getDupInvServiceSmsHistory}/${serviceID}`, "crmToken");
};

/**
 * @param  {object} obj;
 * Duplicate Inv Resend SMS Based Services
 */

export const getDuplicateResendSMSBasedServices = (obj: any = {}) => {
  const { serviceID = "" } = obj;
  return Post(`${crmApiBaseUrl}${duplicateInv.dupInvResendSMS}/${serviceID}`, { ...obj }, "crmToken");
};
