import store from "store";
import { _get } from "utils/lodashConst";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { Get, Post, withoutTokenGet } from "ApiProcess/httpRestServices";
import { DateTimeFormeterTimeing, convertToLowerCase, nullToArray, nullToObject, nullToString } from "utils/validation";
import {
  pimNewApi,
  // eToeBaseUrl,
  ecomBasePath,
  salesProduct,
  crmApiBaseUrl,
  apxBaseUrlPath,
  encodeGetParams,
  offerProjectEndPath,
  centralInventoryApi,
  getAssignBranchDetailsApiPath,
  productDetailsGet
} from "ApiProcess/httpRestServices/apiManage";

export const setSaveSalesEnqStatusSuccessCall = (mobile = "") => {
  const endPath = mobile ? `/new-call?phone=${mobile}` : `/new-call`;
  window.history.pushState({}, "", endPath); // url search query remove without reload
  store.dispatch({ type: "MOVE_TO_ENQUIRY_T0_TAB_ONE", moveToTabOne: true });
};

export const resetSaveSalesEnqStatusSuccessCall = () => {
  store.dispatch({ type: "MOVE_TO_ENQUIRY_T0_TAB_ONE", moveToTabOne: false });
};

export const setMailUpdateSalesSuccessCall = () => {
  store.dispatch({
    type: "SET_UPDATED_MAIL_SUCCESS_CODE",
    updatedMailSuccessCode: true
  });
};

/**
 * product slug id
 * pass slug id getDetails
 * @param slugId
 */
export const getProductDetailsByCategory = (ele: any = {}) => {
  const { item_code = "", shortcode = "" } = ele;
  const newObj = {
    isproject: "CRM",
    isactive_flag: 1,
    shopcode: shortcode,
    includespecification: 1,
    ...(shortcode && { shopcode: shortcode })
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${pimNewApi}${salesProduct.productDeatails}${item_code}?${queryString}`, "poorvikaProductDetails");
};

/**
 * get delivery type
 */
export const pinCodeBasedDeliveryTypeApiCall = async (itemCode = "", pinCode = "") => {
  const newObj = {
    pincode: pinCode,
    item_code: itemCode
  };
  pinCodeBasedStockDetails(itemCode, pinCode);
  const queryString = encodeGetParams(newObj);
  const response = await Get(
    `${centralInventoryApi}${salesProduct.deliveryTypeGet}?${queryString}`,
    "getDeliveryTypeToken"
  );
  const { data: { data = [], status = 0 } = {} } = nullToObject(response);
  if (status === 200) {
    store.dispatch({
      type: "PIN_CODE_ITEM_CODE_BASED_DELIVERY_DATA",
      deliveryType: data
    });
  } else {
    store.dispatch({
      type: "PIN_CODE_ITEM_CODE_BASED_DELIVERY_DATA",
      deliveryType: []
    });
  }
  return response;
};

/**
 * @param get stock details
 */
export const pinCodeBasedStockDetails = async (itemCode = "", pinCode = "") => {
  const newObj = {
    radius: 100,
    pincode: pinCode,
    item_code: itemCode
  };
  const queryString = encodeGetParams(newObj);
  const response = await Get(
    `${centralInventoryApi}${salesProduct.productAvailabilityCheck}?${queryString}`,
    "centralInventorySalesHeader"
  );
  const { data: { data = {}, status = 0 } = {} } = response;

  if (status === 200) {
    store.dispatch({
      type: "PRODUCT_STOCK_DETAILS_DATA",
      stockDetails: nullToArray(data)
    });
  } else {
    store.dispatch({ type: "PRODUCT_STOCK_DETAILS_DATA", stockDetails: {} });
    failToast("Fail to fetch stock details");
  }
  return data;
};

/**
 * @param get stock details
 */
export const getShowroomPriceApiCall = async (itemCode = "") => {
  const year = new Date().getFullYear();
  const month = new Date().getMonth() + 1;
  const monthZeroAdd = month >= 10 ? +month : "0" + month; //add o
  const date = new Date().getDate();
  const dateZeroAdd = date >= 10 ? +date : "0" + date; //add o

  const dateConstarct = `${year}${monthZeroAdd}${dateZeroAdd}`;
  const newObj = {
    Brand: 0,
    ItemCode: itemCode,
    PriceTemplate: "Discount Chennai Price List"
  };
  const queryString = encodeGetParams(newObj);
  const response: any = await Get(
    `${apxBaseUrlPath}${salesProduct.getShowroomPrice}${dateConstarct}&${queryString}`,
    "apxToken"
  );
  const { status = 0, data: { Table: { 0: { ITEM_NAME = "" } = {} } = [] } = {} } = nullToObject(response);

  if (status === 200) {
    const lastIndex = nullToString(ITEM_NAME).lastIndexOf(":");
    const apxValueFind = nullToString(ITEM_NAME).substring(lastIndex + 1);
    return {
      ...response,
      apxValueFind: apxValueFind
    };
  } else {
    return {
      ...response,
      apxValueFind: "z-apx-no-value"
    };
  }
};

/**
 * similar product
 * get related product details
 */
export const getRelatedProductDetailsCall = (itemCode = "") => {
  return Get(`${pimNewApi}${salesProduct.productDeatails}${itemCode}/relatedproducts`, "poorvikaProductDetails");
};

/**
 * Alternate product
 * get related product details
 */
export const getAlternateProductDetails = (itemCode = "") => {
  const newObjHeader = {
    locale: "en-IN",
    channel: "SHOP"
  };
  return withoutTokenGet(
    `${productDetailsGet}${salesProduct.relatedProduct}/${itemCode}/alternative-products?shopcode=AKDM`,
    newObjHeader
  );
};

/**
 * itemCodeBased
 */
export const getBoughtToGetherProductApi = (ele: any = "") => {
  const { item_code = "" } = ele;
  if (item_code) {
    return Get(`${pimNewApi}${salesProduct.productDeatails}${item_code}/bought-togather`, "poorvikaProductDetails");
  }
};

/**
 * mobile number based get recent enquiry
 */
export const getRelatedEnquiryProductApiCall = (mobileNumber = "") => {
  return Get(`${crmApiBaseUrl}${salesProduct.relatedEnquiryCall}/${mobileNumber}`, "crmToken");
};

/**
 * add to relatedEnquiryDetailCall
 */
export const addRelatedEnquiryDetailsList = (productInfo: any = {}, globalData: any = {}, productSlugId: any = {}) => {
  const { basicInfoReducer = {} } = globalData;
  const { document: { slug = "" } = {} } = productSlugId;
  const { basicInfoUserinfoData: { customerId = "", mobile = "" } = {} } = basicInfoReducer;
  const { id = 0, item_code = "", additional_image_color = [] } = productInfo;
  const productData = additional_image_color.filter((ele: any) => ele.product_id === id);
  const imgUrl = _get(productData, "length", 0) ? _get(productData, "[0].image", "") : _get(productInfo, "image", "");
  const payLoad = {
    mobileNo: mobile,
    customerId: customerId,
    product: {
      ...productInfo,
      slug: slug,
      image: imgUrl,
      productId: id,
      itemCode: item_code
    }
  };
  return Post(`${crmApiBaseUrl}${salesProduct.relatedEnquiryCall}`, payLoad, "crmToken");
};

/**
 * we have 4 product
 * so this common file for 4 data
 * @param productInfo
 * @param salesfillingData
 * @param basicInfoUserinfoData
 */
const commonSpreadOperatotSales = (
  productInfo: any = {},
  salesfillingData: any = {},
  basicInfoUserinfoData: any = {},
  getPinCode: any = ""
) => {
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;

  const { apxProduct = "", offlinePrice = "", document = "", productDetails = {} } = productInfo;

  if (Object.keys(nullToObject(productDetails))?.length > 0) {
    const { slug = "", sku = "", price = 0, special_price = 0, pre_order = false } = document;
    // const { model = "", itemCode = "", item_code = "", brand = {}, category: { name: categoryName = "" } = {}, name: productName = "" } = nullToObject(productDetails);
    const {
      itemCode = "",
      item_code = "",
      brand = {},
      category: { name: categoryName = "" } = {},
      name: productName = ""
    } = nullToObject(productDetails);

    const { name = "", mobile = "", pincode = "", customerId = "" } = basicInfoUserinfoData;

    const {
      assignedData = {},
      source = "",
      nextFollowUpDate = "",
      status = "",
      remark = "",
      bankDetails = [],
      leadClassification = "", //hot is default
      leadClassificationReason = "",
      existingUser = "",
      preferredPayment = [],
      callType = false,
      currentStatus = "",
      openedMailId = "",
      sendBySMS = false,
      sendByWhatsapp = false,
      alternativeNumber = "",
      laptopCategory = ""
    } = salesfillingData;

    const { inputData = {} } = assignedData;

    const { assignToMe = false, userData = {}, branchData = {} } = inputData;

    // Find the object with "code": "department-manager"
    const departmentManagerObject = productDetails?.channel_shapes?.find(
      (obj: any) => obj?.code === "department-manager"
    );
    const departmentManagerValue = departmentManagerObject
      ? departmentManagerObject?.value?.[0]?.value
      : "Not Assigned";

    return {
      status: status,
      source: source,
      remarks: remark,
      productId: itemCode,
      callType: callType,
      customerName: name,
      itemCode: item_code,
      enquiryFor: "Sales",
      mailId: openedMailId,
      ...(!assignToMe && {
        assignedTo: {
          date: new Date(),
          name: userData.name,
          empId: userData.empId,
          branch: branchData.branch,
          branchCode: branchData.shortcode,
          mobile: userData.mobile,
          role: userData.role
        }
      }),
      itemName: productName,
      department: departmentManagerValue,
      subCategory: "Product",
      cusId: "" + customerId,
      apxProductName: "" + apxProduct === "0" ? "z-apx-no-value" : "" + apxProduct,
      mobileNo: mobile ? mobile : "",
      productInfo: {
        //optional u can add any data inide object
        pre_order,
        sku: sku,
        slug: slug,
        price: price,
        pincode: pincode,
        alternativeNumber,
        laptopCategory,
        searchPinCode: getPinCode,
        weburl: ecomBasePath + slug,
        offlinePrice: offlinePrice,
        special_price: special_price,
        ticket_created_from: `web`,

        informVia: {
          whatsapp: sendByWhatsapp,
          sms: sendBySMS
        },
        // model,
        productName,
        customerName: name,
        mobileNo: mobile ? mobile : "",
        brand: _get(brand, "code", "")
      },
      category: categoryName,
      bankDetails: bankDetails,
      currentStatus: currentStatus,
      leadDetails: justDialLeadDetails,
      preferredPayment: preferredPayment,
      leadClassification: leadClassification,
      itemBrandName: _get(brand, "code", ""),
      leadClassificationReason: leadClassificationReason,
      nextFollowUpDate: nextFollowUpDate ? DateTimeFormeterTimeing(nextFollowUpDate) : "",
      existingUser: convertToLowerCase(existingUser) === convertToLowerCase("yes") ? true : false
    };
  }
  return false;
};

/**
 * dublicate item code found
 * @param arr
 */
function hasDuplicates(arr: any = []) {
  return arr.some(function (item: any) {
    return arr.indexOf(item) !== arr.lastIndexOf(item);
  });
}

export const arrayConstructorProductData = (productInfo: any = {}) => {
  const storeData: any = store.getState();
  const { salesReducer = {} } = storeData;
  const { isSpecificProduct = false } = salesReducer;
  const { productInfo1 = {}, productInfo2 = {}, productInfo3 = {}, productInfo4 = {} } = productInfo;
  const itemBrand = ["apple"];
  //typesence document data
  const newObj1: any = commonSpreadOperatotSales(productInfo1, {}, {});
  const newObj2: any = commonSpreadOperatotSales(productInfo2, {}, {});
  const newObj3: any = commonSpreadOperatotSales(productInfo3, {}, {});
  const newObj4: any = commonSpreadOperatotSales(productInfo4, {}, {});
  const newArray = [
    ...(newObj1 !== false ? [newObj1] : []),
    ...(newObj2 !== false ? [newObj2] : []),
    ...(newObj3 !== false ? [newObj3] : []),
    ...(newObj4 !== false ? [newObj4] : [])
  ];

  const result1 = newArray
    .filter((o1: any) => {
      return itemBrand.some((o2: any) => {
        return convertToLowerCase(o1.itemBrandName) === convertToLowerCase(o2);
      });
    })
    .map((ele) => _get(ele, "itemBrandName", ""));

  const result = nullToArray(result1)?.length > 0 ? true : false;

  if (isSpecificProduct !== result) {
    if (nullToArray(result1)?.length > 0) {
      store.dispatch({ type: "CHECK_PRODUCT_ARRAY_HAVE_SPECIFIC_DATA", isSpecificProduct: result1 });
    } else {
      store.dispatch({ type: "CHECK_PRODUCT_ARRAY_HAVE_SPECIFIC_DATA", isSpecificProduct: [] });
    }
  }
  return result1;
};

/**
 * update status
 */
export const createSalesProductStatus = async (event: any = {}) => {
  const { productInfo = {}, basicInfoReducer = {}, salesFillingData = {}, getPinCode = "" } = event;
  const { basicInfoUserinfoData = {} } = nullToObject(basicInfoReducer);
  const { productInfo1 = {}, productInfo2 = {}, productInfo3 = {}, productInfo4 = {} } = productInfo;
  const itemCodeUpdate: any = [];

  for (const item in productInfo) {
    if (_get(productInfo[item], "document.item_code", "")) {
      itemCodeUpdate.push(_get(productInfo[item], "document.item_code", ""));
    }
  }
  const dublidateFound = hasDuplicates(itemCodeUpdate);

  //typesence document data
  const newObj1: any = commonSpreadOperatotSales(productInfo1, salesFillingData, basicInfoUserinfoData, getPinCode);
  const newObj2: any = commonSpreadOperatotSales(productInfo2, salesFillingData, basicInfoUserinfoData, getPinCode);
  const newObj3: any = commonSpreadOperatotSales(productInfo3, salesFillingData, basicInfoUserinfoData, getPinCode);
  const newObj4: any = commonSpreadOperatotSales(productInfo4, salesFillingData, basicInfoUserinfoData, getPinCode);
  const newArray = [
    ...(newObj1 !== false ? [newObj1] : []),
    ...(newObj2 !== false ? [newObj2] : []),
    ...(newObj3 !== false ? [newObj3] : []),
    ...(newObj4 !== false ? [newObj4] : [])
  ];

  //false means no dublicate product added
  if (!dublidateFound) {
    const response = await Post(`${crmApiBaseUrl}${salesProduct.multipleProduct}`, { salesData: newArray }, "crmToken");
    const { data: { status = 0 } = {} } = response;

    if (status === 201) {
      response.data.data["saleArray"] = newArray;
    }
    return response;
  } else {
    failToast("You have selected same product multiple time");
  }
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const offerDetailsInfoGetForAll = async (erp_item_code = "", channel = "ONLINE") => {
  return await Get(`${pimNewApi}${offerProjectEndPath.alloffers}${erp_item_code}`, "offerToken", { channel });
};

/**
 * get assign branch details
 */
export const getAssignBranchDetails = () => {
  return Get(`${crmApiBaseUrl}${getAssignBranchDetailsApiPath.getAssignBranchDetails}`, "crmToken");
};

/**
 * sync assign branch details
 */
export const syncAssignBranchDetails = () => {
  return Get(`${crmApiBaseUrl}${getAssignBranchDetailsApiPath.syncAssignBranchDetails}`, "crmToken");
};

/**
 * get assign branch details to showroom staff details
 */
export const getAssignBranchDetailsToStaff = (event: any = {}) => {
  const newObj = {
    branch: event.branch
  };
  const queryString = encodeGetParams(newObj);
  return Get(
    `${crmApiBaseUrl}${getAssignBranchDetailsApiPath.getAssignBranchDetailsBasedStaff}?${queryString}`,
    "crmToken"
  );
};

/**
 * get assign branch details to showroom staff details
 */
export const getInstockOutStockDetailsApi = (itemCode = "", pinCode = "") => {
  const newObj = {
    pincode: pinCode,
    item_code: itemCode
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${centralInventoryApi}${salesProduct.inStockOutStock}?${queryString}`, "getDeliveryTypeToken");
};
