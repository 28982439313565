import { _get } from "./lodashConst";
import { staticData } from "../ApiProcess/httpRestServices/apiManage";
import { fileUploadSizeValidate, nullToArray } from "./validation";

//pass upload file in array formate
export const uploadFileSizeCheck = (array: any = []) => {
  let fileSize = 0;
  if (nullToArray(array)?.length > 0) {
    array.forEach((element: any) => {
      fileSize = fileSize + _get(element, "file.size", 0);
    });
    const sizeCheck = fileUploadSizeValidate(fileSize, staticData.thirtyMbHandle); //below 30 mb is true
    if (!sizeCheck) {
      //moreThen 30 MB
      return false;
    }
    return true; //below 30 MB
  }
  return true; //is array is empty
};

export const removeDuplicates = (array: any, key: any) => {
  return array.filter(
    (item: any, index: any, self: any) => index === self.findIndex((subItem: any) => subItem[key] === item[key])
  );
};

export const findIndexArrayObj = (array: any, key: any, value: any) => {
  return array.findIndex((item: any) => item[key] === value);
};

export const replaceAtArrayObject = (arrayData: any = [], key: any = "subStatus") =>
  (arrayData || []).map((data) => {
    if (data[key].includes(" At ") || data[key].includes(" at ")) {
      data[key] = data[key].replace(" At ", " @ ").replace(" at ", " @ ");
    }
    return data;
  });
