import { _get } from "utils/lodashConst";

export const rolesAndPages = {
  getRolesLoader: false,
  employeeDetails: {},
  getEmpDetailsLoader: false
};

export const rolesAndPagesTypesReducer = (state = rolesAndPages, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "ROLE_AND_PAGES_INITIAL": {
      return {
        ...state,
        ...rolesAndPages
      };
    }

    case "REG_GET_ACTUAL_EMPLOYEE_DETAILS_LOADER": {
      return {
        ...state,
        getEmpDetailsLoader: _get(action, "getEmpDetailsLoader", false)
      };
    }

    //store actual employee details
    case "REG_STORE_ACTUAL_EMPLOYEE_DETAILS": {
      return {
        ...state,
        employeeDetails: _get(action, "empDetailsBasedEmpId", {})
      };
    }

    default:
      return {
        ...state
      };
  }
};
