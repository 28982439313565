import React from "react";
import { nullToObject } from "utils/validation";
import { StyleFormTextarea } from "utils/ClassContent";
import CommonTextarea from "Component/Form/CommonTextarea";

const EnquiryForRemark = (props: any = {}) => {
  const {
    name = "",
    value = "",
    parentClass = "",
    readOnly = false,
    mustField = false,
    onBlur = () => {},
    inputError = false,
    inputErrorMsg = "",
    isSalesPage = false,
    onBlurChange = false,
    onChange = () => {}
  } = nullToObject(props);

  return (
    <CommonTextarea
      name={name}
      label={true}
      value={value}
      setShow={true}
      onChange={onChange}
      readOnly={readOnly}
      labelText={"Remark"}
      inputWithIcon={false}
      mustField={mustField}
      inputError={inputError}
      isSalesPage={isSalesPage}
      onBlurChange={onBlurChange}
      labelTextClass={"font-bold"}
      inputErrorMsg={inputErrorMsg}
      inputClass="flex justify-end"
      onBlur={onBlur ? onBlur : null}
      parentClass={parentClass ? parentClass : StyleFormTextarea()}
      inputFieldClass={readOnly ? "h-24 resize-none" : "h-32 max-h-64"}
    />
  );
};
export default EnquiryForRemark;
