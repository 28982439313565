import React from "react";
import CommonInput from "../CommonInput";
import { commonMultiChooseProps } from "../types";
import { IconError, IconSearchCustomer } from "assets/images";
import { convertToLowerCase, nullToObject } from "utils/validation";

const CooseRightSideData = (props: commonMultiChooseProps) => {
  const {
    name = "",
    value = "",
    GetData = [],
    labelRight = "",
    inputClass = "",
    optionClass = "",
    mustField = false,
    placeholder = "",
    selectRightId = "2",
    inputError = false,
    inputvalid = false,
    enableSearch = false,
    inputFieldClass = "",
    inputWithIcon = false,
    inputWithIconClass = "",
    getSelectValues = () => {},
    handleRightSearch = () => {}
  } = nullToObject(props);

  const dataList = GetData.filter((ele: any) => ele.isSelect === true); //only filter show for seleted Data
  return (
    <div
      className={`
                    ${inputClass ? inputClass : ""}
                    ${inputWithIcon ? inputWithIcon : ""}
                    relative mb-1 w-full md:w-1/2 px-4 flex flex-col`}
    >
      {labelRight && (
        <label className={`block text-xs input-label mb-1`} htmlFor={`multiSelect-${selectRightId}`}>
          {labelRight} {mustField && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      {enableSearch && (
        <label className="relative w-full">
          <CommonInput
            name={name}
            value={value}
            setShow={true}
            parentPosition={""}
            readOnly={true}
            placeholder={"Search"}
            parentClass={"w-full "}
            onChange={handleRightSearch}
            inputFieldClass="h-9 rounded-b-none -mb-1"
          />
        </label>
      )}
      <div
        id={`multiSelect-${selectRightId}`}
        onChange={(e) => getSelectValues(e)}
        placeholder={placeholder ? placeholder : ""}
        className={`selected block w-full h-auto rounded select-common px-3 py-2 ${inputError && !inputvalid ? " error pr-4" : ""}
                        ${inputvalid && !inputError ? " input-valid" : ""}${inputFieldClass ? inputFieldClass : ""}  ${
                          enableSearch ? " search" : ""
                        }`}
      >
        {dataList?.map((item: any, i: any) => (
          <button
            type="button"
            onClick={(e) => getSelectValues(e, JSON.stringify(item))}
            key={convertToLowerCase(i + "dataList-efs")}
            value={item.id}
            className={optionClass}
          >
            {item.isSelect === true && item.optionData}
          </button>
        ))}
      </div>

      {inputError && (
        <i className="inline-block  absolute top-1/2 right-3 transform -translate-y-1/2">
          <IconError />
        </i>
      )}

      {inputWithIcon && (
        <span
          className={`absolute flex justify-center items-center 
                            ${inputWithIconClass ? inputWithIconClass : ""}`}
        >
          <IconSearchCustomer />
        </span>
      )}
    </div>
  );
};

export default CooseRightSideData;
