import { Component } from "react";
import toastService from "../ToastServices";
import { withTranslation } from "react-i18next";
import { encryptData } from "utils/secureFile";

const toastId = "networkToast";

class NetworkDetector extends Component {
  constructor(props = {}) {
    super(props);
    this.state = {
      netWorkStatus: "online"
    };
  }

  componentDidMount() {
    window.addEventListener("online", this.handleConnectionChange);
    window.addEventListener("offline", this.handleConnectionChange);
  }

  componentWillUnmount() {
    window.removeEventListener("online", this.handleConnectionChange);
    window.removeEventListener("offline", this.handleConnectionChange);
  }

  /**
      Help to find Internet is Connected or Not
    */
  handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      this.setState({ netWorkStatus: "online" });
      toastService.dismissToast(toastId);
      encryptData("onlineStatus", true);
      setTimeout(() => {
        toastService.successToast("NetWork connected", toastId);
      }, 1000);
    } else {
      encryptData("onlineStatus", false);
      this.setState({ netWorkStatus: "offline" });
      toastService.networkToast("You are offline", toastId);
    }
  };

  render() {
    return null;
  }
}

export default withTranslation()(NetworkDetector);
