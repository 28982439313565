import React from "react";
import { moment } from "utils/momentConst";
import { convertToLowerCase } from "utils/validation";
import DatePickerCommon from "Component/CommonTable/CommonStartToEndDatePic/DatePickerCommon";
import PercenatgeView from "Pages/QualityScorecard/ScorecardEditor/ScorecardEditorPages/CallAuditSettings/PercenatgeView";

const GeneralComments = (props: any = {}) => {
  const { comment = [] } = props;
  return (
    <div className="py-5 flex flex-col">
      {/* date picker */}
      <div className="flex justify-end px-2 border-b pb-1">
        <DatePickerCommon userSearch={{}} _onDateChange={() => {}} />
      </div>

      {comment.map((item: any = {}, index = 0) => (
        <div
          key={convertToLowerCase(index + "commxent-tendk")}
          className="flex flex-col justify-between lg:flex-row w-full lg:p-8 p-4 border-b border-gray-300 items-center relative  hover:bg-gray-50 cursor-pointer"
        >
          <span className="text-sm lg:absolute top-3 right-8 mb-3">{moment(item?.createdBy?.date).format("llll")}</span>
          <div className="flex">
            <span className="lg:mr-5 mr-3 inline-block ">{index + 1}.</span>
            <div className="flex flex-col flex-grow ml-4">
              <div className="flex justify-between">
                <div className="flex">
                  <span className="font-semibold">Audit By </span>
                  <span className="ml-1">{item?.createdBy?.name}</span>
                </div>
              </div>
              <p className="mt-1">
                {item.list.map((itm = "", i = 0) => (
                  <p key={itm + i} dangerouslySetInnerHTML={{ __html: itm }} />
                ))}
              </p>
            </div>
          </div>
          <div className="flex justify-center items-center w-40">
            <PercenatgeView scoreCardId={item._id} scoreVlaue={parseInt(item.scorePercentage)} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default GeneralComments;
