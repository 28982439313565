import * as ameyoType from "../actionTypes/ameyoType";

/**
 * @param  {object} data;
 * Update complain Data
 */
export const AmeyoCallAction = (data: any = {}) => {
  return {
    type: ameyoType.AMEYO_CALL_ACTION,
    data
  };
};

/**
 * get call recording data
 * @param data
 * @returns data
 */
export const getCallRecordingAction = (data: any = {}) => {
  return {
    type: ameyoType.GET_CALL_RECORDING_ACTION,
    data
  };
};
