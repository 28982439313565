import React, { useState } from "react";
import { _get } from "utils/lodashConst";
import { btn_download } from "utils/ClassContent";
import { IconAnimateLoading } from "assets/images";
import { DownloadIcon } from "@heroicons/react/outline";
import { pageNumber, tabBasedAccess } from "helperRole";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";

const ExcelDownload = (props: any = {}) => {
  const downloadCreateAccess: any = tabBasedAccess(pageNumber.DOWNLOAD); //93
  const { triggerUploadExcel = () => {} } = props;

  const [getFileGenerated, setFileGenerated] = useState(false);

  const uploadTableData = async () => {
    setFileGenerated(true);
    const response = await triggerUploadExcel();
    if (_get(response, "data.status", 0) === 201) {
      successToast(
        <a
          href="/downloads"
          className="focus:outline-none text-xs leading-tight overflow-hidden overflow-ellipsis ellipse-2"
        >
          File generated successfully You can Download in
          <span className="text-blue-700 ml-0.5">Download Page</span>
        </a>
      );
    } else {
      failToast(_get(response, "data.message", ""));
    }
    setFileGenerated(false);
  };

  return (
    downloadCreateAccess && (
      <button title="Download" type="button" className={btn_download + " w-8 h-8 ml-2 "} onClick={uploadTableData}>
        {!getFileGenerated ? (
          <DownloadIcon className="w-5 h-5" />
        ) : (
          <IconAnimateLoading className=" w-6 h-6 text-indigo-200 " />
        )}
      </button>
    )
  );
};
export default ExcelDownload;
