import { XIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { searchProductApiCall } from "../../commonSalesProduct";
import GlobalPRoductSearch from "../../GlobalPRoductSearch";
import { getInstockOutStockDetailsApi } from "../commonSalesProductDetails";
import "./product-compare.css";

const vieDataBasedCondition = (condition = false, data1: any = "", data2: any = "") => {
  if (condition) {
    return data1;
  }
  return data2;
};

const ProductCompare = (props: any = {}) => {
  const {
    item_code = "",
    pinCode = "",
    productDetails = {},
    similarProduct: similarProductData = [],
    getProductDetailsBySlugIdBased = () => {}
  } = props;

  const [hidden, setHidden] = useState({} as any);
  const [searchParams, setSearchParams] = useState(""); //search
  const [removeCount, setRemoveCount] = useState(0);
  const [searchResult, setSearchResult] = useState([]); //search dropDown list
  const [similarProduct, setsimilarProduct] = useState<any>([]);

  const getComparisonChartData = () => {
    const relatedProductsAttributes = similarProduct
      ?.slice(0, 4)
      ?.map((product: any) => product.specifications.attributeGroups);
    const result: any = {};

    relatedProductsAttributes.forEach((attributeGroups: any) => {
      if (attributeGroups) {
        attributeGroups.forEach((attributeGroup: any) => {
          result[attributeGroup.name] = result[attributeGroup.name] || {};
          attributeGroup.attributes.forEach((attr: any) => {
            result[attributeGroup.name][attr.name] = result[attributeGroup.name][attr.name] || [];
          });
        });
      }
    });

    Object.keys(result).forEach((group) => {
      Object.keys(result[group]).forEach((attribute) => {
        relatedProductsAttributes.forEach((prodAttrGrps: any) => {
          const prodGroup =
            !!prodAttrGrps &&
            prodAttrGrps.find((prodAttrGrp: any) => convertToLowerCase(prodAttrGrp.name) === convertToLowerCase(group));

          if (prodGroup) {
            const prodAttr = prodGroup.attributes.find(
              (attr: any) => convertToLowerCase(attr.name) === convertToLowerCase(attribute)
            );

            result[group][attribute].push(prodAttr?.value);
          } else {
            result[group][attribute].push(null);
          }
        });
      });
    });
    return result;
  };

  const toggleHide = (index: any) => {
    setHidden({
      ...hidden,
      [index]: !hidden[index]
    });
  };

  const compareData = getComparisonChartData();

  const removeCompareItem = (_item: any) => {
    let cnt = 1;
    similarProduct?.map((item: any) => {
      if (item.item_code === "") {
        cnt++;
      } else {
        cnt = 1;
      }
      return item;
    });
    setSearchParams(""); //search params empty
    setRemoveCount(cnt);
    const current = {
      specifications: { attributeGroups: [] },
      name: "",
      special: "",
      rating: 0,
      review_count: 0,
      image: "",
      stock_status: "",
      product_id: "",
      item_code: "",
      price: "",
      discount: "",
      option_value: "",
      slug: ""
    };
    const newIndex = similarProduct.findIndex((ele: any) => ele.item_code === _item.item_code);
    const newArray = [...similarProduct];
    newArray.splice(newIndex, 1);
    setsimilarProduct([...newArray, current]);
  };

  const addProduct = (index: any) => {
    let cnt = 3;
    similarProduct?.map((item: any) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (item.item_code !== "") {
        cnt--;
      } else {
        cnt = 0;
      }
      return item;
    });
    setRemoveCount(cnt);
    const newArrayData = [...similarProduct, similarProductData[index]].filter((ele) => !!ele.item_code);
    for (let i = 0; i < 4; i++) {
      if (newArrayData?.length < 4) {
        const current = {
          specifications: { attributeGroups: [] },
          name: "",
          special_format: "",
          rating: 0,
          review_count: 0,
          image: "",
          stock_status: "",
          product_id: "",
          item_code: "",
          price_format: "",
          discount: "",
          option_value: ""
        };
        newArrayData.push(current);
      }
    }
    setsimilarProduct(newArrayData);
  };

  /**
   * @param e enter based search
   * mose click product dropdown list
   */
  const handleSubmit = (e: any = {}) => {
    e && e.preventDefault && e.preventDefault();
  };

  /**
   * search product
   * @param event
   * sreach product typesense call
   */
  const searchProduct = async (event: any = {}) => {
    const { target: { value = "" } = {} } = event;
    setSearchParams(value);
    const response = (await searchProductApiCall(value)) || {}; //api call
    const { status = 0, data: { results = [] } = {} } = nullToObject(response);

    if (status === 200) {
      setSearchResult(results); //search dropDown purpose
      // setAllResult(results);//total found
    } else {
      setSearchResult([]);
    }
  };

  //pass slug id to index
  const handleChooseProduct = (productFulInfo: any = {}) => {
    const { document: { name = "", keyword = "" } = {} } = productFulInfo;
    const { document = {} } = productFulInfo;
    getProductDetailsBySlugIdBased(document); //pass slug id
    const value = keyword ? keyword : name ? name : "";
    setSearchParams(value);
  };

  const mappingProductDetails = async () => {
    const response = await getInstockOutStockDetailsApi(productDetails.item_code, pinCode);
    const { data: { status = 0, data: { stock = 0 } = {} } = {} } = nullToObject(response);
    let stock_status = "";
    if (status === 200 && stock > 0) {
      stock_status = "In Stock";
    }
    const updatedData = await Promise.all(
      similarProductData?.map(async (item: any) => {
        const result = await getInstockOutStockDetailsApi(item.item_code, pinCode);
        const { data: { status: status2 = 0, data: { stock: stock2 = 0 } = {} } = {} } = nullToObject(response);
        let stockstatus = "";
        if (status2 === 200 && stock2 > 0) {
          stockstatus = "In Stock";
        }
        return result ? { ...item, stock_status: stockstatus } : item;
      })
    );
    const current = {
      stock_status,
      specifications: _get(productDetails, "specifications", {}),
      name: _get(productDetails, "name", ""),
      special_format: _get(productDetails, "prices.sp", 0),
      rating: _get(productDetails, "rating", "0"),
      review_count: _get(productDetails.reviews_v1, "review_total", ""),
      image: _get(productDetails, "image", ""),
      product_id: _get(productDetails, "id", ""),
      item_code: _get(productDetails, "item_code", ""),
      price: _get(productDetails, "mrp.price", ""),
      price_format: _get(productDetails, "mrp.price", ""),
      viewing: "You are viewing :",
      discount: _get(productDetails, "discount", ""),
      option_value: _get(productDetails, "option_value", ""),
      slug: _get(productDetails, "slug", "")
    };
    const newArray = [current, ...updatedData];
    const spliceData = newArray.filter((month, idx) => idx < 4);
    setsimilarProduct(spliceData);
  };

  useEffect(() => {
    mappingProductDetails();
  }, [similarProductData, item_code, pinCode]);

  return (
    <div className={`${"compare_table"} relative my-5`}>
      <span className={`${"compare_height"} absolute`} id="productCompare" />
      <div>
        {similarProduct?.length && (
          <div>
            <h3>Compare with similar items</h3>
            <div className={"table_responsive"}>
              <table className="border-collapse border border-gray-200 w-full">
                <tbody>
                  <tr>
                    <td className={` ${"table_column_sticky"}`} rowSpan={2}>
                      {" "}
                      <span className="visible flex items-center jjustify-items-center font-bold">
                        Compare Features
                      </span>
                    </td>
                  </tr>
                  <tr>
                    {/* !!item.item_code ? ( */}

                    {similarProduct?.map((item: any, index: number) => {
                      return item.item_code ? (
                        <React.Fragment key={convertToLowerCase(index + "similarProduct-susjd")}>
                          <td
                            className={`border border-gray-100 text-sm relative ${vieDataBasedCondition(
                              item.viewing,
                              "viewed",
                              ""
                            )} `}
                          >
                            {/* <Link href={goToProductPage(item)}> */}
                            <a className="block" target="_blank">
                              <img
                                className="w-32 m-auto"
                                src={_get(item, "image.url", "")}
                                alt={_get(item, "name", "")}
                              />
                            </a>
                            {/* </Link> */}
                            {!item.viewing && (
                              <button
                                type="button"
                                className="hover:bg-red-500  hover:bg-opacity-90 hover:shadow-md top-1 absolute p-0.5 right-1 bg-black rounded-full bg-opacity-60 text-white"
                                onClick={() => removeCompareItem(item)}
                              >
                                <XIcon className={"h-3 w-3"} />
                              </button>
                            )}
                            <div>
                              <h5 className="mt-2">
                                {vieDataBasedCondition(item?.viewing, <b>{item?.viewing}</b>, null)}
                                {/* {item.viewing && <b>{item.viewing} </b>} */}
                              </h5>
                              {/* <Link href={goToProductPage(item)}> */}
                              <a className="block" target="_blank">
                                <h4>{item.name}</h4>
                              </a>
                              {/* </Link> */}
                              <span className="text-gray-500">{item.option_value}</span>
                              <span className="flex justify-between font-medium mt-2">
                                <span>{item.special_format}</span>

                                <span className="flex">
                                  {/* rating */}
                                  {vieDataBasedCondition(
                                    item?.rating,
                                    <span className="flex">
                                      <StarIcon className="text-yellow-500 w-5 h-5" />
                                      {item.rating}
                                    </span>,
                                    null
                                  )}

                                  {/* review */}
                                  {vieDataBasedCondition(
                                    item?.review_count,
                                    <span> ({item?.review_count})</span>,
                                    null
                                  )}
                                </span>
                              </span>

                              {/* price */}
                              <div className="my-2 flex flex-wrap items-center">
                                <span className="text-gray-500 line-through">{item.price_format}</span>
                                <span className="text-xs ml-2">
                                  {/* discount */}
                                  {vieDataBasedCondition(item?.discount, <>({item.discount} % OFF)</>, null)}
                                </span>
                              </div>

                              <div className="text-center mt-2">
                                {/* pre order */}
                                {vieDataBasedCondition(
                                  item?.pre_order,
                                  <span className="flex">
                                    <button type="button" className={``}>
                                      <span>Pre Order</span>
                                    </button>
                                  </span>,
                                  null
                                )}

                                {/* ITEM CODE */}
                                <span className="flex">
                                  <button type="button" className={``}>
                                    <span>item_code :</span>
                                    {item.item_code}
                                  </button>
                                </span>

                                {convertToLowerCase(item.stock_status) === convertToLowerCase("In Stock") ? (
                                  <div className="bg-green-50 p-1 mt-1">
                                    <span className={`text-green-600 font-bold`}>INSTOCK </span>
                                  </div>
                                ) : (
                                  <div className="bg-red-50 p-1 mt-1">
                                    <span className="text-red-600 font-bold">Out Of Stock</span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                        </React.Fragment>
                      ) : (
                        <React.Fragment key={convertToLowerCase(index + "similarProduct-srscwf")}>
                          <td className="border border-gray-100 text-sm">
                            <img
                              className="m-auto"
                              style={{ width: "88%" }}
                              src="https://img.poorvika.com//noimage_available_new.jpg"
                              alt=""
                            />
                            {removeCount === 1 && index === 3 ? (
                              <>
                                <form className="w-full flex justify-center mb-2" onSubmit={handleSubmit}>
                                  <GlobalPRoductSearch
                                    value={searchParams}
                                    selectorKey={"label"}
                                    name={"selectedProduct"}
                                    inputWithIconClass="w-10"
                                    clearInput={searchProduct}
                                    dropDownData={searchResult}
                                    inputFieldClass="h-9 pr-11"
                                    inputClass="flex justify-end"
                                    labelTextClass={"font-bold mt-2"}
                                    parentClass={"max-w-lg w-full w-full"}
                                    handleChooseProduct={(ele: any) => handleChooseProduct(ele)}
                                  />
                                </form>
                                <select
                                  disabled={similarProductData?.length === 0}
                                  className={"add_product rounded h-9 pr-2"}
                                  onChange={(e) => addProduct(e.target.value)}
                                >
                                  <option value="Please Select">Please Select</option>
                                  {similarProductData?.map((items: any, iitems: number) => {
                                    return (
                                      <React.Fragment key={convertToLowerCase(iitems + "newProduct-sdksi")}>
                                        <option value={iitems}>
                                          {/* name */}
                                          {vieDataBasedCondition(
                                            _get(items, "item_code", "") !== item_code,
                                            items.name,
                                            null
                                          )}
                                        </option>
                                      </React.Fragment>
                                    );
                                  })}
                                </select>
                              </>
                            ) : (
                              <>
                                <h4>Add a Product</h4>
                                <select
                                  disabled={similarProductData?.length === 0}
                                  className={"add_product"}
                                  onChange={(e) => addProduct(e.target.value)}
                                >
                                  <option value="Please Select">Please Select</option>
                                  {similarProductData?.map((items: any, iitems: number) => {
                                    return (
                                      <React.Fragment key={convertToLowerCase(iitems + "newProduct-dydv")}>
                                        <option value={iitems}>
                                          {/* name */}
                                          {vieDataBasedCondition(
                                            _get(items, "item_code", "") !== item_code,
                                            items.name,
                                            null
                                          )}
                                        </option>
                                      </React.Fragment>
                                    );
                                  })}
                                </select>
                              </>
                            )}
                          </td>
                        </React.Fragment>
                      );
                    })}
                  </tr>

                  {Object.keys(compareData)?.map((group, index) => (
                    <React.Fragment key={convertToLowerCase(index + "compareData-sjxjd")}>
                      <tr>
                        <td
                          colSpan={5}
                          className={`border-t border-b border-gray-200 bg-gray-100 text-sm font-medium ${
                            group?.length > 40 ? "h-16" : "h-12"
                          } ${"table_column_sticky_desc"}`}
                        >
                          <div className={"sticky_text"}>
                            <div onClick={() => toggleHide(index)} className="inline-flex space-x-2 items-center mr-2">
                              <div
                                className={`md:w-10 md:h-5 w-8 h-4 flex items-center bg-${
                                  !hidden[index] === true ? "green600 " : "yellowShadow "
                                }
                                                        rounded-full p-1 cursor-pointer ml-auto mr-auto`}
                              >
                                <div
                                  className={
                                    "bg-white md:w-3 md:h-3 h-2 w-2 rounded-full shadow-md transform transition duration-300 " +
                                    (!hidden[index] === true ? " translate-x-5 " : " ")
                                  }
                                />
                              </div>
                            </div>
                            {group}
                          </div>
                        </td>
                      </tr>
                      {Object.keys(compareData[group])?.map(
                        (attribute: any, idexValue: number) =>
                          !hidden[index] && (
                            <React.Fragment key={convertToLowerCase(idexValue + "compareData[group]=siks")}>
                              <tr>
                                <td
                                  className={`border border-gray-200 text-sm font-medium bg-gray-50 ${"table_column_sticky"}`}
                                >
                                  {attribute}
                                </td>
                                {compareData[group][attribute]?.map((value: any, indexValueAttribute: number) => {
                                  return value ? (
                                    <td
                                      key={convertToLowerCase(indexValueAttribute + "indexValueAttribute-sjddd")}
                                      className={`border border-gray-200 text-sm ${"value_first_child"}`}
                                    >
                                      {value}
                                    </td>
                                  ) : (
                                    <td
                                      key={convertToLowerCase(indexValueAttribute + "indexValueAttribute-sgsr")}
                                      className={`border border-gray-200 text-sm ${"value_first_child"}`}
                                    >
                                      N/A
                                    </td>
                                  );
                                })}
                              </tr>
                            </React.Fragment>
                          )
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductCompare;
