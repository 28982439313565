import { Delete, Get, Post } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import {
  salesProduct,
  crmApiBaseUrl,
  cusListPageApi,
  encodeGetParams,
  typesenceOutDatedBasePath
} from "../../ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const uploadCuslistServices = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    status = "",
    mobileNo = "",
    createdBy = "",
    callType = null,
    endDate = null,
    enquiryFor = "",
    startDate = null,
    subCategory = "",
    currentStatus = ""
  } = obj;

  const newObj = {
    page,
    size,
    search,
    mobileNo,
    createdBy,
    enquiryFor,
    subCategory,
    currentStatus,
    status: status !== "All" ? status : "",
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    callType: callType === true ? true : callType === false ? false : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${cusListPageApi.uploadCusList}?${consvertString}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * delete cus
 */
export const deleteCusListServices = (obj: any = {}) => {
  const { data: { endPath = "", complainId = "" } = {} } = obj || {};
  endPath !== "advocate" &&
    Delete(`${typesenceOutDatedBasePath}${salesProduct.outDatedDelete}${complainId}`, "outDatedProductPost");
  return Delete(`${crmApiBaseUrl}${cusListPageApi.deleteCusData}${endPath}/${complainId}`, "crmToken");
};
