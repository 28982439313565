export const GET_MAIL_BY_ID = "GET_MAIL_BY_ID";
export const SYNC_WITH_SERVER = "SYNC_WITH_SERVER";
export const SEND_EMAIL_TO_SERVER = "SEND_EMAIL_TO_SERVER";
export const DRAFT_EMAIL_TO_SERVER = "DRAFT_EMAIL_TO_SERVER";
export const GET_MAIL_ENQUIRY_BY_ID = "GET_MAIL_ENQUIRY_BY_ID";
export const RECEIVE_EMAIL_FROM_SERVER = "RECEIVE_EMAIL_FROM_SERVER";

export const SAVE_MAIL_CREDENTIAL = "SAVE_MAIL_CREDENTIAL";
export const GET_MAIL_CREDENTIALS = "GET_MAIL_CREDENTIALS";
export const UPDATE_MAIL_CREDENTIALS = "UPDATE_MAIL_CREDENTIALS";
