import store from "store";
import { moment } from "utils/momentConst";
import { _flattenDeep } from "utils/lodashConst";
import { servicesCenterAction, servicesCenterTokenAction } from "store/action/serviceCenterAction";
import {
  updateServiceStatusApiAction,
  updateSalesReturnDataAction,
  getUserDetailsBasedOnInvoiceAndDate,
  getEmpleeDetailsBasedEmpId,
  ImeinumApiCallAction,
  ImeinumBasedTransactionHistory
} from "store/action/salessReturnAction";
import { Get, Put } from "ApiProcess/httpRestServices";
import { uploadFileSizeCheck } from "utils/projectHelper";
import { reasonforSalesReturnDrop } from "Component/MockData";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { convertToLowerCase, DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { crmApiBaseUrl, encodeGetParams, salesReturn } from "ApiProcess/httpRestServices/apiManage";

/**
 * get sales retun only
 */
export const getSalesRetunListApiServices = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    status = "",
    endDate = null,
    enquiryFor = "",
    callType = null,
    startDate = null,
    subCategory = "",
    currentStatus = "",
    createdByState = []
  } = obj || {};

  const newObj = {
    page,
    size,
    search,
    enquiryFor,
    subCategory,
    currentStatus,
    createdByState,
    status: status !== "All" ? status : "",
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    callType: callType === true ? true : callType === false ? false : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${salesReturn.slaseRetunData}?${consvertString}`, "crmToken");
};

/**
 * @param  {number} imeiNumber
 * loader enable
 * dispatch the imei number action
 */
export const imeiNumApiCall = (imeiNumber = "") => {
  const paramsObj = { imeiNum: imeiNumber, enquiryFor: "Sales Returns" };
  store.dispatch(ImeinumBasedTransactionHistory(paramsObj)); //dispatch the imei based get transActionList
  store.dispatch({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: true });
  store.dispatch(ImeinumApiCallAction(imeiNumber)); //dispatch the imei number action
  store.dispatch({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER", imeiNumLoader: true });
};

const imageUploadArrayGet = (data: any = {}) => {
  const { passSalesReturnData: { reasonforSalesReturn = "" } = {} } = data;
  const { passRefunData: { refundImages = [] } = {} } = data;
  const { passDoaDetails: { doaimagRenderArry = [] } = {} } = data;
  const { replacePassdata: { relaceimagRenderArry = [] } = {} } = data;
  switch (convertToLowerCase(reasonforSalesReturn)) {
    case "replacement":
      return relaceimagRenderArry;
    case "refund":
      return refundImages;
    default:
      return doaimagRenderArry;
  }
};

/**
 * @param  {object} imeiNumber
 * loader enable
 * dispatch salesReturn information
 */
export const updatesalesReturnData = (data: any = {}) => {
  const { passSalesReturnData: { reasonforSalesReturn = "" } = {} } = data;
  const uploadImage = imageUploadArrayGet(data);
  if (uploadImage.length === 0 && reasonforSalesReturn) {
    failToast("Attach image is a mandatory field.");
    return;
  }
  const fileUploadSize = uploadFileSizeCheck(uploadImage);
  if (fileUploadSize) {
    store.dispatch({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: true }); //loader
    store.dispatch(updateSalesReturnDataAction(data)); //dispatch salesReturn information
  } else {
    failToast("Please upload file blow 30MB");
  }
};

const problemHandleCommon = (arrayData = []) => {
  return arrayData
    .map((ele: any) => {
      if (ele.isSelect === true) {
        return ele.optionData;
      }
      return undefined;
    })
    .filter((ele: any) => ele !== undefined);
};

/**
 * afterMyCall open and Edit
 */
export const updatesalesreturnDataViaEdit = (data: any = {}) => {
  const fileUploadSize = uploadFileSizeCheck(imageUploadArrayGet(data));
  if (fileUploadSize) {
    const { originalElement: { _id = "", mobileNo = "", customerName = "" } = {} } = data;

    const {
      enquireFor = {},
      passDataGenralData = {},
      passDoaDetails = {},
      passImeiNumber = {},
      passRefunData = {},
      passSalesReturnData = {},
      replacePassdata = {},
      srSubCategory = {}
    } = data;

    const { imeiNumber = "" } = passImeiNumber;
    const { remarkData = "" } = enquireFor;

    const { srSubCategory: srSubCategoryValue = "" } = srSubCategory;

    const {
      billDate = "",
      billNo = "",
      branchName = "",
      cusName = "",
      invoiceAmount = "",
      itemBrandName = "",
      itemGrupName = "",
      paymentMode = "",
      itemName = "",
      mobileNumber = "",
      itemCode = ""
    } = passDataGenralData;
    //salesReurn data
    const {
      srnValue = "",
      ageOfDevice = "",
      selectedProblem = [],
      problemDescription = "",
      subselectedProblem = [],
      reasonforSalesReturn = ""
    } = passSalesReturnData;

    //DOA data
    const {
      doaJobSheetDate = "",
      doaServiceCenter = "",
      doaimagRenderArry = [],
      doaCertificateDate = "",
      doaCertificateImei = "",
      doaCertificateNumber = ""
    } = passDoaDetails;

    const {
      relaceEmpId = "",
      relaceEmpName = "",
      relaceProduct = "",
      relaceDepartment = "",
      relaceDesignation = "",
      relaceSellingPrice = "",
      relaceimagRenderArry = []
    } = replacePassdata;

    const {
      refundDepatmet = "",
      refundDesignation = "",
      refundEmpId = "",
      refundEmpName = "",
      refundImages = [],
      refundRequestAmt = ""
    } = passRefunData;

    const problem = problemHandleCommon(selectedProblem);

    const subProblem = problemHandleCommon(subselectedProblem);

    const formData = new FormData();
    const imageArray = [...relaceimagRenderArry, ...doaimagRenderArry, ...refundImages];

    if (imageArray.length > 0) {
      for (const file of imageArray) {
        formData.append("commonImgFile", file.file); // appending every file to formdata
      }
    }

    const reasonReturn: any = reasonforSalesReturnDrop.find((ele: any) => ele.optionData === reasonforSalesReturn);
    const returnId = reasonReturn.id || "";
    const submitData = {
      billNo: billNo,
      imei: imeiNumber,
      problem: problem,
      mobileNo: mobileNo,
      itemCode: itemCode,
      billDate: billDate,
      itemName: itemName,
      srnValue: srnValue,
      remarks: remarkData,
      deviceAge: ageOfDevice,
      branchName: branchName,
      paymentMode: paymentMode,
      imeiCustomerName: cusName,
      customerName: customerName,
      imeiMobileNo: mobileNumber,
      enquiryFor: "Sales Returns",
      itemGroupName: itemGrupName,
      problemCategory: subProblem,
      itemBrandName: itemBrandName,
      invoiceAmount: invoiceAmount,
      subCategory: srSubCategoryValue,
      problemDesc: problemDescription,
      reasonForSalesReturn: {
        reason: reasonforSalesReturn,
        doaCertificateDate: returnId === 1 ? doaCertificateDate : "",
        doaCertificateNo: returnId === 1 ? doaCertificateNumber : "",
        doaJobSheetDate: returnId === 1 ? doaJobSheetDate : "",
        doaServiceCenter: returnId === 1 ? doaServiceCenter : "",
        doaIMEINo: returnId === 1 ? doaCertificateImei : "",

        replacedProduct: returnId === 2 ? relaceProduct : "",
        replacedSellPrice: returnId === 2 ? relaceSellingPrice : "",
        employeeId: returnId === 2 ? relaceEmpId : returnId === 3 ? refundEmpId : "",
        employeeName: returnId === 2 ? relaceEmpName : returnId === 3 ? refundEmpName : "",
        employeeDept: returnId === 2 ? relaceDepartment : returnId === 3 ? refundDepatmet : "",
        employeeDesignation: returnId === 2 ? relaceDesignation : returnId === 3 ? refundDesignation : "",
        refundReqAmt: returnId === 3 ? refundRequestAmt : ""
      },
      isQuery: "t"
    };
    const queryString = encodeGetParams(submitData);
    return Put(`${crmApiBaseUrl}${salesReturn.slaseRetunData}/${_id}?${queryString}`, formData, "crmToken");
  } else {
    failToast("Please upload file blow 30MB");
  }
};

/**
 * sales return done remove all details
 */
export const removeSalesreturnAutoFill = () => {
  store.dispatch({ type: "SALES_RETURN_INITIAL_STAGE" }); //empty sales return
  store.dispatch({ type: "SALES_RETURN_DATA_SUCCESS_CODE", salesReturnSuccessCode: false }); //sucess code update
};

export const ageOfDeviceCalculation = (start = "") => {
  const current = moment().startOf("day");
  try {
    return moment.duration(current.diff(start.toString())).asDays();
  } catch {
    return "";
  }
};

/**
 * loader enable
 * get services center list
 */
export const getServiceCenterList = () => {
  store.dispatch({ type: "SERVICE_CENTER_GET_LOADER", servicesCenterLoader: true }); //remove sales return autofill
  store.dispatch(servicesCenterAction()); //get services center list
};

/**
 * loader enable
 * get services center list
 */
export const getServiceCenterToken = () => {
  store.dispatch({ type: "SERVICE_CENTER_TOKEN_GET_LOADER", servicesCenterTokenLoader: true }); //remove sales return autofill
  store.dispatch(servicesCenterTokenAction()); //get services center list
};

/**
 * loader enable
 * update status api call
 */
export const updateSalesRetunStatusApi = (data = {}) => {
  store.dispatch({ type: "SALES_RETURN_STATUS_UPDATE_LOADER", salesReturnSatusUpdateLoader: true }); //remove sales return autofill
  store.dispatch(updateServiceStatusApiAction(data)); //update sales retun status
};

/**
 * @param  {string} convertLowerCase=""
 * check value is approval request
 */
export const checkSRNValue = (convertLowerCase = "") => {
  return convertLowerCase !== "approval request" ? true : false;
};

/**
 * @param  {string} convertLowerCase=""
 * check value is srn
 */
export const checkSRNorNot = (value = "") => {
  const convertLowerCase = value.toLowerCase();
  return convertLowerCase !== "srn" ? false : true;
};

/**
 * replacement and refund
 * get employee details
 */
export const getEmpDetailsForReplaceMent = (userEmpId, isRegister = false) => {
  const loaderType = isRegister ? "REG_GET_ACTUAL_EMPLOYEE_DETAILS_LOADER" : "GET_EMP_ID_BASED_GET_DETAIL_LOADER";
  const loaderVal = isRegister ? "getEmpDetailsLoader" : "getEmpIdBasedEmpDetailLoader";
  const newObj = {
    empId: userEmpId,
    isRegister,
    loaderType,
    loaderVal
  };
  store.dispatch(getEmpleeDetailsBasedEmpId(newObj)); //action created
  store.dispatch({ type: loaderType, [loaderVal]: true });
};

/**
 * user history based pass invoice and Date based get imei number
 */
export const getImeiAndTransactionDetails = (userTransHistory: any = {}) => {
  const { INVOICE_DATE = "", INVOICE_NO = "" } = userTransHistory;
  const newObj = {
    inVoiceNo: INVOICE_NO,
    inVoiceDate: INVOICE_DATE
  };
  store.dispatch(getUserDetailsBasedOnInvoiceAndDate(newObj)); //action created
  store.dispatch({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_LOADER", invoiceBasedDetailLoader: true });
};

export const removeIMEITableData = () => {
  store.dispatch({
    type: "REMOVE_USER_DETAILS_BASED_INVOICE_DATE_DETAILS",
    invoiceBasedDetails: []
  });
};

export const filterImeiAndTransactionDetails = ({ INVOICE_NO = "" }) => {
  store.dispatch({
    type: "FILTER_USER_IMEI_AND_DETAIL_BASED_INVOICE",
    inVoiceNo: INVOICE_NO
  });
};

export const findWithOutCommaData = (userDetails = []) => {
  return userDetails
    .map((ele: any, i: number) => {
      if (ele.ITEM_SERIAL_NOS.indexOf(",") === -1) {
        return {
          ...ele,
          id: i + 1
        };
      }
      return undefined;
    })
    .filter((element: any) => element !== undefined); //newObject constrt and id is added
};

export const findWithCommaData = (userDetails = []) => {
  const newArray = userDetails
    .map((ele: any, i: number) => {
      if (ele.ITEM_SERIAL_NOS.indexOf(",") !== -1) {
        const match = ele.ITEM_SERIAL_NOS.split(",");
        return match.map((eleImei: any, num: number) => {
          return {
            ...ele,
            id: num + i,
            ITEM_SERIAL_NOS: eleImei
          };
        });
      }
      return undefined;
    })
    .filter((element: any) => element !== undefined);
  return _flattenDeep(newArray);
};

export const stateBasedMultiCheckSRN = [
  { isCheck: false, _id: 1, itemName: "Tamil Nadu" },
  { isCheck: false, _id: 2, itemName: "Karnataka" },
  { isCheck: false, _id: 3, itemName: "Pondicherry" },
  { isCheck: false, _id: 4, itemName: "Maharashtra" },
  { isCheck: false, _id: 5, itemName: "Kerala" }
];

export const statusMultiCheckSRN: any = [
  { isCheck: true, _id: 1, itemName: "Open" },
  { isCheck: true, _id: 2, itemName: "Pending" },
  { isCheck: false, _id: 3, itemName: "Closed" }
];

export const subCategorydropPropsSRN = [
  { isCheck: false, _id: 1, itemName: "SRN" },
  { isCheck: false, _id: 2, itemName: "Approval Request" }
];
