import store from "store";
import { Get } from "ApiProcess/httpRestServices";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { crmApiBaseUrl, salesProduct } from "ApiProcess/httpRestServices/apiManage";

export const removeMoveToPageEnquriePage = () => {
  const storeData: any = store.getState();
  const { basicInfoReducer: { moveToEnqirePage = null } = {} } = storeData;
  if (moveToEnqirePage) {
    //from basic info next button condition is true so remove true
    store.dispatch({ type: "MOVE_TO_ENQUIRE_PAGE", moveToEnqirePage: null }); //remove sales return autofill
  }
};

/**
 * pass ticket id
 * @param ticketId string
 * @returns object
 */
export const ticketIdBasedGetAllInfo = async (ele: any = {}) => {
  const { ticketId = "" } = ele;
  const response = await Get(`${crmApiBaseUrl}${salesProduct.ticketId}/${ticketId}`, "crmToken");
  const { data: { status = 0, data = {}, message = "" } = {} } = response;
  if (status === 200) {
    return data;
  } else {
    failToast(message);
    return {};
  }
};
