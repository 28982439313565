import { _get } from "utils/lodashConst";
import { getSalesTableDataList, statusUpdateSalesApiCall } from "../commonSalesProduct";

/**
 * add new key value pair
 * for dropDown check and unCheck
 * @param salesArray array
 */
export const brandCheckBoxNewArrayConstaract = (salesArray = []) => {
  return salesArray
    .map((ele: any) => {
      //add new key value pair
      if (_get(ele, "itemBrandName", "")) {
        return {
          isCheck: false,
          _id: _get(ele, "_id", ""),
          itemName: _get(ele, "itemBrandName", "")
        };
      } else {
        return {
          isCheck: false,
          _id: _get(ele, "_id", ""),
          itemName: _get(ele, "outdated", "")
        };
      }
    })
    .filter((ele: any) => _get(ele, "itemName", ""));
};

const filterCommon = (arrayData = []) => {
  return arrayData
    .map((ele: any) => {
      if (_get(ele, "isCheck", false) === true) {
        return _get(ele, "itemName", undefined);
      }
      return undefined;
    })
    .filter((ele: any) => ele !== undefined);
};

export const getOtherFilters = (
  brandFilterArray: any,
  apxFilterArray: any,
  roleSearch: any,
  languageArray: any,
  userSearch: any
) => {
  const brandCheck = filterCommon(brandFilterArray);

  const apxCheck = filterCommon(apxFilterArray);

  const roleData = filterCommon(roleSearch);

  const filterCheckLanguage = filterCommon(languageArray);

  return {
    ...userSearch,
    crmDesignation: _get(roleData, "length", 0) > 0 ? roleData : "",
    apxProductName: _get(apxCheck, "length", 0) > 0 ? apxCheck : "",
    itemBrandName: _get(brandCheck, "length", 0) > 0 ? brandCheck : "",
    language: _get(filterCheckLanguage, "length", 0) > 0 ? filterCheckLanguage : ""
  };
};

/**
 * @param languageArray language array
 * @param brandFilterArray brand array
 * @param userSearch user state
 */
export const multiSearchApiCall = async (userSearch: any = {}) => {
  // const newObjData = getOtherFilters(brandFilterArray, apxFilterArray, roleSearch, languageArray, userSearch);
  return (await getSalesTableDataList(userSearch)) || {};
};

/**
 * fetch to update apx produt onclik based updated name
 */
export const updateApxDetailswhenApxUpdateFail = async (apxProName = "", customerData = {}) => {
  const newObj = {
    isQuery: "t",
    isUpdateAPX: "1",
    apxProductName: "" + apxProName === "0" ? "z-apx-no-value" : "" + apxProName,
    customerId: _get(customerData, "_id", ""),
    // status: _get(customerData, "status", ""),
    // callType: _get(customerData, "callType", false),
    enquiryFor: "Sales",
    subCategory: _get(customerData, "subCategory", "")
    // currentStatus: _get(customerData, "currentStatus", ""),
    // statusRemarks: _get(customerData, "statusRemarks", "")
  };
  return await statusUpdateSalesApiCall(newObj);
};
