import React from "react";
import { nullToObject } from "utils/validation";
import CommonTable from "Component/CommonTable";
import { bg_EnquiryStatusHistory } from "utils/ClassContent";
import EnquiryStatusTrackingData from "./EnquiryStatusTrackingData";

// column view and get status
const EnquiryStatusTrackingTable = (props: any = {}) => {
  const { transactions = [], columnView = false, historyTab = false } = nullToObject(props);

  return (
    <div
      id="EnquiryStatusHistory"
      className={`w-full flex transition flex-wrap mb-4 ${
        historyTab && columnView ? "h-0 overflow-hidden transition ease-linear" : "h-auto"
      } `}
    >
      <CommonTable
        enableModal={3}
        setShow={true}
        parentClass={"w-full"}
        heading={"Enquiry Status History"}
        customClass={bg_EnquiryStatusHistory}
      >
        <EnquiryStatusTrackingData columnView={columnView} transactions={transactions} />
      </CommonTable>
    </div>
  );
};

export default EnquiryStatusTrackingTable;
