import React from "react";

const ConvertSRNbutton = (props: any = {}) => {
  const {
    handleSendToSRN = () => {} //click
  } = props;

  return (
    <div className="px-4">
      <button
        type="button"
        onClick={() => handleSendToSRN(true)}
        className={`flex justify-center items-center w-60 bg-green-600 hover:bg-transparent border border-green-500 text-white
                            hover:text-green-600 mt-6 rounded p-3 h-9`}
      >
        <span>Convert To SRN</span>
      </button>
    </div>
  );
};
export default ConvertSRNbutton;
