import React from "react";
import { nullToObject } from "utils/validation";
import { XIcon, SearchIcon } from "@heroicons/react/outline";

const CommonDropDownInput = (props: any = {}) => {
  const {
    name = "",
    value = "",
    autoFocus = false,
    handleSearchfilter = () => {},
    handleClearOptionSearch = () => {}
  } = nullToObject(props);

  return (
    <div className="py-2 px-3 relative">
      <input
        type="text"
        name={name}
        value={value}
        autoFocus={autoFocus}
        id={`search-${name}`}
        data-jest-id={"handleSearchFilter"}
        onChange={handleSearchfilter}
        className={` block w-full rounded input-common pl-3 pr-7 py-2 text-xs h-7`}
      />

      {value ? (
        <button
          type="button"
          data-jest-id={"jestHandleClearOptionSearch"}
          onClick={() => handleClearOptionSearch(`search-${name}`)}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 flex justify-center items-center"
        >
          <XIcon className="w-4 h-4 text-gray-500 hover:text-red-500 cursor-pointer" />
        </button>
      ) : (
        <div className="absolute z-10 right-4 top-1/2 transform -translate-y-1/2 flex justify-center items-center">
          <SearchIcon className="w-4 h-4 text-gray-400" />
        </div>
      )}
    </div>
  );
};

export default CommonDropDownInput;
