import React, { Fragment } from "react";
import { _get } from "utils/lodashConst";
import { basicInforProps } from "../../types";
import { StyleForminput } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import CommonTextarea from "Component/Form/CommonTextarea";
import { numOnlyAllow } from "utils/validation";
import { staticData } from "ApiProcess/httpRestServices/apiManage";

const BasicInfoImei = (props: basicInforProps) => {
  const {
    showTitle = true,
    title = "",
    objectKey = "",
    inputStyle = "",
    enableViewMode = false,
    handelChange = () => {},
    basicInfo = { name: "", email: "", address: "", mobile: "" },
    errorObj = { name: false, email: false, address: false, mobile: false }
  } = props;

  /**
   * @param  {object} event
   * handle form data change
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    handelChange({ name: name, value: value }, objectKey.value, objectKey.key);
  };

  return (
    <Fragment>
      {showTitle && (
        <div className="flex mb-4 ml-4 w-full">
          <h4 className="text-sm">{title}</h4>
        </div>
      )}

      <CommonInput
        mustField
        inputError={errorObj.name}
        onChange={onChange}
        value={basicInfo.name}
        name={"name"}
        inputFieldClass="h-9"
        enableViewMode={enableViewMode}
        labelText={"Name"}
        parentClass={inputStyle || StyleForminput()}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
        placeholder={"Name"}
      />
      <CommonInput
        name={"mobile"}
        maxLength={staticData.MobileNo}
        onChange={onChange}
        labelText={"Mobile"}
        inputFieldClass="h-9"
        placeholder={"Mobile"}
        value={basicInfo.mobile}
        labelTextClass={"font-bold"}
        inputError={errorObj.mobile}
        readOnly={enableViewMode}
        enableViewMode={enableViewMode}
        parentClass={inputStyle || StyleForminput()}
        onKeyPress={numOnlyAllow}
      />
      <CommonInput
        inputError={errorObj.email}
        onChange={onChange}
        readOnly={enableViewMode}
        value={basicInfo.email}
        enableViewMode={enableViewMode}
        name={"email"}
        inputFieldClass="h-9"
        labelText={"Email"}
        parentClass={inputStyle || StyleForminput()}
        labelTextClass={"font-bold"}
        placeholder={"Email"}
      />
      <CommonTextarea
        setShow={true}
        inputError={errorObj.address}
        readOnly={enableViewMode}
        onChange={onChange}
        value={basicInfo.address}
        name={"address"}
        // inputFieldClass="h-9"
        labelText={"Address"}
        parentClass={inputStyle || "mb-2 w-full lg:w-1/2 mt-2 px-4"}
        labelTextClass={"font-bold"}
        placeholder={"Address"}
      />
    </Fragment>
  );
};

export default BasicInfoImei;
