import { nullToObject } from "utils/validation";
import { Delete, Get, Post } from "ApiProcess/httpRestServices";
import {
  crmApiBaseUrl,
  encodeGetParams,
  serviceCenterProdBasePath,
  servicesCenterList
} from "../../ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * services center list token
 */
export const getservicesCenterTokenServices = () => {
  return Get(`${serviceCenterProdBasePath}${servicesCenterList.servicesToken}`, "NoToken"); //
};

/**
 * @param  {object} obj;
 * services center list
 */
export const getservicesCenterListServices = () => {
  return Get(`${crmApiBaseUrl}${servicesCenterList.serviceCenterList}?crmPage=1`, "crmServiceCenter");
};

/**
 * @param  {object} obj;
 * services center list
 */
export const getserviceCenterListServices = (obj: any = {}) => {
  const { data = {} } = obj;
  const queryString = encodeGetParams({ ...data, crmPage: 1 });
  return Get(`${crmApiBaseUrl}${servicesCenterList.serviceCenterListCrm}?${queryString}`, "crmToken");
};

/**
 * upload services center list data
 */

export const uploadServiceCenterListData = (obj: any = {}) => {
  const queryString = encodeGetParams(obj);
  return Post(`${crmApiBaseUrl}${servicesCenterList.uploadserviceCenterListCrm}?${queryString}`, {}, "crmToken");
};

/**
 * upload services center list data
 */

export const uploadServiceCenterData = (obj: any = {}) => {
  return Post(`${crmApiBaseUrl}${servicesCenterList.uploadserviceCenterList}`, obj, "crmToken");
};

/**
 * services center list
 */
export const getAllServiceCenterListServices = () => {
  return Get(`${crmApiBaseUrl}${servicesCenterList.serviceCenterAllList}`, "extToken");
};

/**
 * @param  {object} obj;
 * services center refresh
 */
export const getserviceCenterRefreshServices = () => {
  return Get(`${crmApiBaseUrl}${servicesCenterList.serviceCenterRefresh}`, "crmToken");
};

/**
 * call attachment export api
 * @param obj
 */
export const sendServiceCenterReportEmailService = (obj: any = {}) => {
  const {
    data: {
      cc = "",
      bcc = "",
      status = "",
      search = "",
      toEmail = "",
      subject = "",
      content = "",
      excelColumnFilter = {}
    } = {}
  } = nullToObject(obj);

  const newObj = {
    cc,
    bcc,
    search,
    status,
    toEmail,
    subject,
    content,
    fromEmail: "crm@poorvika.com"
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${servicesCenterList.attachEmail}?${queryString}`, excelColumnFilter, "crmToken");
};

/**
 * create service center api
 * @param obj
 */
export const serviceCenterCreateService = (obj: any = {}) => {
  const { data = {} } = obj || {};
  const {
    _id = "",
    Brand = "",
    Name = "",
    address = "",
    district = "",
    State = "",
    CityTown = "",
    PinCode = "",
    lat = "",
    lng = "",
    phone1 = "",
    phone2 = "",
    EMailId = "",
    product = [],
    status = true,
    ignoreAddressCheck = false
  } = data;
  const reqObj = {
    Brand,
    Name,
    address: ignoreAddressCheck ? "All India" : address,
    district: ignoreAddressCheck ? "All India" : district,
    State: ignoreAddressCheck ? "All India" : State,
    CityTown: ignoreAddressCheck ? "All India" : CityTown,
    PinCode: ignoreAddressCheck ? "All India" : PinCode,
    lat,
    lng,
    phone1,
    phone2,
    EMailId,
    product: typeof product === "string" ? JSON.stringify([product]) : JSON.stringify(product),
    status,
    isQuery: "t",
    ignoreAddressCheck
  };
  const queryString = encodeGetParams(reqObj);
  return _id
    ? Post(`${crmApiBaseUrl}${servicesCenterList.update}/${_id}?${queryString}`, {}, "crmToken")
    : Post(`${crmApiBaseUrl}${servicesCenterList.create}?${queryString}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * services center delete
 */
export const deleteServiceCenterServices = (id: any = "") => {
  const { data = "" } = id;
  return Delete(`${crmApiBaseUrl}${servicesCenterList.delete}/${data}?`, "crmToken");
};
