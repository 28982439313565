import React, { Fragment, useEffect, useState } from "react";
import PageAnimate from "Component/PageAnimate";
import { TableClass } from "utils/ClassContent";
import EvaluatorReportsListBody from "./EvaluatorReportsListBody";
import EvaluatorReportsListHeader from "./EvaluatorReportsListHeader";
import ModalFixed from "Component/Modal/ModalFixed";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import EvaluatorComments from "./EvaluatorComments";
import EvaluatorMembers from "./EvaluatorMembers";

const EvaluatorReportsList = (props: any = {}) => {
  const { data = [] } = props;
  const [viewComment, setViewComment] = useState(false);
  const [dataList, setDataList] = useState(data);
  const [selectedEvaluator, setSelectedEvaluator] = useState(false);

  const handleViewComment = (state: any = {}) => {
    setViewComment(state);
  };

  const handleSort = (state: any, key = "") => {
    const tempData = [...dataList];
    const gtSort = (a: any, b: any) => (a[key] > b[key] ? 1 : -1);
    const ltSort = (a: any, b: any) => (a[key] < b[key] ? 1 : -1);
    tempData.sort(state ? gtSort : ltSort);
    setDataList([...tempData]);
  };

  const sortByDate = (obj: any = []) => {
    return obj.sort(
      (objA: any = {}, objB: any = {}) =>
        Number(new Date(objB.createdBy?.date)) - Number(new Date(objA.createdBy?.date))
    );
  };

  const handleEvaluatorMember = (evaluator: any = {}) => {
    setSelectedEvaluator(evaluator);
  };

  useEffect(() => {
    setDataList(data);
  }, [data]);

  return (
    <Fragment>
      <PageAnimate>
        <div className="flex flex-col w-full h-auto">
          <div style={{ minHeight: "400px" }} className="block w-full overflow-x-auto relative ">
            <table id="UserDetailsTable" className={TableClass}>
              <EvaluatorReportsListHeader handleSort={handleSort} />
              <EvaluatorReportsListBody
                data={dataList}
                handleViewComment={(comment: any) => handleViewComment(comment)}
                handleEvaluatorMember={(evaluator: any) => handleEvaluatorMember(evaluator)}
              />
            </table>
          </div>
        </div>
      </PageAnimate>
      {viewComment && (
        <ModalFixed>
          <FixedPopupTemplate handleClosePopup={() => handleViewComment(false)} textHeaderHeading="Overall Comments">
            <PageAnimate>
              <EvaluatorComments comment={sortByDate(viewComment)} />
            </PageAnimate>
          </FixedPopupTemplate>
        </ModalFixed>
      )}
      {selectedEvaluator && (
        <ModalFixed>
          <FixedPopupTemplate handleClosePopup={() => handleEvaluatorMember(false)} textHeaderHeading="Team Members">
            <PageAnimate>
              <EvaluatorMembers evaluator={selectedEvaluator} />
            </PageAnimate>
          </FixedPopupTemplate>
        </ModalFixed>
      )}
    </Fragment>
  );
};

export default EvaluatorReportsList;
