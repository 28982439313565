import LazyImage from "Component/LazyImage";
import React, { useEffect, useState } from "react";
import { convertToLowerCase } from "utils/validation";
import { _isEmpty, _isEqual } from "utils/lodashConst";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { getProductPrice, toLocaleNumber } from "./ProductOffers/EffectivePriceView/OfferCommon";

interface OffersProductDetailsProps {
  product: any;
  getSelectedProduct: any;
  channelType: any;
}

const OffersProductDetails = (props: OffersProductDetailsProps) => {
  const { product, getSelectedProduct, channelType } = props;

  const [image, setImage] = useState<string>("");
  const [mrpPrice, setMrpPrice] = useState<number>(0);
  const [actualPrice, setActualPrice] = useState<number>(0);

  const handleChangeVariant = (key: string, value: string) => {
    const variant = product?.variant?.attr;
    variant[key] = value;
    const selectedVariant = product.model.variants.find((item: any) => _isEqual(item.attr, variant));
    if (!_isEmpty(selectedVariant)) {
      getSelectedProduct(selectedVariant.erp_item_code);
    } else {
      failToast("Variant not found on Pim");
    }
  };

  useEffect(() => {
    try {
      setImage(product.gallery[0].media.url);
      const { sp, mrp } = getProductPrice(product);
      setMrpPrice(mrp);
      setActualPrice(sp);
    } catch (error) {
      setImage("");
    }
  }, [product]);

  return (
    <>
      {" "}
      <div className="flex flex-wrap mt-4 px-4 py-2 w-full h-auto">
        <div className={`inline-flex flex-row my-1 pl-4 lg:pl-0 pr-4 border-r sm:w-1/2 w-full `}>
          <div className="flex">
            <LazyImage src={image} className=" h-auto w-20 sm:w-40 object-contain" alt={product?.name} />
          </div>
          <div className="flex flex-col">
            {/* product name */}
            <div className="font-bold text-sm">{product?.name}</div>

            {/* product code */}
            <div className="text-sm pt-2 flex flex-col">
              <div className="flex mb-1">
                <span className="mr-1">Product Code: </span>
                <strong>{product?.erp_item_code}</strong>
              </div>

              {/* item code */}
              <div className="flex mb-1">
                <span className="mr-1">Item Code: </span>
                <strong>{product?.erp_item_code}</strong>
              </div>
            </div>

            {/* original price */}
            <div className="text-sm">
              {/* showroom price */}
              <div className="flex flex-col ">
                <div className="flex mb-1">
                  <span className="mr-1">MRP :</span>
                  <strong>{toLocaleNumber(mrpPrice)}</strong>
                </div>
                {convertToLowerCase(channelType) === "shop" ? (
                  <div className="flex mb-1">
                    <span className="mr-1">Shop:</span>
                    <strong>{toLocaleNumber(actualPrice)}</strong>
                    {/* <strong>{paisaToAmount(showroomPrice + "00")}</strong> */}
                  </div>
                ) : (
                  <div className="flex">
                    <span className="mr-1">Online (₹) : </span>
                    <strong>
                      {toLocaleNumber(actualPrice)}

                      {/* {_get(productDetails, "special_formatted", "")} */}
                    </strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="inline-flex flex-col my-1 px-4 border-r w-full sm:w-1/2">
          {product?.model?.variant_attr_scheme?.map((item: any, i = 0) => {
            return (
              <div key={convertToLowerCase(i + "product?.model?-tendk")}>
                <div className="variant-title">
                  <h3>{item.name}</h3>
                </div>
                <div className="flex flex-wrap">
                  {item.values.map((attr: any, index: any) => {
                    return (
                      <button
                        type="button"
                        key={convertToLowerCase(index + "displayStorage-efd")}
                        onClick={() => handleChangeVariant(item.code, attr.code)}
                        data-jest-id={"jestHandleChangeVariant"}
                        className={`border border-yellow-500 active:bg-indigo-600 text-xs font-bold px-3 py-1 rounded outline-none focus:outline-none
                         mr-2 mb-2 mt-1 ease-linear transition-all duration-150 ${
                           product?.variant?.attr[item.code] === attr.code
                             ? " bg-primary-gradient text-white"
                             : "text-yellow-600"
                         } `}
                      >
                        {attr.name}
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OffersProductDetails;
