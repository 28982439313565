import { TableClass, tdClass, thClass } from "utils/ClassContent";
import { _get } from "utils/lodashConst";
import { convertNumberToDate, convertToLowerCase, nullToObject } from "utils/validation";

type SalesProductDetailsTableProps = {
  customerData?: any;
};

const SalesProductDetailsTable = (props: SalesProductDetailsTableProps) => {
  const { customerData = {} } = props;
  const { status: customerDatastatus = "" } = customerData;

  const isClosed = convertToLowerCase(customerDatastatus) === convertToLowerCase("Closed") ? true : false;

  const getColumnsHeader = (data: any = []) => (
    <thead>
      <tr className="border">
        {data.map((header: any, index: any) => (
          <th key={convertToLowerCase("heder" + index)} className={thClass}>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );

  return (
    <>
      {isClosed && Array.isArray(customerData.feedback) && (customerData.feedback || [])?.length > 0 && (
        <div className="flex flex-col mb-5 w-full mt-2">
          <div className="flex justify-between items-center bg-yellow-500 w-full px-4 py-2 cursor-pointer">
            <h3 className="w-full text-sm font-bold  text-white">Product details</h3>
          </div>
          <div className="form-group relative mb-1 w-full mt-1 px-4">
            <table className={""}>
              {getColumnsHeader([
                "Customer Name",
                "Item Code",
                "Item Name",
                "Item Qty",
                "Item Serial No",
                "Invoice No",
                "Invoice Date",
                "Invoice Value"
              ])}
              <tbody>
                {(_get(customerData, "feedback", []) || []).map((ele: any, i) => {
                  const invoiceDate = _get(ele, "INVOICE_DATE", "");
                  return (
                    <tr key={convertToLowerCase(i) + "feedback"}>
                      <td className={tdClass}>{_get(ele, "CUSTOMER_NAME", "")}</td>
                      <td className={tdClass}>{_get(ele, "ITEM_CODE", "")}</td>
                      <td className={tdClass}>{_get(ele, "ITEM_NAME", "")}</td>
                      <td className={tdClass}>{_get(ele, "ITEM_QTY", "")}</td>
                      <td className={tdClass}>{_get(ele, "ITEM_SERIAL_NOS", "")}</td>
                      <td className={tdClass}>{_get(ele, "INVOICE_NO", "")}</td>
                      <td className={tdClass}>{invoiceDate ? convertNumberToDate(invoiceDate) : invoiceDate}</td>
                      <td className={tdClass}>{_get(ele, "INVOICE_VALUE", "")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {isClosed &&
        !Array.isArray(customerData.feedback) &&
        Object.keys(nullToObject(_get(customerData, "feedback", {})))?.length > 0 && (
          <div className="flex flex-col mb-5 w-full mt-2">
            <div className="flex justify-between items-center bg-yellow-500 w-full px-4 py-2 cursor-pointer">
              <h3 className="w-full text-sm font-bold  text-white"> Product details </h3>
            </div>
            <div className="form-group relative mb-1 w-full lg:w-1/2 mt-1 px-4 xl:w-1/3">
              <table className={TableClass}>
                <tbody>
                  {Object.keys(nullToObject(_get(customerData, "feedback", {}))).map((ele: any) => {
                    const value = _get(customerData, `feedback.${ele}`, "");
                    return _get(customerData, `feedback.${ele}`, "") ? (
                      <tr>
                        <th
                          className=" py-2 px-4 bg-blueGray-50 text-blueGray-500 align-middle border border-solid
                              border-blueGray-100 text-xs font-semibold text-left whitespace-nowrap capitalize"
                        >
                          {ele.replaceAll("_", " ").toString().toLowerCase()}
                        </th>
                        <td className="text-xs border-l border-r border-t border-b px-4">
                          {ele === "INVOICE_DATE" && value ? convertNumberToDate(value) : value}
                        </td>
                      </tr>
                    ) : null;
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
    </>
  );
};

export default SalesProductDetailsTable;
