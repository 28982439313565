import * as cusListTypes from "../actionTypes/cusListTypes";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { updateMailByIdService } from "utils/services/mailService";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import {
  sendCustomerServiceEmailService,
  saveServiceDetails,
  updateCustomerInfoService,
  updateServiceDetails,
  updateSupportStatus,
  createDeliveryChallanService,
  sendWhatsappMessageService,
  sendGeneralInfoMailService
} from "utils/services/customerDetailsServiceServices";
import { justDialDetailsStore } from "Pages/JustDialTable/commonJustDial";

/**
 * Save customer service data
 * @param obj customer service data
 */
function* saveCustomerServiceInfo(obj: object = {}): any {
  try {
    const response = yield call(saveServiceDetails, obj);
    const {
      data: { status = 0, message = "", data: { enquiryFor = "", subCategory = "", _id = "", mailId = "" } = {} } = {}
    } = response || {};
    if (status === 201) {
      justDialDetailsStore({}); //clear just dial details
      if (mailId) {
        const data = {
          id: mailId,
          enquiryId: _id,
          enquiryFor: enquiryFor,
          subCategory: subCategory
        };
        const res = yield call(updateMailByIdService, data);
        const { data: { status: stat = 0 } = {} } = res || {};
        if (stat === 200) {
          yield all([put({ type: "SET_UPDATED_MAIL_SUCCESS_CODE", updatedMailSuccessCode: true })]);
        }
      }
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_SAVE_SERVICE_SUCCESS_CODE", successCode: true })
      ]);
      successToast(message);
    } else {
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_SAVE_SERVICE_SUCCESS_CODE", successCode: false })
      ]);
      failToast(message);
    }
  } catch (err) {
    yield all([
      put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
      put({ type: "SET_SAVE_SERVICE_SUCCESS_CODE", successCode: false })
    ]);
    failToast("Error in updating details");
  }
}

function* updateCustomerServiceInfo(obj: object = {}): any {
  try {
    const response = yield call(updateServiceDetails, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: true })
      ]);
      successToast(message);
    } else {
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false })
      ]);
      failToast(message);
    }
  } catch (err) {
    yield all([
      put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
      put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false })
    ]);
    failToast("Error in updating details");
  }
}

function* updateCustomerInfo(obj: object = {}): any {
  try {
    const response = yield call(updateCustomerInfoService, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: true })
      ]);
      successToast(message);
    } else {
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false })
      ]);
      failToast(message);
    }
  } catch (err) {
    yield all([
      put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
      put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false })
    ]);
    failToast("Error in updating details");
  }
}

function* updateSupportStatusInfo(obj: object = {}): any {
  try {
    const response = yield call(updateSupportStatus, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: true }), //sucess code update
        put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: true })
      ]);
      successToast(message);
    } else {
      yield all([
        put({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: false }), //sucess code update
        put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
        put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false })
      ]);
      failToast(message);
    }
  } catch (err) {
    yield all([
      put({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: false }), //sucess code update
      put({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: false }),
      put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false })
    ]);
    failToast("Error in updating details");
  }
}

/**
 * send email
 */
function* sendCustomerServiceEmail(obj: object = {}): any {
  try {
    const response = yield call(sendCustomerServiceEmailService, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      successToast("Email sent Successfully");
      yield all([
        put({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: true }),
        put({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: false }),
        put({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: false })
      ]);
    }
  } catch (error) {
    failToast("Error in sending email");
    yield all([
      put({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: false }),
      put({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: false })
    ]);
  }
}

/**
 * send email
 */
function* sendGeneralInfoEmail(obj: object = {}): any {
  try {
    const response = yield call(sendGeneralInfoMailService, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 200) {
      successToast(message);
      yield all([
        put({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: true }),
        put({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: false }),
        put({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: false })
      ]);
    }
  } catch (error) {
    failToast("Error in sending email");
    yield all([
      put({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: false }),
      put({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: false })
    ]);
  }
}

/**
 * send email
 */
function* createDeliveryChallan(obj: object = {}): any {
  try {
    const response = yield call(createDeliveryChallanService, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 201) {
      yield all([put({ type: "SET_DELIVERY_CHALLAN_LOADER", deliveryChallanLoader: false })]);
      successToast(message);
    } else {
      yield all([put({ type: "SET_DELIVERY_CHALLAN_LOADER", deliveryChallanLoader: false })]);
      failToast(message);
    }
    yield all([put({ type: "IS_DELIVERY_CHALLAN", isDeliveryChallanDone: true })]);
  } catch (error) {
    yield all([put({ type: "SET_DELIVERY_CHALLAN_LOADER", deliveryChallanLoader: false })]);
    failToast("Error occured in generating Delivery challan");
  }
}

function* sendWhatsappMessageSaga(obj: object = {}): any {
  try {
    const response = yield call(sendWhatsappMessageService, obj);
    const { data: { status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([put({ type: cusListTypes.WHATSAPP_MESSAGE_SENT_SUCCESSFULLY, whatsappServiceLoader: false })]);
    } else {
      yield all([put({ type: cusListTypes.WHATSAPP_MESSAGE_SENT_FAILURE, smsServiceLoader: false })]);
    }
  } catch (error) {
    yield all([put({ type: cusListTypes.WHATSAPP_MESSAGE_SENT_FAILURE, whatsappServiceLoader: false })]);
  }
}

export default function* customerServiceSaga() {
  yield takeLatest(cusListTypes.UPDATE_CUSTOMER_INFO, updateCustomerInfo);
  yield takeLatest(cusListTypes.SEND_GENERAL_INFO_MAIL, sendGeneralInfoEmail);
  yield takeLatest(cusListTypes.SAVE_SERVICE_DETAILS, saveCustomerServiceInfo);
  yield takeLatest(cusListTypes.UPDATE_SUPPORT_STATUS, updateSupportStatusInfo);
  yield takeLatest(cusListTypes.CREATE_DELIVERY_CHALLAN, createDeliveryChallan);
  yield takeLatest(cusListTypes.UPDATE_SERVICE_DETAILS, updateCustomerServiceInfo);
  yield takeLatest(cusListTypes.CUSTOMER_SERVICE_EMAIL_SEND, sendCustomerServiceEmail);
  yield takeLatest(cusListTypes.REQUEST_TO_SEND_WHATSAPP_MSG, sendWhatsappMessageSaga);
}
