import React, { useState } from "react";
import _get from "lodash/get";
// import { ImgSms } from 'assets/images';
import { linkResendTableProps } from "./types";
// import TimerButton from 'Component/TimerButton';
import { tdClassStyle2, thClass } from "utils/ClassContent";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { getDuplicateResendSMSBasedServices } from "utils/services/duplicateInvService";
import CommonCheck from "Component/Form/CommonCheck";

const DuplicateInvSmsLink = (props: linkResendTableProps) => {
  const { dupImeiServiceUpdatedData = {}, customerData = {}, getModalStatus = false } = props || {};
  const { imei = "", mobile = "", service = "", smsLink = "" } = dupImeiServiceUpdatedData;

  const { status: _status = "" } = customerData;

  const [sendType, setSendType] = useState({ sms: false, email: false, whatsapp: false });

  const resendSMS = async (serviceId: any) => {
    const data = {
      serviceID: serviceId,
      ...sendType
    };
    const resendsmsres = await getDuplicateResendSMSBasedServices(data); //Resend Call
    const { data: { status = 0, message = "" } = {} } = resendsmsres || {};
    if (status === 200) {
      successToast(message);
    } else {
      failToast(message);
    }
    setTimeout(() => {
      successToast("Ready to Resend LinK");
    }, 32000);
  };

  const onChange = (event: any = {}) => {
    const { name = "" } = _get(event, "target", {});
    setSendType({ ...sendType, [name]: !sendType[name] });
  };

  return (
    <div
      className="block w-full overflow-x-auto relative "
      style={{ minHeight: "250px", height: getModalStatus ? "250px" : "100%" }}
    >
      <table className="items-center bg-transparent w-full border-collapse ">
        <thead>
          <tr>
            <th className={thClass}>IMEI Number</th>
            <th className={thClass}>Mobile Number</th>
            <th className={thClass}>Link</th>
            <th className={thClass}>Resend Action</th>
            <th className={thClass}>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr tabIndex={1}>
            <td className={tdClassStyle2}>{imei}</td>
            <td className={tdClassStyle2}>{mobile}</td>
            <td className={tdClassStyle2}>{smsLink}</td>
            <td className={tdClassStyle2}>
              {service && _status !== "Closed" && (
                <div className="flex items-center space-x-3">
                  <div className="flex flex-col form-group relative w-full md:w-1/2 xl:w-1/4">
                    {/* <label className="block text-xs input-label mb-1 font-bold">Share via </label> */}
                    <div className="flex">
                      <CommonCheck
                        id="sms"
                        name="sms"
                        label={true}
                        labelText="SMS"
                        onChange={onChange}
                        checked={sendType.sms}
                        parentClass={"mb-4 sm:mb-4 w-full lg:w-full sm:pr-4"}
                      />
                      <CommonCheck
                        id="mail"
                        name="email"
                        label={true}
                        labelText="Mail"
                        onChange={onChange}
                        checked={sendType.email}
                        parentClass={"mb-4 sm:mb-4 w-full lg:w-full sm:px-4"}
                      />

                      <CommonCheck
                        id="whatsapp"
                        name="whatsapp"
                        label={true}
                        labelText="Whatsapp"
                        onChange={onChange}
                        checked={sendType.whatsapp}
                        parentClass={"mb-4 sm:mb-4 w-full lg:w-full"}
                      />
                    </div>
                  </div>
                </div>
              )}
            </td>
            <td>
              <button
                type="button"
                onClick={() => resendSMS(service)}
                data-jest-id={"jestResendSMS"}
                className="w-20 rounded bg-primary-gradient-hover bg-primary-gradient"
              >
                Send
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DuplicateInvSmsLink;
