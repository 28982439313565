import React, { useState } from "react";
import { CheckProblemsPropsType } from "../ServiceCallsTypes";
import CommonCheck from "Component/Form/CommonCheck";

const CheckProblems = (props: CheckProblemsPropsType = {}) => {
  const { label = "", onChecked = () => {} } = props || {};
  const [checkedValue, setCheckedValue] = useState(false);

  // On click check box , change checked status
  const onClickingCheckbox = () => {
    onChecked(label, !checkedValue);
    setCheckedValue(!checkedValue);
  };

  return (
    <CommonCheck
      id={label}
      labelText={label}
      checked={checkedValue}
      onChange={onClickingCheckbox}
      parentClass="mb-4 sm:mb-8 w-full lg:w-full mt-2 sm:px-4"
    />
  );
};

export default CheckProblems;
