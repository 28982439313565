import React, { useEffect, useState } from "react";
import LazyImage from "Component/LazyImage";
import { subMenuIcon } from "utils/ClassContent";
import { RefreshIcon } from "@heroicons/react/outline";
import { _get, _uniqBy, _has } from "utils/lodashConst";
import ReleatedProductView from "../ReleatedProductView";
import { getProductDetailsCommon } from "../../LeadHelper";
import { dateTimeDisplay, nullToObject } from "utils/validation";
import { infoToast } from "Component/ToastServices/ToastDisplay";
import { updateApxDetailswhenApxUpdateFail } from "../commonSalesEnquiry";
import OfferDetails from "../../SalesProductDetails/ProductDetail/OfferDetails";
import PinCodeBasedDeliveryType from "../../SalesProductDetails/PinCodeBasedDeliveryType";
import ProductColor from "../../SalesProductDetails/ProductDetail/ProductDetailsPage/ProductColor";
import ProductReview from "../../SalesProductDetails/ProductDetail/ProductDetailsPage/ProductReview";
import ProductStorage from "../../SalesProductDetails/ProductDetail/ProductDetailsPage/ProductStorage";
import ProductEmiAndStock from "../../SalesProductDetails/ProductDetail/ProductDetailsPage/ProductEmiAndStock";
import OnlinePriceDetailsData from "../../SalesProductDetails/ProductDetail/ProductDetailsPage/OnlinePriceDetailsData";
import OfflinePriceDetailsData from "../../SalesProductDetails/ProductDetail/ProductDetailsPage/OfflinePriceDetailsData";

const ProductRelatedTable = (props: any = {}) => {
  const { apxProduct = "", customerData = {}, columnView = false, productDetails = {} } = nullToObject(props);

  const { createdBy: { date = "" } = {} } = customerData;

  const { rating = 0, item_code = 0 } = nullToObject(productDetails);
  const PCode = _get(customerData, "productInfo.pincode", 0);
  const iCode = _get(customerData, "itemCode", 0);
  const itemCode = iCode ? iCode : item_code;
  const [priceDetails, setpriceDetails] = useState<any>({});

  const [actionPopup, setActionPopup] = useState(false);
  const [reset, setReset] = useState(false);
  let pincode = PCode;
  if (_has(customerData, "shipping_details")) {
    pincode = customerData.shipping_details.shipping_postcode || 0;
  }

  const colorVariants = _uniqBy(_get(productDetails, "variants", []), "colors");
  const filterStorageDublicate = _uniqBy(_get(productDetails, "variants", []), "storage");

  const handleEmiPopup = (state = false) => {
    setActionPopup(state);
  };

  /**
   * fetch to update apx produt onclik based updated name
   */
  const updateApxDetails = async (apxProName = "") => {
    setReset(true);
    const respose = await updateApxDetailswhenApxUpdateFail(apxProName, customerData);
    const { data: { message = "" } = {} } = nullToObject(respose);
    infoToast(message);
    setTimeout(() => {
      setReset(false);
    }, 1500);
  };

  const imgUrl = _get(productDetails, "image.url", "")
    ? _get(productDetails, "image.url", "")
    : _get(productDetails, "variantdetails.image.url", "");

  const searchCategoriesProduct = async (value: string) => {
    const response = await getProductDetailsCommon({
      shortcode: "",
      channel: "ONLINE",
      item_code: value,
      num: 6
    });
    setpriceDetails({ ...response, mode: "SHOP" }); //search dropDown purpose
  };

  useEffect(() => {
    item_code && searchCategoriesProduct(item_code);
  }, [item_code]);
  return (
    <div className="lg:px-5 flex justify-center w-full border-b mb-1 h-full">
      <div className="flex w-full h-full">
        <div className="flex flex-wrap w-full h-full">
          <div
            className={`inline-flex flex-col lg:flex-row my-1 pl-4 lg:pl-0 pr-4 border-r w-full ${
              columnView ? " lg:w-1/2 xl:w-1/2" : "lg:w-1/2 xl:w-1/4"
            }`}
          >
            <div className="flex">
              <LazyImage
                src={imgUrl}
                className="w-full h-auto sm:w-1/2  sm:h-auto sm:m-auto lg:w-24 lg:h-24 object-contain"
                alt={_get(productDetails, "name", "")}
              />
            </div>
            <div className="flex flex-col">
              {/* date */}
              <header className="flex flex-row gap-3 items-center">
                <div className="text-sm text-gray-500">Enquired on: {dateTimeDisplay(date)}</div>
              </header>

              {/* product name */}
              <div className="font-bold text-sm">{_get(productDetails, "name", "")}</div>

              {/* apx product name */}
              {apxProduct && (
                <div className="font-bold text-sm flex items-center">
                  <span className="">Apx Product:</span>
                  <span className="ml-1">{apxProduct}</span>
                  <button
                    type="button"
                    className="bg-gray-10 rounded w-6 h-6 p-1"
                    onClick={() => updateApxDetails(apxProduct)}
                  >
                    <RefreshIcon
                      className={`${subMenuIcon} ${
                        reset ? " animate-spin " : " "
                      } h-full w-full mr-2 text-red-500 transition-all duration-200`}
                    />
                  </button>
                </div>
              )}

              <div className="text-sm pt-2 flex space-x-2">
                {/* item code */}
                <div className="flex mb-1">
                  <span className="mr-1">Item Code: </span>
                  <strong>{itemCode}</strong>
                </div>
              </div>

              {/* original price */}
              <div className="text-sm">
                {/* showroom price */}
                <div className="flex flex-wrap ">
                  <OnlinePriceDetailsData productDetails={productDetails} priceDetails={priceDetails} />

                  <OfflinePriceDetailsData productDetails={productDetails} priceDetails={priceDetails} />
                </div>
              </div>
              {/* review */}
              {rating ? (
                <div className="text-sm">
                  <div className="flex flex-col">
                    <ProductReview productDetails={productDetails} />
                  </div>
                </div>
              ) : null}

              <ProductEmiAndStock actionPopup={actionPopup} handleEmiPopup={handleEmiPopup} />
            </div>
          </div>
          <div
            className={`flex flex-col px-4 lg:px-3 border-r w-full text-sm ${
              columnView ? " lg:w-1/2 xl:w-1/2" : "lg:w-1/2 xl:w-1/4"
            }`}
          >
            {/* stoage */}
            <ProductStorage
              fromPage={"viewOnly"}
              productDetails={productDetails}
              filterStorageDublicate={filterStorageDublicate}
            />

            {/* color */}
            <ProductColor fromPage={"viewOnly"} colorVariants={colorVariants} productDetails={productDetails} />
          </div>
          <div className={`flex flex-col lg:flex-row w-full ${columnView ? " " : " xl:w-2/4 "} `}>
            <div className={`${columnView ? " w-full " : " lg:w-1/2 xl:w-2/4  "} `}>
              <ReleatedProductView customerData={customerData} productDetails={productDetails} />
              <PinCodeBasedDeliveryType pincode={pincode} item_code={itemCode} />
            </div>
            <div className={`${columnView ? " w-full " : " lg:w-1/2 xl:w-2/4  "} `}>
              {/* offer details */}
              <div className="-mt-1">
                <OfferDetails productDetails={productDetails} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductRelatedTable;
