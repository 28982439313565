const setLocalTableSetting = (key, value, tableId) => {
  const TableSetting = localStorage.getItem(`Table-${tableId}`);
  const parserLocalStorage = TableSetting ? JSON.parse(TableSetting) : {};
  const constructObject = {
    ...parserLocalStorage,
    [key]: value
  };
  localStorage.setItem(`Table-${tableId}`, JSON.stringify(constructObject));
};
function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

const getLocalWebsettings = (tableId) => {
  const TableSetting = localStorage.getItem(`Table-${tableId}`);
  return TableSetting ? JSON.parse(TableSetting) : {};
};

const getIndex = (value, tableId) => {
  const colIndex = getLocalWebsettings(tableId);
  const key = getKeyByValue(colIndex, value);
  return Object.keys(colIndex).indexOf(key);
};

const addStyle = (tableId, MenuId) => {
  let styleSheet = "";
  if (document.getElementById("table-" + tableId)) {
    styleSheet = document.getElementById(`table-${tableId}`);
  } else {
    styleSheet = document.createElement("style");
  }
  const colIndex = getLocalWebsettings(tableId);
  styleSheet.setAttribute("id", "table-" + tableId);
  let style = "";
  for (const key in colIndex) {
    style += `
    #${tableId} tr th:nth-child(${colIndex[key] + 1}), #${tableId} tr td:nth-child(${colIndex[key] + 1}) {
      display:none !important;
  }
  #${MenuId} [data-check-index="${colIndex[key]}"] {
    order:2;
  }
  `;
  }
  styleSheet.innerHTML = style;
  return document.head.appendChild(styleSheet);
};

const DeleteLocalStorageCol = (index, tableId) => {
  const tableColumnObj = getLocalWebsettings(tableId);
  const col = Object.keys(tableColumnObj)[index];
  delete tableColumnObj[col];
  localStorage.setItem(`Table-${tableId}`, JSON.stringify(tableColumnObj));
};

export const TableColumnHider = (MenuId = "", tableId = "") => {
  if (MenuId !== "" && tableId !== "") {
    const menu = document && document.getElementById(MenuId);
    const table = document && document.getElementById(tableId);
    const headers = [].slice.call(table.querySelectorAll("th"));
    const cells = [].slice.call(table.querySelectorAll("th, td"));
    const numColumns = headers.length;
    const thead = table.querySelector("thead");
    const showColumn = function (index) {
      cells
        .filter(function (cell) {
          return cell.getAttribute("data-column-index") === `${index}`;
        })
        .forEach(function (cell) {
          cell.style.display = "";
          cell.setAttribute("data-shown", "true");
        });

      menu.querySelectorAll(`[type="checkbox"][disabled]`).forEach(function (checkbox) {
        checkbox.removeAttribute("disabled");
      });
    };

    const hideColumn = function (index) {
      cells.filter(function (cell) {
        return cell.getAttribute("data-column-index") === `${index}`;
      });
      // How many columns are hidden
      const numHiddenCols = headers.filter(function (th) {
        return th.getAttribute("data-shown") === "false";
      })?.length;
      if (numHiddenCols === numColumns - 1) {
        // There's only one column which isn't hidden yet
        // We don't allow user to hide it
        const shownColumnIndex = thead.querySelector('[data-shown="true"]').getAttribute("data-column-index");

        const checkbox = menu.querySelector(`[type="checkbox"][data-column-index="${shownColumnIndex}"]`);
        checkbox.setAttribute("disabled", "true");
      }
    };

    headers.forEach(function (th, index) {
      // Build the menu item
      const li = document && document.createElement("li");
      li.classList.add("flex", "items-center", "p-2", "hover:bg-gray-100", "cursor-pointer");
      li.setAttribute("data-check-index", index);
      const label = document && document.createElement("label");
      label.classList.add(
        "inline-flex",
        "items-center",
        "w-full",
        "px-2",
        "text-xs",
        "capitalize",
        "text-gray-700",
        "text-left",
        "cursor-pointer"
      );
      const div = document && document.createElement("div");
      div.classList.add("form-check", "inline-block");
      const checkbox = document.createElement("input");
      checkbox.setAttribute("type", "checkbox");
      checkbox.checked = true;
      checkbox.setAttribute("data-column-index", index);
      checkbox.classList.add("form-check-input", "mr-2");
      const text = document && document.createTextNode(th.textContent);
      div.appendChild(checkbox);
      label.appendChild(div);
      label.appendChild(text);
      li.appendChild(label);
      menu.appendChild(li);
      // Handle the event
      checkbox.addEventListener("change", function (e) {
        e.target.checked
          ? DeleteLocalStorageCol(getIndex(index, tableId), tableId)
          : setLocalTableSetting(`col-${index}`, index, tableId);
        e.target.checked ? showColumn(index) : hideColumn(index);
        menu.classList.add("container__menu--hidden");
        addStyle(tableId, MenuId);
      });
    });
  }
  setTimeout(() => {
    const colIndex = getLocalWebsettings(tableId);
    if (Object.keys(colIndex)?.length !== 0) {
      for (const key in colIndex) {
        document.querySelector(`#${MenuId} [data-column-index="${colIndex[key]}"]`).checked = false;
      }
    } else {
      const checkbox = document && document.querySelectorAll(`#${MenuId} input`);
      checkbox &&
        checkbox.forEach((item = {}) => {
          item.checked = true;
          item.disabled = false;
        });
    }
  }, 100);
};

export const CurrentStatusHighLighter = (data = "") => {
  switch (data) {
    case "New":
      return "green600";
    case "Need More Information":
      return "yellow600";
    case "Information Pending":
      return "pink600";
    case "Approved":
      return "blue700";
    case "Awaiting Status":
      return "red600";
    case "Ready To Send Service Center":
      return "indigo600";
    case "Followup":
      return "green600";
    default:
      return "yellow600";
  }
};
