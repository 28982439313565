import React, { useEffect, useState } from "react";
import "./CommonInputDatePicker.css";
import DatePicker from "react-datepicker";
import { moment } from "utils/momentConst";
import { _toLower } from "utils/lodashConst";
import { CommonDatePickerProps } from "./types";
import { XIcon } from "@heroicons/react/outline";
import "react-datepicker/dist/react-datepicker.css";
import { nullToObject, timeConverting } from "utils/validation";

const CommonDatePicker = (props: CommonDatePickerProps) => {
  const {
    value = "",
    name = "",
    readOnly = false,
    inputvalid = false,
    inputError = false,
    inputFieldClass = "",
    placeholderText = "",
    closButtonShow = false,
    enableViewMode = false,
    minDate = moment().toDate(),
    maxDate = moment().toDate(),
    handleOnchange = () => {} //click
  } = nullToObject(props);

  const [startDate, setStartDate] = useState<any>(null);

  const dateOnchange = (nameData = "", dateValue: any = {}) => {
    if (!enableViewMode) {
      handleOnchange({ target: { name: nameData, value: timeConverting(dateValue) } });
      setStartDate(dateValue);
    }
  };

  const clearDate = () => {
    if (!enableViewMode) {
      handleOnchange({ target: { name: name, value: "" } });
      setStartDate(null);
    }
  };

  const handleDateChangeRaw = (e: any = {}) => e.preventDefault();

  //value is empty mean null append
  useEffect(() => {
    if (!value) {
      setStartDate(null);
    }
  }, [value]);
  const paceHolder: any = _toLower(placeholderText) !== "invalid date" ? placeholderText : "";
  return (
    <React.Fragment>
      <DatePicker
        name={name}
        id={name}
        showYearDropdown
        showMonthDropdown
        maxDate={maxDate}
        minDate={minDate}
        selected={startDate}
        readOnly={readOnly}
        placeholderText={paceHolder}
        dateFormat={"dd/MM/yyyy"}
        onChangeRaw={handleDateChangeRaw}
        onChange={(dateEvent: any) => dateOnchange(name, dateEvent)}
        className={` ${" block w-60 rounded input-common px-3 py-1 h-8 "}
                ${inputError && !inputvalid ? " error pr-10" : "input-valid"}
                ${closButtonShow && startDate ? " pr-10" : ""}
                ${inputFieldClass ? inputFieldClass : ""}`}
      />
      {closButtonShow && startDate && (
        <span
          className="absolute inline-block right-10 top-1/2 transform -translate-y-1/2 outline-none"
          onClick={clearDate}
        >
          <XIcon className="w-4 h-4 text-gray-500 hover:text-red-500 cursor-pointer" />
        </span>
      )}
    </React.Fragment>
  );
};

export default CommonDatePicker;
