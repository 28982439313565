import React, { useEffect, useState } from "react";
import { moment } from "utils/momentConst";
import { _get, _isEmpty } from "utils/lodashConst";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import CommonInput from "Component/Form/CommonInput";
import { StyleFormTextarea } from "utils/ClassContent";
import CommonTextarea from "Component/Form/CommonTextarea";
import { emptyValidate, validateForm } from "utils/validation";
import { updateOnlineEnqStatusApiService } from "../helperOnlineEnquiry";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import CustomerCallType from "Pages/NewCall/EnquiryFor/CustomerCallType";
import EnquiryMailReply from "Pages/MailBox/ShowMailOnEnquiry/EnquiryMailReply";
import { replyMailTemplate, sendEmailToServerCall } from "Pages/MailBox/commonMailBoxFile";
import { MockOnlineOrderStatus, MockOnlineOrderStatusClosed, MockOnlineOrderStatusPending } from "Component/MockData";
import CommonCheck from "Component/Form/CommonCheck";

const initErrorInput = {
  status: false,
  callType: false,
  currentStatus: false
};

/**
 * Service call status update
 * @param props props for service call status update
 * @returns Service status update
 */
const OnlineEnqStatusUpdate = (props: any = {}) => {
  const {
    _id = "",
    enquiryFor = "",
    customerData = {},
    columnView = false,
    selectedMailData = {},
    getListApiReload = () => {}
  } = props || {};

  const [status, setStatus] = useState("");
  const [remark, setRemark] = useState("");
  const [mailInfo, setMailInfo] = useState({});
  const [isClosed, setIsClosed] = useState(false);
  const [callType, setCallType] = useState<any>(null);
  const [composedHtml, setComposedHtml] = useState("");
  const [followUpDate, setFollowUpDate] = useState("");
  const [isMailReply, setIsMailReply] = useState(false);
  const [currentStatus, setCurrentStatus] = useState("");
  const [sendAttachmants, setSendAttachmants] = useState([]);
  const [errInput, setErrorInput] = useState({ ...initErrorInput });
  const [defaultFollowUpDate, setDefaultFollowUpDate] = useState("");

  const updateOnlineEnquiryApiCall = async (payload) => {
    const response = await updateOnlineEnqStatusApiService(payload);
    const {
      data: { message = "", status: apiCode = 0 }
    } = response || {};
    if (apiCode === 200) {
      successToast(message);
      resetAllValues();
      getListApiReload();
      // send mail
      if (isMailReply) {
        if (!_isEmpty(_get(mailInfo, "to", [])) && !_isEmpty(_get(mailInfo, "from", []))) {
          sendReplyMail();
        }
      }
    } else {
      failToast(message);
    }
  };
  // Handle on submit
  const handleSubmit = () => {
    let remarks = remark;
    if (isMailReply) {
      remarks = "Sent Mail";
    }
    let newObj = {
      _id: _id,
      status: status,
      remarks: remarks,
      callType: callType,
      enquiryFor: enquiryFor,
      followUpDate: followUpDate,
      currentStatus: currentStatus
    };

    if (status === "Pending" && followUpDate === "") {
      newObj = { ...newObj, ...{ followUpDate: defaultFollowUpDate } };
    }

    if (validateForm(errInput)) {
      updateOnlineEnquiryApiCall(newObj);
    } else {
      const emptyField: any = Object.entries(errInput).find((ele) => {
        return ele[1] === false;
      });
      const unFilledData = _get(emptyField, "0", "");
      failToast(`Please fill ${unFilledData}`);
    }
  };

  // Send reply mail to enquiry mail
  const sendReplyMail = () => {
    const currentMailData = _get(selectedMailData, "mailData", {});
    const originalMailHtml = _get(currentMailData, "html", "");
    const originalMailFrom = _get(currentMailData, "from", []);
    const originalMailText = _get(currentMailData, "text", "");
    const originalMailDate = _get(currentMailData, "date", "");
    const originalMailSubject = _get(currentMailData, "subject", "");
    const originalMailContent = originalMailHtml ? originalMailHtml : originalMailText;

    const originalMailReplyFormat = replyMailTemplate(originalMailDate, originalMailFrom, originalMailContent); // gets original mail in reply format

    const sendMailContent = composedHtml + originalMailReplyFormat;

    const mailData = {
      from: _get(mailInfo, "from", []).pop(),
      cc: _get(mailInfo, "cc", []),
      bcc: _get(mailInfo, "bcc", []),
      toEmail: _get(mailInfo, "to", []),
      subject: originalMailSubject,
      content: sendMailContent,
      attachments: sendAttachmants
    };
    sendEmailToServerCall(mailData);
    resetAllValues();
  };

  const passValue = (htmlValue = "") => {
    setComposedHtml(htmlValue);
  };

  const passAttachmants = (attachmants: any = []) => {
    setSendAttachmants(attachmants);
  };

  const passMailInfo = (val: Object = {}) => {
    setMailInfo(val);
  };

  const resetAllValues = () => {
    setRemark("");
    setStatus("");
    setCallType(null);
    setFollowUpDate("");
    setCurrentStatus("");
    setIsMailReply(false);
    setErrorInput(initErrorInput);
  };

  /**
   * call type change
   * false out,true mean incoming
   */
  const callTypeHandle = (value: any = "") => {
    const error = errInput;
    error.callType = value !== null;
    setCallType(value);
  };

  /**
   * Handle field values change
   * @param event event
   */
  const onChange = (event: any = {}) => {
    const { name = "", value = "" } = _get(event, "target", {});
    switch (name) {
      case "followUpDate":
        setFollowUpDate(value);
        break;
      case "remarks":
        setRemark(value);
        break;
      default:
        break;
    }
  };

  /**
   * handle status search
   * @param event
   */
  const handleStatusSelect = (event: any = {}) => {
    const optionData = _get(event, "optionData", "");
    const error = errInput;
    error.status = emptyValidate(optionData);
    error.currentStatus = false;
    setErrorInput(error);
    setCurrentStatus("");
    setStatus(optionData);
    if (optionData !== "Pending") setFollowUpDate("");
  };

  /**
   * handle current status search
   * @param event
   */
  const handleCurrentStatusSelect = (event: any = {}) => {
    const optionData = _get(event, "optionData", "");
    const error = errInput;
    error.currentStatus = emptyValidate(optionData);
    setErrorInput(error);
    setCurrentStatus(optionData);
  };

  useEffect(() => {
    if (_get(customerData, "followUpDate", "")) {
      setDefaultFollowUpDate(_get(customerData, "followUpDate", ""));
    }
    if (_get(customerData, "status", "") === "Closed") {
      setIsClosed(true);
      setStatus(_get(customerData, "status", ""));
      setCurrentStatus(_get(customerData, "currentStatus", ""));
    }
  }, [customerData]);

  return (
    <React.Fragment>
      <CommonSearchDropdown
        label={true}
        value={status}
        setShow={true}
        name={"status"}
        mustField={true}
        labelText={"Status"}
        readOnly={isClosed}
        placeholder={"--None--"}
        handleOnChange={onChange}
        inputWithIconClass="w-10"
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        arrayData={MockOnlineOrderStatus}
        handleParentDropVal={handleStatusSelect}
        parentClass={
          columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
        }
      />
      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        readOnly={isClosed}
        value={currentStatus}
        name={"currentStatus"}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        handleOnChange={onChange}
        labelText={"Sub Status"}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        parentClass={
          columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
        }
        handleParentDropVal={handleCurrentStatusSelect}
        arrayData={status === "Pending" ? MockOnlineOrderStatusPending : MockOnlineOrderStatusClosed}
      />

      {status === "Pending" && (
        <CommonInput
          maxDate={""}
          inputError={false}
          readOnly={isClosed}
          onChange={onChange}
          inputWithIcon={true}
          value={followUpDate}
          name={"followUpDate"}
          inputFieldClass="h-9"
          enableDatePicker={true}
          isIconLabel={true}
          minDate={moment().toDate()}
          labelText={"FollowUp Date"}
          labelTextClass={"font-bold"}
          placeholderText={followUpDate}
          parentClass={
            columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
          }
        />
      )}

      {!isClosed && (
        <CustomerCallType
          inModelView={true}
          callType={callType}
          columnView={columnView}
          callTypeHandle={callTypeHandle}
        />
      )}

      {!isClosed && (
        <div className="w-full">
          {Object.keys(selectedMailData)?.length !== 0 && (
            <CommonCheck
              id="Send Reply Mail"
              labelText="Send Reply Mail"
              checked={isMailReply}
              onChange={() => {
                setIsMailReply(!isMailReply);
              }}
              parentClass={"mb-2 w-full mt-2 px-4"}
            />
          )}
        </div>
      )}

      {!isMailReply ? (
        <CommonTextarea
          setShow={true}
          value={remark}
          name={"remarks"}
          inputError={false}
          onChange={onChange}
          readOnly={isClosed}
          labelText={"Remarks"}
          placeholder={"Remarks"}
          labelTextClass={"font-bold"}
          parentClass={StyleFormTextarea()}
          inputFieldClass="h-32 max-h-64 min-h-textarea"
        />
      ) : (
        <EnquiryMailReply
          showReplyMail={true}
          passValue={passValue}
          passAttachmants={passAttachmants}
          selectedMailData={selectedMailData}
          passMailInfo={passMailInfo}
        />
      )}

      <div className="bg-white sticky bottom-0 py-5 flex flex-col items-center justify-center w-full">
        <div>
          <button
            type="button"
            hidden={isClosed}
            onClick={handleSubmit}
            data-jest-id={"jestHandleSubmitOnlineOrders"}
            className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
          >
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default OnlineEnqStatusUpdate;
