import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { ImgAvatarMale4 } from "assets/images";
import MailInfo from "../MailInformation/MailInfo";
import { _get, _isEmpty } from "utils/lodashConst";
import { ChevronDownIcon } from "@heroicons/react/outline";
import ImageUploadReturn from "Component/ImageUploadReturn";

/**
 * Shows Received mail
 * @param props props
 * @returns Received mail
 */
const EnquiryFromMail = (props: any) => {
  const { selectedMailData = {} } = props || {};

  const { mailData = {}, mailData: { from: mailFrom = [], html: mailHtml = "" } = {} } = selectedMailData || {};

  const [mailInfoDrop, setMailInfoDrop] = useState(false);
  const [mailAttachments, setMailAttachments] = useState([]);
  const [mapAttachmants, setMapAttachmants] = useState(false);

  const handleMailInfoDrop = (value = false) => {
    setMailInfoDrop(value ? value : !mailInfoDrop);
  };

  /**
   * Get all the attachments from the mail
   */
  useEffect(() => {
    if (!_isEmpty(_get(mailData, "attachments", []))) {
      const attachemtsList = _get(mailData, "attachments", []).map((file: any) => {
        const byteCharacters = atob(file?.content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        // now that we have the byte array, construct the blob from it
        const blobFile = new Blob([byteArray]);
        const generatedFile = new File([blobFile], file?.fileName, { type: file?.contentType });
        return {
          file: generatedFile,
          contentId: file.contentId,
          fileName: generatedFile.name,
          url: URL.createObjectURL(generatedFile),
          type: generatedFile.name.split(".").pop()?.toLowerCase()
        };
      });
      setMailAttachments(attachemtsList);
      setMapAttachmants(true);
    }
  }, [mailData]);

  /**
   * Replace img with proper attachments
   */
  useEffect(() => {
    if (mapAttachmants) {
      const imgTags: any = document && document.getElementsByTagName("img");
      const attachmentsToBeRemoved = [];
      for (let i = 0; i < imgTags.length; i++) {
        const srcAtt = imgTags[i].getAttribute("src");
        // const altAtt = imgTags[i].getAttribute("alt");
        if (srcAtt) {
          if (srcAtt.includes("cid:")) {
            const contentId = srcAtt.split("cid:").pop();
            const respectiveAttachmant = mailAttachments.find((attachment) => {
              return _get(attachment, "contentId", "") === contentId;
            });
            if (respectiveAttachmant) {
              const localUrl = _get(respectiveAttachmant, "url", "");
              const selectedContentId = _get(respectiveAttachmant, "contentId", "");
              if (localUrl) {
                imgTags[i].setAttribute("src", localUrl);
                attachmentsToBeRemoved.push(selectedContentId);
              }
            }
          }
        }
      }
      const attachments = [...mailAttachments];
      attachmentsToBeRemoved.forEach((contentId) => {
        const indexOfAttachment = mailAttachments.findIndex((attachment) => {
          return _get(attachment, "contentId", "") === contentId;
        });
        if (indexOfAttachment > -1) {
          attachments.splice(indexOfAttachment, 1);
        }
      });
      setMailAttachments(attachments);
      setMapAttachmants(false);
    }
  }, [mapAttachmants]);

  return (
    <div className="pt-3 px-5 overflow-hidden bg-white w-full">
      <section className="pl-5">
        <div className="flex space-x-4 items-center mb-5 w-full">
          <div className="h-8 w-8 rounded-full overflow-hidden flex-shrink-0">
            <img src={ImgAvatarMale4} alt="avatar" className="h-full w-full object-cover" />
          </div>
          <div className="inline-flex flex-col max-w-full flex-1 relative">
            <h3 className="font-semibold text-lg">
              {mailFrom
                ? mailFrom.map((eachFrom: any) => {
                    return _get(eachFrom, "name", "") ? _get(eachFrom, "name", "") : _get(eachFrom, "address", "");
                  })
                : null}
            </h3>
            <div onClick={() => handleMailInfoDrop()} className={`${mailInfoDrop ? " open " : ""} flex items-center`}>
              <p className={`${mailInfoDrop ? " open " : ""} text-light text-xs text-gray-400 leading-none`}>
                {mailFrom
                  ? mailFrom.map((eachFrom: any) => {
                      return _get(eachFrom, "address", "");
                    })
                  : null}
              </p>
              <button type="button" className={`${mailInfoDrop ? " open " : ""}`}>
                <ChevronDownIcon
                  className={`${mailInfoDrop ? " open " : ""} w-4 h-4 text-gray-400 hover:text-gray-600 ml-1`}
                />
              </button>
            </div>
            {mailInfoDrop && <MailInfo mailData={mailData} handleClose={(e: any) => handleMailInfoDrop(e)} />}
          </div>
        </div>
        <article className="text-sm mt-8 text-gray-800 leading-6 tracking-wider mail_style">
          {mailHtml
            ? parse(mailHtml)
            : _get(mailData, "text", "")
                .split("\n")
                .map((eachLine: any) => {
                  return eachLine === "" ? <br /> : <p>{eachLine}</p>;
                })}
        </article>
      </section>
      <ImageUploadReturn imagRenderArry={mailAttachments} enableViewMode={true} />
      {/* <div className='pl-5'>
                    <h4 className='bg-gray-200 py-1 pl-5 font-semibold text-gray-600'>Update history Timeline </h4>
                    <div className="mx-auto relative pl-40 pr-10 border max-h-96 overflow-y-auto">
                        {transactions.map((trans: any) => {
                            return (<div className="border-l-2 pt-5">
                                <div className="transform transition cursor-pointer relative
                             flex items-center px-6 py-4 bg-gray-100 text-gray-600 rounded flex-col md:flex-row space-y-4 md:space-y-0">
                                    <div className="w-5 h-5 bg-gray-200 absolute -left-10 transform -translate-x-2/4 rounded-full z-10 mt-2 md:mt-0"></div>
                                    <div className="w-10 h-1 bg-gray-100 absolute -left-10 z-0"></div>
                                    <div className=" absolute -left-44 text-sm text-gray-600">{moment(_get(trans, "actionBy", {})["date"]).format("MMM Do , hh:mm a")}</div>
                                    <div className="w-full flex flex-col mt-5">
                                        <header className="flex flex-row gap-3 items-center">
                                            <img src={ImgAvatarMale4} alt="avatar" className="rounded-full w-8 h-8 overflow-hidden" />
                                            <div> {_get(trans, "actionBy.name", {})} </div>
                                        </header>
                                        <div className="flex flex-wrap">
                                            <div className=" flex flex-col">
                                                <div className="pt-3">
                                                    <p>
                                                        <span className='font-bold text-sm text-gray-400'>Status {' -> '}</span>
                                                        <strong className='text-sm'>{_get(trans, "status", "")}</strong></p>
                                                    <p>
                                                        <span className='font-bold text-sm text-gray-400'>Sub Status {' -> '}</span>
                                                        <strong className='text-sm'>{_get(trans, "currentStatus", "")}</strong>
                                                    </p>
                                                </div>
                                                <div className="text-sm pt-2 inline-flex">
                                                    <span className='font-bold text-sm text-gray-400'>Remark {' -> '} </span>
                                                    <strong className='text-sm'>{_get(trans, "remarks", "")}</strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>);
                        })}
                    </div>
                </div> */}
    </div>
  );
};

export default EnquiryFromMail;
