import React, { useEffect, useState } from "react";
import CommonCheck from "./CommonCheck";
import { CommonInputProps } from "./types";
import { moment } from "utils/momentConst";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import CommonDatePicker from "./CommonDatePicker";
import { CalendarIcon } from "@heroicons/react/outline";
import { IconError, IconResponseLoader, IconSearch } from "assets/images";
import { convertToLowerCase, nullToObject, nullToString } from "utils/validation";

const parentClassUpdate = (classNameProps: any = "", optionalClass: any = "") => {
  if (classNameProps) return classNameProps;
  return optionalClass;
};

const CommonInput = (props: CommonInputProps) => {
  const {
    name = "",
    value = "",
    style = {},
    inputId = "",
    minDate = "",
    label = true,
    type = "text",
    maxLength = 0,
    children = "",
    iconTitle = "",
    autoFocus = "",
    labelText = "",
    setShow = true,
    genralInfo = {},
    inputClass = "",
    placeholder = "",
    parentClass = "",
    readOnly = false,
    mustField = false,
    onFocus = () => {},
    onBlur = () => {},
    inputvalid = false,
    inputError = false,
    inputErrorMsg = "",
    ErrorMsgClass = "",
    IconErrorClass = "",
    checkBoxVal = false,
    labelTextClass = "",
    onPaste = () => {},
    onInput = () => {},
    parentPosition = "",
    inputFieldClass = "",
    isIconLabel = false,
    onBlurChange = false,
    onChange = () => {}, //click
    placeholderText = "",
    buttonType = "button",
    onKeyPress = () => {}, //click
    inputWithIcon = false,
    closButtonShow = false,
    enableCheckBox = false,
    enableViewMode = false,
    responseLoader = false,
    inputWithIconClass = "",
    enableDatePicker = false,
    enableCloseIcon = false,
    checkCurrentData = false,
    iconClickAction = () => {},
    _handleCheckBox = () => {},
    maxDate = moment().toDate(),
    isExcludeTextNumField = false,
    handleOnchangeEvent = () => {},
    iconComponent = <IconSearch />,
    ...rest
  } = nullToObject(props);
  const { billDate = "" } = genralInfo;
  const [enableDate, setEnableDate] = useState(false);
  const [getCheckBoxVal, setCheckBoxVal] = useState(checkBoxVal);

  const handleOnchange = (event: any = {}) => {
    if (!onBlurChange) {
      //onChange not trigger
      event.preventDefault && event.preventDefault();
      const { value: aliceValue = "", name: aliceName = "" } = event.target;
      handleOnchangeEvent && handleOnchangeEvent(event);
      onChange({
        target: {
          name: aliceName,
          value: aliceValue
        }
      });
    }
  };

  const HandleDatePicker = () => setEnableDate(!enableDate);

  const onBlurHandle = (event: any = {}) => {
    if (onBlurChange) {
      event.preventDefault();
      const { value: aliceValue = "", name: aliceName = "" } = event.target;
      handleOnchangeEvent && handleOnchangeEvent(event);
      onChange({
        target: {
          name: aliceName,
          value: aliceValue
        }
      });
      onBlur(event);
    } else {
      onBlur(event);
    }
  };

  const handleSearchEmpty = () => {
    const event = { target: { name: name, value: "" } };
    handleOnchange(event);
  };

  useEffect(() => {
    if (autoFocus) {
      const inputEle = document.querySelector(`[name=${name}]`) as HTMLElement | null;
      inputEle?.focus();
    }
  }, [autoFocus]);

  useEffect(() => setCheckBoxVal(checkBoxVal), [checkBoxVal]);
  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group ${parentClassUpdate(parentPosition, "relative")} ${parentClassUpdate(parentClass, "")}`}
    >
      {/* label print */}
      {label && (
        <div className="flex">
          <label
            htmlFor=""
            className={` text-xs mb-0.5 LabelName font-bold 
            ${parentClassUpdate(labelTextClass, "")}
            ${inputError && !inputvalid ? " text-error" : ""}
            ${inputvalid && !inputError ? " text-valid" : ""}`}
          >
            {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
          </label>
          {enableCheckBox && (
            <div className="ml-2 relative -top-0.5">
              <CommonCheck
                name={name}
                label={false}
                parentClass={"mr-2"}
                checked={getCheckBoxVal}
                id={convertToLowerCase(name)}
                onClick={() => _handleCheckBox({ target: { name: name, value: getCheckBoxVal } })}
              />
            </div>
          )}
        </div>
      )}

      <div className={` ${parentClassUpdate(inputClass, "")}${parentClassUpdate(inputWithIcon, "")} relative `}>
        {enableDatePicker ? (
          enableViewMode ? (
            <input
              id={inputId}
              readOnly={enableViewMode}
              value={nullToString(value)}
              placeholder={parentClassUpdate(placeholderText, placeholder)}
              className={`block w-60 rounded input-common px-3 py-1 h-8 text-sm ${inputError && !inputvalid ? " error pr-10" : ""}${
                inputvalid && !inputError ? " input-valid" : ""
              }${parentClassUpdate(inputFieldClass, "")}`}
            />
          ) : (
            <CommonDatePicker
              name={name}
              value={value}
              maxDate={maxDate}
              minDate={minDate}
              billDate={billDate}
              readOnly={readOnly}
              onChange={() => {}}
              enableViewMode={enableViewMode}
              closButtonShow={closButtonShow}
              handleOnchange={handleOnchange}
              inputFieldClass={inputFieldClass}
              placeholderText={placeholderText}
              checkCurrentData={checkCurrentData}
            />
          )
        ) : (
          <input
            name={name}
            id={inputId}
            type={type}
            autoCorrect="off"
            onFocus={onFocus}
            spellCheck={false}
            readOnly={readOnly}
            autoComplete={"off"}
            style={{ ...style }}
            onPaste={onPaste ? onPaste : null}
            onInput={onInput ? onInput : null}
            onKeyPress={onKeyPress ? onKeyPress : null}
            placeholder={parentClassUpdate(placeholder, "")}
            maxLength={parentClassUpdate(maxLength, 10000000)}
            onBlur={onBlur ? (e) => onBlurHandle(e) : () => {}}
            value={isExcludeTextNumField ? value : nullToString(value)}
            onChange={readOnly ? () => {} : (e) => handleOnchange(e)}
            className={`block w-60 rounded input-common px-3 py-1 h-8 text-sm ${inputError && !inputvalid ? " error pr-10" : ""}
                        ${inputvalid && !inputError ? " input-valid" : ""}${inputFieldClass ? inputFieldClass : ""}`}
            {...rest}
          />
        )}

        {inputError && !inputvalid && (
          <i
            className={`inline-block  absolute top-1/2 right-3 transform -translate-y-1/2 ${IconErrorClass ? IconErrorClass : ""}`}
          >
            <IconError />
          </i>
        )}

        {inputWithIcon &&
          iconComponent &&
          (isIconLabel ? (
            <label
              className={`w-8 absolute flex justify-center items-center cursor-pointer input-icon-color-fill 
                top-px bottom-px right-px input-group-icon rounded ${parentClassUpdate(inputWithIconClass, "")}`}
              htmlFor={name}
            >
              <CalendarIcon onClick={HandleDatePicker} className="w-5 h-5" />
            </label>
          ) : (
            <button
              title={iconTitle}
              // eslint-disable-next-line react/button-has-type
              type={buttonType}
              id={`btn-${inputId}`}
              onClick={(event: any = {}) => {
                if (enableDatePicker) {
                  HandleDatePicker();
                } else if (iconClickAction) {
                  iconClickAction(event);
                }
              }}
              className={`w-8 absolute flex justify-center items-center cursor-pointer input-icon-color-fill 
                        top-px bottom-px right-px input-group-icon rounded ${parentClassUpdate(inputWithIconClass, "")}`}
            >
              {enableDatePicker ? (
                <CalendarIcon onClick={HandleDatePicker} className="w-5 h-5" />
              ) : responseLoader ? (
                <IconResponseLoader />
              ) : enableCloseIcon ? (
                value === "" ? (
                  <span title="search" className=" inline-flex w-5 h-5 justify-center items-center">
                    <IconSearch className=" w-6 h-6 " />
                  </span>
                ) : (
                  <span title="clear" className=" inline-flex w-5 h-5 justify-center items-center">
                    <XIcon onClick={() => handleSearchEmpty()} className="hover:text-red-500 w-5 h-5" />
                  </span>
                )
              ) : (
                React.cloneElement(iconComponent)
              )}
            </button>
          ))}
        {children}
      </div>

      {/* error msg */}
      {inputError && inputErrorMsg && (
        <span
          className={`absolute top-full right-4 whitespace-nowrap error-msg text-xs ${parentClassUpdate(ErrorMsgClass, "")}`}
        >
          {inputErrorMsg}
        </span>
      )}
    </Transition>
  );
};

export default CommonInput;
