import { _get } from "utils/lodashConst";
import React, { useState, useEffect } from "react";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import { ageOfDeviceCalculation } from "../commonServiceCalls";
import { CustomerBasicInfoPropsType } from "../ServiceCallsTypes";
import { alphaOnly, convertToLowerCase, getDateConvert, getDateConvertAgeDevice, numOnlyAllow } from "utils/validation";

/**
 * Show's customer basic info fields
 * @param props properties for customer basic info
 * @returns input fields to get customer info
 */
const CustomerBasicInfo = (props: CustomerBasicInfoPropsType = {}) => {
  const { customerData = {}, editView = false, passProduct = () => {}, passBasicInfo = () => {} } = props || {};

  const [imei, setImei] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [billNo, setBillNo] = useState("");
  const [product, setProduct] = useState("");
  const [cusName, setCusName] = useState("");
  const [onPass, setOnPass] = useState(false);
  const [itemCode, setItemCode] = useState("");
  const [billDate, setBillDate] = useState("");
  const [branchName, setbranchName] = useState("");
  const [enquiryFor, setEnquiryFor] = useState("");
  const [ageOfDevice, setAgeOfDevice] = useState("");
  const [rawBillDate, setRawBillDate] = useState("");
  const [subCategory, setSubcategory] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");

  const [basicInfoData, setbasicInfoData] = useState({
    billNo: "",
    itemName: "",
    itemCode: "",
    billDate: "",
    deviceAge: "",
    branchName: "",
    imeiMobileNo: "",
    invoiceAmount: "",
    itemGroupName: "",
    itemBrandName: "",
    imeiCustomerName: ""
  });

  /**
   * Update the value of input field
   * @param event event for selected input field
   */
  const onChange = (event: any = {}) => {
    const { name = "", value = "" } = _get(event, "target", {});
    const userDetails = basicInfoData;
    switch (name) {
      case "cusName":
        userDetails.imeiCustomerName = value;
        setCusName(value);
        break;
      case "mobileNumber":
        userDetails.imeiMobileNo = value;
        setMobileNumber(value);
        break;
      case "billDate":
        const convertDate = value === "" ? value : getDateConvert(value);
        userDetails.billDate = convertDate;
        setRawBillDate(value);
        setBillDate(convertDate);
        break;
      case "model":
        userDetails.itemName = value;
        setModel(value);
        break;
      case "billNo":
        userDetails.billNo = value;
        setBillNo(value);
        break;
      case "branchName":
        userDetails.branchName = value;
        setbranchName(value);
        break;
      case "itemCode":
        userDetails.itemCode = value;
        setItemCode(value);
        break;
      case "product":
        userDetails.itemGroupName = value;
        setProduct(value);
        passProduct(value);
        break;
      case "brand":
        userDetails.itemBrandName = value;
        setBrand(value);
        break;
      case "ProductValue":
        userDetails.invoiceAmount = value;
        setInvoiceAmount(value);
        break;
      case "ageOfDevice":
        userDetails.deviceAge = value;
        setAgeOfDevice(value);
        break;
      default:
        break;
    }
    setbasicInfoData(userDetails);
    setOnPass(true);
  };

  useEffect(() => {
    setImei(_get(customerData, "imei", ""));
    setBillNo(_get(customerData, "billNo", ""));
    setModel(_get(customerData, "itemName", ""));
    setBillDate(_get(customerData, "billDate", ""));
    setItemCode(_get(customerData, "itemCode", ""));
    setBrand(_get(customerData, "itemBrandName", ""));
    setAgeOfDevice(_get(customerData, "deviceAge", ""));
    setbranchName(_get(customerData, "branchName", ""));
    setProduct(_get(customerData, "itemGroupName", ""));
    setEnquiryFor(_get(customerData, "enquiryFor", ""));
    passProduct(_get(customerData, "itemGroupName", ""));
    setSubcategory(_get(customerData, "subCategory", ""));
    setCusName(_get(customerData, "imeiCustomerName", ""));
    setMobileNumber(_get(customerData, "imeiMobileNo", ""));
    setInvoiceAmount(_get(customerData, "invoiceAmount", ""));
  }, [customerData]);

  /**
   * Update age of device based on bill date
   */
  useEffect(() => {
    if (rawBillDate) {
      const convertDate = getDateConvertAgeDevice(rawBillDate);

      if (convertDate !== "Invalid date") {
        const ageOfSelectedDevice = ageOfDeviceCalculation(convertDate);
        onChange({ target: { name: "ageOfDevice", value: ageOfSelectedDevice } });
      }
    }
  }, [rawBillDate]);

  useEffect(() => {
    if (onPass) {
      const newObj = {
        billNo: billNo,
        itemName: model,
        itemCode: itemCode,
        billDate: billDate,
        deviceAge: ageOfDevice,
        branchName: branchName,
        imeiMobileNo: mobileNumber,
        invoiceAmount: invoiceAmount,
        itemGroupName: product,
        itemBrandName: brand,
        imeiCustomerName: cusName
      };
      passBasicInfo(newObj);
      setOnPass(false);
    }
  }, [onPass]);

  return (
    <React.Fragment>
      <CommonInput
        readOnly={true}
        placeholder={""}
        mustField={true}
        inputError={false}
        value={enquiryFor}
        name={"enquiryFor"}
        onChange={onChange}
        inputFieldClass="h-9"
        labelText={"Enquiry For"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        readOnly={true}
        mustField={true}
        placeholder={""}
        inputError={false}
        value={subCategory}
        onChange={onChange}
        name={"subCategory"}
        inputFieldClass="h-9"
        labelText={"Sub Category"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        value={imei}
        name={"imei"}
        readOnly={true}
        mustField={true}
        placeholder={""}
        inputError={false}
        labelText={"IMEI"}
        onChange={onChange}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        maxLength={staticData.imeilength}
      />
      <CommonInput
        maxLength={staticData.MobileNo}
        mustField={true}
        placeholder={""}
        inputError={false}
        onChange={onChange}
        value={mobileNumber}
        readOnly={!editView}
        name={"mobileNumber"}
        inputFieldClass="h-9"
        labelText={"Mobile Number"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        onKeyPress={numOnlyAllow}
      />
      <CommonInput
        value={cusName}
        name={"cusName"}
        placeholder={""}
        mustField={true}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        readOnly={!editView}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        labelText={"Customer Invoice Name"}
        onKeyPress={alphaOnly}
      />
      <CommonInput
        placeholder={""}
        mustField={true}
        inputError={false}
        value={branchName}
        onChange={onChange}
        name={"branchName"}
        inputFieldClass="h-9"
        readOnly={!editView}
        labelText={"Branch Name"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        onKeyPress={alphaOnly}
      />
      <CommonInput
        placeholder={""}
        mustField={true}
        value={itemCode}
        name={"itemCode"}
        inputError={false}
        onChange={onChange}
        readOnly={!editView}
        inputFieldClass="h-9"
        labelText={"Item Code"}
        onKeyPress={numOnlyAllow}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        value={product}
        name={"product"}
        placeholder={""}
        mustField={true}
        inputError={false}
        onChange={onChange}
        labelText={"Product"}
        inputFieldClass="h-9"
        readOnly={!editView}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        name={"brand"}
        value={brand}
        placeholder={""}
        mustField={true}
        inputError={false}
        labelText={"Brand"}
        onChange={onChange}
        inputFieldClass="h-9"
        readOnly={!editView}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        value={model}
        name={"model"}
        placeholder={""}
        mustField={true}
        inputError={false}
        labelText={"Model"}
        onChange={onChange}
        inputFieldClass="h-9"
        readOnly={!editView}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        placeholder={""}
        mustField={true}
        inputError={false}
        onChange={onChange}
        readOnly={!editView}
        value={invoiceAmount}
        name={"ProductValue"}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        labelText={"Product Value"}
        parentClass={inputStylePopup}
        onKeyPress={numOnlyAllow}
      />
      <CommonInput
        value={billNo}
        name={"billNo"}
        placeholder={""}
        mustField={true}
        inputError={false}
        onChange={onChange}
        readOnly={!editView}
        labelText={"Bill No"}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
      {subCategory !== "Duplicate invoice" && (
        <CommonInput
          value={billDate}
          name={"billDate"}
          inputError={false}
          onChange={onChange}
          readOnly={!editView}
          inputFieldClass="h-9"
          labelText={"Bill Date"}
          placeholderText={billDate}
          enableDatePicker={editView}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          inputWithIcon={billDate ? false : true}
        />
      )}
      {subCategory !== "Duplicate invoice" && (
        <CommonInput
          inputError={false}
          onChange={onChange}
          value={convertToLowerCase(ageOfDevice)}
          name={"ageOfDevice"}
          inputFieldClass="h-9"
          readOnly={!editView}
          labelText={"Age of device"}
          labelTextClass={"font-bold"}
          placeholder={"Age of device"}
          parentClass={inputStylePopup}
          onKeyPress={numOnlyAllow}
        />
      )}
      {/* <AdditionalInfo
                additionalInfo={customerData}
            /> */}
    </React.Fragment>
  );
};

export default CustomerBasicInfo;
