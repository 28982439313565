import { Get, withoutTokenGet } from "ApiProcess/httpRestServices";
import {
  poorvikaBasePath,
  orderTracking,
  encodeGetParams,
  omsBasePath,
  omsGetDetailsBasedPhoneNumber
} from "../../ApiProcess/httpRestServices/apiManage";

export const getocTokenServices = () => {
  return Get(`${poorvikaBasePath}${orderTracking.tokenAccecc}`, "NoToken");
};
