import React, { Fragment, useState } from "react";
import { _get } from "utils/lodashConst";
import { Decrypt } from "utils/secureFile";
import { enquiryStatusTrackingTableProps } from "../types";
import CallTypeBody from "Component/CallTypeBody/CallTypeBody";
import { TableClass, tdClass, tdHighLight, thClass } from "utils/ClassContent";
import {
  convertToLowerCase,
  dateTimeDisplay,
  dateUTCTimeDisplay,
  nullToObject,
  nullToString,
  handleCurrentUser,
  getSortedTrans
} from "utils/validation";
import AmeyoCall from "Component/Ameyo";
import ReadMore from "Component/ReadMore";
import CallRecording from "Component/Ameyo/CallRecording";
import EnquiryStatusHistoryView from "./EnquiryStatusHistoryView";

const EnquiryStatusTrackingData = (props: enquiryStatusTrackingTableProps) => {
  const {
    customerData = {},
    transactions = [],
    columnView = false,
    getModalStatus = false,
    showAssign = false,
    isShowRoomStaffAndMobView = false
  } = nullToObject(props);
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { empId = "" } = {} } = nullToObject(userLoginDetails);
  const { mobileNo = "" } = customerData;
  const [oldStatusView, setOldStatusView] = useState(false);
  const [seletectElment, setseletectElment] = useState({});
  //view eyeIcon click
  const handleViewOldStatus = (ele = {}) => {
    setseletectElment(ele);
    setOldStatusView(true);
  };

  const renderData = (data: any = "") => {
    return <td className={tdClass}>{data ? data : "nill"}</td>;
  };

  const ameoTagCommon = (branchContactNo = [], i = 0) => {
    return (
      <td className={tdClass}>
        {branchContactNo
          ? branchContactNo.map((contactNo: string) => (
            <AmeyoCall
              key={`branchContactNo-${contactNo}`}
              phone={contactNo}
              id={convertToLowerCase(i + "branchContactNo")}
            />
          ))
          : "nill"}
      </td>
    );
  };

  return (
    <Fragment>
      <div
        className="block w-full overflow-x-auto relative "
        style={{
          minHeight: isShowRoomStaffAndMobView ? "auto" : "250px",
          height: getModalStatus ? "250px" : "100%"
        }}
      >
        <table className={TableClass}>
          <thead>
            {isShowRoomStaffAndMobView ? (
              <tr>
                <th className={thClass}>Status</th>
                <th className={thClass}>Sub Status</th>
                <th className={thClass}>Remark</th>
                <th className={thClass}>Updated by</th>
                {showAssign && (
                  <>
                    <th className={thClass}>Assigned by</th>
                    <th className={thClass}>Assigned to</th>
                  </>
                )}
                <th className={thClass}>Next Follow Up Date</th>
                <th className={thClass}>Branch Contact No</th>
                <th className={thClass}>Date</th>
              </tr>
            ) : (
              <tr>
                <th className={thClass}>Date</th>
                <th className={thClass}>Status</th>
                <th className={thClass}>Sub Status</th>
                <th className={thClass}>Remark</th>
                <th className={thClass}>Updated by</th>

                {showAssign && (
                  <>
                    <th className={thClass}>Assigned by</th>
                    <th className={thClass}>Assigned to</th>
                  </>
                )}
                <th className={thClass}>Next Follow Up Date</th>
                <th className={thClass}>Branch Contact No</th>
                <th className={thClass}>{"Call Recording"}</th>
              </tr>
            )}
          </thead>
          {transactions.sort(getSortedTrans).map((ele: any, i: number) => {
            const {
              recordingUrl = "",
              nextFollowUpDate = "",
              actionBy: { branch = null, customerId = "", type = "", date = "", name = "", empId: employId = "" } = {}
            } = ele;
            const assignedByName = _get(ele, "assignedBy.name", "");
            const assignedByBranch = _get(ele, "assignedBy.branch", "");
            const assignedByEmpId = _get(ele, "assignedBy.empId", "");
            const assignedToName = _get(ele, "assignedTo.name", "");
            const assignedToBranch = _get(ele, "assignedTo.branch", "");
            const assignedToEmpId = _get(ele, "assignedTo.empId", "");
            const branchContactNo = _get(ele, "branchContactNo", "");
            return (
              <tbody key={convertToLowerCase(i + "transactions-fsvx")}>
                {isShowRoomStaffAndMobView ? (
                  <tr tabIndex={1}>
                    <td className={tdClass}>
                      <button type={"button"} className={tdHighLight} onClick={() => handleViewOldStatus(ele)}>
                        {nullToString(ele.status)}
                      </button>
                    </td>

                    {renderData(nullToString(ele.currentStatus))}
                    <td className={tdClass}>
                      <ReadMore content={nullToString(ele.remarks)} />
                    </td>
                    {renderData(handleCurrentUser(name, branch, employId, empId, customerId, type))}

                    {showAssign && (
                      <>
                        {renderData(handleCurrentUser(assignedByName, assignedByBranch, assignedByEmpId, empId))}
                        {renderData(handleCurrentUser(assignedToName, assignedToBranch, assignedToEmpId, empId))}
                      </>
                    )}

                    {renderData(nextFollowUpDate ? dateUTCTimeDisplay(nextFollowUpDate) : "")}

                    {ameoTagCommon(branchContactNo, i)}

                    <td className={tdClass} style={{ minWidth: "130px" }}>
                      <div className="flex items-center">
                        <span className="">{dateTimeDisplay(date)}</span>
                        <span className="mx-1">
                          <CallTypeBody callType={_get(ele, "callType", true)} />
                        </span>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr tabIndex={1}>
                    <td className={tdClass}>
                      <div className="flex items-center">
                        <span className="">{dateTimeDisplay(date)}</span>
                        <span className="mx-1">
                          <CallTypeBody callType={_get(ele, "callType", true)} />
                        </span>
                      </div>
                    </td>
                    <td className={tdClass}>
                      <button type={"button"} className={tdHighLight} onClick={() => handleViewOldStatus(ele)}>
                        {nullToString(ele.status)}
                      </button>
                    </td>

                    {renderData(nullToString(ele.currentStatus))}
                    <td className={tdClass}>
                      <ReadMore content={nullToString(ele.remarks)} />
                    </td>

                    {renderData(handleCurrentUser(name, branch, employId, empId, customerId, type))}

                    {showAssign && (
                      <>
                        {renderData(handleCurrentUser(assignedByName, assignedByBranch, assignedByEmpId, empId))}
                        {renderData(handleCurrentUser(assignedToName, assignedToBranch, assignedToEmpId, empId))}
                      </>
                    )}

                    {renderData(nextFollowUpDate ? dateUTCTimeDisplay(nextFollowUpDate) : "")}

                    {ameoTagCommon(branchContactNo, i)}

                    {recordingUrl ? <td className={tdClass}>
                      <CallRecording phone={mobileNo} updatedDate={date} recordingUrl={recordingUrl} />
                    </td> : <td className={tdClass}>nill</td>}
                  </tr>
                )}
              </tbody>
            );
          })}
        </table>
      </div>

      {oldStatusView && (
        <EnquiryStatusHistoryView
          columnView={columnView}
          getData={seletectElment}
          handleClosePopup={() => setOldStatusView(false)}
        />
      )}
    </Fragment>
  );
};

export default EnquiryStatusTrackingData;
