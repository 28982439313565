export const SCORECARD_LOCK = "SCORECARD_LOCK";
export const SCORECARD_CREATE = "SCORECARD_CREATE";
export const SCORECARD_DELETE = "SCORECARD_DELETE";
export const GET_SCORECARD_LIST = "GET_SCORECARD_LIST";
export const SCORECARD_RESPONSE = "SCORECARD_RESPONSE";
export const GET_SCORECARD_BY_ID = "GET_SCORECARD_BY_ID";
export const GET_SCORECARD_RESPONSE = "GET_SCORECARD_RESPONSE";
export const GET_SCORECARD_EVALUATOR = "GET_SCORECARD_EVALUATOR";
export const GET_SCORECARDRESPONSE__BY_ID = "GET_SCORECARDRESPONSE__BY_ID";
export const SCORECARD_DASHBOARD_AGENT = "SCORECARD_DASHBOARD_AGENT";
export const SCORECARD_DASHBOARD_EVALUATOR = "SCORECARD_DASHBOARD_EVALUATOR";
export const SCORECARD_DASHBOARD_NOTIFICATION = "SCORECARD_DASHBOARD_NOTIFICATION";
export const SCORECARD_DASHBOARD_KUDOS = "SCORECARD_DASHBOARD_KUDOS";
