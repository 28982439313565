import { _get } from "utils/lodashConst";
import { encryptData } from "utils/secureFile";
import { leadingAndTrailingSpaceAvoid, nullToObject } from "utils/validation";
import { Get, Post, Put, withoutTokenGet } from "ApiProcess/httpRestServices";
import {
  loginApi,
  salesProduct,
  crmApiBaseUrl,
  encodeGetParams,
  productDetailsGet
} from "ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * userRole find
 */

export const registerUsersService = (obj: any = {}, getRolesDetails = {}) => {
  const {
    empId = "",
    branch = [],
    userRole = "",
    passWord = "",
    userName = "",
    roleType = "",
    priceMode = {},
    expiryAt = null,
    dialedFrom = "",
    nlcCategory = [],
    allowedRadius = "",
    allowedOnlyFrom = "",
    allowExcludeLocation = false
  } = nullToObject(obj);
  encryptData("logData", leadingAndTrailingSpaceAvoid(passWord), true);
  const encryptPassword = sessionStorage.getItem("logData");

  const { userRoles = [] } = nullToObject(getRolesDetails);

  const roleFind: any = userRoles?.find((ele: any) => ele.name === userRole);
  const { roleId = "", slug = "", _id = "" } = roleFind || {};
  const params = {
    expiryAt,
    dialedFrom,
    roleRef: _id,
    roleId: roleId,
    branch: branch,
    allowedOnlyFrom,
    allowExcludeLocation,
    nlcCategory: nlcCategory,
    pricingDetails: priceMode,
    password: encryptPassword,
    allowedRadius: "" + allowedRadius,
    role: leadingAndTrailingSpaceAvoid(slug),
    empId: leadingAndTrailingSpaceAvoid(empId),
    name: leadingAndTrailingSpaceAvoid(userName),
    roleType: leadingAndTrailingSpaceAvoid(roleType)
  };
  return Post(`${crmApiBaseUrl}${loginApi.register}`, params, "crmToken");
};

/**
 * @param  {} data={}
 * edit register user role and name
 */

export const editRegisterUserDetails = async (editUserDetails: any = {}, getRolesDetails = {}) => {
  const {
    id = "",
    branch = [],
    roleType = "",
    userRole = "",
    userName = "",
    dialedFrom = "",
    expiryAt = null,
    priceMode = {},
    allowedRadius = "",
    allowedOnlyFrom = "",
    nlcCategory = [],
    allowExcludeLocation = false
  } = editUserDetails;
  const { userRoles = [] } = nullToObject(getRolesDetails);
  const userRoleIdFind = userRoles?.find((ele: any) => ele.name === userRole);

  const newObj = {
    expiryAt,
    dialedFrom,
    name: userName,
    branch: branch,
    allowedOnlyFrom,
    roleType: roleType,
    allowExcludeLocation,
    nlcCategory: nlcCategory,
    pricingDetails: priceMode,
    allowedRadius: "" + allowedRadius,
    role: _get(userRoleIdFind, "slug", ""),
    roleRef: _get(userRoleIdFind, "_id", ""),
    roleId: _get(userRoleIdFind, "roleId", "")
  };
  return await Put(`${crmApiBaseUrl}${loginApi.registerSatusChange}${id}`, newObj, "crmToken");
};

/**
 * showRoom list search api call
 */
export const showRoomListSearchApiCall = (searchData: any = {}) => {
  const { search = "" } = searchData;
  const newObj = {
    search: search
  };
  const queryString = encodeGetParams(newObj);

  return Get(`${crmApiBaseUrl}${loginApi.showRoomListSearch}?${queryString}`, "crmToken");
};

/**
 * syncUserBranchUpdate
 */
export const syncUserBranchUpdateApiCall = () => {
  return Get(`${crmApiBaseUrl}${loginApi.showRoomListSync}`, "crmToken");
};

/**
 * syncUserBranchUpdate
 */
export const getNlcCategoriesList = () => {
  const newObjHeader = {
    locale: "en-IN",
    channel: "SHOP"
  };
  return withoutTokenGet(`${productDetailsGet}${salesProduct.nlcCategoriesList}`, newObjHeader);
};

export const mockLocation = [
  { _id: 1, isCheck: false, itemName: "20", meters: "20" },
  { _id: 2, isCheck: false, itemName: "40", meters: "40" },
  { _id: 3, isCheck: false, itemName: "80", meters: "80" },
  { _id: 4, isCheck: false, itemName: "160", meters: "160" },
  { _id: 5, isCheck: false, itemName: "320", meters: "320" },
  { _id: 6, isCheck: false, itemName: "640", meters: "640" },
  { _id: 7, isCheck: false, itemName: "1280", meters: "1280" }
];

export const roleTypeArray = ["Novice", "Beginner", "Intermediate", "Expert"];

export const allowedRoles = ["Department Manager-M", "Branch Manager-M", "Accountant", "Store Manager", "Manager"];

export const UserWorkingType = [
  { labelText: "Web", value: "web", id: 1 },
  { labelText: "Mobile App", value: "mobile-app", id: 2 },
  { labelText: "Both", value: "both", id: 3 }
];

export const dialedFromData = [
  { _id: 1, isCheck: false, itemName: "Cloud", value: "cloud" },
  { _id: 2, isCheck: false, itemName: "Mobile", value: "mobile" }
];

export const initialPriceMode = { fsp: 0, cp: 0, nlc: 0, comp: 0 };
