import React, { useState } from "react";
import { _get } from "utils/lodashConst";
import Loader from "Component/Loader";
import CommonInput from "Component/Form/CommonInput";
import ModalFixed from "Component/Modal/ModalFixed";
import { convertToLowerCase } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import ComplainUpdatePopup from "Pages/Complains/List/ComplainUpdatePopup";
import EnquiryForUpdatePopup from "Pages/Customers/CallsList/EnquiryForUpdatePopup";
import { ticketIdBasedGetAllInfo } from "Pages/NewCall/EnquiryFor/commonEnquireFor";
import { getCuslistByTicketIdApiServices } from "Pages/SalesEnquiries/commonCalllist";
import OnlineEnquiryPopup from "Pages/OnlineOrders/ViewOnlineEnqInfo/OnlineEnquiryPopup";
import EnquiryForServicePopup from "Pages/ServiceCalls/ViewCallInfo/EnquiryForServicePopup";
import SalesEnquiryStatusUpdate from "Pages/NewCall/EnquiryFor/SalesEnquiry/SalesProductInformation/SalesTable/SalesEnquiryStatusUpdate";
import DealingsUpdate from "Pages/NewCall/EnquiryFor/SalesEnquiry/SalesProductInformation/SalesTable/DealingsView/DealingsUpdate";
import SalesOtherUpdate from "Pages/NewCall/EnquiryFor/SalesEnquiry/SalesProductInformation/SalesTable/SalesOtherView/SalesOtherUpdate";

const defaultState = {
  srnPopup: false,
  salesPopup: false,
  onlinePopup: false,
  servicePopup: false,
  complainPopup: false,
  delaingShowPopup: false,
  salesOtherShowPopup: false
};

const TicketDetails = (props: any) => {
  const { ticketId = "", enquiryType = "", setTicket = () => { }, setEnquiryType = () => { } } = props || {};

  const [ticketDetailsStateManage, setTicketDetailsStateManage] = useState(defaultState);
  const {
    complainPopup = false,
    delaingShowPopup = false,
    salesOtherShowPopup = false,
    srnPopup = false,
    salesPopup = false,
    onlinePopup = false,
    servicePopup = false
  } = ticketDetailsStateManage;

  const [cusListDataByTicket, set_cusListDataByTicket] = useState<any>({});
  const [customerData, setCustomerData] = useState({});
  const [loader, setLoader] = useState(false);

  const stateManage = (name = "", value = false) => {
    let newObj: any = {};
    for (const prop in ticketDetailsStateManage) {
      if (prop === name) {
        newObj = {
          ...newObj,
          [name]: value
        };
      } else {
        newObj = {
          ...newObj,
          [prop]: false
        };
      }
    }
    setTicketDetailsStateManage(newObj);
  };

  /**
   * ticket id based get full information
   * @param ele object
   */
  const getEnquiryInformation = async (ele: any = {}) => {
    const cusInfo = await ticketIdBasedGetAllInfo(ele);
    const newObj = {
      ...cusInfo,
      item_code: _get(cusInfo, "itemCode", "")
    };
    setCustomerData(newObj);
  };
  /**
   * open subCategory based popUp
   * @param data object
   */
  const handleCustomerStatus = (data: any = {}) => {
    const { enquiryFor = "", subCategory = "" } = cusListDataByTicket;
    getEnquiryInformation({ ticketId: ticketId });
    switch (enquiryFor) {
      case "Service":
        stateManage("servicePopup", true);
        break;
      case "Sales Returns":
        stateManage("srnPopup", true);
        break;
      case "Complaints":
        stateManage("complainPopup", true);
        break;
      case "Online":
        stateManage("onlinePopup", true);
        break;
      case "Sales":
        if (convertToLowerCase(subCategory) === convertToLowerCase("Non-Dealing")) {
          stateManage("delaingShowPopup", true);
        } else if (convertToLowerCase(subCategory) === convertToLowerCase("Others")) {
          stateManage("salesOtherShowPopup", true);
        } else if (convertToLowerCase(subCategory) === convertToLowerCase("Product")) {
          stateManage("salesPopup", true);
        }
        break;
      default:
        break;
    }
  };

  //all pop up close
  const closePopup = () => {
    setCustomerData({});
    setTicketDetailsStateManage(defaultState);
  };

  //close outdated modal
  const closeDelaingPopup = () => {
    setCustomerData({});
    stateManage("delaingShowPopup", true);
  };

  const getCuslistByTicketIdApiCall = async (ticketID) => {
    setLoader(true);
    const response = await getCuslistByTicketIdApiServices(ticketID);
    setLoader(false);

    const { data: { data = {}, status = 0, message = "" } = {} } = response || {};
    if (status === 200) {
      const { enquiryFor = "" } = data;
      if (enquiryFor) {
        setEnquiryType(enquiryFor);
      } else {
        setEnquiryType("");
      }
      set_cusListDataByTicket(data);
    } else {
      failToast(message);
      set_cusListDataByTicket({});
    }
  };
  /**
   * handle tciket changes
   */
  const handleTicket = (event: any = {}) => {
    const { target: { value = "" } = {} } = event;
    setTicket(value);
    if (value?.length > 4) {
      getCuslistByTicketIdApiCall(value);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <div className="flex items-center md:w-1/2 w-full px-2 py-1">
        <strong className="w-32 flex-shrink-0 text-gray-500">Ticket ID</strong>{" "}
        <div className="overflow-hidden overflow-ellipsis flex-1">
          <CommonInput
            label={false}
            setShow={true}
            placeholder=""
            inputWithIcon={true}
            parentClass={"w-full"}
            labelTextClass={"font-bold"}
            labelText={"item.headingValue"}
            inputFieldClass=" h-7 w-full text-xs "
            name={"ticketId"}
            value={ticketId}
            iconClickAction={handleCustomerStatus}
            onChange={(event: any = {}) => handleTicket(event)}
          />
        </div>
      </div>
      <div className="flex items-center md:w-1/2 px-2 py-1 w-full">
        <strong className="w-32 flex-shrink-0 text-gray-500">Enquiry type</strong>
        <div className="overflow-hidden overflow-ellipsis flex-1">
          <CommonInput
            label={false}
            setShow={true}
            placeholder=""
            inputWithIcon={false}
            parentClass={"w-full"}
            labelTextClass={"font-bold"}
            labelText={"item.headingValue"}
            inputFieldClass=" h-7 w-full text-xs "
            name={""}
            value={enquiryType}
            onChange={() => { }}
          />
        </div>
      </div>
      {srnPopup && (
        <ModalFixed>
          <EnquiryForUpdatePopup activeModal={true} setShow={srnPopup} getData={customerData} closePopup={closePopup} />
        </ModalFixed>
      )}

      {/* service view */}
      {servicePopup && (
        <ModalFixed>
          <EnquiryForServicePopup
            activeModal={true}
            setShow={servicePopup}
            customerData={customerData}
            closePopup={closePopup}
          />
        </ModalFixed>
      )}
      {salesPopup && Object.keys(customerData)?.length && (
        <ModalFixed>
          <SalesEnquiryStatusUpdate
            activeModal={true}
            setShow={salesPopup}
            closePopup={closePopup}
            customerData={customerData}
          />
        </ModalFixed>
      )}

      {/* non-dealing view data */}
      {delaingShowPopup && Object.keys(customerData)?.length && (
        <ModalFixed>
          <DealingsUpdate
            activeModal={true}
            setShow={delaingShowPopup}
            customerData={customerData}
            closePopup={closeDelaingPopup}
          />
        </ModalFixed>
      )}

      {/* others view data */}
      {salesOtherShowPopup && Object.keys(customerData)?.length && (
        <ModalFixed>
          <SalesOtherUpdate
            activeModal={true}
            setShow={delaingShowPopup}
            customerData={customerData}
            closePopup={() => stateManage("salesOtherShowPopup", true)}
          />
        </ModalFixed>
      )}

      {/* compaint view */}
      {complainPopup && Object.keys(customerData)?.length && (
        <ModalFixed>
          <ComplainUpdatePopup
            setShow={complainPopup}
            getData={customerData}
            enableViewMode={true}
            closePopup={closePopup}
          />
        </ModalFixed>
      )}

      {/* invoice call details */}
      {onlinePopup && Object.keys(customerData)?.length && (
        <ModalFixed>
          <OnlineEnquiryPopup setShow={onlinePopup} enquiryData={customerData} closePopup={closePopup} />
        </ModalFixed>
      )}
    </>
  );
};

export default TicketDetails;
