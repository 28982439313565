import store from "store";
import { moment } from "utils/momentConst";
import { Post, Get, withoutTokenGet } from "ApiProcess/httpRestServices";
import {
  campaign,
  crmApiBaseUrl,
  encodeGetParams,
  omsGetDetailsBasedPhoneNumber,
  orderTracking
} from "ApiProcess/httpRestServices/apiManage";

//sourse
export const sourceTickeErrorMsg = (Errorsource = false, sourceWhiteSpaceErr = false) => {
  if (Errorsource) {
    return "Source file allow only 250 char";
  } else if (sourceWhiteSpaceErr) {
    return "Source cannot allow only white space";
  } else {
    return "";
  }
};

//name
export const nameCampaignErrorMsg = (Errorsource = false, sourceWhiteSpaceErr = false) => {
  if (Errorsource) {
    return "Name file allow only 100 char";
  } else if (sourceWhiteSpaceErr) {
    return "Name cannot allow only white space";
  } else {
    return "";
  }
};

//discription
export const discriptionErrorMsg = (Errorsource = false, sourceWhiteSpaceErr = false) => {
  if (Errorsource) {
    return "source file allow only 2000 char";
  } else if (sourceWhiteSpaceErr) {
    return "source cannot allow only white space";
  } else {
    return "";
  }
};

//mobile
export const mobileValidateErrorMsg = (Errorsource = false) => {
  if (Errorsource) {
    return "Please give valid mobile number";
  } else {
    return "";
  }
};
//email
export const emailErrorMsg = (Errorsource = false, sourceWhiteSpaceErr = false) => {
  if (Errorsource) {
    return "Please give valid email ";
  } else if (sourceWhiteSpaceErr) {
    return "email cannot allow only white space";
  } else {
    return "";
  }
};

//error msg show purbose
export const sourceTickeErrorMsgBoolean = (Errorsource = false, sourceWhiteSpaceErr = false) => {
  if (Errorsource) {
    return true;
  } else if (sourceWhiteSpaceErr) {
    return true;
  } else {
    return false;
  }
};

//create campaing api call
export const createCampainApiCall = async (obj: any = {}) => {
  const {
    campaignImg = [],
    description = "",
    campaignDate = null,
    name = "",
    email = "",
    mobile = "",
    source = ""
  } = obj;
  const formData = new FormData();

  if (campaignImg.length > 0) {
    for (const file of campaignImg) {
      formData.append("attachments", file.file); // appending every file to formdata
    }
  }

  const newObj = {
    name,
    email,
    mobile,
    description,
    isQuery: "t",
    srcOfCampaign: source,
    date: campaignDate ? moment(campaignDate).format("x") : ""
  };
  const queryString = encodeGetParams(newObj);
  return await Post(`${crmApiBaseUrl}${campaign.campaignCreate}?${queryString}`, formData, "crmToken");
};

export const getCampaignDetailsApiCallService = (obj) => {
  const { page = 1, size = 10, mobile = "", search = "", isCard = 0 } = obj;
  const newObj = {
    page,
    size,
    mobile,
    search,
    ...(isCard && { isCard: 1 })
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${campaign.campaignCreate}?${queryString}`, "crmToken");
};

/**
 * get order details history
 */
export const getUserPurchesOrderHistoryDetaisApiService = (data: any = {}) => {
  const { mobileNumber = "", page = "1", size = "10" } = data;
  const newObj = {
    page: page,
    size: size,
    phone_number: mobileNumber
  };
  const queryString = encodeGetParams(newObj);
  return withoutTokenGet(
    `${omsGetDetailsBasedPhoneNumber}${orderTracking.omsDetailsBsedPhoneNumberApi}?${queryString}`
  );
};

//
export const uploadBulKCampaignData = (obj: any = {}) => {
  const { name = "", type = "" } = obj;
  const newObj = {
    type: type,
    fileName: name
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${campaign.uploadBulkCsv}?${queryString}`, "crmToken");
};

export const storageUrlUpload = (obj: any = {}) => {
  const { signedUrl = "", file = {} } = obj;

  return fetch(signedUrl, {
    method: "PUT",
    headers: {
      "Content-Type": "application/octet-stream"
    },
    body: file
  });
};

/**
 * get campaign details table
 */
export const campaignDetailsExcelDownload = (obj: any = {}) => {
  const { page = 1, size = 10, mobile = "", search = "" } = obj;
  const newObj = {
    page,
    size,
    mobile,
    search
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${campaign.campaignExcelDownload}?${queryString}`, {}, "crmToken");
};
