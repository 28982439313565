import React from "react";
import { bg_EnquiryFromMail } from "utils/ClassContent";
import CommonDropHeading from "Component/CommonDropHeading";
import EnquiryFromMail from "Pages/MailBox/ShowMailOnEnquiry/EnquiryFromMail";

/**
 * Email Info Drop Heading
 * @param props props
 * @returns Shows basic customer info on enquiry popup
 */
const OnlineEmailInfoDropHeading = (props: any = {}) => {
  const { selectedMailData = {}, popParentTabs = "" } = props || {};

  return (
    <CommonDropHeading
      tabActiveDefault={popParentTabs === "EnquiryFromMail" ? true : false}
      id="EnquiryFromMail"
      heading="Enquiry From Mail"
      customClass={bg_EnquiryFromMail}
    >
      <EnquiryFromMail selectedMailData={selectedMailData} />
    </CommonDropHeading>
  );
};

export default OnlineEmailInfoDropHeading;
