import { all, fork } from "redux-saga/effects";
import emiSaga from "./emiSaga";
import ameyoSaga from "./ameyoSaga";
import loginSaga from "./loginSaga";
import cusListSaga from "./cusListSaga";
import comListSaga from "./comListSaga";
import complainSaga from "./complainSaga";
import basicInfoSaga from "./basicInfoSaga";
import scoreCardSaga from "./scorecardSaga";
import StoreLocateSaga from "./storeLocateSaga";
import salesReturnSaga from "./salesReturnSaga";
import customerInfoSaga from "./CustomerInfoSaga";
import duplicateInvSaga from "./duplicateInvSaga";
import serviceCentersSaga from "./ServiceCenterSaga";
import customerServiceSaga from "./customerServiceSaga";
import salesTransactionSaga from "./salesTransactionSaga";

export default function* rootSaga() {
  yield all([
    fork(emiSaga), //emiPage call
    fork(ameyoSaga), // Ameyo call
    fork(loginSaga), //loginSaga handle
    fork(cusListSaga), // cusList
    fork(scoreCardSaga),
    fork(comListSaga), // get complain list
    fork(complainSaga), // complains
    fork(basicInfoSaga), //basicInformation handle
    fork(salesReturnSaga), //salesReturn
    fork(StoreLocateSaga), //storeLocate findIng
    fork(duplicateInvSaga), // duplicateInvoice service
    fork(customerInfoSaga), // customer info
    fork(serviceCentersSaga), // service center details
    fork(customerServiceSaga), // customer service
    fork(salesTransactionSaga) // sales trans saga
  ]);
}
