import React from "react";

const Download = React.lazy(() => import("./Download"));
const SalesMenu = React.lazy(() => import("./SalesMenu"));
const AllOrders = React.lazy(() => import("./AllOrders"));
const Salesorder = React.lazy(() => import("./Salesorder"));
const HomeNewCall = React.lazy(() => import("./HomeNewCall"));
const Transactions = React.lazy(() => import("./Transactions"));
const JustDialMenu = React.lazy(() => import("./JustDialMenu"));
const AllCallMyCall = React.lazy(() => import("./AllCallMyCall"));
const NewCallSideBar = React.lazy(() => import("./NewCallSideBar"));
const TransitionDrop = React.lazy(() => import("./TransitionDrop"));
const NotifyMeSideBar = React.lazy(() => import("./NotifyMeSideBar"));
const PreRegisterMenu = React.lazy(() => import("./PreRegisterMenu"));
const SalesReturnMenu = React.lazy(() => import("./SalesReturnMenu"));
const AdvocateSideBar = React.lazy(() => import("./AdvocateSideBar"));
const AuditReportMenu = React.lazy(() => import("./AuditReportMenu"));
const ServiceSideMenu = React.lazy(() => import("./ServiceSideMenu"));
const CampaignsSideBar = React.lazy(() => import("./CampaignsSideBar"));
const QualityScorecard = React.lazy(() => import("./QualityScorecard"));
const NonDealingSideBar = React.lazy(() => import("./NonDealingSideBar"));
const ComplaintSideMenu = React.lazy(() => import("./ComplaintSideMenu"));
const SideBarSizeHandle = React.lazy(() => import("./SideBarSizeHandle"));
const SuperAdminSideBar = React.lazy(() => import("./SuperAdminSideBar"));
const FeedbackContestMenu = React.lazy(() => import("./FeedbackContestMenu"));
const AllEnquiryBasedEmpId = React.lazy(() => import("./AllEnquiryBasedEmpId"));
const ServiceCenterDetails = React.lazy(() => import("./ServiceCenterDetails"));
const OnlineQueriesSideMenu = React.lazy(() => import("./OnlineQueriesSideMenu"));
const ContactsDetailsSideBar = React.lazy(() => import("./ContactsDetailsSideBar"));
const ProductQRGeneratorMenu = React.lazy(() => import("./ProductQRGeneratorMenu"));
const CampaignTransactionSideBar = React.lazy(() => import("./CampaignTransactionSideBar"));
export {
  Download,
  SalesMenu,
  AllOrders,
  Salesorder,
  HomeNewCall,
  Transactions,
  JustDialMenu,
  AllCallMyCall,
  NewCallSideBar,
  NotifyMeSideBar,
  TransitionDrop,
  PreRegisterMenu,
  SalesReturnMenu,
  AuditReportMenu,
  AdvocateSideBar,
  ServiceSideMenu,
  CampaignsSideBar,
  QualityScorecard,
  NonDealingSideBar,
  ComplaintSideMenu,
  SideBarSizeHandle,
  SuperAdminSideBar,
  FeedbackContestMenu,
  AllEnquiryBasedEmpId,
  ServiceCenterDetails,
  OnlineQueriesSideMenu,
  ContactsDetailsSideBar,
  ProductQRGeneratorMenu,
  CampaignTransactionSideBar,
};
