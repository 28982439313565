import { put, takeLatest, call, all } from "redux-saga/effects";
import * as storeLocateType from "../actionTypes/storeLocateType";
import { pincodeStoreLocate, storeLocateService } from "utils/services/storeLocateService";

/**
 * @param  {object} obj
 * pin code based search loacte
 */
function* pincodeStoreLocateCall(obj = {}): any {
  try {
    const response = yield call(pincodeStoreLocate, obj);
    const { status = 0, data: { data = [] } = {} } = response;
    if (status === 200) {
      yield all([
        put({ type: "PINCODE_LOADER", loaderPincode: false }), //loaderOff
        put({ type: "STORE_LOCATE_DETAILS", storeLocateDetails: data }) //lodering on / off
      ]);
    } else {
      yield all([
        put({ type: "PINCODE_LOADER", loaderPincode: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield put({ type: "PINCODE_LOADER", loaderPincode: false }); //load teams page on success of api call
  }
}

/**
 * @param  {object} obj
 * pin code based search loacte
 */
function* storeLocate(obj = {}): any {
  try {
    const response = yield call(storeLocateService, obj);
    const { status = 0, data: { data = [] } = {} } = response;
    if (status === 200) {
      const newArrayConstrct = data.map((ele: any) => {
        return {
          ...ele,
          optionData: ele.state
        };
      });
      yield all([
        put({ type: "PINCODE_LOADER", loaderPincode: false }), //loaderOff
        put({ type: "STORE_LOCATE_DATA", storeLocateData: newArrayConstrct }) //lodering on / off
      ]);
    } else {
      yield all([
        put({ type: "PINCODE_LOADER", loaderPincode: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield put({ type: "PINCODE_LOADER", loaderPincode: false }); //load teams page on success of api call
  }
}

export default function* storeLocateSaga() {
  yield takeLatest(storeLocateType.FETCH_STORE_LOCATE_DETAILS, storeLocate);
  yield takeLatest(storeLocateType.FETCH_PINCODE_STORE, pincodeStoreLocateCall);
}
