import * as salesReturnType from "../actionTypes/salesReturnType";

/**
 * GET services center list
 */
export const servicesCenterAction = () => {
  return {
    type: salesReturnType.GET_SERVICES_CENTER_LIST
  };
};

/**
 * GET services center list
 */
export const servicesCenterTokenAction = () => {
  return {
    type: salesReturnType.GET_SERVICES_CENTER_TOKEN
  };
};

/**
 * GET services center list
 */
export const getServiceCenterAction = (data = {}) => {
  return {
    type: salesReturnType.GET_SERVICE_CENTER_LIST,
    data
  };
};

/**
 * GET services center refresh
 */
export const getServiceCenterRefreshAction = () => {
  return {
    type: salesReturnType.GET_SERVICE_CENTER_REFRESH
  };
};

/**
 * send service center list on email
 */
export const serviceCenterEmailSendAction = (data: any) => {
  return {
    type: salesReturnType.SERVICE_CENTER_SEND_REPORT_EMAIL,
    data
  };
};

/**
 * service center create
 */
export const createServiceCenterAction = (data: any) => {
  return {
    type: salesReturnType.SERVICE_CENTER_CREATE,
    data
  };
};

/**
 * service center delete
 */
export const deleteServiceCenterAction = (data: any) => {
  return {
    type: salesReturnType.SERVICE_CENTER_DELETE,
    data
  };
};
