import { Put } from "ApiProcess/httpRestServices";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { crmApiBaseUrl, encodeGetParams, salesReturn } from "ApiProcess/httpRestServices/apiManage";

export const convertToSRNAPiCall = async (srnDetails: any = {}) => {
  const {
    _id = "",
    resonForSRN = 0,
    passRefunData = {},
    passDoaDetails = {},
    replacePassdata = {},
    reasonforSalesReturn = ""
  } = srnDetails;

  const newObj = {
    ...(resonForSRN === 1 && { ressonData: passDoaDetails }),
    ...(resonForSRN === 2 && { ressonData: replacePassdata }),
    ...(resonForSRN === 3 && { ressonData: passRefunData }),
    reason: reasonforSalesReturn
  };

  const {
    ressonData: {
      refundImages = [],
      doaimagRenderArry = [],
      relaceimagRenderArry = [],

      doaCertificateDate = "",
      doaCertificateNumber = "",
      doaJobSheetDate = "",
      doaServiceCenter = "",
      doaCertificateImei = "",

      relaceEmpId = "",
      relaceProduct = "",
      relaceEmpName = "",
      relaceDepartment = "",
      refundRequestAmt = "",
      relaceDesignation = "",
      relaceSellingPrice = "",

      refundEmpId = "",
      refundEmpName = "",
      refundDepatmet = "",
      refundDesignation = ""
    } = {}
  } = newObj;

  const formData = new FormData();
  const imageArray = [...relaceimagRenderArry, ...doaimagRenderArry, ...refundImages];

  const submitData = {
    reasonForSalesReturn: {
      reason: reasonforSalesReturn,
      doaCertificateDate: resonForSRN === 1 ? doaCertificateDate : "",
      doaCertificateNo: resonForSRN === 1 ? doaCertificateNumber : "",
      doaJobSheetDate: resonForSRN === 1 ? doaJobSheetDate : "",
      doaServiceCenter: resonForSRN === 1 ? doaServiceCenter : "",
      doaIMEINo: resonForSRN === 1 ? doaCertificateImei : "",

      replacedProduct: resonForSRN === 2 ? relaceProduct : "",
      refundReqAmt: resonForSRN === 3 ? refundRequestAmt : "",
      replacedSellPrice: resonForSRN === 2 ? relaceSellingPrice : "",
      employeeId: resonForSRN === 2 ? relaceEmpId : resonForSRN === 3 ? refundEmpId : "",
      employeeName: resonForSRN === 2 ? relaceEmpName : resonForSRN === 3 ? refundEmpName : "",
      employeeDept: resonForSRN === 2 ? relaceDepartment : resonForSRN === 3 ? refundDepatmet : "",
      employeeDesignation: resonForSRN === 2 ? relaceDesignation : resonForSRN === 3 ? refundDesignation : ""
    },
    isQuery: "t"
  };
  /**
   * form data make
   */
  if (imageArray.length > 0) {
    for (const file of imageArray) {
      formData.append("commonImgFile", file.file); // appending every file to formdata
    }
  }
  const queryString = encodeGetParams(submitData);
  if (resonForSRN === 2 || resonForSRN === 3) {
    //refun and replacement
    if (relaceEmpId !== "" || refundEmpId !== "") {
      return await Put(`${crmApiBaseUrl}${salesReturn.convertTosrn}${_id}?${queryString}`, formData, "crmToken");
    } else {
      failToast("Please update emplyee ID for create SRN");
    }
  } else {
    //DOA
    return await Put(`${crmApiBaseUrl}${salesReturn.convertTosrn}${_id}?${queryString}`, formData, "crmToken");
  }
};
