import React from "react";
import { _get } from "utils/lodashConst";
import { ImgOnline } from "assets/images";
import { paisaToAmount } from "utils/validation";
import InOutStockDetails from "./InOutStockDetails";

const OfflinePriceDetailsData = (props: any = {}) => {
  const { pincode = "", item_code = "", priceDetails = {}, productDetails = {} } = props;

  const price =
    _get(productDetails, "prices.sp", 0) > 0
      ? _get(productDetails, "prices.sp", 0)
      : _get(productDetails, "mrp.price", 0);
  const showroomPrice =
    _get(priceDetails, "prices.sp", 0) > 0 ? _get(priceDetails, "prices.sp", 0) : _get(priceDetails, "mrp.price", 0);

  const splPrice = +price > 0 ? showroomPrice - +price : 0;
  const onlineDiscount = splPrice > 0 ? (splPrice / showroomPrice) * 100 : 0;

  return (
    <div className="w-1/2 text-sm ">
      <div className="flex justify-center sm:justify-start items-center w-full mb-2">
        <div className="w-7 h-7 inline-block">
          <img src={ImgOnline} alt="online" />
        </div>
        <div className="flex flex-col justify-center ml-3">
          <span className="font-bold uppercase text-gray-900 text-xs">OFFLINE</span>
          <div className="flex gap-1 whitespace-nowrap items-center">
            <span className="text-green-500 font-bold text-sm">₹{price > 0 ? paisaToAmount(price + "00") : ""}</span>
            {/* in and out stock details  */}
            <InOutStockDetails pincode={pincode} item_code={item_code} />
          </div>
        </div>
      </div>

      {/* online save price */}
      {splPrice > 0 ? (
        <div className="mb-2 flex items-center justify-center sm:justify-start whitespace-nowrap">
          <span className="inline-block text-sm text-gray-600">Save :</span>
          <div className="flex text-green-500 font-bold text-sm">
            <span className=" ml-1 ">₹{paisaToAmount(splPrice + "00")}</span>
            {onlineDiscount && <div className=" font-bold text-sm ml-2">({onlineDiscount.toFixed(2)}%)</div>}
          </div>
        </div>
      ) : (
        <div className="flex mt-1" />
      )}
      {productDetails?.mrpArray?.[0]?.pre_order && (
        <span
          className="group text-sm mr-2 bg-green-500 hover:bg-green-600 font-bold py-1 px-2 text-white rounded inline-flex items-center"
          style={{ width: "8em" }}
        >
          Pre Order: {"₹ "}
          {paisaToAmount(productDetails?.mrpArray?.[0]?.preorder_amount + "00")}
        </span>
      )}
    </div>
  );
};

export default OfflinePriceDetailsData;
