import * as duplicateInvImeiType from "../actionTypes/duplicateInvImeiType";

/**
 * @param  {object} data;
 * imei num based get details
 */
export const DuplicateImeinumApiCallAction = (dupimeiNumber: number) => {
  return {
    type: duplicateInvImeiType.DUP_IMEI_NUMBER_BASED_GET_DETAILS,
    imei: dupimeiNumber
  };
};

/**
 * @param  {object} data;
 * StatusUpdateList based get details
 */
export const DuplicateStatusUpdateListAction = (obj: any = {}) => {
  return {
    type: duplicateInvImeiType.DUP_STATUS_LIST_BASED_GET_DETAILS,
    obj
  };
};
