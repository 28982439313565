import { PencilAltIcon, TrashIcon } from "@heroicons/react/outline";
import React, { useState } from "react";

function EditableLable(props: any = {}) {
  const { id = "" } = props;
  const [content, setContent] = useState("<p>Edit this content...</p>");

  const handleContentChange = (e: any) => {
    const { target: { innerHTML = "" } = {} } = e;
    setContent(innerHTML);
  };

  return (
    <label htmlFor={id} className="">
      <div
        className=""
        contentEditable={true}
        dangerouslySetInnerHTML={{ __html: content }}
        onChange={handleContentChange}
      />
      <div className="flex">
        <button
          type={"button"}
          className="flex-shrink-0  text-sm text-red-400 transition-all flex items-center justify-center rounded p-1 ml-2 "
          onClick={() => {}}
        >
          <TrashIcon className="w-4 h-4" />
        </button>
        <button
          type={"button"}
          className="flex-shrink-0  text-sm text-green-400 transition-all flex items-center justify-center rounded p-1 ml-2 "
          onClick={() => {}}
        >
          <PencilAltIcon className="w-4 h-4" />
        </button>
      </div>
    </label>
  );
}

export default EditableLable;
