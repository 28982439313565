import React from "react";

import { convertToLowerCase } from "utils/validation";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { onWheel } from "Component/SliderMenu/CommonFunctions";

const DealingsUpdatePopupTap = (props: any = {}) => {
  const { showImeiTrans, subCategory = "", popParentTabs = "", popParentTabsHandle = () => {} } = props || {};

  const generalInfoSubCat = "General Information";

  return (
    <div className="sticky -top-1 bg-white z-20 shadow-md">
      <ScrollMenu scrollContainerClassName={"flex m:justify-around white whitespace-nowrap"} onWheel={onWheel}>
        <span
          onClick={() => popParentTabsHandle("BasicInfo")}
          data-jest-id={"jestPopParentTabsHandleDealings"}
          className={`${
            convertToLowerCase(popParentTabs) === convertToLowerCase("BasicInfo")
              ? " border-b-2 border-yellow-600 font-bold"
              : ""
          }
                                         rounded-t-sm sm:text-center whitespace-nowrap px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
        >
          Basic Info
        </span>

        {subCategory !== generalInfoSubCat && (
          <a
            href="#EnquiryInfo"
            onClick={() => popParentTabsHandle("EnquiryInfo")}
            data-jest-id={"jestPopParentTabsHandleDealings2"}
            className={`${
              convertToLowerCase(popParentTabs) === convertToLowerCase("EnquiryInfo")
                ? " border-b-2 border-yellow-600 font-bold"
                : ""
            }
                            rounded-t-sm sm:text-center whitespace-nowrap px-2 py-2 h-9 flex items-center justify-center text-sm
                            cursor-pointer`}
          >
            Enquiry Info
          </a>
        )}

        {subCategory !== generalInfoSubCat && showImeiTrans && (
          <a
            href="#IMEIandMobile"
            onClick={() => popParentTabsHandle("IMEIandMobile")}
            data-jest-id={"jestPopParentTabsHandleDealings3"}
            className={`${
              convertToLowerCase(popParentTabs) === convertToLowerCase("IMEIandMobile")
                ? " border-b-2 border-yellow-600 font-bold"
                : ""
            }
                                rounded-t-sm sm:text-center whitespace-nowrap px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
          >
            IMEI and Mobile Enquiry History
          </a>
        )}

        <a
          href="#DelaingsStatusUpdate"
          onClick={() => popParentTabsHandle("DelaingsStatusUpdate")}
          data-jest-id={"jestPopParentTabsHandleDealings4"}
          className={`${popParentTabs === "DelaingsStatusUpdate" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                rounded-t-sm sm:text-center whitespace-nowrap px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
        >
          Dealings Status Update
        </a>
      </ScrollMenu>
    </div>
  );
};

export default DealingsUpdatePopupTap;
