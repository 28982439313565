import React from "react";
import "./MyErrorBoundary.css";

type ReactProps = {
  component: any;
  children: any;
};
type ReactState = {
  hasError: boolean;
};

class MyErrorBoundary extends React.Component<ReactProps, ReactState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { component = "" } = this.props;
    if (this.state.hasError) {
      return (
        <div className="flex justify-center items-center w-full h-full">
          {component ? (
            <div className="error-boundary-container">
              <h1>{`<${component} />`}</h1>
              An error has occured in {component} component.
              <div>Please visit and fix it as soon as possible..!</div>
            </div>
          ) : (
            <div className="page-level-error-boundary-container">
              <div>We are experiencing some technical difficulties. We will return shortly.!</div>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}

export default MyErrorBoundary;
