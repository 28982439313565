import React, { useEffect, useState } from "react";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import { convertToLowerCase, numOnlyAllow } from "utils/validation";
import { pinCodeBasedDeliveryTypeApiCall } from "../commonSalesProductDetails";
import DeliveryTypeView from "./DeliveryTypeView";
import CommonInput from "Component/Form/CommonInput";

const PinCodeBasedDeliveryType = (props: any = {}) => {
  const { pincode = "", item_code = "", hideInput = false } = props;

  const [deliveryType, setDeliveryType] = useState<any>([]);
  const [productDetailsState, setProductDetailsState] = useState({
    getPinCode: ""
  });

  /**
   * pin code based get delivery type
   */
  const getDeliveryTypeBasedOnpin = async (itemCode = "", pinCode = "") => {
    // event.preventDefault && event.preventDefault();
    const response = await pinCodeBasedDeliveryTypeApiCall(itemCode, pinCode);
    const { data: { data = [], success = 0 } = {} } = response || {};
    if (success === 200) {
      setDeliveryType(data); //get delivery type
    } else {
      setDeliveryType([]); //empty pass
    }
  };

  /**
   * pincode update on state value
   * @param event object
   */
  const piCodeUpdate = (event: any = {}) => {
    const { target: { name = "", value = "" } = {} } = event;
    setProductDetailsState({
      ...productDetailsState,
      [name]: value
    });

    if (value && item_code) {
      //pincode and item code fount api call
      if (convertToLowerCase(value)?.length > 4) {
        //api will hot morethen 4 char
        getDeliveryTypeBasedOnpin(item_code, value);
      }
    }
  };

  //pinCode based get stock details
  useEffect(() => {
    if (pincode && item_code) {
      piCodeUpdate({ target: { name: "getPinCode", value: pincode } }); //update pinCode
    }
  }, [pincode, item_code]);

  return (
    <div className="mb-2 w-full py-2 flex flex-col border-gray-100 border">
      <div
        className={`w-full border-b hover:bg-white text-yellow-500 rounded relative
                                                flex justify-between items-center mb-2 `}
      >
        <h3 className="w-full text-left px-2 pb-2 text-sm">Delivery Options</h3>
      </div>
      {!hideInput && (
        <CommonInput
          label={false}
          setShow={true}
          inputError={false}
          name={"getPinCode"}
          inputWithIcon={false}
          buttonType={"submit"}
          inputFieldClass="h-9"
          responseLoader={false}
          onChange={piCodeUpdate}
          parentClass={"mb-3 px-3"}
          labelTextClass={"font-bold"}
          placeholder={"Enter Pincode"}
          maxLength={staticData.pinCode}
          inputWithIconClass={"input-icon-btn"}
          value={productDetailsState.getPinCode} //value
          onKeyPress={numOnlyAllow}
          iconClickAction={(e: any) => getDeliveryTypeBasedOnpin(item_code, productDetailsState.getPinCode)}
        />
      )}
      <DeliveryTypeView deliveryType={deliveryType} />
    </div>
  );
};

export default PinCodeBasedDeliveryType;
