import store from "store";
import { nullToObject } from "utils/validation";
import { Get, Post, Put } from "ApiProcess/httpRestServices";
import { loginApi, netCoreMail, crmApiBaseUrl, encodeGetParams } from "../../ApiProcess/httpRestServices/apiManage";

/**
 * Get mail autofill datas from server
 * @returns autofills from server
 */
export const getMailAutoFillsService = (obj: any = {}) => {
  const { search = "" } = nullToObject(obj);
  const newObj = {
    search
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${netCoreMail.getAutoFills}?${queryString}`, "crmToken");
};

/**
 * Update mail with created enquiry
 * @returns updated mail from server
 */
export const updateMailByIdService = (obj: any = {}) => {
  const { id = "", enquiryId = "", enquiryFor = "", subCategory = "" } = nullToObject(obj);

  const enquiryData = {
    id: id,
    enquiryId: enquiryId,
    enquiryFor: enquiryFor,
    subCategory: subCategory
  };
  const queryString = encodeGetParams(enquiryData);
  return Put(`${crmApiBaseUrl}${netCoreMail.updateEnquiry}?${queryString}`, enquiryData, "crmToken");
};

/**
 * Update mail readed status
 * @returns updated mail from server
 */
export const updateReadedMailStatusService = (obj: any = {}) => {
  const { id = "", readed = "" } = nullToObject(obj);

  const enquiryData = {
    id: id,
    readed
  };
  const queryString = encodeGetParams(enquiryData);
  return Put(`${crmApiBaseUrl}${netCoreMail.updateEnquiry}?${queryString}`, enquiryData, "crmToken");
};

/**
 * Update mail starred status
 * @returns updated mail from server
 */
export const updateStarredMailService = (obj: any = {}) => {
  const { id = "", starred = "" } = nullToObject(obj);

  const enquiryData = {
    id: id,
    starred
  };
  const queryString = encodeGetParams(enquiryData);
  return Put(`${crmApiBaseUrl}${netCoreMail.updateEnquiry}?${queryString}`, enquiryData, "crmToken");
};

/**
 * Update mail bookmarked status
 * @returns updated mail from server
 */
export const updateBookmarkedMailService = (obj: any = {}) => {
  const { id = "", important = "", readed = "" } = nullToObject(obj);

  const enquiryData = {
    id: id,
    important,
    readed
  };
  const queryString = encodeGetParams(enquiryData);
  return Put(`${crmApiBaseUrl}${netCoreMail.updateEnquiry}?${queryString}`, enquiryData, "crmToken");
};

/**
 * @param  editUserDetails user details
 * edit logged in mail
 */
export const editLastLoggedInMail = (obj: any = {}) => {
  const { lastSelectedMail = "", id = "" } = nullToObject(obj);

  const newObj = {
    lastSelectedMail: lastSelectedMail,
    ignoreCurrentUserCheck: "t"
  };
  return Put(`${crmApiBaseUrl}${loginApi.editUser}${id}`, newObj, "crmToken");
};

/**
 * @param  editUserDetails user details
 * edit mail signature
 */
export const editUserMailSignature = (obj: any = {}) => {
  const { mailSignature = {}, id = "" } = nullToObject(obj);
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });

  const newObj = {
    mailSignature: mailSignature,
    ignoreCurrentUserCheck: "t"
  };
  return Put(`${crmApiBaseUrl}${loginApi.editUser}${id}`, newObj, "crmToken");
};

/**
 * @param  obj user details
 * edit mail groups
 */
export const editUserMailGroup = (obj: any = {}) => {
  const { mailGroup = {}, id = "" } = nullToObject(obj);
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });

  const newObj = {
    mailGroups: mailGroup,
    ignoreCurrentUserCheck: "t"
  };
  return Put(`${crmApiBaseUrl}${loginApi.editUser}${id}`, newObj, "crmToken");
};

/**
 * Check pop server mail credentials
 * @param obj mail credential data
 * @returns response from server
 */
export const checkPopMailCredentialService = (obj: any = {}) => {
  const {
    mailId = "",
    mailUserName = "",
    mailPassword = "",
    popMailHost = "",
    popMailPort = "",
    smtpMailHost = "",
    smtpMailPort = ""
  } = nullToObject(obj);

  const newObj = {
    mailId: mailId,
    mailUserName: mailUserName,
    password: mailPassword,
    popMailHost: popMailHost,
    popMailPort: popMailPort,
    smtpMailHost: smtpMailHost,
    smtpMailPort: smtpMailPort
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${netCoreMail.checkPopServer}?${queryString}`, "crmToken");
};

/**
 * Check smtp server mail credentials
 * @param obj mail credential data
 * @returns response from server
 */
export const checkSmtpMailCredentialService = (obj: any = {}) => {
  const {
    mailId = "",
    mailUserName = "",
    mailPassword = "",
    popMailHost = "",
    popMailPort = "",
    smtpMailHost = "",
    smtpMailPort = ""
  } = nullToObject(obj);

  const newObj = {
    mailId: mailId,
    mailUserName: mailUserName,
    password: mailPassword,
    popMailHost: popMailHost,
    popMailPort: popMailPort,
    smtpMailHost: smtpMailHost,
    smtpMailPort: smtpMailPort
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${netCoreMail.checkSmtpServer}?${queryString}`, "crmToken");
};
