import React from "react";
import { StyleFormTextarea } from "utils/ClassContent";
import CommonTextarea from "Component/Form/CommonTextarea";

const EnquiryServiceForRemark = (props: any) => {
  const { name = "", value = "", onChange = () => {}, enableViewMode = false } = props || {};

  return (
    <CommonTextarea
      name={name}
      label={true}
      value={value}
      setShow={true}
      onChange={onChange}
      labelText={"Remark"}
      inputWithIcon={false}
      readOnly={enableViewMode}
      labelTextClass={"font-bold"}
      inputClass="flex justify-end"
      parentClass={StyleFormTextarea()}
      mustField={enableViewMode ? false : true}
      inputFieldClass={enableViewMode ? "h-24 resize-none" : "h-32 max-h-64 min-h-textarea"}
    />
  );
};
export default EnquiryServiceForRemark;
