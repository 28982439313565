import React, { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { commonMultiChooseProps } from "../types";
import CooseLeftSideData from "./CooseLeftSideData";
import CooseRightSideData from "./CooseRightSideData";
import { convertToLowerCase } from "utils/validation";

const CommonMultiChoose = (props: commonMultiChooseProps) => {
  const {
    name = "",
    value = "",
    label = true,
    labelText = "",
    labelLeft = "",
    setShow = true,
    labelRight = "",
    inputClass = "",
    // EditView = false,
    placeholder = "",
    parentClass = "",
    mustField = false,
    enableSearch = false,
    // _editViewText = "",
    selectLeftId = "1",
    selectRightId = "2",
    inputError = false,
    labelTextClass = "",
    inputvalid = false,
    seletedLanuage = [],
    editSubProblem = [],
    editProblem = [],
    enableViewMode = false,
    onChange = () => {}, //click,
    inputFieldClass = "",
    inputWithIcon = false,
    inputWithIconClass = "",
    chooseLangage = () => {}, //click
    deselectLanguage = () => {}, //click
    handleLeftSearch = () => {} //click
  } = props || {};
  const optionClass = `px-3 py-1 w-full text-left text-xs cursor-pointer hover:bg-yellow-50 transition duration-200`;

  //select lanuage here
  const getSelectValues = (event: any = {}, dataId = "") => {
    const dataIdGet = dataId;
    const dataParse = JSON.parse(dataIdGet);
    chooseLangage(dataParse);
  };

  //deselect language handle here
  const deselectValue = (event: any = {}, dataId = "") => {
    const dataIdGet = dataId;
    const dataParse = JSON.parse(dataIdGet);
    deselectLanguage(dataParse);
  };

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative w-full ${parentClass ? parentClass : ""}`}
    >
      {label && (
        <label
          className={` ${enableViewMode ? "" : "pl-4"} block  text-xs input-label mb-1
                    ${labelTextClass ? labelTextClass : ""}
                    ${inputError && !inputvalid ? " text-error" : ""}
                    ${inputvalid && !inputError ? " text-valid" : ""}`}
          htmlFor={`multiSelect-${selectLeftId}`}
        >
          {labelText}
          {mustField && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      <div
        className="flex flex-wrap relative w-full text-sm break-words overflow-auto"
        style={{ maxHeight: enableViewMode ? "80px" : "" }}
      >
        {enableViewMode ? (
          <Fragment>
            {editProblem &&
              editProblem?.map((ele: any, index: number) => <React.Fragment key={convertToLowerCase(index + "editProblem-ssdjh")}>{ele}, </React.Fragment>)}

            {editSubProblem &&
              editSubProblem?.map((ele: any, index: number) => <React.Fragment key={convertToLowerCase(index + "editSubProblem-ssdjh")}>{ele}, </React.Fragment>)}
          </Fragment>
        ) : (
          <Fragment>
            <span className="input-icon-color-fill cursor-pointer flex flex-col absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2" />
            <CooseLeftSideData
              name={name}
              value={value}
              onChange={onChange}
              labelLeft={labelLeft}
              labelRight={labelRight}
              inputError={inputError}
              inputvalid={inputvalid}
              inputClass={inputClass}
              GetData={seletedLanuage}
              optionClass={optionClass}
              placeholder={placeholder}
              enableSearch={enableSearch}
              selectLeftId={selectLeftId}
              selectRightId={selectRightId}
              chooseLangage={chooseLangage}
              inputWithIcon={inputWithIcon}
              getSelectValues={getSelectValues}
              inputFieldClass={inputFieldClass}
              handleLeftSearch={handleLeftSearch}
              inputWithIconClass={inputWithIconClass}
            />

            <CooseRightSideData
              name={name}
              value={value}
              mustField={true}
              onChange={onChange}
              labelLeft={labelLeft}
              labelRight={labelRight}
              inputError={inputError}
              inputvalid={inputvalid}
              inputClass={inputClass}
              GetData={seletedLanuage}
              optionClass={optionClass}
              placeholder={placeholder}
              selectLeftId={selectLeftId}
              selectRightId={selectRightId}
              enableSearch={enableSearch}
              inputWithIcon={inputWithIcon}
              getSelectValues={deselectValue}
              inputFieldClass={inputFieldClass}
              inputWithIconClass={inputWithIconClass}
            />
          </Fragment>
        )}
      </div>
    </Transition>
  );
};

export default CommonMultiChoose;
