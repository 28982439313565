import { useEffect, useState } from "react";
import { moment } from "utils/momentConst";
import { convertToLowerCase } from "utils/validation";
import "./styles.scss";

interface ProductOfferProp {
  offers: any;
  index: number;
}

const ProductOffersGroup = (props: ProductOfferProp) => {
  const { offers, index } = props;
  const [showTc, setShowTc] = useState<boolean>(false);

  const [ProductOffersGroupState, setProductOffersGroupState] = useState<any>({
    showOffer: false
  });

  const { showOffer = false } = ProductOffersGroupState;

  const stageManage = (name = "", value: any = "") => {
    const newObj = {
      ...ProductOffersGroupState,
      [name]: value
    };
    setProductOffersGroupState(newObj);
  };

  useEffect(() => {
    stageManage("offer", offers);
  }, [offers]);

  return (
    <div className="px-4 py-2">
      <div className="flex justify-between font-semibold text-white p-2 text bg-primary-gradient rounded-sm">
        <span>
          {index === 0 ? "Best Grouped Offer" : "Max Grouped Offer" + index}
          <p className="dateText">{`(Ends on ${moment(offers.endDate).format("DD-MMM-YYYY hh:mm A")})`}</p>
        </span>

        <span>Max Benefit Rs. {offers?.offerMaxBenefits || 0}</span>
      </div>
      <div className="font-bold mr-1 capitalize border border-gray-200">
        {offers.offers.map((item: any, i) => {
          return (
            <div key={convertToLowerCase(i) + "offergroup"} className="offergroup-content p-2">
              <div className="flex justify-between p-2">
                <div className="offer-name">
                  <span>{item?.offerName}</span>
                  <button
                    type="button"
                    className="text-blue-500 underline ml-1"
                    onClick={() => {
                      setShowTc(!showTc);
                    }}
                  >{`Terms & Conditions`}</button>
                </div>
                <div className="text-green-500 font-bold text-sm">
                  {item.offerType !== "Offer Text" && item.offerType !== "Standard EMI" && (
                    <span>{`₹ ${item?.maxBenefites || 0} `}</span>
                  )}
                </div>
              </div>
              {showTc && (
                <div
                  className="show-tc overflow-auto max-w-full p-5"
                  dangerouslySetInnerHTML={{ __html: item?.termsConditions }}
                />
              )}

              {(item.offerType === "Combo Offer" || item.offerType === "freebies") && (
                <div className="newdiv">
                  <div className="offer-group-list">
                    <div className="offer-expansion">
                      <span className="offer-type cursor-pointer">{item.offerType}</span>
                      <span className="cursor-pointer" onClick={() => stageManage("showOffer", !showOffer)}>
                        {" "}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductOffersGroup;
