import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

const PriceRangeOffer = (props: any = {}) => {
  const { min = 0, max = 0, searchAmount = {}, pricerangeBarSeach = () => {} } = props;
  const { minAmount = 0, mxAmount = 0 } = searchAmount;

  return (
    <Range
      marks={{
        100: `₹ 1`,
        500: `₹ ${max}`
      }}
      min={min}
      max={max}
      value={[minAmount, mxAmount]}
      defaultValue={[min, max]}
      tipFormatter={(value) => `₹ ${value}`}
      onChange={(logs) => pricerangeBarSeach(logs)}
    />
  );
};
export default PriceRangeOffer;
