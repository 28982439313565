import { moment } from "utils/momentConst";
import React from "react";
import { headerButton } from "utils/ClassContent";
import { TrackingBorderDataProps } from "../../types";

const TrackingBorderData = (props: TrackingBorderDataProps) => {
  const {
    handleBack = () => {},
    enableModal = false,
    trackingBorderDatas: { order_id = "", created_date = "" } = {}
  } = props || {};
  return (
    <div className="text-white flex justify-between bg-primary-gradient items-center ">
      <div className="flex justify-between flex-grow flex-col sm:flex-row">
        <h3 className="w-full md:w-auto md:py-2 py-1 text-base px-4  whitespace-nowrap">
          {order_id && <>Order Id :</>} {order_id}
        </h3>
        <h3 className="w-full h-9 md:w-auto md:py-2 py-1 text-base px-4 md:text-right flex md:justify-end  whitespace-nowrap">
          {order_id && <> Ordered on :</>}
          <span className="">{created_date ? moment(created_date).utc().format("DD/MM/YYYY") : created_date}</span>
        </h3>
      </div>
      {enableModal && (
        <button
          onClick={() => handleBack(false)}
          data-jest-id={"jestHandleBack"}
          className={headerButton + " mr-3 "}
          type="button"
        >
          Close
        </button>
      )}
    </div>
  );
};

export default TrackingBorderData;
