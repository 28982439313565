import React, { useState } from "react";
import { ameyoProps } from "./types";
import AmeyoPopup from "./AmeyoPopup";
import { ameyoCall } from "./CommonAmoyo";
import { useSelector } from "react-redux";
import { Decrypt } from "utils/secureFile";
import { nullToObject } from "utils/validation";
import Loader from "Component/Loader";
import CopyToClipboard from "../CopyToClipboard";
import { PhoneIcon } from "@heroicons/react/outline";
import { decodeMobileNo } from "Component/utility/commonUtility";

const AmoyoCall = (props: ameyoProps) => {
  const { id = "", phone = "", campaignId = "", showPhone = true, isShowSensInfo = true } = nullToObject(props);

  const userLoginDetails = Decrypt("userLoginData");
  const { data: { empId = "" } = {} } = nullToObject(userLoginDetails);
  const globalSate = useSelector((state: any) => state);

  const { ameyoReducer = {} } = nullToObject(globalSate); //globalStore
  const { ameyoLoader = false } = ameyoReducer;
  const { loginReducer: { getRegisterUserDetailsData = {} } = {} } = nullToObject(globalSate);
  const { data: employeeListData = [] } = getRegisterUserDetailsData;

  const [popupOpen, setPopupOpen] = useState("");

  const phoneNumUpdate = () => {
    setPopupOpen(phone);
  };

  /**
   * handle user call
   */
  const handleCall = (item: any = {}) => {
    // handle user call
    ameyoCall({ phone: phone, id: id, campaignId: campaignId, ...item });
    setPopupOpen("");
  };

  return (
    <React.Fragment>
      {ameyoLoader && <Loader />}

      {phone ? (
        <CopyToClipboard copyText={isShowSensInfo ? phone : decodeMobileNo({ phone, isShowSensInfo })}>
          <button type={"button"} onClick={phoneNumUpdate} className="flex flex-auto text-blue-500">
            <PhoneIcon className="w-4 h-4 mr-1" />
            {showPhone && <span>{decodeMobileNo({ phone, isShowSensInfo })}</span>}
          </button>
        </CopyToClipboard>
      ) : null}

      {popupOpen ? (
        <AmeyoPopup
          userId={empId}
          employeeListData={employeeListData}
          closePopup={(e: any) => setPopupOpen(e)}
          handleCall={handleCall}
        />
      ) : null}
    </React.Fragment>
  );
};

export default AmoyoCall;
