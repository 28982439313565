import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { TableClass, thClass, tdClass } from "utils/ClassContent";
import { enquiryStatusTrackingTableProps } from "../ServiceCallsTypes";
import { convertToLowerCase, dateTimeDisplay } from "utils/validation";

const MessageDeliveryHistory = (props: enquiryStatusTrackingTableProps) => {
  const { customerData = {}, getModalStatus = false } = props || {};

  const [deliveryHistory, setDeliveryHistory] = useState([]);

  useEffect(() => {
    setDeliveryHistory(_get(customerData, "deliveryHistory", []));
  }, [customerData]);

  return (
    <div
      className="block w-full overflow-x-auto relative "
      style={{ minHeight: "250px", height: getModalStatus ? "250px" : "100%" }}
    >
      <table className={TableClass}>
        <thead>
          <tr>
            <th className={thClass}>Type</th>
            <th className={thClass}>Contact</th>
            <th className={thClass}>Date</th>
            <th className={thClass}>Status</th>
          </tr>
        </thead>
        {deliveryHistory.map((ele: any, i: number) => {
          const {
            mode = "",
            Mode = "", // will remove this line once backend changes reflected
            contactDetail = "",
            date = "",
            status = "",
            Status = "" // will remove this line once backend changes reflected
          } = ele;
          return (
            <tbody key={convertToLowerCase(i + "transactions-fsc")}>
              <tr tabIndex={1} data-jest-id={"jestHandleViewOldStatus"}>
                <td style={{ minWidth: "200px" }} className={tdClass}>
                  {mode || Mode}
                </td>
                <td style={{ minWidth: "200px" }} className={tdClass}>
                  {contactDetail}
                </td>
                <td style={{ minWidth: "100px" }} className={tdClass}>
                  <span className="w-32">{dateTimeDisplay(date)}</span>
                </td>
                <td style={{ minWidth: "200px" }} className={tdClass}>
                  {status || Status}
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default MessageDeliveryHistory;
