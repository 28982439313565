import React, { useEffect, useState, forwardRef } from "react";
import { moment } from "utils/momentConst";
import DatePicker from "react-datepicker";
import { nullToObject } from "utils/validation";
import "react-datepicker/dist/react-datepicker.css";

const CustomInput = forwardRef(({ value, onClick, placeholder }: any, ref: any) => (
  <input
    ref={ref}
    value={value}
    onClick={onClick}
    placeholder={placeholder}
    autoComplete="off" // Disable autocomplete
    className="block w-60 rounded input-common pl-3 pr-7 py-2 text-sm h-8"
  />
));
CustomInput.displayName = "CustomInput";
const CommonSingleDatePicker = (props: any = {}) => {
  const {
    id = "",
    maxDate = null,
    minDate = null,
    startDate = null,
    readOnly = false,
    placeholder = "",
    parentClass = "",
    timeIntervals = 30,
    isFilterTime = false,
    showTimeSelect = true,
    dateChange = () => {},
    dateFormat = "d MMM, yyyy h:mm aa"
  } = nullToObject(props);

  const [maxDates, setMaxDates] = useState<any>(null);
  const [minDates, setMinDates] = useState<any>(null);

  const onChange = (date: any = {}) => {
    dateChange(date);
  };

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return isFilterTime || currentDate.getTime() < selectedDate.getTime();
  };

  useEffect(() => {
    if (maxDate) {
      setMaxDates(true);
    }

    if (minDate) {
      setMinDates(true);
    }
  }, [maxDate, minDate]);

  return (
    <DatePicker
      id={id}
      maxDate={maxDates}
      readOnly={readOnly}
      dateFormat={dateFormat}
      filterTime={filterPassedTime}
      timeIntervals={timeIntervals}
      showTimeSelect={showTimeSelect}
      calendarClassName={parentClass}
      onChange={(date) => onChange(date)}
      minDate={minDates ? moment().toDate() : null}
      placeholderText={placeholder ? placeholder : ""}
      selected={startDate ? new Date(startDate) : null}
      customInput={<CustomInput placeholder={placeholder} />}
      className={`block w-60 rounded input-common pl-3 pr-7 py-2 text-sm h-8`}
    />
  );
};
export default CommonSingleDatePicker;
