import { Transition } from "@headlessui/react";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { convertToLowerCase, convertToString, paisaToAmount } from "utils/validation";
import EmiTable from "./EmiTable";

export type EmiBankDetailsProps = {
  emiData?: any;
  _activeTab?: string;
  setShow?: boolean;
  nbfcTotalData?: any;
  showEmiMoreDetailsClick?: any;
};

const EmiBankDetailsPop = (props: EmiBankDetailsProps) => {
  const {
    emiData = {},
    setShow = true,
    _activeTab = "",
    nbfcTotalData = [],
    showEmiMoreDetailsClick = () => {}
  } = props || {};
  const { emiDetails = [] } = emiData;

  const [bankName, setBankName] = useState("");
  const [bankList, setBankList] = useState([]);
  const [activeTab, setActiveTab] = useState("tab1");
  const [emiTableData, setEmiTableData] = useState({});
  const [nbfcActiveTab, setNbfcActiveTab] = useState<any>("");

  const nbfcColumnDefiniton = [
    {
      name: "Scheme",
      datakey: "schemeName"
    },
    {
      name: "Down Payment",
      datakey: "initialPayment",
      type: "amount",
      postText: "Months",
      post_data_key: "initalEmiMonths"
    },
    {
      name: "Processing Fee",
      datakey: "processingFees",
      type: "amount"
    },
    {
      name: "Per Month EMI",
      datakey: "emiPerMonth",
      type: "amount"
    },
    {
      name: "Total EMI",
      datakey: "totalEmiAmt",
      type: "amount"
    }
  ];

  //after search bank detail or select bank
  const seletedValue = (item: any = {}) => {
    setBankName(_get(item, "optionData", ""));
    setEmiTableData(item.optionValue);
  };

  const constractBankDropDown = (itm: any = []) => {
    const options = [] as object[];
    itm.forEach((item: any = "", index = 0) => {
      options.push({
        id: index + 1,
        isSelect: false,
        optionData: item?.name,
        optionValue: item
      });
    });
    return options;
  };

  /**
   * no cost emi and standEmi tab chnage
   * @param str string
   */
  const handleTabs = (tab = "", emiObj: any = {}) => {
    const dropDownVal = constractBankDropDown(emiObj?.providers);
    setActiveTab(tab); //tap active
    setNbfcActiveTab("");
    setBankList(dropDownVal);
    setBankName(_get(emiObj, "providers.[0].name", "")); //banck name
    setEmiTableData(_get(dropDownVal, "[0].optionValue", {}));
  };

  const nbfcHandleTabs = (str = "") => {
    setNbfcActiveTab(str);
  };

  const setEmiDataToTable = (initialRender = false, lowestEMIOfferType: any = "", lowestEMIOfferedBy: any = "") => {
    const emiDetailsIndex: any = emiDetails.findIndex((data: any) => data.type === lowestEMIOfferType);
    const providers = emiDetails[emiDetailsIndex]?.providers || [];
    const dropDownVal = constractBankDropDown(providers);
    const providersIndex = emiDetails[emiDetailsIndex]?.providers.findIndex(
      (bankType: any) => bankType.name === lowestEMIOfferedBy
    );
    const bank = emiDetails[emiDetailsIndex]?.providers[providersIndex]?.name;
    const selectedDropdownValue: any = dropDownVal.find((val: any) => val.optionData === lowestEMIOfferedBy);
    initialRender && setActiveTab("tab" + (emiDetailsIndex + 1));
    setBankName(bank);
    setBankList(dropDownVal);
    setNbfcActiveTab("");
    setEmiTableData(selectedDropdownValue.optionValue);
  };

  useEffect(() => {
    if (emiDetails?.length) {
      const { lowestEMIOfferType = "", lowestEMIOfferedBy = "" } = emiData;
      setEmiDataToTable(true, lowestEMIOfferType, lowestEMIOfferedBy);
    }
  }, []);

  useEffect(() => {
    if (_activeTab) {
      setActiveTab(_activeTab);
    }
  }, [_activeTab]);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      className="bg-blueGray-50 w-full"
      leave="transition-opacity duration-200"
      enter="transition-opacity duration-200"
    >
      <div className="w-full xl:mb-0 sm:px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white border mx-1 w-full mb-6 rounded ">
          <div className="w-full overflow-auto">
            <div className="flex flex-wrap">
              {/* stanted no/coast collection */}
              {(emiDetails || []).map((emi: any, index: number) => (
                <button
                  key={convertToString(index + "emi")}
                  type="button"
                  onClick={(e) => handleTabs("tab" + (index + 1), emi)}
                  className={` text-xs mr-2 py-1 px-6 rounded-t-sm font-normal whitespace-nowrap
                                ${activeTab === "tab" + (index + 1) ? " bg-primary text-white " : "bg-gray-200"}`}
                >
                  {emi.type}
                </button>
              ))}

              {nbfcTotalData.map((data: any, index: any) => {
                if (index === 0 && activeTab === "custom" && nbfcActiveTab === "") {
                  nbfcHandleTabs(data?._id);
                }
                return (
                  <button
                    key={convertToLowerCase("tab" + index)}
                    type="button"
                    onClick={(e) => {
                      setActiveTab("custom");
                      nbfcHandleTabs(data?._id);
                    }}
                    className={` text-xs mr-2 py-1 px-6 rounded-t-sm font-normal whitespace-nowrap
                                ${activeTab === "custom" ? " bg-primary text-white " : "bg-gray-200"}`}
                  >
                    {data?._id || ""}
                  </button>
                );
              })}
            </div>
          </div>

          {activeTab !== "custom" ? (
            <div className="w-full ">
              <CommonSearchDropdown
                setShow={setShow}
                value={bankName}
                inputWithIconClass="w-10"
                name={"bankDetailString"}
                defaltSeleted={"--None--"}
                labelText={"Bank Details"}
                arrayData={bankList}
                inputFieldClass=" h-9 pr-11 "
                labelTextClass={"font-bold"}
                inputClass="flex justify-end"
                handleParentDropVal={seletedValue}
                parentClass={"mb-3 p-4 w-full xl:w-1/2"}
              />

              <EmiTable emiTableData={emiTableData} showEmiMoreDetailsClick={showEmiMoreDetailsClick} />
            </div>
          ) : (
            <div className="w-full overflow-auto">
              <table className="w-full pt-3">
                <tbody>
                  <tr className="">
                    {nbfcColumnDefiniton.map((el, indf) => (
                      <td
                        key={convertToString(indf + "ytableColumnDefiniton-head-hk")}
                        className=" whitespace-nowrap border-0.5 border-gray-500 bg-yellow-200 p-2"
                      >
                        <span className="text-black text-xs text-center">{el?.name}</span>
                      </td>
                    ))}
                  </tr>
                  {nbfcTotalData?.map(
                    (data: any) =>
                      data._id === nbfcActiveTab &&
                      data?.schemas.map((item: any, ind: any) => (
                        <tr key={convertToString(ind) + "sftableColumznDefiniton-6790"}>
                          {nbfcColumnDefiniton.map((elem: any, j: any) => {
                            return (
                              <React.Fragment key={convertToString(j) + "sftableColumnDefiniton-6790"}>
                                <td className=" border-t border-gray-500 border-opacity-20 p-2">
                                  <div className="flex items-center">
                                    {elem.type === "amount" ? (
                                      <span className="text-gray-600 text-xs text-center">
                                        ₹{paisaToAmount(item[elem?.datakey] + "00")}{" "}
                                        {elem?.post_data_key ? ` (${item[elem?.post_data_key]} ${elem.postText})` : ""}
                                      </span>
                                    ) : (
                                      <span className="text-gray-600 text-xs text-center">{item[elem?.datakey]}</span>
                                    )}
                                  </div>
                                </td>
                              </React.Fragment>
                            );
                          })}
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </Transition>
  );
};

export default EmiBankDetailsPop;
