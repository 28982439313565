import store from "store";
import { Get, Post } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { crmApiBaseUrl, encodeGetParams, scoreCardList } from "ApiProcess/httpRestServices/apiManage";
import {
  lockScoreCardAction,
  deleteScoreCardAction,
  createScoreCardResponseAction
} from "store/action/scoreCardAction";

/**
 * get evaluator list
 */
export const getEvaluatorListApiService = (filter: any = {}) => {
  const { page = 1, size = 10, search = "", endDate = "", startDate = "", userId = [], scoreCardId = [] } = filter;
  const newObj = {
    page,
    size,
    search,
    userId,
    scoreCardId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.scorecardEvaluator}?${convertString}`, "crmToken");
};

/**
 * @param  {object} obj;
 * services center list
 */
export const getScoreDataApiCallApiServices = (obj: any = {}) => {
  const queryString = encodeGetParams(obj);

  return Get(`${crmApiBaseUrl}${scoreCardList.serviceCenterList}?${queryString}`, "crmToken");
};

/**
 * get scorecard by ID
 * @param obj
 */
export const getScoreCardByIdApiServiceCall = (id: any = {}) => {
  return Get(`${crmApiBaseUrl}${scoreCardList.create}/${id}?`, "crmToken");
};

/**
 * reset scorecard
 */
export const resetScorecard = () => {
  store.dispatch({
    type: "SCORECARD_LIST_DATA",
    scoreCardData: {}
  });
};

/**
 * get scorecard by ID
 * @param obj
 */
export const getScoreCardServiceByIdServiceCall = (scoreId) => {
  return Get(`${crmApiBaseUrl}${scoreCardList.scorecardresponse}/${scoreId}?`, "crmToken");
};

/**
 * create service center api
 * @param obj
 */
export const scoreCardCreateApiService = (data: any = {}) => {
  const {
    _id = "",
    name = "",
    type = "",
    description = "",
    groups = "",
    totalQuestion = 0,
    questions = [],
    settings = {},
    dynamicFields = []
  } = data;
  const reqObj = {
    name,
    type,
    description,
    totalQuestion,
    groups,
    questions: questions,
    settings: settings,
    dynamicFields: dynamicFields
  };
  return _id
    ? Post(`${crmApiBaseUrl}${scoreCardList.create}/${_id}`, reqObj, "crmToken")
    : Post(`${crmApiBaseUrl}${scoreCardList.create}`, reqObj, "crmToken");
};

/**
 * submit score card response
 * @param data
 */
export const submitScoreCardResponse = (data = {}, _id = "") => {
  store.dispatch({ type: "SCORECARDRESP_CREATE_SUCCESS", scoreCardRespSuccess: false });
  store.dispatch(createScoreCardResponseAction(data, _id));
};

/**
 * delete score card
 * @param data
 */
export const deleteScoreCard = (data = "") => {
  store.dispatch({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: true }); //send email loader
  store.dispatch({ type: "SCORECARD_CREATE_SUCCESS", scoreCardSuccess: false });
  store.dispatch(deleteScoreCardAction(data));
};

export const lockScoreCard = (data = {}) => {
  store.dispatch({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: true }); //send email loader
  store.dispatch({ type: "SCORECARD_LOCK_SUCCESS", lockScoreCardSuccess: false });
  store.dispatch(lockScoreCardAction(data));
};

/**
 * @param  {object} obj;
 * services center list
 */
export const getscoreCardResponseApiServices = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    endDate = "",
    startDate = "",
    userId = [],
    status = [],
    evaluator = [],
    scoreCardId = []
  } = obj || {};
  const newObj = {
    page,
    size,
    search,
    userId,
    status,
    evaluator,
    scoreCardId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.scorecardresponse}?${convertString}`, "crmToken");
};

/**
 * @param  {object} obj;
 * score card dashboard evaluator
 */
export const getEvaluatorDashboardApiServices = () => {
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardEvalualtor}`, "crmToken");
};

/**
 * @param  {object} obj;
 * score card dashboard evaluator
 */
export const getAgentDashboardApiServices = () => {
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardAgent}`, "crmToken");
};

// /**
//  * get dashboard
//  */
// export const getDashboardNotification = (userSearch = {}) => {
//   store.dispatch({
//     type: "GET_SCORECARD_LIST_LOADER",
//     scoreCardLoader: true
//   }); //loader
//   store.dispatch(dashboardNotificationAction(userSearch));
// };
/**
 * @param  {object} obj;
 * get Notification
 */
export const getDashboardNotificationApiServices = () => {
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardNotification}`, "crmToken");
};

/**
 * @param  {object} obj;
 * score card kudos by staff
 */
export const getKudosDashboardApiServices = (data: any = {}) => {
  const queryString = commonData(data);
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardKudos}?${queryString}`, "crmToken");
};

/**
 * @param  {object} obj;
 * get General
 */
export const getDashboardGeneralApiServices = (data: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    evaluator = "",
    endDate = "",
    startDate = "",
    userId = [],
    scoreCardId = []
  } = data || {};
  const newObj = {
    page,
    size,
    search,
    userId,
    evaluator,
    scoreCardId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardGeneral}?${convertString}`, "crmToken");
};

const commonData = (data = {}) => {
  const queryStr = {};
  Object.keys(data).forEach((itm: any = "") => {
    if (data[itm]) {
      queryStr[itm] = data[itm];
    }
  });
  return encodeGetParams(queryStr);
};

/**
 * score card kudos by staff
 */
export const getDashboardEvaluationPerPeriodService = (data: any = {}) => {
  const queryString = commonData(data);
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardEvaluationPerPeriod}?${queryString}`, "crmToken");
};
