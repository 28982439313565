import React, { useState } from "react";
import { CopyToClipboardProps } from "./types";
import { IconCopy2, IconTriangle } from "assets/images";

const CopyToClipboard = (props: CopyToClipboardProps = {}) => {
  const { copyText = "", children } = props || {};
  const [copyTooltip, setCopyTooltip] = useState(false);

  // this is for copy functionality
  const handleCopy = () => {
    setCopyTooltip(true);
    navigator.clipboard.writeText(copyText);
    setTimeout(() => {
      setCopyTooltip(false);
    }, 1500);
  };
  return (
    <div className={`${copyTooltip ? " z-10 relative " : " "} flex items-center copyIconHover`}>
      {children}
      <div onClick={handleCopy} className="copyIcon cursor-pointer relative">
        <IconCopy2 className="w-4 h-4 text-blue-500 ml-1" />
        {copyTooltip && (
          <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 flex flex-col items-center mb-6">
            <div className="relative px-1 py-0.5 rounded text-xxs text-white whitespace-no-wrap bg-blue-500 shadow-lg">
              Copied!
              <IconTriangle />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CopyToClipboard;
