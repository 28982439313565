import React, { useEffect, useState } from "react";
import Loader from "Component/Loader";
import { useLocation } from "react-router-dom";
import ActionPopup from "Component/ActionPopup";
import { XIcon } from "@heroicons/react/outline";
import ModalFixed from "Component/Modal/ModalFixed";
import { PlusCircleIcon } from "@heroicons/react/solid";
import { CALL_ROLES, CHAT_ROLES } from "utils/VariableConstant";
import { successToast } from "Component/ToastServices/ToastDisplay";
import { nullToObject, firstLetterUpperCase } from "utils/validation";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import { getRegisterUserDetailService } from "utils/services/loginServices";

import {
  getAllTeams,
  getAllTeamMembers,
  addTeamMember,
  createTeam,
  deleteTeam,
  getTeamMembers,
  deleteTeamMember
} from "../helper";
const initialDropdownCount = 6;

const getTextBetweenParentheses = (text: any = "") => {
  const regex = /\(([^)]+)\)/;
  const matches = text.match(regex);
  if (matches && matches.length > 1) {
    return matches[1];
  } else {
    return null;
  }
};

const TeamHigherHierarchy = (props: any = {}) => {
  const [getDeletePopup, setDeletePopup]: any = useState({ show: false });
  const [userDropProps, setUserDropProps] = useState([]);
  const [teamLead, setTeamLead] = useState(Array(initialDropdownCount).fill(""));
  const [hierarchy] = useState(Array(initialDropdownCount).fill(null));
  const location: any = useLocation();
  const { pathname = "" } = nullToObject(location);
  const pageType = pathname.includes("chat") ? "chat" : "call";

  const [allTeamMembers, setAllTeamMembers] = useState<any>({});
  const [teamMembers, setTeamMembers] = useState<any>({});
  const [createdTeamData, setCreatedTeamData] = useState<any>({});
  const [loader, setLoader] = useState<boolean>(false);

  const userIdFilter = async (index: number, event: any = {}) => {
    const { originalArray = [], filterArray = [] } = event;
    const currTeam = [...teamLead];
    if (filterArray.length) {
      const filterCheck = originalArray.filter((ele: any) => ele.isCheck === true);
      [currTeam[index]] = filterCheck;
      const requestObj = {
        leadId: filterCheck?.[0]._id,
        name: filterCheck?.[0].itemName,
        pageType: pageType
      };
      setLoader(true);
      const response = await createTeam(requestObj);
      setLoader(false);

      const {
        data: { data = {}, status = 0 }
      } = nullToObject(response);
      if (status === 201) {
        const newArray = originalArray.filter((ele: any) => ele.isCheck === false);
        currTeam[index] = { ...currTeam?.[index], teamId: data?._id };
        setUserDropProps(newArray);
        setCreatedTeamData(data);
      }
    } else {
      setDeletePopup({ show: true, type: "team", team: currTeam[index] });
    }
    setTeamLead(currTeam);
  };

  const addTeamMembersToTeam = async (team: any, event: any, index: number) => {
    const { originalArray = [], filterArray = [] } = event;
    if (filterArray.length) {
      const currTeam = [...teamLead];
      const filterCheck = originalArray.filter((ele: any) => ele.isCheck === true);
      const requestObj = {
        teamId: team?.teamId || createdTeamData._id,
        members: filterCheck
      };
      setLoader(true);

      const response = await addTeamMember(requestObj);
      setLoader(false);

      const {
        data: { status = 0 }
      } = nullToObject(response);
      if (status === 201) {
        const newMembers = [...(currTeam[index].members || []), ...filterCheck];
        currTeam[index].members = newMembers;
        currTeam[index].memberCount = newMembers.length + 1;
        setTeamLead(currTeam);
      }
    }
    const newArray = originalArray.filter((ele: any) => ele.isCheck === false);
    setUserDropProps(newArray);
  };

  useEffect(() => {
    // Push team members under clicked team
    if (teamMembers && Object.keys(teamMembers)?.length) {
      const idx = teamLead.findIndex((el: any) => el.teamId === teamMembers.teamId);
      if (idx > -1) {
        const currTeam = [...teamLead];
        const mappedMembers = teamMembers.members.reduce((filtered: any, member: any) => {
          if (member.role !== "lead") {
            filtered.push({
              isCheck: false,
              _id: member.userId.empId,
              itemName: member.userId.name,
              tmp: true
            });
          }
          return filtered;
        }, []);
        currTeam[idx].members = mappedMembers;
        currTeam[idx].showMembers = true;

        setTeamLead(currTeam);
      }
    }
  }, [teamMembers]);

  useEffect(() => {
    // To remove all existing team member from the dropdown
    if (allTeamMembers?.length) {
      const newFilteredUsers = userDropProps.filter(
        (user: any) => !allTeamMembers.map((member: any) => member.userEmpId).includes(user._id)
      );
      setUserDropProps(newFilteredUsers);
    }
  }, [allTeamMembers]);

  useEffect(() => {
    setTeamLead(Array(initialDropdownCount).fill(""));
    setUserDropProps([]);
    const newRoles = pageType === "chat" ? CHAT_ROLES : CALL_ROLES;

    getRegisterUserDetailService({ data: { size: 1000, ignoreCurrentUserCheck: "t", role: newRoles } }).then(
      async (res: any = {}) => {
        const { data: { data: { data = [] } = {} } = {} } = res;
        const mappedUserData = data.map((itm: any = {}) => ({
          isCheck: false,
          _id: itm.empId,
          itemName: `${itm.name} (${itm.empId})`
        }));
        setUserDropProps(mappedUserData);
        setLoader(true);

        const teamResponse = await getAllTeams({ pageType });
        setLoader(false);

        // TODO: Move to reuable Method
        const { data: { data: teamData = {}, status: respStatus = 0 } = {} } = nullToObject(teamResponse);
        if (respStatus === 200) {
          if (teamData?.length) {
            let index = 0;
            const currTeam = [...teamLead];
            teamData?.map((item: any) => {
              const matchingTeam = mappedUserData?.find((team: any) => team?._id === item.leadEmpId);
              if (matchingTeam) {
                currTeam[index] = {
                  teamId: item._id,
                  isCheck: true,
                  _id: matchingTeam._id,
                  itemName: matchingTeam.itemName,
                  memberCount: item.memberCount
                };
                index++;
              } else {
                currTeam[index] = "";
              }
              return true;
            });
            const finalFormattedTeam = [...currTeam.filter((el) => el !== ""), ...currTeam.filter((el) => el === "")];
            setTeamLead(finalFormattedTeam);
          }
          setLoader(true);

          const response = await getAllTeamMembers({ pageType });
          setLoader(false);

          const {
            data: { data: teamMembersData = {}, status = 0 }
          } = nullToObject(response);
          if (status === 200) {
            setAllTeamMembers(teamMembersData);
          }
        }
      }
    );
  }, [pageType, getRegisterUserDetailService]);

  const tabHandle = async (team: any = {}) => {
    if (team?.teamId) {
      setLoader(true);

      const response = await getTeamMembers(team?.teamId);
      setLoader(false);

      const {
        data: { data = {}, status = 0 }
      } = nullToObject(response);
      if (status === 200) {
        setTeamMembers(data);
      }
    } else {
      // Show members only when clicked
      const idx = teamLead.findIndex((el: any) => el._id === team?._id);
      const currTeam = [...teamLead];
      currTeam[idx].showMembers = true;
      setTeamLead(currTeam);
    }
  };

  const handleDeletePopup = (memberId: any, teamId: any) => {
    setDeletePopup({ show: true, type: "member", memberId, teamId });
  };

  const getMemberCount = (team: any) => {
    return team?.memberCount
      ? parseInt(team?.memberCount) - 1
      : (team?.members?.length > 0 ? team?.members?.length - 1 : 0) || 0;
  };

  const popupAction = async (value: any) => {
    setDeletePopup({ show: false });
    if (value) {
      const { type = "", teamId = "", team = {}, memberId = "" } = getDeletePopup;
      const currTeam = [...teamLead];
      if (type === "team") {
        let flag = false;
        if (team?.teamId) {
          setLoader(true);
          const response = await deleteTeam(team?.teamId);
          setLoader(false);
          const {
            data: { message = "", status = 0 }
          } = nullToObject(response);
          if (status === 200) {
            flag = true;
            successToast(message);
          }
        } else {
          flag = true;
        }

        if (flag) {
          const idx = teamLead.findIndex((el: any) => el.teamId === team?.teamId);
          if (idx > -1) {
            const deletedTeamMembers = (currTeam[idx].members || []).map((el: any) => ({ ...el, isCheck: false }));
            const deletedTeamLead = {
              isCheck: false,
              _id: currTeam[idx]._id,
              itemName: `${currTeam[idx].itemName} (${currTeam[idx]._id})`
            };
            const updatedUsers = [...userDropProps, deletedTeamLead, ...deletedTeamMembers];
            setUserDropProps(updatedUsers);
            currTeam[idx] = "";
            setTeamLead(currTeam);
          }
        }
      } else {
        setLoader(true);
        const response = await deleteTeamMember(memberId, { teamId });
        setLoader(false);
        const {
          data: { message = "", status = 0 }
        } = nullToObject(response);
        if (status === 200) {
          const idx = teamLead.findIndex((el: any) => el.teamId === teamId);
          if (idx > -1) {
            const deletedMembers = currTeam[idx].members.filter((ele: any) => ele._id === memberId);
            // Add removed member to original user's list
            if (deletedMembers.length) {
              const updatedUsers = [...userDropProps];
              const deletedMember = {
                isCheck: false,
                _id: deletedMembers[0]._id,
                itemName: `${deletedMembers[0].itemName} (${deletedMembers[0]._id})`
              };
              updatedUsers.push(deletedMember);
              setUserDropProps(updatedUsers);
            }
            currTeam[idx].members = currTeam[idx].members.filter((el: any) => el._id !== memberId);
            currTeam[idx].memberCount = currTeam[idx].members.length + 1;
            setTeamLead(currTeam);
          }
          successToast(message);
        }
      }
    }
  };

  return (
    <React.Fragment>
      {loader && <Loader />}
      <div className="flex flex-col w-full h-full">
        <div className=" w-full text-gray-800 flex-shrink-0 flex-grow-0">
          <div className="flex justify-between items-center border-b pb-1">
            <div className="flex justify-between items-center text-base font-bold">
              <h4>{firstLetterUpperCase(pageType)} Hierarchy</h4>
            </div>
          </div>
        </div>
        <div className=" flex flex-col pt-5 w-full flex-shrink-0 flex-grow">
          <div
            style={{ zIndex: 4 }}
            className=" flex-shrink-0  flex-grow-0 flex flex-row w-full flex-wrap gap-4 pb-3 border-b border-gray-200 relative "
          >
            {hierarchy.map((itm: any = {}, index = 0) => (
              <React.Fragment key={index}>
                <MultiDropDownNew
                  name={"_id"}
                  labeName={`Team Lead ${index + 1}`}
                  isSelectAll={false}
                  enableCheckbox={false}
                  isOKButtonEnable={false}
                  arrayData={teamLead[index] ? [teamLead[index]] : userDropProps}
                  filterArrayPass={(val: any) => userIdFilter(index, val)}
                />
              </React.Fragment>
            ))}
          </div>
          <div className="overflow-auto flex gap-4 flex-1">
            {teamLead.map((team, idex) => {
              const { itemName: tlName = "" } = team;
              return (
                team?._id && (
                  <div
                    style={{ width: "180px" }}
                    className=" border border-gray-100 h-full pt-3 flex-shrink-0  flex-grow-0"
                    key={`team_${team?._id}`}
                  >
                    <button
                      onClick={() => tabHandle(team)}
                      type="button"
                      className=" text-left w-full h-16 mx-auto bg-white rounded shadow-md overflow-hidden relative "
                      style={{ zIndex: 3 }}
                    >
                      <div className="flex w-full">
                        <div className="p-1 px-2 w-full">
                          <div className=" w-full capitalize flex leading-tight text-sm text-indigo-600 flex-col font-semibold overflow-hidden">
                            <span className="overflow-hidden overflow-ellipsis whitespace-nowrap flex-1 ">
                              {tlName.replace(getTextBetweenParentheses(tlName), "").replace("(", "").replace(")", "")}
                            </span>
                            <span className="flex-shrink-0">{getTextBetweenParentheses(tlName)}</span>
                          </div>
                          <p className="mt-1 text-gray-500 text-xs leading-tight overflow-hidden overflow-ellipsis whitespace-nowrap">
                            Total Team size - {getMemberCount(team)}
                          </p>
                        </div>
                      </div>
                    </button>
                    <div className={` -top-1 relative pt-2 pb-1 visible opacity-1`} style={{ zIndex: 2 }}>
                      <div className=" pb-1 ">
                        {team?.showMembers && (
                          <>
                            <div className={` -top-3 h-6 absolute left-5  border border-yellow-400 `} />
                            <button
                              style={{ zIndex: 2 }}
                              type="button"
                              className="relative flex items-center justify-center whitespace-nowrap group w-full rounded bg-yellow-400  border-yellow-400 border-2
                              px-1.5 p-1.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white"
                            >
                              <div className="h-6 w-6 border-yellow-400 border-2 rounded">
                                <PlusCircleIcon className="inline-block h-full w-full translate-x-px translate-y-px rounded object-cover object-center" />
                              </div>
                              <div className="ml-2 mt-px flex-1 h-5 flex items-center justify-between">
                                <p className="block font-sans text-xs overflow-hidden overflow-ellipsis whitespace-nowrap font-medium capitalize leading-none antialiased">
                                  Add Member
                                </p>
                              </div>
                              <MultiDropDownNew
                                DropDownParentClass={" absolute -top-0 -left-0 right-0 w-full z-10 "}
                                parentClass={"w-full"}
                                customWidthClass={" w-full md:w-full h-10 opacity-0"}
                                inputFieldClass={" h-10 "}
                                name={"_id"}
                                isSelectAll={false}
                                enableCheckbox={true}
                                isOKButtonEnable={true}
                                arrayData={userDropProps}
                                filterArrayPass={(val: any) => addTeamMembersToTeam(team, val, idex)}
                              />
                            </button>
                          </>
                        )}
                      </div>
                      {team?.members?.map((item: any) => {
                        const { itemName = "", _id = "", tmp = false } = item;
                        return (
                          <div key={`member_${_id}`} className=" py-1 relative">
                            <div
                              className={` -top-5 h-8 absolute left-5 border border-yellow-400`}
                              style={{ zIndex: -1 }}
                            />
                            <div
                              className="relative flex items-center justify-center whitespace-nowrap group
                rounded bg-white border-yellow-300 border-2 px-1.5 p-1.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white"
                            >
                              <div className="ml-2 mt-px flex-1 h-5 flex items-center justify-between">
                                <p className="block font-sans text-xs overflow-hidden overflow-ellipsis whitespace-nowrap font-medium capitalize leading-none text-black antialiased">
                                  {itemName}
                                  {tmp && `(${_id})`}
                                </p>
                                <button
                                  onClick={() => handleDeletePopup(_id, team?.teamId)}
                                  className="w-4 h-4 group-hover:opacity-100 hover:opacity-100 focus:opacity-100 opacity-0 text-red-600"
                                  type="button"
                                >
                                  {" "}
                                  <XIcon />{" "}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
      {getDeletePopup.show && (
        <ModalFixed>
          <ActionPopup
            enableDesc={true}
            actionIconComponent={false}
            enableActionIcon={true}
            handleActionText="remove"
            actionHeading={"Are you sure?"}
            handleCancel={() => popupAction(false)}
            handleAction={() => popupAction(true)}
            actionDescription={
              getDeletePopup.type === "member"
                ? "Do you really want to remove the selected member from the Team?"
                : "Do you really want to remove the Team?"
            }
          />
        </ModalFixed>
      )}
    </React.Fragment>
  );
};

export default TeamHigherHierarchy;
