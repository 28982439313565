import React, { useEffect, useState } from "react";

import OtherOffers from "./OtherOffers";
import PaymentOffers from "./PaymentOffers";
import AdditionalOffers from "./AdditionalOffers";
import { getProductPrice, toLocaleNumber } from "./OfferCommon";
import ExchangeOffers from "./ExchangeOffers";
import { _has, _isEmpty, _orderBy } from "utils/lodashConst";

interface ProductOfferProp {
  offerList: any;
  product: any;
}

export default function EffectivePriceView(props: ProductOfferProp) {
  const { offerList, product } = props;

  const [benefitPrice, setBenefitPrice] = useState<number>(0);
  const [discountPrice, setDiscountPrice] = useState<number>(0);
  const [effectivePrice, setEffectivePrice] = useState<number>(0);
  const [actualPrice, setActualPrice] = useState<number>(0);
  const [paymentOffers, setPaymentOffers] = useState<any>([]);
  const [otherOffers, setOtherOffers] = useState<any>([]);
  const [exchangeOffers, setExchangeOffers] = useState<any>([]);
  const [additionalOffers, setAdditionalOffers] = useState<any>([]);

  useEffect(() => {
    if (offerList.length > 0) {
      const offerGroups: any = {
        exchanges: [],
        payments: [],
        additionals: [],
        others: []
      };

      offerList?.forEach((item: any) => {
        item.checked = !(
          item.offerSelection === "notselected" ||
          item.offerType === "Instant Discount" ||
          item.offerType === "Deferred Cashback"
        );
        item.showTc = false;
        item.showBan = false;
        item.showOffer = false;
        item.sponsorName = item.sponsor.map((spon: any) => spon.name).join(", ");
        item.channelName = item.channel.map((chan: any) => chan.name).join(", ");

        switch (item.offerType) {
          case "TradeIn and Exchange":
            if (item.checked && item.exchangeBonus > 0 && _isEmpty(item.tradeValues)) {
              item.maxBenefites = item.exchangeBonus;
              item.exchangeVal = item.exchangeBonus;
              item.deviceVal = 0;
            }
            offerGroups.exchanges.push(item);
            break;
          case "Instant Discount":
          case "Deferred Cashback":
            offerGroups.payments.push(item);
            break;
          case "Offer Text":
          case "Standard EMI":
            offerGroups.additionals.push(item);
            break;
          default:
            offerGroups.others.push(item);
        }
      });

      let { exchanges, payments, additionals } = offerGroups;
      exchanges = _orderBy(exchanges, "maxBenefites", "desc");
      payments = _orderBy(payments, "maxBenefites", "desc");

      let checkFlag = true;
      payments = payments.map((element: any) => {
        if (checkFlag && element?.offerSelection !== "notselected" && element.offerType !== "Deferred Cashback") {
          element.checked = true;
          checkFlag = false;
        }
        return element;
      });

      payments.push({
        offerName: "Other Payments",
        sponsorName: "Not Available",
        channelName: "Not Available",
        maxBenefites: 0,
        checked: false,
        offerId: "others"
      });

      additionals = _orderBy(additionals, "maxBenefites", "desc");

      const exclusiveOffers = {
        "Freebie Offer": null,
        "Combo Offer": null,
        "Sellout/Activation Offers": null
      };
      const { others } = offerGroups;

      others.forEach((offer: any) => {
        if (offer.exclusivity === "Yes" && _has(exclusiveOffers, offer.offerType)) {
          if (!exclusiveOffers[offer.offerType] || offer.maxBenefites > exclusiveOffers[offer.offerType].maxBenefites) {
            exclusiveOffers[offer.offerType] = offer;
          }
        }
      });

      // Uncheck non-exclusive offers
      others.forEach((offer: any) => {
        if (offer.exclusivity === "Yes" && _has(exclusiveOffers, offer.offerType)) {
          if (offer.offerId !== exclusiveOffers[offer.offerType].offerId) {
            offer.checked = false;
          }
        }
      });

      setOtherOffers(others);

      setExchangeOffers(exchanges);
      setPaymentOffers(payments);
      setAdditionalOffers(additionals);
      setOtherOffers(others);
    }
  }, [offerList]);

  useEffect(() => {
    let benefits = 0;
    let discount = 0;
    let comboPrice = 0;
    [...paymentOffers, ...otherOffers, ...exchangeOffers].forEach((item) => {
      if (item.checked) {
        if (item.offerType === "TradeIn and Exchange") {
          benefits += item.exchangeVal ? item.exchangeVal : 0;
        } else {
          benefits += item.addBenefits ? item.maxBenefites : 0;
        }

        if (item.offerType === "Combo Offer") {
          const comboOffersForProduct = item.comboOffers?.[product?.erp_item_code] || [];

          for (const comboProducts of comboOffersForProduct) {
            if (comboProducts[0].type !== "Cash") {
              const discountValue = comboProducts.reduce((acc: any, offer: any) => acc + offer.discountValue, 0);
              const totalComboPrice = comboProducts.reduce((acc: any, offer: any) => acc + Number(offer.offerPrice), 0);

              if (discountValue === item.maxBenefites) {
                comboPrice += totalComboPrice;
                break;
              }
            }
          }
        }

        if (item.cashDiscount || item.reduceEffective) {
          if (item.offerType === "TradeIn and Exchange") {
            discount += (item.exchangeVal || 0) + (item.deviceVal || 0);
          } else {
            discount += item.maxBenefites;
          }
        }
      }
    });
    setDiscountPrice(discount);
    setBenefitPrice(benefits);
    setEffectivePrice(actualPrice + comboPrice - discount);
  }, [paymentOffers, otherOffers, exchangeOffers, actualPrice]);

  useEffect(() => {
    if (product) {
      const { sp } = getProductPrice(product);
      setActualPrice(sp);
    }
  }, [product]);

  return (
    <div className="px-4 py-2">
      <div className="flex justify-between font-semibold text-white p-2 text bg-primary-gradient rounded-sm">
        <span>Actual Product Price</span>
        <span>{toLocaleNumber(actualPrice)}</span>
      </div>
      <div className="flex flex-col">
        <OtherOffers otherOffers={otherOffers} setOtherOffers={setOtherOffers} product={product} />
      </div>
      {paymentOffers?.length > 0 && (
        <div className="m-2 text-sm">
          <div className=" flex flex-col">
            <span className="font-bold text-base text-gray-600">Payment Offers</span>
            <span className="m-1">Mutually exclusive offer only one can opted below</span>
          </div>
          <div className="flex flex-col">
            <PaymentOffers paymentOffers={paymentOffers} setPaymentOffers={setPaymentOffers} product={product} />
          </div>
        </div>
      )}

      {exchangeOffers?.length > 0 && (
        <div className="m-2 text-sm">
          <div className=" flex flex-col">
            <span className="font-bold text-base text-gray-600">Exchange Offers</span>
          </div>
          <div className="flex flex-col">
            <ExchangeOffers exchangeOffers={exchangeOffers} setExchangeOffers={setExchangeOffers} product={product} />
          </div>
        </div>
      )}

      <div className="flex flex-row justify-between text-sm font-bold text-green-500 px-5">
        <span className="flex-1 pt-1 text-left">Total Benefits</span>
        <span className=" flex-1 border-t-2 align-middle pt-1 pb-1 text-right ">{toLocaleNumber(benefitPrice)}</span>
      </div>

      <div className="flex flex-row justify-between text-sm font-bold text-green-500 px-5">
        <span className="flex-1 pt-1 text-left">Total Discounts</span>
        <span className=" flex-1 align-middle pt-1 pb-1 text-right ">{toLocaleNumber(discountPrice)}</span>
      </div>

      <div className="flex flex-row justify-between text-sm font-bold text-green-500 px-5">
        <span className="flex-1 pt-1 text-left">Final Effective Price</span>
        <span className=" flex-1 border-b-2 align-middle pt-1 pb-1 text-right ">{toLocaleNumber(effectivePrice)}</span>
      </div>
      {additionalOffers?.length > 0 && (
        <div className="mt-3 mb-3">
          <div className="flex justify-between font-semibold text-white p-2 text bg-primary-gradient rounded-sm">
            <span>{"Additional Offers"}</span>
          </div>
          <div className="additional-offer">
            <AdditionalOffers
              additionalOffers={additionalOffers}
              setAdditionalOffers={setAdditionalOffers}
              product={product}
            />
          </div>
        </div>
      )}
    </div>
  );
}
