import { XIcon } from "@heroicons/react/outline";
import ActionPopup from "Component/ActionPopup";
import React, { useEffect, useState } from "react";
import { _isEmpty, _orderBy } from "utils/lodashConst";
import MobileViewFilter from "../../CategoryFilter/MobileViewFilter";
import SideMenuOffer from "../SideMenuOffer";
import OffersProductDetails from "../OffersProductDetails";
import ProductOffers from "../ProductOffers";
import {
  getFilterOptionsApiCall,
  getFilteredOffersApiCall,
  getOfferEmiApiCall,
  getProductDetails
} from "../../../commonSalesProduct";
import { failToast } from "Component/ToastServices/ToastDisplay";
import ModalFixed3 from "Component/Modal/ModalFixed3";
import { nullToObject } from "utils/validation";

const ShowOfferDialog: React.FC<any> = (props) => {
  const { handlePopupOfferClose, productDetails, setOfferPopup, channelType } = props;

  const [offerDetLoaders, setOfferDetLoaders] = useState(true);
  const [offerGroup, setOfferGroup] = useState<any>([]);
  const [emiList, setEmiList] = useState<any>([]);
  const [activeOffersFilterOptions, setActiveOffersFilterOptions] = useState<any>({});
  const [offersFilterOptions, setOffersFilterOptions] = useState<any>({});
  const [filteredList, SetFilteredOffers] = useState<any>([]);
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState<any>(false);
  const [offersFilter, setOffersFilter] = useState<any>({ filters: [], startDate: "", endDate: "" });
  const [offersList, setOffersList] = useState<any>([]);
  const [pimProduct, setPimProduct] = useState<any>({});

  const handleMobileFiltersOpen = (state: any = false) => {
    setMobileFiltersOpen(state);
  };

  const getSelectedProduct = async (item_code = "") => {
    if (item_code) {
      const response: any = await getProductDetails({ item_code, channel: channelType }); //api call
      const {
        data: {
          data: { product = {} },
          status = 0
        }
      } = response;

      if (status === 200 && !_isEmpty(product)) {
        setPimProduct(product);
        setOffersFilter({ filters: [], startDate: "", endDate: "" });
      } else {
        failToast("Product not available");
      }
    }
  };

  const getEMIList = async (item_code: string) => {
    const response = await getOfferEmiApiCall(item_code, channelType);
    const { status = 0, data: { data = [] } = {} } = nullToObject(response) || {};

    if (status) {
      const emiListData: any = data?.financeModelList;
      if (emiListData !== undefined) {
        setEmiList(emiListData);
      }
    } else {
      failToast("Unable to get EMI List");
    }
  };
  /* Getting List of Filters  */
  const getFilters = async (item_code: string): Promise<void> => {
    const {
      data: { data = {}, status = "" }
    } = nullToObject(await getFilterOptionsApiCall(item_code, channelType));

    if (status === "success") {
      setOffersFilterOptions(data);
    }
  };

  const getOfferList = async (item_code: any) => {
    setOfferDetLoaders(true);
    const response = await getFilteredOffersApiCall(offersFilter, item_code, channelType);
    const { status = 0, data: { data = [] } = {} } = nullToObject(response) || {};
    const offer_data = data;
    if (status) {
      setActiveOffersFilterOptions(offer_data?.activeFilterOptions);
      let offers_details = offer_data?.offerList;
      offers_details = _orderBy(offers_details, ["maxBenefites"], ["desc"]);
      SetFilteredOffers(offers_details);
      setOffersList(offers_details);
      setOfferGroup(offer_data?.offergroupList);
      setOfferDetLoaders(false);
    }
  };

  useEffect(() => {
    if (!_isEmpty(pimProduct)) {
      const { erp_item_code } = pimProduct;
      getOfferList(erp_item_code);
    }
  }, [offersFilter]);

  useEffect(() => {
    if (!_isEmpty(pimProduct)) {
      const { erp_item_code } = pimProduct;
      getEMIList(erp_item_code);
      getFilters(erp_item_code);
      getOfferList(erp_item_code);
      setOfferPopup(true);
    }
  }, [pimProduct]);

  useEffect(() => {
    if (!_isEmpty(productDetails)) {
      const { erp_item_code } = productDetails;
      getSelectedProduct(erp_item_code);
    }
  }, [productDetails]);

  return (
    <ModalFixed3>
      <ActionPopup
        customWidth={"1200px"}
        customMWidth={"100%"}
        enableFullView={true}
        enableHeader={false}
        enableActionIcon={false}
        handleActionText={"OfferDetails"}
        textHeaderHeading={"Offer Details"}
        isOverFlow={true}
        CustomHeading={
          <div className="flex justify-between flex-wrap md:flex-nowrap items-center header px-3 py-1 mb-5 font-bold text-base text-white  bg-primary-gradient rounded-t-lg">
            <h3 className=" font-normal text-sm md:text-base inline-block order-1 md:order-1 flex-grow-1">
              Offer Details
            </h3>
            <button
              type="button"
              onClick={handlePopupOfferClose}
              className=" hover:bg-white hover:text-yellow-500 rounded text w-6 h-6 flex justify-center items-center
                         bg-white text-yellow-500 order-2 flex-grow-0 md:order-1"
            >
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        }
        PopupClass={"h-full"}
        handleCancel={handlePopupOfferClose}
      >
        {!_isEmpty(pimProduct) && (
          <div className="w-full h-full flex justify-left mt-3" style={{ height: "calc(100%  - 50px)", width: "100%" }}>
            {mobileFiltersOpen ? (
              <MobileViewFilter handleCloseMobileViewFilter={() => handleMobileFiltersOpen(false)}>
                <div className=" pt-2 bg-gray-100 w-80 border border-l border-gray-200 overflow-y-auto pb-10 ">
                  <SideMenuOffer
                    offersFilterOptions={offersFilterOptions}
                    setOffersFilterOptions={setOffersFilterOptions}
                    offersFilter={offersFilter}
                    activeOffersFilterOptions={activeOffersFilterOptions}
                    setOffersFilter={setOffersFilter}
                    offerList={offersList}
                    SetFilteredOffers={SetFilteredOffers}
                    filteredOffer={filteredList}
                    mobileToggle
                    setMobileToggle
                  />
                </div>
              </MobileViewFilter>
            ) : null}
            <div className=" pt-2 hidden md:block bg-gray-100 w-60 lg:w-80 border border-l border-gray-200 overflow-y-auto pb-10 rounded-md">
              <SideMenuOffer
                offersFilterOptions={offersFilterOptions}
                setOffersFilterOptions={setOffersFilterOptions}
                offersFilter={offersFilter}
                activeOffersFilterOptions={activeOffersFilterOptions}
                setOffersFilter={setOffersFilter}
                offerList={offersList}
                SetFilteredOffers={SetFilteredOffers}
                filteredOffer={filteredList}
                mobileToggle
                setMobileToggle
              />
            </div>
            <div className="w-full flex flex-col view-product overflow-auto max-h-full flex-1 bg-white">
              {pimProduct && (
                <>
                  <OffersProductDetails
                    channelType={channelType}
                    getSelectedProduct={getSelectedProduct}
                    product={pimProduct}
                  />
                  <ProductOffers
                    channelType={channelType}
                    offerDetLoaders={offerDetLoaders}
                    price={0}
                    emiList={emiList}
                    offerList={filteredList}
                    offerGroup={offerGroup}
                    product={pimProduct}
                  />
                </>
              )}
            </div>
          </div>
        )}
      </ActionPopup>
    </ModalFixed3>
  );
};

export default ShowOfferDialog;
