import React from "react";
import { salesReturnLoaderTypes } from "./types";
import { nullToObject } from "utils/validation";
import Loader from "Component/Loader";

const SalesReturnLoader = (props: salesReturnLoaderTypes) => {
  const { globalSate = {}, loader = false } = nullToObject(props);
  const {
    salesReturn: {
      imeiNumLoader = false,
      salesReturnLoader = false,
      imeiNumHistoryLoader = false,
      servicesCenterLoader = false,
      invoiceBasedDetailLoader = false,
      servicesCenterTokenLoader = false,
      getEmpIdBasedEmpDetailLoader = false
    } = {}
  } = globalSate || {};

  return (
    <React.Fragment>
      {loader && <Loader />}
      {imeiNumLoader && <Loader />}
      {salesReturnLoader && <Loader />}
      {servicesCenterLoader && <Loader />}
      {imeiNumHistoryLoader && <Loader />}
      {invoiceBasedDetailLoader && <Loader />}
      {servicesCenterTokenLoader && <Loader />}
      {getEmpIdBasedEmpDetailLoader && <Loader />}
    </React.Fragment>
  );
};

export default SalesReturnLoader;
