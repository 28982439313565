import { TableClass, tdClass, thClass, thSticky } from "utils/ClassContent";
import { convertToLowerCase } from "utils/validation";

const EcomExpressTrackingTable = (props: any) => {
  const { tracking_detail = [] } = props || {};
  return (
    <div
      className="block w-full overflow-x-auto relative scroll_1px"
      style={{ minHeight: "250px", maxHeight: "calc(100vh - 100px)" }}
    >
      <table className={TableClass}>
        <thead>
          <tr>
            <th className={thClass + thSticky}>Id</th>
            <th className={thClass + thSticky}>Status Updated On</th>
            <th className={thClass + thSticky}>Status</th>
            <th className={thClass + thSticky}>Shipment Message</th>
            <th className={thClass + thSticky}>Shipment Code</th>
            <th className={thClass + thSticky}>Location</th>
            <th className={thClass + thSticky}>City</th>
            <th className={thClass + thSticky}>Status Update Number</th>
            <th className={thClass + thSticky}>Ref awb</th>
            <th className={thClass + thSticky}>Created On</th>
          </tr>
        </thead>
        {tracking_detail.map((ele: any, i: number) => {
          const {
            _id = "",
            status_updated_on = "",
            status_txt = "",
            shipment_msg = "",
            shipment_code = "",
            location = "",
            city = "",
            status_update_number = "",
            ref_awb = "",
            created_on = ""
          } = ele;
          return (
            <tbody key={convertToLowerCase(i + "tracking_detail-dfdi")}>
              <tr tabIndex={1} className="hover:bg-gray-600 hover:bg-opacity-10 cursor-pointer">
                <td style={{ minWidth: "150px" }} className={tdClass}>
                  {_id}
                </td>
                <td className={tdClass}>{status_updated_on}</td>
                <td className={tdClass}>{status_txt}</td>
                <td className={tdClass}>{shipment_msg}</td>
                <td className={tdClass}>{shipment_code}</td>
                <td className={tdClass}>{location}</td>
                <td className={tdClass}>{city}</td>
                <td className={tdClass}>{status_update_number}</td>
                <td className={tdClass}>{ref_awb}</td>
                <td className={tdClass}>{created_on}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default EcomExpressTrackingTable;
