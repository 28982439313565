import React, { useState } from "react";
import { moment } from "utils/momentConst";
import "./TrackingByCard.css";
import { _get } from "utils/lodashConst";
import "./TrackingByCard2.css";
import { TrackingDataProps } from "../../types";
import { ViewGridIcon } from "@heroicons/react/solid";
import { convertToLowerCase } from "utils/validation";

const TrackingByCard = (props: TrackingDataProps) => {
  const { trackingData: { histories = [] } = {} } = props || {};
  const [orderStyle1, setOrderStyle1] = useState(false);
  const feature_status = histories.map((hist: any) => {
    return _get(hist, "current_status", "");
  });

  const handleOrderStyle = () => {
    setOrderStyle1(!orderStyle1);
  };

  const handleBgColor = (status: string) => {
    switch (status) {
      case "Processing":
        return "#f3b73b";
      case "Ready To Ship":
        return "#2f92cc";
      case "Order Picked":
        return "#3576c0";
      case "Shipped":
        return "#3476c0";
      case "Out for Delivery":
        return "#3476c0";
      case "Cancel Request":
        return "#fc7035";
      case "Cancelled":
        return "#ff001900";
      case "Refunded":
        return "#5dac4d";
      case "Delivered":
        return "#00af5b";
      case "Return Request":
        return "#d05724";
      case "Replaced":
        return "#75339b";
      case "Rejected":
        return "#848484";
      default:
        return "#5596f6";
    }
  };

  return (
    <div className="inline-block w-full">
      <div className="flex items-center">
        <h4 className="uppercase tracking-wide text-sm font-semibold text-gray-700 my-2 px-4">Tracking</h4>
        <button
          onClick={handleOrderStyle}
          type="button"
          className={`w-5 h-5 ${orderStyle1 ? "text-green-500" : "text-blue-500"} `}
        >
          <ViewGridIcon className="ml-1" />
        </button>
      </div>
      <div className="overflow-x-auto overflow-hidden pt-4">
        {!orderStyle1 ? (
          <div className={`tracking_wraper flex flex-wrap`}>
            {feature_status.map((element: any, i: number) => {
              return (
                <React.Fragment key={convertToLowerCase(i + "feature_status-dfdi")}>
                  {histories.map((orderHistoryElement: any, j: number) => {
                    const dateFromReactWeb =
                      _get(orderHistoryElement, "emp_id", "") === "Cron Job - Customer SMS" ||
                      _get(orderHistoryElement, "emp_id", "") === "From Opencart";
                    return (
                      <React.Fragment key={convertToLowerCase(i + "histories-dgd")}>
                        {i === histories.length - 1 ? (
                          <div className="tracking_card">
                            <div
                              title={`Branch : ${_get(orderHistoryElement, "branchname", "")} - ${_get(
                                orderHistoryElement,
                                "Action",
                                ""
                              )}`}
                              className="tracking_card_body"
                              style={{
                                backgroundColor: `${handleBgColor(orderHistoryElement.current_status)}`,
                                borderColor: `${handleBgColor(orderHistoryElement.current_status)}`
                              }}
                            >
                              {j === feature_status?.length - 1 ? (
                                <div className="absolute right-4 top-4">
                                  <span className="flex h-3 w-3 relative">
                                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-white opacity-75" />
                                    <span className="relative inline-flex rounded-full h-3 w-3 bg-white" />
                                  </span>
                                </div>
                              ) : null}
                              <p className="event-date flex flex-col text-white">
                                <p className="event-description">{orderHistoryElement.current_status}</p>
                                <span className="text-xxs ">
                                  {_get(orderHistoryElement, "updated_date", "")
                                    ? dateFromReactWeb
                                      ? moment(_get(orderHistoryElement, "updated_date", ""))
                                          .utc()
                                          .format("DD/MM/YYYY hh:mm a")
                                      : moment(_get(orderHistoryElement, "updated_date", "")).format(
                                          "DD/MM/YYYY hh:mm a"
                                        )
                                    : ""}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
        ) : (
          <div className=" hh-grayBox flex">
            <div className="flex justify-between">
              {feature_status.map((element: any, i: number) => {
                return (
                  <React.Fragment key={convertToLowerCase(i + "feature_status-ddss")}>
                    {histories.map((orderHistoryElement: any, j: number) => {
                      const dateFromReactWeb =
                        _get(orderHistoryElement, "emp_id", "") === "Cron Job - Customer SMS" ||
                        _get(orderHistoryElement, "emp_id", "") === "From Opencart";
                      return (
                        <React.Fragment key={convertToLowerCase(i + "feature_historiesstatus-dgd")}>
                          {i === histories.length - 1 ? (
                            <div
                              style={{
                                color: `${handleBgColor(orderHistoryElement.current_status)}`,
                                minWidth: "150px"
                              }}
                              className={`order-tracking${
                                _get(orderHistoryElement, "current_status", "") !== element &&
                                _get(orderHistoryElement, "current_status", "") === "Delivered"
                                  ? "  "
                                  : " completed "
                              } `}
                            >
                              <span
                                style={{
                                  backgroundColor: `${handleBgColor(orderHistoryElement.current_status)}`,
                                  borderColor: `${handleBgColor(orderHistoryElement.current_status)}`
                                }}
                                className="is-complete"
                              />
                              <p>{orderHistoryElement.current_status}</p>
                              <p className="text-xxs text-white">
                                {_get(orderHistoryElement, "updated_date", "")
                                  ? dateFromReactWeb
                                    ? moment(_get(orderHistoryElement, "updated_date", ""))
                                        .utc()
                                        .format("DD/MM/YYYY hh:mm a")
                                    : moment(_get(orderHistoryElement, "updated_date", "")).format("DD/MM/YYYY hh:mm a")
                                  : ""}
                              </p>
                            </div>
                          ) : null}
                        </React.Fragment>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrackingByCard;
