import React from "react";
import { IsFullView } from "utils/ClassContent";
import { BillingAddressProps } from "../../types";

const BillingAddress = (props: BillingAddressProps) => {
  const { billingAddressCardDatas: { payment_name = "", phone_number = "", payment_address = "" } = {} } = props || {};

  return (
    <div className={` ${IsFullView() ? "w-1/2" : ""} rounded-md w-full px-1 flex-1`}>
      <div>
        <section>
          <h4 className="uppercase tracking-wide text-sm font-semibold text-gray-700 my-2">Billing Address</h4>
          <fieldset className="mb-3 rounded text-gray-600">
            <label className="flex border border-gray-100 py-2 items-start">
              <span className="text-left px-2 text-sm w-36">Name</span>
              <span className="flex-1 px-2 text-sm">{payment_name}</span>
            </label>
            <label className="flex border border-gray-100 py-2 items-start">
              <span className="text-left px-2 text-sm w-36">Contact number</span>
              <span className="flex-1 px-2 text-sm">{phone_number}</span>
            </label>
            <label className="flex border border-gray-100 py-2 items-start">
              <span className="text-left px-2 text-sm w-36">Address</span>
              <span className="flex-1 px-2 text-sm">{payment_address}</span>
            </label>
          </fieldset>
        </section>
      </div>
    </div>
  );
};

export default BillingAddress;
