import { _get } from "utils/lodashConst";

type orderTrackingInitType = {
  orderTrackingUserData?: Array<any>;
  orderID?: boolean;
};

export const orderTrackingInit = {
  orderTrackingUserData: {},
  orderID: false
};

export const orderTrackingReducer = (state = orderTrackingInit, action: orderTrackingInitType = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "ORDERTRACKING_INITIAL_STAGE": {
      return {
        ...state,
        ...orderTrackingInit
      };
    }
    //get details based on Order ID loader
    case "OT_GET_DETAILS_BASED_ORDERID_BASED_LOADER": {
      return {
        ...state,
        orderID: _get(action, "orderID", false)
      };
    }
    case "OT_GET_DETAILS_BASED_ORDERID_BASED_DATA": {
      return {
        ...state,
        orderTrackingUserData: _get(action, "orderTrackingUserData", [])
      };
    }

    default:
      return {
        ...state
      };
  }
};
