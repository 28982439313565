import { Get } from "ApiProcess/httpRestServices";
import { crmApiBaseUrl, loginApi } from "ApiProcess/httpRestServices/apiManage";
import { createContext } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Ripples from "react-ripples";
import store from "store";
import {
  draftEmailToServerAction,
  getMailEnquiryByIdAction,
  receiveEmailFromServerAction,
  sendEmailToServerAction,
  syncWithServerAction
} from "store/action/mailAction";
import { _get } from "utils/lodashConst";
import { moment } from "utils/momentConst";
import { convertToLowerCase } from "utils/validation";

export const LoggedMailContext = createContext({ loggedMail: "" });

/**
 * Get mails
 * @param data mail search data
 */
export const receiveEmailFromServerCall = (data: object = {}) => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(receiveEmailFromServerAction(data));
};

/**
 * Set popup to true/ to show popup
 */
export const setShowDetailsPopupCall = () => {
  store.dispatch({ type: "SET_TRANSACTION_POPUP", transactionPopup: true });
};

/**
 * Get mails
 * @param data mail search data
 */
export const receiveEmailFromServerWithoutLoaderCall = (data: object = {}) => {
  store.dispatch(receiveEmailFromServerAction(data));
};

/**
 * Reset sync mail success code
 */
export const resetSyncMailSuccesCodeCall = () => {
  store.dispatch({ type: "SET_SYNC_MAIL_SUCCESS_CODE", syncMailSuccessCode: false });
};

/**
 * Send mails
 * @param data sending mail data
 */
export const sendEmailToServerCall = (data: object = {}) => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(sendEmailToServerAction(data));
};

/**
 * draft mails
 * @param data draft mail data
 */
export const draftEmailToServerCall = (data: object = {}) => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(draftEmailToServerAction(data));
};

/**
 * Sync with mail server
 */
export const syncWithServerCall = (mailData: any = {}) => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(syncWithServerAction(mailData));
};

/**
 * Sync with mail server , without loader
 */
export const syncWithServerWithoutLoaderCall = (mailData: any = {}) => {
  store.dispatch(syncWithServerAction(mailData));
};

/**
 * Get enquiry of selected mail
 */
export const getMailEnquiryByIdCall = (id = "") => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(getMailEnquiryByIdAction(id));
};

/**
 * Reset the enquiry
 */
export const resetMailEnquiryCall = () => {
  store.dispatch({ type: "SET_SELECTED_ENQUIRY", selectedEnquiry: {} });
};

/**
 * Set current mail Id
 */
export const setOpenedMailIdCall = (mailId = "") => {
  store.dispatch({ type: "SET_OPENED_MAIL_ID", openedMailId: mailId });
};

/**
 * Reset current mail Id
 */
export const resetOpenedMailIdCall = () => {
  store.dispatch({ type: "SET_OPENED_MAIL_ID", openedMailId: "" });
};

/**
 * Reset mail update success code
 */
export const resetMailUpdateSuccessCall = () => {
  store.dispatch({ type: "SET_UPDATED_MAIL_SUCCESS_CODE", updatedMailSuccessCode: false });
};

/**
 * Reset mail update success code
 */
export const resetSendMailSuccessCall = () => {
  store.dispatch({ type: "SET_SEND_MAIL_SUCCESS_CODE", sendMailSuccessCode: false });
};

/**
 * @param  userDetails user details
 * get user by id
 */
export const getUserById = async (id: any = "") => {
  return await Get(`${crmApiBaseUrl}${loginApi.getRegisteredUser}${id}`, "crmToken");
};

export const setUpdatedUserCode = () => {
  store.dispatch({ type: "SET_UPDATED_USER", updatedUser: true });
};

export const resetUpdatedUserCode = () => {
  store.dispatch({ type: "SET_UPDATED_USER", updatedUser: false });
};

export const replyMailTemplate = (originalMailDate = "", originalMailFrom = [], originalMailContent = "") => {
  return `<br /><div dir="ltr" class="gmail_attr">On 
    ${moment(originalMailDate).format("ddd ,MMM DD YYYY, h:mm a")} 
    ${
      originalMailFrom
        ? originalMailFrom.map((eachFrom: any) => {
            return _get(eachFrom, "name", "") ? _get(eachFrom, "name", "") : _get(eachFrom, "address", "");
          })
        : ""
    } &lt;
    ${
      originalMailFrom
        ? originalMailFrom.map((eachFrom: any) => {
            return ` <a href="mailto: ${_get(eachFrom, "address", "")}">${_get(eachFrom, "address", "")}</a>`;
          })
        : ""
    }&gt;wrote:<br></div><blockquote class="gmail_quote" style="margin:0 0 0 .8ex;border-left:1px #ccc solid;padding-left:1ex">
    ${originalMailContent}`;
};

const poorvikaImgLogoUrl = "https://storage.googleapis.com/crm.img.poorvika.com/production-Service/poorvika_logo.jpg";

export const getMailSignature = (event: any = {}) => {
  const {
    name = {},
    email = {},
    phone = {},
    telephone = {},
    team = {},
    position = {},
    web = {},
    company = {},
    address = {}
  } = event;
  return `&nbsp;
    &nbsp; 
    <table
        style="width: 500px;font-size: 11pt;font-family: Lucida Console, monospace;" cellpadding="0" cellspacing="0" >
        <tbody>
            <tr>
                <td
                    width="100"
                    style="background:#f15a23; padding-left:10px; font-size: 10pt;font-family: Lucida Console, monospace;width: 100px;padding-right: 10px;vertical-align: middle; padding-top:5px"
                    valign="middle"
                    rowspan="6"
                >
                    <a href="https://${web}" rel="noreferrer" target="_blank">
                        <img
                            alt="Logo"
                            width="90"
                            style="width: 90px; height: auto; border: 0"
                            src="${poorvikaImgLogoUrl}"
                        />
                    </a>
                </td>
                <td style="padding-left: 10px">
                    <table cellpadding="0" cellspacing="0">
                        <tbody>
                           ${
                             name &&
                             `<tr>
                                <td
                                    colspan="2"
                                    style="font-size: 10pt;color: #0079ac;font-family: Lucida Console, monospace;width: 400px;vertical-align: top;"
                                    valign="top"
                                >
                                    <strong>
                                        <span style="text-transform:capitalize; width: 100%;font-size: 11pt;font-family: Lucida Console, monospace;color: #ff7631;">
                                            ${name}
                                        </span>
                                    </strong>
                                </td>
                            </tr>`
                           }
                            <tr>
                                <td
                                    colspan="2"
                                    style="font-size: 10pt;color: #0079ac;font-family: Lucida Console, monospace;width: 400px;vertical-align: top;"
                                    valign="top">
                                   ${
                                     team &&
                                     `<strong>
                                        <span style="text-transform:capitalize; font-size: 11pt;font-family: Lucida Console, monospace;color: #ff7631;">
                                            ${team}
                                        </span>
                                    </strong>`
                                   }
                                    ${
                                      position &&
                                      `<strong
                                        style="text-transform:capitalize; font-family: Lucida Console, monospace;font-size: 11pt;color: #ff7631;">
                                        ${team && `<span>|</span>`}
                                        ${position}
                                    </strong>`
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td
                                    colspan="2"
                                    style="font-size: 10pt;font-family: Lucida Console, monospace;vertical-align: top;line-height: 22px;"
                                    valign="top">
                                    ${
                                      telephone &&
                                      `<span>
                                        <span style="color: #ff7631">
                                            <strong style="text-transform:capitalize;">Telephone:</strong>
                                        </span>
                                        <span
                                            style="font-size: 10pt;font-family: Lucida Console, monospace;color: #142247;"
                                        >
                                            ${telephone}
                                        </span>
                                    </span>`
                                    }
                                   ${
                                     phone &&
                                     `<span>
                                        ${
                                          telephone &&
                                          `<span style="color: #ff7631; font-size: 10pt;">
                                            |
                                        </span>`
                                        }
                                        <span style="color: #ff7631">
                                            <strong style="text-transform:capitalize;">Mobile:</strong>
                                        </span>
                                        <span
                                            style="font-size: 10pt;font-family: Lucida Console, monospace;color: #142247;"
                                        >
                                            ${phone}
                                        </span>
                                    </span>`
                                   }
                                    ${
                                      email &&
                                      `<span>
                                        <br />
                                        <span style="color: #ff7631">
                                            <strong style="text-transform:capitalize;" >Email:</strong>
                                        </span>
                                        <a
                                            href="mailto:${email}"
                                            style="font-size: 10pt;font-family: Lucida Console, monospace; color: #142247; text-decoration: none;"
                                        >
                                            <span
                                                style="font-size: 10pt;font-family: Lucida Console, monospace;color: #142247;text-decoration: none;"
                                            >
                                                ${email}
                                            </span>
                                        </a>
                                    </span>`
                                    }
                                   <span>
                                        <br />
                                        ${
                                          company &&
                                          `<span
                                            style="text-transform:capitalize; font-size: 10pt;color: #ff7631;font-weight: bold;"
                                        >
                                            ${company}
                                            <span
                                                style="font-size: 10pt;color: #ff7631;font-weight: bold;"
                                            >
                                                |
                                            </span>
                                        </span>`
                                        }
                                        ${
                                          address &&
                                          `<span>
                                            <span
                                                style="text-transform:capitalize; font-size: 10pt;font-family: Lucida Console, monospace; color: #142247;"
                                            >
                                                ${address}
                                            </span>
                                        </span>`
                                        }
                                    </span>
                                </td>
                            </tr>
                            ${
                              web &&
                              `<tr>
                                <td
                                    style="font-size: 10pt; line-height:100%;font-family: Arial, sans-serif;font-weight: bold;color: #1793ce; width: 200px;
                  padding-top: 5px;" width="200" >
                                    <span
                                        style="display: inline-block;font-family: Arial, sans- serif;font-size: 10pt;" >
                                        <a
                                            href="https://${web}"
                                            rel="noreferrer"
                                            target="_blank"
                                            style="text-decoration: none"
                                        >
                                            <span
                                                style="text-transform:lowercase; font-family: Lucida Console, monospace;color: #ff7631;text-decoration: none;"
                                            >
                                                ${web}
                                            </span>
                                        </a>
                                    </span>
                                </td>
                            </tr>`
                            }
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>`;
};

export const getAutoFillCards = (
  id = "",
  autoFillList: any = [],
  handleDivTextContent: Function = () => {},
  resetAutoFillList: Function = () => {}
) => {
  return (
    <OutsideClickHandler onOutsideClick={() => resetAutoFillList()}>
      <div className="absolute left-0 top-full z-10 bg-white py-2 shadow-lg rounded">
        {autoFillList.map((autofill: any, i = 0) => {
          const autoFillName = _get(autofill, "name", "");
          const autoFillMailId = _get(autofill, "emailId", "");
          return (
            <Ripples
              key={convertToLowerCase(i + "autoFillList-tendk")}
              className="flex cursor-pointer shadow-sm w-full flex-shrink-0 transition-all duration-150 hover:bg-blue-50 bg-opacity-25"
            >
              <div className="flex  w-full px-2 py-1">
                <div className="flex w-full">
                  <button
                    type="button"
                    className="text-left"
                    onClick={() => {
                      handleDivTextContent(id, autoFillMailId);
                    }}
                  >
                    <h2 className="text-sm font-semibold text-gray-700">{autoFillName}</h2>
                    <span className="text-xs text-gray-700">{autoFillMailId}</span>
                  </button>
                </div>
              </div>
            </Ripples>
          );
        })}
      </div>
    </OutsideClickHandler>
  );
};
