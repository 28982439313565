import React, { useEffect, useState } from "react";
import { _get, _flattenDeep } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { inputStylePopup } from "utils/ClassContent";
import { infoToast } from "Component/ToastServices/ToastDisplay";
import { ageOfDeviceCalculation } from "../../commonSalesReturn";
import { convertToLowerCase, getDateConvertAgeDevice, nullToObject, numOnlyAllow } from "utils/validation";
import { reasonforSalesReturnDrop, availableSubProblem, reasonforSalesReturnDropOption } from "Component/MockData";
import RefundForm from "./RefundForm";
import DOADetailForm from "./DoaDetailsForm";
import ReplacementForm from "./ReplacementForm";
import CommonInput from "Component/Form/CommonInput";
import CommonTextarea from "Component/Form/CommonTextarea";
import CommonMultiChoose from "Component/Form/CommonMultiChoose";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

const SalesReturnGetProblem = (props: any) => {
  const {
    itemName = "",
    setShow = true,
    genralInfo = {},
    viewModeData = {},
    enableViewMode = false,
    srSubCategoryState = {},
    passRefunData = () => {}, //rdfund componet
    passDoaDetails = () => {}, //doa component
    replacePassdata = () => {}, //replace componet
    passSalesReturnData = () => {} //language component
  } = props || {};

  const { srSubCategory = "" } = srSubCategoryState;
  const convertLowerCase = srSubCategory.toLowerCase(); //convet lowercase
  const srnValueCheck = convertLowerCase !== "srn" ? true : false;
  const globalStore = useSelector((state: any) => state || {});
  const { salesReturn: { imeiNumbasedUserData: { 0: { INVOICE_DATE = "" } = {} } = [] } = {} } =
    nullToObject(globalStore);
  const [dropDOA, setDropDOA] = useState(0);
  const [srnValue, setSrnValue] = useState("");
  const [subProblem, setSubProblem] = useState([]);
  const [ageOfDevice, setAgeOfDevice] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [reasonforSalesReturn, setReasonforSalesReturn] = useState("");
  const [selectedProblem, setSelectedProblem] = useState([]);

  const { problem = [], problemCategory = [] } = viewModeData;

  const [basiInfoReturn, setbasiInfoReturn] = useState({
    srnValue: "",
    ageOfDevice: "",
    selectedProblem: [],
    problemDescription: "",
    reasonforSalesReturn: "",
    subselectedProblem: []
  });

  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const userDetails = basiInfoReturn;

    switch (name) {
      case "srnValue":
        const { invoiceAmount = "" } = genralInfo; //get srn value from amt
        const consvetValue = +value;
        const consvetInvoiceAmt = +invoiceAmount;
        if (invoiceAmount) {
          if (consvetInvoiceAmt >= consvetValue) {
            userDetails.srnValue = value;
            setSrnValue(value);
          } else {
            infoToast("srn value to be equal amout or less then Product value");
          }
        } else {
          userDetails.srnValue = value;
          setSrnValue(value);
        }
        break;
      case "ageOfDevice":
        userDetails.ageOfDevice = value;
        setAgeOfDevice(value);
        break;
      case "problemDescription":
        userDetails.problemDescription = value;
        setProblemDescription(value);
        break;
      case "reasonforSalesReturn":
        userDetails.reasonforSalesReturn = value;
        setReasonforSalesReturn(value);
        break;
      default:
        break;
    }
    setbasiInfoReturn(userDetails);
    passSalesReturnData(userDetails, true); //pass information here to parent index
  };

  const handleDOAStateChange = (item: any) => {
    const { id = 1, optionData = "" } = item || {};
    setDropDOA(id);
    onChange({ target: { name: "reasonforSalesReturn", value: optionData } });
  };

  const clearReasonDropDown = (event: any = {}) => {
    onChange({ target: { name: "reasonforSalesReturn", value: "" } });
    setDropDOA(0);
  };

  const choosedProblem = (data: any) => {
    const newArray: any = [...selectedProblem];
    const findIndex = newArray.findIndex((ele: any) => ele.id === data.id);
    newArray[findIndex] = {
      ...newArray[findIndex],
      isSelect: !newArray[findIndex]?.isSelect, //change seleted value
      value: newArray[findIndex].value.map((ele: any) => {
        return {
          ...ele,
          isSelect: false
        };
      })
    };
    const filterSeletcedValue = newArray.filter((ele: any) => ele.isSelect);
    const mapValueData: any = filterSeletcedValue.map((ele: any) => {
      return ele.value;
    });
    setSelectedProblem(newArray);
    setSubProblem(_flattenDeep(mapValueData));
    setbasiInfoReturn({
      ...basiInfoReturn,
      selectedProblem: newArray,
      subselectedProblem: _flattenDeep(mapValueData)
    });
    const newObj: any = {
      ...basiInfoReturn,
      selectedProblem: newArray,
      subselectedProblem: _flattenDeep(mapValueData)
    };
    setbasiInfoReturn(newObj);
    passSalesReturnData(newObj, true); //pass information here to parent index
  };

  const subProblemHandle = (data: any) => {
    const newArray: any = [...selectedProblem];
    const findObj: any = newArray.find((ele: any) => ele.id === data.parentId);
    findObj.value.map((ele: any) => {
      if (ele.id === data.id) {
        ele.isSelect = !ele.isSelect;
      }
      return ele;
    });
    const newseletectParentArray: any = [...selectedProblem];
    const findIndex = newArray.findIndex((ele: any) => ele.id === findObj.id);
    newseletectParentArray[findIndex] = findObj;
    const filterSeletcedValue = newseletectParentArray.filter((ele: any) => ele.isSelect);
    const mapValueData: any = filterSeletcedValue.map((ele: any) => {
      return ele.value;
    });
    setSelectedProblem(newArray);
    setSubProblem(_flattenDeep(mapValueData));
    const newObj: any = {
      ...basiInfoReturn,
      selectedProblem: newArray,
      subselectedProblem: _flattenDeep(mapValueData)
    };
    setbasiInfoReturn(newObj);
    passSalesReturnData(newObj, true);
  };

  const styleClass = () => {
    //class based layOut change
    return inputStylePopup;
  };

  const salesRerunDataValue = () => {
    return !srnValueCheck ? reasonforSalesReturnDrop : reasonforSalesReturnDropOption;
  };

  useEffect(() => {
    if (INVOICE_DATE) {
      const consvertData = getDateConvertAgeDevice(INVOICE_DATE); //convet to date formate
      if (consvertData !== "Invalid date") {
        const ageOfDeviceDate = ageOfDeviceCalculation(consvertData); //current date given date subtract
        onChange({ target: { name: "ageOfDevice", value: ageOfDeviceDate } });
      }
    }
  }, [INVOICE_DATE]);

  useEffect(() => {
    if (enableViewMode) {
      const {
        deviceAge = "",
        problemDesc = "",
        srnValue: _srnValue = "",
        reasonForSalesReturn: { reason = {} } = {}
      } = viewModeData || {};

      const dataOption: any = reasonforSalesReturnDrop.find((ele: any) => ele.optionData === reason) || {};
      handleDOAStateChange(dataOption);
      onChange({ target: { name: "ageOfDevice", value: deviceAge } });
      onChange({ target: { name: "problemDescription", value: problemDesc } });
      onChange({ target: { name: "srnValue", value: _srnValue } });
    }
  }, [enableViewMode]);

  /**
   * product name based problem display
   */
  useEffect(() => {
    const findProblem: any = availableSubProblem.filter((ele: any) => ele.itemGrupName === itemName) || [];
    const newArray = [...findProblem];
    /**
     * problem find
     */
    const result: any = newArray.map((ele: any) => {
      ele.isSelect = problem.some((eleProbelm: any) => ele.optionData === eleProbelm);
      return ele;
    });
    const filterSeletcedValue = result.filter((ele: any) => ele.isSelect === true);
    const subProblemResult: any = _flattenDeep(filterSeletcedValue.map((ele: any) => _get(ele, "value", [])));
    /**
     * sub problem find
     */
    const resultsubProblem: any = subProblemResult.map((ele: any) => {
      ele.isSelect = problemCategory.some((eleProbelm: any) => ele.optionData === eleProbelm);
      return ele;
    });
    setSelectedProblem(result);
    setSubProblem(resultsubProblem);

    const newObj: any = {
      ...basiInfoReturn,
      selectedProblem: filterSeletcedValue,
      subselectedProblem: resultsubProblem
    };
    setbasiInfoReturn(newObj);
    passSalesReturnData(newObj, true);
  }, [itemName]);

  return (
    <React.Fragment>
      <CommonMultiChoose
        setShow={setShow}
        editSubProblem={[]}
        editProblem={problem}
        labelRight={"Choosen"}
        labelLeft={"Available"}
        labelText={"Problem Category"}
        chooseLangage={choosedProblem}
        seletedLanuage={selectedProblem}
        enableViewMode={enableViewMode}
        deselectLanguage={choosedProblem}
        parentClass={enableViewMode ? inputStylePopup : ""}
      />

      <CommonMultiChoose
        editProblem={[]}
        setShow={setShow}
        labelText={"Problem"}
        seletedLanuage={subProblem}
        labelRight={"Choosen Options"}
        labelLeft={"Available Options"}
        editSubProblem={problemCategory}
        chooseLangage={subProblemHandle}
        enableViewMode={enableViewMode}
        deselectLanguage={subProblemHandle}
        parentClass={enableViewMode ? inputStylePopup : ""}
      />
      <CommonTextarea
        setShow={setShow}
        onChange={onChange}
        name="problemDescription"
        value={problemDescription}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
        labelText={"Problem Description"}
        parentClass={inputStylePopup}
      />
      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        value={convertToLowerCase(ageOfDevice || "")}
        onChange={onChange}
        name={"ageOfDevice"}
        inputFieldClass="h-9"
        parentClass={styleClass()}
        onKeyPress={numOnlyAllow}
        labelText={"Age of device"}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
      />

      {!srnValueCheck && (
        <CommonInput
          placeholder={""}
          value={srnValue}
          mustField={true}
          name={"srnValue"}
          setShow={setShow}
          inputError={false}
          onChange={onChange}
          inputFieldClass="h-9"
          labelText={"SRN Value"}
          parentClass={styleClass()}
          labelTextClass={"font-bold"}
          readOnly={enableViewMode}
          onKeyPress={numOnlyAllow}
        />
      )}

      <CommonSearchDropdown
        mustField={true}
        setShow={setShow}
        inputWithIcon={false}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        parentClass={styleClass()}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        value={reasonforSalesReturn}
        name={"ReasonforSalesReturn"}
        inputClass="flex justify-end"
        readOnly={enableViewMode}
        arrayData={salesRerunDataValue()}
        labelText={"Reason for Sales Return"}
        handleParentDropVal={handleDOAStateChange}
        clearInput={clearReasonDropDown}
      />

      {dropDOA === 1 && (
        <DOADetailForm
          globalStore={globalStore}
          genralInfo={genralInfo}
          viewModeData={viewModeData}
          passDoaDetails={passDoaDetails}
          enableViewMode={enableViewMode}
        />
      )}

      {dropDOA === 2 && (
        <ReplacementForm
          globalStore={globalStore}
          viewModeData={viewModeData}
          enableViewMode={enableViewMode}
          replacePassdata={replacePassdata}
          convertLowerCase={convertLowerCase}
        />
      )}

      {dropDOA === 3 && (
        <RefundForm
          globalStore={globalStore}
          viewModeData={viewModeData}
          passRefunData={passRefunData}
          enableViewMode={enableViewMode}
          convertLowerCase={convertLowerCase}
        />
      )}
    </React.Fragment>
  );
};
export default SalesReturnGetProblem;
