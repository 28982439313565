import {
  campaign,
  centralInventoryToken,
  offerKey,
  OMS_AccessId,
  OMS_Secret,
  typesenceOutDatedDeleteToken,
  typesenceOutDatedPostToken,
  typesenceTokenKey
} from "ApiProcess/httpRestServices/apiManage";
import { Decrypt } from "utils/secureFile";
import { getocTokenServices } from "utils/services/orderTrackingService";
import { nullToObject } from "utils/validation";

export const Noheaders: any = {
  "Content-Type": "application/json"
};

/**
 * setToken in header
 */
export const centralInventoryHeader: any = () => {
  return {
    "Content-Type": "application/json",
    Authorization: centralInventoryToken
  };
};

/**
 * setToken in header
 */
export const centralInventorySalesHeader: any = () => {
  return {
    "Content-Type": "application/json",
    Authorization: centralInventoryToken,
    channel: "shop"
  };
};

/**
 * setToken in header
 */
export const orderHistoryToken: any = () => {
  return {
    "Content-Type": "application/json",
    Authorization: campaign.orderHistoryToken,
    "CF-Access-Client-Id": OMS_AccessId,
    "CF-Access-Client-Secret": OMS_Secret
  };
};

/**
 * setToken in header
 */
export const orgToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: campaign.orgToken
  };
};

/**
 * setToken in header
 */
export const pimApiHeader = () => {
  return {
    "Content-Type": "application/json",
    "access-token": campaign.pimapiToken
  };
};

/**
 * setToken in header
 */
export const pimToken = () => {
  return {
    "Content-Type": "application/json",
    channel: "ONLINE",
    locale: "en-IN"
  };
};

/**
 * setToken in header
 */
export const orgPincodeToken = () => {
  return {
    "Content-Type": "application/json",
    authorization: "14b5016c-0d9c-4e3f-ad13-71ac2b0b65df"
  };
};

/**
 * pinePg token set in header
 */
export const pinePgTokenSet = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "558b5629-4394-437a-b65f-cd3b28e53d43"
  };
};

/**
 * apxToken
 * add header
 */
export const apxToken = () => {
  return {
    userid: "poorvika",
    "Content-Type": "application/json",
    securitycode: "5382-1294-8763-3537"
  };
};

/**
 * oc3Token
 * add header
 */
export const oc3Token = async () => {
  const authToken: any = (await getocTokenServices()) || {};
  const {
    data: {
      data: { access_token = "" }
    }
  } = authToken || {};
  return {
    "Content-Type": "application/json",
    ...(access_token && { Authorization: `Bearer ${access_token}` })
  };
};
/**
 * typeSense
 * peoductDetail
 */
export const typesenceProduct = () => {
  return {
    "Content-Type": "application/json",
    "X-TYPESENSE-API-KEY": typesenceTokenKey
  };
};

/**
 * typeSense
 * outDated search api key
 */
export const outDatedProductSearch = () => {
  return {
    "Content-Type": "application/json",
    "X-TYPESENSE-API-KEY": typesenceOutDatedPostToken
  };
};

/**
 * typeSense
 * outDated post api key
 */
export const outDatedProductPost = () => {
  return {
    "Content-Type": "application/json",
    "X-TYPESENSE-API-KEY": typesenceOutDatedDeleteToken
  };
};

/**
 * related product details token
 */
export const relatedProductToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer X2pcnY7BZ0Vv7DxstoepNQ=="
  };
};

/**
 * related product details token
 */
export const getDeliveryTypeToken = () => {
  return {
    "Content-Type": "application/json",
    authorization:
      "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3QifQ.FILrByQNl1Mx00RSZonmT3p5waGlFaymZJ3e3a5VBac",
    channel: "shop"
  };
};

/**
 * get stock avalilablity
 */
export const getStockAvaliablityDetailsToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "94cd6185-8c03-42ae-a012-e31a50d7f1f4"
  };
};

/**
 * get stock avalilablity
 */
export const bitlyHeaderToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer 82557cb0262f95e1c29fea26beb69c252b44033f"
  };
};

/**
 * get stock avalilablity
 */
export const crmSalesHeaderToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "cd184e-a7cd6c-8b4b0b-a6a7ef"
  };
};

/**
 * crmToken token set in header
 */
export const crmToken = () => {
  const dataData = Decrypt("userLoginData");
  const { data: { token = "" } = {} } = nullToObject(dataData);
  return {
    "Content-Type": "application/json",
    Authorization: token
  };
};

export const crmServiceCenterToken = () => ({
  "Content-Type": "application/json",
  token: "App hbGciOijJmYTg4Y2I1NiIsInR5cCI6IkpXVCLWZlMTktNGM2OS"
});

/**
 * crmToken token set in header
 */
export const offerToken = () => {
  return {
    "Content-Type": "application/json",
    "x-api-key": offerKey
  };
};

export const feedbackToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "App eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjJmYTg4Y2I1LWZlMTktNGM2OS"
  };
};
/**
 * crmToken token set in header
 */
export const serviceCenterToken = () => {
  const token = Decrypt("serviceCenterToken") || {};
  return {
    "Content-Type": "application/json",
    Authorization: token || ""
  };
};

/**
 * Infobib header
 */
export const infoBibToken = () => {
  return {
    "Content-Type": "application/json",
    Authorization: "App 81563ae9ec22c3856293720cda1e03c2-67f6fbff-c0e5-4586-8973-764df6436369"
  };
};

/**
 *  xApiKey
 */
export const xApiKey = () => {
  return {
    "Content-Type": "application/json",
    "x-api-key": "750b5cb4-f84f-4d6d-b130-5958bc741475"
  };
};

/**
 * crm service center static token
 */
export const extToken = () => {
  return {
    "Content-Type": "application/json",
    token: "App hbGciOijJmYTg4Y2I1NiIsInR5cCI6IkpXVCLWZlMTktNGM2OS"
  };
};

/**
 * crmToken token set in header
 */
export const epmloyeDetailToken = () => {
  return {
    "Content-Type": "application/json",
    access_token: "6d48184e-cd6c-4b0b-a6a7-feff21138b04",
    status: 1
  };
};

/**
 * crmToken token set in header
 */
export const poorvikaProductDetails = () => {
  return {
    "Content-Type": "application/json",
    locale: "en-IN",
    channel: "SHOP"
  };
};
