import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { convertToLowerCase } from "utils/validation";
import RelatedEnquiryCard from "../ProductSlider/RelatedEnquiryCard";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";

const MultipleItems = (props: any = {}) => {
  const { items = [], fromPage = "", handleChooseProduct = () => {} } = props;

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    initialSlide: 0,
    nextArrow: (
      <button type="button" className="flex w-7 h-7">
        <ChevronRightIcon className="rounded-sm w-7 h-7 absolute flex justify-center items-center right-2 -top-11 bg-yellow-500 text-white " />
      </button>
    ),
    prevArrow: (
      <button type="button">
        <ChevronLeftIcon className="rounded-sm w-7 h-7 absolute flex justify-center items-center right-10 -top-11 bg-yellow-500 text-white" />
      </button>
    ),
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="pt-2">
      {items.length > 0 ? (
        <Slider {...settings}>
          {items.map((ele: any, index: number) => (
            <RelatedEnquiryCard
              ele={ele}
              index={index}
              items={items}
              fromPage={fromPage}
              handleChooseProduct={handleChooseProduct}
              key={convertToLowerCase(index + "items-fsd")}
            />
          ))}
        </Slider>
      ) : (
        <div className="text-center">No Products Found</div>
      )}
    </div>
  );
};

export default MultipleItems;
