import React, { useState } from "react";
import { nullToObject } from "utils/validation";
import { XIcon } from "@heroicons/react/outline";
import { commonDatePickerFnction } from "Component/CommonTable/CommonStartToEndDatePic/commonDatePickerFnc";
import CommonStartToEndDatePic from "Component/CommonTable/CommonStartToEndDatePic";

const DatePickerCommon = (props: any) => {
  const {
    userSearch = {},
    dateName = "Created Date",
    _onDateChange = () => {},
    _onClearDate = () => {},
    labelTextClass = ""
  } = nullToObject(props);

  const { endDate = null, startDate = null } = userSearch;

  const [dateHighLighter, setDateHighLighter] = useState(false);

  /**
   * pass date and get it search formate
   * @param param0
   */
  const changeDateGroup = ({ startDate: sDate = "", endDate: eDate = "" }: any) => {
    setDateHighLighter(true);
    const dateObj = commonDatePickerFnction(sDate, eDate);
    _onDateChange(dateObj);
  };

  const clearDate = () => {
    _onDateChange({
      target: {
        name: "startDate",
        value: null,
        nameEdate: "endDate",
        nameEvalue: null
      }
    });
    setDateHighLighter(false);
    _onClearDate();
  };

  return (
    <div className="flex flex-col">
      {dateName ? (
        <label htmlFor="" className={`text-xs mb-0.5 ${labelTextClass}`}>
          {dateName}
        </label>
      ) : null}
      <div
        className={` ${dateHighLighter ? "border-green-500" : "border-gray"}
                         relative rounded inline-flex items-center border justify-center bg-white`}
      >
        <CommonStartToEndDatePic endDate={endDate} startDate={startDate} onChange={changeDateGroup} />

        {(endDate || startDate) && (
          <button type="button" title="reset date" className="inlin-block" onClick={clearDate}>
            <XIcon className="mr-2 w-4 h-4 text-gray-500 cursor-pointer hover:text-gray-600" />
          </button>
        )}
      </div>
    </div>
  );
};

export default DatePickerCommon;
