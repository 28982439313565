import React, { useEffect, useState, useRef } from "react";
import "./assigneeLead.scss";
import { Decrypt } from "utils/secureFile";
import { btn_refresh } from "utils/ClassContent";
import CommonCheck from "Component/Form/CommonCheck";
import { RefreshIcon } from "@heroicons/react/outline";
import { asignToCheckParseCheck } from "../../LeadHelper";
import SalesProductLoader from "../../SalesProductLoader";
import { convertToLowerCase, nullToArray, nullToObject, nullToString } from "utils/validation";
import DropDownForMultiFilter from "Component/CommonTable/CommonTableSortDropdown/DropDownForMultiFilter";
import {
  getAssignBranchDetails,
  getAssignBranchDetailsToStaff,
  syncAssignBranchDetails
} from "../commonSalesProductDetails";

const AssigneeLead = (props: any = {}) => {
  const {
    isEdit = false,
    sendSMSType = {},
    customerData = {},
    onClickCheckBox = () => {},
    passignAssignedDetails = () => {}
  } = nullToObject(props);
  const tempHide = false;
  const { data: { roleId = "" } = {} } = Decrypt("userLoginData");
  const count = useRef<any>(0);
  const assignedTo = asignToCheckParseCheck(customerData);
  const { status = "" } = nullToObject(customerData);
  const [statusLoader, setStatusLoader] = useState(false);
  const isCheckClosed = convertToLowerCase(status) === "closed" ? true : false;
  const [assignedDataState, setAssignedDataState] = useState<any>({});
  const [showroomBranchData, setShowroomBranchData] = useState([]);
  const [showRoomUserList, setShowRoomBasedUserList] = useState([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  const [enableDropDown, setEnableDropDown] = useState({
    userAssign: false,
    branchAssign: false
  });

  const userClickHandle = (event: any = {}) => {
    const { originalArray = [], isEditObj = {} } = event;
    const { branchData: branchDataEdit = {} } = isEditObj;
    const isCheckFilter = originalArray.filter((ele: any) => ele.isCheck === true);
    const { branchData = {} } = assignedDataState;
    const newObj = {
      assignToMe: false,
      userData: nullToObject(isCheckFilter[0]),
      branchData: Object.keys(branchData)?.length > 0 ? branchData : branchDataEdit,
      validate: Object.keys(nullToObject(isCheckFilter[0]))?.length > 0 ? true : false
    };

    passignAssignedDetails({
      target: {
        ...newObj
      }
    });
    setAssignedDataState(newObj);
  };

  const BranchClickHandle = async (event: any = {}) => {
    const { originalArray = [], isSync = false } = event;
    setStatusLoader(true);
    const isCheckFilter = originalArray.filter((ele: any) => ele.isCheck === true);
    const response: any = await getAssignBranchDetailsToStaff(isCheckFilter[0]);
    setStatusLoader(false);
    const { data: { status: resStatus = 0, data: { userList = [] } = {} } = {} } = response;

    const newObj = {
      userData: {},
      validate: false,
      assignToMe: false,
      branchData: isCheckFilter[0]
    };
    setAssignedDataState(newObj);

    if (resStatus === 200) {
      if (isEdit && (count.current === 0 || isSync)) {
        const { empId = "" } = assignedTo;
        const newArr = userList.map((ele: any, i: number) => {
          if (isEdit) {
            return {
              ...ele,
              _id: i,
              isCheck: ele.empId === empId ? true : false,
              itemName: `${ele.name} - ${nullToString(ele?.details[0]?.employee_designation)} - ${
                ele?.details?.[0]?.employee_department || ""
              }`
            };
          } else {
            return {
              ...ele,
              _id: i,
              isCheck: false,
              itemName: `${ele.name} - ${nullToString(ele?.details[0]?.employee_designation)} - ${
                ele?.details?.[0]?.employee_department || ""
              }`
            };
          }
        });

        if (!newArr.find((user: any) => user.isCheck)) {
          const assignedToObj = {
            ...assignedTo,
            _id: newArr.length,
            isCheck: true,
            exclude: true,
            itemName: `${assignedTo.name} - ${nullToString(assignedTo.role)}`
          };
          newArr.unshift(assignedToObj);
        }

        count.current = 2;
        setShowRoomBasedUserList(newArr);
        userClickHandle({ originalArray: newArr, isEditObj: newObj });
      } else {
        const newArr = userList.map((ele: any, i: number) => {
          return {
            ...ele,
            _id: i,
            isCheck: false,
            itemName: `${ele.name} - ${nullToString(ele?.details[0]?.employee_designation)} - ${
              ele?.details?.[0]?.employee_department || ""
            }`
          };
        });
        setShowRoomBasedUserList(newArr);
        passignAssignedDetails({
          target: {
            ...newObj
          }
        });
      }
    } else {
      setShowRoomBasedUserList([]);
    }
  };

  /**
   * send SMS funcionality
   */
  const onClickCheckBoxHandle = (event: any = {}) => {
    const { name = "" } = nullToObject(event.target);
    onClickCheckBox({
      target: {
        name: name
      }
    });
  };

  const getBranchList = async (isSync = false) => {
    setShowRoomBasedUserList([]);
    isSync ? setRefresh(true) : setStatusLoader(true);
    const response = await (isSync ? syncAssignBranchDetails() : getAssignBranchDetails());
    isSync ? setRefresh(false) : setStatusLoader(false);
    const { data: { status: resStatus = 0, data = [] } = {} } = nullToObject(response);

    if (resStatus === 200 || resStatus === 201) {
      if (isEdit) {
        //edit handle
        const { branchCode = "" } = assignedTo;

        const newArray = nullToArray(data).map((ele: any, i: number) => {
          return {
            ...ele,
            _id: i,
            isCheck: ele.shortcode === branchCode ? true : false, //seleted name make false
            itemName: `${ele.branch} - ${ele.shortcode}`
          };
        });

        BranchClickHandle({ originalArray: newArray, isSync });
        setShowroomBranchData(newArray);
      } else {
        const newArray = nullToArray(data).map((ele: any, i: number) => {
          return {
            ...ele,
            _id: i,
            isCheck: false,
            itemName: `${ele.branch} - ${ele.shortcode}`
          };
        });
        setShowroomBranchData(newArray);
      }
    } else {
      setShowroomBranchData([]);
    }
  };

  const handleDropDownClick = (name = "", valus = false) => {
    setEnableDropDown({
      ...enableDropDown,
      [name]: valus
    });
  };

  useEffect(() => {
    getBranchList(); //source list get
  }, []);

  return (
    <React.Fragment>
      {tempHide && <SalesProductLoader statusLoader={statusLoader} />}
      {!isCheckClosed && (
        <>
          {!sendSMSType.assignToMe ? (
            <>
              <DropDownForMultiFilter
                inputErrorMsg={""}
                mustField={true}
                name={"branchAssign"}
                parentClass={"block"}
                enableCheckbox={false}
                labeName={"Branch Assign"}
                customWidthClass={"w-full"}
                arrayData={showroomBranchData}
                filterArrayPass={BranchClickHandle}
                readOnly={enableDropDown.branchAssign}
                handleDropDownClick={handleDropDownClick}
                disabled={(isEdit && roleId) === 4 ? true : sendSMSType.assignToMe}
                DropDownParentClass={
                  !isEdit
                    ? "form-group relative mb-2 sm:mb-2 mt-2 sm:px-3"
                    : "form-group relative mb-2 w-full  mt-1 px-4  sm:w-1/2 xl:w-1/4"
                }
              />

              <DropDownForMultiFilter
                inputErrorMsg={""}
                mustField={true}
                name={"userAssign"}
                enableCheckbox={false}
                labeName={"User Assign"}
                customWidthClass={"w-full"}
                arrayData={showRoomUserList}
                filterArrayPass={userClickHandle}
                readOnly={enableDropDown.userAssign}
                handleDropDownClick={handleDropDownClick}
                DropDownParentClass={
                  !isEdit
                    ? "form-group relative mb-2 sm:mb-2 mt-2 sm:px-3"
                    : "form-group relative mb-2 w-full  mt-1 px-4  sm:w-1/2 xl:w-1/4"
                }
                parentClass={"block"}
                disabled={showRoomUserList.length === 0 ? true : sendSMSType.assignToMe}
              />
            </>
          ) : null}

          {!isCheckClosed ? (
            <CommonCheck
              mustField={false}
              id={"assignToMe"}
              name="assignToMe"
              labelText={"Assign to Me"}
              checked={sendSMSType.assignToMe}
              parentClass={
                !isEdit
                  ? "mr-2 cursor-pointer mb-2 sm:mb-2 mt-2 sm:px-3"
                  : "salesupdate-checkbox form-group relative mb-1 w-full md:w-1/2 mt-4 px-4 xl:w-1/4"
              }
              onChange={(e: any) => onClickCheckBoxHandle(e)}
            />
          ) : null}

          {!sendSMSType.assignToMe ? (
            <div className="form-group relative mb-2 sm:mb-0 ml-2 sm:ml-0 w-full md:w-1/2 mt-2 sm:mt-0 sm:px-4 sales-syncup flex items-center md:pl-2">
              <button
                type="button"
                title={'Click on "Syncup branches" if your branch is not listed in the branch assign dropdown menu.'}
                className={`mt-2 ${btn_refresh}`}
                onClick={() => getBranchList(true)}
                data-jest-id={"jestHandleSync"}
              >
                <RefreshIcon
                  className={`${refresh ? " animate-spin " : " "} h-5 w-5 stroke-current transition-all duration-200`}
                />
              </button>
              <span className="mt-2 ml-2 text-sm whitespace-nowrap">Sync Branches</span>
            </div>
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};

export default AssigneeLead;
