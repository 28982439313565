import * as scorecardType from "../actionTypes/scorecardType";

/**
 * service center delete
 */
export const deleteScoreCardAction = (data: any) => {
  return {
    type: scorecardType.SCORECARD_DELETE,
    data
  };
};

/**
 * service center delete
 */
export const lockScoreCardAction = (data: any) => {
  return {
    type: scorecardType.SCORECARD_LOCK,
    data
  };
};

/**
 * score card response
 */
export const createScoreCardResponseAction = (data: any, _id = "") => {
  return {
    type: scorecardType.SCORECARD_RESPONSE,
    data: { data: data, _id: _id }
  };
};
