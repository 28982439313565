import { _get } from "utils/lodashConst";
import { IconSearch } from "assets/images";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { convertToLowerCase } from "utils/validation";
import OutsideClickHandler from "react-outside-click-handler";

const OutDatedPoductSearch = (props: any = {}) => {
  const {
    name = "",
    value = "",
    inputId = "",
    label = true,
    children = "",
    labelText = "",
    setShow = true,
    inputClass = "",
    parentClass = "",
    iconClickAction,
    mustField = false,
    dropDownData = [],
    readOnly = false,
    inputError = false,
    inputvalid = false,
    dropOnTop = false,
    dropIconClass = "",
    labelTextClass = "",
    disabledDrop = false,
    inputFieldClass = "",
    removeCursor = false,
    inputErrorMsg = "",
    inputWithIcon = false,
    handleOnChange = () => {}, //click
    handleChooseProduct = () => {}, //click
    clearInput = () => {}, //click
    placeholder = "Search Non-deal product..."
  } = props || {};

  const listStyle = "px-3 py-2";
  const [dropShow, setDropShow] = useState(false);

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearInput && clearInput(event);
    handleOnChange && handleOnChange(event);
    setDropShow(true);
  };

  const handleOutSideClick = () => {
    setDropShow(false);
  };

  const handleInputValueReset = () => {
    setDropShow(true);
    clearInput && clearInput({ target: { name: name, value: "" } });
  };

  const result = _get(dropDownData, "[0].hits", []);
  const result1 = _get(dropDownData, "[1].hits", []);
  const resultData = [...result1, ...result];

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      {label && (
        <label
          className={`block text-xs input-label mb-1
          ${labelTextClass ? labelTextClass : ""}
          ${inputError && !inputvalid ? " text-error" : ""}
          ${inputvalid && !inputError ? " text-valid" : ""}`}
        >
          {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}
      <div
        className={`
                ${inputClass ? inputClass : ""}
                ${inputWithIcon ? inputWithIcon : ""} relative`}
      >
        <input
          name={name}
          type="text"
          id={inputId}
          autoCorrect="off"
          autoComplete="off"
          spellCheck={false}
          disabled={disabledDrop}
          value={value ? value : ""}
          readOnly={removeCursor || readOnly}
          placeholder={placeholder ? placeholder : ""}
          onChange={readOnly === true ? () => {} : handleInputValue}
          data-jest-id={"jestHandleInputValueSales"}
          onClick={readOnly ? () => {} : () => setDropShow(true)}
          className={`overflow-hidden overflow-ellipsis block w-60 rounded input-common pl-3 pr-7 py-2 text-sm ${
            inputError && !inputvalid ? " error pr-10" : ""
          }${inputvalid && !inputError ? " input-valid" : ""}${inputFieldClass ? inputFieldClass : ""}${
            inputWithIcon ? " pr-20" : ""
          }`}
        />
        {/* icon */}
        {value !== "" ? (
          <button
            type="button"
            onClick={handleInputValueReset}
            data-jest-id={"jestHandleInputValueResetSales"}
            className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute top-1/2 input-icon-color-fill cursor-pointer right-3 transform -translate-y-1/2
                        ${iconClickAction ? "cursor-pointer" : ""}`}
          >
            <XIcon className="w-5 h-5" />
          </button>
        ) : (
          <i
            className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute input-icon-color-fill top-1/2 right-3 transform -translate-y-1/2 ${
                          iconClickAction ? "cursor-pointer" : ""
                        }`}
          >
            <IconSearch />
          </i>
        )}

        {/* dropDown data */}
        {dropShow && (
          <OutsideClickHandler onOutsideClick={handleOutSideClick}>
            <div
              className={`dropdown  overflow-x-hidden overflow-y-auto bg-white z-10  max-h-60 absolute  left-0 right-0 
                            ${(inputvalid && !inputError) || dropShow ? " input-valid" : ""}${
                              dropOnTop ? " bottom-full dropOnTop -mb-1 rounded-t" : " top-full -mt-1 rounded-b"
                            }`}
            >
              <ul className="h-full">
                {resultData.length > 0 && (
                  <>
                    {resultData.map((item: any, i: any) => {
                      return (
                        <li key={convertToLowerCase(i + "resultData-dddm6")}>
                          <button
                            onClick={() => {
                              handleChooseProduct(item);
                              handleOutSideClick();
                            }}
                            type="button"
                            className={`${listStyle} w-full text-left flex`}
                          >
                            <div className="" style={{ width: "calc(100% - 40px)" }}>
                              <p className="text-black text-sm  w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
                                {_get(item, "document.outdated_nondealing", "")}
                              </p>
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {inputErrorMsg && (
        <span className={`absolute -bottom-5 right-5 error-msg text-xs ${inputErrorMsg ? inputErrorMsg : ""}`}>
          {inputErrorMsg}
        </span>
      )}
      {children}
    </Transition>
  );
};

export default OutDatedPoductSearch;
