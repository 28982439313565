import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { Decrypt } from "utils/secureFile";
import { ImgAvatarMaleSm3 } from "assets/images";
import { imeiNumHistoryApiCall } from "../commonCalllist";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { onWheel } from "Component/SliderMenu/CommonFunctions";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { bg_BasicInfo, bg_EnquiryInfo, inputStylePopup } from "utils/ClassContent";
import BasicInfoView from "./BasicInfoView";
import CommonTable from "Component/CommonTable";
import PageAnimate from "Component/PageAnimate";
import CommonDropHeading from "Component/CommonDropHeading";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import ViewSalesReturn from "../../NewCall/EnquiryFor/SalesReturnsForm/ViewSalesReturn";
import EnquiryDetailsTable from "Pages/NewCall/CustomerDetails/CustomerDetailedTable/EnquiryDetailsTable";
import StatusUpdateSalesReturn from "Pages/NewCall/EnquiryFor/SalesReturnsForm/ViewSalesReturn/StatusUpdateSalesReturn";
import EnquiryStatusTrackingData from "../../NewCall/EnquiryFor/SalesReturnsForm/ViewSalesReturn/StatusUpdateSalesReturn/EnquiryStatusTrackingTable/EnquiryStatusTrackingData";

type EnquiryForUpdatePopupProps = {
  getData?: any;
  closePopup?: any;
  setShow?: boolean;
  activeModal?: boolean;
  handleCustomerStatus?: any;
};

const EnquiryForUpdatePopup = (props: EnquiryForUpdatePopupProps) => {
  const {
    getData = {},
    setShow = true,
    activeModal = false,
    closePopup = () => {},
    handleCustomerStatus = () => {}
  } = nullToObject(props);

  const { status = "", enquiryFor = "", subCategory = "", customerName = "", transactions = [] } = getData;

  const globalStore = useSelector((state: any) => state);
  const { salesReturn: { imeiNumHistoryDetails = {} } = {} } = nullToObject(globalStore);

  const userLoginDetails = Decrypt("userLoginData");
  const { data: { roleId = 0 } = {} } = nullToObject(userLoginDetails);
  const editOption = roleId === 1 || roleId === 6 || roleId === 9 ? true : false;
  const [imeiNo, setImeiNo] = useState("");
  const [editView, setEditView] = useState(false);
  const [columnView, setColumnView] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [popParentTabs, setPopParentTabs] = useState("Tab0");
  const [enableViewMode, setEnableViewMode] = useState(true);
  const [serviceExistingEnquiries, setServiceExistingEnquiries] = useState([]);
  const historyTab = true;
  // const [genralInfo, setGenralInfo] = useState({});

  const handleEditView = () => {
    setEditView(!editView);
    setEnableViewMode(!enableViewMode);
  };

  const handleColumnView = () => {
    setColumnView(!columnView);
  };

  const getModalStatus = (value: boolean) => {
    return setModalStatus(value);
  };

  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) {
      closePopup(); //close popUp press esc button
    }
  };

  const handlePopParentTabs = (value = "") => {
    setPopParentTabs(value);
  };

  /**
   * user clik escape key close popUp
   */
  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);

  useEffect(() => {
    const { data: imeiNumHistoryDetailsData = [] } = imeiNumHistoryDetails;
    const existingEnquiry = imeiNumHistoryDetailsData
      .map((ele: any) => {
        if (
          convertToLowerCase(ele.enquiryFor) === convertToLowerCase("Sales Returns") &&
          ele.imeiMobileNo === _get(getData, "imeiMobileNo", "")
        ) {
          return ele;
        }
        return undefined;
      })
      .filter((eleUndefined: any) => eleUndefined !== undefined);
    setServiceExistingEnquiries(existingEnquiry);
  }, [imeiNumHistoryDetails]);

  useEffect(() => {
    setImeiNo(_get(getData, "imei", ""));
  }, [getData]);

  useEffect(() => {
    imeiNumHistoryApiCall(imeiNo);
  }, [imeiNo]);

  return (
    <FixedPopupTemplate
      overflowChild={true}
      editStatus={editView}
      imgSrc={ImgAvatarMaleSm3}
      columnViewStatus={columnView}
      enableColumnViewOption={true}
      textHeaderHeading={customerName}
      handleEditView={handleEditView}
      handleClosePopup={() => closePopup(false)}
      handleColumnView={handleColumnView}
      textSubHeaderHeading={`Enquiry For : ${enquiryFor}`}
      enableEditOption={editOption && status.toLowerCase() !== "closed"}
    >
      <PageAnimate className="h-full">
        <div className="sticky -top-1 bg-white z-20 shadow-md">
          <ScrollMenu scrollContainerClassName={"flex whitespace-nowrap"} onWheel={onWheel}>
            <a
              href="#BasicInfo"
              data-jest-id={"jestBasicInfo"}
              onClick={() => handlePopParentTabs("BasicInfo")}
              className={` mx-2 ${popParentTabs === "BasicInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                            cursor-pointer font-medium`}
            >
              Basic Info
            </a>

            <a
              href="#EnquiryInfo"
              data-jest-id={"jestEnquiryInfo"}
              onClick={() => handlePopParentTabs("EnquiryInfo")}
              className={` mx-2 ${popParentTabs === "EnquiryInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                         cursor-pointer`}
            >
              Enquiry Info
            </a>
            {/* {(subCategory !== "Duplicate invoice" && serviceExistingEnquiries.length >= 1) && */}
            <a
              href="#IMEIMobile"
              data-jest-id={"jestIMEIMobile"}
              onClick={() => handlePopParentTabs("IMEIMobile")}
              className={` mx-2 ${popParentTabs === "IMEIMobile" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer`}
            >
              IMEI and Mobile Enquiry History
            </a>
            {/* } */}

            <a
              href="#EnquiryStatusHistory"
              data-jest-id={"jestEnquiryStatusHistory"}
              onClick={() => handlePopParentTabs("EnquiryStatusHistory")}
              className={` mx-2 ${
                popParentTabs === "EnquiryStatusHistory" ? " border-b-2 border-yellow-600 font-bold" : ""
              }
                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                         cursor-pointer`}
            >
              Enquiry Status History
            </a>
            <a
              href="#EnquiryStatusUpdate"
              data-jest-id={"jestEnquiryStatusUpdate"}
              onClick={() => handlePopParentTabs("EnquiryStatusUpdate")}
              className={` mx-2 ${
                popParentTabs === "EnquiryStatusUpdate" ? " border-b-2 border-yellow-600 font-bold" : ""
              }
                            rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                            cursor-pointer`}
            >
              Enquiry Status Update
            </a>
          </ScrollMenu>
        </div>
        <div
          className={`${columnView ? "flex h-full items-start flex-wrap lg:flex-nowrap" : ""} overflow-y-auto  md:px-2`}
          style={{ maxHeight: "calc(100% - 100px)" }}
        >
          <div
            className={` ${!activeModal ? "py-5" : "py-1"} ${
              columnView ? " w-full lg:w-7/12" : " "
            } flex-wrap flex  md:px-2`}
          >
            <CommonDropHeading
              tabActiveDefault={popParentTabs === "BasicInfo" ? true : false}
              id="BasicInfo"
              heading="Basic Info"
              customClass={bg_BasicInfo}
            >
              <BasicInfoView customerData={getData} enableViewMode={enableViewMode} handleEditView={handleEditView} />
            </CommonDropHeading>
            <CommonDropHeading
              tabActiveDefault={popParentTabs === "EnquiryInfo" ? true : false}
              id="EnquiryInfo"
              heading="Enquiry Info"
              customClass={bg_EnquiryInfo}
            >
              <CommonSearchDropdown
                label={true}
                setShow={true}
                value={enquiryFor}
                name={"enquiryFor"}
                placeholder={"--None--"}
                inputWithIconClass="w-10"
                labelText={"Enquiry For"}
                labelTextClass={"font-bold"}
                inputFieldClass="h-9 pr-11"
                parentClass={inputStylePopup}
                inputClass="flex justify-end"
                readOnly={true}
              />
              {convertToLowerCase(enquiryFor) === convertToLowerCase("Sales Returns") && (
                <ViewSalesReturn
                  setShow={true}
                  editView={editView}
                  viewModeData={getData}
                  enquiryForValue={subCategory}
                  handleEditView={handleEditView}
                  enableViewMode={enableViewMode}
                  handleCustomerStatus={handleCustomerStatus}
                />
              )}
            </CommonDropHeading>
          </div>
          <div
            className={` ${
              columnView ? "w-full lg:w-5/12 " : " w-full pt-4 border-gray-300 border-t-2 border-dashed "
            } bg-white flex flex-col items-center md:px-2`}
          >
            {subCategory !== "Duplicate invoice" && serviceExistingEnquiries.length >= 1 && (
              <CommonTable
                id="IMEIMobile"
                setShow={true}
                enableModal={3}
                parentClass={"w-full"}
                columnView={columnView}
                heading={"IMEI and Mobile Enquiry History"}
              >
                <EnquiryDetailsTable
                  getModalStatus={true}
                  modalFullscreen={true}
                  cusTransHistory={serviceExistingEnquiries}
                />
              </CommonTable>
            )}

            {enquiryFor === "Sales Returns" && (
              <StatusUpdateSalesReturn editView={editView} viewModeData={getData} columnView={columnView}>
                <div className={`w-full flex transition flex-wrap mb-4 ${!historyTab && columnView ? " " : "h-auto"} `}>
                  <CommonTable
                    id="EnquiryStatusUpdate"
                    setShow={true}
                    parentClass={"w-full"}
                    columnView={columnView}
                    heading={"Enquiry Status History"}
                    getModalStatus={getModalStatus}
                  >
                    <EnquiryStatusTrackingData transactions={transactions} getModalStatus={modalStatus} />
                  </CommonTable>
                </div>
              </StatusUpdateSalesReturn>
            )}
          </div>
          <div id="ModalEnquiryForPopup" />
        </div>
      </PageAnimate>
    </FixedPopupTemplate>
  );
};

export default EnquiryForUpdatePopup;
