import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { Decrypt } from "utils/secureFile";
import { useAuth } from "utils/UseKeyCloak";
import { tabBasedGetFullAccess } from "helperRole";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { IconAddUser, IconDropDown, IconMenu, ImgAvatarMaleSm4, ImgLogoNav } from "assets/images";

type NavbarProps = {
  handleSideMenu: (e?: any) => void;
  handleNewuserPopup: (e?: any) => void;
};

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const Navbar = (props: NavbarProps) => {
  const { handleSideMenu = () => {}, handleNewuserPopup = () => {} } = nullToObject(props);
  const [getDropShow, setDropShow] = useState(false);
  const loginUserDetails = Decrypt("userLoginData");
  const { read: registerEnable = false } = tabBasedGetFullAccess(65);
  const { data: { name = "", role = "" } = {} } = nullToObject(loginUserDetails);

  const keycloak: any = useAuth();

  const clearCookies = () => {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const cookieName = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  };

  const onHandleLogout = async (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault && event.preventDefault();
    clearCookies();
    window.sessionStorage.clear();
    if (keycloak.isAuthenticated) {
      window.localStorage.clear();
      await keycloak.logout();
    }
    window.location.href = "/";
    // navigate("/");
  };

  const navigation = [
    {
      title: "Add new User",
      listType: "Button",
      handler: handleNewuserPopup,
      href: "",
      icons: <IconAddUser className="w-5 h-5" />,
      current: false
    }
  ];

  return (
    <div id="wrapperHead" className="wrapper-head sm:pr-12 " style={{ zIndex: getDropShow ? 20 : 9 }}>
      <Disclosure as="nav" className="">
        {({ open }) => (
          <>
            <div className="mx-auto">
              <div className="relative flex items-center justify-between h-12">
                <button onClick={handleSideMenu} className="ml-3 sm:hidden" type="button">
                  <IconMenu className="w-5 h-5" />
                </button>
                <div className="flex-1 flex justify-end items-center">
                  <Link
                    to={Object.keys(nullToObject(loginUserDetails)?.length > 0) ? "/home" : ""}
                    className="hidden sm:absolute left-0 sm:flex flex-shrink-0 items-center sm:shadow-none  lg:shadow-lg logo"
                  >
                    <img alt="logo" className="h-8 w-full object-contain" src={ImgLogoNav} />
                  </Link>
                  <div className="block sm:ml-6 h-auto">
                    <div className="flex space-x-4">
                      {navigation.map((item, i) => {
                        const isObject = typeof item === "object";
                        const icon = isObject ? item.icons : null;
                        return (
                          <React.Fragment key={convertToLowerCase(i + "navigation-dfs")}>
                            {item.listType === "Button" && registerEnable === true ? (
                              <button
                                type="button"
                                title={item.title}
                                className="text-gray-600 p-1 text-sm flex justify-between items-center hover:bg-black hover:bg-opacity-5
                                                                 transition duration-200 hover:text-yellow-600 rounded"
                                onClick={registerEnable ? () => handleNewuserPopup() : () => {}}
                              >
                                {icon && (
                                  <div className="lg:w-6 sm:w-5 inline-block">
                                    {React.cloneElement(icon, { className: "lg:w-6 sm:w-5 h-auto " })}
                                  </div>
                                )}
                              </button>
                            ) : (
                              <Link
                                to={item.href}
                                title={item.title}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-900 text-gray-300 "
                                    : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                  "px-1 py-1 rounded-md text-sm font-medium flex justify-center items-center"
                                )}
                                aria-current={item.current ? "page" : undefined}
                              >
                                {icon && (
                                  <div className="w-5 sm:w-5 inline-block">
                                    {React.cloneElement(icon, { className: "w-full h-auto  fill-current" })}
                                  </div>
                                )}
                              </Link>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                </div>

                <div className=" inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-2 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div className="flex items-center">
                      <Menu.Button
                        className="bg-gray-800 flex text-sm rounded-full
                                        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                      >
                        <img className="h-8 w-8 rounded-full" src={ImgAvatarMaleSm4} alt="" />
                      </Menu.Button>
                      <Menu.Button className="flex flex-col ml-2 capitalize">
                        <strong
                          className="text-xs -mb-px text-gray-800 overflow-ellipsis overflow-hidden whitespace-nowrap"
                          style={{ maxWidth: "100px" }}
                        >
                          {name.toLowerCase()}
                        </strong>
                        <small className="text-xs  capitalize text-black opacity-70 overflow-ellipsis overflow-hidden whitespace-nowrap">
                          {role.replace("_", " ").toLowerCase()}
                        </small>
                      </Menu.Button>
                      <Menu.Button>
                        <IconDropDown className="w-4 h-4 ml-px text-gray-700" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                      beforeEnter={() => {
                        setDropShow(true);
                      }}
                      afterLeave={() => {
                        setDropShow(false);
                      }}
                    >
                      <Menu.Items
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md
                                                shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
                      >
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/profile"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700  w-full text-left"
                              )}
                            >
                              <ul>
                                <li>
                                  <button
                                    type="button"
                                    className=" w-full text-left cursor-pointer"
                                    onClick={(e: any) => onHandleLogout(e)}
                                  >
                                    Sign out
                                  </button>
                                </li>
                              </ul>
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="bg-white sm:bg-none sm:hidden">
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item, i) => {
                  const isObject = typeof item === "object";
                  const icon = isObject ? item.icons : null;
                  return (
                    <a
                      key={convertToLowerCase(i + "navigation-dfd")}
                      href={item.href}
                      className={classNames(
                        item.current ? "bg-gray-900 text-white" : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "block px-3 py-2 rounded-md text-base font-medium"
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      {icon && (
                        <div className="w-5 sm:w-5 inline-block">
                          {React.cloneElement(icon, { className: "w-full h-auto  fill-current" })}
                        </div>
                      )}
                    </a>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
};
export default Navbar;
