import store from "store";
import { encryptData } from "utils/secureFile";
import { Post, withoutTokenGet } from "ApiProcess/httpRestServices";
import { crmApiBaseUrl, loginApi } from "ApiProcess/httpRestServices/apiManage";
import { createLoginAction, singleSignOnActionCall } from "store/action/loginAction";
import { leadingAndTrailingSpaceAvoid, nullToObject, validateForm } from "utils/validation";

/**
 * @param  {object} obj;
 * login passWord and userName pass
 */

export const dologinService = (obj: any) => {
  const { data: { inputValue = {}, isEmpId = true } = {} } = nullToObject(obj);
  const { email = "", password = "" } = inputValue || {};
  encryptData("userLoginInputDetails", obj);
  encryptData("logData", leadingAndTrailingSpaceAvoid(password), true);
  const encryptPassword = sessionStorage.getItem("logData");
  const dataPayloadLogin: any = {
    isEmpId: isEmpId,
    password: encryptPassword,
    isPin: false,
    ...(isEmpId ? { empId: leadingAndTrailingSpaceAvoid(email) } : { email: leadingAndTrailingSpaceAvoid(email) })
  };

  return Post(`${crmApiBaseUrl}${loginApi.loginPage}`, dataPayloadLogin, "NoToken");
};

/**
 * @param  {object} obj;
 * login passWord and userName pass
 */

export const dologinSesstionUpdate = (obj: any) => {
  const removeBearer = obj?.data?.token?.split("Bearer ")[1];
  const newObjHeader = {
    Authorization: removeBearer
  };
  return withoutTokenGet(`${crmApiBaseUrl}${loginApi.sesstionUdate}`, newObjHeader);
};

/**
 * @param  {object} loginData={}
 * pass userName and password
 * loader Enable
 */
export const callLogInFunction = (loginData = {}) => {
  store.dispatch(createLoginAction(loginData));
  store.dispatch({ type: "LOGIN_INITIAL_LOADER", loginLoader: true }); //loader on
};

/**
 * @param  {object} loginData={}
 * pass userName and password
 * loader Enable
 */
export const callSsoLogInFunction = (token = "", user = {}, isStore = undefined) => {
  store.dispatch(singleSignOnActionCall(token, user, isStore));
  store.dispatch({ type: "LOGIN_INITIAL_LOADER", loginLoader: true }); //loader on
};

/**
 * Single Sign on Api call to log user in
 * @param token login token
 */
export const singleSignOnApiCall = (token = "", user = {}, isStore = undefined) => {
  store.dispatch({ type: "LOGIN_INITIAL_LOADER", loginLoader: true }); //loader on
  store.dispatch(singleSignOnActionCall(token, user, isStore));
};

/**
 * check contain have spl char added
 * @param {string} string
 */
const containsSpecialCharCheck = (string = "") => {
  const reg = /^((?=.*\W)|(?=.*_))/;
  return reg.test(string) ? true : false;
};

/**
 * check password policy return validate value
 * @param password password value
 */
export const checkPasswordPolicy = (password = "") => {
  const lower = new RegExp("(?=.*[a-z])");
  const upper = new RegExp("(?=.*[A-Z])");
  const number = new RegExp("(?=.*[0-9])");
  const newObj = {
    lowerCase: lower.test(password),
    upperCase: upper.test(password),
    digit: number.test(password),
    specialChar: containsSpecialCharCheck(password),
    minLength: ("" + password)?.length >= 8 ? true : false
  };
  const validatePass = validateForm(newObj);
  return { validatePass: validatePass, passwordObj: newObj };
};

export const isNumber = (num: any) =>
  (typeof num === "number" || typeof num) === "string" && num.trim() !== "" && !isNaN(num as number);
