import { _get, _isEmpty } from "utils/lodashConst";

import React, { useEffect, useState } from "react";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import BlueDartTrackingTable from "./BlueDartTrackingTable";
import { getCourierTrackingApiCall, getCourierTrackingInfoApiService } from "../../CommonOrderTracking";

const BlueDartTracking = (props: any) => {
  const { awb_no = "" } = props || {};

  const [trackingData, setTrackingData] = useState({});

  const createInputField = (name = "", label = "", value = "") => {
    return (
      <CommonInput
        name={name}
        labelText={label}
        inputError={false}
        inputFieldClass="h-30"
        readOnly={true}
        value={value}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
    );
  };
  const [loader, setLoader] = useState({});

  const getCourierTrackingApiCall = async (payload) => {
    setLoader(true);
    const response = await getCourierTrackingInfoApiService(payload);
    setLoader(false);

    const { status = 0, data: { trackingData = {} } = {} } = response || {};
    const { response: trackingResponse = [] } = trackingData || {};
    if (status === 200) {
      setTrackingData(_get(trackingResponse, "[0]", {}));
    } else {
      setTrackingData({});
    }
  };

  useEffect(() => {
    if (awb_no) {
      getCourierTrackingApiCall(awb_no);
    }
  }, [awb_no]);

  return (
    <>
      <div className="flex flex-wrap">
        {createInputField("refNo", "Ref No", _get(_get(trackingData, "$", {}), "RefNo", ""))}
        {createInputField("WaybillNo", "Way Bill No", _get(_get(trackingData, "$", {}), "WaybillNo", ""))}
        {createInputField("prodCode", "Product Code", _get(trackingData, "Prodcode", [])[0])}
        {createInputField("Service", "Service", _get(trackingData, "Service", [])[0])}
        {createInputField("pickUpDate", "PickUp Date", _get(trackingData, "PickUpDate", [])[0])}
        {createInputField("PickUpTime", "PickUp Time", _get(trackingData, "PickUpTime", [])[0])}
        {createInputField("origin", "Origin", _get(trackingData, "Origin", [])[0])}
        {createInputField("originAreaCode", "Origin Area Code", _get(trackingData, "OriginAreaCode", [])[0])}
        {createInputField("Destination", "Destination", _get(trackingData, "Destination", [])[0])}
        {createInputField(
          "DestinationAreaCode",
          "Destination Area Code",
          _get(trackingData, "DestinationAreaCode", [])[0]
        )}
        {createInputField("ProductType", "Product Type", _get(trackingData, "ProductType", [])[0])}
        {createInputField("CustomerCode", "Customer Code", _get(trackingData, "CustomerCode", [])[0])}
        {createInputField("CustomerName", "Customer Name", _get(trackingData, "CustomerName", [])[0])}
        {createInputField("SenderName", "Sender Name", _get(trackingData, "SenderName", [])[0])}
        {createInputField("ToAttention", "To Attention", _get(trackingData, "ToAttention", [])[0])}
        {createInputField("Weight", "Weight", _get(trackingData, "Weight", [])[0])}
        {createInputField("Status", "Status", _get(trackingData, "Status", [])[0])}
        {createInputField("StatusType", "Status Type", _get(trackingData, "StatusType", [])[0])}
        {createInputField(
          "ExpectedDeliveryDate",
          "Expected Delivery Date",
          _get(trackingData, "ExpectedDeliveryDate", [])[0]
        )}
        {createInputField("StatusDate", "Status Date", _get(trackingData, "StatusDate", [])[0])}
        {createInputField("StatusTime", "Status Time", _get(trackingData, "StatusTime", [])[0])}
        {createInputField("ReceivedBy", "Received By", _get(trackingData, "ReceivedBy", [])[0])}
        {createInputField("Instructions", "Instructions", _get(trackingData, "Instructions", [])[0])}
      </div>
      <br />
      <div className="mb-10">
        <BlueDartTrackingTable scanDetails={_get(_get(trackingData, "Scans", [])[0], "ScanDetail", [])} />
      </div>
    </>
  );
};

export default BlueDartTracking;
