import React from "react";
import Ripples from "react-ripples";
import { btn_view, tdClass, tdHighLight } from "utils/ClassContent";
import { ChatAltIcon } from "@heroicons/react/outline";
import { convertToLowerCase } from "utils/validation";

const EvaluatorReportsListBody = (props: any = {}) => {
  const { handleViewComment = () => {}, handleEvaluatorMember = () => {}, data = [] } = props;
  return (
    <React.Fragment>
      {data.map((item: any = {}, i = 0) => (
        <tr key={convertToLowerCase(i + "userServiceCenter-tendk")}>
          <td className={tdClass}>
            <button onClick={() => handleEvaluatorMember(item)} type={"button"} className={`${tdHighLight}`}>
              <span>{item.name}</span>
            </button>
          </td>
          <td className={tdClass + " text-center "}>{item.avgScore}%</td>
          <td className={tdClass + " text-center "}>{item.scoreCount}</td>
          <td className={tdClass + " text-center "}>{item.failAllCount || 0}</td>
          <td className={tdClass + " text-center "}>
            <div className="flex justify-center items-center mx-auto space-x-2">
              <Ripples>
                <button
                  type="button"
                  className={btn_view + " p-1 mr-1 rounded "}
                  onClick={() => {
                    handleViewComment(item.comment);
                  }}
                >
                  <ChatAltIcon className="w-4 h-4 " />
                </button>
              </Ripples>
            </div>
          </td>
        </tr>
      ))}
    </React.Fragment>
  );
};

export default EvaluatorReportsListBody;
