import React, { useState } from "react";
import { IconDownArrow } from "assets/images";
import { Transition } from "@headlessui/react";
import { PageSizeDropdownProps } from "./types";
import { CheckIcon } from "@heroicons/react/solid";
import { convertToLowerCase } from "utils/validation";
import OutsideClickHandler from "react-outside-click-handler";

const PageSizeDropdown = (props: PageSizeDropdownProps) => {
  const {
    name = "",
    value = "",
    inputId = "",
    children = "",
    GetData = [],
    inputClass = "",
    parentClass = "",
    iconClickAction,
    readOnly = false,
    dropOnTop = false,
    inputError = false,
    inputvalid = false,
    dropIconClass = "",
    handleParentDropVal,
    prevSelected = false,
    disabledDrop = false,
    inputFieldClass = "",
    removeCursor = false,
    inputWithIcon = false,
    placeholder = "--None--",
    selectorKey = "optionData",
    handleOnChange = () => { }, //click
    clearInput = () => { } //click
  } = props || {};

  const listStyle = "px-3 py-2";
  const [dropShow, setDropShow] = useState(false);

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearInput && clearInput(event);
    handleOnChange && handleOnChange(event);
    setDropShow(true);
  };

  const handleSelectValue = (valuesData: any) => {
    const { [selectorKey]: valueData = "" } = valuesData;
    setDropShow(false);
    if (handleParentDropVal) {
      handleParentDropVal(valuesData);
    }
    handleOnChange && handleOnChange({ target: { name: name, value: valueData } });
  };

  const handleOutSideClick = () => setDropShow(false);

  return (
    <Transition
      show={true}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      <div
        className={`
                ${inputClass ? inputClass : ""}
                ${inputWithIcon ? inputWithIcon : ""}
                relative`}
      >
        <input
          name={name}
          type="text"
          id={inputId}
          autoCorrect="off"
          autoComplete="off"
          spellCheck={false}
          disabled={disabledDrop}
          value={value ? value : ""}
          data-jest-id={"jestInputCheck"}
          readOnly={removeCursor || readOnly}
          placeholder={placeholder ? placeholder : ""}
          onClick={readOnly ? () => { } : () => setDropShow(true)}
          onChange={readOnly === true ? () => { } : handleInputValue}
          className={`block w-60 rounded input-common pl-3 pr-5 py-2 
                    ${inputError && !inputvalid ? " error pr-10" : ""} ${inputvalid && !inputError ? " input-valid" : ""}
                    ${inputFieldClass ? inputFieldClass : ""}
                    ${inputWithIcon ? "pr-20" : ""}
                `}
        />
        {!readOnly && (
          <i
            className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute top-1/2 right-3 transform -translate-y-1/2 ${iconClickAction ? "cursor-pointer" : ""}`}
          >
            <IconDownArrow />
          </i>
        )}
        {dropShow && (
          <OutsideClickHandler onOutsideClick={handleOutSideClick}>
            <div
              className={`dropdown  overflow-x-hidden overflow-y-auto bg-white z-10  max-h-60 absolute  left-0 right-0 
                            ${(inputvalid && !inputError) || dropShow ? " input-valid" : ""}${dropOnTop ? " bottom-full dropOnTop -mb-1 rounded-t" : " top-full -mt-1 rounded-b"
                }`}
            >
              <ul className="h-full">
                {(GetData || [])?.map((item: any, i: any) => 
                    <li value={item[selectorKey]} key={convertToLowerCase(i + "GetData-fsc")}>
                      <button
                        type="button"
                        data-jest-id={"jestHandleSelectValue"}
                        onClick={() => handleSelectValue(item)}
                        className={`${listStyle} w-full text-left flex`}
                      >
                        {prevSelected && <CheckIcon className="absolute left-1 w-4 h-4 text-gray-400 " />}
                        <span>{item[selectorKey]}</span>
                      </button>

                      {item.optionsubText !== "" && <span className="text-lite flex">{item.optionsubText}</span>}
                    </li>)}
              </ul>
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {children}
    </Transition>
  );
};

export default PageSizeDropdown;
