import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import { CalendarIcon } from "@heroicons/react/solid";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { inputStylePopup } from "utils/ClassContent";
import { replyMailTemplate, sendEmailToServerCall } from "Pages/MailBox/commonMailBoxFile";
import AssigneeLead from "../SalesProductInformation/SalesProductDetails/ProductStatusUpdate/AssigneeLead";
import { getsalesStatusList, statusUpdateSalesApiCall } from "../SalesProductInformation/commonSalesProduct";
import {
  filterImeiAndTransactionDetails,
  getImeiAndTransactionDetails
} from "../../SalesReturnsForm/commonSalesReturn";
import {
  handleMobileNumBasedHistorySearch,
  resetTransHistoryInvoceDetails
} from "../../ServiceEnquiry/commonServiceEnquiry";
import {
  convertToLowerCase,
  DateTimeFormeterTimeing,
  dateTimeDisplay,
  nullToObject,
  numOnlyAllow,
  processInput,
  nullToString
} from "utils/validation";
import CommonTable from "Component/CommonTable";
import CommonInput from "Component/Form/CommonInput";
import CommonCheck from "Component/Form/CommonCheck";
import ModalFixed3 from "Component/Modal/ModalFixed3";
import CommonTextarea from "Component/Form/CommonTextarea";
import { pageNumber, tabBasedAccess } from "helperRole";
import SalesProductDetailsTable from "../SalesProductDetails";
import { _get, _isEmpty, _uniqBy } from "utils/lodashConst";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import CustomerCallType from "Pages/NewCall/EnquiryFor/CustomerCallType";
import SalesProductLoader from "../SalesProductInformation/SalesProductLoader";
import { asignToCheckParseCheck } from "../SalesProductInformation/LeadHelper";
import EnquiryMailReply from "Pages/MailBox/ShowMailOnEnquiry/EnquiryMailReply";
import CommonSingleDatePicker from "Component/CommonTable/CommonSingleDatePicker";
import SalesEnqTransactionHiostoryTable from "../../SalesReturnsForm/CreateSalesReturn/SalesEnqTransactionHiostoryTable";
import SalesEnqImeiTransactionHistoryTable from "../../SalesReturnsForm/CreateSalesReturn/SalesEnqImeiTransactionHistoryTable";

const SalesStatusUpdate = (props: any = {}) => {
  const {
    customerData = {},
    columnView = false,
    selectedMailData = {},
    closePopup = () => {},
    isShowRoomStaffAndMobView = false
  } = nullToObject(props);
  const { item_code = "", subCategory = "", biCustomerMobile = "" } = customerData;
  const assignedDataRef = useRef<any>({
    inputData: {
      userData: {},
      branchData: {},
      assignToMe: false
    }
  });

  const isAllowSalesOrder = tabBasedAccess(pageNumber.SALES_ORDER);

  const [checkedData, setCheckedData] = useState({});
  const [invoceBasedImei, setInvoceBasedImei] = useState<any>({}); //invoice based imei number pass
  const [sendSMSType, setSendSMSType] = useState({
    assignToMe: false
  }); //short sms url

  const {
    source = "",
    remarks = "",
    mobileNo = "",
    assignedTo = {},
    productInfo = {},
    currentStatus = "",
    nextFollowUpDate = "",
    status: customerDatastatus = ""
  } = customerData;

  const isClosed = convertToLowerCase(customerDatastatus) === convertToLowerCase("Closed") ? true : false;
  const globalStore = useSelector((state: any) => state) || {};
  const [seletedImeiData, setSeletedImeiData] = useState<any>([]);

  const [mailInfo, setMailInfo] = useState({});
  const [composedHtml, setComposedHtml] = useState("");
  const [isMailReply, setIsMailReply] = useState(false);
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false);
  const [sendAttachmants, setSendAttachmants] = useState([]);
  const [alterNumber, setAlterNumber] = useState<any>({
    altNum: "",
    altNumIsValid: false,
    enableAlterNmberInput: false
  });
  const { altNum = "", enableAlterNmberInput = false, altNumIsValid = false } = alterNumber;

  const [statusData, setStatusData] = useState([]);
  const [statusLoader, setStatusLoader] = useState(false);
  const [currentStatusData, setCurrentStatusData] = useState([]);
  const [originalstatusData, setOriginalStatusData] = useState([]);

  const [salesStatusData, setSalesStatusData] = useState({ status: "", remark: "", currentStatus: "" });

  const [followUpDate, setFollowUpDate] = useState(null);
  const [callType, setCallType] = useState<any>(false);

  /**
   * @param dataElement tranaction invoice and date based get details
   */
  const onChangeTranactionData = (dataElement: any = {}) => {
    setInvoceBasedImei({});
    setCheckedData(dataElement);
    if (!dataElement.isChecked) {
      getImeiAndTransactionDetails(dataElement); // pass element api call
    } else {
      filterImeiAndTransactionDetails(dataElement);
    }
  };

  const passignAssignedDetails = (event: any = {}) => {
    const { target = {} } = event;
    assignedDataRef.current.inputData = target;
  };

  const onClickCheckBox = (event: any = {}) => {
    const { name = "" } = nullToObject(event.target);
    setSendSMSType({
      ...sendSMSType,
      [name]: !sendSMSType[name]
    });
    if (convertToLowerCase(name) === convertToLowerCase("assignToMe")) {
      const newObj = assignedDataRef.current.inputData;
      passignAssignedDetails({
        target: {
          ...newObj,
          assignToMe: !sendSMSType[name]
        }
      });
    }
  };

  // Send reply mail to enquiry mail
  const sendReplyMail = () => {
    const currentMailData = _get(selectedMailData, "mailData", {});
    const originalMailHtml = _get(currentMailData, "html", "");
    const originalMailFrom = _get(currentMailData, "from", []);
    const originalMailText = _get(currentMailData, "text", "");
    const originalMailDate = _get(currentMailData, "date", "");
    const originalMailSubject = _get(currentMailData, "subject", "");
    const originalMailContent = originalMailHtml ? originalMailHtml : originalMailText;

    const originalMailReplyFormat = replyMailTemplate(originalMailDate, originalMailFrom, originalMailContent); // gets original mail in reply format

    const sendMailContent = composedHtml + originalMailReplyFormat;

    const mailData = {
      from: _get(mailInfo, "from", []).pop(),
      cc: _get(mailInfo, "cc", []),
      bcc: _get(mailInfo, "bcc", []),
      toEmail: _get(mailInfo, "to", []),
      subject: originalMailSubject,
      content: sendMailContent,
      attachments: sendAttachmants
    };
    sendEmailToServerCall(mailData);
  };

  const handleSubmitBasedAssign = async () => {
    const { inputData = {} } = assignedDataRef.current;
    const { assignToMe = false, userData = {}, branchData = {} } = inputData;
    const { salesReturn: { groupInvoiceBasedDetailsResponse = {} } = {} } = nullToObject(globalStore);
    let feedbackData: any = [];
    [...seletedImeiData].forEach((item: any) => {
      const { ITEM_CODE = "", ITEM_NAME = "", ITEM_QTY = "", ITEM_SERIAL_NOS = "", PARAENT_KEY } = item;
      if (item.isChecked) {
        const invoiceDtls = groupInvoiceBasedDetailsResponse[PARAENT_KEY] || {};
        feedbackData = [...feedbackData, { ITEM_CODE, ITEM_NAME, ITEM_QTY, ITEM_SERIAL_NOS, ...invoiceDtls }];
      }
    });
    if (callType !== null) {
      //call type check
      const formatedfollowUpDate = DateTimeFormeterTimeing(followUpDate);
      if (salesStatusData.status === "Pending" && _isEmpty(formatedfollowUpDate)) {
        failToast("Please select next follow date");
        return;
      }
      if (_isEmpty(salesStatusData?.remark)) {
        failToast("Remark is Mandatory");
        return;
      }
      const substatus = ["Purchased @ Poorvika Offline", "Purchased @ Poorvika Online"];
      if (
        salesStatusData?.status === "Closed" &&
        substatus.indexOf(salesStatusData.currentStatus) > -1 &&
        !feedbackData?.length
      )
        return failToast("Please select atleast one transaction history");

      if (salesStatusData.status && salesStatusData.currentStatus) {
        setStatusLoader(true); //loader
        const newObj = {
          isQuery: "t",
          feedback: feedbackData,
          ...(!assignToMe && {
            assignedTo: {
              date: new Date(),
              name: userData.name,
              empId: userData.empId,
              branch: branchData.branch,
              branchCode: branchData.shortcode,
              mobile: userData.mobile,
              role: userData.exclude
                ? nullToString(userData.role)
                : nullToString(userData?.details[0]?.employee_designation)
            }
          }),
          callType: callType,
          customerId: _get(customerData, "_id", ""),
          status: _get(salesStatusData, "status", ""),
          enquiryFor: _get(customerData, "enquiryFor", ""),
          subCategory: _get(customerData, "subCategory", ""),
          currentStatus: _get(salesStatusData, "currentStatus", ""),
          nextFollowUpDate: followUpDate ? formatedfollowUpDate : "",
          statusRemarks: isMailReply ? "Sent Mail" : _get(salesStatusData, "remark", ""),
          ...(altNumIsValid && enableAlterNmberInput && { customerAltMobileNum: altNum })
        };

        let respose: any = {};
        if (isMailReply) {
          if (!_isEmpty(_get(mailInfo, "to", [])) && !_isEmpty(_get(mailInfo, "from", []))) {
            respose = await statusUpdateSalesApiCall(newObj);
            sendReplyMail();
          } else {
            failToast("Please enter From/To email address");
          }
        } else {
          if (enableAlterNmberInput && !altNumIsValid) {
            setStatusLoader(false); //loader
            failToast("Please Enter Alter Phone Number");
            return null;
          } else {
            respose = await statusUpdateSalesApiCall(newObj);
          }
        }
        setStatusLoader(false); //loader
        const { data: { status = 0 } = {} } = respose;
        resetTransHistoryInvoceDetails(true);
        if (status === 200) {
          closePopup("reload"); //if reload need pass reload
        }
      } else {
        failToast("Please select status and current status");
      }
    } else {
      failToast("Please select call type");
    }
    resetTransHistoryInvoceDetails();
  };

  // Handle on submit
  const handleSubmit = () => {
    const assignedToIsCheck = Object.keys(assignedTo)?.length === 0 ? true : false;
    const { inputData: { assignToMe = false, branchData = {}, userData = {} } = {} } = nullToObject(
      assignedDataRef.current
    );
    const assignDataHandel = Object.keys(branchData)?.length > 0 && Object.keys(userData)?.length > 0 ? true : false;

    if (assignedToIsCheck) {
      // no assign in history
      handleSubmitBasedAssign();
    } else {
      if (assignToMe || assignDataHandel) {
        //assigne validate
        handleSubmitBasedAssign();
      } else {
        failToast("Please assign to ticket");
      }
    }
  };

  const passValue = (htmlValue = "") => {
    setComposedHtml(htmlValue);
  };

  const passAttachmants = (attachmants: any = []) => {
    setSendAttachmants(attachmants);
  };

  const passMailInfo = (val: Object = {}) => {
    setMailInfo(val);
  };

  /**
   * call type change
   * false out,true mean incoming
   */
  const callTypeHandle = (value: any = false) => {
    setCallType(value);
  };

  /**
   * @param event mobile number based get invoice details
   * i
   */
  const handleMobileSearch = (num: any = "") => {
    handleMobileNumBasedHistorySearch(num ? num : mobileNo);
    setOpenHistoryPopup(true);
  };

  const handleBasicChange = (event: any = {}) => {
    const { target: { value = "" } = {} } = event;

    const newObj = {
      ...alterNumber,
      altNum: value,
      altNumIsValid: convertToLowerCase(value)?.length === 10 ? true : false
    };
    if (convertToLowerCase(value)?.length === 10) {
      handleMobileSearch(value);
    }
    setAlterNumber(newObj);
  };

  /**
   * Handle field values change
   * @param event event
   */
  const onChange = (event: any = {}) => {
    const { status = "" } = salesStatusData;
    const { name = "", value = "" } = _get(event, "target", {});

    if (name === "status") {
      const currenStateUpdate: any = originalstatusData
        .filter((ele: any) => convertToLowerCase(ele.status) === convertToLowerCase(value))
        .map((data) => {
          if (data.subStatus.includes(" At ") || data.subStatus.includes(" at ")) {
            data.subStatus = data.subStatus.replace(" At ", " @ ").replace(" at ", " @ ");
          }
          return data;
        });
      setCurrentStatusData(currenStateUpdate);
    }

    if (
      convertToLowerCase(status) === convertToLowerCase("Closed") &&
      convertToLowerCase(name) === convertToLowerCase("currentStatus")
    ) {
      if (convertToLowerCase(value) === convertToLowerCase("Purchased @ Poorvika with Alt Number")) {
        const newObj = {
          ...alterNumber,
          enableAlterNmberInput: true
        };
        setAlterNumber(newObj);
      } else {
        if (enableAlterNmberInput) {
          const newObj = {
            ...alterNumber,
            enableAlterNmberInput: false
          };
          setAlterNumber(newObj);
        }
      }

      if (
        convertToLowerCase(value) === convertToLowerCase("Purchased @ Poorvika Online") ||
        convertToLowerCase(value) === convertToLowerCase("Purchased @ Poorvika Offline")
      ) {
        handleMobileSearch();
      } else {
        setInvoceBasedImei({});
      }
    } else if (convertToLowerCase(name) === convertToLowerCase("remark")) {
      setInvoceBasedImei({
        //status remark
        ...invoceBasedImei
      });
    } else {
      setInvoceBasedImei({});
    }

    setSalesStatusData({
      ...salesStatusData,
      [name]: value,
      // source mean empty
      //status mean value
      status:
        convertToLowerCase(name) === convertToLowerCase("source")
          ? ""
          : convertToLowerCase(name) === "status"
            ? value
            : salesStatusData.status,
      //remark and current status appen current status value otherwise empty
      currentStatus:
        convertToLowerCase(name) === convertToLowerCase("currentStatus")
          ? value
          : convertToLowerCase(name) === convertToLowerCase("remark")
            ? salesStatusData.currentStatus
            : ""
    });
  };

  /**
   * current status
   */
  const getupdateStatusList = async () => {
    const response = (await getsalesStatusList()) || {};
    const { data: { data: { salesStatus = [] } = {}, status: statusCode = 0, message = "" } = {} } = response || {};
    if (statusCode === 200) {
      const uniq: any = _uniqBy(salesStatus, function (ele) {
        return _get(ele, "id", "");
      });
      setStatusData(uniq); //filter data
      setOriginalStatusData(salesStatus); //with out filter data
    } else {
      setStatusData([]);
      setOriginalStatusData([]);
      failToast(message);
    }
  };

  const onDatePicker = (datePicker: any = null) => {
    setFollowUpDate(datePicker);
  };

  // reset transaction history checked data
  const resetCheckedData = () => {
    setCheckedData({});
  };

  useEffect(() => {
    getupdateStatusList(); //status list
  }, []);

  return (
    <React.Fragment>
      {/* loader */}
      <SalesProductLoader statusLoader={statusLoader} />
      {!isShowRoomStaffAndMobView && (
        <CommonInput
          label={true}
          value={source}
          setShow={true}
          readOnly={true}
          name={"source"}
          mustField={true}
          onChange={onChange}
          labelText={"Source"}
          inputWithIconClass="w-10"
          labelTextClass={"font-bold"}
          inputFieldClass="h-8 pr-11"
          inputClass="flex justify-end"
          parentClass={
            columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
          }
        />
      )}
      <CommonSearchDropdown
        label={true}
        setShow={true}
        name={"status"}
        dropOnTop={false}
        mustField={true}
        removeCursor={true}
        labelText={"Status"}
        arrayData={statusData}
        inputFieldClass="h-8"
        inputWithIcon={false}
        clearInput={onChange}
        selectorKey={"status"}
        placeholder={"--None--"}
        handleOnChange={onChange}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        parentClass={
          columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
        }
        //closed meadn make read only and bind state
        readOnly={isClosed}
        value={!isClosed ? salesStatusData.status : customerDatastatus}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        removeCursor={true}
        readOnly={isClosed}
        clearInput={onChange}
        inputFieldClass="h-8"
        inputWithIcon={false}
        name={"currentStatus"}
        placeholder={"--None--"}
        labelText={"Sub Status"}
        selectorKey={"subStatus"}
        handleOnChange={onChange}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        arrayData={currentStatusData}
        value={!isClosed ? salesStatusData.currentStatus : currentStatus}
        parentClass={
          columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
        }
      />
      {enableAlterNmberInput ? (
        <CommonInput
          value={altNum}
          name={"altNum"}
          mustField={true}
          inputFieldClass="h-8"
          inputWithIcon={true}
          labelText={"Alter Number"}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          onChange={handleBasicChange}
          maxLength={staticData.MobileNo}
          onInput={processInput}
          onKeyPress={numOnlyAllow}
        />
      ) : null}

      {/* folowUp date only for pending */}
      {convertToLowerCase(_get(salesStatusData, "status", "")) === convertToLowerCase("pending") ? (
        <div
          className={
            columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
          }
        >
          <label className="block text-xs input-label mb-1 font-bold" htmlFor="nextFollowUpDate">
            Next Follow Up Date <sup style={{ color: "red" }}>*</sup>
          </label>
          <div className="relative">
            <CommonSingleDatePicker
              minDate={true}
              readOnly={isClosed}
              id="nextFollowUpDate"
              startDate={followUpDate}
              dateChange={onDatePicker}
              parentClass="datePickerWithTime"
              placeholder={isClosed && nextFollowUpDate ? dateTimeDisplay(nextFollowUpDate) : "Follow up date"}
            />
            <label
              htmlFor="nextFollowUpDate"
              className={`w-8 top-px absolute flex justify-center items-center cursor-pointer input-icon-color-fill 
                bottom-px right-px input-group-icon rounded`}
            >
              <CalendarIcon className="w-5 h-5" />
            </label>
          </div>
        </div>
      ) : null}

      {!isClosed && !isShowRoomStaffAndMobView && (
        <CustomerCallType
          inModelView={true}
          callType={callType}
          columnView={columnView}
          callTypeHandle={callTypeHandle}
        />
      )}

      {!isClosed && (
        <div className="w-full">
          {Object.keys(selectedMailData)?.length !== 0 && (
            <CommonCheck
              id="mail"
              labelText="Send Reply Mail"
              checked={isMailReply}
              onChange={() => {
                setIsMailReply(!isMailReply);
              }}
              parentClass={"mb-2 w-full  mt-2 px-4"}
            />
          )}
        </div>
      )}

      {Object.keys(asignToCheckParseCheck(customerData))?.length > 0 && !isShowRoomStaffAndMobView ? (
        <AssigneeLead
          isEdit={true}
          sendSMSType={sendSMSType}
          customerData={customerData}
          onClickCheckBox={onClickCheckBox}
          passignAssignedDetails={passignAssignedDetails}
        />
      ) : null}

      {!isMailReply ? (
        <CommonTextarea
          setShow={true}
          mustField={true}
          name={"remark"}
          isSalesPage={true}
          inputError={false}
          onChange={onChange}
          readOnly={isClosed}
          labelText={"Remark"}
          placeholder={"Remark"}
          columnView={columnView}
          labelTextClass={"font-bold"}
          parentClass={
            columnView ? "mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2" : "mb-4 sm:mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4"
          }
          inputFieldClass="h-32 max-h-64 min-h-textarea"
          value={!isClosed ? salesStatusData.remark : remarks}
        />
      ) : (
        <EnquiryMailReply
          showReplyMail={true}
          passValue={passValue}
          passMailInfo={passMailInfo}
          passAttachmants={passAttachmants}
          selectedMailData={selectedMailData}
        />
      )}
      <SalesProductDetailsTable customerData={customerData} />
      <div className="flex flex-col sm:flex-row items-center justify-center w-full fixed bottom-0 sm:static sm:bottom-auto">
        <button
          type="button"
          hidden={isClosed}
          disabled={statusLoader}
          onClick={handleSubmit}
          className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
        >
          Submit
        </button>
        {subCategory === "Product" && isAllowSalesOrder && item_code && !isClosed && (
          <a
            href={`/sales-order?mobile=${biCustomerMobile}&item_code=${item_code}&searchPinCode=${
              productInfo.searchPinCode || ""
            }`}
          >
            <button
              type="button"
              className="w-40 h-10 ml-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
            >
              Create Order
            </button>
          </a>
        )}
      </div>

      {openHistoryPopup && (
        <ModalFixed3>
          <div className="w-full h-full bg-black bg-opacity-20">
            <CommonTable
              setShow={true}
              enableClose={true}
              parentClass={"w-full"}
              getModalStatus={false}
              handleTableClose={() => {
                setOpenHistoryPopup(false);
                resetTransHistoryInvoceDetails();
              }}
              heading={"Transaction History"}
            >
              <SalesEnqTransactionHiostoryTable
                enableRadio={true}
                getModalStatus={false}
                checkedData={checkedData} //
                resetCheckedData={resetCheckedData}
                handleTableClose={() => setOpenHistoryPopup(false)}
                onChangeTranactionData={onChangeTranactionData}
              />
              <SalesEnqImeiTransactionHistoryTable
                enableRadio={true}
                seletedImeiDataHandler={(data: any) => {
                  setSeletedImeiData(data);
                  setOpenHistoryPopup(false);
                }}
                globalStore={globalStore}
                invoceBasedImei={invoceBasedImei}
              />
            </CommonTable>
          </div>
        </ModalFixed3>
      )}
    </React.Fragment>
  );
};
export default SalesStatusUpdate;
