import { _get } from "utils/lodashConst";

export const teamInitState = {
  cusDeleteLoader: false,
  cusListgetLoader: false,
  cusDeletePopUpClose: null,
  getAllCallByIdLoader: false
};

export const missedListReducer = (state = teamInitState, action = {}) => {
  switch (_get(action, "type")) {
    //all reasult data store here
    case "GET_MISSED_LIST_LOADER": {
      return {
        ...state,
        missedListgetLoader: _get(action, "missedListgetLoader", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
