export const VALIDATE_OTP_ACTION = "VALIDATE_OTP_ACTION"; //validate otp
export const VERIFY_INFO_ACTION = "VERIFY_INFO_ACTION"; // verify gst info
export const MERGE_REQUEST_CUSTOMER = "MERGE_REQUEST_CUSTOMER"; //merge request customer
export const GET_GST_DETAILS_ACTION = "GET_GST_DETAILS_ACTION"; // gst details action
export const GET_PRODUCT_LIST_ACTION = "GET_PRODUCT_LIST_ACTION"; // get product list
export const GET_PINCODE_SEARCH_BA_ACTION = "GET_PINCODE_SEARCH_BA_ACTION"; // picode Search
export const GET_PINCODE_SEARCH_DA_ACTION = "GET_PINCODE_SEARCH_DA_ACTION"; // picode Search
export const SUBMIT_CUSTOMER_INFO_ACTION = "SUBMIT_CUSTOMER_INFO_ACTION"; // customer Info
export const GET_CUSTOMER_DETAIL_ACTION = "GET_CUSTOMER_DETAIL_ACTION"; // get customer details
export const GET_CUSTOMER_INFORMATION_USING_EMAIL = "GET_CUSTOMER_INFORMATION_USING_EMAIL"; // get basic info using email
export const GET_CUSTOMER_INFORMATION_USING_MOBILE = "GET_CUSTOMER_INFORMATION_USING_MOBILE"; // get details using mobile no
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_INFO_LIST"; // get customer list
