import { Get, Post } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import {
  crmApiBaseUrl,
  crmApiProdBaseUrl,
  encodeGetParams,
  enquiryFeedBackApiList
} from "ApiProcess/httpRestServices/apiManage";

export const enquiryFeedBackApiCallList = (obj: any = {}) => {
  const { page = 0, size = 0, search = "", endDate = null, startDate = null, employeeId = "" } = obj;
  const newObj = {
    page,
    size,
    search,
    employeeId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const queryString = encodeGetParams(newObj); //
  return Get(`${crmApiProdBaseUrl}${enquiryFeedBackApiList.getEnquiryFeedBackListCall}?${queryString}`, "crmToken");
};

/**
 * @param  {object} obj;
 * feedBack list download
 */
export const downLoadEnquiryFeedBackList = (obj: any = {}) => {
  const { page = 1, size = 10, search = "", endDate = "", startDate = "" } = obj || {};
  const newObj = {
    page,
    size,
    search,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Post(
    `${crmApiBaseUrl}${enquiryFeedBackApiList.downloadEnquiryFeedBackListCall}${convertString}`,
    {},
    "crmToken"
  );
};
