import { _get } from "utils/lodashConst";
import { withoutTokenGet } from "ApiProcess/httpRestServices";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { convertToLowerCase, nullToArray, nullToObject } from "utils/validation";
import { encodeGetParams, pimNewApi, productDetailsGet, salesProduct } from "ApiProcess/httpRestServices/apiManage";
// import { getProductDetailsByCategory } from "./SalesProductDetails/commonSalesProductDetails";

export const getPriceValue = (item) => {
  const currentDate = new Date();
  const price =
    (item || [])
      .filter(
        (elee: any) =>
          new Date(elee?.startDate) <= currentDate && (currentDate <= new Date(elee?.endDate) || elee?.endDate == null)
      )
      .sort((a: any, b: any) => new Date(b?.startDate).getTime() - new Date(a?.startDate).getTime())[0]?.price || 0;
  return +price || 0;
};
// const getPriceValue = (priceArr) => {
//   let price = 0;
//   if (!_isEmpty(priceArr)) {
//     // eslint-disable-next-line no-param-reassign
//     priceArr = _sortBy(priceArr, "startDate", "desc");
//     const currentdate = new Date();
//     priceArr.forEach((element) => {
//       const startDate = new Date(element.startDate);
//       let endDate: any = "";

//       if (element.endDate && element.endDate !== null) endDate = new Date(element.endDate);
//       else endDate = null;

//       if (currentdate > startDate && ((endDate && currentdate < endDate) || endDate == null))
//         // eslint-disable-next-line prefer-destructuring
//         price = element.price;
//     });
//   }
//   return +price;
// };

const commonDataAppend = (response = {}, channel = "") => {
  const { data: { status = 0, data: { product: products = null } = {}, error: { message = "" } = {} } = {} } =
    nullToObject(response);
  if (status === 200) {
    const mrpData = nullToArray(products?.mrp)?.length > 0 ? products?.mrp : [];
    const priceData = nullToArray(products?.prices)?.length > 0 ? products?.prices : [];

    const product = {
      ...(products || {}),
      mrp: mrpData,
      prices: priceData
    };
    const { mrp: mrp_list = [], prices = [] } = product || {};
    const { price: mrp = [], nlcPrice = [], costPrice = [] } = _get(mrp_list, "[0]", {});
    const { sp = [], min = [], max = [] } = _get(prices, "[0]", {});
    if (convertToLowerCase(channel) === "shop") {
      const shop = (product?.prices || []).find((price: any) => price?.pl === "SHOP");
      return {
        ...product,
        pricesArray: product?.prices,
        mrpArray: product?.mrp,
        prices: {
          sp: getPriceValue(shop?.sp) || getPriceValue(mrp),
          min: getPriceValue(shop?.min),
          max: getPriceValue(shop?.max)
        },
        mrp: {
          price: getPriceValue(mrp),
          nlcPrice: getPriceValue(nlcPrice),
          costPrice: getPriceValue(costPrice)
        }
      };
    } else {
      return {
        ...product,
        pricesArray: product?.prices,
        mrpArray: product?.mrp,
        prices: {
          sp: getPriceValue(sp || []) || getPriceValue(mrp),
          min: getPriceValue(min),
          max: getPriceValue(max)
        },
        mrp: {
          price: getPriceValue(mrp),
          nlcPrice: getPriceValue(nlcPrice),
          costPrice: getPriceValue(costPrice)
        }
      };
    }
  } else {
    failToast(message || "product not found");
    return {};
  }
};

/**
 * product slug id
 * pass slug id getDetails
 * @param slugId
 */
export const getProductDetailsByCategoryDirectApi = (ele: any = {}) => {
  const { item_code = "", shortcode = "", channel = "SHOP" } = ele;
  const newObjHeader = {
    locale: "en-IN",
    channel: channel,
    "Content-Type": "application/json"
  };
  const iCode = typeof item_code === "object" ? item_code?.item_code : item_code;
  const newObj = {
    isproject: "CRM",
    isactive_flag: 1,
    includespecification: 1,
    ...(convertToLowerCase(channel) === "shop" && { shopcode: shortcode || "" })
  };
  const queryString = encodeGetParams(newObj);
  return withoutTokenGet(`${productDetailsGet}${salesProduct.directApiProduct}${iCode}?${queryString}`, newObjHeader);
};

const getProductDetailsDetails = (ele: any = {}) => {
  const { item_code = "", shortcode = "", channel = "SHOP" } = ele;
  const newObjHeader = {
    locale: "en-IN",
    channel: channel,
    "Content-Type": "application/json"
  };
  const iCode = typeof item_code === "object" ? item_code?.item_code : item_code;
  const newObj = {
    isproject: "CRM",
    isactive_flag: 1,
    includespecification: 1,
    ...(convertToLowerCase(channel) === "shop" && { shopcode: shortcode || "" })
  };
  const queryString = encodeGetParams(newObj);
  return withoutTokenGet(`${pimNewApi}${salesProduct.productDeatails}${iCode}?${queryString}`, newObjHeader);
};

export const getProductDetailsCommon = async (ele) => {
  const response = (await getProductDetailsDetails(ele)) || {}; //api call
  const { channel = "SHOP" } = ele;

  const { data: { data: { product = null } = {} } = {} } = nullToObject(response);

  // kv api fail need to call direct api
  if (product !== null) {
    return commonDataAppend(response, channel);
  } else {
    //direct api call
    const responses = (await getProductDetailsByCategoryDirectApi(ele)) || {}; //api call
    return commonDataAppend(responses, channel);
  }
};

export const asignToCheckParseCheck = (customerData) => {
  if (typeof customerData?.assignedTo === "object") {
    return _get(customerData, "assignedTo", {});
  }
  return typeof customerData?.assignedTo === "string" && customerData?.assignedTo.length > 30
    ? JSON.parse(customerData?.assignedTo)
    : {};
};
