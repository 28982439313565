import { _get } from "utils/lodashConst";

type allOrdersReducerTypes = {
  cartLoader?: boolean;
  orderLoader?: boolean;
  wishListLoader?: boolean;
  anonymCartLoader?: boolean;
  updatedCart?: boolean;
  updatedWishList?: boolean;
  updatedAnonymCart?: boolean;
};

export const allOrdersInitialState = {
  cartLoader: false,
  orderLoader: false,
  wishListLoader: false,
  anonymCartLoader: false,
  updatedCart: false,
  updatedWishList: false,
  updatedAnonymCart: false
};

export const allOrdersReducer = (state = allOrdersInitialState, action: allOrdersReducerTypes = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "ALL_ORDERS_INITIAL_STAGE": {
      return {
        ...state,
        ...allOrdersInitialState
      };
    }

    case "SET_CART_LOADER": {
      //loader
      return {
        ...state,
        cartLoader: action.cartLoader
      };
    }

    case "SET_ORDER_LOADER": {
      //loader
      return {
        ...state,
        orderLoader: action.orderLoader
      };
    }

    case "SET_WISH_LIST_LOADER": {
      //loader
      return {
        ...state,
        wishListLoader: action.wishListLoader
      };
    }

    case "SET_UPDATED_CART": {
      //updated cart list
      return {
        ...state,
        updatedCart: action.updatedCart
      };
    }

    case "SET_UPDATED_ANONYM_CART": {
      //updated anonym cart list
      return {
        ...state,
        updatedAnonymCart: action.updatedAnonymCart
      };
    }

    case "SET_UPDATED_WISH_LIST": {
      //updated wish list
      return {
        ...state,
        updatedWishList: action.updatedWishList
      };
    }

    default:
      return {
        ...state
      };
  }
};
