import { _get } from "utils/lodashConst";

type complainInitType = {
  complainSubmitLoader?: any;
  complainSuccessCode?: boolean;
  imeiNumbasedUserData?: any;
  complainActionData?: any;
  imeiNumLoader?: false;
};

export const complainInit = {
  complainSubmitLoader: false,
  complainSuccessCode: false,
  imeiNumbasedUserData: [],
  complainActionData: false,
  imeiNumLoader: false
};

export const complainReducer = (state = complainInit, action: complainInitType = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "COMPLAIN_INITIAL_STAGE": {
      return {
        ...state,
        ...complainInit
      };
    }

    case "COMPLAIN_DATA_SUCCESS_CODE": {
      return {
        ...state,
        complainSuccessCode: _get(action, "complainSuccessCode", false)
      };
    }

    //get details based on IMEI number loader
    case "GET_DETAILS_BASED_IMEI_BASED_LOADER_COMPLAIN": {
      return {
        ...state,
        imeiNumLoader: _get(action, "imeiNumLoader", false)
      };
    }

    case "IMEI_NUMBER_BASED_GET_DETAILS_COMPLAIN": {
      return {
        ...state,
        imeiNumbasedUserData: _get(action, "imeiNumbasedUserData", [])
      };
    }

    case "COMPLAIN_DATA_SUCCESS":
    case "COMPLAIN_DATA_ERROR": {
      return {
        ...state,
        complainActionData: _get(action, "data")
      };
    }

    //complain submit loader
    case "COMPLAIN_SUBMIT_LOADER": {
      return {
        ...state,
        complainSubmitLoader: _get(action, "complainSubmitLoader", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
