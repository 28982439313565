import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/outline";
import { ImgTable } from "assets/images";
import CommonDatePicker from "Component/Form/CommonDatePicker";
import QuickLaunch from "Pages/QuickLaunch";
import React, { Fragment, useState } from "react";
import { thClass } from "utils/ClassContent";
import { nullToObject } from "utils/validation";
import CommonCheck from "../Form/CommonCheck";
import { TableHeadProps } from "./CommonTableSortDropdown/types";

const TableHead = (props: TableHeadProps) => {
  const {
    name = "",
    text = "",
    isData = true,
    children = "",
    badgeData = 0,
    handlerList = [],
    thClassCustom = "",
    enableSort = false,
    checkBoxVal = false,
    checkParentClass = "",
    enableCheckbox = false,
    enableDatePicker = false,
    handleOnchange = () => {},
    datePickerPlaceHolder = "",
    handlerListParentClass = "",
    onCheckBoxChange = () => {},
    enableSelectItemsAction = false,
    handleSort: handleSortProps = () => {}
  } = nullToObject(props);

  const [sort, setSort] = useState(false);

  const handleSort = () => {
    setSort(!sort);
    handleSortProps(!sort);
  };

  const DataTemplate: React.FC = () => {
    return (
      <Fragment>
        <div className={` inline-flex justify-between items-center relative mx-auto`}>
          {/* if we need checkBox */}
          {enableCheckbox && (
            <CommonCheck
              parentClass={checkParentClass}
              label={false}
              checked={checkBoxVal}
              onChange={onCheckBoxChange}
            />
          )}
          <div className="hidden"> checkbox</div>
          {/* sort icon */}
          {text && (
            <button
              type="button"
              onClick={handleSort}
              data-jest-id={"#jestHandleSort"}
              className={`text-center px-1 bg-blueGray-50 text-blueGray-500 align-middle text-xs
                        border-l-0 border-r-0 font-semibold flex justify-between items-center`}
            >
              <span className="text">{text ? text : children}</span>

              {enableSort && (
                <div className="sort w-3 h-3 ml-1">
                  {sort ? (
                    <button type={"button"} title={"sort by descending"}>
                      <ArrowUpIcon className="w-full h-full text-green-600" />
                    </button>
                  ) : (
                    <button type={"button"} title="sort by ascending">
                      <ArrowDownIcon className="w-full h-full" />
                    </button>
                  )}
                </div>
              )}
            </button>
          )}

          {enableDatePicker && (
            <div className={"w-28"}>
              <CommonDatePicker
                name={name}
                customStyle={true}
                closButtonShow={true}
                handleOnchange={handleOnchange}
                placeholderText={datePickerPlaceHolder}
              />
            </div>
          )}
        </div>

        {enableSelectItemsAction ? (
          <QuickLaunch
            parentClass={handlerListParentClass}
            badgeData={badgeData}
            imgUrl={ImgTable}
            GetData={handlerList}
          />
        ) : null}
      </Fragment>
    );
  };

  return isData ? (
    <td className={thClassCustom ? thClassCustom : thClass}>
      <DataTemplate />
    </td>
  ) : (
    <th className={thClassCustom ? thClassCustom : thClass}>
      <DataTemplate />
    </th>
  );
};

export default TableHead;
