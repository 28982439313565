import { _get } from "utils/lodashConst";

export const teamInitState = {
  employeeDetail: {},
  comListgetLoader: false,
  comListApiReload: false
};

export const comListReducer = (state = teamInitState, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "COMLIST_INITIAL_STAGE": {
      return {
        ...state,
        ...teamInitState
      };
    }
    //all reasult data store here
    case "GET_COM_LIST_LOADER": {
      return {
        ...state,
        comListgetLoader: _get(action, "comListgetLoader", false)
      };
    }

    case "RELOAD_COM_LIST_API_CALL": {
      return {
        ...state,
        comListApiReload: _get(action, "comListApiReload", false)
      };
    }

    case "STORE_EMPLOYEE_DETAIL": {
      return {
        ...state,
        employeeDetail: _get(action, "employeeDetail", {})
      };
    }

    // changes email send loader
    case "COMPLAIN_EMAIL_SEND_LOADER": {
      return {
        ...state,
        complainEmailLoader: _get(action, "complainEmailLoader", false)
      };
    }

    // update succescode for send email
    case "COMPLAIN_SEND_EMAIL_SUCCESS": {
      return {
        ...state,
        complainEmailSuccess: _get(action, "complainEmailSuccess", false)
      };
    }
    default:
      return {
        ...state
      };
  }
};
