import React from "react";
import { shippingAddressCardProps } from "../../types";
import { IsFullView } from "utils/ClassContent";

const ShippingAddress = (props: shippingAddressCardProps) => {
  const { shippingAddressCardDatas: { shipping_name = "", shipping_address = "", shipping_mobile_number = "" } = {} } =
    props || {};
  return (
    <div className={` ${IsFullView() ? "w-1/2" : ""} rounded-md w-full px-1 flex-1`}>
      <div>
        <section>
          <h4 className="uppercase tracking-wide text-sm font-semibold text-gray-700 my-2">Shipping Address</h4>
          <div className="mb-3 rounded text-gray-600">
            <label className="flex border border-gray-100 py-2 items-start">
              <span className="text-left px-2 text-sm w-36">Name</span>
              <span className="flex-1 px-2 text-sm">{shipping_name}</span>
            </label>

            <label className="flex border border-gray-100 py-2 items-start">
              <span className="text-left px-2 text-sm w-36">Contact number</span>
              <span className="flex-1 px-2 text-sm">{shipping_mobile_number}</span>
            </label>

            <label className="flex border border-gray-100 py-2 items-start">
              <span className="text-left px-2 text-sm w-36">Address</span>
              <span className="flex-1 px-2 text-sm">{shipping_address}</span>
            </label>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ShippingAddress;
