import React from "react";
import { ProductCardTable } from "../ProductCardTable";
import CommonRadioBox from "Component/Form/CommonRadioBox";
import { DiscountCashCard } from "./DiscountCashCard";

const ComboFreeDisplay = (props: any) => {
  const { offers, offerId, isCombo, comboFreeOffers, setComboFreeOffers, maxBenefites } = props;

  const calculateDiscountValue = (prod: any) => {
    let discountValue = 0;
    prod.forEach((offer: any) => {
      if (isCombo) discountValue += offer.discountValue;
      else discountValue += offer.mrpPrice;
    });

    return discountValue;
  };

  const handleCheck = (prod: any) => {
    const cfOffers = comboFreeOffers.map((item: any) => {
      if (item._id === offerId) {
        let discountValue = 0;
        prod.forEach((offer: any) => {
          if (isCombo) discountValue += offer.discountValue;
          else discountValue += offer.mrpPrice;

          if (offer.type === "Cash") item.cashDiscount = true;
          else item.cashDiscount = false;
        });
        item.maxBenefites = discountValue;
      }
      return item;
    });
    setComboFreeOffers(cfOffers);
  };

  const handleChosen = (prod: []): boolean => {
    const discountValue = calculateDiscountValue(prod);
    return discountValue === maxBenefites;
  };

  return (
    <div className="combooffer-page mt-2">
      <div className="combooffer-selection">
        <div className="product-header">
          <div className="product-card">
            <div className="combooffer-products">
              <div className="freebiesorcomboproductCard">
                {offers.length
                  ? offers?.map((prod: any, index: number) => {
                      return (
                        <>
                          <CommonRadioBox checked={handleChosen(prod)} onChange={() => handleCheck(prod)} />
                          <div className="product-multiField-container-flex ">
                            <div className="product-multiField-container">
                              {prod?.[0]?.type === "Cash" ? (
                                <DiscountCashCard products={prod} />
                              ) : (
                                <ProductCardTable combooff={true} products={prod} />
                              )}
                            </div>
                          </div>
                          {index < offers.length - 1 && (
                            <div className="hr-lin relative m-1">
                              <hr className="hr-line" />
                              <div className="add-btn text-center bg-white w-16 mx-auto absolute left-1/2  transform translate-x-1/2 -top-3.5">
                                OR
                              </div>
                            </div>
                          )}
                        </>
                      );
                    })
                  : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComboFreeDisplay;
