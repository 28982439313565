import React from "react";
import { XIcon } from "@heroicons/react/outline";
import CommonImageTag from "Component/Form/CommonImageTag";
import { convertToLowerCase, getDocType, imageTypeCheck } from "utils/validation";
import { ImgCsv, ImgDoc, ImgFile, ImgPdf, ImgTxt, ImgXls } from "assets/images";

const ImageUploadReturn = (props: any) => {
  const { imagRenderArry = [], enableViewMode = false, CommonImageStyle = "", onClosImage = () => { } } = props || {};

  const handleDoctypesImage = (doctype = "") => {
    switch (doctype) {
      case "pdf":
        return ImgPdf;
      case "doc":
        return ImgDoc;
      case "xlsx":
        return ImgXls;
      case "xls":
        return ImgXls;
      case "txt":
        return ImgTxt;
      case "csv":
        return ImgCsv;
      default:
        return ImgFile;
    }
  };

  return (
    <div className="w-full flex flex-wrap justify-center px-3">
      {imagRenderArry.map((ele: any, i: number) => {
        return (
          <React.Fragment key={convertToLowerCase(i + "imagRenderArry-fsvx")}>
            <div className="w-28 h-28 relative m-1 rounded overflow-hidden">
              {!enableViewMode && (
                <span
                  onClick={() => onClosImage(i)}
                  className={`cursor-pointer shadow-lg drop-shadow-sm absolute -top-1 -right-1 rounded-full p-1 bg-gray-700 text-white w-5 h-5
                                                        hover:bg-red-400 transition-all `}
                >
                  <XIcon className=" w-full h-full" />
                </span>
              )}

              {imageTypeCheck(ele.type) ? (
                <a href={ele.url} target="_blank" rel="noreferrer" className="inline-block w-full h-full">
                  <img src={ele.url} alt={ele.fileName} className="w-full h-full object-contain object-center p-1" />
                </a>
              ) : (
                <a
                  className="bg-white shadow rounded-sm p-2 inline-block border-gray-200 border w-28"
                  href={ele.url}
                  target="_blank"
                  rel="noreferrer"
                  title={ele.fileName}
                >
                  <div className={` flex flex-col justify-between items-center text-center`}>
                    <span className="text-sm block w-full c-primary uppercase font-bold mb-2">
                      {getDocType(ele.type)}
                    </span>
                    <CommonImageTag
                      altName={ele.fileName}
                      className={CommonImageStyle !== "" ? CommonImageStyle : "w-auto h-10 c-primary"}
                      imgData={handleDoctypesImage(getDocType(ele.type))}
                    />
                    <div className="overflow-hidden overflow-ellipsis whitespace-nowrap w-full text-sm mt-2">
                      {ele.fileName}
                    </div>
                  </div>
                </a>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default ImageUploadReturn;
