import React from "react";
import { ImgAvatarMaleSm3 } from "assets/images";
import { headerButton } from "utils/ClassContent";
import BlueDartTracking from "./BlueDartTracking";
import EcomExpressTracking from "./EcomExpressTracking";

const AWBTracking = (props: any) => {
  const { orderData = {}, closePopup = () => {} } = props || {};
  const { awb_no = "", courier_name = "" } = orderData || {};
  return (
    <React.Fragment>
      <div className="absolute bg-black opacity-80 inset-0 z-0 next-w-full" />
      <form
        autoComplete="off"
        className="popup_dataView w-full h-full flex-1 relative z-1 flex flex-col bg-white transform -translate-y-1/2 top-1/2"
      >
        <div className="flex items-center justify-between text-sm bg-primary-gradient px-3 py-2">
          <div className="flex items-center">
            <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
              <img
                className="object-cover bg-white w-full h-full rounded-full"
                src={ImgAvatarMaleSm3}
                alt=""
                loading="lazy"
              />
              <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
            </div>
            <div className="">
              <p className="font-semibold text-sm text-white">AWB No : {awb_no}</p>
              <p className="text-sm text-white ">Courier Name : {courier_name}</p>
            </div>
          </div>

          <div className="flex justify-center items-center space-x-2">
            <button
              className={headerButton}
              type="button"
              onClick={closePopup}
              data-jest-id={"jestClosePopupAWBTracking"}
            >
              Close
            </button>
          </div>
        </div>
        <div className="overflow-y-auto">
          {courier_name === "Bluedart" && <BlueDartTracking awb_no={awb_no} />}
          {courier_name === "Ecom Exp" && <EcomExpressTracking awb_no={awb_no} />}
        </div>
      </form>
    </React.Fragment>
  );
};

export default AWBTracking;
