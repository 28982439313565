import { toLocaleNumber } from "./EffectivePriceView/OfferCommon";
import "./styles.scss";

export const DiscountCashCard = (props: any) => {
  const { products = [] } = props;
  return products?.map((product: any, index: any) => {
    return (
      <div key={`cashcard`} className="product-multiField-box">
        {index !== 0 && (
          <div className="btn-Wrapper">
            <p>+</p>
          </div>
        )}
        <div className="flex px-4 py-2 flex-col sm:flex-row">
          <div className="inline-flex flex-row lg:pl-0 ">
            <img className="h-auto w-28 sm:w-40 object-contain" alt={"combo-img" + index} src={product.cashImg} />
          </div>
          <div className="flex flex-col">
            <p className="font-bold text-sm">{"Cash Discount"}</p>
            <div className="product-price flex mt-1">
              <h4 className="mr-2 font-semibold">Amount:</h4>
              <p className="font-bold text-green-600">{toLocaleNumber(product?.cashDiscount)}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });
};
