import * as cusListTypes from "../actionTypes/cusListTypes";
import * as advocateTypes from "../actionTypes/advocateType";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { deleteCusListServices } from "utils/services/cusListService";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";

/**
 * yeild call on delete listings
 * @param endPath
 */
function* yieldSuccess(endPath = "", searchParams: any = null): any {
  if (endPath === "complains") {
    yield all([
      put({ type: "CUS_DELETE_LOADER", cusDeleteLoader: false }),
      put({ type: "CUS_DELETE_POP_UP_CLOSE", cusDeletePopUpClose: true }),
      put({ type: "RELOAD_COM_LIST_API_CALL", comListApiReload: true })
    ]);
  } else {
    const isAdvocate = endPath === "advocate";
    yield all([
      put({ type: "CUS_DELETE_LOADER", cusDeleteLoader: false }),
      put({ type: "CUS_DELETE_POP_UP_CLOSE", cusDeletePopUpClose: true }),
      put({
        type: isAdvocate ? advocateTypes.GET_ADVOCATE_LIST : "RELOAD_CUS_LIST_API_CALL",
        ...(isAdvocate ? { data: searchParams || {} } : { cusListApiReload: true })
      })
    ]);
  }
  if (endPath === "enquires/services") {
    yield all([
      put({ type: "CUS_DELETE_LOADER", cusDeleteLoader: false }),
      put({ type: "CUS_DELETE_POP_UP_CLOSE", cusDeletePopUpClose: true }),
      put({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: true })
    ]);
  }
  if (endPath === "enquires/onlines") {
    yield all([
      put({ type: "CUS_DELETE_LOADER", cusDeleteLoader: false }),
      put({ type: "CUS_DELETE_POP_UP_CLOSE", cusDeletePopUpClose: true })
    ]);
  }
}
/**
 * @param  {object} obj
 * delete cusList
 */
function* deleteCusListSaga(obj: any = {}): any {
  try {
    const { data: { searchParams = null } = {} } = obj;
    const response = yield call(deleteCusListServices, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      //sucess cus delete
      successToast(message); //toast throw
      yield yieldSuccess(obj?.data?.endPath, searchParams);
    } else {
      failToast(message);
      yield yieldSuccess(obj?.data?.endPath);
    }
  } catch (error) {
    failToast("Something went wrong while deleting customer");
    yield yieldSuccess(obj?.data?.endPath);
  }
}

export default function* cusListSaga() {
  yield takeLatest(cusListTypes.DELETE_CUSTOMER_LIST, deleteCusListSaga);
}
