import React from "react";
import { nullToObject } from "utils/validation";
import { bg_OrderInfo } from "utils/ClassContent";
import CommonDropHeading from "Component/CommonDropHeading";

/**
 * Basic Info Drop Heading
 * @param props props
 * @returns Shows basic customer info on enquiry popup
 */
const OnlineOrderInfoDropHeading = (props: any = {}) => {
  const {
    enquiryInfo = {},
    popParentTabs = "",
    createInputFields = () => {},
    handleShowOrderDetails = () => {}
  } = nullToObject(props);

  return (
    <CommonDropHeading
      id="OrderInfo"
      heading="Order Info"
      customClass={bg_OrderInfo}
      tabActiveDefault={popParentTabs === "OrderInfo" ? true : false}
    >
      {createInputFields("Order Id", "orderId", enquiryInfo)}
      {createInputFields("Sub Category", "subCategory", enquiryInfo)}
      {createInputFields("Options", "options", enquiryInfo)}
      {createInputFields("Status", "status", enquiryInfo)}
      {createInputFields("Sub Status", "currentStatus", enquiryInfo)}
      {createInputFields("Remarks", "remarks", enquiryInfo)}
      <button
        type="button"
        className=" mt-4 h-8 text-yellow-500 text-xs font-bold px-3 mx-4 py-1 rounded ease-linear
                     transition-all duration-150 border border-yellow-500 hover:bg-yellow-500 hover:text-white"
        onClick={handleShowOrderDetails}
      >
        Show Order Details
      </button>
    </CommonDropHeading>
  );
};

export default OnlineOrderInfoDropHeading;
