import * as gstType from "../actionTypes/customerInfoTypes";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { failToast, infoToast, successToast } from "Component/ToastServices/ToastDisplay";
import {
  validateOtpServices,
  getGstDetailService,
  getProductListService,
  verifyMobileEmailServices,
  submitCustomerInfoServices,
  mergeRequestCustomerServices,
  getCustomerDetailServices,
  getCustomerListServices
} from "utils/services/customerInfoService";
import {
  getLocateBasicInfoServices,
  getuserBasicInfoUsingEmailServices,
  getuserBasicInfoUseingMobileNumServices
} from "utils/services/basicInfoService";

/**
 * @param  {object} obj
 * get gst info
 */
function* getGstDetails(obj = {}): any {
  try {
    const response = yield call(getGstDetailService, obj);
    const { data: { data: { data = {} } = {}, status = 0 } = {} } = response;
    if (status === 200 && data && data.sts) {
      successToast("Please wait while fetching records ...");
      yield put({ type: "GET_GST_DETAIL_LOADER", gstDetailLoader: false });
      yield put({ type: "GET_GST_DETAIL_DATA", gstDetailsData: data });
    } else {
      yield put({ type: "GET_GST_DETAIL_DATA", gstDetailsData: {} });
      yield put({ type: "GET_GST_DETAIL_LOADER", gstDetailLoader: false });
      failToast("No data found for this gstin");
    }
  } catch (error) {
    yield put({ type: "GET_GST_DETAIL_DATA", gstDetailsData: {} });
    yield put({ type: "GET_GST_DETAIL_LOADER", gstDetailLoader: false });
    failToast("Something went wrong");
  }
}

/**
 * @param  {object} obj
 * get product list
 */
function* getProductList(obj = {}): any {
  try {
    const response = yield call(getProductListService, obj);
    const { data: { Data = {} } = {}, status = "" } = response;
    if (status === 200 && Data) {
      yield put({
        type: "GET_PRODUCT_LIST_LOADER",
        getProductListLoader: false
      });
      yield put({ type: "GET_PRODUCT_LIST_DATA", getProductListData: Data });
    } else {
      yield put({ type: "GET_PRODUCT_LIST_DATA", getProductListData: {} });
      yield put({
        type: "GET_PRODUCT_LIST_LOADER",
        getProductListLoader: false
      });
      failToast("No data found for this gstin");
    }
  } catch (error) {
    yield put({ type: "GET_PRODUCT_LIST_DATA", getProductListData: {} });
    yield put({ type: "GET_PRODUCT_LIST_LOADER", getProductListLoader: false });
    failToast("Something went wrong");
  }
}

const pinCodeCommon = (data = []) => {
  return data.map((ele: any) => {
    return {
      ...ele,
      optionData: ele.area
    };
  });
};

/**
 * @param  {} obj={object}
 * @returns anyode data
 * pinCode information
 */
function* getAreaLocateBasedBAPincode(obj = {}): any {
  try {
    const response = yield call(getLocateBasicInfoServices, obj);
    const { status = 0, data: { data = [] } = {} } = response;
    if (status === 200) {
      const picodeModify = pinCodeCommon(data);
      yield all([
        put({
          type: "BASIC_IFO_PINCODE_BA_LOADER",
          userInfoPinCodeBALoader: false
        }), //loaderOff
        put({
          type: "BASIC_IFO_PINCODE_BA_LOADER_DATA",
          userInfoPinDataBA: picodeModify
        }) //pinCode data
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_PINCODE_BA_LOADER_DATA",
          userInfoPinDataBA: []
        }), //pinCode data
        put({
          type: "BASIC_IFO_PINCODE_BA_LOADER",
          userInfoPinCodeBALoader: false
        }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "BASIC_IFO_PINCODE_BA_LOADER_DATA", userInfoPinDataBA: [] }), //pinCode data
      put({
        type: "BASIC_IFO_PINCODE_BA_LOADER",
        userInfoPinCodeBALoader: false
      }) //loaderOff
    ]);
  }
}

/**
 * @param  {} obj={object}
 * @returns anyode data
 * pinCode information
 */
function* getAreaLocateBasedDAPincode(obj = {}): any {
  try {
    const response = yield call(getLocateBasicInfoServices, obj);
    const { status = 0, data: { data = [] } = {} } = response;
    if (status === 200) {
      const picodeModify = pinCodeCommon(data);
      yield all([
        put({
          type: "BASIC_IFO_PINCODE_DA_LOADER",
          userInfoPinCodeDALoader: false
        }), //loaderOff
        put({
          type: "BASIC_IFO_PINCODE_DA_LOADER_DATA",
          userInfoPinDataDA: picodeModify
        }) //pinCode data
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_PINCODE_DA_LOADER_DATA",
          userInfoPinDataDA: []
        }), //pinCode data
        put({
          type: "BASIC_IFO_PINCODE_DA_LOADER",
          userInfoPinCodeDALoader: false
        }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "BASIC_IFO_PINCODE_DA_LOADER_DATA", userInfoPinDataDA: [] }), //pinCode data
      put({
        type: "BASIC_IFO_PINCODE_DA_LOADER",
        userInfoPinCodeDALoader: false
      }) //loaderOff
    ]);
  }
}

/**
 * @param  {} obj={object}
 * @returns anyode data
 * verify mobile data
 */
function* verifyMobileEmail(obj: any = {}): any {
  try {
    const response = yield call(verifyMobileEmailServices, obj);
    const { data: { data = [], status = 0 } = {} } = response;
    if (status === 201) {
      infoToast("Otp sent successfully");
      yield all([
        put({ type: "VERIFY_INFO_LOADER", verifyLoader: false }), //loaderOff
        put({ type: "VERIFY_INFO_DATA", verifyInfoData: data }) //pinCode data
      ]);
    } else {
      failToast("Unable to send otp now");
      yield all([
        put({ type: "VERIFY_INFO_DATA", verifyInfoData: {} }), //pinCode data
        put({ type: "VERIFY_INFO_LOADER", verifyLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    failToast("Something went wrong");
    yield all([
      put({ type: "VERIFY_INFO_DATA", verifyInfoData: {} }), //pinCode data
      put({ type: "VERIFY_INFO_LOADER", verifyLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {} obj={object}
 * @returns anyode data
 * pinCode information
 */
function* submitCustomerInfo(obj = {}): any {
  try {
    const response = yield call(submitCustomerInfoServices, obj);
    const { data: { data = {}, message = {}, status = 0 } = {} } = response;
    if (status === 201) {
      successToast("Order created successfully.");
      yield all([
        put({ type: "CUSTOMER_INFO_SUBMIT_LOADER", customerInfoLoader: false }), //loaderOff
        put({
          type: "CUSTOMER_INFO_SUBMIT_DATA",
          customerInfoData: data
        }) //pinCode data
      ]);
    } else {
      failToast(message);
      yield all([
        put({
          type: "CUSTOMER_INFO_SUBMIT_DATA",
          customerInfoData: {}
        }), //pinCode data
        put({ type: "CUSTOMER_INFO_SUBMIT_LOADER", customerInfoLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    failToast("Something went wrong");
    yield all([
      put({ type: "CUSTOMER_INFO_SUBMIT_DATA", customerInfoData: {} }), //pinCode data
      put({ type: "CUSTOMER_INFO_SUBMIT_LOADER", customerInfoLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {} obj={object}
 * @returns anyode data
 * validate otp
 */
function* validateOtp(obj: any = {}): any {
  try {
    const response = yield call(validateOtpServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response;
    infoToast(message);
    if (status === 201) {
      yield all([
        put({ type: "VALIDATE_OTP_LOADER", validateLoader: false }), //loaderOff
        put({ type: "VALIDATE_OTP_DATA", validateInfoData: data }) //pinCode data
      ]);
    } else {
      failToast("Unable to verify otp now");
      yield all([
        put({ type: "VALIDATE_OTP_DATA", validateInfoData: {} }), //pinCode data
        put({ type: "VALIDATE_OTP_LOADER", validateLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    failToast("Something went wrong");
    yield all([
      put({ type: "VALIDATE_OTP_DATA", validateInfoData: {} }), //pinCode data
      put({ type: "VALIDATE_OTP_LOADER", validateLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get user basic info
 */
function* getUserBasicDetailsUsingEmail(obj = {}): any {
  try {
    const response = yield call(getuserBasicInfoUsingEmailServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "CUSTOMER_INFO_USER_FIND_DATA_EMAIL", basicInfoUserinfoEmail: data }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "CUSTOMER_INFO_USER_FIND_DATA_EMAIL", basicInfoUserinfoEmail: null }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
      failToast(message);
    }
  } catch (error) {
    yield all([
      put({ type: "CUSTOMER_INFO_USER_FIND_DATA_STORE", basicInfoUserinfoEmail: null }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
    failToast("Error in getting customer details");
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get user basic info
 */
function* getUserBasicDetailsUsingMobile(obj = {}): any {
  try {
    const response = yield call(getuserBasicInfoUseingMobileNumServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "CUSTOMER_INFO_USER_FIND_DATA_MOBILE", basicInfoUserinfoMobile: data }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "CUSTOMER_INFO_USER_FIND_DATA_MOBILE", basicInfoUserinfoMobile: null }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
      failToast(message);
    }
  } catch (error) {
    yield all([
      put({ type: "CUSTOMER_INFO_USER_FIND_DATA_MOBILE", basicInfoUserinfoMobile: null }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
    failToast("Error in getting customer details");
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * merge request customer
 */
function* mergeRequestCustomer(obj = {}): any {
  try {
    const response = yield call(mergeRequestCustomerServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 201) {
      infoToast(message);
      yield all([
        put({ type: "MERGE_REQUEST_CUSTOMER_DATA", mergeRequestData: data }),
        put({ type: "MERGE_REQUEST_CUSTOMER_LOADER", mergeRequestLoader: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "MERGE_REQUEST_CUSTOMER_DATA", mergeRequestData: {} }),
        put({ type: "MERGE_REQUEST_CUSTOMER_LOADER", mergeRequestLoader: false }) //loaderOff
      ]);
      failToast(message);
    }
  } catch (error) {
    yield all([
      put({ type: "MERGE_REQUEST_CUSTOMER_DATA", mergeRequestData: {} }),
      put({ type: "MERGE_REQUEST_CUSTOMER_LOADER", mergeRequestLoader: false }) //loaderOff
    ]);
    failToast("Error in getting customer details");
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get customers details
 */
function* getCustomerDetails(obj = {}): any {
  try {
    const response = yield call(getCustomerDetailServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      infoToast(message);
      yield all([
        put({ type: "GET_CUSTOMER_DETAIL_DATA", customerDetailData: data }),
        put({ type: "GET_CUSTOMER_DETAIL_LOADER", customerDetailLoader: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "GET_CUSTOMER_DETAIL_DATA", customerDetailData: data }),
        put({ type: "GET_CUSTOMER_DETAIL_LOADER", customerDetailLoader: false }) //loaderOff
      ]);
      failToast(message);
    }
  } catch (error) {
    yield all([
      put({ type: "GET_CUSTOMER_DETAIL_DATA", customerDetailData: {} }),
      put({ type: "GET_CUSTOMER_DETAIL_LOADER", customerDetailLoader: false }) //loaderOff
    ]);
    failToast("Error in getting customer details");
  }
}

/**
 * @param  {object} obj
 * get complain list
 */
function* getCustomerListSaga(obj: any = {}): any {
  try {
    const response = yield call(getCustomerListServices, obj);
    const { data: { data = [], status = 0 } = {} } = response;
    if (status === 200) {
      yield all([
        put({ type: "STORE_CUSTOMER_LIST_DATA", customerListData: data }),
        put({ type: "GET_CUSTOMER_LIST_LOADER", customerListgetLoader: false })
      ]);
    } else {
      yield all([
        put({ type: "STORE_CUSTOMER_LIST_DATA", customerListData: {} }),
        put({ type: "GET_CUSTOMER_LIST_LOADER", customerListgetLoader: false })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "STORE_CUSTOMER_LIST_DATA", customerListData: {} }),
      put({ type: "GET_CUSTOMER_LIST_LOADER", customerListgetLoader: false })
    ]);
    failToast("Error in fetching list");
  }
}

export default function* customerInfoSaga() {
  yield takeLatest(gstType.GET_GST_DETAILS_ACTION, getGstDetails);
  yield takeLatest(gstType.VERIFY_INFO_ACTION, verifyMobileEmail);
  yield takeLatest(gstType.VALIDATE_OTP_ACTION, validateOtp);
  yield takeLatest(gstType.GET_PRODUCT_LIST_ACTION, getProductList);
  yield takeLatest(gstType.SUBMIT_CUSTOMER_INFO_ACTION, submitCustomerInfo);
  yield takeLatest(gstType.GET_CUSTOMER_DETAIL_ACTION, getCustomerDetails);
  yield takeLatest(gstType.GET_CUSTOMER_INFORMATION_USING_EMAIL, getUserBasicDetailsUsingEmail);
  yield takeLatest(gstType.GET_CUSTOMER_INFORMATION_USING_MOBILE, getUserBasicDetailsUsingMobile);
  yield takeLatest(gstType.GET_PINCODE_SEARCH_BA_ACTION, getAreaLocateBasedBAPincode);
  yield takeLatest(gstType.GET_PINCODE_SEARCH_DA_ACTION, getAreaLocateBasedDAPincode);
  yield takeLatest(gstType.MERGE_REQUEST_CUSTOMER, mergeRequestCustomer);
  yield takeLatest(gstType.GET_CUSTOMER_LIST, getCustomerListSaga);
}
