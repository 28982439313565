import { Delete, Get, Post, Put } from "ApiProcess/httpRestServices";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { crmApiBaseUrl, rolesAndPagesApi } from "ApiProcess/httpRestServices/apiManage";
import { convertToLowerCase, leadingAndTrailingSpaceAvoid, nullToString } from "utils/validation";

/**
 * user role status changes
 */
export const userRolestatusChange = async (ele: any = {}) => {
  const { _id = "", name = "", slug = "", status = false } = ele;
  const newObj = {
    status: !status,
    name: leadingAndTrailingSpaceAvoid(name),
    slug: leadingAndTrailingSpaceAvoid(slug)
  };
  return await Put(`${crmApiBaseUrl}${rolesAndPagesApi.statusChange}/${_id}`, newObj, "crmToken");
};

/**
 * delete role
 */
export const deleteRoleApiCall = async (ele: any = {}) => {
  const { _id = "" } = ele;
  return await Delete(`${crmApiBaseUrl}${rolesAndPagesApi.statusChange}/${_id}`, "crmToken");
};

/**
 * pass role id and get permisstion
 */
export const getUserIdBasedPagePermisstionDetails = (ele: any = {}) => {
  const { roleId = 0 } = ele;
  return Get(`${crmApiBaseUrl}${rolesAndPagesApi.getPagePermission}/${roleId}`, "crmToken");
};

/**
 * update page Permission
 */
export const roleUpdatePermisstionDetails = (ele: any = {}) => {
  return Put(`${crmApiBaseUrl}${rolesAndPagesApi.permissionUpdate}`, ele, "crmToken");
};

/**
 * update page Permission
 */
export const roleChangesUpdate = (ele: any = {}) => {
  const { _id = "", create = false, del = false, read = false, write = false } = ele;

  const newObj = {
    del,
    read,
    write,
    create
  };
  return Put(`${crmApiBaseUrl}${rolesAndPagesApi.getPagePermission}/${_id}`, newObj, "crmToken");
};

/**
 * add new role
 */
export const creteNewUserRole = async (ele: any = {}) => {
  const {
    userRoleName = "",
    userStatus = false,
    showEnqType = "",
    showScoreCardType = "",
    allowedEnquires = [],
    custMobileEncode,
    showIncentive
  } = ele;
  const name = leadingAndTrailingSpaceAvoid(userRoleName);
  if (userRoleName) {
    if (nullToString(userRoleName)?.length > 5) {
      const addUnderScore = convertToLowerCase(name).split(" ").join("_").toUpperCase();
      const newObj = {
        name: name,
        showEnqType,
        allowedEnquires,
        showScoreCardType,
        status: userStatus,
        isShowSensInfo: custMobileEncode,
        slug: leadingAndTrailingSpaceAvoid(addUnderScore),
        showIncentive
      };
      return await Post(`${crmApiBaseUrl}${rolesAndPagesApi.statusChange}`, newObj, "crmToken");
    } else {
      failToast("userRoleName is must for 5 digit");
    }
  } else {
    failToast("userRoleName is must for create new role");
  }
};

/**
 * edit new role
 */
export const editApiNewUserRole = async (ele: any = {}) => {
  const {
    _id = "",
    userRoleName = "",
    slug = "",
    userStatus = false,
    showEnqType = "",
    showScoreCardType = "",
    allowedEnquires = [],
    custMobileEncode = "",
    showIncentive
  } = ele;
  const newObj = {
    showEnqType,
    allowedEnquires,
    showScoreCardType,
    status: userStatus,
    isShowSensInfo: custMobileEncode,
    slug: leadingAndTrailingSpaceAvoid(slug),
    name: leadingAndTrailingSpaceAvoid(userRoleName),
    showIncentive
  };
  return await Put(`${crmApiBaseUrl}${rolesAndPagesApi.statusChange}/${_id}`, newObj, "crmToken");
};
