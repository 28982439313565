import React from "react";
import { ameyoProps } from "./types";
import { ameyoCall } from "./CommonAmoyo";
import { useSelector } from "react-redux";
import { Decrypt } from "utils/secureFile";
import { nullToObject } from "utils/validation";
import AmeyoPopup from "./AmeyoPopup";

const AmoyoCallButton = (props: ameyoProps) => {
  const { id = "", phone = "", campaignId = "", setPopupOpen = () => {} } = props;

  const userLoginDetails = Decrypt("userLoginData");
  const { data: { empId = "" } = {} } = nullToObject(userLoginDetails);
  const globalSate = useSelector((state: any) => state);
  const { loginReducer: { getRegisterUserDetailsData = {} } = {} } = nullToObject(globalSate);
  const { data: employeeListData = [] } = getRegisterUserDetailsData;
  /**
   * handle user call
   */
  const handleCall = (item: any = {}) => {
    // handle user call
    ameyoCall({ phone: phone, id: id, campaignId: campaignId, ...item });
    setPopupOpen(false);
  };

  return (
    <AmeyoPopup
      userId={empId}
      handleCall={handleCall}
      closePopup={(e: any) => setPopupOpen(e)}
      employeeListData={employeeListData}
    />
  );
};

export default AmoyoCallButton;
