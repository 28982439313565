import React from "react";
import { nullToObject } from "utils/validation";
import { ArrowNarrowRightIcon } from "@heroicons/react/solid";
import { ArrowNarrowLeftIcon } from "@heroicons/react/outline";

const CallTypeBody = (props: any = {}) => {
  const { callType = null } = nullToObject(props);

  return (
    <React.Fragment>
      {"" + callType === "" + true && (
        <div className="ml-1 flex items-center">
          <i title="In Bound">
            <ArrowNarrowLeftIcon className="w-5 h-5 text-green-600 cursor-pointer hover:text-green-500" />
          </i>
        </div>
      )}

      {"" + callType === "" + false && (
        <div className="ml-1 flex items-center">
          <i title="Out Bound">
            <ArrowNarrowRightIcon className="w-5 h-5 text-red-600 cursor-pointer hover:text-red-500" />
          </i>
        </div>
      )}
    </React.Fragment>
  );
};

export default CallTypeBody;
