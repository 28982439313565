import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { numOnlyAllow } from "utils/validation";
import { inputStylePopup } from "utils/ClassContent";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import SourceBasicInfo from "./BasicIformation";
import CommonInput from "Component/Form/CommonInput";
import SalesReturnGetProblem from "./SalesReturnGetProblem";

const SourseSubCategory = (props: any) => {
  const {
    setShow = true,
    editView = false,
    globalStore = {},
    viewModeData = {},
    enableViewMode = false,
    srSubCategoryState = {}, //sub category data
    passRefunData = () => {},
    passImeiNumber = () => {},
    passDoaDetails = () => {},
    replacePassdata = () => {},
    passDataGenralData = () => {},
    passSalesReturnData = () => {}
  } = props || {};

  const { imei = "" } = viewModeData;
  const [imeiNumber, setImeiNumber] = useState("");
  const [genralInfo, setGenralInfo] = useState({});
  const [itemName, setItemName] = useState("");

  const onChange = (event: any = {}) => {
    const { value = "" } = _get(event, "target", {});
    if (("" + value)?.length >= 7) {
      // imeiNumApiCall(value);//imei number call
    }
    setImeiNumber(value);
    passImeiNumber({ imeiNumber: value }, true);
  };

  const passDataGenralDatas = (data = {}) => {
    setGenralInfo(data);
    passDataGenralData(data);
  };

  const passItemGrpName = (value = "") => {
    setItemName(value);
  };

  useEffect(() => {
    imei && onChange({ target: { value: imei } });
  }, [imei]);

  return (
    <React.Fragment>
      <CommonInput
        readOnly={true}
        setShow={setShow}
        inputError={false}
        labelText={"IMEI"}
        value={imeiNumber}
        onChange={onChange}
        name={"imeiNumber"}
        inputFieldClass="h-9"
        placeholder={"IMEI Number"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        maxLength={staticData.imeilength}
        onKeyPress={numOnlyAllow}
      />

      <SourceBasicInfo
        editView={editView}
        imeiNumber={imeiNumber}
        viewModeData={viewModeData}
        enableViewMode={enableViewMode}
        passItemGrpName={passItemGrpName}
        passDataGenralData={passDataGenralDatas}
      />

      <SalesReturnGetProblem
        itemName={itemName}
        editView={editView}
        genralInfo={genralInfo}
        globalStore={globalStore}
        viewModeData={viewModeData}
        passRefunData={passRefunData}
        passDoaDetails={passDoaDetails}
        enableViewMode={enableViewMode}
        replacePassdata={replacePassdata}
        srSubCategoryState={srSubCategoryState}
        passSalesReturnData={passSalesReturnData}
      />
    </React.Fragment>
  );
};
export default SourseSubCategory;
