import { StarIcon } from "@heroicons/react/solid";
import { ImgAvatarFemale1 } from "assets/images";

export type AuditFormChildOneProps = {
  kudos?: any;
  employeeDetail?: any;
};

const AuditFormChildOne = (props: AuditFormChildOneProps = {}) => {
  const { kudos = false, employeeDetail = {} } = props;
  return (
    <div className="lg:w-2/4 xl:w-2/6">
      <div className="bg-gray-200 flex flex-wrap items-center  justify-center  ">
        <div className="container bg-white  shadow-lg    transform   duration-200 easy-in-out">
          <div className="flex justify-center px-5  -mt-12">
            <div className="inline-block">
              <img className="h-32 w-32 bg-white p-2 rounded-full   " src={ImgAvatarFemale1} alt="" />
            </div>
          </div>
          <div className=" ">
            {kudos ? (
              <StarIcon
                id="kudoStar"
                style={{ filter: "url(#shadow)" }}
                className={`w-20 h-20 text-yellow-400 absolute left-6`}
              />
            ) : null}
            <svg height="0" width="0">
              <filter id="shadow" colorInterpolationFilters="sRGB">
                <feDropShadow dx="0" dy="6" stdDeviation="4" floodOpacity="0.5" floodColor="#8f6700" />
              </filter>
            </svg>
            <div className="text-center px-14 text-gray-800">
              <h2 className=" text-3xl font-bold ">
                <span className="inline-block">{employeeDetail?.name}</span>
              </h2>
              <span className="text-gray-400 mt-2">{employeeDetail?.email}</span>
              <p className="w-full max-w-xs mx-auto flex items-center text-sm text-right">
                <strong className="w-32 flex-shrink-0 text-gray-500 mr-2">Group : </strong>{" "}
                <span className=""> Poorvika</span>
              </p>
              <p className="w-full max-w-xs mx-auto flex items-center text-sm text-right">
                <strong className="w-32 flex-shrink-0 text-gray-500 mr-2">Team :</strong>
                <span className="">Customer Support Team</span>
              </p>
              <p className="w-full max-w-xs mx-auto  flex items-center text-sm text-right">
                <strong className="w-32 flex-shrink-0 text-gray-500 mr-2">Supervisor : </strong>{" "}
                <span className="overflow-hidden overflow-ellipsis">Nithya R</span>
              </p>
            </div>
            <hr className="mt-6" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditFormChildOne;
