import React from "react";
import CommonCheck from "Component/Form/CommonCheck";
import { convertToLowerCase, convertToUpperCase } from "utils/validation";

type RegisterPriceModeProps = {
  priceMode: any;
  roleId: number;
  onPriceModeChange: (e?: any) => void;
};

const RegisterPriceMode = (props: RegisterPriceModeProps) => {
  const { priceMode = [], onPriceModeChange = () => {}, roleId } = props;

  return (
    <div className="form-group relative mb-2 sm:mb-2 mt-2 sm:px-4">
      <label htmlFor="" className="block text-xs input-label mb-1 font-bold">
        {"Price Mode"}
      </label>
      <div className="flex">
        {Object.keys(priceMode).map((type: any, i: any) => (
          <CommonCheck
            id={type}
            name={type}
            onChange={onPriceModeChange}
            labelText={convertToUpperCase(type)}
            key={convertToLowerCase(`check` + i)}
            disabled={type === "nlc" && roleId !== 1}
            checked={priceMode[type] === 1 ? true : false}
            parentClass={"form-check p-1.5 pl-0 w-28"}
          />
        ))}
      </div>
    </div>
  );
};

export default RegisterPriceMode;
