import React from "react";
import { Link } from "react-router-dom";
import { pageNumber, tabBasedAccess } from "helperRole";
import { GlobeIcon } from "@heroicons/react/outline";
import { SideBarIconClass, MenuName, MenuIconSm, ListClass } from "utils/ClassContent";

const MisDashboardMenu = (props: any = {}) => {
  const { pathname = "" } = props;

  return (
    tabBasedAccess(pageNumber.MIS_DASHBOARD) && ( //155
      <li
        className={`${ListClass} s
            ${pathname === "/mis-dashboard" ? " active-menu" : ""}`}
      >
        <Link to={"/mis-dashboard"} className={`${SideBarIconClass} flex-auto`}>
          <GlobeIcon className={MenuIconSm} />
          <span className={MenuName}>MIS Dashboard</span>
        </Link>
      </li>
    )
  );
};
export default MisDashboardMenu;
