import React from "react";
import CheckQC from "Pages/ServiceCalls/CheckQC";

const Home = React.lazy(() => import("Pages/DashBoard"));
const Setting = React.lazy(() => import("Pages/Setting"));
const NewCall = React.lazy(() => import("Pages/NewCall"));
const NotifyMe = React.lazy(() => import("Pages/NotifyMe"));
const Downloads = React.lazy(() => import("Pages/Downloads"));
const AdvocateList = React.lazy(() => import("Pages/Advocate"));
const SalesOrder = React.lazy(() => import("Pages/SalesOrder"));
const JustDial = React.lazy(() => import("Pages/JustDialTable"));
const PreRegister = React.lazy(() => import("Pages/PreRegister"));
const UserProfile = React.lazy(() => import("Pages/UserProfile"));
const MisDashboard = React.lazy(() => import("Pages/MisDashboard"));
const CampaignForm = React.lazy(() => import("Pages/CampaignForm"));
const Transactions = React.lazy(() => import("Pages/Transactions"));
const EmployeeList = React.lazy(() => import("Pages/EmployeeList"));
const OnlineOrders = React.lazy(() => import("Pages/OnlineOrders"));
const PageNotFound = React.lazy(() => import("Pages/PageNotFound"));
const AuditReports = React.lazy(() => import("Pages/AuditReports"));
const WishList = React.lazy(() => import("Pages/AllOrders/WishList"));
const PSOrders = React.lazy(() => import("Pages/AllOrders/PSOrders"));
const CartList = React.lazy(() => import("Pages/AllOrders/CartList"));
const ComplainList = React.lazy(() => import("Pages/Complains/List"));
const CustomerService = React.lazy(() => import("Pages/ServiceCalls"));
const SaveLater = React.lazy(() => import("Pages/AllOrders/SaveLater"));
const ServiceCenters = React.lazy(() => import("Pages/ServiceCenters"));
const ContactsDetails = React.lazy(() => import("Pages/ContactsDetails"));
const FeedbackContest = React.lazy(() => import("Pages/FeedbackContest"));
const NonDealingTable = React.lazy(() => import("Pages/NonDealingTable"));
const FollowUp = React.lazy(() => import("Pages/Complains/List/Followup"));
const LoginPanel = React.lazy(() => import("Pages/PublicComponent/Login"));
const AllOrderList = React.lazy(() => import("Pages/AllOrders/OrdersList"));
const UserLoggedInList = React.lazy(() => import("Pages/UserLoggedInList"));
const CustomerCallsList = React.lazy(() => import("Pages/Customers/CallsList"));
const ProductQRGenerator = React.lazy(() => import("Pages/ProductQRGenerator"));
const CustomerFeedback = React.lazy(() => import("./../CustomerFeedback/index"));
const QRLoaderTable = React.lazy(() => import("Pages/ProductQRGenerator/QRTable"));
const Evaluations = React.lazy(() => import("Pages/QualityScorecard/Evaluations"));
const CampaignTransactions = React.lazy(() => import("Pages/CampaignTransactions"));
const AllEnquiryBasedEmpId = React.lazy(() => import("Pages/AllEnquiryBasedEmpId"));
const AnonymCartList = React.lazy(() => import("Pages/AllOrders/AnonymousCartList"));
const ManageAuditors = React.lazy(() => import("Pages/AuditReports/ManageAuditors"));
const AuditHistory = React.lazy(() => import("Pages/QualityScorecard/AuditHistory"));
const KudosByStaff = React.lazy(() => import("Pages/QualityScorecard/KudosByStaff"));
const MyAuditReports = React.lazy(() => import("Pages/AuditReports/MyAuditReports"));
const CreatePassword = React.lazy(() => import("Pages/PublicComponent/ChangePassword"));
const ForgetPassword = React.lazy(() => import("Pages/PublicComponent/ForgetPassword"));
const SingleSignOn = React.lazy(() => import("Pages/PublicComponent/Login/SingleSignOn"));
const GeneralReports = React.lazy(() => import("Pages/QualityScorecard/GeneralReports"));
const SearchKeywordTerms = React.lazy(() => import("Pages/AllOrders/SearchKeywordTerms"));
const ScorecardEditor = React.lazy(() => import("Pages/QualityScorecard/ScorecardEditor"));
const RecentProductSearch = React.lazy(() => import("Pages/AllOrders/RecentProductSearch"));
const QuestionAnalysis = React.lazy(() => import("Pages/QualityScorecard/QuestionAnalysis"));
const GlobalAuditReports = React.lazy(() => import("Pages/AuditReports/GlobalAuditReports"));
const TeamLeadsAuditReports = React.lazy(() => import("Pages/AuditReports/TeamLeadsAuditReports"));
const EvaluationsPerPeriod = React.lazy(() => import("Pages/QualityScorecard/EvaluationsPerPeriod"));
const SupervisorAuditReports = React.lazy(() => import("Pages/AuditReports/SupervisorAuditReports"));
const AgentResultAcceptance = React.lazy(() => import("Pages/QualityScorecard/AgentResultAcceptance"));
const SalesReturnTable = React.lazy(() => import("Pages/NewCall/EnquiryFor/SalesReturnsForm/SalesReturnTable"));
const SalesTable = React.lazy(() => import("Pages/NewCall/EnquiryFor/SalesEnquiry/SalesProductInformation/SalesTable"));

export {
  Home,
  NewCall,
  CheckQC,
  NotifyMe,
  WishList,
  FollowUp,
  PSOrders,
  CartList,
  JustDial,
  Downloads,
  SaveLater,
  Setting,
  LoginPanel,
  SalesOrder,
  SalesTable,
  UserProfile,
  Evaluations,
  AdvocateList,
  MisDashboard,
  CampaignForm,
  Transactions,
  EmployeeList,
  OnlineOrders,
  PageNotFound,
  SingleSignOn,
  AuditReports,
  ComplainList,
  AuditHistory,
  KudosByStaff,
  AllOrderList,
  ForgetPassword,
  MyAuditReports,
  ManageAuditors,
  AnonymCartList,
  GeneralReports,
  ServiceCenters,
  CreatePassword,
  CustomerService,
  PreRegister,
  ContactsDetails,
  QRLoaderTable,
  ScorecardEditor,
  FeedbackContest,
  NonDealingTable,
  QuestionAnalysis,
  CustomerFeedback,
  SalesReturnTable,
  UserLoggedInList,
  CustomerCallsList,
  SearchKeywordTerms,
  GlobalAuditReports,
  ProductQRGenerator,
  RecentProductSearch,
  CampaignTransactions,
  AllEnquiryBasedEmpId,
  EvaluationsPerPeriod,
  AgentResultAcceptance,
  TeamLeadsAuditReports,
  SupervisorAuditReports
};
