import store from "store";
import { Post, Get, Put } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { crmApiBaseUrl, serviceCalls, encodeGetParams } from "../../ApiProcess/httpRestServices/apiManage";

/**
 * Update service call details
 * @param  {object} obj service call details
 */
export const updateCustomerInfoService = (datas: any = {}) => {
  const { data = {} } = datas;
  const {
    _id = "",
    billNo = "",
    status = "",
    problem = [],
    billDate = "",
    itemName = "",
    itemCode = "",
    solution = {},
    deviceAge = "",
    enquiryFor = "",
    branchName = "",
    subCategory = "",
    problemDesc = "",
    imeiMobileNo = "",
    followUpDate = "",
    currentStatus = "",
    docFiles = "",
    itemBrandName = "",
    invoiceAmount = "",
    itemGroupName = "",
    typeOfService = "",
    problemCategory = [],
    imeiCustomerName = "",
    remarks = ""
  } = data;

  const formData = new FormData();
  if (docFiles.length > 0) {
    // Append image files
    for (const file of docFiles) {
      formData.append("document", file.file); // appending every file to formdata
    }
  }

  const userData = {
    billNo: billNo,
    status: status,
    remarks: remarks,
    problem: problem,
    solution: solution,
    itemCode: itemCode,
    itemName: itemName,
    billDate: billDate,
    deviceAge: deviceAge,
    branchName: branchName,
    enquiryFor: enquiryFor,
    subCategory: subCategory,
    problemDesc: problemDesc,
    followUpDate: followUpDate,
    imeiMobileNo: imeiMobileNo,
    itemBrandName: itemBrandName,
    itemGroupName: itemGroupName,
    currentStatus: currentStatus,
    invoiceAmount: invoiceAmount,
    typeOfService: typeOfService,
    problemCategory: problemCategory,
    imeiCustomerName: imeiCustomerName,
    isQuery: "t"
  };

  const queryString = encodeGetParams(userData);
  return Put(`${crmApiBaseUrl}${serviceCalls.updateServices}/${_id}?${queryString}`, formData, "crmToken");
};

/**
 * Update service call details
 * @param  {object} obj service call details
 */
export const updateSupportStatus = (datas: any = {}) => {
  const { data = {} } = datas;
  const {
    _id = "",
    status = "",
    callType = "",
    docFiles = "",
    enquiryFor = "",
    subCategory = "",
    followUpDate = "",
    currentStatus = "",
    remarks = ""
  } = data;
  const formData = new FormData();

  if (docFiles.length > 0) {
    // Append image files
    for (const file of docFiles) {
      formData.append("document", file.file); // appending every file to formdata
    }
  }
  const userData = {
    status: status,
    callType: callType,
    enquiryFor: enquiryFor,
    statusRemarks: remarks,
    subCategory: subCategory,
    followUpDate: followUpDate,
    currentStatus: currentStatus,
    isQuery: "t"
  };
  const queryString = encodeGetParams(userData);
  return Put(`${crmApiBaseUrl}${serviceCalls.updateServices}/${_id}?${queryString}`, formData, "crmToken");
};

const problemHandleCommon = (arrayData = []) => {
  return arrayData
    .map((ele: any) => {
      if (ele.isSelect === true) {
        return ele.optionData;
      }
      return undefined;
    })
    .filter((ele: any) => ele !== undefined);
};

/**
 * Update service call details
 * @param  {object} obj service call details;
 */
export const updateServiceDetails = (datas: any = {}) => {
  const { data = {} } = datas;
  const {
    _id = "",
    status = "",
    jobSheet = {},
    callType = "",
    hoDetails = {},
    fromHo = false,
    enquiryFor = "",
    subCategory = "",
    statusRemarks = "",
    currentStatus = "",
    serviceCenter = {},
    storeDelivery = {}
  } = data;
  const formData = new FormData();
  const userData = {
    status: status,
    callType: callType,
    enquiryFor: enquiryFor,
    subCategory: subCategory,
    statusRemarks: statusRemarks,
    currentStatus: currentStatus,
    fromHo: fromHo ? fromHo : false,
    jobSheet: jobSheet === null ? {} : jobSheet,
    hoDetails: hoDetails === null ? {} : hoDetails,
    storeDelivery: storeDelivery === null ? {} : storeDelivery,
    serviceCenter: serviceCenter === null ? {} : serviceCenter,
    isQuery: "t"
  };
  const queryString = encodeGetParams(userData);
  return Put(`${crmApiBaseUrl}${serviceCalls.updateServices}/${_id}?${queryString}`, formData, "crmToken");
};

/**
 * Save the customer details to server
 * @param obj customer details
 * @returns response from server
 */
export const saveServiceDetails = (obj: any = {}) => {
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;

  const {
    basicInfoReducer: { basicInfoUserinfoData: { customerId = "", name = "", mobile = "", email = "" } = {} } = {}
  } = storeData;
  const {
    data: {
      serviceSubCategory = "",
      enquiryFor = "",
      imeiInfo = "",
      callType = true,
      mailId = "",
      source = "",
      basicInfo = {},
      problemInfo = {},
      imageInfo = [],
      solutionInfo = {},
      statusInfo = {},
      informVia = {}
    } = {}
  } = obj;
  const {
    cusName = "",
    mobileNumber = "",
    itemCode = "",
    billDate = "",
    model = "",
    billNo = "",
    branchName = "",
    product = "",
    brand = "",
    invoiceAmount = "",
    ageOfDevice = ""
  } = basicInfo; // get customer basic info

  const { problem = [], problemCategory = [], problemDescription = "", typeOfService = "" } = problemInfo; // get problem info

  const { status = "", currentStatus = "", remarks = "" } = statusInfo;

  const problemSelected = problemHandleCommon(problem);

  const subProblemSelected = problemHandleCommon(problemCategory);

  const formData = new FormData();

  if (imageInfo.length > 0) {
    // Append image files
    for (const file of imageInfo) {
      formData.append("document", file.file); // appending every file to formdata
    }
  }
  let customerServiceData = {
    imei: imeiInfo,
    status: status,
    billNo: billNo,
    itemName: model,
    remarks: remarks,
    callType: callType,
    customerName: name,
    itemCode: itemCode,
    billDate: billDate,
    itemBrandName: brand,
    informVia: informVia,
    branchName: branchName,
    itemGroupName: product,
    enquiryFor: enquiryFor,
    deviceAge: ageOfDevice,
    solution: solutionInfo,
    problem: problemSelected,
    imeiMobileNo: mobileNumber,
    imeiCustomerName: cusName,
    typeOfService: typeOfService,
    invoiceAmount: invoiceAmount,
    currentStatus: currentStatus,
    problemDesc: problemDescription,
    subCategory: serviceSubCategory,
    problemCategory: subProblemSelected,
    email: email === null ? "" : email,
    mobileNo: mobile === null ? "" : mobile,
    leadDetails: justDialLeadDetails, //justDial lead create
    isQuery: "t",
    cusId: customerId,
    ...(serviceSubCategory === "General Information" && solutionInfo?.solution === "Other Details" && { source })
  };
  if (mailId) {
    customerServiceData = { ...customerServiceData, ...{ mailId: mailId } };
  }
  const queryString = encodeGetParams(customerServiceData);
  return Post(`${crmApiBaseUrl}${serviceCalls.saveServices}?${queryString}`, formData, "crmToken");
};

/**
 * call attachment export api
 * @param obj
 */
export const sendCustomerServiceEmailService = (obj: any = {}) => {
  const {
    data: {
      cc = "",
      bcc = "",
      status = [],
      search = "",
      toEmail = "",
      subject = "",
      content = "",
      endDate = "",
      startDate = "",
      subCategory = "",
      currentStatus = [],
      createdByState = [],
      excelColumnFilter = {}
    } = {}
  } = obj || {};

  const newObj = {
    cc,
    bcc,
    search,
    status,
    toEmail,
    subject,
    content,
    subCategory,
    currentStatus,
    createdByState,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${serviceCalls.attachEmail}?${queryString}`, excelColumnFilter, "crmToken");
};

/**
 * call attachment export api
 * @param obj
 */
export const sendGeneralInfoMailService = (obj: any = {}) => {
  const { data: { cc = "", bcc = "", toEmail = "", subject = "", content = "", fromEmail = "" } = {} } = obj || {};

  const newObj = {
    cc,
    bcc,
    toEmail,
    subject,
    content,
    fromEmail
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${serviceCalls.generalInfoMail}?${queryString}`, {}, "crmToken");
};

/**
 * Create Delivery Challan
 * @param obj delivery challan details
 * @returns dc saved as response from server
 */
export const createDeliveryChallanService = (data: any = {}) => {
  const { data: { html = "", idList = [], fileName = "", branchCode = "" } = {} } = data || {};
  const dcData = {
    html: html,
    idList: idList,
    fileName: fileName,
    branchCode: branchCode
  };
  return Post(`${crmApiBaseUrl}${serviceCalls.createDC}`, dcData, "crmToken"); //
};

/**
 * @param  {object} obj;
 * Whatsapp message send
 */
export const sendWhatsappMessageService = async (data: any) => {
  const { data: { mobile = "", imgUrl = "", customerName = "", addressDetails = "" } = {} } = data || {};
  const sendData = {
    imgUrl: imgUrl,
    contactList: [mobile],
    customerName: customerName,
    addressDetails: addressDetails
  };
  return await Post(`${crmApiBaseUrl}${serviceCalls.sendWhatsappGeneralMsg}`, sendData, "crmToken");
};
