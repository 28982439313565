import React, { useEffect, useState } from "react";
import { nullToObject } from "utils/validation";
import { MockLanguageData } from "Component/MockData";
import { _get } from "utils/lodashConst";
import CommonMultiChoose from "Component/Form/CommonMultiChoose";

type chooseLangageTypes = {
  id: number;
};

type basicInfoLanguageType = {
  mobile?: string;
  passLangData?: any;
  globelStoreData?: any;
  basicInfoUserInformation?: any; // user detail fetch
  basicInfoUserinfoData?: any;
};

const BasicInfoLang = (props: basicInfoLanguageType) => {
  const { passLangData = () => {}, basicInfoUserInformation = null } = nullToObject(props);

  const [seletedLanuage, setSeletedLanuage] = useState(MockLanguageData);

  // language seleted by customer
  const chooseLangage = (data: chooseLangageTypes) => {
    const newArray = [...seletedLanuage];
    const findIndex = newArray.findIndex((ele: any) => _get(ele, "id", "") === _get(data, "id", ""));
    newArray[findIndex] = {
      ...newArray[findIndex],
      isSelect: !newArray[findIndex]?.isSelect //change seleted value
    };
    setSeletedLanuage(newArray);
    passLangData(newArray); //pass orignal value to index
  };

  useEffect(() => {
    if (basicInfoUserInformation !== null) {
      const { language = [] } = basicInfoUserInformation;
      //filter selected data
      const result1 = MockLanguageData.filter((o1: any) => {
        return language.some((o2: any) => {
          return o1.optionData === o2;
        });
      });

      //filter Unselected data
      const result2 = MockLanguageData.filter((o1: any) => {
        return !language.some((o2: any) => {
          return o1.optionData === o2;
        });
      });

      //Has change flag
      const newArr = result1.map((ele: any) => {
        return {
          ...ele,
          isSelect: true
        };
      });
      const finalArra = [...result2, ...newArr]; //concat two arrar
      setSeletedLanuage(finalArra);
    } else {
      setSeletedLanuage(MockLanguageData);
    }
  }, [basicInfoUserInformation]);
  return (
    <CommonMultiChoose
      mustField={true}
      inputError={false}
      enableSearch={false}
      labelText={"Language"}
      inputFieldClass="h-9"
      inputClass="flex  w-full"
      labelTextClass={"font-bold"}
      chooseLangage={chooseLangage} //click fu
      seletedLanuage={seletedLanuage} //click fu
      deselectLanguage={chooseLangage} //click fun
      parentClass={"w-full mt-1 mb-1"}
    />
  );
};

export default BasicInfoLang;
