import React from "react";
import { internetStatusDecrypt } from "utils/validation";
import toastService from "./index";

export const infoToast = (successMsg = "", informToastId = "") => {
  toastService.infoToast(
    <React.Fragment className="customToast">
      <i className="info" />
      <p>{successMsg}</p>
    </React.Fragment>,
    informToastId
  );
};

export const failToast = (failMsg = "", failToastId = "") => {
  toastService.errorToast(
    <div className="customToast">
      <i className="info" />
      <p>{failMsg}</p>
    </div>,
    failToastId
  );
};

export const copyToast = (cpyMsg, toastId = "") => {
  toastService.copyToast(
    <div className="customToast">
      <i className="info" />
      <p>{cpyMsg}</p>
    </div>,
    toastId
  );
};

export const successToast = (Msg, successtoastId = "") => {
  toastService.successToast(
    <div className="customToast">
      <i className="info" />
      <p>{Msg ? Msg : ""}</p>
    </div>,
    successtoastId
  );
};

export const serverNotRespond = () => {
  toastService.infoToast(
    <div className="customToast">
      <i className="info" />
      <p>
        {internetStatusDecrypt()
          ? "The server is not responding. Please try again later"
          : "Please Check your ineternet connection"}
      </p>
    </div>
  );
};
