import React from "react";
import { _get } from "utils/lodashConst";
import { convertToLowerCase, firstLetterUpperCase, nullToObject } from "utils/validation";

const ProductStorage = (props: any = {}) => {
  const {
    fromPage = "",
    productDetails = {},
    getProductgetProductDetailsByCategoryCall = () => {}
  } = nullToObject(props);

  const variantColor = _get(productDetails, "model.variant_attr_scheme", []);

  const findstorage = variantColor
    .map((ele: any) => {
      if (ele.code !== "color") {
        return {
          ...ele,
          values: ele.values.filter(
            (eleData: any) => _get(eleData, "code", "") === _get(productDetails, "variant.attr.storage", "")
          )
        };
      }
      return undefined;
    })
    .filter((ele: any) => ele);
  const variant_attr_scheme = fromPage === "viewOnly" ? findstorage : variantColor;

  /**
   * pass element
   * destcute color and storage
   * find exact index value and pass slug
   * @param slug
   */
  const getProductgetProductDetailsByCategoryCalls = (element: any = {}, fromKey = "") => {
    const { code = "" } = element;
    const findObj: any = productDetails.model?.variants?.find((ele: any) => ele.attr[fromKey] === code);
    const newObj = {
      ...findObj,
      item_code: findObj?.erp_item_code || ""
    };
    getProductgetProductDetailsByCategoryCall(newObj);
  };

  const packDetailPrintData = (ele: any = "") => {
    const { code = "" } = ele;
    if (code) {
      return code;
    }
    return "";
  };
  return _get(variant_attr_scheme, "length", 0) > 0 ? (
    <>
      {variant_attr_scheme.map((ele: any, i: number) => {
        return (
          <React.Fragment key={convertToLowerCase(i + "variant_attr_scheme-jdm-jdfd")}>
            {convertToLowerCase(ele.code) !== "color" ? (
              <>
                <span className=" mb-2 text-sm">{firstLetterUpperCase(ele.code)}</span>
                <div className="flex flex-wrap  mb-2 ">
                  {ele.values.map((eles: any, index: number) => {
                    return (
                      <button
                        type="button"
                        key={convertToLowerCase(index + "values-displayStorage-efd")}
                        onClick={() => getProductgetProductDetailsByCategoryCalls(eles, ele.code)}
                        className={`border border-yellow-500
                                                active:bg-indigo-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none
                                                mr-2 mb-2 mt-1 ease-linear transition-all duration-150
                                                ${
                                                  convertToLowerCase(
                                                    _get(productDetails, "variant.attr.storage", "")
                                                  ) === convertToLowerCase(_get(eles, "code", ""))
                                                    ? " bg-primary-gradient text-white"
                                                    : "text-yellow-600"
                                                } `}
                      >
                        {packDetailPrintData(eles)}
                      </button>
                    );
                  })}
                </div>
              </>
            ) : null}
          </React.Fragment>
        );
      })}
    </>
  ) : null;
};

export default ProductStorage;
