import React from "react";
import { _get } from "utils/lodashConst";
import { StarIcon } from "@heroicons/react/solid";
import LazyImage from "Component/LazyImage";
import { getPriceValue } from "../../../LeadHelper";

/**
 * similar product and recent enquiry have same component so we have render based on key
 * @param pageFrom from which componet find
 * @param elementData ele
 * @param key from similar product
 * @param key1 from recent enquiry
 */
const renderBasedOnKey = (pageFrom = "", elementData: any = {}, key = "", key1 = "") => {
  const { mrp = [], prices = [] } = elementData;
  const shop = (prices || []).find((price: any) => price?.pl === "SHOP");
  if (pageFrom === "relatedEnquiry") {
    return elementData[key1] || "";
  } else {
    return getPriceValue(shop?.sp) || getPriceValue(mrp);
  }
};

const RelatedEnquiryCard = (props: any = {}) => {
  const { ele = {}, fromPage = "", ParentClass = "", handleChooseProduct = () => {} } = props;

  return (
    <div key={_get(ele, "product_id", "")} tabIndex={0} className="p-2 w-60">
      <div
        onClick={() => handleChooseProduct(ele)}
        className={ParentClass ? ParentClass : "p-1 w-full h-68 cursor-pointer"}
      >
        <div className=" border bg-white h-full shadow-md rounded-lg flex mx-auto w-full">
          <div className="  my-1 w-full cursor-pointer">
            <div className="text-center">
              <LazyImage
                width={144}
                height={144}
                alt={_get(ele, "name", "")}
                src={_get(ele, "image.url", "")}
                className="rounded-t-lg p-2 w-36 h-36 mx-auto"
              />
            </div>
            <div className="px-3 pb-1">
              <span>
                <h3
                  className="h-8 overflow-hidden text-black opacity-80 font-semibold text-xs
                                                            tracking-tight "
                >
                  {_get(ele, "name", "")}
                </h3>
              </span>
              <div className="flex items-center mt-2.5 justify-between">
                <p className="whitespace-nowrap mt-1 text-base font-bold text-blue-600">
                  ₹ {renderBasedOnKey(fromPage, ele, "special_price", "special_formatted")}
                </p>
                {_get(ele, "rating", "") ? (
                  <div className="flex">
                    <StarIcon className="w-5 h-5 text-yellow-300" />
                    <span
                      className={`bg-blue-100 text-blue-800 text-xs font-semibold 
                                                                            px-2.5 py-0.5 rounded  ml-2`}
                    >
                      {_get(ele, "rating", "")}
                    </span>
                  </div>
                ) : null}
              </div>

              {/* price and special price not equal */}
              {_get(ele, "price", 0) !== _get(ele, "special_price", 0) ? (
                <div className="flex text-xs font-medium ">
                  <p className=" line-through mb-2 text-black opacity-40">
                    {renderBasedOnKey(fromPage, ele, "price_format", "price_formatted")}
                  </p>

                  {_get(ele, "discount", 0) > 0 && (
                    <strong className=" ml-1 text-gray-800 opacity-90">({_get(ele, "discount", "")}% OFF)</strong>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default RelatedEnquiryCard;
