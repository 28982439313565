import * as basicInfoType from "../actionTypes/basicInfoType";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { failToast, infoToast } from "Component/ToastServices/ToastDisplay";
import {
  getLocateBasicInfoServices,
  getuserBasicInfoUseingMobileNumServices,
  updateBasicInfoServices,
  getEnquiresByCustomerServices,
  getTransDetailsByCustomerServices,
  getuserBasicInfoUsingEmailServices,
  getuserBasicInfoUsingCusIdServices,
  updateBasicInfoOfEnquiryService
} from "utils/services/basicInfoService";
import { mergeRequestCustomerServices } from "utils/services/customerInfoService";

/**
 * @param  {object} obj
 * updateBasicInformation
 */
function* updateBasicInformation(obj = {}): any {
  try {
    const response = yield call(updateBasicInfoServices, obj);
    const { data: { message = "", data = {}, status = 0 } = {} } = response || {};
    if (status === 201) {
      infoToast(message);
      yield all([
        put({
          type: "BASIC_IFO_UPDATE_LOADER",
          basicInfoUpdateResetData: true,
          basicInfoUpdateLoader: false
        }), //loaderOff
        put({
          type: "MOVE_TO_ENQUIRE_PAGE",
          moveToEnqirePage: true
        }), //loaderOff
        put({
          type: "BASIC_IFO_USER_FIND_DATA_STORE",
          basicInfoUserinfoData: data
        }),
        put({
          type: "BASIC_IFO_USER_FIND_INFORMATION",
          basicInfoUserInformation: data
        })
      ]);
    } else {
      failToast(message);
      yield all([
        put({
          type: "BASIC_IFO_UPDATE_LOADER",
          basicInfoUpdateResetData: false,
          basicInfoUpdateLoader: false
        }), //loaderOff
        put({
          type: "MOVE_TO_ENQUIRE_PAGE",
          moveToEnqirePage: null
        }), //loaderOff
        put({
          type: "BASIC_IFO_USER_FIND_DATA_STORE",
          basicInfoUserinfoData: {}
        }),
        put({
          type: "BASIC_IFO_USER_FIND_INFORMATION",
          basicInfoUserInformation: null
        })
      ]);
    }
  } catch (error) {
    yield all([
      put({
        type: "BASIC_IFO_UPDATE_LOADER",
        basicInfoUpdateResetData: false,
        basicInfoUpdateLoader: false
      }), //loaderOff
      put({
        type: "MOVE_TO_ENQUIRE_PAGE",
        moveToEnqirePage: null
      }), //loaderOff
      put({
        type: "BASIC_IFO_USER_FIND_DATA_STORE",
        basicInfoUserinfoData: {}
      }),
      put({
        type: "BASIC_IFO_USER_FIND_INFORMATION",
        basicInfoUserInformation: null
      })
    ]);
  }
}

/**
 * @param  {object} obj
 * updateBasicInformation
 */
function* updateBasicInformationFromEnquiryPopup(obj = {}): any {
  try {
    const response = yield call(updateBasicInfoServices, obj);
    const { data: { message = "", data = {}, status = 0 } = {} } = response || {};
    if (status === 201) {
      infoToast(message);
      yield all([
        put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateResetData: true, basicInfoUpdateLoader: false }), //loaderOff
        put({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: data })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateResetData: false, basicInfoUpdateLoader: false }), //loaderOff
        put({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: {} })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateResetData: false, basicInfoUpdateLoader: false }), //loaderOff
      put({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: {} })
    ]);
  }
}

/**
 * @param  {object} obj
 * updateBasicInformation
 */
function* updateEnquiryBasicCustomerInformation(obj = {}): any {
  try {
    const response = yield call(updateBasicInfoOfEnquiryService, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      infoToast(message);
      yield all([
        put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateLoader: false }), //loaderOff
        put({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateLoader: false }), //loaderOff
        put({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: false })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateLoader: false }), //loaderOff
      put({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: false })
    ]);
  }
}

/**
 * @param  {} obj={object}
 * @returns anyode data
 * pinCode information
 */
function* getAreaLocateBasedPincode(obj = {}): any {
  try {
    const response = yield call(getLocateBasicInfoServices, obj);
    const { status = 0, data: { data = [] } = {} } = response;
    if (status === 200) {
      const picodeModify = data.map((ele: any) => {
        return {
          ...ele,
          optionData: ele.area
        };
      });
      yield all([
        put({ type: "BASIC_IFO_PINCODE_LOADER", userInfoPinCodeLoader: false }), //loaderOff
        put({
          type: "BASIC_IFO_PINCODE_LOADER_DATA",
          userInfoPinData: picodeModify
        }) //pinCode data
      ]);
    } else {
      yield all([
        put({ type: "BASIC_IFO_PINCODE_LOADER_DATA", userInfoPinData: [] }), //pinCode data
        put({ type: "BASIC_IFO_PINCODE_LOADER", userInfoPinCodeLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "BASIC_IFO_PINCODE_LOADER_DATA", userInfoPinData: [] }), //pinCode data
      put({ type: "BASIC_IFO_PINCODE_LOADER", userInfoPinCodeLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get user basic info
 */
function* getUserBasicDetailsUsingMobileNumber(obj = {}): any {
  try {
    const response = yield call(getuserBasicInfoUseingMobileNumServices, obj);
    const { data: { data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({
          type: "BASIC_IFO_USER_FIND_DATA_STORE",
          basicInfoUserinfoData: data
        }),
        put({
          type: "BASIC_IFO_USER_FIND_INFORMATION",
          basicInfoUserInformation: data
        }), //user inform
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_USER_FIND_DATA_STORE",
          basicInfoUserinfoData: {}
        }),
        put({
          type: "BASIC_IFO_USER_FIND_INFORMATION",
          basicInfoUserInformation: null
        }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({
        type: "BASIC_IFO_USER_FIND_DATA_STORE",
        basicInfoUserinfoData: {}
      }),
      put({
        type: "BASIC_IFO_USER_FIND_INFORMATION",
        basicInfoUserInformation: null
      }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get user basic info
 */
function* getUserBasicDetailsUsingEmail(obj = {}): any {
  try {
    const response = yield call(getuserBasicInfoUsingEmailServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({
          type: "BASIC_IFO_USER_FIND_DATA_EMAIL",
          basicInfoUserinfoEmail: data
        }),
        // put({ type: "BASIC_IFO_USER_FIND_INFORMATION", basicInfoUserInformation: data }),//user inform
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_USER_FIND_DATA_EMAIL",
          basicInfoUserinfoEmail: null
        }),
        // put({ type: "BASIC_IFO_USER_FIND_INFORMATION", basicInfoUserInformation: null }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
      failToast(message);
    }
  } catch (error) {
    yield all([
      put({
        type: "BASIC_IFO_USER_FIND_DATA_EMAIL",
        basicInfoUserinfoEmail: null
      }),
      // put({ type: "BASIC_IFO_USER_FIND_INFORMATION", basicInfoUserInformation: null }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
    failToast("Error in getting customer details");
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get user basic info
 */
function* getUserBasicDetailsUsingCustomerId(obj = {}): any {
  try {
    const response = yield call(getuserBasicInfoUsingCusIdServices, obj);
    const { data: { data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({
          type: "BASIC_IFO_USER_FIND_DATA_STORE",
          basicInfoUserinfoData: data
        }),
        put({
          type: "BASIC_IFO_USER_FIND_INFORMATION",
          basicInfoUserInformation: data
        }), //user inform
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_USER_FIND_DATA_STORE",
          basicInfoUserinfoData: {}
        }),
        put({
          type: "BASIC_IFO_USER_FIND_INFORMATION",
          basicInfoUserInformation: null
        }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({
        type: "BASIC_IFO_USER_FIND_DATA_STORE",
        basicInfoUserinfoData: {}
      }),
      put({
        type: "BASIC_IFO_USER_FIND_INFORMATION",
        basicInfoUserInformation: null
      }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * get user basic info
 */
function* getEnquiryBasicInfoUsingCustomerId(obj = {}): any {
  try {
    const response = yield call(getuserBasicInfoUsingCusIdServices, obj);
    const { data: { data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateLoader: false }), //loaderOff
        put({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: data })
      ]);
    } else {
      yield all([
        put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateLoader: false }), //loaderOff
        put({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: {} })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "BASIC_IFO_UPDATE_LOADER", basicInfoUpdateLoader: false }), //loaderOff
      put({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: {} })
    ]);
  }
}

/**
 * @param {Object} obj={}
 * @returns any
 * get enquires by customer
 */
function* getEnquiriesDetailsByCustomer(obj: Object): any {
  try {
    const response = yield call(getEnquiresByCustomerServices, obj);
    const { data: { data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({
          type: "BASIC_IFO_ENQUIRES_BY_CUSTOMER_DATA",
          customerEnquiries: data
        }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_ENQUIRES_BY_CUSTOMER_DATA",
          customerEnquiries: []
        }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({
        type: "BASIC_IFO_ENQUIRES_BY_CUSTOMER_DATA",
        customerEnquiries: []
      }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
  }
}

/**
 * @param {Object} obj={}
 * @returns any
 * get transactions by customer
 */
function* getTransDetailsByCustomer(obj: Object): any {
  try {
    const response = yield call(getTransDetailsByCustomerServices, obj);
    const { status = 0, data: { Data = [] } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({
          type: "BASIC_IFO_TRANS_BY_CUSTOMER_DATA",
          customerTransactions: Data
        }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false })
      ]);
    } else {
      yield all([
        put({
          type: "BASIC_IFO_TRANS_BY_CUSTOMER_DATA",
          customerTransactions: []
        }),
        put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false })
      ]);
    }
  } catch (error) {
    yield all([
      put({
        type: "BASIC_IFO_TRANS_BY_CUSTOMER_DATA",
        customerTransactions: []
      }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false })
    ]);
  }
}

/**
 * @param  {Object} obj={}
 * @returns any
 * merge request customer
 */
function* mergeRequestCustomer(obj = {}): any {
  try {
    const response = yield call(mergeRequestCustomerServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 201) {
      infoToast(message);
      yield all([
        put({ type: "MERGE_REQUEST_NEW_CUSTOMER_DATA", mergeRequestData: data }),
        put({
          type: "BASIC_IFO_USER_FIND_LOADER",
          basicInfoUserFind: false
        }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "MERGE_REQUEST_NEW_CUSTOMER_DATA", mergeRequestData: {} }),
        put({
          type: "BASIC_IFO_USER_FIND_LOADER",
          basicInfoUserFind: false
        }) //loaderOff
      ]);
      failToast(message);
    }
  } catch (error) {
    yield all([
      put({ type: "MERGE_REQUEST_NEW_CUSTOMER_DATA", mergeRequestData: {} }),
      put({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: false }) //loaderOff
    ]);
    failToast("Error in getting customer details");
  }
}

export default function* basicInfoSaga() {
  yield takeLatest(basicInfoType.UPDATE_BASIC_INFORMATION_CUSTOMER, updateBasicInformation);
  yield takeLatest(basicInfoType.GET_CUS_LOCATION_BASED_PINCODE, getAreaLocateBasedPincode);
  yield takeLatest(basicInfoType.GET_TRANS_INFORMATION_BY_CUSTOMER, getTransDetailsByCustomer);
  yield takeLatest(basicInfoType.GET_BASIC_INFORMATION_USING_EMAIL, getUserBasicDetailsUsingEmail);
  yield takeLatest(basicInfoType.GET_BASIC_INFORMATION_USING_CUSTOMER_ID, getUserBasicDetailsUsingCustomerId);
  yield takeLatest(basicInfoType.GET_ENQUIRY_INFORMATION_BY_CUSTOMER, getEnquiriesDetailsByCustomer);
  yield takeLatest(basicInfoType.GET_BASIC_INFORMATION_CUSTOMER, getUserBasicDetailsUsingMobileNumber);
  yield takeLatest(basicInfoType.MERGE_REQUEST_NEW_CUSTOMER, mergeRequestCustomer);
  yield takeLatest(basicInfoType.GET_BASIC_INFORMATION_FROM_ENQUIRY_POPUP, getEnquiryBasicInfoUsingCustomerId);
  yield takeLatest(basicInfoType.UPDATE_BASIC_INFORMATION_FROM_POPUP, updateBasicInformationFromEnquiryPopup);
  yield takeLatest(basicInfoType.UPDATE_ENQUIRY_BASIC_CUSTOMER_INFO, updateEnquiryBasicCustomerInformation);
}
