import { IconReceipt } from "assets/images";
import React from "react";
import { tdClass, thClass } from "utils/ClassContent";
import { moment } from "utils/momentConst";
import { convertToLowerCase } from "utils/validation";

const ResponseHistory = (props: any) => {
  const {
    transactions = [],
    createdBy = {},
    handleRevision = () => {},
    viewCurrent = false,
    handleViewCurrent = () => {}
  } = props || [];
  const tableData = (data: any = "") => {
    return <td className={tdClass}>{data}</td>;
  };
  if (!transactions.length) {
    return null;
  }
  return (
    <React.Fragment>
      <p>
        Created By: {createdBy?.name}/{createdBy?.empId}
      </p>
      {viewCurrent && (
        <button
          type="button"
          className="flex items-center justify-between text-sm border border-red-500 bg-primary-gradient px-3 py-2"
          onClick={handleViewCurrent}
        >
          Back to Original
        </button>
      )}
      <table>
        <thead>
          <tr className="border">
            <th className={thClass}>{"Updated On"}</th>
            <th className={thClass}>{"Updated By"}</th>
            <th className={thClass}>{"Status"}</th>
            <th className={thClass}>{"Action"}</th>
          </tr>

          {transactions.map((itm: any, i: number) => {
            const { status = "", updatedBy: { date = "", name = "" } = {} } = itm || {};
            return (
              <tr key={convertToLowerCase(i + "transactions-df")}>
                {tableData(date && moment(date).format("LLL"))}
                {tableData(name)}
                {tableData(status)}
                <td className={tdClass}>
                  <IconReceipt onClick={() => handleRevision(itm)} title="View revision" />
                </td>
              </tr>
            );
          })}
        </thead>
      </table>
    </React.Fragment>
  );
};

export default ResponseHistory;
