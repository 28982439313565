import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommonTable from "Component/CommonTable";
import PageAnimate from "Component/PageAnimate";
import { ImgAvatarMaleSm3 } from "assets/images";
import { _get, _isEmpty } from "utils/lodashConst";
import CommonInput from "Component/Form/CommonInput";
import DuplicateInvSmsLink from "./DuplicateInvSmsLink";
import DuplicateInvRemarks from "./DuplicateInvRemarks";
import CommonDropHeading from "Component/CommonDropHeading";
import MessageDeliveryHistory from "./MessageDeliveryHistory";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import CustomerBasicInfo from "../EditViewCall/CustomerBasicInfo";
import ServiceStatusForm from "./DropHeading/ServiceStatusUpdate";
import DuplicateInvStatusHistory from "./DuplicateInvStatusHistory";
import EnquiryForServicePopupTap from "./EnquiryForServicePopupTap";
import JobSheetDropHeading from "./DropHeading/JobSheetDropHeading";
import { ServiceSupport, GeneralInfo } from "utils/VariableConstant";
import BasicInfoDropHeading from "./DropHeading/BasicInfoDropHeading";
import CustomerSolutionInfo from "../EditViewCall/CustomerSolutionInfo";
import GeneralInfoDropHeading from "./DropHeading/GeneralInfoDropHeading";
import ProblemInfoDropHeading from "./DropHeading/ProblemInfoDropHeading";
import ServiceCallTransactionHistory from "./ServiceCallTransationHistory";
import EnquiryFromMail from "Pages/MailBox/ShowMailOnEnquiry/EnquiryFromMail";
import ServiceCenterDropHeading from "./DropHeading/ServiceCenterDropHeading";
import EnquiryDetailsTable from "Pages/NewCall/CustomerDetails/CustomerDetailedTable/EnquiryDetailsTable";
import { duplicateInvStatusUpdateList } from "Pages/NewCall/EnquiryFor/ServiceEnquiry/commonServiceEnquiry";
import {
  inputStylePopup,
  bg_EnquiryInfo,
  bg_EnquiryFromMail,
  bg_IMEIandMobile,
  bg_DuplicateInvoice,
  bg_EnquiryStatusHistory,
  bg_EnquiryStatusUpdate,
  bg_ComplaintInfo
} from "utils/ClassContent";
import {
  getMailByIdCall,
  imeiNumHistoryApiCall,
  resetEnquiryMailCall,
  sendServiceCenterSMSService,
  sendShowroomSMSService,
  updateServiceCustomerInfoCall,
  updateSupportStatusCall
} from "../commonServiceCalls";
import Loader from "Component/Loader";

/**
 * Show's customer enquiry data in popup
 * @param props props
 * @returns Enquiry for update popup
 */
const EnquiryForServicePopup = (props: any = {}) => {
  const {
    closePopup = () => {},
    customerData = {},
    setShow = true,
    activeModal = false,
    showImeiTrans = true
  } = nullToObject(props);

  const {
    _id: cusId = "",
    imei: imeiNo = "",
    mailId = "",
    status = "",
    enquiryFor = "",
    subCategory = "",
    document: documentList = "",
    solution: solutionInfo = {},
    customerName = "",
    deliveryChallan: deliveyChallan = []
  } = customerData;

  const globalStore = useSelector((state: any) => state);
  const { duplicateInvDetailsReducer: { dupImeiServiceUpdatedData = {} } = {} } = nullToObject(globalStore);
  const { salesReturn: { imeiNumHistoryDetails = {} } = {}, mailReducer: { selectedMailData = {} } = {} } =
    nullToObject(globalStore);

  const generalInfoSubCat = GeneralInfo;
  const serviceSupportSubCat = ServiceSupport;
  const [loader, setLoader] = useState(false);

  const [product, setProduct] = useState("");
  const [editView, setEditView] = useState(false);
  const [columnView, setColumnView] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({});
  const [popParentTabs, setPopParentTabs] = useState("Tab0");
  const [editedSolution, setEditedSolution] = useState(false);
  const [onStatusSubmit, setOnStatusSubmit] = useState(false);
  const [sendAttachments, setSendAttachments] = useState([]);
  const [serviceExistingEnquiries, setServiceExistingEnquiries] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState({
    _id: "",
    status: "",
    remarks: "",
    docFiles: [],
    enquiryFor: "",
    subCategory: "",
    followUpDate: "",
    currentStatus: ""
  });

  const popParentTabsHandle = (value = "") => {
    setPopParentTabs(value);
  };

  // Create's input fields using param conditions
  const createInputFields = (label = "", item = "", userObject: object = {}, editData = true) => {
    return (
      <CommonInput
        name={item}
        labelText={label}
        inputError={false}
        inputFieldClass="h-30"
        readOnly={editData}
        value={_get(userObject, item, "")}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
    );
  };

  /**
   * Add updated status value
   * @param changedItem changed object
   */
  const handleUserInfoChange = (changedItem: any = {}) => {
    setCustomerInfo({ ...customerInfo, ...changedItem });
  };

  /**
   * Add updated status value
   * @param changedItem changed object
   */
  const passSolutionInfo = (changedItem: any = {}, modifiedSolution = false) => {
    setCustomerInfo({ ...customerInfo, ...changedItem });
    if (modifiedSolution) setEditedSolution(modifiedSolution);
  };

  /**
   * Add updated status value
   * @param changedItem changed object
   */
  const handleOnStatusChange = (changedItem: Object = {}) => {
    setUpdatedStatus({ ...updatedStatus, ...changedItem });
    setOnStatusSubmit(true);
  };

  const handleColumnView = () => {
    setColumnView(!columnView);
  };
  /**
   * set product name
   * @param prod product name
   */
  const getProduct = (prod = "") => {
    setProduct(prod);
  };

  // change to edit mode
  const handleEditView = () => {
    if (status.toLowerCase().trim() !== "closed") {
      setEditView(!editView);
    } else {
      failToast("Cannot edit the closed enquiry");
    }
  };

  /**
   * Update customer info on click
   */
  const onUpdate = async () => {
    updateServiceCustomerInfoCall({ ...customerInfo, docFiles: sendAttachments });
    if (editedSolution) {
      setEditedSolution(false);
      const solObj = _get(customerInfo, "solution", {});
      const sol: any = _get(solObj, "solution", "");
      if (sol === "Visit Store") {
        const storeDetails =
          _get(solObj, "branchName", "") +
          " , " +
          _get(solObj, "storeAddress", "") +
          " , " +
          _get(solObj, "storeContactNo", "");
        const sendData = {
          mobile: _get(customerInfo, "imeiMobileNo", ""),
          customerName: _get(customerInfo, "imeiCustomerName", ""),
          addressDetails: storeDetails
        };
        setLoader(true);
        await sendShowroomSMSService(sendData);
        setLoader(false);
      }
      if (sol === "Visit Service Center") {
        const serviceCenterDetails =
          _get(solObj, "serviceCenter", "") + " , " + _get(solObj, "serviceCenterAddress", "");
        const sendData = {
          mobile: _get(customerInfo, "imeiMobileNo", ""),
          customerName: _get(customerInfo, "imeiCustomerName", ""),
          addressDetails: serviceCenterDetails
        };
        setLoader(true);
        await sendServiceCenterSMSService(sendData);
        setLoader(false);
      }
    }
    setEditView(false);
  };

  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) {
      closePopup(); //close popUp press esc button
    }
  };

  const imageGet = (imgArray: any = []) => {
    setSendAttachments(imgArray);
  };

  const onCloseImage = (index = -1) => {
    const newArray: any = [...sendAttachments];
    newArray.splice(index, 1);
    setSendAttachments(newArray);
  };

  // Call update api on submit
  useEffect(() => {
    if (onStatusSubmit) {
      updateSupportStatusCall(updatedStatus);
      setOnStatusSubmit(false);
    }
  }, [onStatusSubmit]);

  /**
   * user clik escape key close popUp
   */
  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    setCustomerInfo(customerData);
    setSendAttachments(documentList);
  }, [customerData]);

  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);

  useEffect(() => {
    const { data: imeiNumHistoryDetailsData = [] } = imeiNumHistoryDetails;

    const existingEnquiry = imeiNumHistoryDetailsData
      .map((ele: any) => {
        if (ele.imeiMobileNo === _get(customerData, "imeiMobileNo", "")) {
          return ele;
        }
        return undefined;
      })
      .filter((eleUndefined: any) => eleUndefined !== undefined);
    setServiceExistingEnquiries(existingEnquiry);
  }, [imeiNumHistoryDetails]);

  useEffect(() => {
    imeiNumHistoryApiCall(imeiNo);
  }, [imeiNo]);

  useEffect(() => {
    if (mailId) {
      getMailByIdCall(mailId);
    }
    return () => {
      if (mailId) {
        resetEnquiryMailCall();
      }
    };
  }, [mailId]);

  useEffect(() => {
    if (convertToLowerCase(subCategory) === convertToLowerCase("Duplicate invoice")) {
      const datas = {
        serviceID: cusId
      };
      duplicateInvStatusUpdateList(datas); //send service id
    }
  }, [cusId, subCategory]);

  return (
    <React.Fragment>
      {loader ? <Loader /> : null}

      <FixedPopupTemplate
        imgSrc={ImgAvatarMaleSm3}
        editStatus={editView}
        enableColumnViewOption={true}
        columnViewStatus={columnView}
        textHeaderHeading={customerName}
        handleClosePopup={closePopup}
        handleEditView={handleEditView}
        handleColumnView={handleColumnView}
        textSubHeaderHeading={`Enquiry For : ${enquiryFor}`}
        enableEditOption={status.toLowerCase().trim() !== "closed"}
      >
        <PageAnimate className="h-full">
          <EnquiryForServicePopupTap
            subCategory={subCategory}
            customerData={customerData}
            showImeiTrans={showImeiTrans}
            popParentTabs={popParentTabs}
            popParentTabsHandle={popParentTabsHandle}
            serviceExistingEnquiries={serviceExistingEnquiries}
          />

          <div
            className={`${columnView ? "flex items-start h-full flex-wrap lg:flex-nowrap pb-5" : ""}`}
            style={{ maxHeight: "calc(100% - 100px)" }}
          >
            <div
              className={` ${!activeModal ? "py-1" : "py-1 px-2"} ${
                columnView ? " w-full lg:w-7/12 px-2" : " "
              } flex-wrap flex `}
            >
              <BasicInfoDropHeading
                editView={editView}
                customerData={customerData}
                popParentTabs={popParentTabs}
                handleEditView={handleEditView}
              />

              {subCategory !== generalInfoSubCat && (
                <CommonDropHeading
                  customClass={bg_EnquiryInfo}
                  id="CustomerInfo"
                  heading="Customer Info"
                  tabActiveDefault={popParentTabs === "CustomerInfo" ? true : false}
                >
                  <CustomerBasicInfo
                    editView={editView}
                    passProduct={getProduct}
                    customerData={customerData}
                    passBasicInfo={handleUserInfoChange}
                  />
                </CommonDropHeading>
              )}

              {subCategory !== "Duplicate invoice" && subCategory !== generalInfoSubCat && (
                <ProblemInfoDropHeading
                  imageGet={imageGet}
                  product={product}
                  editView={editView}
                  onCloseImage={onCloseImage}
                  customerData={customerData}
                  documentList={documentList}
                  popParentTabs={popParentTabs}
                  deliveyChallan={deliveyChallan}
                  sendAttachments={sendAttachments}
                  createInputFields={createInputFields}
                  handleUserInfoChange={handleUserInfoChange}
                />
              )}

              {subCategory === generalInfoSubCat && (
                <GeneralInfoDropHeading
                  editView={editView}
                  customerData={customerData}
                  popParentTabs={popParentTabs}
                  createInputFields={createInputFields}
                />
              )}

              {subCategory === serviceSupportSubCat && (
                <CustomerSolutionInfo
                  editView={editView}
                  solutionData={solutionInfo}
                  customerData={customerData}
                  passSolutionInfo={passSolutionInfo}
                />
              )}

              {editView && subCategory !== generalInfoSubCat && (
                <div className="bg-white sticky bottom-0 py-5 flex flex-col items-center justify-center w-full">
                  <div>
                    <button
                      type="button"
                      onClick={onUpdate}
                      data-jest-id={"jestOnUpdate"}
                      className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
                    >
                      {"Update"}
                    </button>
                  </div>
                </div>
              )}

              {mailId && (
                <CommonDropHeading
                  tabActiveDefault={popParentTabs === "EnquiryFromMail" ? true : false}
                  id="EnquiryFromMail"
                  heading="Enquiry From Mail"
                  customClass={bg_EnquiryFromMail}
                >
                  <EnquiryFromMail customerData={customerData} selectedMailData={selectedMailData} />
                </CommonDropHeading>
              )}

              {!_isEmpty(customerData["serviceCenter"]) && (
                <ServiceCenterDropHeading
                  customerData={customerData}
                  popParentTabs={popParentTabs}
                  createInputFields={createInputFields}
                />
              )}

              {!_isEmpty(customerData["jobSheet"]) && (
                <JobSheetDropHeading
                  customerData={customerData}
                  popParentTabs={popParentTabs}
                  createInputFields={createInputFields}
                />
              )}
            </div>
            <div
              className={`bg-white flex flex-wrap items-center  border-gray-300 w-full  ${
                columnView ? "lg:w-5/12 px-2" : "pt-4 border-t-2 border-dashed "
              } `}
            >
              {subCategory !== "Duplicate invoice" &&
                subCategory !== generalInfoSubCat &&
                showImeiTrans &&
                serviceExistingEnquiries.length >= 1 && (
                  <CommonTable
                    id="IMEIandMobile"
                    setShow={true}
                    parentClass={"w-full"}
                    columnView={columnView}
                    heading={"IMEI and Mobile Enquiry History"}
                    enableModal={3}
                    customClass={bg_IMEIandMobile}
                  >
                    <EnquiryDetailsTable
                      getModalStatus={true}
                      modalFullscreen={true}
                      handleTableClose={() => {}}
                      openUserQnquireDetails={() => {}}
                      cusTransHistory={serviceExistingEnquiries}
                    />
                  </CommonTable>
                )}

              {convertToLowerCase(subCategory) !== convertToLowerCase("Duplicate invoice") && (
                <CommonTable
                  setShow={true}
                  parentClass={"w-full"}
                  columnView={columnView}
                  id="EnquiryStatusHistory"
                  heading={"Enquiry Status History"}
                  customClass={bg_EnquiryStatusHistory}
                >
                  <ServiceCallTransactionHistory customerData={customerData} />
                </CommonTable>
              )}
              {(convertToLowerCase(subCategory === convertToLowerCase("General Information")) ||
                convertToLowerCase(subCategory === convertToLowerCase("Duplicate invoice"))) && (
                <CommonTable
                  setShow={true}
                  parentClass={"w-full"}
                  columnView={columnView}
                  id="messageDeliveryHistory"
                  heading={"Message Delivery History"}
                  customClass={bg_EnquiryStatusHistory}
                >
                  <MessageDeliveryHistory customerData={customerData} />
                </CommonTable>
              )}
              {convertToLowerCase(subCategory) === convertToLowerCase("Duplicate invoice") && (
                <CommonTable
                  setShow={true}
                  id="DuplicateInvoice"
                  parentClass={"w-full"}
                  columnView={columnView}
                  heading={"Duplicate Invoice Status History"}
                  customClass={bg_DuplicateInvoice}
                >
                  <DuplicateInvStatusHistory
                    customerData={customerData}
                    dupImeiServiceUpdatedData={dupImeiServiceUpdatedData}
                  />
                </CommonTable>
              )}

              {convertToLowerCase(subCategory) === convertToLowerCase("General Information") && (
                <CommonTable
                  setShow={true}
                  id="DeliveryStatus"
                  parentClass={"w-full"}
                  columnView={columnView}
                  heading={"Message Delivery Status History"}
                  customClass={bg_DuplicateInvoice}
                >
                  <DuplicateInvStatusHistory
                    customerData={customerData}
                    dupImeiServiceUpdatedData={dupImeiServiceUpdatedData}
                  />
                </CommonTable>
              )}

              {convertToLowerCase(subCategory) === convertToLowerCase("Duplicate invoice") && (
                <CommonTable
                  setShow={true}
                  id="DuplicateInvoiceSms"
                  parentClass={"w-full"}
                  columnView={columnView}
                  heading={"Duplicate Invoice Sms Link"}
                  customClass={bg_ComplaintInfo}
                >
                  <DuplicateInvSmsLink
                    customerData={customerData}
                    dupImeiServiceUpdatedData={dupImeiServiceUpdatedData}
                  />
                </CommonTable>
              )}
              {convertToLowerCase(subCategory) === convertToLowerCase("Duplicate invoice") && (
                <CommonTable
                  setShow={true}
                  id="DuplicateRemark"
                  parentClass={"w-full"}
                  columnView={columnView}
                  heading={"Remark"}
                  customClass={bg_EnquiryStatusUpdate}
                >
                  <DuplicateInvRemarks
                    closePopup={closePopup}
                    customerData={customerData}
                    selectedMailData={selectedMailData}
                    dupImeiServiceUpdatedData={dupImeiServiceUpdatedData}
                  />
                </CommonTable>
              )}

              <ServiceStatusForm
                cusId={cusId}
                enquiryFor={enquiryFor}
                subCategory={subCategory}
                customerData={customerData}
                columnView={columnView}
                selectedMailData={selectedMailData}
                handleOnStatusChange={handleOnStatusChange}
              />
            </div>
          </div>
        </PageAnimate>
      </FixedPopupTemplate>
      <div id="ModalEnquiryForPopup" />
    </React.Fragment>
  );
};

export default EnquiryForServicePopup;
