import React from "react";
import ReactMultiCarousel from "Component/ReactMultiCarousel";

const OfferBanner = (props: any = {}) => {
  const { offers = [], title, handlePopupOffer } = props;

  /**
   * offer display
   * @param ele
   */
  function createMarkup(ele: any = {}) {
    const { offer_text = "" } = ele;
    return {
      __html: offer_text
    };
  }

  return (
    <div className="w-full">
      <h4 className="w-full text-left py-1 border-b pl-4 pr-5 text-sm  mb-3 font-bold uppercase text-gray-900">
        {" "}
        {title}
      </h4>
      <ReactMultiCarousel>
        {offers?.map((ele: any, i: number) => {
          return (
            <React.Fragment key={`${"offer" + i.toString()}`}>
              <div className="flex  h-full text-sm w-full group px-1 pt-1 pb-1" key={ele._id}>
                <div
                  onClick={() => handlePopupOffer(ele, title)}
                  style={{ minWidth: "80px", display: "flex", flexDirection: "column", lineHeight: 1 }}
                  className=" flex w-full pr-1 cursor-pointer border-2 border-dashed rounded py-2 px-3"
                  dangerouslySetInnerHTML={createMarkup(ele)}
                />
              </div>
            </React.Fragment>
          );
        })}
      </ReactMultiCarousel>
    </div>
  );
};

export default OfferBanner;
