import React from "react";
import CommonDropHeading from "Component/CommonDropHeading";
import { bg_ServiceCenter } from "utils/ClassContent";

/**
 * Service Center Drop Heading
 * @param props props
 * @returns Shows service center info on enquiry popup
 */
const ServiceCenterDropHeading = (props: any = {}) => {
  const { createInputFields = () => {}, customerData = {}, popParentTabs = "" } = props || {};

  return (
    <CommonDropHeading
      tabActiveDefault={popParentTabs === "serviceCenter" ? true : false}
      id="ServiceCenter"
      customClass={bg_ServiceCenter}
      heading="Service Center"
    >
      {createInputFields("From", "from", customerData["serviceCenter"])}
      {createInputFields("Send Date", "SendDate", customerData["serviceCenter"])}
      {createInputFields("Service Center Name", "ServiceCenterName", customerData["serviceCenter"])}
      {createInputFields("Carried Person", "carriedPerson", customerData["serviceCenter"])}
      {createInputFields("Carried Person MobileNo", "mobileNo", customerData["serviceCenter"])}
    </CommonDropHeading>
  );
};

export default ServiceCenterDropHeading;
