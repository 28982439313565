import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { amyeoPopupProps } from "./types";
import ActionPopup from "Component/ActionPopup";
import { MockCampaignData } from "Component/MockData";
import CommonRadioBox from "Component/Form/CommonRadioBox";
import { ExclamationIcon } from "@heroicons/react/solid";
import { getManualPageAccess, pageNumber } from "helperRole";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import { getRegisterUserLit } from "Pages/EmployeeList/commonEmployeeDetailsget";
import { actionPopupBtnStyleGreen, actionPopupBtnStyleWhite } from "utils/ClassContent";
import ModalFixed3 from "Component/Modal/ModalFixed3";

const callTypeArr = [
  { labelText: "Call from self", value: "self" },
  { labelText: "Call to specific user", value: "user" },
  { labelText: "Call to any User", value: "any" }
];
const AmeyoPopup = (props: amyeoPopupProps) => {
  const { userId = "", closePopup = () => {}, handleCall = () => {}, employeeListData = [] } = nullToObject(props);

  const permissionDetails = getManualPageAccess(pageNumber.AMEYO_CALL); //73
  const { read = false } = nullToObject(permissionDetails);

  const [popup, setpopup] = useState(true);
  const [selectedEmpId, setEmpId] = useState("");
  const [listValue, setListValue] = useState([]);
  const [campaignId, setCampaignId] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const [campaignLabel, setCampaignLabel] = useState("");
  const [selectCallType, setSelectedCallType] = useState("self");
  const [compaignData, setCampaignData] = useState(MockCampaignData);

  /**
   * handle call type
   * @param event
   */
  const onCheckBoxChange = (event: any = {}) => {
    const { value = "" } = _get(event, "target", {});
    setSelectedCallType(value);
  };

  const handleOptionChange = (item: any = {}) => {
    const { name = "", empId = "" } = item;
    setEmpId(empId);
    setSelectedUser(name + " | " + empId);
  };

  /**
   * handle campaign select
   * @param item
   */
  const handleCampagnChange = (item: any = {}) => {
    const { label = "", id = "" } = item;
    setCampaignId(id);
    setCampaignLabel(label + " | " + id);
  };

  /**
   * handle input search
   * @param event
   */
  const handleSearch = (event: any = {}) => {
    const { value = "" } = _get(event, "target", {});
    const dataFilterResult = MockCampaignData.filter((e: any) => {
      return convertToLowerCase(e.label).includes(value.toLowerCase());
    });
    setCampaignData(dataFilterResult);
    setCampaignId("");
    setCampaignLabel(value); //pass value to empty dropDown value
  };

  /**
   * handle call submit
   */
  const handleSubmit = () => {
    if (!campaignId || typeof campaignId !== "number") {
      failToast("Please select campaign");
      return;
    }
    if (selectCallType === "self") {
      handleCall({ userId: userId, campaignId: campaignId });
    } else if (selectCallType === "user") {
      selectedUser && employeeListData.length > 0
        ? handleCall({ userId: selectedEmpId, campaignId: campaignId })
        : failToast("Please select user");
    } else {
      handleCall({ campaignId: campaignId });
    }
  };

  useEffect(() => {
    if (read) {
      getRegisterUserLit({ page: 1, size: 10000 });
    }
    setpopup(true);
    return () => {
      setpopup(true);
    };
  }, [read]);

  useEffect(() => {
    setListValue(employeeListData);
  }, [employeeListData]);

  return (
    <>
      {read ? (
        <ModalFixed3>
          <ActionPopup
            isOverFlow={false}
            customWidth="550px"
            enableHeader={true}
            enableActionIcon={false}
            handleActionText={"Create Call"}
            textHeaderHeading={"Assign Call"}
            handleAction={handleSubmit}
            handleCancel={() => closePopup(false)}
            styleHandleAction={actionPopupBtnStyleGreen}
            styleHandleCancel={actionPopupBtnStyleWhite}
          >
            <div className="px-4 pb-0">
              <div className="flex flex-wrap justify-between w-full mb-2">
                {callTypeArr.map((itm, index) => (
                  <CommonRadioBox
                    label={true}
                    name="complain"
                    value={itm.value}
                    id={index + itm.value}
                    labelText={itm.labelText}
                    onChange={onCheckBoxChange}
                    checked={selectCallType === itm.value}
                    parentClass={"p-1.5 pl-0 w-full lg:w-1/2"}
                    key={convertToLowerCase(index + "callTypeArr-tendk")}
                  />
                ))}
              </div>

              <div>
                {selectCallType === "user" && (
                  <CommonSearchDropdown
                    label={true}
                    name={"user"}
                    mustField={true}
                    arrayData={listValue}
                    value={selectedUser}
                    selectorKey={"name"}
                    placeholder={"--None--"}
                    inputWithIconClass="w-10"
                    labelText={"Select User"}
                    clearInput={handleSearch}
                    labelTextClass={"font-bold"}
                    inputFieldClass="h-9 pr-11"
                    inputClass="flex justify-end"
                    // parentClass={inputStylePopup}
                    handleParentDropVal={handleOptionChange}
                  />
                )}

                <CommonSearchDropdown
                  label={true}
                  mustField={true}
                  name={"campaignId"}
                  value={campaignLabel}
                  selectorKey={"label"}
                  arrayData={compaignData}
                  placeholder={"--None--"}
                  inputWithIconClass="w-10"
                  clearInput={handleSearch}
                  labelText={"Select Campaign"}
                  inputFieldClass="h-9 pr-11"
                  inputClass="flex justify-end"
                  // parentClass={inputStylePopup}
                  labelTextClass={"font-bold mt-2"}
                  handleParentDropVal={handleCampagnChange}
                />
              </div>
            </div>
          </ActionPopup>
        </ModalFixed3>
      ) : null}

      {!read && popup ? (
        <ModalFixed3>
          <ActionPopup
            enableDesc={true}
            customWidth={"100%"}
            customMWidth={"450px"}
            handleActionText={"Ok"}
            actionHeading={"Alert"}
            actionDescription={"No permisstion to make call"}
            handleAction={() => {
              setpopup(false);
              closePopup(false);
            }}
            actionIconComponent={<ExclamationIcon className="w-10 h-10 mx-auto text-red-500" />}
          />
        </ModalFixed3>
      ) : null}
    </>
  );
};

export default AmeyoPopup;
