import React from "react";
import { _get } from "utils/lodashConst";
import { convertToLowerCase, nullToArray, nullToObject } from "utils/validation";
import LazyImage from "Component/LazyImage";

const DeliveryTypeView = (props: any = {}) => {
  const { deliveryType = [] } = nullToObject(props);

  const deliveryBasedList = nullToArray(deliveryType)
    .map((ele: any) => {
      if (convertToLowerCase(_get(ele, "mode", "")) === convertToLowerCase("Regular Delivery")) {
        ele.sortId = 5;
        return ele;
      } else if (convertToLowerCase(_get(ele, "mode", "")) === convertToLowerCase("Pickup@store")) {
        ele.sortId = 4;
        return ele;
      } else if (convertToLowerCase(_get(ele, "mode", "")) === convertToLowerCase("Nextday Delivery")) {
        ele.sortId = 3;
        return ele;
      } else if (convertToLowerCase(_get(ele, "mode", "")) === convertToLowerCase("2 hours Delivery")) {
        ele.sortId = 1;
        return ele;
      }
      ele.sortId = 2;
      return ele;
    })
    .sort((a: any, b: any) => a.sortId - b.sortId);

  return (
    <div className="inline-flex items-center w-full px-4">
      <div className="flex flex-col w-full">
        {_get(deliveryBasedList, "length", 0) > 0 ? (
          <React.Fragment>
            {deliveryBasedList.map((ele: any, index: number) => {
              return (
                <React.Fragment key={convertToLowerCase(index + "deliveryType")}>
                  <div className="flex items-center text-xs mb-1">
                    <LazyImage height={50} width={105} alt={_get(ele, "mode", "")} src={_get(ele, "icon", "")} />
                    {_get(ele, "delivery_time", "") ? (
                      <>
                        <div className="mx-2 inline-block text-green-500 font-semibold">
                          {_get(ele, "delivery_time", "")}
                        </div>
                        <div className="mx-2 inline-block text-green-500 font-semibold">
                          {_get(ele, "charges", 0) !== 0 && <> Charge: {_get(ele, "charges", 0)}</>}
                        </div>
                      </>
                    ) : null}
                  </div>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        ) : (
          <div className="text-center w-full text-sm text-gray-600">No delivery for this pincode</div>
        )}
      </div>
    </div>
  );
};

export default DeliveryTypeView;
