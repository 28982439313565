import { _get } from "utils/lodashConst";
import { put, call, takeLatest, all } from "redux-saga/effects";
import * as duplicateInvImeiType from "../actionTypes/duplicateInvImeiType";
import { infoToast } from "Component/ToastServices/ToastDisplay";
import {
  getDuplicateInvImeiNumberBasedServices,
  getDuplicateStatusUpdatedBasedServices
} from "utils/services/duplicateInvService";

/**
 * @param  {object} obj
 * get details data from IMEI number based data
 */
function* imeiBasedGetDetailSaga(obj = {}): any {
  try {
    const response = yield call(getDuplicateInvImeiNumberBasedServices, obj);
    const { status = 0, data: { Table = [] } = {} } = response || {};
    if (status === 200) {
      if (Table.length >= 1) {
        yield all([
          put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_DATA, dupImeiNumbasedUserData: Table }), //userData
          put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_LOADER, imeiNumLoader: false }) //loaderOff
        ]);
      } else {
        infoToast(_get(response, "data[0].Status", "No Data Found"));
        yield all([
          put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_DATA, dupImeiNumbasedUserData: [] }), //userData
          put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_LOADER, imeiNumLoader: false }) //loaderOff
        ]);
      }
    } else {
      yield all([
        put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_DATA, dupImeiNumbasedUserData: [] }), //userData
        put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_LOADER, imeiNumLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_DATA, dupImeiNumbasedUserData: [] }), //userData
      put({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_LOADER, imeiNumLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {object} obj
 * get details data from Duplicate Invoice Status based data
 */
function* dupInvStatusBasedGetDetailSaga(obj = {}): any {
  try {
    const response = yield call(getDuplicateStatusUpdatedBasedServices, obj);
    const { data: { status = 0, data: _Datas = {} } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_BASED_DATA, dupImeiServiceUpdatedData: _Datas }), //userData
        put({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_LOADER, statusUpdateListLoader: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_BASED_DATA, dupImeiServiceUpdatedData: [] }), //userData
        put({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_LOADER, statusUpdateListLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_BASED_DATA, dupImeiServiceUpdatedData: [] }), //userData
      put({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_LOADER, statusUpdateListLoader: false }) //loaderOff
    ]);
  }
}

export default function* duplicateInvSaga() {
  yield takeLatest(duplicateInvImeiType.DUP_IMEI_NUMBER_BASED_GET_DETAILS, imeiBasedGetDetailSaga);
  yield takeLatest(duplicateInvImeiType.DUP_STATUS_LIST_BASED_GET_DETAILS, dupInvStatusBasedGetDetailSaga);
}
