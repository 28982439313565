import React from "react";

const SubmitInfoButton = (props: any) => {
  const { viewerType = "", handleSubmit = () => {}, status = "" } = props || {};
  return (
    <div className="py-8 flex flex-col items-center justify-center w-full">
      {viewerType === "auditor" && status === "request1:1" && (
        <button
          type="button"
          onClick={() => handleSubmit(false, "reviewed")}
          className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
        >
          Submit
        </button>
      )}
      {viewerType === "agent" && status === "reviewed" && (
        <button
          type="button"
          onClick={() => handleSubmit(false, "accepted")}
          className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
        >
          Accept
        </button>
      )}
      {viewerType === "agent" && (status === "pending" || status === "viewed") && (
        <>
          <button
            type="button"
            onClick={() => handleSubmit(true)}
            className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
          >
            Request 1:1
          </button>
          <button
            type="button"
            onClick={() => handleSubmit(false, "accepted")}
            className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
          >
            Accept
          </button>
        </>
      )}
      {viewerType === "" && !status && (
        <button
          type="button"
          onClick={() => handleSubmit(false, "pending")}
          className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default SubmitInfoButton;
