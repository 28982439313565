import React from "react";
import ReadMore from "Component/ReadMore";
import CallRecording from "Component/Ameyo/CallRecording";
import { enquiryStatusTrackingTableProps } from "../types";
import { thClassStyle2, tdClass, TableClass } from "utils/ClassContent";
import { convertToLowerCase, dateTimeDisplay, nullToObject } from "utils/validation";

const ComplainStatusTrackingData = (props: enquiryStatusTrackingTableProps) => {
  const { phone = "", transactions = [] } = nullToObject(props);

  return (
    <div className="block w-full overflow-x-auto relative" style={{ minHeight: "120px" }}>
      <table className={TableClass}>
        <thead>
          <tr>
            <th className={thClassStyle2}>Complain Type</th>
            <th className={thClassStyle2}>Concern Type</th>
            <th className={thClassStyle2}>Name / Contact / Email</th>
            <th className={thClassStyle2}>Follow Date / Hearing Date</th>
            <th className={thClassStyle2} style={{ minWidth: "250px" }}>
              Remarks
            </th>
            <th className={thClassStyle2}>Status</th>
            <th className={thClassStyle2}>Sub Status</th>
            <th className={thClassStyle2}>Updated On</th>
            <th className={thClassStyle2}>Updated by</th>
            <th className={thClassStyle2}>Call Recording</th>
            {/* <th className={thClassStyle2}>View</th> */}
          </tr>
        </thead>
        {transactions.map((ele: any, i: number) => {
          const {
            additional: {
              emailId = "",
              concernType = "",
              hearingDate = "",
              complainType = "",
              contactNumber = "",
              nameOfThePerson = "",
              nextFollowUpDate = "",
              additionalRemarks = "",
              complaintantExpectation = ""
            } = {},
            remarks = "",
            status = "",
            currentStatus = "",
            actionBy: { date = "", name = "" } = {}
          } = ele;
          return (
            <tbody key={convertToLowerCase(i + "transactions-dfd")}>
              <tr>
                <td className={tdClass}>{complainType}</td>
                <td className={tdClass}>{concernType}</td>
                <td className={tdClass}>
                  {nameOfThePerson}
                  {nameOfThePerson && <br />}
                  {contactNumber}
                  {contactNumber && <br />}
                  {emailId}
                </td>

                <td className={tdClass}>
                  {nextFollowUpDate && "F-" + dateTimeDisplay(nextFollowUpDate)}
                  <br />
                  {hearingDate && "H- " + dateTimeDisplay(hearingDate)}
                </td>

                <td className={tdClass}>
                  <ReadMore content={remarks || additionalRemarks || complaintantExpectation || ""} />
                </td>

                <td className={tdClass}>{status}</td>
                <td className={tdClass}>{currentStatus}</td>
                <td className={tdClass}>{date && dateTimeDisplay(date)}</td>
                <td className={tdClass}>{name}</td>
                <td className={tdClass}>
                  <CallRecording phone={phone} updatedDate={date} />
                </td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default ComplainStatusTrackingData;
