import React, { useEffect, useState } from "react";
import TLHierarchyList from ".";
import { moment } from "utils/momentConst";
import { nullToObject } from "utils/validation";
import { getTeamMembers } from "Pages/QualityScorecard/helper";
import DatePickerCommon from "Component/CommonTable/CommonStartToEndDatePic/DatePickerCommon";

const TeamMembers = (props: any = {}) => {
  const { team = {} } = props;
  const [teamMembers, setTeamMembers] = useState<any>({});
  const [members, setMembers] = useState([]);
  const [userSearch, setUserSearch] = useState({
    page: 1,
    size: 10,
    search: "",
    endDate: moment(),
    startDate: moment().subtract(1, "w")
  });

  const fetchTeamMembers = async (teamData: any = {}) => {
    if (teamData._id) {
      const response = await getTeamMembers(teamData._id, userSearch);
      const {
        data: { data: memeberData = {}, status = 0 }
      } = nullToObject(response);
      if (status === 200) {
        setTeamMembers(memeberData);
      }
    }
  };

  useEffect(() => {
    fetchTeamMembers(team);
  }, [team, userSearch]);

  useEffect(() => {
    if (teamMembers && Object.keys(teamMembers)?.length) {
      const mappedTeam = teamMembers.members.map((member: any) => ({
        ...member,
        _id: member.userId.empId,
        name: member.userId.name
      }));
      setMembers(mappedTeam);
    }
  }, [teamMembers]);

  const _onDateChange = (event: any = {}) => {
    const { target: { nameEvalue: endDate = "", value: startDate = "" } = {} } = event;
    setUserSearch({ ...userSearch, startDate, endDate });
  };

  return (
    <div className="py-5 flex flex-col">
      <div className="flex justify-start px-2">
        <h3>
          {team.name}
          {"'s"} Team
        </h3>
      </div>
      <div className="flex justify-end px-2 border-b pb-1">
        <DatePickerCommon userSearch={userSearch} _onDateChange={_onDateChange} />
      </div>

      <div className="flex flex-col">
        <TLHierarchyList type={"member"} data={members} />
      </div>
    </div>
  );
};

export default TeamMembers;
