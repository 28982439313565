import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { ComplainFormProps } from "../types";
import { dateToString } from "utils/validation";
import SourceBasicInfo from "../SourceBasicInfo";
import PartyInputDetail from "./PartyInputDetail";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import CommonTextarea from "Component/Form/CommonTextarea";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import BasicInfoImei from "Pages/NewCall/EnquiryFor/Complains/Iemi/BasicIformation/BasicInfoImei";
import {
  mockComplaintType,
  mockComplaintAbout,
  mockComplaintSource,
  mockComplaintSubCategory
} from "Component/MockData";
import ImageUploadReturn from "Component/ImageUploadReturn";

const ComplainForm = (props: ComplainFormProps) => {
  const { setShow = true, enableViewMode, viewModeData = {}, handleUpdateDataChange = () => {} } = props;
  const [partyError, setPartyError] = useState<any>([]);
  const [imagRenderArry, setImagRenderArry] = useState([]);
  const dropDownValue = {
    complaints: mockComplaintType,
    complaintsAbout: mockComplaintAbout,
    complaintsSource: mockComplaintSource,
    complaintSubCatgory: mockComplaintSubCategory
  };

  /**
   * on image close
   * @param index
   */
  const onClosImage = (index: number) => {
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    setImagRenderArry(newArray);
    handleUpdateDataChange({ additionalAttachments: newArray });
  };

  /**
   * handle options change
   * @param data
   * @param type
   */
  const handleOptions = (data: any = {}, type: any = "") => {
    const { optionData = "" } = data;
    handleUpdateDataChange({
      [type]: optionData
    });
  };

  /**
   * hanlde key and value in input
   * @param value
   * @param key
   */
  const handleInputChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    handleUpdateDataChange({
      [name]: value
    });
  };

  /**
   * handle party changes cb
   * @param data
   */
  const handlePartyCb = (data: any = {}) => {
    handleUpdateDataChange({
      party: data
    });
  };

  /**
   * handle advocate details change
   */
  const handleComplaintAdChange = (changeValue: any = {}) => {
    const { value = "", name = "" } = changeValue;
    handleUpdateDataChange({
      complaintantAdvocateDetails: {
        ...viewModeData.complaintantAdvocateDetails,
        [name]: value
      }
    });
  };

  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);

  return (
    <React.Fragment>
      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        name={"subCategory"}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        readOnly={enableViewMode}
        labelText={"Sub Category"}
        inputFieldClass="h-9 pr-11"
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        inputClass="flex justify-end"
        value={viewModeData.subCategory}
        handleParentDropVal={(data: any = {}) => handleOptions(data, "subCategory")}
        arrayData={dropDownValue.complaintSubCatgory}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        name={"complainType"}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        readOnly={enableViewMode}
        inputFieldClass="h-9 pr-11"
        labelText={"Complain Type"}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        parentClass={inputStylePopup}
        value={viewModeData.complainType}
        arrayData={dropDownValue.complaints}
        handleParentDropVal={(data: any = {}) => handleOptions(data, "complainType")}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        name={"complainSource"}
        placeholder={"--None--"}
        readOnly={enableViewMode}
        inputWithIconClass="w-10"
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        labelTextClass={"font-bold"}
        labelText={"Complain Source"}
        parentClass={inputStylePopup}
        handleParentDropVal={(data: any = {}) => handleOptions(data, "complainSource")}
        value={viewModeData.complainSource}
        arrayData={dropDownValue.complaintsSource}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        name={"complainAbout"}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        readOnly={enableViewMode}
        inputFieldClass="h-9 pr-11"
        labelText={"Complain About"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        inputClass="flex justify-end"
        handleParentDropVal={(data: any = {}) => handleOptions(data, "complainAbout")}
        value={viewModeData.complainAbout}
        arrayData={dropDownValue.complaintsAbout}
      />

      <CommonInput
        setShow={true}
        inputError={false}
        inputFieldClass="h-9"
        enableDatePicker={true}
        isIconLabel={true}
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        onChange={handleInputChange}
        name={"ComplainRecievedDate"}
        parentClass={inputStylePopup}
        enableViewMode={enableViewMode}
        labelText={"Complain Received Date"}
        placeholderText={"" + dateToString(viewModeData.complainRecieveDate)}
        // value={viewModeData.complainRecieveDate && new Date(viewModeData.complainRecieveDate)}
      />

      <CommonInput
        name={"imei"}
        maxLength={staticData.imeilength}
        setShow={true}
        inputError={false}
        labelText={"IMEI"}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        value={viewModeData.imei}
        placeholder={"IMEI Number"}
        onChange={handleInputChange}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />

      <SourceBasicInfo
        viewModeData={viewModeData}
        onChange={handleInputChange}
        enableViewMode={enableViewMode}
        // passDataGenralData={passDataGenralDatas}
      />

      <CommonTextarea
        setShow={true}
        inputFieldClass="h-30"
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        name={"complaintantExpectation"}
        labelText={"Complain Expectation"}
        value={viewModeData.complaintantExpectation}
      />

      <>
        {viewModeData.document?.length >= 1 && (
          <div className="flex mb-4 ml-4 w-full">
            <h2>Document Uploaded</h2>
          </div>
        )}
        <div className="mb-4 ml-4">
          {!enableViewMode && imagRenderArry.length >= 1 && (
            <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
          )}

          {viewModeData.document?.length >= 1 && (
            <ImageUploadReturn enableViewMode={true} imagRenderArry={viewModeData.document} />
          )}
        </div>
      </>

      {viewModeData.complaintantAdvocateDetails && (
        <BasicInfoImei
          title={"Advocate Details"}
          inputStyle={inputStylePopup}
          enableViewMode={enableViewMode}
          handelChange={handleComplaintAdChange}
          basicInfo={viewModeData.complaintantAdvocateDetails}
        />
      )}

      <PartyInputDetail
        partyError={partyError}
        party={viewModeData.party}
        handlePartyCb={handlePartyCb}
        enableViewMode={enableViewMode}
        setPartyError={setPartyError}
      />
    </React.Fragment>
  );
};

export default ComplainForm;
