import React, { Fragment, useEffect, useState } from "react";
import TeamMembers from "./TeamMembers";
import PageAnimate from "Component/PageAnimate";
import { TableClass } from "utils/ClassContent";
import ModalFixed from "Component/Modal/ModalFixed";
import TLHierarchyListBody from "./TLHierarchyListBody";
import TLHierarchyListHeader from "./TLHierarchyListHeader";
import FixedPopupTemplate from "Component/FixedPopupTemplate";

const TLHierarchyList = (props: any = {}) => {
  const { data = [], type = "member" } = props;
  const [selectedTeam, setSelectedTeam] = useState(false);
  const [dataList, setDataList] = useState(data);

  const handleTeamMember = (team: any = {}) => {
    setSelectedTeam(team);
  };

  const handleSort = (state: any, key = "") => {
    const tempData = [...dataList];
    const gtSort = (a: any, b: any) => (a[key] > b[key] ? 1 : -1);
    const ltSort = (a: any, b: any) => (a[key] < b[key] ? 1 : -1);
    tempData.sort(state ? gtSort : ltSort);
    setDataList([...tempData]);
  };

  useEffect(() => {
    setDataList(data);
  }, [data]);

  return (
    <Fragment>
      <PageAnimate>
        <div className="flex flex-col w-full h-auto">
          <div style={{ minHeight: "400px" }} className="block w-full overflow-x-auto relative ">
            <table id="UserDetailsTable" className={TableClass}>
              <TLHierarchyListHeader type={type} handleSort={handleSort} />
              <TLHierarchyListBody
                data={dataList}
                type={type}
                handleTeamMember={(team: any) => handleTeamMember(team)}
              />
            </table>
          </div>
        </div>
      </PageAnimate>
      {selectedTeam && (
        <ModalFixed>
          <FixedPopupTemplate handleClosePopup={() => handleTeamMember(false)} textHeaderHeading="Team Members">
            <PageAnimate>
              <TeamMembers team={selectedTeam} />
            </PageAnimate>
          </FixedPopupTemplate>
        </ModalFixed>
      )}
    </Fragment>
  );
};

export default TLHierarchyList;
