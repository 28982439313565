import React, { useEffect, useState } from "react";
import PageAnimate from "Component/PageAnimate";
import { TableClass } from "utils/ClassContent";
import EvaluatorAuditListHeader from "./EvaluatorAuditListHeader";
import EvaluatorAuditListBody from "./EvaluatorAuditListBody";

const EvaluatorReportsList = (props: any = {}) => {
  const { data = [] } = props;
  const [dataList, setDataList] = useState(data);

  const handleSort = (state: any, key = "") => {
    const tempData = [...dataList];
    const gtSort = (a: any, b: any) => (a[key] > b[key] ? 1 : -1);
    const ltSort = (a: any, b: any) => (a[key] < b[key] ? 1 : -1);
    tempData.sort(state ? gtSort : ltSort);
    setDataList([...tempData]);
  };

  useEffect(() => {
    setDataList(data);
  }, [data]);

  return (
    <PageAnimate>
      <div className="flex flex-col w-full h-auto">
        <div style={{ minHeight: "400px" }} className="block w-full overflow-x-auto relative ">
          <table id="UserDetailsTable" className={TableClass}>
            <EvaluatorAuditListHeader handleSort={handleSort} />
            <EvaluatorAuditListBody data={dataList} />
          </table>
        </div>
      </div>
    </PageAnimate>
  );
};

export default EvaluatorReportsList;
