import { PhoneIcon } from "@heroicons/react/outline";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import { IconWhatsappOutline } from "assets/images";
import CommonInput from "Component/Form/CommonInput";
import { inputStylePopup } from "utils/ClassContent";
import { numOnlyAllow, processInput } from "utils/validation";

const AdditionalInfo = (props: any = {}) => {
  const {
    isEdit = false,
    whatsappNum = "",
    alterMobile = "",
    enableViewMode = false,
    handleBasicChange = () => {}
  } = props;

  return (
    isEdit && (
      <>
        <CommonInput
          value={alterMobile}
          name={"alterMobile"}
          inputFieldClass="h-9"
          inputWithIcon={true}
          readOnly={enableViewMode}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          onChange={handleBasicChange}
          maxLength={staticData.MobileNo}
          labelText={"Alternative Number"}
          onInput={processInput}
          iconComponent={<PhoneIcon className="w-5 h-5" />}
          onKeyPress={numOnlyAllow}
        />

        <CommonInput
          value={whatsappNum}
          inputWithIcon={true}
          name={"whatsappNum"}
          inputFieldClass="h-9"
          readOnly={enableViewMode}
          onChange={handleBasicChange}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          maxLength={staticData.MobileNo}
          labelText={"Whatsapp Number"}
          onInput={processInput}
          onKeyPress={numOnlyAllow}
          iconComponent={<IconWhatsappOutline className="w-5 h-5" />}
        />
      </>
    )
  );
};

export default AdditionalInfo;
