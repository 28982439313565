import { _get } from "utils/lodashConst";
import { encryptData } from "utils/secureFile";
import * as emiTypes from "../actionTypes/emiTypes";
import { put, takeLatest, call, all } from "redux-saga/effects";
import { serverNotRespond } from "Component/ToastServices/ToastDisplay";
import {
  getEmiProductServices,
  getEmiOfferDetailsServices,
  typeSenseAccessTokenservices
} from "utils/services/emiService";

/**
 * @param  {object} obj
 * GET ACCESS TOKEN FOR EMI PRODUCT
 */
function* emiTokenCallSaga(): any {
  try {
    const response = yield call(typeSenseAccessTokenservices);
    const { data: { success = 0, data: { access_token: accessToken = "" } = {} } = {} } = response || {};
    if (success === 1) {
      encryptData("typesenseToken", accessToken);
      yield put({ type: "EMI_TOKEN_LOADR", tokenLoader: false }); //load teams page on success of api call
    } else {
      yield put({ type: "EMI_TOKEN_LOADR", tokenLoader: false }); //load teams page on success of api call
    }
  } catch (error) {
    serverNotRespond(); //serverNotFound
    yield put({ type: "EMI_TOKEN_LOADR", tokenLoader: false }); //load teams page on success of api call
  }
}

/**
 * @param  {object} obj
 *EMI PEODUCT GET VALUE
 */
function* emiProductGetvalueSaga(obj = {}): any {
  try {
    const response = yield call(getEmiProductServices, obj) || {};
    const { data = {}, status = 0 } = response || {};
    const { results = {} } = data;
    if (status === 200) {
      const filterSkuId = _get(results, "[0].hits", [])
        .map((ele: any) => {
          //filter sku id is empty
          if (ele.document.sku !== "") {
            ele.sku = ele.document.sku;
            ele.price = ele.document.price;
            ele.thumb = ele.document.thumb;
            ele.optionData = ele.document.name;
            ele.brand_name = ele.document.brand_name;
            ele.stock_status = ele.document.stock_status;
            ele.selling_price = ele.document.selling_price;
            return ele;
          }
          return undefined;
        })
        .filter((undefineRemove: any) => undefineRemove !== undefined);
      yield all([
        put({ type: "EMI_PRODUCT_LOADR", productLoader: false }), //loaderOff
        put({ type: "EMI_PRODUCT_DATA", emiProductData: _get(results, "[0].hits", []) }),
        put({ type: "AVAILABLE_EMI_PRODUCT_DATA", availableEmiProductData: filterSkuId })
      ]);
    } else {
      yield all([
        put({ type: "EMI_PRODUCT_LOADR", productLoader: false }), //loaderOff
        put({ type: "EMI_PRODUCT_DATA", emiProductData: {} }),
        put({ type: "AVAILABLE_EMI_PRODUCT_DATA", availableEmiProductData: [] })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "EMI_PRODUCT_LOADR", productLoader: false }), //loaderOff
      put({ type: "EMI_PRODUCT_DATA", emiProductData: {} }),
      put({ type: "AVAILABLE_EMI_PRODUCT_DATA", availableEmiProductData: [] })
    ]);
    serverNotRespond(); //serverNotFound
  }
}

type passParams = {};

/**
 * @param  {object} obj
 * get offer and bank details
 */
function* emipeoductDeatils(obj: passParams): any {
  try {
    const response = yield call(getEmiOfferDetailsServices, obj);

    const { data: { data: { issuer = [], response_code: responseCcode = 0 } = {}, status = 0 } = {} } = response || {};
    if (status === 201 && responseCcode === 1) {
      const issuerData = issuer.map((ele: any) => {
        //filter sku id is empty
        return {
          ...ele,
          optionData: ele.issuer_name
        };
      });
      yield all([
        put({ type: "EMI_PRODUCT_OFFER_LOADER", emiBackOfferLoader: false }), //loaderOff
        put({ type: "EMI_PRODUCT_BANK_OFFER_DETAILS", emiBackOfferDetails: issuerData }),
        put({ type: "EMI_PRODUCT_BANK_OFFER_AVAILABLE", emiBackOfferIsAvailable: responseCcode })
      ]);
    }
  } catch (error) {
    serverNotRespond(); //serverNotFound
    // errorCommonStoreData();//errorResponse
  }
}

export default function* emiSaga() {
  yield takeLatest(emiTypes.TYPESENSE_ACCESS_TOKEN, emiTokenCallSaga); //getToken
  yield takeLatest(emiTypes.GET_EMI_PRODUCT_DETAILS, emipeoductDeatils); //getBanck offerDetails
  yield takeLatest(emiTypes.GET_EMI_PRODUCT_DATA, emiProductGetvalueSaga); //getProduct Details
}
