import React from "react";
import Loader from "Component/Loader";

const ConvertSRNLoader = (props: any = {}) => {
  const { convertTosrnLoader = false } = props || {};

  return convertTosrnLoader && <Loader />;
};

export default ConvertSRNLoader;
