import { _get } from "utils/lodashConst";
import * as complainType from "../actionTypes/complainType";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { justDialDetailsStore } from "Pages/JustDialTable/commonJustDial";
import { failToast, infoToast } from "Component/ToastServices/ToastDisplay";
import { updatecomplainServices, getImeiNumberBasedServices } from "utils/services/complainService";
import { updateMailByIdService } from "utils/services/mailService";

/**
 * @param  {object} complainData
 * update complain Data
 */
function* updateComplainData(complainData = {}): any {
  try {
    const response = yield call(updatecomplainServices, complainData);
    const {
      data: { data: { enquiryFor = "", subCategory = "", _id = "", mailId = "" } = {}, Table = [], status = 0 } = {}
    } = response || {};
    if (status === 201) {
      infoToast("Data saved successfully");
      justDialDetailsStore({}); //after success enquiry clear data
      yield all([
        put({ type: "COMPLAIN_DATA_SUCCESS", data: response }),
        put({ type: "COMPLAIN_SUBMIT_LOADER", complainSubmitLoader: false }),
        put({ type: "IMEI_NUMBER_BASED_GET_DETAILS_COMPLAIN", imeiNumbasedUserData: Table }), //userData
        put({ type: "COMPLAIN_DATA_SUCCESS_CODE", complainSuccessCode: true })
      ]);
      if (mailId) {
        const data = {
          id: mailId,
          enquiryId: _id,
          enquiryFor: enquiryFor,
          subCategory: subCategory
        };
        const res = yield call(updateMailByIdService, data);
        const { data: { status: stat = 0 } = {} } = res || {};
        if (stat === 200) {
          yield all([put({ type: "SET_UPDATED_MAIL_SUCCESS_CODE", updatedMailSuccessCode: true })]);
        }
      }
    } else {
      failToast(response?.data?.errorMessage || response?.data?.message || "something went wrong");
      yield all([
        put({ type: "COMPLAIN_DATA_SUCCESS_CODE", complainSuccessCode: false }),
        put({ type: "COMPLAIN_SUBMIT_LOADER", complainSubmitLoader: false })
      ]);
    }
  } catch (error) {
    yield put({ type: "COMPLAIN_SUBMIT_LOADER", complainSubmitLoader: false });
    yield put({ type: "COMPLAIN_DATA_SUCCESS_CODE", complainSuccessCode: false });
    failToast("Something went wrong");
  }
}

/**
 * @param  {object} obj
 * get details data from IMEI number based data
 */
function* imeiBasedGetDetailSaga(obj = {}): any {
  try {
    const response = yield call(getImeiNumberBasedServices, obj);
    const { status = 0, data: { Table = [] } = {} } = response || {};
    if (status === 200) {
      if (Table.length >= 1) {
        yield all([
          put({ type: "IMEI_NUMBER_BASED_GET_DETAILS_COMPLAIN", imeiNumbasedUserData: Table }), //userData
          put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER_COMPLAIN", imeiNumLoader: false }) //loaderOff
        ]);
      } else {
        infoToast(_get(response, "data[0].Status", "No Data Found"));
        yield all([
          put({ type: "IMEI_NUMBER_BASED_GET_DETAILS_COMPLAIN", imeiNumbasedUserData: [] }), //userData
          put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER_COMPLAIN", imeiNumLoader: false }) //loaderOff
        ]);
      }
    } else {
      yield all([
        put({ type: "IMEI_NUMBER_BASED_GET_DETAILS_COMPLAIN", imeiNumbasedUserData: [] }), //userData
        put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER_COMPLAIN", imeiNumLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "IMEI_NUMBER_BASED_GET_DETAILS_COMPLAIN", imeiNumbasedUserData: [] }), //userData
      put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER_COMPLAIN", imeiNumLoader: false }) //loaderOff
    ]);
  }
}

export default function* complainSaga() {
  yield takeLatest(complainType.UPDATE_COMPLAIN_DETAILS, updateComplainData);
  yield takeLatest(complainType.IMEI_NUMBER_BASED_GET_DETAILS, imeiBasedGetDetailSaga);
}
