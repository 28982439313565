/* eslint max-lines: 0 */

import React, { Suspense, useEffect } from "react";
import Loader from "../Loader";
import { pageNumber } from "helperRole";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "Component/ScreenRouting/ProtectedRoute";
import {
  PSOrders,
  CampaignForm,
  Transactions,
  EmployeeList,
  SalesOrder,
  PreRegister,
  NewCall,
  Home,
  NotifyMe,
  JustDial,
  CheckQC,
  WishList,
  ContactsDetails,
  SaveLater,
  CreatePassword,
  FeedbackContest,
  NonDealingTable,
  CustomerCallsList,
  ProductQRGenerator,
  EvaluationsPerPeriod,
  AgentResultAcceptance,
  TeamLeadsAuditReports,
  OnlineOrders,
  PageNotFound,
  CartList,
  Downloads,
  Setting,
  UserProfile,
  AdvocateList,
  ServiceCenters,
  CustomerService,
  SingleSignOn,
  UserLoggedInList,
  CampaignTransactions,
  AllEnquiryBasedEmpId,
  SupervisorAuditReports,
  AuditReports,
  ComplainList,
  FollowUp,
  AuditHistory,
  KudosByStaff,
  Evaluations,
  AllOrderList,
  ForgetPassword,
  MyAuditReports,
  ManageAuditors,
  AnonymCartList,
  GeneralReports,
  ScorecardEditor,
  QuestionAnalysis,
  GlobalAuditReports,
  SearchKeywordTerms,
  SalesReturnTable,
  SalesTable,
  RecentProductSearch,
  CustomerFeedback,
  MisDashboard,
  QRLoaderTable
} from "./ComponentPage";
import { useAuth } from "utils/UseKeyCloak";
import { _isEmpty } from "utils/lodashConst";
import EnquiryFeedback from "Pages/EnquiryFeedback";
import { failToast } from "Component/ToastServices/ToastDisplay";
import EvaluatorReports from "Pages/QualityScorecard/EvaluatorReports";
import TeamHigherHierarchy from "Pages/QualityScorecard/TeamHigherHierarchy";
import TLHierarchy from "Pages/QualityScorecard/TeamHigherHierarchy/TLHierarchy";
import { callSsoLogInFunction } from "Pages/PublicComponent/Login/commonLoginPage";

const ScreenRouter = () => {
  const keycloak: any = useAuth();
  const sessionData: any = sessionStorage.getItem("ssologin");
  const userLoginData: any = sessionStorage.getItem("userLoginData");
  const ssologin = JSON.parse(sessionData || "{}");
  const isAutho = sessionStorage.getItem("isAuthenticated");
  useEffect(() => {
    if (
      ssologin?.IsSSOAllow === true &&
      _isEmpty(userLoginData) &&
      isAutho !== "true" /*&& window.location.pathname !== '/home'*/
    ) {
      setTimeout(() => {
        sessionStorage.setItem("isAuthenticated", "true");
        keycloak?.login();
      }, 2000);
    }
  }, [ssologin?.IsSSOAllow]);

  useEffect(() => {
    if (!_isEmpty(keycloak?.user)) {
      if (keycloak && keycloak?.isAuthenticated)
        callSsoLogInFunction(keycloak?.token, keycloak?.user, ssologin?.isStoreUser);
      else failToast("You don't have access Permission of CRM");
    }
  }, [keycloak?.user]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/home/:token?"
          element={
            <ProtectedRoute
              component={Home}
              path="/home/:token?"
              componentName={"Home"}
              pageNumber={[pageNumber.Home]} //1
            />
          }
        />

        <Route
          path="/new-call/:token?"
          element={
            <ProtectedRoute
              path="/new-call/:token?"
              componentName={"new-call"}
              render={(props: any) => <NewCall {...props} />}
              pageNumber={[pageNumber.New_Call]} //2
              component={NewCall}
            />
          }
        />

        <Route
          path="/contacts/:token?"
          element={
            <ProtectedRoute
              path="/contacts/:token?"
              componentName={"contacts"}
              render={(props: any) => <ContactsDetails {...props} />}
              pageNumber={[pageNumber.Contacts_Contacts]} //62
              component={ContactsDetails}
            />
          }
        />
        <Route
          path="/pre-register"
          element={
            <ProtectedRoute
              path="/pre-register"
              componentName={"pre-register"}
              render={(props: any) => <PreRegister {...props} />}
              pageNumber={[pageNumber.PRE_REGISTER_BOOKING]} // 136
              component={PreRegister}
            />
          }
        />

        <Route
          path="/enquiry-feedback"
          element={
            <ProtectedRoute
              path="/enquiry-feedback"
              componentName={"enquiry-feedback"}
              render={(props: any) => <EnquiryFeedback {...props} />}
              pageNumber={[pageNumber.ENQUIRY_FEEDBACK]} // 138
              component={EnquiryFeedback}
            />
          }
        />

        <Route
          path="/profile/:token?"
          element={
            <ProtectedRoute
              path="/profile/:token?"
              componentName={"profile"}
              render={(props: any) => <UserProfile {...props} />}
              pageNumber={[6]} //all permisstion
              component={UserProfile}
            />
          }
        />

        <Route
          path="/advocate/:token?"
          element={
            <ProtectedRoute
              path="/advocate/:token?"
              componentName={"advocate"}
              render={(props: any) => <AdvocateList {...props} />}
              pageNumber={[pageNumber.Complaints_Advocates]} //29
              component={AdvocateList}
            />
          }
        />

        <Route
          path="/setting/:token?"
          element={
            <ProtectedRoute
              path="/setting/:token?"
              componentName={" "}
              render={(props: any) => <Setting {...props} />}
              pageNumber={[pageNumber.Super_Access_Setting]} //66
              component={Setting}
            />
          }
        />

        <Route
          path="/employee-list/:token?"
          element={
            <ProtectedRoute
              path="/employee-list/:token?"
              componentName={"employee"}
              render={(props: any) => <EmployeeList {...props} />}
              pageNumber={[pageNumber.Super_Access_Users]} //65
              component={EmployeeList}
            />
          }
        />

        <Route
          path="/emp-enquiry-details/:token?"
          element={
            <ProtectedRoute
              component={AllEnquiryBasedEmpId}
              path="/emp-enquiry-details/:token?"
              componentName={"emp-enquiry-details"}
              render={(props: any) => <AllEnquiryBasedEmpId {...props} />}
              pageNumber={[pageNumber.Employee_Enquiry_Details]} //6
            />
          }
        />

        <Route
          path="/notify-me"
          element={
            <ProtectedRoute
              path="/notify-me"
              componentName={"notify"}
              component={NotifyMe}
              render={(props: any) => <NotifyMe {...props} />}
              pageNumber={[pageNumber.NOTIFY_ME]} //89
            />
          }
        />

        <Route
          path="/call-score-card"
          element={
            <ProtectedRoute
              path="/call-score-card"
              componentName={"call-score-card"}
              component={ScorecardEditor}
              render={(props: any) => <ScorecardEditor {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Scorecard_Editor_Calls_Scorecard]} //48
            />
          }
        />

        <Route
          path="/chat-score-card"
          element={
            <ProtectedRoute
              path="/chat-score-card"
              componentName={"chat-score-card"}
              component={ScorecardEditor}
              render={(props: any) => <ScorecardEditor {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Scorecard_Editor_Chat_Scorecard]} //49
            />
          }
        />

        <Route
          path="/campaigns/:token?"
          element={
            <ProtectedRoute
              path="/campaigns/:token?"
              componentName={"campaigns"}
              component={CampaignForm}
              render={(props: any) => <CampaignForm {...props} />}
              pageNumber={[pageNumber.Campaigns]} //31
            />
          }
        />

        <Route
          path="/campaignTransactions"
          element={
            <ProtectedRoute
              path="/campaignTransactions"
              componentName={"campaignTransactions"}
              component={CampaignTransactions}
              render={(props: any) => <CampaignTransactions {...props} />}
              pageNumber={[pageNumber.Campaign_Transactions]} //61
            />
          }
        />

        <Route
          path="/non-dealing/:token?"
          element={
            <ProtectedRoute
              path="/non-dealing/:token?"
              componentName={"non-dealing"}
              component={NonDealingTable}
              render={(props: any) => <NonDealingTable {...props} />}
              pageNumber={[pageNumber.Non_ealing]} //30
            />
          }
        />
        <Route
          path="/question-analysis"
          element={
            <ProtectedRoute
              path="/question-analysis"
              componentName={"/question-analysis"}
              component={QuestionAnalysis}
              render={(props: any) => <QuestionAnalysis {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Question_Analysis]} //56
            />
          }
        />

        <Route
          path="/audit-history/:scoreId"
          element={
            <ProtectedRoute
              path="/audit-history/:scoreId"
              componentName={"audit-history"}
              component={AuditHistory}
              render={(props: any) => <AuditHistory {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Audit_History]} //54
            />
          }
        />

        <Route
          path="/audit-history/:scoreId/:type"
          element={
            <ProtectedRoute
              path="/audit-history/:scoreId/:type"
              component={AuditHistory}
              componentName={"audit-history-scoreId"}
              render={(props: any) => <AuditHistory {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Audit_History]} //54
            />
          }
        />

        <Route
          path="/audit-history"
          element={
            <ProtectedRoute
              path="/audit-history"
              componentName={"audit-history"}
              component={AuditHistory}
              render={(props: any) => <AuditHistory {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Audit_History]} //54
            />
          }
        />

        <Route
          path="/evaluations"
          element={
            <ProtectedRoute
              path="/evaluations"
              componentName={"evaluations"}
              component={Evaluations}
              render={(props: any) => <Evaluations {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Evaluations]} //55
            />
          }
        />

        <Route
          path="/evaluations-per-period"
          element={
            <ProtectedRoute
              path="/evaluations-per-period"
              componentName={"evaluations-per-period"}
              component={EvaluationsPerPeriod}
              render={(props: any) => <EvaluationsPerPeriod {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Evaluations_Per_Period]} //57
            />
          }
        />

        <Route
          path="/agent-result-acceptance"
          element={
            <ProtectedRoute
              path="/agent-result-acceptance"
              componentName={"agent-result-acceptance"}
              component={AgentResultAcceptance}
              render={(props: any) => <AgentResultAcceptance {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Result_Acceptance_By_Agent]} //58
            />
          }
        />

        <Route
          path="/kudos-by-staff"
          element={
            <ProtectedRoute
              path="/kudos-by-staff"
              componentName={"kudos-by-staff"}
              component={KudosByStaff}
              render={(props: any) => <KudosByStaff {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_Kudos_By_Staff]} //59
            />
          }
        />

        <Route
          path="/general-reports"
          element={
            <ProtectedRoute
              path="/general-reports"
              componentName={"general-reports"}
              component={GeneralReports}
              render={(props: any) => <GeneralReports {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Reports_General_Reports]} //53
            />
          }
        />

        <Route
          path="/evaluator-reports"
          element={
            <ProtectedRoute
              path="/evaluator-reports"
              componentName={"evaluator-reports"}
              component={EvaluatorReports}
              render={(props: any) => <EvaluatorReports {...props} />}
              pageNumber={[pageNumber.EVALUATOR_REPORTS]} //148
            />
          }
        />

        <Route
          path="/chat-higher-hierarchy"
          element={
            <ProtectedRoute
              path="/chat-higher-hierarchy"
              componentName={"chat-higher-hierarchy"}
              component={TeamHigherHierarchy}
              render={(props: any) => <TeamHigherHierarchy {...props} />}
              pageNumber={[pageNumber.CHAT_HIGHER_HIERARCHY]} //150
            />
          }
        />

        <Route
          path="/call-higher-hierarchy"
          element={
            <ProtectedRoute
              path="/call-higher-hierarchy"
              componentName={"call-higher-hierarchy"}
              component={TeamHigherHierarchy}
              render={(props: any) => <TeamHigherHierarchy {...props} />}
              pageNumber={[pageNumber.CALLS_HIGHER_HIERARCHY]} //151
            />
          }
        />

        <Route
          path="/tl-hierarchy"
          element={
            <ProtectedRoute
              path="/tl-hierarchy"
              componentName={"tl-hierarchy"}
              component={TLHierarchy}
              render={(props: any) => <TLHierarchy {...props} />}
              pageNumber={[pageNumber.TL_HIGHER_HIERARCHY]} //152
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-all/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-all/:token?"
              componentName={"service-calls-service-product-service-all"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_ALL]} //13
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-new/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-new/:token?"
              componentName={"service-calls-service-product-service-new"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_New]} //14
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-in-transit/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-in-transit/:token?"
              componentName={"service-calls-service-product-service-in-transit"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_In_Transit]} //15
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-received-from-store/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-received-from-store/:token?"
              componentName={"service-calls-service-product-service-received-from-store"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Received_From_Store]} //16
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-pending-at-service-center/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-pending-at-service-center/:token?"
              componentName={"service-calls-service-product-service-pending-at-service-center"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Pending_At_Service_Center]} //17
            />
          }
        />

        <Route
          path="/service-calls-received-from-service-center/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-received-from-service-center/:token?"
              componentName={"Home"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Received_From_Service_Center]} //73
            />
          }
        />

        <Route
          path="/checkQC/:token?"
          element={
            <ProtectedRoute
              path="/checkQC/:token?"
              componentName={"checkQC"}
              component={CheckQC}
              render={(props: any) => <CheckQC {...props} />}
              pageNumber={[pageNumber.CHECK_QC]} //81
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-pending-to-send-store/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-pending-to-send-store/:token?"
              componentName={"service-calls-service-product-service-pending-to-send-store"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Pending_To_Send_Store]} //18
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-received-from-ho/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-received-from-ho/:token?"
              componentName={"service-calls-service-product-service-received-from-ho"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Received_From_HO]} //19
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-pending-to-inform-customer/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-pending-to-inform-customer/:token?"
              componentName={"service-calls-service-product-service-pending-to-inform-customer"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Pending_To_Inform_Customer]} //20
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-informed-customer/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-informed-customer/:token?"
              componentName={"service-calls-service-product-service-informed-customer"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Informed_Customer]} //21
            />
          }
        />

        <Route
          path="/service-calls-service-product-service-delivered/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-product-service-delivered/:token?"
              componentName={"service-calls-service-product-service-delivered"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Product_Service_Delivered]} //22
            />
          }
        />

        <Route
          path="/service-calls-service-service-support/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-service-support/:token?"
              componentName={"service-calls-service-service-support"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Service_Support]} //23
            />
          }
        />

        <Route
          path="/service-calls-service-duplicate-invoice/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-duplicate-invoice/:token?"
              componentName={"service-calls-service-duplicate-invoice"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_Duplicate_Invoice]} //24
            />
          }
        />

        <Route
          path="/service-calls-service-general-information/:token?"
          element={
            <ProtectedRoute
              path="/service-calls-service-general-information/:token?"
              componentName={"service-calls-service-general-information"}
              component={CustomerService}
              render={(props: any) => <CustomerService {...props} />}
              pageNumber={[pageNumber.Service_General_Information]} //25
            />
          }
        />

        <Route
          path="/customer-calls-list/:token?"
          element={
            <ProtectedRoute
              component={CustomerCallsList}
              path="/customer-calls-list/:token?"
              componentName={"customer-calls-list"}
              render={(props: any) => <CustomerCallsList {...props} />}
              pageNumber={[pageNumber.Call_History_All_Calls]} //4
            />
          }
        />

        <Route
          path="/my-follow-up/:token?"
          element={
            <ProtectedRoute
              component={CustomerCallsList}
              path="/my-follow-up/:token?"
              componentName={"my-follow-up"}
              render={(props: any) => <CustomerCallsList {...props} />}
              pageNumber={[pageNumber.Call_History_All_Follow]} //5
            />
          }
        />

        <Route
          path="/all-orders-missing-orders/:token?"
          element={
            <ProtectedRoute
              component={AllOrderList}
              path="/all-orders-missing-orders/:token?"
              componentName={"all-orders-missing-orders"}
              render={(props: any) => <AllOrderList {...props} />}
              pageNumber={[pageNumber.All_Orders_All_Orders]} //34
            />
          }
        />

        <Route
          path="/all-orders-cart/:token?"
          element={
            <ProtectedRoute
              component={CartList}
              path="/all-orders-cart/:token?"
              componentName={"all-orders-cart"}
              render={(props: any) => <CartList {...props} />}
              pageNumber={[pageNumber.ALL_ORDERS_CART]} //87
            />
          }
        />

        <Route
          path="/all-orders-anonym-carts/:token?"
          element={
            <ProtectedRoute
              component={AnonymCartList}
              path="/all-orders-anonym-carts/:token?"
              componentName={"all-orders-anonym-carts"}
              render={(props: any) => <AnonymCartList {...props} />}
              pageNumber={[pageNumber.ALL_ORDERS_ANONYMOUS_CART]} //90
            />
          }
        />

        <Route
          path="/product-qr-generator/:token?"
          element={
            <ProtectedRoute
              component={ProductQRGenerator}
              path="/product-qr-generator/:token?"
              componentName={"product-qr-generator"}
              render={(props: any) => <ProductQRGenerator {...props} />}
              pageNumber={[pageNumber.PRODUCT_QR_GENERATOR]} //92
            />
          }
        />

        <Route
          path="/product-qr-table/:token?"
          element={
            <ProtectedRoute
              component={QRLoaderTable}
              path="/product-qr-table/:token?"
              componentName={"product-qr-table"}
              render={(props: any) => <QRLoaderTable {...props} />}
              pageNumber={[pageNumber.QR_TABLE]} //158
            />
          }
        />

        <Route
          path="/all-orders-wish-list/:token?"
          element={
            <ProtectedRoute
              component={WishList}
              path="/all-orders-wish-list/:token?"
              componentName={"all-orders-wish-list"}
              render={(props: any) => <WishList {...props} />}
              pageNumber={[pageNumber.ALL_ORDERS_WISH_LIST]} //88
            />
          }
        />

        <Route
          path="/recent-product-keyword"
          element={
            <ProtectedRoute
              component={RecentProductSearch}
              path="/recent-product-keyword"
              componentName={"recent-product-keyword"}
              render={(props: any) => <RecentProductSearch {...props} />}
              pageNumber={[pageNumber.RECENT_PRODUCT_SEARCH]} //134
            />
          }
        />

        <Route
          path="/search-keyword"
          element={
            <ProtectedRoute
              component={SearchKeywordTerms}
              path="/search-keyword"
              componentName={"search-keyword"}
              render={(props: any) => <SearchKeywordTerms {...props} />}
              pageNumber={[pageNumber.SEARCH_KEYWORD]} //88
            />
          }
        />

        <Route
          path="/all-order-ps-order/:token?"
          element={
            <ProtectedRoute
              component={PSOrders}
              path="/all-order-ps-order/:token?"
              componentName={"all-order-ps-order"}
              render={(props: any) => <PSOrders {...props} />}
              pageNumber={[pageNumber.ALL_ORDERS_PS_ORDERS]} //86
            />
          }
        />

        <Route
          path="/save-later/:token?"
          element={
            <ProtectedRoute
              component={SaveLater}
              path="/save-later/:token?"
              componentName={"save-later"}
              render={(props: any) => <PSOrders {...props} />}
              pageNumber={[pageNumber.SAVE_LATER]} //135
            />
          }
        />

        <Route
          path="/customer-complain-list/:token?"
          element={
            <ProtectedRoute
              path="/customer-complain-list/:token?"
              componentName={"customer-complain-list"}
              render={(props: any) => <ComplainList {...props} />}
              pageNumber={[pageNumber.Complaints_Evaluate_Complaints]} //27
              component={ComplainList}
            />
          }
        />

        <Route
          path="/sales-return-list/:token?"
          element={
            <ProtectedRoute
              component={SalesReturnTable}
              path="/sales-return-list/:token?"
              componentName={"sales-return-list"}
              render={(props: any) => <SalesReturnTable {...props} />}
              pageNumber={[pageNumber.Sales_Return]} //7
            />
          }
        />

        <Route
          path="/sales-enquiries/:token?"
          element={
            <ProtectedRoute
              component={SalesTable}
              path="/sales-enquiries/:token?"
              componentName={"sales-enquiries"}
              pageNumber={[pageNumber.Sales_Sales_Enquiries]} //9
              render={(props: any) => <SalesTable {...props} />}
            />
          }
        />

        <Route
          path="/sales-follow-up/:token?"
          element={
            <ProtectedRoute
              component={SalesTable}
              path="/sales-follow-up/:token?"
              componentName={"sales-follow-up"}
              pageNumber={[pageNumber.Sales_Follow_Up]} //10
              render={(props: any) => <SalesTable {...props} />}
            />
          }
        />

        <Route
          path="/customer-complain-list/followup"
          element={
            <ProtectedRoute
              component={FollowUp}
              path="/customer-complain-list/followup"
              componentName={"customer-complain"}
              render={(props: any) => <FollowUp {...props} />}
              pageNumber={[pageNumber.Complaints_Followup_and_Closures]} //28
            />
          }
        />

        <Route
          path="/transactions"
          element={
            <ProtectedRoute
              path="/transactions"
              component={Transactions}
              componentName={"transactions"}
              render={(props: any) => <Transactions {...props} />}
              pageNumber={[pageNumber.Transactions]} //60
            />
          }
        />

        <Route
          path="/just-dial"
          element={
            <ProtectedRoute
              path="/just-dial"
              component={JustDial}
              componentName={"just-dial"}
              render={(props: any) => <JustDial {...props} />}
              pageNumber={[pageNumber.Just_Dial]} //35
            />
          }
        />

        <Route
          path="/feedback-contest"
          element={
            <ProtectedRoute
              path="/feedback-contest"
              component={FeedbackContest}
              componentName={"just-dial"}
              render={(props: any) => <FeedbackContest {...props} />}
              pageNumber={[pageNumber.Feedback_Contest]} //38
            />
          }
        />

        <Route
          path="/downloads/:token?"
          element={
            <ProtectedRoute
              component={Downloads}
              path="/downloads/:token?"
              componentName={"downloads"}
              render={(props: any) => <Downloads {...props} />}
              pageNumber={[pageNumber.DOWNLOAD]} //93
            />
          }
        />

        <Route
          path="/user-loggedin-list/:token?"
          element={
            <ProtectedRoute
              component={UserLoggedInList}
              path="/user-loggedin-list/:token?"
              componentName={"User LoggedIn"}
              render={(props: any) => <UserLoggedInList {...props} />}
              pageNumber={[pageNumber.DOWNLOAD]} //93
            />
          }
        />

        <Route
          path="/audit-reports"
          element={
            <ProtectedRoute
              path="/audit-reports"
              component={AuditReports}
              componentName={"audit-reports"}
              render={(props: any) => <AuditReports {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Audit_Scorecard_Scorecard_List]} //51
            />
          }
        />

        <Route
          path="/service-centers"
          element={
            <ProtectedRoute
              path="/service-centers"
              component={ServiceCenters}
              componentName={"service-centers"}
              render={(props: any) => <ServiceCenters {...props} />}
              pageNumber={[pageNumber.SERVICE_CENTERS]} //36
            />
          }
        />

        <Route
          path="/online-orders"
          element={
            <ProtectedRoute
              path="/online-orders"
              component={OnlineOrders}
              componentName={"online-orders"}
              render={(props: any) => <OnlineOrders {...props} />}
              pageNumber={[pageNumber.Online_Queries]} //32
            />
          }
        />

        <Route
          path="/my-audit-reports"
          element={
            <ProtectedRoute
              path="/my-audit-reports"
              component={MyAuditReports}
              componentName={"my-audit-reports"}
              render={(props: any) => <MyAuditReports {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Dashboard_Agent_Dashboard]} //45
            />
          }
        />

        <Route
          path="/manager-dashboard"
          element={
            <ProtectedRoute
              path="/manager-dashboard"
              component={SupervisorAuditReports}
              componentName={"manager-dashboard"}
              render={(props: any) => <SupervisorAuditReports {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Dashboard_Manger_Dashboard]} //43
            />
          }
        />

        <Route
          path="/team-lead-dashboard"
          element={
            <ProtectedRoute
              path="/team-lead-dashboard"
              component={TeamLeadsAuditReports}
              componentName={"team-lead-dashboard"}
              render={(props: any) => <TeamLeadsAuditReports {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Dashboard_TeamLead_Dashboard]} //44
            />
          }
        />

        <Route
          path="/global-dashboard"
          element={
            <ProtectedRoute
              path="/global-dashboard"
              component={GlobalAuditReports}
              componentName={"global-dashboard"}
              render={(props: any) => <GlobalAuditReports {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Dashboard_Global_Dashboard]} //42
            />
          }
        />

        <Route
          path="/evaluator-dashboard"
          element={
            <ProtectedRoute
              component={ManageAuditors}
              path="/evaluator-dashboard"
              componentName={"evaluator-dashboard"}
              render={(props: any) => <ManageAuditors {...props} />}
              pageNumber={[pageNumber.Quality_Scorecard_Dashboard_Evaluator_Dashboard]} //46
            />
          }
        />
        <Route
          path="/sales-order"
          element={
            <ProtectedRoute
              path="/sales-order"
              component={SalesOrder}
              componentName={"sales-order"}
              render={(props: any) => <SalesOrder {...props} />}
              pageNumber={[pageNumber.SALES_ORDER]} //140
            />
          }
        />
        <Route
          path="/mis-dashboard"
          element={
            <ProtectedRoute
              path="/mis-dashboard"
              component={MisDashboard}
              componentName={"mis-dashboard"}
              render={(props: any) => <SalesOrder {...props} />}
              pageNumber={[pageNumber.MIS_DASHBOARD]} //155s
            />
          }
        />

        <Route path="/customer-feedback" element={<CustomerFeedback />} />
        <Route path="/sson" element={<SingleSignOn />} />
        <Route path="/not-found" element={<PageNotFound />} />
        <Route path="/forgetPassword" element={<ForgetPassword />} />
        <Route path="/createPassword" element={<CreatePassword />} />
        <Route path="/resetPassword/:token?" element={<CreatePassword />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      {/* <IdleTimeOut /> */}
    </Suspense>
  );
};

export default ScreenRouter;
