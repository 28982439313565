import NoDataFoundPage from "Component/NoDataFoundPage";
import React, { useState, useEffect } from "react";
import { TableClass, tdClassStyle2, thClassStyle2 } from "utils/ClassContent";
import { fetchBasicInfoHistory } from "utils/services/basicInfoService";
import { convertToLowerCase, dateTimeDisplay } from "utils/validation";

const ActionHistory = (props: any = {}) => {
  let sno = 0;
  const { customerId = "" } = props;
  const [historyData, setHistoryData] = useState<any>({});

  const getBasicInfoHistory = async () => {
    const response: any = await fetchBasicInfoHistory(customerId);
    const { status = 0, data = {} } = response;
    if (status === 200) {
      setHistoryData(data?.data);
    }
  };

  useEffect(() => {
    getBasicInfoHistory();
  }, []);
  return (
    <div
      className="flex flex-col w-full"
      style={{
        minHeight: "250px",
        height: "100%",
        maxHeight: "calc(100vh - 48px)"
      }}
    >
      <div className="block flex-grow w-full overflow-x-auto relative">
        <table className={TableClass}>
          <thead>
            <tr className="">
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> S.No</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Employee Id</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Field Name</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Old Value</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> New Value</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Updated Date & Time</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Updated By</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {historyData?.history?.length > 0 ? (
              (historyData?.history || []).map((item: any, i: number) => {
                const { changes = [], actionBy = {} } = item;
                const { empId = "", name = "", date = "" } = actionBy;
                return changes.map((value: any, index: number) => {
                  const { fieldName = "", newValue = "", oldValue = "" } = value;
                  sno += 1;
                  if (sno > 10) {
                    return null;
                  }
                  return (
                    <tr key={convertToLowerCase(i + "cusTransHistory-dfd" + index)}>
                      <td className={tdClassStyle2}>
                        <span>{sno}</span>
                      </td>
                      <td className={tdClassStyle2}>
                        <span>{empId}</span>
                      </td>
                      <td className={tdClassStyle2}>{fieldName}</td>
                      <td className={tdClassStyle2}>{oldValue ? oldValue : <div className="text-center">-</div>}</td>
                      <td className={tdClassStyle2}>{newValue ? newValue : <div className="text-center">-</div>}</td>
                      <td className={tdClassStyle2}>{dateTimeDisplay(date)}</td>
                      <td className={tdClassStyle2}>{name}</td>
                    </tr>
                  );
                });
              })
            ) : (
              <NoDataFoundPage colSpan={6} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ActionHistory;
