import React, { useEffect, useState } from "react";
import { moment } from "utils/momentConst";
import CommonSearchDropdown from "./CommonSearchDropdown";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import { getRegisterUserDetailService } from "utils/services/loginServices";
import DatePickerCommon from "../CommonTable/CommonStartToEndDatePic/DatePickerCommon";

const CommonFilterDatePicker = (props: any = {}) => {
  const { handleDateSelect = () => {}, temphide = false } = props || {};
  const [dateLabel, setDateLabel] = useState("");
  const [getDateOption, setDateOption]: any = useState({
    name: "minDate",
    value: null,
    nameEdate: "maxDate",
    nameEvalue: null,
    startDate: "",
    endDate: ""
  });
  const [customerDate, setCustomDate] = useState(false);

  const [userDropProps, setUserDropProps] = useState([]);
  const DateOption = [
    { id: 4, isSelect: false, optionData: "Last 30 Days" },
    { id: 6, isSelect: false, optionData: "Last Month" },
    { id: 7, isSelect: false, optionData: "Last 3 Months" },
    { id: 8, isSelect: false, optionData: "Last 6 Months" },
    { id: 9, isSelect: false, optionData: "Custom Range" }
  ];

  const defaultDateOption = () => {
    const data = {
      name: "minDate",
      nameEdate: "maxDate",
      value: moment().subtract(30, "d").format("YYYY-MM-DD"),
      nameEvalue: moment().format("YYYY-MM-DD"),
      startDate: "",
      endDate: ""
    };
    setDateLabel("Last 30 Days");
    setDateOption({ ...data });
    handleDateSelect({
      target: {
        ...data
      }
    });
  };

  // Sub Category
  const userIdFiter = (event: any = {}) => {
    const { originalArray = [] } = event;
    const filterCheck = originalArray.filter((ele: any) => ele.isCheck === true);
    const getId = filterCheck.map((ele: any) => ele._id);
    setUserDropProps(originalArray);
    setDateOption((ev: any = {}) => ({ ...ev, userId: getId }));
    handleDateSelect({ ...getDateOption, userId: getId });
  };

  const _onClearDate = () => {
    setCustomDate(false);
    defaultDateOption();
  };

  const handleDateDrop = (data: any) => {
    let startDate = "";
    let endDate = "";
    switch (data.optionData) {
      case "Today":
        startDate = moment(Date.now()).format("YYYY-MM-DD");
        endDate = moment(Date.now()).endOf("day").format("YYYY-MM-DD");
        break;
      case "Yesterday":
        startDate = moment().subtract(1, "d").format("YYYY-MM-DD");
        endDate = moment().subtract(1, "d").endOf("day").format("YYYY-MM-DD");
        break;
      case "Last 7 Days":
        startDate = moment().subtract(1, "w").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 30 Days":
        startDate = moment().subtract(30, "d").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "This Month":
        startDate = moment().startOf("month").format("YYYY-MM-DD");
        endDate = moment().endOf("month").format("YYYY-MM-DD");
        break;
      case "Last Month":
        startDate = moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD");
        endDate = moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD");
        break;
      case "Last 3 Months":
        startDate = moment().subtract(90, "d").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Last 6 Months":
        startDate = moment().subtract(180, "d").format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
        break;
      case "Custom Range":
        setCustomDate(true);
        startDate = "";
        endDate = "";
        break;
      default:
        setCustomDate(true);
        break;
    }
    setDateLabel(data.optionData);
    setDateOption({
      ...getDateOption,
      value: startDate,
      nameEvalue: endDate
    });
    setTimeout(() => {
      handleDateSelect({ target: { ...getDateOption, value: startDate, nameEvalue: endDate } });
    }, 10);
  };

  /**
   * handle cusomt date select
   */
  const handleCUstomDate = (event: any = {}) => {
    const { target: { value = null, nameEvalue = null } = {} } = event;
    setDateOption({
      ...getDateOption,
      nameEvalue,
      value,
      endDate: nameEvalue,
      startDate: value
    });
    handleDateSelect({
      target: {
        name: "minDate",
        nameEdate: "maxDate",
        ...(nameEvalue && { nameEvalue: moment(nameEvalue).format("YYYY-MM-DD") }),
        ...(value && { value: moment(value).format("YYYY-MM-DD") })
      }
    });
  };

  useEffect(() => defaultDateOption(), []);

  useEffect(() => {
    getRegisterUserDetailService({ data: { size: 1000, ignoreCurrentUserCheck: "t" } }).then((res: any = {}) => {
      const { data: { data: { data = [] } = {} } = {} } = res;
      setUserDropProps(
        data.map((itm: any = {}) => ({
          isCheck: false,
          _id: itm.empId,
          itemName: itm.name
        }))
      );
    });
  }, [getRegisterUserDetailService]);
  return (
    <>
      {customerDate ? (
        <div className="flex items-center mr-2">
          <DatePickerCommon
            dateName={""}
            labelTextClass="font-bold"
            userSearch={getDateOption}
            _onDateChange={handleCUstomDate}
            _onClearDate={_onClearDate}
          />
        </div>
      ) : (
        <CommonSearchDropdown
          parentClass={" flex flex-col w-44 "}
          minSearchCriteria={20}
          label={false}
          setShow={true}
          mustField={true}
          fontSize={"text-xs"}
          placeholder="Select Date Range"
          value={dateLabel}
          name={"DateOption"}
          inputWithIcon={false}
          arrayData={DateOption}
          clearInput={defaultDateOption}
          inputWithIconClass="w-12"
          inputFieldClass="h-8 pr-7 text-xs"
          labelTextClass={"font-bold"}
          inputClass="flex relative h-8"
          labelText={"Date Filter"}
          handleParentDropVal={handleDateDrop}
        />
      )}

      {temphide && (
        <MultiDropDownNew
          name={"userId"}
          labeName={"Staff"}
          isSelectAll={true}
          enableCheckbox={true}
          isOKButtonEnable={true}
          arrayData={userDropProps}
          filterArrayPass={userIdFiter}
        />
      )}
    </>
  );
};

export default CommonFilterDatePicker;
