import LazyImage from "Component/LazyImage";
import React from "react";
import { _get } from "utils/lodashConst";
import { convertToLowerCase, firstLetterUpperCase, nullToObject } from "utils/validation";
import ProductColorSlider from "../ProductColorSlider";

const ProductColor = (props: any = {}) => {
  const {
    fromPage = "",
    productDetails = {},
    getProductgetProductDetailsByCategoryCall = () => {}
  } = nullToObject(props);

  const variantColor = _get(productDetails, "model.variant_attr_scheme", []);

  const findColor = variantColor
    .map((ele: any) => {
      if (ele.code === "color") {
        return {
          ...ele,
          values: ele.values.filter(
            (eleData: any) => _get(eleData, "code", "") === _get(productDetails, "variant.attr.color", "")
          )
        };
      }
      return undefined;
    })
    .filter((ele: any) => ele);
  const variant_attr_scheme = fromPage === "viewOnly" ? findColor : variantColor;

  /**
   * pass element
   * destcute color and storage
   * find exact index value and pass slug
   * @param slug
   */
  const getProductgetProductDetailsColor = (element: any = {}, fromKey = "") => {
    const { code = "" } = element;
    const findObj: any = productDetails?.model?.variants?.find((ele: any) => ele.attr[fromKey] === code);
    const newObj = {
      ...findObj,
      item_code: findObj?.erp_item_code || ""
    };
    getProductgetProductDetailsByCategoryCall(newObj);
  };

  return _get(variant_attr_scheme, "length", 0) > 0 ? (
    <>
      {variant_attr_scheme.map((ele: any, i: number) => {
        return (
          <React.Fragment key={convertToLowerCase(i + "variant_attr_scheme-jdm-jd")}>
            {convertToLowerCase(ele.code) === "color" ? (
              <>
                <span className=" mb-2 text-sm">{firstLetterUpperCase(ele.code)}</span>
                <ul className="flex  max-w-xl overflow-x-auto overflow-y-hidden">
                  <ProductColorSlider>
                    {ele.values.map((eleData: any, index: number) => (
                      <li tabIndex={0} itemID={`id-${index}`} key={convertToLowerCase(index + "values-efd")}>
                        <button
                          onClick={() => getProductgetProductDetailsColor(eleData, ele.code)}
                          data-jest-id={"jestGetProductgetProductDetailsColor"}
                          type="button"
                          className={`bg-gray-100 border-2 border-transparent flex flex-col m-2 p-1 hover:border-blue-400 
                                                    ${
                                                      _get(productDetails, "variant.attr.color", "") ===
                                                      _get(eleData, "code", "")
                                                        ? "border-yellow-400 "
                                                        : ""
                                                    }`}
                        >
                          <div className="w-12 h-12 mb-1 mx-auto">
                            <LazyImage
                              alt={_get(eleData, `image`, "")}
                              src={_get(eleData, `image`, "")}
                              className="w-full h-full object-contain mx-auto"
                            />
                          </div>
                          <span className="text-xs text-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                            {firstLetterUpperCase(_get(eleData, `code`, ""))}
                          </span>
                        </button>
                      </li>
                    ))}
                  </ProductColorSlider>
                </ul>
              </>
            ) : null}
          </React.Fragment>
        );
      })}
    </>
  ) : null;
};

export default ProductColor;
