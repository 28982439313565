import { encryptData } from "utils/secureFile";
import { put, call, takeLatest, all } from "redux-saga/effects";
import * as salesReturnType from "../actionTypes/salesReturnType";
import {
  getservicesCenterListServices,
  getservicesCenterTokenServices,
  sendServiceCenterReportEmailService,
  getserviceCenterListServices,
  getserviceCenterRefreshServices,
  serviceCenterCreateService,
  deleteServiceCenterServices
} from "utils/services/serviceCenterServices";
import { failToast, infoToast, successToast } from "Component/ToastServices/ToastDisplay";

/**
 * @param  {object} obj
 * get service center Token
 */
function* getServicesCenterToken(): any {
  try {
    const response = yield call(getservicesCenterTokenServices);
    const { status = 0, data: { data: { access_token: serviceCenterToken = "" } = {} } = {} } = response || {};
    if (status === 200) {
      encryptData("serviceCenterToken", serviceCenterToken); //tokenEncryptAndStore
      yield all([
        put({
          type: "SERVICE_CENTER_TOKEN_GET_LOADER",
          servicesCenterTokenLoader: false
        })
      ]);
    } else {
      encryptData("serviceCenterToken", "");
      yield all([
        put({
          type: "SERVICE_CENTER_TOKEN_GET_LOADER",
          servicesCenterTokenLoader: false
        })
      ]);
    }
  } catch (error) {
    encryptData("serviceCenterToken", "");
    yield all([
      put({
        type: "SERVICE_CENTER_TOKEN_GET_LOADER",
        servicesCenterTokenLoader: false
      })
    ]);
  }
}

/**
 * @param  {object} obj
 * get service center details
 */
function* getServicesCenterList(obj: object = {}): any {
  try {
    const response = yield call(getservicesCenterListServices, obj);
    const { status = 0, data: { data: serviceCenterList = [] } = {} } = response || {};
    const dataOptionAdd = serviceCenterList.map((ele: any) => {
      return {
        ...ele,
        brand: ele.brand.toLowerCase(),
        optionData: ele.name + "," + ele.state
      };
    });
    if (status === 200) {
      yield all([
        put({ type: "SERVICE_CENTER_GET_LOADER", servicesCenterLoader: false }), //loader
        put({
          type: "SERVICE_CENTER_LIST_DATA_STORE",
          serviceCenterDataList: dataOptionAdd
        }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "SERVICE_CENTER_GET_LOADER", servicesCenterLoader: false }), //loader
        put({
          type: "SERVICE_CENTER_LIST_DATA_STORE",
          serviceCenterDataList: []
        }) //data
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "SERVICE_CENTER_GET_LOADER", servicesCenterLoader: false }), //loader
      put({
        type: "SERVICE_CENTER_LIST_DATA_STORE",
        serviceCenterDataList: []
      }) //data
    ]);
  }
}

/**
 * @param  {object} obj
 * get service center details
 */
function* getServiceCenterList(obj: object = {}): any {
  try {
    const response = yield call(getserviceCenterListServices, obj);
    const { data: { data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({
          type: "GET_SERVICE_CENTER_LIST_LOADER",
          serviceCenterLoader: false
        }), //loader
        put({ type: "SERVICE_CENTER_LIST_DATA", serviceCenterListAll: data }) //loaderOff
      ]);
    } else {
      yield all([
        put({
          type: "GET_SERVICE_CENTER_LIST_LOADER",
          serviceCenterLoader: false
        }), //loader
        put({ type: "SERVICE_CENTER_LIST_DATA", serviceCenterListAll: [] }) //data
      ]);
    }
  } catch (error) {
    yield all([
      put({
        type: "GET_SERVICE_CENTER_LIST_LOADER",
        serviceCenterLoader: false
      }), //loader
      put({ type: "SERVICE_CENTER_LIST_DATA", serviceCenterListAll: [] }) //data
    ]);
  }
}

/**
 * @param  {object} obj
 * get service center details
 */
function* getServiceCenterRefresh(obj: object = {}): any {
  try {
    const response = yield call(getserviceCenterRefreshServices, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "SERVICE_CENTER_REFRESH", serviceCenterRefresh: true }), //loader
        put({ type: "GET_SERVICE_CENTER_LIST_LOADER", serviceCenterLoader: false }) //loader
      ]);
      infoToast(message || "Updated service center list");
    } else {
      failToast(message || "Unable to update at this moment");
      yield all([
        put({ type: "SERVICE_CENTER_REFRESH", serviceCenterRefresh: true }), //loader
        put({ type: "GET_SERVICE_CENTER_LIST_LOADER", serviceCenterLoader: false }) //loader
      ]);
    }
  } catch (error) {
    failToast("Something went wrong");
    yield all([
      put({ type: "SERVICE_CENTER_REFRESH", serviceCenterRefresh: true }), //loader
      put({ type: "GET_SERVICE_CENTER_LIST_LOADER", serviceCenterLoader: false }) //loader
    ]);
  }
}

/**
 * send email
 */
function* sendCenterReportEmail(obj: object = {}): any {
  try {
    const response = yield call(sendServiceCenterReportEmailService, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 201) {
      successToast(message || "Email sent Successfully");
      yield all([
        put({
          type: "SET_SERVICE_CENTER_MAIL_SUCCESS",
          serviceCenterMailSendSuccess: true
        }),
        put({
          type: "SET_SERVICE_CENTER_MAIL_LOADER",
          serviceCenterMailLoader: false
        })
      ]);
    } else {
      failToast(message);
      yield all([
        put({
          type: "SET_SERVICE_CENTER_MAIL_SUCCESS",
          serviceCenterMailSendSuccess: false
        }),
        put({
          type: "SET_SERVICE_CENTER_MAIL_LOADER",
          serviceCenterMailLoader: false
        })
      ]);
    }
  } catch (error) {
    failToast("Error in sending email");
    yield all([
      put({
        type: "SET_SERVICE_CENTER_MAIL_SUCCESS",
        serviceCenterMailSendSuccess: false
      }),
      put({
        type: "SET_SERVICE_CENTER_MAIL_LOADER",
        serviceCenterMailLoader: false
      })
    ]);
  }
}

/**
 * service center create
 */
function* sendCenterCreate(obj: object = {}): any {
  try {
    const response = yield call(serviceCenterCreateService, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 201) {
      successToast(message || "Service center added successfully");
      yield all([
        put({ type: "SERVICE_CENTER_CREATE_SUCCESS", serviceCenterSuccess: true }),
        put({ type: "SERVICE_CENTER_CREATE_LOADER", serviceCenterCreateLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "SERVICE_CENTER_CREATE_SUCCESS", serviceCenterSuccess: false }),
        put({ type: "SERVICE_CENTER_CREATE_LOADER", serviceCenterCreateLoader: false })
      ]);
    }
  } catch (error) {
    failToast("Error in sending email");
    yield all([
      put({ type: "SERVICE_CENTER_CREATE_SUCCESS", serviceCenterSuccess: false }),
      put({ type: "SERVICE_CENTER_CREATE_LOADER", serviceCenterCreateLoader: false })
    ]);
  }
}

/**
 * service center delete
 */
function* serviceCenterDelete(obj: any = ""): any {
  try {
    const response = yield call(deleteServiceCenterServices, obj);
    const {
      data: { message = "", errorMessage = "" },
      status = 0
    } = response || {};
    if (status === 200) {
      successToast(message || "Service center added successfully");
      yield all([
        put({ type: "SERVICE_CENTER_CREATE_SUCCESS", serviceCenterSuccess: true }),
        put({ type: "SERVICE_CENTER_CREATE_LOADER", serviceCenterCreateLoader: false })
      ]);
    } else {
      failToast(message || errorMessage);
      yield all([
        put({ type: "SERVICE_CENTER_CREATE_SUCCESS", serviceCenterSuccess: false }),
        put({ type: "SERVICE_CENTER_CREATE_LOADER", serviceCenterCreateLoader: false })
      ]);
    }
  } catch (error) {
    failToast("Error in deleting service center");
    yield all([
      put({ type: "SERVICE_CENTER_CREATE_SUCCESS", serviceCenterSuccess: false }),
      put({ type: "SERVICE_CENTER_CREATE_LOADER", serviceCenterCreateLoader: false })
    ]);
  }
}

export default function* ServiceCenterSaga() {
  yield takeLatest(salesReturnType.GET_SERVICES_CENTER_LIST, getServicesCenterList);
  yield takeLatest(salesReturnType.GET_SERVICES_CENTER_TOKEN, getServicesCenterToken);
  yield takeLatest(salesReturnType.GET_SERVICE_CENTER_LIST, getServiceCenterList);
  yield takeLatest(salesReturnType.GET_SERVICE_CENTER_REFRESH, getServiceCenterRefresh);
  yield takeLatest(salesReturnType.SERVICE_CENTER_SEND_REPORT_EMAIL, sendCenterReportEmail);
  yield takeLatest(salesReturnType.SERVICE_CENTER_CREATE, sendCenterCreate);
  yield takeLatest(salesReturnType.SERVICE_CENTER_DELETE, serviceCenterDelete);
}
