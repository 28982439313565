import { Delete, Get, Post } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { crmApiBaseUrl, encodeGetParams, scoreCardList } from "ApiProcess/httpRestServices/apiManage";

/**
 * Delete Team Members
 */
export const deleteTeamMember = (memberId: any, obj: any) => {
  const queryString = encodeGetParams(obj);
  return Delete(`${crmApiBaseUrl}${scoreCardList.teamMember}/${memberId}?${queryString}`, "crmToken");
};

/**
 * Get Created Teams
 */
export const getAllTeams = (payload: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    endDate = "",
    startDate = "",
    pageType = "",
    scoreCardId = []
  } = payload || {};
  const newObj = {
    page,
    size,
    search,
    pageType,
    scoreCardId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.teams}?${convertString}`, "crmToken");
};

/**
 * Get All Team Members
 */
export const getAllTeamMembers = (payload: any = {}) => {
  const convertString = encodeGetParams(payload);
  return Get(`${crmApiBaseUrl}${scoreCardList.allTeamMember}?${convertString}`, "crmToken");
};

/**
 * Add Team Members to the Team
 * @param obj
 */
export const addTeamMember = (payload: any = {}) => {
  return Post(`${crmApiBaseUrl}${scoreCardList.teamMember}`, payload, "crmToken");
};

/**
 * Create New Team
 * @param obj
 */
export const createTeam = (payload: any = {}) => {
  return Post(`${crmApiBaseUrl}${scoreCardList.team}`, payload, "crmToken");
};

/**
 * Delete Team and All Team Members
 */
export const deleteTeam = (teamId: any) => {
  return Delete(`${crmApiBaseUrl}${scoreCardList.teams}/${teamId}?`, "crmToken");
};

/**
 * Get Team Members
 */
export const getTeamMembers = (teamId: any, payload: any = {}) => {
  const { page = 1, size = 10, search = "", endDate = "", startDate = "" } = payload || {};
  const newObj = {
    page,
    size,
    search,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.teamMember}/${teamId}?${convertString}`, "crmToken");
};

/**
 * Evaluator Reports
 */
export const getEvaluatorReports = (payload: any) => {
  const {
    page = 1,
    size = 10,
    search = "",
    endDate = "",
    startDate = "",
    userId = [],
    scoreCardId = []
  } = payload || {};
  const newObj = {
    page,
    size,
    search,
    userId,
    scoreCardId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.evaluatorReport}?${convertString}`, "crmToken");
};
