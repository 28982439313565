import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import ReadMore from "Component/ReadMore";
import CallTypeBody from "Component/CallTypeBody/CallTypeBody";
import { TableClass, tdClass, thClass } from "utils/ClassContent";
import OnlineEnqTransHistoryView from "./OnlineEnqTransHistoryView";
import { convertToLowerCase, dateTimeDisplay, getDateConvert } from "utils/validation";

const OnlineEnqTransactionHistory = (props: any = {}) => {
  const { enquiryData = {}, columnView = false, getModalStatus = false } = props || {};

  const [transactions, setTransactions] = useState([]);
  const [seletectElment, setseletectElment] = useState({});
  const [oldStatusView, setOldStatusView] = useState(false);

  //view eyeIcon click
  const handleViewOldStatus = (ele = {}) => {
    setseletectElment(ele);
    setOldStatusView(true);
  };

  useEffect(() => {
    setTransactions(_get(enquiryData, "transactions", []));
  }, [enquiryData]);

  return (
    <Fragment>
      <div
        className="block w-full overflow-x-auto relative "
        style={{ minHeight: "250px", height: getModalStatus ? "250px" : "100%" }}
      >
        <table className={TableClass}>
          <thead>
            <tr>
              <th className={thClass}>Date</th>
              <th className={thClass}>Status</th>
              <th className={thClass}>Sub Status</th>
              <th className={thClass}>FollowUp Date</th>
              <th className={thClass}>Remarks</th>
              <th className={thClass}>Updated by</th>
            </tr>
          </thead>
          {transactions.map((ele: any, i: number) => {
            const {
              status = "",
              remarks = "",
              currentStatus = "",
              followUpDate = "",
              actionBy: { date = "", name = "", branch = "" } = {}
            } = ele;
            return (
              <tbody key={convertToLowerCase(i + "transactions-fsvx")}>
                <tr
                  tabIndex={1}
                  onClick={() => handleViewOldStatus(ele)}
                  data-jest-id={"jestHandleViewOldStatusOnlineOrders"}
                  className="hover:bg-gray-600 hover:bg-opacity-10 cursor-pointer"
                >
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    <div className="flex items-center justify-between">
                      <span className="w-32">{dateTimeDisplay(date)}</span>

                      <span className="mx-1">
                        <CallTypeBody callType={_get(ele, "callType", false)} />
                      </span>
                    </div>
                  </td>
                  <td style={{ minWidth: "100px" }} className={tdClass}>
                    {status}
                  </td>
                  <td style={{ minWidth: "250px" }} className={tdClass}>
                    {currentStatus}
                  </td>
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    {followUpDate ? getDateConvert(followUpDate) : followUpDate}
                  </td>
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    <ReadMore content={remarks || ""} />
                  </td>
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    {name}-{branch}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      {oldStatusView && (
        <OnlineEnqTransHistoryView
          columnView={columnView}
          getData={seletectElment}
          handleClosePopup={() => setOldStatusView(false)}
        />
      )}
    </Fragment>
  );
};

export default OnlineEnqTransactionHistory;
