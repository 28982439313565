import React, { useEffect } from "react";
import "./kudoAnimation.css";
import { StarIcon } from "@heroicons/react/solid";

const KudosButton = (props: any) => {
  const { animateID = "", setKudos = () => {}, kudos = false } = props;
  useEffect(() => {
    if (kudos && animateID !== "") {
      setTimeout(() => {
        const kudoStar: any = document && document.getElementById(animateID);
        kudoStar.classList.add("shake");
      }, 1000);
      setTimeout(() => {
        const kudoStar: any = document && document.getElementById(animateID);
        kudoStar.classList.remove("shake");
      }, 3000);
    }
  }, [kudos]);
  return (
    <button type="button" onClick={() => setKudos()} className={`flex ${kudos ? "active_kudos" : ""}`}>
      {/* {kudos && animateID !== '' && <StarIcon className={`text-yellow-500 w-6 h-6 mr-2 ml-1`} />} */}
      <StarIcon className={`${kudos ? "text-yellow-500" : "text-gray-500"} w-6 h-6 mr-2 ml-1`} />
      <span>Provide Kudos</span>
    </button>
  );
};

export default KudosButton;
