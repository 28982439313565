import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { convertToLowerCase, getDateConvert, nullToArray, nullToObject } from "utils/validation";
import NoDataFoundPage from "Component/NoDataFoundPage";
import { TableClass, tdClassStyle2, thClassStyle2, thSticky } from "utils/ClassContent";

const SalesEnqTransactionHiostoryTable = (props: any = {}) => {
  const { enableRadio = false, getModalStatus = false, onChangeTranactionData = () => {} } = props || {};

  const globelStoreData = useSelector((state: any) => state || {});
  const { basicInfoReducer: { customerTransactions = [] } = {} } = nullToObject(globelStoreData);
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    const customerTransactionsNewArray = nullToArray(customerTransactions)
      .sort((a: any, b: any) => b.INVOICE_DATE - a.INVOICE_DATE)
      .map((ele: object, i: number) => {
        return {
          ...ele,
          isChecked: false,
          id: i + 1
        };
      }); //newObject constrt and id is added
    setHistoryData(customerTransactionsNewArray);
  }, [customerTransactions]);

  return (
    <div
      className=" block w-full overflow-x-auto relative max-h-screen "
      style={{ minHeight: "250px", height: getModalStatus ? "100%" : "250px" }}
    >
      <table className={TableClass}>
        <thead>
          <tr className="">
            {enableRadio && <th className={thClassStyle2 + thSticky} />}
            <th className={thClassStyle2 + thSticky}>Customer Name</th>
            <th className={thClassStyle2 + thSticky}>Invoice No</th>
            <th className={thClassStyle2 + thSticky}>Invoice Date</th>
            <th className={thClassStyle2 + thSticky}>Invoice Value</th>
          </tr>
        </thead>
        <tbody>
          {customerTransactions && nullToArray(customerTransactions)?.length ? (
            historyData.map((enquiry: any, i: number) => {
              return (
                <tr
                  key={convertToLowerCase(i + "customerTransactionsNewArray-efd")}
                  className={enableRadio ? "cursor-pointer hover:bg-yellow-50" : ""}
                >
                  {enableRadio && (
                    <td className={tdClassStyle2}>
                      <div className="cursor-pointer justify-center flex items-center w-6 h-6">
                        <input
                          type="checkbox"
                          id={`GetImei-${i}`}
                          name={`imeiDetailsHistory-${i}`}
                          onChange={() => {
                            const cloneHistoryData: any = [...historyData];
                            cloneHistoryData[i].isChecked = !cloneHistoryData[i].isChecked;
                            setHistoryData(cloneHistoryData);
                            onChangeTranactionData({ ...enquiry, isChecked: !enquiry.isChecked });
                          }}
                          checked={enquiry.isChecked}
                          className="focus:ring-yellow-500  ring-yellow-500 cursor-pointer h-4 w-4 text-yellow-600 border-gray-300"
                        />
                      </div>
                    </td>
                  )}
                  <td className={tdClassStyle2}>
                    <label className="block cursor-pointer" htmlFor={enableRadio ? `GetImei-${i}` : ""}>
                      {enquiry?.CUSTOMER_NAME}
                    </label>
                  </td>

                  <td className={tdClassStyle2}>
                    <label className="block cursor-pointer" htmlFor={enableRadio ? `GetImei-${i}` : ""}>
                      {enquiry?.INVOICE_NO}
                    </label>
                  </td>

                  <td className={tdClassStyle2}>
                    <label className="block cursor-pointer" htmlFor={enableRadio ? `GetImei-${i}` : ""}>
                      {getDateConvert(enquiry?.INVOICE_DATE)}
                    </label>
                  </td>

                  <td className={tdClassStyle2}>
                    <label className="block cursor-pointer" htmlFor={enableRadio ? `GetImei-${i}` : ""}>
                      {enquiry?.INVOICE_VALUE}
                    </label>
                  </td>
                </tr>
              );
            })
          ) : (
            <NoDataFoundPage colSpan={5} />
          )}
        </tbody>
      </table>
    </div>
  );
};

export default SalesEnqTransactionHiostoryTable;
