import React, { Fragment, useEffect, useState } from "react";
import { _get, _isEmpty } from "utils/lodashConst";

import { duplicateInvRemarksProps } from "./types";
import { StyleFormTextarea } from "utils/ClassContent";
import CommonTextarea from "Component/Form/CommonTextarea";
import { DuplicateInvoiceStatus } from "Component/MockData";
import { emptyValidate, validateForm } from "utils/validation";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { remarkUpdateSupportStatusCall } from "Pages/ServiceCalls/commonServiceCalls";
import { getDuplicateInvRemarkUpdateServices } from "utils/services/duplicateInvService";
import { duplicateInvStatusUpdateList } from "Pages/NewCall/EnquiryFor/ServiceEnquiry/commonServiceEnquiry";
import EnquiryMailReply from "Pages/MailBox/ShowMailOnEnquiry/EnquiryMailReply";
import { replyMailTemplate, sendEmailToServerCall } from "Pages/MailBox/commonMailBoxFile";
import CommonCheck from "Component/Form/CommonCheck";

const DuplicateInvRemarks = (props: duplicateInvRemarksProps) => {
  const {
    dupImeiServiceUpdatedData = {},
    customerData = {},
    selectedMailData = {},
    closePopup = () => {}
  } = props || {};
  const { status: _status = "" } = customerData;
  const { service = "" } = dupImeiServiceUpdatedData;

  const [mailInfo, setMailInfo] = useState({});
  const [composedHtml, setComposedHtml] = useState("");
  const [isMailReply, setIsMailReply] = useState(false);
  const [sendAttachmants, setSendAttachmants] = useState([]);

  const [status, setStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isClosed, setIsClosed] = useState(false);
  const [passStatusInfoform, setPassStatusInfoform] = useState({
    status: "",
    remarks: ""
  });

  const [errinputPass, seterrinputPass] = useState({
    status: false
  });

  // Handle on submit
  const handleSubmit = () => {
    let remark_ = remarks;
    if (isMailReply) {
      remark_ = "Sent Mail";
    }
    const newObj = {
      service: service,
      status: status,
      remarks: remark_
    };
    const updateRemarks = async (updateObj: object = {}) => {
      const resendsmsres = await getDuplicateInvRemarkUpdateServices(updateObj); //Resend Call
      const {
        data: { message = "", status: _Status = 0 }
      } = resendsmsres || {};
      resetAllValues();
      if (_Status === 200) {
        const datas = {
          serviceID: service
        };
        closePopup();
        duplicateInvStatusUpdateList(datas); //send service id
        remarkUpdateSupportStatusCall();
        successToast(message);
      } else {
        failToast(message);
      }
    };

    if (validateForm(errinputPass)) {
      if (service !== "") {
        if (isMailReply) {
          if (!_isEmpty(_get(mailInfo, "to", [])) && !_isEmpty(_get(mailInfo, "from", []))) {
            updateRemarks(newObj);
            sendReplyMail();
            resetAllValues();
          } else {
            failToast("Please enter From/To email address");
          }
        } else {
          updateRemarks(newObj);
          resetAllValues();
        }
      } else {
        failToast(`Missing ServiceID`);
      }
    } else {
      failToast(`Please fill all required fields`);
    }
  };

  const resetAllValues = () => {
    setRemarks("");
    setStatus("");
    setIsMailReply(false);
    seterrinputPass(errinputPass);
  };

  /**
   * Handle field values change
   * @param event event
   */
  const onChange = (event: any = {}) => {
    const { name = "", value = "" } = _get(event, "target", {});
    const userStatus = passStatusInfoform;
    const errors: any = errinputPass;
    switch (name) {
      case "status":
        errors.status = emptyValidate(value);
        userStatus.status = value;
        setStatus(value);
        break;
      case "remarks":
        userStatus.remarks = value;
        setRemarks(value);
        break;
      default:
        break;
    }
    setPassStatusInfoform(userStatus);
    seterrinputPass(errors);
  };

  // Send reply mail to enquiry mail
  const sendReplyMail = () => {
    const currentMailData = _get(selectedMailData, "mailData", {});
    const originalMailHtml = _get(currentMailData, "html", "");
    const originalMailFrom = _get(currentMailData, "from", []);
    const originalMailText = _get(currentMailData, "text", "");
    const originalMailDate = _get(currentMailData, "date", "");
    const originalMailSubject = _get(currentMailData, "subject", "");
    const originalMailContent = originalMailHtml ? originalMailHtml : originalMailText;

    const originalMailReplyFormat = replyMailTemplate(originalMailDate, originalMailFrom, originalMailContent); // gets original mail in reply format

    const sendMailContent = composedHtml + originalMailReplyFormat;

    const mailData = {
      from: _get(mailInfo, "from", []).pop(),
      cc: _get(mailInfo, "cc", []),
      bcc: _get(mailInfo, "bcc", []),
      toEmail: _get(mailInfo, "to", []),
      subject: originalMailSubject,
      content: sendMailContent,
      attachments: sendAttachmants
    };
    sendEmailToServerCall(mailData);
    resetAllValues();
  };

  const passValue = (htmlValue = "") => {
    setComposedHtml(htmlValue);
  };

  const passAttachmants = (attachmants: any = []) => {
    setSendAttachmants(attachmants);
  };

  const passMailInfo = (val: Object = {}) => {
    setMailInfo(val);
  };

  /**
   * handle status search
   * @param event
   */
  const handleStatusSelect = (event: any = {}) => {
    const optionData = _get(event, "optionData", "");
    const error = errinputPass;
    error.status = emptyValidate(optionData);
    seterrinputPass(error);
    setStatus(optionData);
  };

  useEffect(() => {
    onChange({ target: { name: "status", value: "" } });
    onChange({ target: { name: "remarks", value: "" } });
    return () => {
      resetAllValues();
    };
  }, []);

  useEffect(() => {
    if (_get(customerData, "status", "") === "Closed") {
      setIsClosed(true);
      setStatus(_get(customerData, "status", ""));
    }
  }, [customerData]);

  return (
    <Fragment>
      <CommonSearchDropdown
        label={true}
        value={status}
        setShow={true}
        name={"status"}
        mustField={true}
        labelText={"Status"}
        placeholder={"--None--"}
        handleOnChange={onChange}
        inputWithIconClass="w-10"
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        arrayData={_status !== "Closed" ? DuplicateInvoiceStatus : ""}
        handleParentDropVal={handleStatusSelect}
        parentClass={"mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2"}
      />

      {!isClosed && (
        <div className="w-full">
          {Object.keys(selectedMailData)?.length !== 0 && (
            <CommonCheck
              id="Send Reply Mail"
              labelText="Send Reply Mail"
              checked={isMailReply}
              onChange={() => {
                setIsMailReply(!isMailReply);
              }}
              parentClass={"mb-2 w-full mt-2 px-4"}
            />
          )}
        </div>
      )}

      {!isMailReply ? (
        <CommonTextarea
          setShow={true}
          value={remarks}
          name={"remarks"}
          inputError={false}
          readOnly={isClosed}
          onChange={onChange}
          labelText={"Remarks"}
          placeholder={"Remarks"}
          labelTextClass={"font-bold"}
          parentClass={StyleFormTextarea()}
          inputFieldClass="h-32 max-h-64 min-h-textarea"
        />
      ) : (
        <EnquiryMailReply
          showReplyMail={true}
          passValue={passValue}
          passAttachmants={passAttachmants}
          selectedMailData={selectedMailData}
          passMailInfo={passMailInfo}
        />
      )}

      <div className="flex flex-col items-center justify-center w-full">
        <div>
          <button
            type="button"
            hidden={isClosed}
            onClick={handleSubmit}
            data-jest-id={"jestDuplicateHandleSubmit"}
            className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
          >
            Submit
          </button>
        </div>
      </div>
    </Fragment>
  );
};

export default DuplicateInvRemarks;
