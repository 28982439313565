import React from "react";
import { tdClass, tdHighLight } from "utils/ClassContent";
import { convertToLowerCase } from "utils/validation";

const EvaluatorAuditListBody = (props: any = {}) => {
  const { data = [] } = props;
  return (
    <React.Fragment>
      {data.map((item: any = {}, i = 0) => (
        <tr key={convertToLowerCase(i + "userServiceCenter-tendk")}>
          <td className={tdClass}>
            <button type={"button"} className={`${tdHighLight}`}>
              <span>{item?.employeeDetail?.name}</span>
            </button>
          </td>
          <td className={tdClass + " text-center "}>{item.avgScore}%</td>
          <td className={tdClass + " text-center "}>{item.scoreCount}</td>
          <td className={tdClass + " text-center "}>{item.failAllCount}</td>
        </tr>
      ))}
    </React.Fragment>
  );
};

export default EvaluatorAuditListBody;
