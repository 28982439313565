import React, { useEffect, useState } from "react";
import { CommonInputProps } from "./types";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { nullToString } from "utils/validation";
import { CalendarIcon } from "@heroicons/react/outline";
import { IconError, IconResponseLoader, IconSearch } from "assets/images";

import CommonDatePicker from "./CommonDatePicker";

const parentClassUpdate = (classNameProps: any = "", optionalClass: any = "") => {
  if (classNameProps) return classNameProps;
  return optionalClass;
};

const DateInput = (props: CommonInputProps) => {
  const {
    name = "",
    value = "",
    inputId = "",
    minDate = "",
    label = true,
    type = "text",
    maxLength = 0,
    children = "",
    iconTitle = "",
    autoFocus = "",
    labelText = "",
    setShow = true,
    genralInfo = {},
    inputClass = "",
    placeholder = "",
    parentClass = "",
    readOnly = false,
    mustField = false,
    onFocus = () => {},
    onBlur = () => {},
    inputvalid = false,
    inputError = false,
    inputErrorMsg = "",
    ErrorMsgClass = "",
    IconErrorClass = "",
    labelTextClass = "",
    onPaste = () => {},
    onInput = () => {},
    parentPosition = "",
    inputFieldClass = "",
    onChange = () => {}, //click
    placeholderText = "",
    closButtonShow = false,
    buttonType = "button",
    onKeyPress = () => {}, //click
    inputWithIcon = false,
    enableViewMode = false,
    responseLoader = false,
    inputWithIconClass = "",
    enableDatePicker = false,
    enableCloseIcon = false,
    checkCurrentData = false,
    maxDate = "",
    iconClickAction = () => {},
    handleOnchangeEvent = () => {},
    iconComponent = <IconSearch />,
    ...rest
  } = props || {};
  const { billDate = "" } = genralInfo;
  const [enableDate, setEnableDate] = useState(false);

  const handleOnchange = (event: any = {}) => {
    event.preventDefault && event.preventDefault();
    const { value: aliceValue = "", name: aliceName = "" } = event.target;
    handleOnchangeEvent && handleOnchangeEvent(event);
    onChange({
      target: {
        name: aliceName,
        value: aliceValue
      }
    });
  };

  const HandleDatePicker = () => setEnableDate(!enableDate);;

  const handleSearchEmpty = () => {
    const nameVal = name;
    const event = { target: { name: nameVal, value: "" } };
    handleOnchange(event);
  };
  useEffect(() => {
    if (autoFocus) {
      const inputEle = document.querySelector(`[name=${name}]`) as HTMLElement | null;
      inputEle?.focus();
    }
  }, [autoFocus]);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group ${parentPosition ? parentPosition : "relative"} ${parentClass ? parentClass : ""}`}
    >
      {/* label print */}
      {label && (
        <label
          htmlFor=""
          className={`block text-xs input-label mb-1 
          ${parentClassUpdate(labelTextClass, "")}
          ${inputError && !inputvalid ? "text-error" : ""}
          ${inputvalid && !inputError ? "text-valid" : ""}`}
        >
          {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
        </label>
      )}

      <div className={` ${parentClassUpdate(inputClass, "")}${parentClassUpdate(inputWithIcon, "")} relative `}>
        {enableDatePicker ? (
          // <div className="absolute top-2 left-2 w-full h-10">
          enableViewMode ? (
            <input
              id={inputId}
              readOnly={enableViewMode}
              value={nullToString(value)}
              placeholder={placeholderText}
              className={`block w-60 rounded input-common px-3 py-1 h-8 text-sm ${inputError && !inputvalid ? " error pr-10" : ""}${
                inputvalid && !inputError ? " input-valid" : ""
              }${parentClassUpdate(inputFieldClass, "")}`}
            />
          ) : (
            <CommonDatePicker
              name={name}
              maxDate={maxDate}
              billDate={billDate}
              readOnly={readOnly}
              onChange={() => {}}
              value={nullToString(value)}
              minDate={new Date(minDate)}
              closButtonShow={closButtonShow}
              enableViewMode={enableViewMode}
              handleOnchange={handleOnchange}
              inputFieldClass={inputFieldClass}
              placeholderText={placeholderText}
              checkCurrentData={checkCurrentData}
            />
          )
        ) : (
          <input
            id={inputId}
            name={name}
            type={type}
            onFocus={onFocus}
            autoCorrect="off"
            autoComplete="off"
            spellCheck={false}
            readOnly={readOnly}
            value={nullToString(value)}
            onBlur={onBlur ? onBlur : null}
            onPaste={onPaste ? onPaste : null}
            onInput={onInput ? onInput : null}
            onKeyPress={onKeyPress ? onKeyPress : null}
            placeholder={parentClassUpdate(placeholder, "")}
            maxLength={parentClassUpdate(maxLength, 1000000000000)}
            onChange={readOnly ? () => {} : (e) => handleOnchange(e)}
            className={`block w-60 rounded input-common px-3 py-1 h-8 text-sm ${inputError && !inputvalid ? " error pr-10" : ""}
                        ${inputvalid && !inputError ? " input-valid" : ""}${parentClassUpdate(inputFieldClass)}`}
            {...rest}
          />
        )}

        {inputError && !inputvalid && (
          <i
            className={`inline-block  absolute top-1/2 right-3 transform -translate-y-1/2 ${parentClassUpdate(IconErrorClass, "")}`}
          >
            <IconError />
          </i>
        )}

        {inputWithIcon && iconComponent && (
          <button
            title={iconTitle}
            // eslint-disable-next-line react/button-has-type
            type={buttonType}
            // eslint-disable-next-line no-confusing-arrow
            onClick={(event: any = {}) =>
              enableDatePicker ? HandleDatePicker() : iconClickAction && iconClickAction(event)
            }
            className={`w-8 absolute flex justify-center items-center cursor-pointer input-icon-color-fill 
                        top-px bottom-px right-px input-group-icon rounded ${inputWithIconClass ? inputWithIconClass : ""}`}
          >
            {enableDatePicker ? (
              <CalendarIcon onClick={HandleDatePicker} className="w-5 h-5" />
            ) : responseLoader ? (
              <IconResponseLoader />
            ) : enableCloseIcon ? (
              value === "" ? (
                <span title="search" className=" inline-flex w-5 h-5 justify-center items-center">
                  <IconSearch className=" w-6 h-6 " />
                </span>
              ) : (
                <span title="clear" className=" inline-flex w-5 h-5 justify-center items-center">
                  <XIcon onClick={() => handleSearchEmpty()} className="hover:text-red-500 w-5 h-5" />
                </span>
              )
            ) : (
              React.cloneElement(iconComponent)
            )}
          </button>
        )}
        {children}
      </div>

      {/* error msg */}
      {inputError && inputErrorMsg && (
        <span className={`absolute -bottom-5 right-5 error-msg text-xs ${parentClassUpdate(ErrorMsgClass, "")}`}>
          {inputErrorMsg}
        </span>
      )}
    </Transition>
  );
};

export default DateInput;
