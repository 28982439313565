import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { employeeDetailProps } from "../types";
import CommonInput from "Component/Form/CommonInput";
import { inputStylePopup } from "utils/ClassContent";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { getEmployeeDetailApiServices } from "../../commonComplainlist";

const ComplainEmployeeDetail = (props: employeeDetailProps) => {
  const {
    handleChange = () => {},
    handleEmployeeFetch = () => {},
    enableViewMode = false,
    complainData = {},
    columnView = false
  } = props;

  const [employeeDetail, setEmployeeDetail] = useState<any>({});
  const [involvedEmploye, setBasicEmployee] = useState({
    InvolvedEmpId: complainData.InvolvedEmpId,
    InvolvedEmpName: complainData.InvolvedEmpName,
    InvolvedEmpContactNo: complainData.InvolvedEmpContactNo
  });
  /**
   * handle inout field change
   * @param event
   */
  const handleFieldChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    setBasicEmployee({
      ...involvedEmploye,
      [name]: value
    });
    handleChange(name, value);
  };

  const fetchEmployeeDetailApiCall = async (id) => {
    const response = await getEmployeeDetailApiServices(id);
    const { data = [], status = 0 } = response;
    if (status === 200) {
      setEmployeeDetail(data?.[0]);
    } else {
      setEmployeeDetail({});
      failToast(data?.error || "Not found");
    }
  };
  /**
   * handle employee change
   * @param event
   */
  const handleEmployeeChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    setBasicEmployee({
      ...involvedEmploye,
      [name]: value
    });
    handleChange(name, value);
    fetchEmployeeDetailApiCall(value);
  };

  useEffect(() => {
    if (employeeDetail?.employee_id) {
      setBasicEmployee({
        InvolvedEmpId: employeeDetail.employee_id,
        InvolvedEmpName: employeeDetail.employee_name,
        InvolvedEmpContactNo: employeeDetail.employee_mobileno
      });
      handleEmployeeFetch({
        InvolvedEmpId: employeeDetail.employee_id,
        InvolvedEmpName: employeeDetail.employee_name,
        InvolvedEmpContactNo: employeeDetail.employee_mobileno
      });
    }
  }, [employeeDetail]);

  useEffect(() => {
    setBasicEmployee({
      InvolvedEmpId: complainData.InvolvedEmpId,
      InvolvedEmpName: complainData.InvolvedEmpName,
      InvolvedEmpContactNo: complainData.InvolvedEmpContactNo
    });
  }, [complainData]);
  return (
    <>
      <CommonInput
        setShow={true}
        mustField={false}
        inputError={false}
        inputFieldClass="h-9"
        name={"InvolvedEmpId"}
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        onChange={handleEmployeeChange}
        labelText={"Involved Employee ID"}
        placeholder={"Involved Employee ID"}
        value={involvedEmploye.InvolvedEmpId}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonInput
        setShow={true}
        readOnly={true}
        mustField={false}
        inputError={false}
        inputFieldClass="h-9"
        name={"InvolvedEmpName"}
        labelTextClass={"font-bold"}
        onChange={handleFieldChange}
        labelText={"Involved Employee Name"}
        placeholder={"Involved Employee Name"}
        value={involvedEmploye.InvolvedEmpName}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonInput
        setShow={true}
        readOnly={true}
        mustField={false}
        inputError={false}
        inputFieldClass="h-9"
        name={"InvolvedEmpContactNo"}
        onChange={handleFieldChange}
        labelTextClass={"font-bold"}
        value={involvedEmploye.InvolvedEmpContactNo}
        labelText={"Involved Employee Contact Number"}
        placeholder={"Involved Employee Contact Number"}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />
    </>
  );
};

export default ComplainEmployeeDetail;
