import { _get } from "utils/lodashConst";

export const salesReturnInit = {
  imeiNumLoader: false,
  cusListApiReload: null,
  invoiceBasedDetails: [],
  empDetailsBasedEmpId: {},
  imeiNumbasedUserData: [],
  salesReturnLoader: false,
  serviceCenterDataList: [],
  imeiNumHistoryDetails: {},
  servicesCenterLoader: false,
  imeiNumHistoryLoader: false,
  salesReturnSuccessCode: false,
  invoiceBasedDetailLoader: false, //loader
  servicesCenterTokenLoader: false,
  groupInvoiceBasedDetails: {},
  salesReturnSatusUpdateLoader: false,
  getEmpIdBasedEmpDetailLoader: false, //loader
  groupInvoiceBasedDetailsResponse: {}
};

export const salesReturnReducer = (state = salesReturnInit, action: any = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "SALES_RETURN_INITIAL_STAGE": {
      return {
        ...state,
        ...salesReturnInit
      };
    }
    //get details based on IMEI number loader
    case "GET_DETAILS_BASED_IMEI_BASED_LOADER": {
      return {
        ...state,
        imeiNumLoader: _get(action, "imeiNumLoader", false)
      };
    }

    //get details based on IMEI number loader
    case "GET_TRANSACTION_BASED_IMEI_BASED_LOADER": {
      return {
        ...state,
        imeiNumHistoryLoader: _get(action, "imeiNumHistoryLoader", false)
      };
    }

    //get details based on IMEI Trans history
    case "GET_TRANSACTION_BASED_IMEI_BASED_DETAILS": {
      return {
        ...state,
        imeiNumHistoryDetails: _get(action, "imeiNumHistoryDetails", {})
      };
    }

    case "SALES_RETURN_DATA_LOADER": {
      return {
        ...state,
        salesReturnLoader: _get(action, "salesReturnLoader", false)
      };
    }

    case "SALES_RETURN_DATA_SUCCESS_CODE": {
      return {
        ...state,
        salesReturnSuccessCode: _get(action, "salesReturnSuccessCode", false)
      };
    }

    case "GET_DETAILS_BASED_IMEI_BASED_DATA": {
      return {
        ...state,
        imeiNumbasedUserData: _get(action, "imeiNumbasedUserData", [])
      };
    }

    case "SERVICE_CENTER_GET_LOADER": {
      return {
        ...state,
        servicesCenterLoader: _get(action, "servicesCenterLoader", false)
      };
    }

    case "SERVICE_CENTER_TOKEN_GET_LOADER": {
      return {
        ...state,
        servicesCenterTokenLoader: _get(action, "servicesCenterTokenLoader", false)
      };
    }

    case "SALES_RETURN_STATUS_UPDATE_LOADER": {
      //sales return status update loadr
      return {
        ...state,
        salesReturnSatusUpdateLoader: _get(action, "salesReturnSatusUpdateLoader", false)
      };
    }

    case "RELOAD_CUS_LIST_API_CALL": {
      //after sales return status update reload cus list
      return {
        ...state,
        cusListApiReload: _get(action, "cusListApiReload", null)
      };
    }

    case "SERVICE_CENTER_LIST_DATA_STORE": {
      return {
        ...state,
        serviceCenterDataList: _get(action, "serviceCenterDataList", [])
      };
    }

    case "GET_EMP_ID_BASED_GET_DETAIL_LOADER": {
      //emp search loader
      return {
        ...state,
        getEmpIdBasedEmpDetailLoader: _get(action, "getEmpIdBasedEmpDetailLoader", false)
      };
    }

    case "STORE_EMP_DETAILS_BASED_EMP_ID": {
      //emp details
      return {
        ...state,
        empDetailsBasedEmpId: _get(action, "empDetailsBasedEmpId", {})
      };
    }

    //emp details based on invoice and date
    case "GET_USER_DETAILS_BASED_INVOICE_DATE_DETAILS": {
      return {
        ...state,
        invoiceBasedDetails: _get(action, "payload.invoiceBasedDetails", []),
        groupInvoiceBasedDetails: {
          ...state.groupInvoiceBasedDetails,
          ..._get(action, "payload.groupInvoiceBasedDetails", {})
        },
        groupInvoiceBasedDetailsResponse: {
          ...state.groupInvoiceBasedDetailsResponse,
          ..._get(action, "payload.groupInvoiceBasedDetailsResponse", {})
        }
      };
    }

    case "REMOVE_USER_DETAILS_BASED_INVOICE_DATE_DETAILS": {
      return {
        ...state,
        invoiceBasedDetails: _get(action, "invoiceBasedDetails", [])
      };
    }

    case "CLEAR_TRANSACTION_HISTORY_DATA": {
      return {
        ...state,
        ...action.payload
      };
    }

    case "FILTER_USER_IMEI_AND_DETAIL_BASED_INVOICE": {
      const cloneGroupInvoicDtlsResponse = { ...state.groupInvoiceBasedDetailsResponse };
      const cloneGroupInvoiceDetails = { ...state.groupInvoiceBasedDetails };
      delete cloneGroupInvoicDtlsResponse[action.inVoiceNo];
      delete cloneGroupInvoiceDetails[action.inVoiceNo];
      return {
        ...state,
        groupInvoiceBasedDetails: cloneGroupInvoiceDetails,
        groupInvoiceBasedDetailsResponse: cloneGroupInvoicDtlsResponse
      };
    }

    case "GET_USER_DETAILS_BASED_INVOICE_DATE_LOADER": {
      return {
        ...state,
        invoiceBasedDetailLoader: _get(action, "invoiceBasedDetailLoader", {})
      };
    }

    default:
      return {
        ...state
      };
  }
};
