import { _get } from "utils/lodashConst";

type duplicateinvReturnInitType = {
  dupImeiNumbasedUserData?: Array<any>;
  dupImeiServiceUpdatedData?: Array<any>;
  dupimeiNumber?: boolean;
  statusUpdateListLoader?: boolean;
};

export const duplicateInvInit = {
  dupImeiNumbasedUserData: [],
  dupImeiServiceUpdatedData: [],
  dupimeiNumber: false,
  statusUpdateListLoader: false
};

export const DuplicateInvReducer = (state = duplicateInvInit, action: duplicateinvReturnInitType = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "DUPLICATE_INITIAL_STAGE": {
      return {
        ...state,
        ...duplicateInvInit
      };
    }
    //get details based on IMEI number loader
    case "DUP_GET_DETAILS_BASED_IMEI_BASED_LOADER": {
      return {
        ...state,
        dupimeiNumber: _get(action, "dupimeiNumber", false)
      };
    }
    //get details based on status loader
    case "DUP_GET_STATUS_LIST_BASED_DATA": {
      return {
        ...state,
        dupImeiServiceUpdatedData: _get(action, "dupImeiServiceUpdatedData", [])
      };
    }
    //get details based on status loader
    case "DUP_GET_STATUS_LIST_LOADER": {
      return {
        ...state,
        statusUpdateListLoader: _get(action, "statusUpdateListLoader", false)
      };
    }
    //get details based on IMEI number removed
    case "DUP_GET_DETAILS_BASED_IMEI_BASED_DATA": {
      return {
        ...state,
        dupImeiNumbasedUserData: _get(action, "dupImeiNumbasedUserData", [])
      };
    }
    default:
      return {
        ...state
      };
  }
};
