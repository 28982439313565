import React, { useEffect, useState } from "react";
import { _get, _isEmpty } from "utils/lodashConst";
import CommonRadioBox from "Component/Form/CommonRadioBox";
import { convertToLowerCase, nullToObject, nullToString, validateForm } from "utils/validation";
import DropDownForMultiFilter from "Component/CommonTable/CommonTableSortDropdown/DropDownForMultiFilter";

const leadClassificationType = [
  { labelText: "Hot", value: "hot", id: 1 },
  { labelText: "Warm", value: "warm", id: 2 },
  { labelText: "Cold", value: "cold", id: 3 }
];

const isExistUserCheck = [
  { labelText: "Yes", value: "Yes", id: 1 },
  { labelText: "No", value: "No", id: 2 }
];

const leastClasificationReason = [
  { isCheck: false, _id: 1, itemName: "Product unavailable" },
  { isCheck: false, _id: 2, itemName: "Price higher than competitor" },
  { isCheck: false, _id: 3, itemName: "Not eligible for offer" },
  { isCheck: false, _id: 4, itemName: "Need more time to decide" }
];

const IsSpecificBrandCheck = (props: any = {}) => {
  const {
    document = {},
    reason = "",
    leadCls = "",
    existUser = "",
    currentPage = "",
    enableViewMode = true,
    passLeadSource = () => {}
  } = nullToObject(props);
  const { brand_name = "" } = nullToObject(document);

  const isInitialExistingUser = existUser === "true" ? "Yes" : "No";
  const [leadClassification, setLeadClassification] = useState("");
  const [existingUser, setExistingUser] = useState(isInitialExistingUser);
  const [reasonDetails, setReasonDetails] = useState([...leastClasificationReason]);
  const [enableDropDown, setEnableDropDown] = useState({
    leadClassificationReason: false
  });

  const getValidateFields = () => {
    if (leadCls && leadCls !== "hot") {
      return {
        leadClassificationReason: false,
        leadClassification: true
      };
    } else if (!_isEmpty(leadCls)) {
      return {
        leadClassificationReason: true,
        leadClassification: true
      };
    }
  };

  const getExistingUser = () => {
    if (existUser === "true") {
      return {
        existingUser: "Yes"
      };
    } else {
      return {
        existingUser: "No"
      };
    }
  };

  const [valiDationField, setValiDationField] = useState({
    leadClassification: false,
    existingUser: false,
    bankDetails: true,
    preferredPayment: true,
    leadClassificationReason: false,
    ...(reason && { leadClassificationReason: true }),
    ...(existUser && { existingUser: true }),
    ...getValidateFields()
  });

  const [inputField, setInputField] = useState({
    bankDetails: [],
    ...getExistingUser(),
    preferredPayment: [],
    leadClassification: leadCls,
    leadClassificationReason: reason === "None" ? "" : reason
  });

  const leadDetailsMake = (inputDataObj = {}, validateObj = {}) => {
    passLeadSource(inputDataObj, validateForm(validateObj));
  };

  const updateReasonDetails = () => {
    setReasonDetails([
      { isCheck: false, _id: 1, itemName: "Product unavailable" },
      { isCheck: false, _id: 2, itemName: "Price higher than competitor" },
      { isCheck: false, _id: 3, itemName: "Not eligible for offer" },
      { isCheck: false, _id: 4, itemName: "Need more time to decide" }
    ]);
  };

  const onCheckBoxChange = (event: any) => {
    const { value = "" } = _get(event, "target", {});
    setLeadClassification(value);
    // not hot reason is mandatory field
    if (value !== "hot") {
      const newObj = {
        ...valiDationField,
        leadClassificationReason: false,
        leadClassification: true
      };
      const newObj1 = {
        ...inputField,
        leadClassification: value
      };
      updateReasonDetails();
      setInputField(newObj1); //input
      setValiDationField(newObj); //validation
      leadDetailsMake(newObj1, newObj);
    } else {
      updateReasonDetails();
      const newObj = {
        ...valiDationField,
        leadClassificationReason: true,
        leadClassification: true
      };
      const newObj1 = {
        ...inputField,
        leadClassificationReason: "",
        leadClassification: value
      };
      setInputField(newObj1); //input
      setValiDationField(newObj); //validate
      leadDetailsMake(newObj1, newObj);
    }
  };

  const onCheckExistUserCheck = (event: any) => {
    const { value = "" } = _get(event, "target", {});
    setExistingUser(value);
    const newObj = {
      ...valiDationField,
      existingUser: true
    };
    const newObj1 = {
      ...inputField,
      existingUser: value
    };
    setInputField(newObj1); //input
    setValiDationField(newObj);
    leadDetailsMake(newObj1, newObj);
  };

  const handleDropDownClick = (name = "", valus = false) => {
    setEnableDropDown({
      ...enableDropDown,
      [name]: valus
    });
  };

  //reason
  const reasonClickHandle = (event: any = {}) => {
    const { filterArray = [], originalArray = [] } = event;
    setReasonDetails(originalArray);
    const newObj = {
      ...valiDationField,
      leadClassificationReason: true
    };
    const newObj1 = {
      ...inputField,
      leadClassificationReason: filterArray[0]
    };
    setInputField(newObj1); //input
    setValiDationField(newObj);
    leadDetailsMake(newObj1, newObj);
  };

  useEffect(() => {
    if (reason) {
      let cloneReasonDetails = [...reasonDetails];
      cloneReasonDetails = cloneReasonDetails.map((val) => {
        if (val.itemName === reason) {
          val.isCheck = true;
        }
        return val;
      });
      setReasonDetails(cloneReasonDetails);
    }
  }, [reason]);

  return (
    <React.Fragment>
      <div className="form-group relative mb-2 sm:mb-2 mt-2 sm:px-3">
        <label htmlFor="" className="block text-xs input-label mb-1 font-bold">
          {"Lead Classification"} <sup style={{ color: "red" }}>*</sup>
        </label>
        <div className="flex">
          {leadClassificationType.map((itm: any, index) => (
            <CommonRadioBox
              label={true}
              value={itm.value}
              id={index + itm.value}
              name="leadClassification"
              labelText={itm.labelText}
              onChange={onCheckBoxChange}
              isDisable={enableViewMode}
              checked={leadClassification === itm.value}
              key={convertToLowerCase(index + "leadClassification-te-ndk")}
              parentClass={`form-check p-1.5 pl-0 ${currentPage === "sales" ? "w-20" : "w-52"}`}
            />
          ))}
        </div>
      </div>
      <div className="form-group relative mb-2 sm:mb-2 mt-2 sm:px-3">
        <div className="flex w-full multifilter-Dropdown">
          {leadClassification && nullToString(leadClassification) !== "hot" ? (
            <DropDownForMultiFilter
              mustField={true}
              labeName={"Reason"}
              enableCheckbox={false}
              arrayData={reasonDetails}
              disabled={enableViewMode}
              name={"leadClassificationReason"}
              filterArrayPass={reasonClickHandle}
              handleDropDownClick={handleDropDownClick}
              readOnly={enableDropDown.leadClassificationReason}
            />
          ) : null}
        </div>
      </div>

      <div className="form-group relative mb-2 sm:mb-2 mt-2 sm:px-3">
        <label htmlFor="" className="block text-xs input-label mb-1 font-bold">
          {brand_name}
          {"Exist User"} <sup style={{ color: "red" }}>*</sup>
        </label>
        <div className="flex">
          {isExistUserCheck.map((itm: any, index) => (
            <CommonRadioBox
              label={true}
              value={itm.value}
              id={index + itm.value}
              name="isExistUserCheck"
              labelText={itm.labelText}
              isDisable={enableViewMode}
              onChange={onCheckExistUserCheck}
              checked={existingUser === itm.value}
              key={convertToLowerCase(index + "isExistUserCheck-te-ndk")}
              parentClass={`form-check p-1.5 pl-0 ${currentPage === "sales" ? "w-20" : "w-52"}`}
            />
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default IsSpecificBrandCheck;
