import React from "react";

import { envPath } from "ApiProcess/httpRestServices/apiManage";

const PoorvikaCopyFileContent = () => {
  const linkCheck = envPath === "dev" ? false : true;
  const year = new Date().getFullYear() || 0;
  return (
    <span className="h-7 text-center copy-right">
      {`© ${year} `}
      {linkCheck ? (
        <a target="_blank" rel="noreferrer" href={"https://www.poorvika.com/"} className="hover-hightlight transition">
          {"Poorvika.com. "}
        </a>
      ) : (
        "Poorvika"
      )}

      {"All rights reserved"}
    </span>
  );
};

export default PoorvikaCopyFileContent;
