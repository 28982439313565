import React, { useState } from "react";
import { loginProps } from "./types";
import Loader from "Component/Loader";
import { _get } from "utils/lodashConst";
import { encryptData } from "utils/secureFile";
import { Transition } from "@headlessui/react";
import ActionPopup from "Component/ActionPopup";
import ModalFixed from "Component/Modal/ModalFixed";
import CommonInput from "Component/Form/CommonInput";
import { Link, useNavigate } from "react-router-dom";
import { envPath } from "ApiProcess/httpRestServices/apiManage";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { EyeIcon, EyeOffIcon, ExclamationIcon, UserIcon } from "@heroicons/react/outline";
import { getUserIdBasedPagePermisstionDetails } from "Pages/Setting/UserRoleTable/helperUserRole";
import { checkPasswordPolicy, dologinService, dologinSesstionUpdate, isNumber } from "./commonLoginPage";
import {
  isEmail,
  nullToObject,
  validateForm,
  EmailValidate,
  emptyValidateForm,
  findEmptyOrNotLoginInput
} from "utils/validation";

const Login = (props: loginProps) => {
  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [activePopup, setActivePopup] = useState(false);
  const [sesstionResponse, setSesstionResponse] = useState({});
  const [loginSesstionPopUp, set_loginSesstionPopUp] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: ""
  });

  const [getValidPassword, setValidPassword] = useState({
    digit: false,
    lowerCase: false,
    upperCase: false,
    minLength: false,
    specialChar: false
  });

  const { lowerCase, upperCase, digit, specialChar, minLength } = getValidPassword;

  const [errinputPass, seterrinputPass] = useState({
    empId: false,
    password: false
  });

  const cancelSesstionPopUp = () => {
    set_loginSesstionPopUp(false);
  };

  const checkPassword = (password = "") => {
    const { validatePass = false, passwordObj = {} } = checkPasswordPolicy(password);
    setValidPassword({
      ...getValidPassword,
      ...passwordObj
    });
    return validatePass;
  };

  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const errors = errinputPass;
    switch (name) {
      case "email":
        errors.empId = value ? true : false;
        break;
      case "password":
        errors.password = checkPassword(value);
        break;
      default:
        break;
    }
    seterrinputPass(errors);
    setInputValue({
      ...inputValue,
      [name]: value
    });
  };

  const onHandleSSO = (event: any = {}, bool: any = true) => {
    event.preventDefault && event.preventDefault();
    sessionStorage.clear();
    sessionStorage.setItem("ssologin", JSON.stringify({ IsSSOAllow: true, isStoreUser: bool }));
    setTimeout(() => {
      window.location.href = "/";
    }, 2000);
  };

  /**
   *get user permisstion list
   * @param roleId string
   * @param data
   */
  const getPermisstionList = async (roleId, data) => {
    setLoader(true);
    const res = await getUserIdBasedPagePermisstionDetails({ roleId });
    setLoader(false);
    const { data: { data: pageAccessData = [] } = {} } = nullToObject(res);
    encryptData("userPageAccessData", pageAccessData);
    if (envPath === "dev") {
      localStorage.setItem("userLoginData", JSON.stringify(data));
      localStorage.setItem("userPageAccessData", JSON.stringify(pageAccessData));
    }
  };

  /**
   * sesstion update api call
   */
  const handleSesstionUpdate = async () => {
    setLoader(true);
    const response = await dologinSesstionUpdate(sesstionResponse);
    const { data: { status = 0, message = "" } = {}, data = {} } = response || {};
    if (status === 200) {
      const { data: { roleId = 0 } = {} } = nullToObject(data);
      encryptData("userLoginData", data);
      await getPermisstionList(roleId, data);
      navigate(roleId === 1 ? "/home" : "/emp-enquiry-details");
      // History.push(roleId === 1 ? "/home" : "/emp-enquiry-details");
    } else if (status === 400 && message === "you are logged in another device.") {
      setSesstionResponse(data);
      set_loginSesstionPopUp(true);
      failToast(message);
    } else {
      failToast(message);
    }
    setLoader(false);
  };

  const onHandleLogin = async (event: any = {}) => {
    event.preventDefault && event.preventDefault();
    sessionStorage.setItem("ssologin", "");
    const { empId = false, password = false } = errinputPass;
    const { email = "" } = inputValue;
    const validateFormData = validateForm({
      password: password,
      userName: empId
    });

    if (inputValue.password !== "Poorvika@123") {
      if (validateFormData) {
        let isEmpId = false;
        if (isEmail(email)) {
          const isValid = EmailValidate(email);
          if (!isValid) {
            failToast("Please pass a valid email address");
            return null;
          }
        } else {
          const allowedChars = ["p"];
          if ((allowedChars.includes(email[0].toLowerCase()) && isNumber(email.slice(1))) || isNumber(email))
            isEmpId = true;
          else {
            failToast("Please pass a valid Employee Id/Email");
            return null;
          }
        }
        const newObj = {
          data: {
            inputValue,
            errinputPass,
            isEmpId: isEmpId
          }
        };

        setLoader(true);
        const loginData: any = await dologinService(newObj);
        setLoader(false);

        const { data: { status = 0, message = "" } = {}, data = {} } = loginData || {};
        if (status === 200) {
          const { data: { roleId = 0 } = {} } = nullToObject(data);
          encryptData("userLoginData", data);
          await getPermisstionList(roleId, data);
          navigate(roleId === 1 ? "/home" : "/emp-enquiry-details");
          // History.push(roleId === 1 ? "/home" : "/emp-enquiry-details");
        } else if (status === 400 && message === "you are logged in another device.") {
          setSesstionResponse(data);
          set_loginSesstionPopUp(true);
          failToast(message);
        } else {
          failToast(message);
        }
      } else {
        if (emptyValidateForm(inputValue)) {
          failToast("Please enter valid login details.");
        } else {
          failToast("Please fill all mandatory field");
        }
      }
    } else {
      setActivePopup(true);
    }
  };

  const errorCallUpdate = (value = false) => {
    if (value) {
      return false;
    }
    return true;
  };

  const handlePasswordVisible = () => {
    setPassVisible(!passVisible);
  };

  const handleActionRedirect = () => {
    navigate("/createPassword");
  };

  return (
    <React.Fragment>
      {loader ? <Loader /> : null}

      <Transition
        show={true}
        leaveTo="hidden"
        leaveFrom="hidden"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        enter="transition-opacity duration-300"
        leave="transition-opacity duration-300"
      >
        <form autoComplete="off" className="login-form m-auto w-10/12 sm:4/5 md:4/5 2xl:w-2/5 xl:w-3/5 max-w-md">
          <div className="flex  mb-8 items-center flex-col justify-center">
            <button
              // disabled
              type="button"
              onClick={(e) => onHandleSSO(e, false)}
              className="px-4 w-48 h-9 mb-3 bg-transparent opacity-50 font-semibold tracking-wide text-sm text-center btn-custom2 rounded transition-all"
            >
              {"SSO LOGIN FOR CORP"}
            </button>
            {true && (
              <button
                // disabled
                type="button"
                onClick={(e) => onHandleSSO(e, true)}
                className="px-4 w-48 h-9 bg-transparent opacity-50 font-semibold tracking-wide text-sm text-center btn-custom2 rounded transition-all"
              >
                {"SSO LOGIN FOR STORE"}
              </button>
            )}
          </div>
          <CommonInput
            name={"email"}
            onChange={onChange}
            value={inputValue.email}
            parentClass={"mb-4 sm:mb-5"}
            labelText={"Employee Id/Email"}
            placeholder={"Employee Id/Email"}
            inputErrorMsg={"Please enter valid email"}
          />

          <div className="relative">
            <CommonInput
              name={"password"}
              onChange={onChange}
              inputFieldClass={"pr-14"}
              IconErrorClass={"right-8"}
              value={inputValue.password}
              parentClass={"mb-6 sm:mb-8"}
              labelText={"Password"}
              type={!passVisible ? "password" : "text"}
              placeholder={"Password"}
              inputErrorMsg={"Please enter valid password"}
              inputError={
                findEmptyOrNotLoginInput(inputValue.password)
                  ? errorCallUpdate(lowerCase && upperCase && digit && specialChar && minLength)
                  : false
              }
            >
              <i
                onClick={handlePasswordVisible}
                className="cursor-pointer absolute w-5 h-5 right-2 top-1/2 transform -translate-y-1/2"
              >
                {!passVisible ? <EyeIcon className="w-5 h-5" /> : <EyeOffIcon className="w-5 h-5" />}
              </i>
            </CommonInput>
          </div>
          <div className="flex items-center justify-center">
            <button
              type="submit"
              onClick={onHandleLogin}
              className="w-40 rounded h-10 bg-primary font-semibold tracking-wide text-sm text-center btn-custom transition-all"
            >
              {"Login"}
            </button>
          </div>

          <hr className="mt-4 sm:mt-8 " />

          <div className="flex flex-col sm:flex-row items-center justify-between ">
            <Link className="sm:mt-8 mt-4" to="/forgetPassword">
              <span className=" inline-block text-sm text-blue">{"Forgot your Password ?"}</span>
            </Link>

            <Link className="sm:mt-8 mt-4" to="/createPassword">
              <span className=" inline-block text-sm text-blue"> Change Password ?</span>
            </Link>
          </div>
        </form>
      </Transition>
      {activePopup && (
        <ModalFixed>
          <ActionPopup
            enableDesc={true}
            actionHeading={"Alert"}
            handleActionText={"Change Password"}
            handleCancel={() => setActivePopup(false)}
            handleAction={() => handleActionRedirect()}
            actionDescription={"Invalid Password, Please change your password and try again."}
            actionIconComponent={<ExclamationIcon className="w-10 h-10 text-red-600 mx-auto block" />}
          />
        </ModalFixed>
      )}

      {loginSesstionPopUp ? (
        <ModalFixed>
          <ActionPopup
            enableDesc={true}
            enableActionIcon={true}
            handleCancelText={"Ok"}
            handleActionText="Cancel"
            actionHeading={"Are you sure?"}
            handleAction={cancelSesstionPopUp}
            handleCancel={handleSesstionUpdate}
            actionIconComponent={<UserIcon className="w-16 h-16 flex items-center text-red-500 mx-auto" />}
            actionDescription={"Do you really want to kill old sesstion and continue here?!"}
          />
        </ModalFixed>
      ) : null}
    </React.Fragment>
  );
};

export default Login;
