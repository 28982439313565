import store from "store";
import { _get } from "utils/lodashConst";
import { Post, Put } from "ApiProcess/httpRestServices";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { convertToString, DateTimeFormeterTimeing, nullToObject } from "utils/validation";
import {
  crmApiBaseUrl,
  encodeGetParams,
  salesProduct,
  typesenceOutDatedBasePath
} from "ApiProcess/httpRestServices/apiManage";

const commonNonDealingReturn = (message = "", salesOutDatedResponse = {}) => {
  return {
    data: {
      success: true,
      message: message,
      salesOutDatedResponse: salesOutDatedResponse
    }
  };
};
/**
 * create non-dealing api call
 */
export const createNonDealingApiCall = async (
  basicInfoReducer: any = {},
  outDatedInformation: any = {},
  searchResult: any = []
) => {
  const result = _get(searchResult, "[0].hits", []);
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;

  //user basic info
  const { basicInfoUserinfoData = {} } = nullToObject(basicInfoReducer);
  const { customerId = "", name = "", mobile = "" } = nullToObject(basicInfoUserinfoData);

  //product full info
  const {
    currentStatus = "",
    remark = "",
    searchParams = "",
    status = "",
    sourceMsg = "",
    callType = false,
    nextFollowUpDate = "",
    isEdit = false,
    editId = ""
  } = outDatedInformation;

  const findExistingOutDatedName = result.some(
    (ele: any) => convertToString(_get(ele, "document.outdated_nondealing", "")) === convertToString(searchParams)
  );

  const newObj = {
    isQuery: "t",
    status: status,
    source: sourceMsg,
    callType: callType,
    enquiryFor: "Sales",
    outdated: searchParams,
    subCategory: "Non-Dealing",
    currentStatus: currentStatus,
    ...(!isEdit && { remarks: remark }),
    ...(!isEdit && { mobileNo: mobile }),
    ...(!isEdit && { cusId: customerId }),
    ...(!isEdit && { customerName: name }),
    ...(isEdit && { statusRemarks: remark }),
    ...(!isEdit && { leadDetails: justDialLeadDetails }),
    nextFollowUpDate: nextFollowUpDate ? DateTimeFormeterTimeing(nextFollowUpDate) : ""
  };

  const queryString = encodeGetParams(newObj);
  let salesOutDatedResponse: any = {};

  if (!isEdit) {
    //create
    salesOutDatedResponse = await Post(`${crmApiBaseUrl}${salesProduct.salesEndPoint}?${queryString}`, {}, "crmToken");
  } else {
    //upDate
    salesOutDatedResponse = await Put(
      `${crmApiBaseUrl}${salesProduct.salesEndPoint}/${editId}?${queryString}`,
      {},
      "crmToken"
    );
  }

  const { data: { status: httpResponse = 0, message = "", data: { success: successData = [] } = {} } = {} } =
    salesOutDatedResponse;
  const {
    _id: uniqueId = "",
    remarks: httpRemarks = "",
    customerName = "",
    outdated = "",
    createdBy: { empId = "", date = "", branch = "", branchCode = "" } = {}
  } = successData[0] || {};

  if (httpResponse === 200) {
    if (httpResponse === 200) {
      const newObjTypesense = {
        id: uniqueId,
        status: 1,
        empId: empId,
        created_date: date,
        name: customerName,
        remark: httpRemarks,
        branch: branch ? branch : "",
        outdated_nondealing: outdated,
        branchcode: branchCode ? branchCode : ""
      };
      const res: any = await Post(
        `${typesenceOutDatedBasePath}${salesProduct.outDatatedPost}`,
        newObjTypesense,
        "outDatedProductPost"
      );
      const { data: { success = false } = {} } = res;
      if (success) {
        res.data.message = "Enquiry updated successfully";
      } else {
        res.data.message = "Typesence Error/CRM Added Error";
      }
      return res;
    } else {
      failToast(message ? message : "Typesence Error/CRM Added Error");
    }
    return commonNonDealingReturn(message, salesOutDatedResponse);
  } else if (findExistingOutDatedName) {
    return commonNonDealingReturn(message, salesOutDatedResponse);
  }
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const outDatedProductSearch = async (value = "", page = 1) => {
  const newObj = {
    searches: [
      {
        page: page,
        per_page: 20,
        q: value ? value : "*",
        sort_by: "status:desc",
        collection: "outdated_non_dealing",
        query_by: "name,outdated_nondealing,remark,empId,branch,branchcode,created_date,"
      }
    ]
  };
  return await Post(`${typesenceOutDatedBasePath}${salesProduct.outDatedSearch}`, newObj, "outDatedProduct");
};

/**
 * create other api call
 */
export const createOtherApiCall = async (basicInfoReducer: any = {}, outDatedInformation: any = {}) => {
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;
  //user basic info
  const { basicInfoUserinfoData = {} } = nullToObject(basicInfoReducer);
  const { customerId = "", name = "", mobile = "" } = nullToObject(basicInfoUserinfoData);

  //product full info
  const {
    currentStatus = "",
    remark = "",
    status = "",
    source = "",
    callType = false,
    nextFollowUpDate = "",
    isEdit = false,
    editId = ""
  } = outDatedInformation;

  const newObj = {
    isQuery: "t",
    status: status,
    source: source,
    callType: callType,
    enquiryFor: "Sales",
    subCategory: "Others",
    currentStatus: currentStatus,
    ...(!isEdit && { remarks: remark }),
    ...(!isEdit && { mobileNo: mobile }),
    ...(!isEdit && { cusId: customerId }),
    ...(!isEdit && { customerName: name }),
    ...(isEdit && { statusRemarks: remark }),
    ...(!isEdit && { leadDetails: justDialLeadDetails }),
    nextFollowUpDate: nextFollowUpDate ? DateTimeFormeterTimeing(nextFollowUpDate) : ""
  };
  const queryString = encodeGetParams(newObj);
  if (!isEdit) {
    //create
    return await Post(`${crmApiBaseUrl}${salesProduct.salesEndPoint}?${queryString}`, {}, "crmToken");
  } else {
    //upDate
    return await Put(`${crmApiBaseUrl}${salesProduct.salesEndPoint}/${editId}?${queryString}`, {}, "crmToken");
  }
};
