import React, { useState } from "react";
import { nullToObject, nullToString } from "utils/validation";
import { PhoneIcon } from "@heroicons/react/outline";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import AmoyoCallButton from "../AmoyoCallButton";
import CommonInput from "Component/Form/CommonInput";
import { Decrypt } from "utils/secureFile";
import { decodeMobileNo } from "Component/utility/commonUtility";

const AmeyoWithPhoneButton = (props: any = {}) => {
  const {
    value = "",
    name = "",
    labelText = "",
    maxLength = "",
    inputError = "",
    parentClass = "",
    onInput = () => {},
    onChange = () => {},
    inputErrorMsg = false,
    onKeyPress = () => {},
    isSalesEnquiry = false,
    ...rest
  } = nullToObject(props);

  const userLoginDetails = Decrypt("userLoginData");
  const { data: { isShowSensInfo = false } = {} } = nullToObject(userLoginDetails);

  const [popupOpen, setPopupOpen] = useState(false);

  const handleAmeyoPopup = (state: boolean) => {
    setPopupOpen(state);
  };

  let phoneVal: any = value;
  if (isSalesEnquiry) {
    phoneVal = decodeMobileNo({ phone: value, isShowSensInfo });
  }

  return (
    <React.Fragment>
      <CommonInput
        name={name}
        value={phoneVal}
        onChange={onChange}
        inputWithIcon={true}
        labelText={labelText}
        inputFieldClass="h-9"
        iconTitle={"Ameyo call"}
        parentClass={parentClass}
        placeholder={"Phone Number"}
        labelTextClass={"font-bold"}
        inputError={inputError} //error handle
        onInput={onInput}
        iconComponent={<PhoneIcon className="w-5 h-5" />}
        onKeyPress={onKeyPress}
        maxLength={maxLength ? maxLength : staticData.MobileNo}
        inputErrorMsg={inputErrorMsg ? "Please Enter Mobile" : ""} //error msg
        iconClickAction={nullToString(value)?.length >= 10 ? () => handleAmeyoPopup(true) : null}
        inputWithIconClass={` input-icon-btn ${nullToString(value)?.length >= 10 ? " " : " disabled"} `}
        {...rest}
      />

      {popupOpen && <AmoyoCallButton phone={value} setPopupOpen={handleAmeyoPopup} />}
    </React.Fragment>
  );
};

export default AmeyoWithPhoneButton;
