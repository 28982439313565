import { _get } from "utils/lodashConst";

type mailReducerTypes = {
  mailList?: any;
  totalCount?: number;
  mailLoader?: boolean;
  openedMailId?: string;
  updatedUser?: boolean;
  unreadedCount?: number;
  selectedEnquiry?: object;
  selectedMailData?: object;
  transactionPopup?: boolean;
  syncMailSuccessCode?: boolean;
  sendMailSuccessCode?: boolean;
  updatedMailSuccessCode?: boolean;
};

export const mailInitState = {
  mailList: [],
  totalCount: 0,
  openedMailId: "",
  unreadedCount: 0,
  updatedUser: false,
  mailLoader: false,
  selectedEnquiry: {},
  selectedMailData: {},
  transactionPopup: false,
  syncMailSuccessCode: false,
  updatedMailSuccessCode: false
};

export const mailReducer = (state = mailInitState, action: mailReducerTypes = {}) => {
  switch (_get(action, "type")) {
    //set to initial state
    case "SET_INIT_STATE": {
      return {
        ...state,
        ...mailInitState
      };
    }

    case "SET_MAIL_DATA": {
      return {
        ...state,
        mailList: _get(action, "mailList", [])
      };
    }

    case "SET_MAIL_LOADER": {
      return {
        ...state,
        mailLoader: _get(action, "mailLoader", false)
      };
    }

    case "SET_SELECTED_MAIL": {
      return {
        ...state,
        selectedMailData: _get(action, "selectedMailData", {})
      };
    }

    case "SET_SELECTED_ENQUIRY": {
      return {
        ...state,
        selectedEnquiry: _get(action, "selectedEnquiry", {})
      };
    }

    case "SET_TOTAL_COUNT": {
      return {
        ...state,
        totalCount: _get(action, "totalCount", 0)
      };
    }

    case "SET_UNREADED_COUNT": {
      return {
        ...state,
        unreadedCount: _get(action, "unreadedCount", 0)
      };
    }

    case "SET_OPENED_MAIL_ID": {
      return {
        ...state,
        openedMailId: _get(action, "openedMailId", "")
      };
    }

    case "SET_UPDATED_USER": {
      return {
        ...state,
        updatedUser: _get(action, "updatedUser", false)
      };
    }

    case "SET_TRANSACTION_POPUP": {
      return {
        ...state,
        transactionPopup: _get(action, "transactionPopup", false)
      };
    }

    case "SET_UPDATED_MAIL_SUCCESS_CODE": {
      return {
        ...state,
        updatedMailSuccessCode: _get(action, "updatedMailSuccessCode", false)
      };
    }

    case "SET_SEND_MAIL_SUCCESS_CODE": {
      return {
        ...state,
        sendMailSuccessCode: _get(action, "sendMailSuccessCode", false)
      };
    }

    case "SET_SYNC_MAIL_SUCCESS_CODE": {
      return {
        ...state,
        syncMailSuccessCode: _get(action, "syncMailSuccessCode", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
