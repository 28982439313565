import { _get } from "utils/lodashConst";

export const dashBoardInitState = {
  getGraphLoader: false, // graph loader
  graphDetails: [], // graph details
  uploadPercentege: 0, //file upload percentage
  campaignGetLoader: false, //loader
  dashBoardOverDetails: {}, //all overView Data
  purchesOrderLoader: false, //loader
  graphCreatedByDetails: [], // graph details created by
  graphsubStatusDetails: [], // graph details
  getGraphsubStatusLoader: false, // graph loader sub status
  dashBoardOverViewLoader: false, //loader
  getGraphCreatedByLoader: false, // graph loader created by
  dashBoardEnquireChartLoader: false, //loader
  dashBoardEnquireChartDetails: {} //chart data
};

export const dashBoardReducer = (state = dashBoardInitState, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "DASH_BORD_INITIAL": {
      return {
        ...state,
        ...dashBoardInitState
      };
    }

    case "DASHBOARD_GET_OVERVIEW_LOADER": {
      return {
        ...state,
        dashBoardOverViewLoader: _get(action, "dashBoardOverViewLoader", false)
      };
    }

    case "DASHBOARD_GET_OVERVIEW_DATA_DETAILS": {
      return {
        ...state,
        dashBoardOverDetails: _get(action, "dashBoardOverDetails", {})
      };
    }

    case "DASHBOARD_GET_GRAPH_LOADER": {
      return {
        ...state,
        getGraphLoader: _get(action, "getGraphLoader", false)
      };
    }

    case "DASHBOARD_GET_GRAPH_DATA_DETAILS": {
      return {
        ...state,
        graphDetails: _get(action, "graphDetails", [])
      };
    }

    case "DASHBOARD_GET_GRAPH_CREATED_BY_LOADER": {
      return {
        ...state,
        getGraphCreatedByLoader: _get(action, "getGraphCreatedByLoader", false)
      };
    }

    case "DASHBOARD_GET_GRAPH_CREATED_BY_DETAILS": {
      return {
        ...state,
        graphCreatedByDetails: _get(action, "graphCreatedByDetails", [])
      };
    }

    case "DASHBOARD_GET_GRAPH_SUB_STATUS_LOADER": {
      return {
        ...state,
        getGraphsubStatusLoader: _get(action, "getGraphsubStatusLoader", false)
      };
    }

    case "DASHBOARD_GET_GRAPH_SUB_STATUS_DETAILS": {
      return {
        ...state,
        graphsubStatusDetails: _get(action, "graphsubStatusDetails", [])
      };
    }

    case "DASHBOARD_GET_ENQUIRE_CHART_LOADER": {
      return {
        ...state,
        dashBoardEnquireChartLoader: _get(action, "dashBoardEnquireChartLoader", false)
      };
    }

    case "DASHBOARD_GET_ENQUIRE_CHART_DETAILS": {
      return {
        ...state,
        dashBoardEnquireChartDetails: _get(action, "dashBoardEnquireChartDetails", {})
      };
    }

    //loader
    case "CAMPAIGN_TABLE_DETAILS_LOADER": {
      return {
        ...state,
        campaignGetLoader: _get(action, "campaignGetLoader", false)
      };
    }

    //loader
    case "PURCHES_ORDER_HISTORY_DETAILS_LOADER": {
      return {
        ...state,
        purchesOrderLoader: _get(action, "purchesOrderLoader", false)
      };
    }

    //data
    case "FILE_UPLOAD_PERCENTAGE_GET": {
      return {
        ...state,
        uploadPercentege: _get(action, "uploadPercentege", 0)
      };
    }

    default:
      return {
        ...state
      };
  }
};
