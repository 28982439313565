import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import BasicInfoLang from "./BasicInfoLang";
import { inputStylePopup } from "utils/ClassContent";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { genderDetail, mockCusBasicInfoTypes } from "Component/MockData";
import { alphaOnly, convertToLowerCase, nullToObject, numOnlyAllow } from "utils/validation";
import { getUserBasicInfoUsingCusIdCall } from "Pages/ServiceCalls/commonServiceCalls";
import { getManualPageAccess, noEditPagePermisston, pageNumber, tabBasedAccess } from "helperRole";
import { clearPincodeDate, getPincodeBasedDataBasiInfo } from "Pages/NewCall/BasicInfo/commonBasicInfo";
import {
  resetUserBasicInfoDataCall,
  updateBasicInfoFromEnquiryCall,
  updateEnquiryBasicCustomerInfoCall
} from "../commonCalllist";
import AdditionalInfo from "Pages/AdditionalInfo";
import CommonInput from "Component/Form/CommonInput";
import { updateBasicInfoHistory } from "utils/services/basicInfoService";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import AmeyoWithPhoneButton from "Component/Ameyo/AmeyoWithPhoneButton";

const BasicInfoView = (props: any = {}) => {
  const {
    customerData = {},
    enableViewMode = true,
    isSalesEnquiry = false,
    handleEditView = () => {}
  } = nullToObject(props);
  const isEnableMobileEmail = tabBasedAccess(pageNumber["CUST_MOBILE_&_EMAIL_ID"]);
  const { customerId = "", ticketId = "" } = customerData;

  const globalStore: any = useSelector((states: any) => states);
  const { basicInfoReducer: { enquiryBasicInfo = {}, userInfoPinData = [], basicInfoUpdateResetData = false } = {} } =
    nullToObject(globalStore);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [gender, setGender] = useState("");
  const [mobile, setMobile] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [language, setLanguage] = useState([]);
  const [district, setDistrict] = useState("");
  const [whatsappNum, setWhatsappNum] = useState("");
  const [alterMobile, setAlterMobile] = useState("");
  const [pinCodeDataCheck, setPinCodeDataCheck] = useState(false);

  /**
   * handle employee change
   * @param event
   */
  const handleBasicChange = (event: any = {}) => {
    const { name: fieldName = "", value = "" } = _get(event, "target", {});
    switch (fieldName) {
      case "name":
        setName(value);
        break;
      case "mobile":
        setMobile(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "gender":
        setGender(value);
        break;
      case "type":
        setType(value);
        break;
      case "whatsappNum":
        setWhatsappNum(value);
        break;
      case "alterMobile":
        setAlterMobile(value);
        break;
      case "pincode":
        setPinCode(value);
        setCity("");
        setState("");
        setDistrict("");
        break;
      case "city":
        setCity(value);
        break;
      case "district":
        setDistrict(value);
        break;
      case "state":
        setState(value);
        break;
      default:
        break;
    }
  };

  /**
   * select cusType
   * make new event file
   */
  const selectCusType = (data: any) => {
    setType(data.optionData);
  };

  const selectCityData = (data: any) => {
    setCity(data.optionData);
  };

  const searchPincode = () => {
    //pinCode typeManage
    if (userInfoPinData.length >= 1) {
      clearPincodeDate(); //clear pinCode
    }
    if (pinCode.length === 6) {
      getPincodeBasedDataBasiInfo(pinCode);
    } else {
      failToast("Please enter valid pinCode");
    }
  };

  /**
   * language upadte and validate
   */
  const passLangData = (lang: Array<any>) => {
    const newArray: any = lang.filter((ele: any) => ele.isSelect === true);
    setLanguage(newArray.map((itm: any) => itm.optionData));
  };

  /**
   * update change history
   */
  const updateRecentChanges = () => {
    let array = [];
    if (_get(enquiryBasicInfo, "name", "") !== name) {
      array = [...array, { fieldName: "Customer Name", oldValue: _get(enquiryBasicInfo, "name", ""), newValue: name }];
    }
    if (_get(enquiryBasicInfo, "mobile", "") !== mobile) {
      array = [...array, { fieldName: "Mobile", oldValue: _get(enquiryBasicInfo, "mobile", ""), newValue: mobile }];
    }
    if (_get(enquiryBasicInfo, "language", "") !== language) {
      array = [
        ...array,
        { fieldName: "Language", oldValue: _get(enquiryBasicInfo, "language", ""), newValue: language }
      ];
    }
    if (_get(enquiryBasicInfo, "email", "") !== email) {
      array = [...array, { fieldName: "Email", oldValue: _get(enquiryBasicInfo, "email", ""), newValue: email }];
    }
    if (_get(enquiryBasicInfo, "gender", "") !== gender) {
      array = [...array, { fieldName: "Gender", oldValue: _get(enquiryBasicInfo, "gender", ""), newValue: gender }];
    }
    if (_get(enquiryBasicInfo, "type", "") !== type) {
      array = [...array, { fieldName: "Customer Type", oldValue: _get(enquiryBasicInfo, "type", ""), newValue: type }];
    }
    if (_get(enquiryBasicInfo, "pincode", "") !== pinCode) {
      array = [...array, { fieldName: "Pincode", oldValue: _get(enquiryBasicInfo, "pincode", ""), newValue: pinCode }];
    }
    if (_get(enquiryBasicInfo, "city", "") !== city) {
      array = [...array, { fieldName: "Area", oldValue: _get(enquiryBasicInfo, "city", ""), newValue: city }];
    }
    if (_get(enquiryBasicInfo, "state", "") !== state) {
      array = [...array, { fieldName: "State", oldValue: _get(enquiryBasicInfo, "state", ""), newValue: state }];
    }
    if (_get(enquiryBasicInfo, "alt_mobile", "") !== alterMobile) {
      array = [
        ...array,
        { fieldName: "Alt mobile", oldValue: _get(enquiryBasicInfo, "alt_mobile", ""), newValue: alterMobile }
      ];
    }
    if (_get(enquiryBasicInfo, "whatsapp_num", "") !== whatsappNum) {
      array = [
        ...array,
        { fieldName: "Whatsapp Number", oldValue: _get(enquiryBasicInfo, "whatsapp_num", ""), newValue: whatsappNum }
      ];
    }
    const payload = {
      ticketId,
      customerId,
      history: array
    };
    updateBasicInfoHistory(payload);
  };

  /**
   * handle submit
   */
  const handleSubmit = () => {
    const pagePemistion = getManualPageAccess(pageNumber.New_Call);
    const { write = false } = nullToObject(pagePemistion); //permisstion edit
    if (alterMobile && alterMobile.length !== 10) {
      failToast("Alternative mobile length must be 10 characters long");
      return;
    }
    if (write) {
      if (name && gender && type) {
        if (mobile || email) {
          const newObj = {
            locationInfo: {
              city,
              district,
              state,
              pinCode: pinCode,
              cusType: type
            },
            genralInfo: {
              userName: name,
              email: email,
              customerId: customerId,
              phoneNumber: mobile,
              userGender: gender,
              alt_mobile: whatsappNum,
              whatsapp_num: alterMobile
            },
            langUageInfo: language.map((itm: any) => ({ optionData: itm }))
          };
          updateBasicInfoFromEnquiryCall(newObj);
          updateRecentChanges();
          handleEditView();
        } else {
          failToast("Please add Mobile/Email");
        }
      } else {
        failToast("Please Enter Mandatory fields");
      }
    } else {
      noEditPagePermisston("Edit");
    }
  };

  useEffect(() => {
    if (customerId) {
      getUserBasicInfoUsingCusIdCall(customerId);
    }

    return () => {
      if (Object.keys(enquiryBasicInfo)?.length !== 0) {
        resetUserBasicInfoDataCall({});
      }
      clearPincodeDate(); //clear pinCode
    };
  }, [customerId]);

  useEffect(() => {
    if (basicInfoUpdateResetData) {
      const data = {
        enquiryId: ticketId,
        customerName: name,
        customerEmail: email,
        customerMobile: mobile,
        customerLanguage: language.map((itm: any) => ({ optionData: itm }))
      };
      updateEnquiryBasicCustomerInfoCall(data);
    }
  }, [basicInfoUpdateResetData]);

  useEffect(() => {
    if (userInfoPinData.length > 1) {
      //more than 1 call here
      const { 0: { state: stateInputData = "", district: disTric = "" } = {} } = userInfoPinData;
      setPinCodeDataCheck(true);
      handleBasicChange({ target: { name: "state", value: stateInputData } });
      handleBasicChange({ target: { name: "district", value: disTric } });
    } else if (userInfoPinData.length === 1) {
      //only one lenth call here
      setPinCodeDataCheck(false);
      const { 0: { state: stateInputData = "", district: disTric = "", area: areaInputData = "" } = {} } =
        userInfoPinData;
      handleBasicChange({ target: { name: "district", value: disTric } });
      handleBasicChange({ target: { name: "city", value: areaInputData } });
      handleBasicChange({ target: { name: "state", value: stateInputData } });
    }
  }, [userInfoPinData]);

  // set field values from api data
  useEffect(() => {
    if (Object.keys(nullToObject(enquiryBasicInfo))?.length > 0) {
      setName(_get(enquiryBasicInfo, "name", ""));
      setMobile(_get(enquiryBasicInfo, "mobile", ""));
      setEmail(_get(enquiryBasicInfo, "email", ""));
      setGender(_get(enquiryBasicInfo, "gender", ""));
      setLanguage(_get(enquiryBasicInfo, "language", []));
      setType(_get(enquiryBasicInfo, "type", ""));
      setPinCode(_get(enquiryBasicInfo, "pincode", ""));
      setCity(_get(enquiryBasicInfo, "city", ""));
      setDistrict(_get(enquiryBasicInfo, "district", ""));
      setState(_get(enquiryBasicInfo, "state", ""));
      setWhatsappNum(_get(enquiryBasicInfo, "whatsapp_num", ""));
      setAlterMobile(
        _get(customerData, "productInfo.alternativeNumber", "") || _get(enquiryBasicInfo, "alt_mobile", "")
      );
    }
  }, [enquiryBasicInfo]);

  return (
    <div className={`flex-wrap flex border-yellow-400`}>
      <AmeyoWithPhoneButton
        value={mobile}
        name={"mobile"}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        onChange={handleBasicChange}
        labelText={"Customer Mobile"}
        parentClass={inputStylePopup}
        maxLength={staticData.MobileNo}
        isSalesEnquiry={isSalesEnquiry}
        onKeyPress={numOnlyAllow}
        {...(isSalesEnquiry && { disabled: !isEnableMobileEmail })}
      />

      <CommonInput
        name={"email"}
        inputError={false}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        onChange={handleBasicChange}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        inputClass="flex justify-end"
        value={email}
        labelText={"Email ID"}
        {...(isSalesEnquiry && { disabled: !isEnableMobileEmail })}
      />

      <CommonInput
        label={true}
        name={"name"}
        setShow={true}
        placeholder={""}
        inputError={true}
        inputvalid={true}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        mustField={!enableViewMode}
        onChange={handleBasicChange}
        inputWithIconClass="w-10"
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        parentClass={inputStylePopup}
        value={name}
        labelText={"Customer Name"}
        onKeyPress={alphaOnly}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        name={"gender"}
        arrayData={genderDetail}
        readOnly={enableViewMode}
        mustField={!enableViewMode}
        inputWithIconClass="w-10"
        inputFieldClass="h-9 pr-11"
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        parentClass={inputStylePopup}
        handleOnChange={handleBasicChange}
        labelText={"Gender"}
        value={gender}
      />
      {enableViewMode ? (
        <div className={`form-group relative w-full mb-4 sm:mb-4 lg:w-1/2 mt-2 px-4 xl:w-1/4`}>
          <label className={`block text-xs input-label mb-1  font-bold `}>{"Language"}</label>
          {language.map((ele: any, i: number) => {
            return (
              <React.Fragment key={convertToLowerCase(i + "language-dddm6")}>
                {ele} {language.length - 1 === i ? "" : ","}{" "}
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <BasicInfoLang passLangData={passLangData} basicInfoUserInformation={{ language: language }} />
      )}
      {enableViewMode ? (
        <CommonInput
          label={true}
          value={type}
          name={"type"}
          setShow={true}
          inputWithIcon={false}
          inputWithIconClass={""}
          readOnly={enableViewMode}
          inputFieldClass="h-9 pr-16"
          onChange={handleBasicChange}
          labelTextClass={"font-bold"}
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          labelText={"Customer Type"}
        />
      ) : (
        <CommonSearchDropdown
          label={true}
          setShow={true}
          name={"type"}
          mustField={true}
          inputErrorMsg={""}
          inputWithIcon={false}
          inputWithIconClass={""}
          labelTextClass={"font-bold"}
          inputFieldClass="h-9 pr-16"
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          arrayData={mockCusBasicInfoTypes}
          handleParentDropVal={selectCusType}
          value={type}
          labelText={"Customer Type"}
        />
      )}

      {enableViewMode ? (
        <CommonInput
          label={true}
          value={pinCode}
          name={"pincode"}
          inputError={false}
          inputFieldClass="h-9"
          readOnly={enableViewMode}
          labelTextClass={"font-bold"}
          onChange={handleBasicChange}
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          maxLength={staticData.pinCode}
          labelText={"Pincode"}
          onKeyPress={numOnlyAllow}
        />
      ) : (
        <CommonInput
          value={pinCode}
          name={"pincode"}
          inputError={false}
          onChange={handleBasicChange}
          inputWithIcon={true}
          placeholder={"Pincode"}
          inputFieldClass="h-9"
          labelTextClass={"font-bold"}
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          labelText={"Pincode"}
          inputWithIconClass={"input-icon-btn"}
          iconClickAction={searchPincode}
          onKeyPress={numOnlyAllow}
        />
      )}
      <CommonInput
        label={true}
        value={district}
        name={"district"}
        inputError={false}
        inputFieldClass="h-9"
        readOnly={true}
        onChange={handleBasicChange}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        parentClass={inputStylePopup}
        labelText={"District/City"}
      />
      {pinCodeDataCheck === true ? (
        <CommonSearchDropdown
          label={true}
          value={city}
          name={"city"}
          inputError={false}
          placeholder={"Area"}
          inputFieldClass="h-9"
          arrayData={userInfoPinData}
          labelTextClass={"font-bold"}
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          labelText={"Area"}
          handleParentDropVal={selectCityData}
        />
      ) : (
        <CommonInput
          label={true}
          value={city}
          name={"city"}
          readOnly={true}
          placeholder={"Area"}
          inputError={false}
          inputFieldClass="h-9"
          onChange={handleBasicChange}
          labelTextClass={"font-bold"}
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          labelText={"Area"}
        />
      )}

      <CommonInput
        label={true}
        value={state}
        name={"state"}
        inputError={false}
        inputFieldClass="h-9"
        readOnly={true}
        labelTextClass={"font-bold"}
        onChange={handleBasicChange}
        inputClass="flex justify-end"
        parentClass={inputStylePopup}
        labelText={"State"}
      />
      <AdditionalInfo
        isEdit={true}
        whatsappNum={whatsappNum}
        alterMobile={alterMobile}
        enableViewMode={enableViewMode}
        handleBasicChange={handleBasicChange}
      />

      {!enableViewMode && (
        <div className="py-8 bg-lite-white sticky -bottom-8 flex flex-col items-center justify-center w-full">
          <div>
            <button
              type="button"
              onClick={handleSubmit}
              className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
            >
              {"Update"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BasicInfoView;
