import { _get } from "utils/lodashConst";

type logInReducerTypes = {
  loginLoader?: boolean;
  registerLoader?: boolean;
  registerResetInput?: boolean;
  getRegisterUserLoader?: boolean;
  getRegisterUserDetailsData?: any;
  reloadUserGetRegisterData?: any;
  registerUsrStatusChangeloader?: boolean;
};

export const emiInitState = {
  loginLoader: false,
  registerLoader: false,
  registerResetInput: false,
  getRegisterUserLoader: false,
  getRegisterUserDetailsData: {},
  registerUsrStatusChangeloader: false
};

export const loginReducer = (state = emiInitState, action: logInReducerTypes = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "LOGIN_INITIAL_STAGE": {
      return {
        ...state,
        ...emiInitState
      };
    }

    case "LOGIN_INITIAL_LOADER": {
      return {
        ...state,
        loginLoader: action.loginLoader
      };
    }

    case "REGISTER_INITIAL_LOADER": {
      return {
        ...state,
        registerLoader: action.registerLoader,
        registerResetInput: action.registerResetInput
      };
    }

    case "GET_REGISTER_USER_INITIAL_LOADER": {
      return {
        ...state,
        getRegisterUserLoader: action.getRegisterUserLoader
      };
    }

    case "GET_REGISTER_USER_DETAILS_DATA": {
      return {
        ...state,
        getRegisterUserDetailsData: action.getRegisterUserDetailsData
      };
    }

    case "RELOAD_USER_GET_LIST_DATA": {
      return {
        ...state,
        reloadUserGetRegisterData: action.reloadUserGetRegisterData
      };
    }

    case "REGISTER_USER_STATUS_CHANGE_LOADER": {
      return {
        ...state,
        registerUsrStatusChange: action.registerUsrStatusChangeloader
      };
    }

    default:
      return {
        ...state
      };
  }
};
