import React, { useEffect, useState } from "react";
import DatePickerCommon from "Component/CommonTable/CommonStartToEndDatePic/DatePickerCommon";
import { getEvaluatorAudits } from "utils/services/scorecardService";
import { nullToObject } from "utils/validation";
import { moment } from "utils/momentConst";
import EvaluatorAudits from "./EvaluatorAudits";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import { useSelector } from "react-redux";

const EvaluatorMembers = (props: any = {}) => {
  const globalSate = useSelector((state: any) => state) || {};
  const { scorecardReducer = {} } = globalSate; //globalStore
  const { scoreCardDataList = {} } = scorecardReducer;
  const { evaluator = {} } = props;
  const [audits, setAudits] = useState([]);
  const [scoreCardDropProps, setScoreCardDropProps] = useState<any>([]);
  const [userSearch, setUserSearch] = useState({
    page: 1,
    size: 10,
    search: "",
    endDate: moment(),
    startDate: moment().subtract(6, "d"),
    scoreCardId: []
  });

  const fetchEvaluatorAudits = async (evaluatorData: any = {}) => {
    if (evaluatorData.empId) {
      const response = await getEvaluatorAudits(evaluatorData.empId, userSearch);
      const {
        data: { data: auditsData = [], status = 0 }
      } = nullToObject(response);
      if (status === 200) {
        setAudits(auditsData);
      } else {
        setAudits([]);
      }
    }
  };

  useEffect(() => {
    fetchEvaluatorAudits(evaluator);
  }, [evaluator, userSearch]);

  const _onDateChange = (event: any = {}) => {
    const { target: { nameEvalue: endDate = "", value: startDate = "" } = {} } = event;
    setUserSearch({ ...userSearch, startDate, endDate });
  };

  const scorecardFilter = (event: any = {}) => {
    const { originalArray = [] } = event;
    const filterCheck = originalArray.filter((ele: any) => ele.isCheck === true);
    const getId = filterCheck.map((ele: any) => ele._id);
    setScoreCardDropProps(originalArray);
    setUserSearch((ev: any = {}) => ({ ...ev, scoreCardId: getId }));
  };

  useEffect(() => {
    if (Object.keys(scoreCardDataList)?.length) {
      if (scoreCardDataList.data?.length) {
        setScoreCardDropProps(scoreCardDataList.data);
      }
    }
  }, [scoreCardDataList]);

  return (
    <div className="py-5 flex flex-col">
      <div className="flex justify-start px-2">
        <h3>
          {evaluator.name}
          {"'s"} Team
        </h3>
      </div>
      <div className="flex justify-end px-2 border-b pb-1">
        <MultiDropDownNew
          name={"name"}
          bindKeyName={"name"}
          labeName={"Scorecard"}
          isSelectAll={true}
          enableCheckbox={true}
          isOKButtonEnable={true}
          arrayData={scoreCardDropProps}
          filterArrayPass={scorecardFilter}
        />
        <DatePickerCommon userSearch={userSearch} _onDateChange={_onDateChange} />
      </div>

      <div className="flex flex-col">
        <EvaluatorAudits data={audits} />
      </div>
    </div>
  );
};

export default EvaluatorMembers;
