import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { SalesPreebookProps } from "./types";
import { useNavigate } from "react-router-dom";
import CustomerCallType from "../CustomerCallType";
import { outDatedStatus } from "Component/MockData";
import { CalendarIcon } from "@heroicons/react/solid";
import SalesEnquiryLoader from "./SalesEnquiryLoader";
import OutDatedPoductSearch from "./OutDatedPoductSearch";
import EnquiryForRemark from "../SalesReturnsForm/EnquiryForRemark";
import { StyleForminput, inputStylePopup } from "utils/ClassContent";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import { reloadCusListStausRemove } from "Pages/Customers/commonCalllist";
import { justDialDetailsStore } from "Pages/JustDialTable/commonJustDial";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import CommonSingleDatePicker from "Component/CommonTable/CommonSingleDatePicker";
import { getsalesSourceList } from "./SalesProductInformation/commonSalesProduct";
import { createNonDealingApiCall, outDatedProductSearch } from "./commonSalesEnquiry";
import { discriptionErrorMsg, sourceTickeErrorMsgBoolean } from "Pages/CampaignForm/helperCapmpaign";
import {
  checkWhiteSpaces,
  convertToLowerCase,
  discriptionLength,
  errValidateCommon,
  negativeValidateForm,
  nullToObject,
  subjectLength,
  validateForm
} from "utils/validation";

const updatestatus = (value: any = "") => {
  if (value) {
    return false;
  }
  return true;
};

const closeData = [
  { id: 1, isSelect: false, optionData: "Out of Stock" },
  { id: 2, isSelect: false, optionData: "Stopped model" },
  { id: 3, isSelect: false, optionData: "Non Dealing model" }
];

const pendinData = [
  { id: 1, isSelect: false, optionData: "Follow Up" },
  { id: 2, isSelect: false, optionData: "Ringing" },
  { id: 3, isSelect: false, optionData: "Busy" },
  { id: 4, isSelect: false, optionData: "Call Not Connected" }
];

const openData = [
  { id: 1, isSelect: false, optionData: "New" },
  { id: 2, isSelect: false, optionData: "Plan to Purchase" }
];

const defaultData = {
  status: "",
  remark: "",
  currentStatus: "",
  nextFollowUpDate: null
};

const OutdatedModel = (props: SalesPreebookProps) => {
  const {
    fromPage = "",
    columnView = false,
    inModelView = false,
    closePopup = () => {},
    customerData: customerDatas = {}
  } = nullToObject(props);
  const navigate = useNavigate();
  const customerData = nullToObject(customerDatas);

  const { outdated = "", status: editStatus = "", _id: editId = "", source = "" } = customerData;

  const isEdit = convertToLowerCase(fromPage) === convertToLowerCase("edit");
  const isClosed = convertToLowerCase(editStatus) === convertToLowerCase("Closed");

  const globalData: any = useSelector((state) => state || {});
  const { basicInfoReducer: basicInfoReducerData = {} } = globalData;
  const basicInfoReducer = nullToObject(basicInfoReducerData);

  const [sourceData, setSourceData] = useState([]);

  const [callType, setCallType] = useState<any>(true); //call type handle
  const [searchParams, setSearchParams] = useState(""); //search
  const [statusLoader, setStatusLoader] = useState(false);
  const [errorMsgsource, SetErrorMsgsource] = useState(false);
  const [currentStatusData, setCurrentStatusData] = useState([]);
  const [originalsourceData, setOriginalSourceData] = useState([]);

  const [salesStatusData, setSalesStatusData] = useState<any>({
    source: "",
    sourceMsg: "",
    status: "",
    remark: "",
    currentStatus: "",
    nextFollowUpDate: null
  });

  const [errConInfo, setErrConInfo] = useState({
    //name
    currentStatusErr: true,
    currentStatusSpace: true,
    //sourseMsg handle
    sourceErrMsg: false,
    sourceSpaceMsg: true,
    //sourse
    sourceErr: false,
    sourceSpace: false,
    //status
    statusError: true,
    statusSpace: true,
    //description
    remarkErr: true,
    remarkSpace: true
  });

  const [searchResult, setSearchResult] = useState([]); //search dropDown list
  const [sourceEmpty, SetEmptsource] = useState(false);
  const [statusEmpty, SetStatusEmpty] = useState(false);
  const [remarkEmpty, SetRemarkEmpty] = useState(false);
  const [sourcemsgEmpty, SetSourcemsgEmpty] = useState(false);
  const [currentstautsEmpty, SetCurrentstautsEmpty] = useState(false);

  //remark
  const [errorMsgRemarkErr, SetErrorRemarkErr] = useState(false);
  const [errorMsgSpaceRemarkErr, SetErrorMsgSpaceRemarkErr] = useState(false);

  const onUpdateSalesDetails = async () => {
    const newObj = {
      sourceEmpty: updatestatus(searchParams),
      statusEmpty: updatestatus(salesStatusData.status),
      sourceMsgEmpty: updatestatus(salesStatusData.sourceMsg),
      currentstautsEmpty: updatestatus(salesStatusData.currentStatus)
    };
    //check is empty

    if (negativeValidateForm(newObj) === false) {
      if (callType !== null) {
        //call type not enable
        if (validateForm(errConInfo)) {
          //validation
          setStatusLoader(true); //loader on
          const response: any = await createNonDealingApiCall(
            basicInfoReducer,
            {
              ...salesStatusData,
              isEdit: isEdit,
              editId: editId,
              callType: callType,
              searchParams: searchParams
            },
            searchResult
          );
          setStatusLoader(false); //loader off
          const { data: { success = false, message = "" } = {} } = nullToObject(response);

          if (success) {
            setSearchParams("");
            justDialDetailsStore({}); //after success enquiry clear data
            reloadCusListStausRemove(true); //for reload outDated api
            setSalesStatusData(defaultData); //apend default value
            setCallType(true); //call type value append
            successToast(message);
            //only for create for toast purose setTime out
            setTimeout(() => {
              if (!isEdit) {
                //create call navigate to basic info page
                navigate(0);
                closePopup("reload");
              }
            }, 1000);
          } else {
            failToast(message ? message : "Enquiry Fail");
          }
        } else {
          failToast("Please fill mandatory input/clear input");
        }
      } else {
        failToast("Please Select call type");
      }
    } else {
      //if empty means throw error
      !searchParams && SetEmptsource(true);
      !salesStatusData.status && SetStatusEmpty(true);
      !salesStatusData.sourceMsg && SetErrorMsgsource(true);
      !salesStatusData.currentStatus && SetCurrentstautsEmpty(true);
    }
  };

  const errValidateOnchange = (error: any = {}) => {
    const { remarkErr, remarkSpace } = error;
    remarkErr && SetErrorRemarkErr(false);
    remarkSpace && SetErrorMsgSpaceRemarkErr(false);
  };

  const swFieldValidation = (name = "", value = "") => {
    const errors = errConInfo;
    switch (name) {
      case "remark":
        value !== "" ? SetRemarkEmpty(false) : SetRemarkEmpty(true);
        errors.remarkErr = discriptionLength(value);
        errors.remarkSpace = checkWhiteSpaces(value);
        break;
      case "currentStatus":
        value !== "" ? SetCurrentstautsEmpty(false) : SetCurrentstautsEmpty(true);
        errors.currentStatusErr = subjectLength(value);
        errors.currentStatusSpace = checkWhiteSpaces(value);
        break;
      case "status":
        value !== "" ? SetStatusEmpty(false) : SetStatusEmpty(true);
        const currenStateUpdate: any =
          convertToLowerCase(value) === convertToLowerCase("Open")
            ? openData
            : convertToLowerCase(value) === convertToLowerCase("Pending")
              ? pendinData
              : closeData;
        setCurrentStatusData(currenStateUpdate);
        errors.statusError = subjectLength(value);
        errors.statusSpace = checkWhiteSpaces(value);
        break;
      default:
        break;
    }
    setSalesStatusData({
      ...salesStatusData,
      [name]: value,
      status: convertToLowerCase(name) === "status" ? value : salesStatusData.status,
      //remark and current status appen current status value otherwise empty
      currentStatus:
        convertToLowerCase(name) === convertToLowerCase("currentStatus")
          ? value
          : convertToLowerCase(name) === convertToLowerCase("remark")
            ? salesStatusData.currentStatus
            : ""
    });
    setErrConInfo(errors);
    errValidateOnchange(errors);
  };

  const onChange = (event: any = {}) => {
    const { target: { name = "", value = "" } = {} } = event;
    swFieldValidation(name, value);
  };

  const onDatePicker = (datePicker: any = null) => {
    setSalesStatusData({
      ...salesStatusData,
      nextFollowUpDate: datePicker
    });
  };

  const blurErrValidate = () => {
    const {
      remarkErr,
      remarkSpace //des
    } = errConInfo;
    SetErrorRemarkErr(errValidateCommon(salesStatusData.remark, remarkErr)); //if false error msg true otherwise true
    SetErrorMsgSpaceRemarkErr(errValidateCommon(salesStatusData.remark, remarkSpace)); //if false error msg true otherwise true
  };

  /**
   * call type handle
   */
  const callTypeHandle = (eleValue = false) => {
    setCallType(eleValue);
  };

  /**
   * search product
   * @param event
   */
  const searchProduct = async (event: any = {}) => {
    const errors = errConInfo;
    const { target: { value = "" } = {} } = event;
    setSearchParams(value);
    value ? SetEmptsource(false) : SetEmptsource(true);
    errors.sourceErr = subjectLength(value);
    errors.sourceSpace = checkWhiteSpaces(value);
    errValidateOnchange(errors);
    const response = (await outDatedProductSearch(value)) || {}; //api call
    const { status = 0, data: { results = [] } = {} } = nullToObject(response);

    if (status === 200) {
      setSearchResult(results); //search dropDown purpose
    } else {
      setSearchResult([]);
    }
  };

  //set search value
  const handleChooseProduct = (productFulInfo: any = {}) => {
    const { document: { outdated_nondealing = "" } = {} } = productFulInfo;
    if (outdated_nondealing) {
      const errors = errConInfo;
      setSearchParams(outdated_nondealing);
      outdated_nondealing ? SetEmptsource(false) : SetEmptsource(true);
      errors.sourceErr = subjectLength(outdated_nondealing);
      errors.sourceSpace = checkWhiteSpaces(outdated_nondealing);
      errValidateOnchange(errors);
    }
  };

  /**
   * getSourceList
   */
  const getUpdateSourceListApi = async () => {
    const response: any = await getsalesSourceList();
    const { data: { data: { salesSource = [] } = {}, status: statusCode = 0, message = "" } = {} } = response || {};
    if (statusCode === 200) {
      setSourceData(salesSource); //dropdown purpose
      setOriginalSourceData(salesSource); // for filter purpose
    } else {
      setSourceData([]);
      setOriginalSourceData([]);
      failToast(message);
    }
  };

  /**
   * Handle's service center name search
   * @param event event
   */
  const clearInput = (event: any = {}) => {
    const errors = errConInfo;
    const { value = "" } = _get(event, "target", {});
    const dataFilterResult = originalsourceData.filter((e: any) => {
      return convertToLowerCase(e.status).includes(value.toLowerCase());
    });
    value !== "" ? SetSourcemsgEmpty(false) : SetSourcemsgEmpty(true);
    value !== "" && SetErrorMsgsource(false);

    setSourceData(dataFilterResult);
    //check user select dropdown value
    const findSome = dataFilterResult.some((ele: any) => ele.status === value);

    errors.sourceErrMsg = findSome;
    if (value === "") {
      setSalesStatusData({
        ...salesStatusData,
        sourceMsg: value
      });
    }
  };

  const onChangeSource = (event: any = {}) => {
    const { target: { name = "", value = "" } = {} } = event;
    const errors = errConInfo;
    value ? SetSourcemsgEmpty(false) : SetSourcemsgEmpty(true);
    const findSome = originalsourceData.some(
      (ele: any) => convertToLowerCase(ele.status) === convertToLowerCase(value)
    );
    findSome ? SetErrorMsgsource(false) : SetErrorMsgsource(true); //if false error msg true otherwise true

    errors.sourceSpaceMsg = checkWhiteSpaces(value);
    errors.sourceErrMsg = findSome;
    setSalesStatusData({
      ...salesStatusData,
      [name]: value
    });
  };

  useEffect(() => {
    if (convertToLowerCase(fromPage) === convertToLowerCase("edit")) {
      handleChooseProduct({
        //create new object
        document: {
          outdated_nondealing: outdated
        }
      });
    }
  }, [fromPage]);

  useEffect(() => {
    getUpdateSourceListApi();
  }, []);

  useEffect(() => {
    if (source && _get(originalsourceData, "length", 0) > 0) {
      onChangeSource({
        target: {
          name: "sourceMsg",
          value: source
        }
      });
    }
  }, [source, _get(originalsourceData, "length", 0)]);
  return (
    <React.Fragment>
      {/* loader */}
      <SalesEnquiryLoader statusLoader={statusLoader} />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        readOnly={isEdit}
        name={"sourceMsg"}
        labelText={"Source"}
        arrayData={sourceData}
        inputFieldClass="h-9"
        inputWithIcon={false}
        selectorKey={"status"}
        clearInput={clearInput}
        placeholder={"--None--"}
        onBlur={blurErrValidate}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        handleOnChange={onChangeSource}
        parentClass={inModelView && !columnView ? inputStylePopup : StyleForminput()}
        value={salesStatusData.sourceMsg}
        inputError={sourcemsgEmpty ? true : errorMsgsource ? true : false}
        inputErrorMsg={sourcemsgEmpty ? "Field cannot be empty" : errorMsgsource ? "Please select any value" : ""}
      />

      <OutDatedPoductSearch
        mustField={true}
        readOnly={isClosed}
        labelText={"Search"}
        value={searchParams}
        selectorKey={"label"}
        name={"selectedProduct"}
        inputWithIconClass="w-10"
        clearInput={searchProduct}
        dropDownData={searchResult}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        labelTextClass={"font-bold"}
        inputError={sourceEmpty ? true : false}
        inputErrorMsg={sourceEmpty ? "Field cannot be empty" : ""}
        handleChooseProduct={(ele: any) => handleChooseProduct(ele)}
        parentClass={inModelView && !columnView ? inputStylePopup : " mb-4 sm:mb-4 w-full lg:w-full mt-2 px-4"}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        name={"status"}
        mustField={true}
        readOnly={isClosed}
        removeCursor={true}
        labelText={"Status"}
        inputFieldClass="h-9"
        inputWithIcon={false}
        clearInput={onChange}
        arrayData={outDatedStatus}
        placeholder={"--None--"}
        handleOnChange={onChange}
        selectorKey={"optionData"}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        inputError={statusEmpty ? true : false}
        inputErrorMsg={statusEmpty ? "Field cannot be empty" : ""}
        value={!isClosed ? salesStatusData.status : _get(customerData, "status", "")}
        parentClass={inModelView && !columnView ? inputStylePopup : StyleForminput()}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        removeCursor={true}
        readOnly={isClosed}
        inputFieldClass="h-9"
        clearInput={onChange}
        inputWithIcon={false}
        name={"currentStatus"}
        labelText={"Sub Status"}
        placeholder={"--None--"}
        handleOnChange={onChange}
        selectorKey={"optionData"}
        arrayData={currentStatusData}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        inputError={currentstautsEmpty ? true : false}
        inputErrorMsg={currentstautsEmpty ? "Field cannot be empty" : ""}
        parentClass={inModelView && !columnView ? inputStylePopup : StyleForminput()}
        value={!isClosed ? salesStatusData.currentStatus : _get(customerData, "currentStatus", "")}
      />

      {/* folowUp date only for pending */}
      {convertToLowerCase(_get(salesStatusData, "status", "")) === convertToLowerCase("pending") ? (
        <div className={inModelView && !columnView ? inputStylePopup : StyleForminput()}>
          <label className="block text-xs input-label mb-1 font-bold">Follow Up Date</label>
          <div className="relative">
            <CommonSingleDatePicker
              minDate={true}
              readOnly={isClosed}
              id="nextFollowUpDate"
              dateChange={onDatePicker}
              placeholder={"Follow Up Date"}
              parentClass="datePickerWithTime"
              startDate={salesStatusData.nextFollowUpDate}
            />
            <label
              htmlFor="CampaignDate"
              className={`w-8 h-8 absolute flex justify-center items-center cursor-pointer input-icon-color-fill 
                                        top-px bottom-px right-px input-group-icon rounded`}
            >
              <CalendarIcon className="w-5 h-5" />
            </label>
          </div>
        </div>
      ) : null}

      {!isClosed && (
        <CustomerCallType
          readOnly={isClosed}
          callType={callType}
          columnView={columnView}
          inModelView={inModelView}
          callTypeHandle={callTypeHandle}
        />
      )}

      <EnquiryForRemark
        name={"remark"}
        parentClass={""}
        editView={false}
        mustField={false}
        readOnly={isClosed}
        onChange={onChange}
        onBlur={blurErrValidate}
        value={salesStatusData.remark}
        inputError={
          remarkEmpty
            ? true
            : salesStatusData.remark
              ? sourceTickeErrorMsgBoolean(errorMsgRemarkErr, errorMsgSpaceRemarkErr)
              : false
        }
        inputErrorMsg={
          remarkEmpty
            ? "Field cannot be empty"
            : salesStatusData.remark && discriptionErrorMsg(errorMsgRemarkErr, errorMsgSpaceRemarkErr)
        }
      />

      {!isClosed && (
        <div className="py-1 bg-lite-white sticky -bottom-8 flex flex-col items-center justify-center w-full">
          <div>
            <button
              type="button"
              onClick={onUpdateSalesDetails}
              className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
            >
              {isEdit ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default OutdatedModel;
