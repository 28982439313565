import React, { Fragment, useState } from "react";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const MobileViewFilter = (props: any = {}) => {
  const { children, handleCloseMobileViewFilter = () => {} } = props;

  const [getTransitionAnimate, setTransitionAnimate] = useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setTransitionAnimate(true);
    }, 300);
    return () => {
      setTransitionAnimate(false);
    };
  }, []);

  return (
    <Transition.Root show={getTransitionAnimate} as={Fragment}>
      <div className="fixed inset-0 flex z-40 lg:hidden">
        <Transition.Child
          as={Fragment}
          leaveTo="opacity-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-100"
          enter="transition-opacity ease-linear duration-300"
          leave="transition-opacity ease-linear duration-300"
        >
          <div
            onClick={() => handleCloseMobileViewFilter(false)}
            data-jest-id={"jestHandleCloseMobileViewFilter"}
            className="fixed inset-0 bg-black bg-opacity-25"
          />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enterTo="translate-x-0"
          leaveTo="translate-x-full"
          leaveFrom="translate-x-0"
          enterFrom="translate-x-full"
          enter="transition ease-in-out duration-300 transform"
          leave="transition ease-in-out duration-300 transform"
        >
          <div className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl pb-2 flex flex-col overflow-y-auto">
            {/* mobile Filters */}
            <div className="px-4 flex items-center justify-between bg-primary-gradient mb-2 sticky top-0 z-20">
              <h2 className="text-lg font-semibold text-white ">Filters</h2>
              <button
                type="button"
                onClick={() => handleCloseMobileViewFilter(false)}
                className="-mr-3 w-10 h-10 p-2 rounded-md flex items-center justify-center text-white "
              >
                <span className="sr-only">Close menu</span>
                <XIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            {children}
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};
export default MobileViewFilter;
