import store from "store";
import { AmeyoCallAction, getCallRecordingAction } from "store/action/ameyoAction";

/**
 * @param  {object} data
 * loader enable
 * dispatch the ameyo call action
 */
export const ameyoCall = (data: object = {}) => {
  store.dispatch(AmeyoCallAction(data)); //dispatch the imei number action
  store.dispatch({ type: "AMEYO_CALL_LOADER", ameyoLoader: true });
};

/**
 * @param  {object} data
 * recording get data
 * dispatch the ameyo call action
 */
export const getRecordingData = (data: object = {}) => {
  store.dispatch({ type: "GET_CALL_RECORDING_DATA", callRecordingData: {} });
  store.dispatch(getCallRecordingAction(data)); //dispatch the get call recording action
  store.dispatch({ type: "GET_CALL_RECORDING_LOADER", callRecordingLoader: true });
};

/**
 * reset call recording
 */
export const resetCallRecording = (data: object = {}) => {
  store.dispatch({ type: "GET_CALL_RECORDING_DATA", callRecordingData: {} });
  store.dispatch({ type: "GET_CALL_RECORDING_LOADER", callRecordingLoader: false });
};
