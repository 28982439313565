import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { getManualPageAccess, pageNumber } from "helperRole";
import { useEffect, useState } from "react";
import { _get, _isEmpty } from "utils/lodashConst";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { offerDetailsInfoGetForAll } from "../commonSalesProductDetails";
import OfferBanner from "./ProductOffers/OfferBanner";
import ShowOfferDialog from "./ShowOfferDialog";
import "./styles.scss";
import { ProductDetailProps } from "./types";

const OfferDetails = (props: ProductDetailProps) => {
  const {
    productDetails = {} //product details
  } = props || {};

  const { item_code = "" } = nullToObject(productDetails);
  const onlinePermisstion = getManualPageAccess(pageNumber.ONLINE_PRICE_DETAILS); //74
  const { read: onlineRead = false } = nullToObject(onlinePermisstion);

  const offlinePermisstion = getManualPageAccess(pageNumber.OFFLINE_PRICE_DETAILS); //75
  const { read: offlineRead = false } = nullToObject(offlinePermisstion);

  const [tabs, setTabs] = useState({
    tab1: true
  });

  const [offerPopup, setOfferPopup] = useState(false);
  const [channelType, setChannelType] = useState("");
  const [offerLoaders, setOfferLoaders] = useState(true);
  const [offlineOffers, setOfflineOffers] = useState([]);
  const [onlineOffers, setOnlineOffers] = useState([]);

  const handlePopupOffer = (type: string) => {
    setChannelType(type);

    if (
      (convertToLowerCase(type) === "shop" && offlineOffers.length > 0) ||
      (convertToLowerCase(type) === "online" && onlineOffers.length > 0)
    ) {
      setOfferPopup(true);
    }
  };

  const handlePopupOfferClose = () => {
    setOfferPopup(false);
  };

  const getOffers = async (erp_item_code: string, channel: string, setOffers: any) => {
    setOffers([]);
    const { data } = await offerDetailsInfoGetForAll(erp_item_code, channel);
    if (data?.length > 0) setOffers(data);
    return null;
  };

  const callOffers = async (itemcode: string) => {
    await getOffers(itemcode, "ONLINE", setOnlineOffers);
    await getOffers(itemcode, "SHOP", setOfflineOffers);
    setOfferLoaders(false);
  };

  useEffect(() => {
    if (!_isEmpty(item_code)) {
      setOfferLoaders(true);
      callOffers(item_code);
    }
  }, [item_code]);

  return (
    <>
      <div className="flex justify-between text-gray-800 ">
        <button
          type="button"
          title="hide/show"
          onClick={() => setTabs({ ...tabs, tab1: !tabs.tab1 })}
          data-jest-id={"jestSetTabsSales"}
          className={` relative w-full hover:bg-white hover:text-yellow-500 rounded flex justify-between items-center
                                         ${tabs.tab1 ? " text-yellow-500 " : "text-gray-800 "}`}
        >
          <h3 className="w-full text-left py-2 border-b pl-4 pr-5 text-sm">
            Offers ({onlineOffers.length + offlineOffers.length})
          </h3>

          <ChevronDownIcon
            className={`text-gray-400 w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 
                                                ${tabs.tab1 ? "transform rotate-180" : ""} `}
          />
        </button>
      </div>

      {tabs.tab1 && (
        <div>
          <div className=" overflow-y-auto m-1 max-h-60" style={{ minHeight: "150px" }}>
            <div className=" inline-flex flex-wrap h-auto flex-grow-0 w-full">
              {offerLoaders ? (
                <div className="inline-flex items-center">Loading ...</div>
              ) : _get([...onlineOffers, ...offlineOffers], "length", 0) > 0 ? (
                <>
                  {offlineRead && (
                    <OfferBanner
                      offers={offlineOffers}
                      title="Shop"
                      handlePopupOffer={() => handlePopupOffer("SHOP")}
                    />
                  )}

                  {onlineRead && (
                    <OfferBanner
                      offers={onlineOffers}
                      title="Online"
                      handlePopupOffer={() => handlePopupOffer("ONLINE")}
                    />
                  )}
                </>
              ) : (
                <div className="flex justify-center items-center text-sm my-2 w-full flex-grow">
                  <div
                    onClick={() => handlePopupOffer("Online")}
                    className="inline-flex items-center text-gray-600 text-sm"
                  >
                    <ExclamationCircleIcon className="w-4 h-4 mr-1" />
                    <span>No Offers Found</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {offerPopup && (
        <ShowOfferDialog
          handlePopupOfferClose={handlePopupOfferClose}
          channelType={channelType}
          productDetails={productDetails}
          setOfferPopup={setOfferPopup}
        />
      )}
    </>
  );
};

export default OfferDetails;
