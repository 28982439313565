import React, { useEffect, useState } from "react";
import CommonCheck from "./CommonCheck";
import { commonTextareaProps } from "./types";
import { Transition } from "@headlessui/react";
import { IconError, IconSearchCustomer } from "assets/images";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";

const CommonTextarea = (props: commonTextareaProps) => {
  const {
    children,
    name = "",
    value = "",
    label = true,
    labelTextClass,
    labelText = "",
    setShow = false,
    inputClass = "",
    placeholder = "",
    parentClass = "",
    readOnly = false,
    mustField = false,
    inputErrorMsg = "",
    ErrorMsgClass = "",
    onBlur = () => {},
    isSalesPage = false,
    checkBoxVal = false,
    onChange = () => {},
    inputFieldClass = "",
    onBlurChange = false,
    maxLength = 10000000,
    inputWithIcon = false,
    enableCheckBox = false,
    inputWithIconClass = "",
    _handleCheckBox = () => {}
  } = nullToObject(props);

  const [valueInput, setValueInput] = useState("");
  const [getCheckBoxVal, setCheckBoxVal] = useState(checkBoxVal);

  const handleOnchange = (event: any = {}) => {
    const { value: aliceValue = "", name: aliceName = "" } = event.target;
    const continuousNumbers = aliceValue.match(/\d{6,}/);
    if (continuousNumbers && isSalesPage) {
      failToast("You can only enter up to 5 continuous numbers.");
      return;
    }
    setValueInput(aliceValue);
    if (!onBlurChange) {
      //onChange not trigger true means not working on change
      event.preventDefault && event.preventDefault();
      onChange({
        ...event,
        target: {
          name: aliceName,
          value: aliceValue
        }
      });
    }
  };

  const onBlurHandle = (event: any = {}) => {
    if (onBlurChange) {
      event.preventDefault && event.preventDefault();
      const { value: aliceValue = "", name: aliceName = "" } = nullToObject(event.target);
      const continuousNumbers = aliceValue.match(/\d{6,}/);
      if (continuousNumbers) {
        failToast("You can only enter up to 5 continuous numbers.");
        return;
      }
      setValueInput(aliceValue);
      onChange({
        ...event,
        target: {
          name: aliceName,
          value: aliceValue
        }
      });
      onBlur(event);
    } else {
      onBlur(event);
    }
  };

  useEffect(() => setValueInput(value), [value]);

  useEffect(() => setCheckBoxVal(checkBoxVal), [checkBoxVal]);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      {label && (
        <div className="flex items-center">
          <label
            className={`block text-xs input-label mb-1 
                    ${labelTextClass ? labelTextClass : ""}
                    ${inputErrorMsg ? " text-error" : "text-valid"}`}
            htmlFor=""
          >
            {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
          </label>
          {enableCheckBox && (
            <div className="ml-2 relative -top-0.5">
              <CommonCheck
                onClick={() => _handleCheckBox({ target: { name: name, value: getCheckBoxVal } })}
                label={false}
                parentClass={"mr-2"}
                name={name}
                id={convertToLowerCase(name)}
                checked={getCheckBoxVal}
              />
            </div>
          )}
        </div>
      )}

      <div
        className={`
                ${inputClass ? inputClass : ""}
                ${inputWithIcon ? inputWithIcon : ""}
                relative`}
      >
        <textarea
          rows={4}
          cols={50}
          name={name}
          value={valueInput}
          autoComplete={"off"}
          readOnly={readOnly}
          id="userTypingInput"
          onChange={(e) => handleOnchange(e)}
          onBlur={onBlur ? onBlurHandle : () => {}}
          maxLength={maxLength ? maxLength : 100000}
          placeholder={placeholder ? placeholder : ""}
          className={`block w-60 rounded input-common px-3 text-sm py-1
                    ${inputErrorMsg ? " error pr-10" : "input-valid"} ${inputFieldClass ? inputFieldClass : ""}
                `}
        />

        {inputErrorMsg && (
          <i className="inline-block  absolute top-1/2 right-3 transform -translate-y-1/2">
            <IconError />
          </i>
        )}

        {inputWithIcon && (
          <span className={`absolute flex justify-center items-center ${inputWithIconClass ? inputWithIconClass : ""}`}>
            <IconSearchCustomer />
          </span>
        )}
      </div>

      {inputErrorMsg && (
        <span className={`absolute -bottom-5 right-5 error-msg text-xs ${inputErrorMsg ? ErrorMsgClass : ""}`}>
          {inputErrorMsg}
        </span>
      )}
      {children}
    </Transition>
  );
};

export default CommonTextarea;
