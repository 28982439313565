import { _get } from "utils/lodashConst";
import { put, call, takeLatest, all } from "redux-saga/effects";
import * as salesReturnType from "../actionTypes/salesReturnType";
import {} from "utils/services/comListService";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import {
  createsalesReturnServices,
  getImeiNumberBasedServices,
  updateSalesRetunStatusServices,
  updateSalesRetunStatusEditServices,
  historyTransactionBasedgetUserDetails,
  getImeinumberBasedTransService,
  getEmployeeDetailServices
} from "utils/services/salesReturnService";
import { justDialDetailsStore } from "Pages/JustDialTable/commonJustDial";

/**
 * @param  {object} obj
 * get details data from IMEI number based data
 */
function* imeiBasedGetDetailSaga(obj = {}): any {
  try {
    const response = yield call(getImeiNumberBasedServices, obj);
    const { status = 0, data: { Table = [] } = {} } = response || {};
    if (status === 200) {
      if (Table.length >= 1) {
        yield all([
          put({ type: "GET_DETAILS_BASED_IMEI_BASED_DATA", imeiNumbasedUserData: Table }), //userData
          put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER", imeiNumLoader: false }) //loaderOff
        ]);
      } else {
        yield all([
          put({ type: "GET_DETAILS_BASED_IMEI_BASED_DATA", imeiNumbasedUserData: [] }), //userData
          put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER", imeiNumLoader: false }) //loaderOff
        ]);
      }
    } else {
      yield all([
        put({ type: "GET_DETAILS_BASED_IMEI_BASED_DATA", imeiNumbasedUserData: [] }), //userData
        put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER", imeiNumLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "GET_DETAILS_BASED_IMEI_BASED_DATA", imeiNumbasedUserData: [] }), //userData
      put({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER", imeiNumLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {object} obj
 * get user transaction details data from IMEI number based data
 */
function* imeiBasedGetUserTransactionHistory(obj = {}): any {
  try {
    const response = yield call(getImeinumberBasedTransService, obj);
    const { data: { data = {}, status = 0 } = {} } = response || {};

    if (status === 200) {
      yield all([
        put({ type: "GET_TRANSACTION_BASED_IMEI_BASED_DETAILS", imeiNumHistoryDetails: data }), //userData
        put({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "GET_TRANSACTION_BASED_IMEI_BASED_DETAILS", imeiNumHistoryDetails: {} }), //userData
        put({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "GET_TRANSACTION_BASED_IMEI_BASED_DETAILS", imeiNumHistoryDetails: {} }), //userData
      put({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {object} obj
 * create sales retun
 */
function* createSalesReturnData(obj = {}): any {
  try {
    const response = yield call(createsalesReturnServices, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 201) {
      successToast(message);
      justDialDetailsStore({}); //after success enquiry clear data
      yield all([
        put({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: false }), //loaderOff
        put({ type: "SALES_RETURN_DATA_SUCCESS_CODE", salesReturnSuccessCode: true }) //status
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: false }),
        put({ type: "SALES_RETURN_DATA_SUCCESS_CODE", salesReturnSuccessCode: false }) //loaderOff
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: false }),
      put({ type: "SALES_RETURN_DATA_SUCCESS_CODE", salesReturnSuccessCode: false }) //loaderOff
    ]);
  }
}

/**
 * @param  {object} obj
 * update sales retun status
 */
function* updateSalesRetunStatusSaga(obj = {}): any {
  try {
    const response = yield call(updateSalesRetunStatusServices, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: true }),
        put({ type: "SALES_RETURN_STATUS_UPDATE_LOADER", salesReturnSatusUpdateLoader: false })
      ]);
      successToast(message);
    } else {
      failToast(message);
      yield all([
        put({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: null }),
        put({ type: "SALES_RETURN_STATUS_UPDATE_LOADER", salesReturnSatusUpdateLoader: false })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: null }),
      put({ type: "SALES_RETURN_STATUS_UPDATE_LOADER", salesReturnSatusUpdateLoader: false })
    ]);
  }
}

/**
 * @param  {object} obj
 * get user details based on emp id
 */
function* getEmpDetailBaseEmpIdSaga(obj: any = {}): any {
  const { data: { isRegister = false, loaderType = "", loaderVal = "" } = {} } = obj;
  const storeType = isRegister ? "REG_STORE_ACTUAL_EMPLOYEE_DETAILS" : "STORE_EMP_DETAILS_BASED_EMP_ID";
  try {
    if (isRegister) yield put({ type: loaderType, [loaderVal]: true }); //loader
    const response = yield call(getEmployeeDetailServices, obj);
    const { status = 0, data = [] } = response || {};

    if (status === 200) {
      if (_get(data, "error", "") === "") {
        const [employee] = data;
        if (isRegister) {
          const { status: empStatus = "" } = employee;
          const isInActive = empStatus === "Inactive";
          yield all([
            put({ type: loaderType, [loaderVal]: false }), //loader
            put({ type: storeType, empDetailsBasedEmpId: !isInActive ? employee : {} }) //employee details
          ]);
          if (isInActive) failToast("Employee is Inactive");
        } else {
          yield all([
            put({ type: loaderType, [loaderVal]: false }), //loader
            put({ type: storeType, empDetailsBasedEmpId: employee }) //employee details
          ]);
        }
      } else {
        failToast(_get(data, "error", ""));
        yield all([
          put({ type: loaderType, [loaderVal]: false }),
          put({ type: storeType, empDetailsBasedEmpId: {} }) //employee details
        ]);
      }
    } else {
      yield all([
        put({ type: loaderType, [loaderVal]: false }), //loader
        put({ type: storeType, empDetailsBasedEmpId: {} }) //employee details
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: loaderType, [loaderVal]: false }), //loader
      put({ type: storeType, empDetailsBasedEmpId: {} }) //employee details
    ]);
  }
}

/**
 * @param  {object} obj
 * sales retyun edit
 */
function* updateSalesReturnViaEditOption(obj = {}): any {
  try {
    const response = yield call(updateSalesRetunStatusEditServices, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: true }), //loader
        put({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: false }) //loader
      ]);
      successToast(message);
    } else {
      yield all([
        put({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: null }),
        put({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: false })
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: null }),
      put({ type: "SALES_RETURN_DATA_LOADER", salesReturnLoader: false }),
      put({ type: "SALES_RETURN_STATUS_UPDATE_LOADER", salesReturnSatusUpdateLoader: false })
    ]);
  }
}

/**
 * @param  {object} obj
 * getUserDetails on inoice and Date passed
 */
function* getUserDetailBasedOnInvoiceSage(obj: any = {}): any {
  try {
    const response = yield call(historyTransactionBasedgetUserDetails, obj);
    const { data: { Table = "" } = {}, status = 0 } = response || {};
    if (status === 200) {
      let tableData = _get(Table, "[0].ITEMDETAILS.Table", []);
      tableData = tableData.map((item: any) => {
        item["PARAENT_KEY"] = obj.data.inVoiceNo;
        return item;
      });
      yield all([
        put({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_LOADER", invoiceBasedDetailLoader: false }), //loader
        put({
          type: "GET_USER_DETAILS_BASED_INVOICE_DATE_DETAILS",
          payload: {
            invoiceBasedDetails: Table,
            groupInvoiceBasedDetails: { [obj.data.inVoiceNo]: tableData },
            groupInvoiceBasedDetailsResponse: { [obj.data.inVoiceNo]: Table[0] }
          }
        }) //data
      ]);
    } else {
      yield all([
        put({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_LOADER", invoiceBasedDetailLoader: false }), //loader
        put({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_DETAILS", invoiceBasedDetails: [] }) //data
      ]);
    }
  } catch (error) {
    yield all([
      put({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_LOADER", invoiceBasedDetailLoader: false }), //loader
      put({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_DETAILS", invoiceBasedDetails: [] }) //data
    ]);
  }
}

export default function* salesReturnSaga() {
  yield takeLatest(salesReturnType.UPDATE_SALES_RETURN_DETAILS, createSalesReturnData);
  yield takeLatest(salesReturnType.GET_EMP_DETAILS_AND_DATA, getEmpDetailBaseEmpIdSaga);
  yield takeLatest(salesReturnType.IMEI_NUMBER_BASED_GET_DETAILS, imeiBasedGetDetailSaga);
  yield takeLatest(salesReturnType.UPDATE_SALES_RETURN_STATUS, updateSalesRetunStatusSaga);
  yield takeLatest(salesReturnType.UPDATE_SALES_RETURN_DETAILS_EDIT_DATA, updateSalesReturnViaEditOption);
  yield takeLatest(salesReturnType.GET_USER_IMEI_AND_DETAIL_BASED_INVOICE_DATE, getUserDetailBasedOnInvoiceSage);
  yield takeLatest(salesReturnType.IMEI_NUMBER_BASED_GET_TRANSACTION_DETAILS, imeiBasedGetUserTransactionHistory);
}
