/* eslint max-lines: 0 */

import { Decrypt } from "./secureFile";
import { moment } from "utils/momentConst";
import { envPath } from "../ApiProcess/httpRestServices/apiManage";
import { _get } from "utils/lodashConst";
import { ImgCsv, ImgDoc, ImgFile, ImgPdf, ImgTxt, ImgXls } from "assets/images";

type actionByType = {
  date: string | Date | number;
};

type transactionType = {
  actionBy: actionByType;
};

const headerData = {
  area: "Area",
  phone1: "Phone1",
  address: "Address",
  district: "District",
  state: "State",
  pinCode: "PinCode",
  product: "Product",
  currentStatus: "Sub Status",
  status: "Status",
  remarks: "Remarks",
  additionalRemarks: "Additional remarks",
  InvolvedEmpContactNo: "Involved Employee Contact No",
  InvolvedEmpName: "Involved Employee Name",
  InvolvedEmpId: "Involved Employee Emp Id",
  actionTaken: "ActionTaken",
  caseNumber: "Case Number",
  concernType: "Concern Type",
  contactNumber: "Contact Number",
  emailId: "Email Id",
  hearingDate: "Hearing date",
  internalDecision: "Internal Decision",
  nameOfThePerson: "name of the person",
  nextFollowUpDate: "Next Followup date",
  typeOfIssue: "Type Of Issue"
};

/**
 * sep 12 this kind of date convert
 * @param value
 */
export const dateMonthOnlyConvert = (value = 0) => {
  if (value) {
    try {
      return moment(value).format("MMM DD");
    } catch {
      return "";
    }
  }
  return "";
};

export const manualDownloadUrlBased = (url = "", name = "", isCSV = false) => {
  const link = document && document.createElement("a");
  link.href = url;
  link.setAttribute("download", name);
  if (isCSV) link.download = `${name}.csv`;
  document && document.body.appendChild(link);
  link.click();
};

/**
 * convert title case
 * @param string
 */
export const convertToTitleCase = (convertValue: any = "") => {
  return convertValue?.toLowerCase()?.replace(/(?:^|\s|-)\w/g, function (match: any) {
    return match?.toUpperCase();
  });
};

/**
 * convert lower case
 * @param string
 */
export const convertToLowerCase = (convertValue: any = "") => {
  return convertValue ? ("" + convertValue).toLowerCase() : "";
};

/**
 * convert upper case
 * @param string
 */
export const convertToUpperCase = (convertValue: any = "") => {
  return convertValue ? ("" + convertValue).toUpperCase() : "";
};

/**
 * convert string
 * @param string
 */
export const convertToString = (convertValue = "") => {
  return convertValue ? "" + convertValue : "";
};

/**
 * check is null or not ,is null mean retun obj
 * @param obj
 */
export const nullToObject = (obj: any = null) => {
  if (obj) {
    return typeof obj === "object" ? obj : {};
  }
  return {};
};

/**
 * check is null or string
 * @param str
 */
export const nullToString = (str: any = null) => {
  if (str) {
    return typeof str === "string" ? str : "";
  }
  return "";
};

/**
 * check is null or not ,is null mean retun obj
 * @param obj
 */
export const nullToArray = (array: any = null) => {
  return Array.isArray(array) ? array : [];
};

/**
 * @param value excel validation
 */
export const excelFileValidate = (value: any = "") => {
  const allowFormet = ["csv"];
  const extension: any = value.split(".").pop();
  return allowFormet.includes(extension);
};

/**
 * current envirnment is prod or not find
 */
export const findProdEnvironMent = () => {
  return envPath === "prod" ? true : false;
};

/**
 * mobile number validate
 */
export const mobileNumValidate = (number = "") => {
  if (("" + number)?.length === 10) {
    return true;
  }
  return false;
};

/**
 * @param  {} email=""
 * email validate
 */
export const EmailValidate = (email = "") => {
  const reg = /^\s*([\w+-]+\.)*[\w+]+@([\w+-]+\.)*([\w+-]+\.[a-zA-Z]{2,8})+\s*$/;
  if (!reg.test(email)) {
    return false;
  }
  return true;
};

/**
 * @param  {} input=""
 * validate given input is email or not
 */
export const isEmail = (input = "") => {
  const reg = /\@/;
  if (!reg.test(input)) {
    return false;
  }
  return true;
};

/**
 * @param email
 * poorvika email validate
 */
export const EmailValidatePoorvika = (email = "") => {
  const reg = /^\s*([\w+-]+\.)*[\w+]+@([\w+-]+\.)*([\w+-]+\.[a-zA-Z]{2,4})+\s*$/;
  if (!reg.test(email)) {
    return false;
  }
  if (email.indexOf("@poorvika.com", email.length - "@poorvika.com".length) !== -1) {
    return true;
  }
  return false;
};

/**
 * @param  {string} password
 * password allow user stories format
 * 1 Cap char
 * 1 Small Char
 * 1 Number
 * 8 digit Must
 */
export const passwordValidate = (password = "") => {
  const reg = /^(?=.*\d)(?=.*['()+,.\/:;<>=?\[\]\-_`{}|~!@#$%^&"*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  if (reg.test(password)) {
    return true;
  }
  return false;
};

export const validateForm = (errors = {}) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val !== true) {
      valid = false;
    }
  });
  return valid;
};

export const negativeValidateForm = (errors = {}) => {
  let valid = false;
  Object.values(errors).forEach((val) => {
    if (val !== false) {
      valid = true;
    }
  });
  return valid;
};

/**
 * validate object with empty message
 * @param errors object
 * @returns array
 */

export const validateObjectWithMessage = (errors = {}) => {
  const valid: any = [];
  Object.keys(errors).forEach((val) => {
    const value = (errors[val] || "").trim();
    if (!value && val !== "EMailId") {
      valid.push(headerData[val] || val);
    }
    if (val === "EMailId" && !EmailValidate(errors[val])) {
      valid.push(headerData["emailId"]);
    }
  });
  return valid.length ? valid.join(" , ") + " is mandatory." : false;
};

export const emptyValidateForm = (errors = {}) => {
  let valid = true;
  Object.values(errors).forEach((val) => {
    if (val === "") {
      valid = false;
    }
  });
  return valid;
};

/**
 * input filed not empty hide error msg
 */
export const findEmptyOrNotLoginInput = (value = "") => {
  return value ? true : false;
};

/**
 * error msg handle input
 */
export const emptyInputErrorMsgThrow = (value = "") => {
  return value ? false : true;
};

/**
 * @param  {object} event
 * input allow only number
 */
export const numOnlyAllow = (event: any = {}) => {
  // eslint-disable-next-line wrap-regex
  if (!/[0-9/.]/.test(event.key)) {
    event && event.preventDefault && event.preventDefault();
  }
};

export const numOnlyAllowAndComma = (event: any = {}) => {
  // eslint-disable-next-line wrap-regex
  const regex = /^[0-9 ,]+$/;
  if (!regex.test(event.key)) {
    event && event.preventDefault && event.preventDefault();
  }
};

/**
 * @param  {object} event
 * input allow only letter
 */
export const alphaOnly = (event: any = {}) => {
  const inputValue = event.which;
  // allow letters and whitespaces only.
  if (!(inputValue >= 65 && inputValue <= 122) && inputValue !== 32 && inputValue !== 0) {
    event.preventDefault();
  }
};

/**
 * is internet status return
 */
export const internetStatusDecrypt = () => {
  return Decrypt("onlineStatus");
};

export const phoneNumValidate = (value = "") => {
  return _get(value, "length", 0) === 10;
};

export const userNameLength = (value = "") => {
  return _get(value, "length", 0) <= 100 && _get(value, "length", 0) >= 1;
};

export const subjectLength = (value = "") => {
  return _get(value, "length", 0) <= 250 && _get(value, "length", 0) >= 1;
};

export const discriptionLength = (value = "") => {
  return _get(value, "length", 0) <= 2000 && _get(value, "length", 0) >= 1;
};

export const errValidateCommon = (stateValue: any = "", defaultValue: any = false) => {
  return stateValue ? !defaultValue : false;
};

export const checkWhiteSpaces = (value = "") => {
  if (typeof value !== "string") {
    // check if the string variable is some type other than string
    return false;
  } else {
    return value.trim()?.length > 0;
  }
};

/**
 * @param  {string} value
 * Remove White space
 * lead and trail
 */
export const leadingAndTrailingSpaceAvoid = (value = "") => {
  if (value) {
    const removeExtraSpace = (s: any) => (s || "").trim().split(/ +/).join(" ");
    return removeExtraSpace(value);
  }
  return "";
};

/**
 * @param  {string} number
 * number must be 10
 */
export const emptyValidate = (value = "") => {
  if (nullToString(value)?.length >= 1) {
    return true;
  }
  return false;
};

/**
 * @param  {string} number
 * dynamic length check
 */
export const dynamiCLengthValiDate = (value = "", lengthData = 0) => {
  if (("" + value)?.length >= lengthData) {
    return true;
  }
  return false;
};

/**
 * @param  {string} number
 * number to date convert
 * "DD/MM/YYYY"
 */
export const getDateConvert = (value = "") => {
  if (value) {
    const stringCovert = value.toString();
    try {
      return moment(stringCovert).format("DD/MM/YYYY");
    } catch {
      return "-";
    }
  } else {
    return "";
  }
};

/**
 * @param  {string} number
 * number to date convert
 * "DD/MM/YYYY"
 */
export const getDateTimeConvert = (value = "") => {
  if (value) {
    const stringCovert = value.toString();
    try {
      return moment(stringCovert).format("lll");
    } catch {
      return "-";
    }
  }
  return "";
};

/**
 * @param  {string}
 * string to date convert
 * "DD/MM/YYYY"
 */

export const dateFormat = (value = "") => {
  if (value) {
    const stringCovert = value.toString();
    try {
      const momentObj = moment(stringCovert, "DD-MM-YYYY");
      return momentObj.format("DD-MM-YYYY");
    } catch {
      return "-";
    }
  } else {
    return "";
  }
};

/**
 * @param  {string} number
 * number to date convert
 * "MM/DD/YYYY"
 */
export const getDateConvertAgeDevice = (value = "") => {
  if (value) {
    const stringCovert = value.toString();
    try {
      return moment(stringCovert).format("MMM/DD/YYYY");
    } catch {
      return "-";
    }
  } else {
    return "";
  }
};

/**
 * @param  {string} number
 * now date
 */
export const nowDateWithTime = () => {
  try {
    return moment().format("YYYY-MM-DDT00:00");
  } catch {
    return "-";
  }
};

/**
 * @param  {string} number
 * now date
 */
export const nowDate = (type: any = "MM/DD/YYYY") => {
  try {
    return moment().format(type);
  } catch {
    return "-";
  }
};

/**
 * @param  {string} number
 * number to date convert
 */
export const timeConverting = (value = "") => {
  if (value) {
    const stringCovert = value.toString();
    try {
      return moment(stringCovert).format("YYYY-MM-DDT00:00");
    } catch {
      return "-";
    }
  } else {
    return "";
  }
};

/**
 * @param  {string} number
 * date with time retuen
 */
export const dateTimeDisplay = (value = "") => {
  if (value) {
    try {
      const stringCovert = value.toString();
      return moment(stringCovert).format("DD/MM/YYYY HH:mm:ss");
    } catch {
      return "-";
    }
  }
  return "nill";
};

/**
 * @param  {string} number
 * date with time retuen
 */
export const dateUTCTimeDisplay = (value = "") => {
  if (value) {
    try {
      const stringCovert = value.toString();
      const momentDate = moment.utc(stringCovert);
      return moment(momentDate).format("DD/MM/YYYY HH:mm:ss");
    } catch {
      return "-";
    }
  }
  return "nill";
};

/**
 * @param  {string} number
 * ToDate convert
 */
export const dateTimeDisplayToDate = (value = "") => {
  if (value) {
    try {
      return moment(value).toDate();
    } catch {
      return false;
    }
  }
  return "";
};

/**
 * @param  {string} value
 * doc check
 */
export const docValidate = (value = "") => {
  const allowFormet = ["doc", "docx", "pdf", "odt", "txt", "xlsx", "xls", "csv"];
  const extension: any = value.split("/").pop();
  return allowFormet.includes(extension);
};

/**
 * @param  {string} value
 * doc check
 */
export const getDocType = (value = "") => {
  const allowFormet = ["doc", "docx", "pdf", "odt", "txt", "xlsx", "xls", "csv"];
  const extension: any = value.split("/").pop();
  return allowFormet.includes(extension) ? extension : "";
};

/**
 * @param  {string} value
 * image type check
 */
export const imageTypeCheck = (value = "") => {
  const allowFormet = ["jpg", "jpeg", "png"];
  const extension: any = value.split("/").pop();
  return allowFormet.includes(extension);
};

/**
 * moment library local timeGet
 */
export const utcTolocalTimeCall = (date = "") => {
  return moment().format("MMM DD,YYYY");
};

/**
 * @param  {string} value=""
 * @param  {string} stringLength=1000 Default value is 1000
 * text length is moreThen fixed place disPlay like ...
 */
export const trimCharacters = (value: any = "", stringLength = 100000000) => {
  if (nullToString(value) !== "") {
    return value.toString()?.length > stringLength ? value.substring(0, stringLength).concat("...") : value;
  }
  return "";
};

/**
 * @param  {string} value=""
 */
export const DateTimeFormeterTimeing = (value: any = "") => {
  return value ? moment(value).format("YYYY-MM-DDTHH:mm") : "";
};

/**
 * @param  {string} value=""
 */
export const DateTimeFormeterStart = (value = "") => {
  return value ? moment(value).format("YYYY-MM-DDT00:00") : "";
};

/**
 * @param  {string} value=""
 */
export const DateTimeFormeterEnd = (value = "") => {
  return value ? moment(value).format("YYYY-MM-DDT23:59") : "";
};

/**
 * @param  {string} number
 * ToDate convert
 */
export const dateToString = (value = "") => {
  try {
    return (
      value &&
      "" + new Date(value).getDate() + "/" + (new Date(value).getMonth() + 1) + "/" + new Date(value).getFullYear()
    );
  } catch {
    return false;
  }
};

/**
 * @param  {string} number
 * date to millisec conevrt
 */
export const convertDateToMillisec = (value: any = "") => {
  try {
    return value ? moment(value).valueOf() : 0;
  } catch {
    return 0;
  }
};

/**
 * @param  {string} number
 * date to millisec conevrt
 */
export const couponConvertMillisec = (value: any = "") => {
  try {
    return value ? convertDateToMillisec(moment(value, "DD.MM.YYYY H:mm:ss").toISOString()) : 0;
  } catch {
    return 0;
  }
};

/**
 * @param  {string} number
 * two date diffent calculation
 */
export const getDaysDifferentCalculation = (startDate = "", endDate = "") => {
  try {
    // const currenDate = moment(new Date()).format("DD/MM/YYYY");
    const nowDates = moment(startDate).format("YYYY-MM-DD");
    const expire = moment(endDate).format("YYYY-MM-DD");
    const end = moment(expire);
    return end.diff(nowDates, "days");
  } catch {
    return 0;
  }
};

/**
 * @param  {string} number
 * two date diffent calculation
 * open Date and now date
 */
export const getOpenToNowDaysDifferentCalculation = (startDate: any = "") => {
  try {
    const start = moment(startDate); //todays date
    const end = moment(new Date()); // another date
    const duration = moment.duration(end.diff(start));
    const days = duration.asDays();
    return Math.round(days);
  } catch {
    return 0;
  }
};

/**
 * @param  {string} number
 * compaer is created date is today Date
 */
export const isCompareDateIsToDay = (startDate = "") => {
  try {
    return startDate ? moment().isSame(startDate, "day") : 0;
  } catch {
    return 0;
  }
};

export const getDaysDifferent = (ele: any = {}) => {
  const { createdBy: { date: createdDate = "" } = {}, status = "" } = ele;
  const { updatedBy = {} } = ele; //get open status last index from array
  const { date = "" } = updatedBy; //get open status date
  const findDateDifferent =
    convertToLowerCase(status) === "closed"
      ? getDaysDifferentCalculation(createdDate, date)
      : getOpenToNowDaysDifferentCalculation(createdDate);
  return isNaN(findDateDifferent) ? "--" : findDateDifferent;
};

export const diffColorChange = (value: any = 0) => {
  const commonStyle = " px-2 py-1 text-white rounded-full font-bold whitespace-nowrap mx-auto ";
  if (value > 10) {
    return `${commonStyle} bg-red-500 `;
  } else if (value >= 6) {
    return `${commonStyle} bg-yellow-500 `;
  } else if (value < 6) {
    return `${commonStyle} bg-green-500 `;
  }
  return `${commonStyle} bg-blue-500 `;
};

/**
 * @param  {string} value=""
 * enter value has been change upperCase
 * first letter convert to upperCase
 */
export const firstLetterUpperCase = (value = "") => {
  return (value || "")
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const handleDoctypesImage = (doctype = "") => {
  switch (doctype) {
    case "pdf":
      return ImgPdf;
    case "doc":
      return ImgDoc;
    case "xlsx":
      return ImgXls;
    case "xls":
      return ImgXls;
    case "txt":
      return ImgTxt;
    case "csv":
      return ImgCsv;
    default:
      return ImgFile;
  }
};

/**
 * number to currency convert
 * @param number
 */
export const numberToCurrencyConvert = (number = 0) => {
  const convertNum = +number;
  return new Intl.NumberFormat("en-IN", { style: "currency", currency: "INR" }).format(convertNum);
};

/**
 * On paste
 * @param event event
 */
export const onPasteHandle = (event: any, type = "") => {
  const { clipboardData } = event;
  const pastedData = clipboardData.getData("Text");
  let regEx;
  if (type === "NUM_ONLY") regEx = /^\d+$/;
  else regEx = /^[a-zA-Z ]*$/;
  if (!regEx.test(pastedData)) event.preventDefault && event.preventDefault();
};

/**
 * convert paisa to amt
 * @param value
 */
export const paisaToAmount = (value: any = "") => {
  if (!value) {
    return "nill";
  }
  let passData = value.toString().slice(0, -2);
  passData = passData.toString();
  let lastThree = passData.substring(passData.length - 3);
  const otherNumbers = passData.substring(0, passData.length - 3);
  if (otherNumbers !== "") lastThree = "," + lastThree;
  return otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
};

export const formatIndianCurrency = (amount: any = 0, digit: any = 2, isCurrency = true) => {
  const options = {
    ...(isCurrency && { style: "currency", currency: "INR" }),
    minimumFractionDigits: digit,
    maximumFractionDigits: digit
  };
  const formattedAmount = new Intl.NumberFormat("en-IN", options).format(Number(amount));
  return formattedAmount;
};

/**
 * @param  {object} event
 * input allow only number
 */
export const processInput = (e: any = {}) => {
  const value = _get(e, "target.value", "");
  const numbers = value.replace(/[^0-9]/g, "");
  e.target.value = numbers;
};

/**
 * @param  {object} event
 * input allow only number and char
 */
export const allowOnlyNumAndChar = (e: any = {}) => {
  const value = _get(e, "target.value", "");
  const numbers = value.replace(/[a-zA-Z0-9]/g, "");
  e.target.value = numbers;
};

/**
 * @param  {object} event
 * input allow char
 */
export const allowOnlyChar = (e: any = {}) => {
  const value = _get(e, "target.value", "");
  const char = value.replace(/[^a-zA-Z ]/g, "");
  e.target.value = char;
};

/**
 * @param  {Array} event
 * remove undefined value
 */
export const arrayUndefinedRemoved = (arrayData: any = []) => {
  return arrayData.filter((ele: any) => ele !== undefined);
};

/**
 * @param  {Array} event
 * upload size handle
 */
export const fileUploadSizeValidate = (size: any = 0, uploadSize = 0) => {
  if (size < uploadSize) {
    return true;
  }
  return false;
};

/**
 * @param  {String} event
 * convert date number to date
 */
export const convertNumberToDate = (dateNumber: string) => {
  const strDate = "" + dateNumber;
  return [strDate.slice(6, 8), "/", strDate.slice(4, 6), "/", strDate.slice(0, 4)].join("");
};

export const handleCurrentUser = (
  name: string,
  branch: string,
  employeeId: string,
  loggedInId: string,
  customerId = "",
  type = ""
) => {
  if (employeeId === loggedInId) return `Self - ${branch}`;
  return `${name} - ${customerId ? type : branch}`;
};

export const getSortedTrans = (a: transactionType, b: transactionType) => {
  const { date: aDate = "" } = a.actionBy || {};
  const { date: bDate = "" } = b.actionBy || {};
  return new Date(bDate).getTime() - new Date(aDate).getTime();
};

/**
 * convert to milli sec
 * @param dateString string
 * @returns milli sec
 */
export const convertUTCToLocalTime = (dateString: any) => {
  if (dateString) {
    const date = new Date(dateString);
    return Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    );
  }
  return 0;
};
