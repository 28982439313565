import React, { useEffect, useState } from "react";
import store from "store";
import { _get } from "utils/lodashConst";
import { Decrypt } from "utils/secureFile";
import { pageObjectFind } from "helperRole";

import { updateSalesRetunStatusApi } from "../../commonSalesReturn";
import { convertToSRNAPiCall } from "./ConvertToSRN/commonConvertToSRN";
import { failToast, infoToast, successToast } from "Component/ToastServices/ToastDisplay";
import { numOnlyAllow, getDateConvert, convertToLowerCase, nullToObject } from "utils/validation";
import {
  MockSalesStatus,
  MockSalesStatusApproved,
  MockSalesStatusClosed,
  MockSalesStatusPending,
  rejectClosed
} from "Component/MockData";
import { bg_EnquiryStatusUpdate, inputStylePopup, actionPopupBtnStyleGreen } from "utils/ClassContent";
import ConvertToSRN from "./ConvertToSRN";
import CommonTable from "Component/CommonTable";
import ActionPopup from "Component/ActionPopup";
import CommonInput from "Component/Form/CommonInput";
import EnquiryForRemark from "../../EnquiryForRemark";
import ModalFixed3 from "Component/Modal/ModalFixed3";
import EnquiryStatusTrackingTable from "./EnquiryStatusTrackingTable";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import CustomerCallType from "Pages/NewCall/EnquiryFor/CustomerCallType";
import ConvertSRNbutton from "./ConvertToSRN/ReplacementFormUpdate/ConvertSRNbutton";

const StatusUpdateSalesReturn = (props: any = {}) => {
  const {
    editView = false,
    viewModeData = {},
    columnView = false,
    fromNonEdit = false //avoid to edit
  } = nullToObject(props);

  const salesReturnPermisstion = pageObjectFind();
  const { write } = nullToObject(salesReturnPermisstion);

  const {
    _id = "",
    status = "",
    approved = {},
    subCategory = "",
    transactions = [],
    currentStatus = "",
    callType: getCallType = false,
    srnValue: invoiceAmount = ""
  } = viewModeData;

  // convert srn button enable and disable handle
  const convertSRNdisplay =
    convertToLowerCase(subCategory) === convertToLowerCase("Approval Request") &&
    convertToLowerCase(currentStatus) === convertToLowerCase("Approved")
      ? true
      : false;
  const srnConvert = subCategory.toLowerCase();
  const loginUserDetails = Decrypt("userLoginData"); //user login details
  const { data: { roleId = 0 } = {} } = nullToObject(loginUserDetails);

  //status changes allo only head off and superAdmin
  const checkSrn = convertToLowerCase(srnConvert) === convertToLowerCase("approval request") ? true : false;
  //edit access user role id

  //closed the ticket will show all role id user
  const statusEditOption: any = status !== "Closed" ? write : true;

  const [approvedValue, setApproved] = useState("");
  const [makeReadOnlyInput, setMakeReadOnlyInput] = useState(false);

  const [stateSalesReturn, setStateSalesReturn] = useState({
    updateButtonDisable: false
  });
  const { updateButtonDisable = false } = stateSalesReturn;

  const [remarkValue, setRemarkValue] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [CurrentValue, setCurrentValue] = useState("");

  const [srnDate, setSrnDate] = useState("");
  const [srnValue, setSrnValue] = useState("");
  const [srnNumber, setSrnNumber] = useState("");
  const [callType, setCallType] = useState<any>(null); //call type handle

  //conevrt srn state manage
  const [convertSRNobj, setConvertSRNobj] = useState<any>({});
  const [convertTosrnPopUp, setConvertTosrnPopUp] = useState(false);
  const [convertTosrnLoader, setConvertTosrnLoader] = useState(false);

  const [statusUpdate, setStatusUpdate] = useState({
    srnDate: "",
    srnValue: "",
    srnNumber: ""
  });

  const [tabs, setTabs] = useState({
    statusTab: false,
    historyTab: false
  });

  const onTapManage = (name = "", value = false) => {
    //tapmanage
    setTabs({
      ...tabs,
      [name]: value
    });
  };

  /**
   * call type change
   * false out,true mean incoming
   */
  const callTypeHandle = (checkValue = false) => {
    setCallType(checkValue);
  };

  /**
   * @param value open/close convert SRN popUp
   */
  const handleSendToSRN = (value = false) => {
    setConvertTosrnPopUp(value);
    setConvertSRNobj({});
  };

  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const statusDetails = statusUpdate;
    switch (name) {
      case "srnValue":
        const consvetValue = +value;
        const consvetInvoiceAmt = +invoiceAmount;
        if (invoiceAmount) {
          if (consvetInvoiceAmt >= consvetValue) {
            statusDetails.srnValue = value;
            setSrnValue(value);
          } else {
            infoToast("srn value to be equal amout or less then Product value");
          }
        } else {
          setSrnValue(value);
        }
        break;
      case "srnDate":
        statusDetails.srnDate = value;
        setSrnDate(value);
        break;
      case "srnNumber":
        statusDetails.srnNumber = value;
        setSrnNumber(value);
        break;
      default:
        break;
    }
    setStatusUpdate(statusDetails);
  };

  const statusRemark = (event: any = {}) => {
    const { target: { value = "" } = {} } = event || {};
    setRemarkValue(value);
  };

  //if parent change empty all field
  const handleButtonStatusChange = (item: any = {}, from = false) => {
    //status update
    const { optionData = "" } = item || {};
    setStatusValue(optionData);
    !from && setCurrentValue(""); //current status empty
    !from && setApproved(""); //approved empty
  };

  //if parent change empty all field
  const handleButtonCurrentChange = (item: any = {}, from = false) => {
    //current changes
    const { optionData = "" } = item || {};
    setCurrentValue(optionData);
    !from && setApproved(""); //approved empty
    !from && setSrnDate(""); //srndate empty
    !from && setSrnNumber(""); //SrnNumber empty
    !from && setSrnValue(""); //SrnValue empty
  };

  const handleButtonRejectChange = (item: any = {}) => {
    //approved chnages
    const { optionData = "" } = item;
    setApproved(optionData);
  };

  const stateUpdate = (obj: any = {}) => {
    const { name = "", value = "" } = obj;
    const newObj = {
      ...stateSalesReturn,
      [name]: value
    };
    setStateSalesReturn(newObj);
  };

  const onUpdateStatus = () => {
    const { srnValue: srnUpdate = "" } = viewModeData;
    if (callType !== null) {
      //call type
      if (
        (convertToLowerCase(CurrentValue) === "rejected" && approvedValue) ||
        (convertToLowerCase(CurrentValue) === "approved" && srnValue) ||
        (convertToLowerCase(viewModeData.subCategory) === "srn" &&
          convertToLowerCase(statusValue) === "pending" &&
          CurrentValue) ||
        (convertToLowerCase(viewModeData.subCategory) !== "srn" && statusValue && CurrentValue)
      ) {
        //reaso
        const newObj = {
          subCategory: viewModeData.subCategory,
          enquiryFor: viewModeData.enquiryFor,
          status: statusValue,
          statusRemarks: remarkValue,
          currentStatus: CurrentValue,
          approved: {
            srnDate: srnDate,
            srnValue: srnValue,
            srnNumber: srnNumber
          },
          rejected: approvedValue,
          customerId: viewModeData._id,
          callType: callType,
          srnValue: srnValue ? srnValue : srnUpdate,
          isQuery: "t"
        };

        stateUpdate({
          //after submit button disable for 1min
          name: "updateButtonDisable",
          value: true
        });
        setTimeout(() => {
          stateUpdate({
            name: "updateButtonDisable",
            value: false
          });
        }, 1000);

        updateSalesRetunStatusApi(newObj);
      } else {
        if (convertToLowerCase(CurrentValue) === "rejected" && approvedValue) {
          failToast("Please select reject reason");
        } else if (statusValue !== "" && CurrentValue !== "") {
          failToast("update status/update current status");
        } else {
          failToast("Please fill all the fields");
        }
      }
    } else {
      failToast("select call type");
    }
  };

  const ApprovedRejectList = () => {
    //approved list
    if (!CurrentValue) {
      return [];
    }
    return convertToLowerCase(CurrentValue) === convertToLowerCase("Approved") ? MockSalesStatusApproved : rejectClosed;
  };

  const statusArrayMaking = () => {
    //array return
    if (!statusValue) {
      return [];
    }
    return convertToLowerCase(statusValue) === convertToLowerCase("Pending")
      ? MockSalesStatusPending
      : MockSalesStatusClosed;
  };

  //make status and current status readonly after status closed
  const makeReadOnly = (value = false, readOnlyCall = false) => {
    if (value === true || readOnlyCall === true) {
      return true;
    }
    return false;
  };

  /**
   * update all convert to SRN details here
   * @param details
   */
  const passRefundDetails = (details: any = {}) => {
    setConvertSRNobj(details);
  };

  /**
   * consvert to srn
   */
  const handleConvertToSRN = async () => {
    const { reasonforSalesReturn = "" } = convertSRNobj;
    if (reasonforSalesReturn !== "") {
      setConvertTosrnLoader(true); //loader on
      const response = (await convertToSRNAPiCall({ ...convertSRNobj, _id: _id })) || {};
      const { data: { message = "", status: srnConvertStatus = 0 } = {} } = response;
      if (srnConvertStatus === 200) {
        successToast(message);
        setConvertTosrnPopUp(false); //convertSrn popUpClose
        //reload and close popUp
        store.dispatch({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: true });
      } else {
        failToast(message);
      }
      setConvertTosrnLoader(false); //loader off
    } else {
      failToast("Please select Reason For Sales Return");
    }
  };

  useEffect(() => {
    //for read only make for status closed
    if (status === "Closed") {
      const findStatus: any = MockSalesStatus.find((ele: any) => ele.optionData === status);
      handleButtonStatusChange(findStatus, true); //update status
      if (convertToLowerCase(findStatus?.optionData) === convertToLowerCase("Closed")) {
        setMakeReadOnlyInput(true);
        handleButtonCurrentChange({ optionData: viewModeData.currentStatus });
        /**
         * rejected reson
         */
        if (convertToLowerCase(currentStatus) === convertToLowerCase("Rejected")) {
          handleButtonRejectChange({ optionData: viewModeData.rejected });
        }
        /**
         * once approve status  data will show
         */
        if (convertToLowerCase(currentStatus) === convertToLowerCase("Approved")) {
          const { srnDate: srnDateUpdate = "", srnNumber: srnNumberUpdate, srnValue: srnValueUpdate = "" } = approved;
          setSrnDate(srnDateUpdate);
          setSrnValue(srnValueUpdate);
          setSrnNumber(srnNumberUpdate);
        }
      }
      setCallType(getCallType); //after close srn appent call type
    }
  }, [status]);

  const { statusTab = false, historyTab = false } = nullToObject(tabs);

  return (
    <React.Fragment>
      {/* status table */}
      {transactions.length > 0 && (
        <EnquiryStatusTrackingTable
          roleId={roleId}
          statusTab={statusTab}
          columnView={columnView}
          historyTab={historyTab}
          onTapManage={onTapManage}
          transactions={transactions}
        />
      )}
      {statusEditOption && (
        <CommonTable
          enableModal={3}
          setShow={true}
          parentClass={"w-full"}
          id="EnquiryStatusUpdate"
          heading={"Enquiry Status Update"}
          customClass={bg_EnquiryStatusUpdate}
        >
          <div
            id="tab5"
            className={`w-full flex historyTab flex-wrap mb-4 ${statusTab && columnView ? "h-0 overflow-hidden transition ease-linear" : "h-auto"} `}
          >
            <CommonSearchDropdown
              label={true}
              name={"status"}
              dropOnTop={false}
              setShow={true}
              value={statusValue}
              labelText={"Status"}
              inputWithIcon={false}
              placeholder={"--None--"}
              arrayData={MockSalesStatus}
              inputWithIconClass="w-10"
              labelTextClass={"font-bold"}
              inputFieldClass="h-9 pr-11"
              inputClass="flex justify-end"
              handleParentDropVal={handleButtonStatusChange}
              readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
              parentClass={columnView ? "mb-4 sm:mb-4 w-1/2 mt-2 px-4" : inputStylePopup}
            />
            <CommonSearchDropdown
              label={true}
              setShow={true}
              dropOnTop={false}
              value={CurrentValue}
              inputWithIcon={false}
              name={"currentStatus"}
              placeholder={"--None--"}
              inputWithIconClass="w-10"
              labelText={"Sub Status"}
              labelTextClass={"font-bold"}
              inputFieldClass="h-9 pr-11"
              inputClass="flex justify-end"
              arrayData={statusArrayMaking()}
              handleParentDropVal={handleButtonCurrentChange}
              readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
              parentClass={columnView ? "mb-4 sm:mb-4 w-1/2 mt-2 px-4" : inputStylePopup}
            />
            {!fromNonEdit ? ( //only show status and current status for edit status
              <>
                {convertToLowerCase(statusValue) !== convertToLowerCase("Pending") &&
                  convertToLowerCase(CurrentValue) !== convertToLowerCase("Approved") && (
                    <CommonSearchDropdown
                      label={true}
                      setShow={true}
                      dropOnTop={false}
                      name={"rejected"}
                      labelText={"Reject"}
                      value={approvedValue}
                      inputWithIcon={false}
                      placeholder={"--None--"}
                      inputWithIconClass="w-10"
                      labelTextClass={"font-bold"}
                      inputFieldClass="h-9 pr-11"
                      inputClass="flex justify-end"
                      handleParentDropVal={handleButtonRejectChange}
                      arrayData={CurrentValue ? ApprovedRejectList() : []}
                      readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
                      parentClass={columnView ? "mb-4 sm:mb-4 w-1/2 mt-2 px-4" : inputStylePopup}
                    />
                  )}

                {convertToLowerCase(CurrentValue) === convertToLowerCase("Approved") && !checkSrn && (
                  <>
                    <CommonInput
                      setShow={true}
                      name={"srnDate"}
                      inputError={false}
                      onChange={onChange}
                      inputFieldClass="h-9"
                      labelText={"SRN Date"}
                      enableDatePicker={true}
                      isIconLabel={true}
                      labelTextClass={"font-bold"}
                      value={srnDate && new Date(srnDate)}
                      placeholderText={getDateConvert(srnDate)}
                      readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
                      parentClass={columnView ? "mb-4 sm:mb-4 w-1/2 mt-2 px-4" : inputStylePopup}
                    />
                    <CommonInput
                      setShow={true}
                      inputError={false}
                      name={"srnNumber"}
                      value={srnNumber}
                      onChange={onChange}
                      inputFieldClass="h-9"
                      labelText={"SRN Number"}
                      labelTextClass={"font-bold"}
                      readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
                      parentClass={columnView ? "mb-4 sm:mb-4 w-1/2 mt-2 px-4" : inputStylePopup}
                    />
                    <CommonInput
                      setShow={true}
                      value={srnValue}
                      name={"srnValue"}
                      inputError={false}
                      onChange={onChange}
                      labelText={"SRN Value"}
                      inputFieldClass="h-9"
                      labelTextClass={"font-bold"}
                      onKeyPress={numOnlyAllow}
                      readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
                      parentClass={columnView ? "mb-4 sm:mb-4 w-1/2 mt-2 px-4" : inputStylePopup}
                    />
                  </>
                )}
              </>
            ) : null}
            {/* for develop purpose hide */}
            {convertSRNdisplay && <ConvertSRNbutton handleSendToSRN={handleSendToSRN} />}

            <CustomerCallType
              callType={callType}
              callTypeHandle={callTypeHandle}
              inModelView={true}
              columnView={columnView}
            />

            <EnquiryForRemark
              value={remarkValue}
              editView={editView}
              name={"remarkValue"}
              onChange={statusRemark}
              readOnly={makeReadOnly(fromNonEdit, makeReadOnlyInput)}
            />

            {/* closed status hide input box */}
            {!makeReadOnlyInput && (
              <div className="py-8 bg-lite-white sticky -bottom-8 flex flex-col items-center justify-center w-full">
                <div>
                  <button
                    type="button"
                    disabled={updateButtonDisable} //for user continuously hot avoid
                    onClick={() => onUpdateStatus()}
                    className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
                  >
                    {"Submit"}
                  </button>
                </div>
              </div>
            )}
          </div>
        </CommonTable>
      )}

      {convertTosrnPopUp && (
        <ModalFixed3>
          <ActionPopup
            enableHeader={true}
            customWidth={"600px"}
            handleActionText="send"
            enableActionIcon={false}
            PopupClass={"max-w-2xl max-h-screen"}
            textHeaderHeading={"Convert To SRN"}
            handleCancel={() => handleSendToSRN(false)}
            handleAction={() => handleConvertToSRN()}
            styleHandleAction={actionPopupBtnStyleGreen}
          >
            <ConvertToSRN
              viewModeData={viewModeData}
              passRefundDetails={passRefundDetails}
              convertTosrnLoader={convertTosrnLoader}
            />
          </ActionPopup>
        </ModalFixed3>
      )}
    </React.Fragment>
  );
};
export default StatusUpdateSalesReturn;
