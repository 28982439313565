import React, { Fragment } from "react";
import { commonCheckProps } from "./types";
import { nullToObject } from "utils/validation";
import { Transition } from "@headlessui/react";

const CommonRadioBox = (props: commonCheckProps) => {
  const {
    id = 0,
    key = 0,
    name = "",
    children,
    value = "",
    label = true,
    labelText = "",
    setShow = true,
    checked = false,
    parentClass = "",
    readOnly = false,
    isDisable = false,
    defaultValue = "",
    onClick = () => {},
    customLabel = false,
    onChange = () => {},
    parentAsLabel = false,
    defaultChecked = false,
    parentAsLabelClass = ""
  } = nullToObject(props);

  const Checktemplate = () => {
    return (
      <Fragment>
        <Transition
          show={setShow}
          leaveTo="opacity-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-10"
          enter="transition-opacity duration-200"
          leave="transition-opacity duration-200"
          className={`form-check ${parentClass ? parentClass : ""}`}
        >
          <input
            key={key}
            name={name}
            type="radio"
            value={value}
            id={`check-${id}`}
            readOnly={readOnly}
            defaultChecked={defaultChecked}
            className="form-check-input mr-2"
            onChange={defaultValue ? null : onChange}
            onClick={onClick}
            disabled={isDisable}
            checked={defaultValue ? undefined : checked}
          />

          {label && (
            <label
              htmlFor={`check-${id}`}
              className="form-check-label pl-2 cursor-pointer text-xs capitalize font-normal"
            >
              {labelText}
            </label>
          )}
        </Transition>

        {customLabel && (
          <label htmlFor={`check-${id}`} className="form-check-label cursor-pointer ml-2  text-xs  font-normal">
            {labelText}
          </label>
        )}
        {children}
      </Fragment>
    );
  };

  return parentAsLabel ? (
    <label
      tabIndex={0}
      role="button"
      htmlFor={`check-${id}`}
      className={`${parentAsLabelClass ? parentAsLabelClass : ""} cursor-pointer`}
    >
      {Checktemplate()}
    </label>
  ) : (
    <React.Fragment>{Checktemplate()}</React.Fragment>
  );
};

export default CommonRadioBox;
