import React from "react";
import { InputFileTypeProps } from "./types";
import { nullToObject } from "utils/validation";
import { Transition } from "@headlessui/react";
import { PhotographIcon } from "@heroicons/react/outline";

const FormFile = (props: InputFileTypeProps = {}) => {
  const {
    id = "",
    name = "",
    type = "",
    accept = "",
    children = "",
    label = true,
    setShow = true,
    labelTextClass,
    labelText = "",
    customIcon = "",
    parentClass = "",
    multiple = false,
    readOnly = false,
    mustField = false,
    inputAlone = false,
    customImage = "",
    selectInfoText = "", //label text
    onBlur = () => {}, //click function
    selectInfoTextclass = "",
    onChange = () => {} //click function
  } = nullToObject(props);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      {!inputAlone ? (
        <>
          {label && (
            <label
              className={`block text-xs input-label mb-1 
                        ${labelTextClass ? labelTextClass : ""}`}
              htmlFor=""
            >
              {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
            </label>
          )}
          <div className="mx-auto rounded overflow-hidden">
            <div className="relative h-8 rounded border-dashed border-2 border-yellow-500 bg-gray-100 flex justify-center items-center">
              <div className="absolute">
                <div className="flex justify-center items-center">
                  {customImage ? (
                    <img className="w-5 h-auto" src={customImage} alt={customImage} />
                  ) : customIcon ? (
                    customIcon
                  ) : (
                    <PhotographIcon className="text-yellow-500 w-5" />
                  )}
                  <span className={`${selectInfoTextclass} block text-gray-500 font-semibold ml-2 text-xs`}>
                    {selectInfoText ? selectInfoText : "Select a file"}
                  </span>
                </div>
              </div>
              <input
                type={type}
                name={name}
                multiple={multiple}
                id={id ? id : name}
                onChange={onChange}
                accept={accept ? accept : null}
                onBlur={onBlur ? onBlur : null}
                disabled={readOnly ? true : false}
                className="h-full w-full opacity-0 cursor-pointer absolute"
                data-jest-id={"jestOnChangeInput"}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          {children}
          <input
            type={type}
            name={name}
            multiple={multiple}
            id={id ? id : name}
            onChange={onChange}
            accept={accept ? accept : null}
            onBlur={onBlur ? onBlur : null}
            disabled={readOnly ? true : false}
            className="h-0 w-0 opacity-0 cursor-pointer absolute"
            data-jest-id={"jestOnChangeInput"}
          />
        </>
      )}
    </Transition>
  );
};
export default FormFile;
