import React, { useState, useEffect } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { Decrypt } from "utils/secureFile";
import { useLocation } from "react-router-dom";
import { _get, _isEmpty } from "utils/lodashConst";
import CommonDropHeading from "Component/CommonDropHeading";
import { nullToArray, nullToObject } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { bg_ProblemDetails, btn_refresh } from "utils/ClassContent";
import { PaperClipIcon, RefreshIcon } from "@heroicons/react/outline";
import { findIndexArrayObj, removeDuplicates } from "utils/projectHelper";
import ImageUploadReturnSmallSize from "Component/ImageUploadReturnSmallSize";
import { getDCSerialNoApiService } from "Pages/ServiceCalls/commonServiceCalls";
import { getEmployeeDetailApiServices } from "Pages/Complains/commonComplainlist";
import CustomerProblemInfo from "Pages/ServiceCalls/EditViewCall/CustomerProblemInfo";
import { HoDeliveryChallan, ServieCenterDeliveryChallan } from "Pages/ServiceCalls/ServicePopup/CreateDeliveryChallan";

/**
 * Problem Info Drop Heading
 * @param props props
 * @returns Shows problem info on enquiry popup
 */
const ProblemInfoDropHeading = (props: any = {}) => {
  const {
    onCloseImage = () => {},
    sendAttachments = [],
    imageGet = () => {},
    product = "",
    editView = false,
    customerData = {},
    //documentList = [],
    popParentTabs = "",
    deliveyChallan = [],
    createInputFields = () => {},
    handleUserInfoChange = () => {}
  } = props || {};

  const filterDeliveyChallan = removeDuplicates(deliveyChallan, "fileName");
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { empId = "" } = {} } = nullToObject(userLoginDetails);
  const location = useLocation();
  const { subCategory = "", subMenu = "" } = nullToObject(location.state);
  const isTransitDC = subCategory === "Product Service" && subMenu === "In Transit";
  const isPendingAtServiceCenter = subCategory === "Product Service" && subMenu === "Pending At Service Center";
  const isPendingToSendStore = subCategory === "Product Service" && subMenu === "Pending To Send Store";
  const globalStore = useSelector((state: any) => state || {});
  const { serviceCallDetailsReducer: { storeEmployeeDetails = {}, dcSerialNo = null } = {} } =
    nullToObject(globalStore);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("Poorvika-HO");
  const [refresh, setRefresh] = useState(false);
  const [empBranchCode, setEmpBranchCode] = useState("");
  const [dcSerialNumber, setDcSerialNumber] = useState(0);
  const [isGotEmpDetails, setIsGotEmpDetails] = useState(true);

  const handleSync = () => {
    setRefresh(true);
    const remarks = "";
    if (isTransitDC) {
      const dcValue = "DC-" + empBranchCode + "-" + dcSerialNumber;
      const hoDetails = {
        to: to,
        from: from,
        sendDate: moment().format("DD/MM/YYYY").toString()
      };
      if (findIndexArrayObj(filterDeliveyChallan, "fileName", "HO_DeliveryChallan") === -1) {
        HoDeliveryChallan(hoDetails, remarks, dcValue, [customerData], empBranchCode);
      }
    }
    if (isPendingAtServiceCenter) {
      const dcValue = "DC-" + empBranchCode + "-" + dcSerialNumber;
      const serviceCenterDetails = {
        from: from,
        mobileNo: customerData.mobileNo,
        SendDate: moment().format("DD/MM/YYYY").toString(),
        carriedPerson: customerData.customerName,
        ServiceCenterName: []
      };
      if (findIndexArrayObj(filterDeliveyChallan, "fileName", "ServiceCenter_DeliveryChallan") === -1) {
        ServieCenterDeliveryChallan(serviceCenterDetails, remarks, dcValue, [customerData], empBranchCode);
      }
    }
    setTimeout(() => {
      setRefresh(false);
    }, 2000);
  };

  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    switch (name) {
      case "from":
        setFrom(value);
        break;
      case "to":
        setTo(value);
        break;
      default:
        break;
    }
  };

  const onChangeFile = (event: any = {}) => {
    const target = _get(event, "target", {});
    if (!_isEmpty(_get(target, "files", []))) {
      const file = _get(target, "files[0]", {});
      const { name = "" } = file;
      if (file.size <= 5e6) {
        const newObj = {
          file: file,
          fileName: name,
          isEditable: true,
          url: URL.createObjectURL(file),
          type: file.name.split(".").pop()?.toLowerCase()
        };

        const newArray: any = [...sendAttachments, newObj];
        if (nullToArray(newArray)?.length) {
          imageGet(newArray);
        }
      } else {
        failToast(" File exceeds the allowed limit of 5MB ");
      }
    }
  };

  const getEmployeeDetailsBasedEmpIdCallApiCall = async (id) => {
    const response = await getEmployeeDetailApiServices(id);
    const { status = 0, data = [], message = "" } = response || {};
    if (status === 200) {
      onChange({ target: { name: "from", value: _get(data?.[0], "employee_branch", "") } });
      const empCode = _get(data?.[0], "employee_branch_code", "");
      setEmpBranchCode(empCode);
      if (_get(data?.[0], "employee_branch_code", "")) {
        const res = await getDCSerialNoApiService(empCode);
        const {
          data: { data: sCode = 0, status: statusCode = 0 }
        } = res || {};
        if (statusCode >= 200 && statusCode <= 202) {
          setDcSerialNumber(sCode);
        } else {
          setDcSerialNumber(0);
        }
      }
    } else {
      setEmpBranchCode("");
      failToast(message);
    }
  };

  useEffect(() => {
    if (empId) {
      getEmployeeDetailsBasedEmpIdCallApiCall(empId);
    }
  }, [empId]);

  useEffect(() => {
    setDcSerialNumber(dcSerialNo);
  }, [dcSerialNo]);

  return (
    <CommonDropHeading
      customClass={bg_ProblemDetails}
      id="ProblemDetails"
      heading="Problem details"
      tabActiveDefault={popParentTabs === "ProblemDetails" ? true : false}
    >
      <CustomerProblemInfo
        itemName={product}
        editView={editView}
        customerData={customerData}
        passProblemInfo={handleUserInfoChange}
      />
      {createInputFields("Status", "status", customerData)}
      {createInputFields("Current Status", "currentStatus", customerData)}
      {createInputFields("Remarks", "remarks", customerData)}
      {/* {documentList.length >= 1 &&
                    <>
                        <div className='w-full flex flex-wrap flex-col mt-2'>
                            <h3 className='block text-xs input-label mb-1 font-bold'>Documents Uploaded</h3>
                            <div className="w-full flex flex-wrap justify-center">
                                <ImageUploadReturn
                                    imagRenderArry={documentList}
                                    enableViewMode={!editView}
                                />
                            </div>
                        </div>
                    </>
                } */}

      <div className={`w-full flex flex-col mb-1 md:w-1/2 mt-1 xl:w-1/4 `}>
        <div className=" w-full flex relative px-4">
          <h3 className="block text-xs input-label mb-1 font-bold">Documents Uploaded</h3>
          {sendAttachments.length < 10 && editView && (
            <div className="inline-flex flex-wrap ml-2">
              <div className="h-5 flex items-center">
                <label
                  htmlFor="FileAttach"
                  className=" cursor-pointer h-6 w-6 flex justify-center items-center rounded-md border-gray-200 border hover:bg-yellow-600
                                        hover:border-yellow-200 hover:bg-opacity-10 text-yellow-500"
                >
                  <input
                    className="absolute z-0 w-0 h-0 opacity-0"
                    id="FileAttach"
                    type={"file"}
                    onChange={onChangeFile}
                    data-jest-id={"jestOnChangeFile"}
                  />
                  <PaperClipIcon className="h-4 w-4" />
                </label>
              </div>
            </div>
          )}
        </div>
        {nullToArray(sendAttachments)?.length > 0 ? (
          <ImageUploadReturnSmallSize
            imageSize="small"
            enableViewMode={!editView}
            controllImageCloseIcon={true}
            imagRenderArry={sendAttachments}
            onClosImage={(index: any) => _get(sendAttachments[index], "isEditable", false) && onCloseImage(index)}
          />
        ) : null}
      </div>
      {/* {deliveyChallan.length > 0 ? ( */}
      <div className={`w-full flex flex-col mb-1 md:w-1/2 mt-1 xl:w-1/4 `}>
        <div className=" w-full flex relative px-4">
          <h3 className="block text-xs input-label mb-1 font-bold">Delivery Challan</h3>
          {
            /*deliveyChallan.length === 0 && */ (isTransitDC || isPendingAtServiceCenter || isPendingToSendStore) && (
              <button
                type="button"
                title={""}
                className={btn_refresh}
                onClick={handleSync}
                data-jest-id={"jestHandleSync"}
              >
                <RefreshIcon
                  className={`${refresh ? "animate-spin " : " "} h-5 w-5 stroke-current transition-all duration-200`}
                />
              </button>
            )
          }
        </div>
        {filterDeliveyChallan.length >= 1 && (
          <div className="w-full flex flex-wrap justify-center">
            <ImageUploadReturnSmallSize imagRenderArry={filterDeliveyChallan} enableViewMode={!editView} />
          </div>
        )}
      </div>
      {/* )} */}
    </CommonDropHeading>
  );
};

export default ProblemInfoDropHeading;
