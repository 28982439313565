/* eslint max-lines: 0 */

import { createDeliveryChallanCall } from "../commonServiceCalls";

export const ServieCenterDeliveryChallan = (
  serviceCenter: any = {},
  remarks = "",
  dcSerialNumber = "",
  customerList: any = [],
  branchCode = ""
) => {
  let count = 0;
  const html = `
    <html lang="en">
            <head>
                <title></title>
            </head>
            <body>
                <table
                    cellpadding="0"
                    cellspacing="0"
                    style="
                    width : 95%;
                    max-width: 95%;
                    max-height:297mm;
         border: 1px solid rgb(0, 0, 0);
         min-height: 455px;
         margin: 2.5% auto 0;
                    border-collapse: collapse;"
                >
                    <tbody>
                        <tr>
                            <td
                                height="30"
                                colspan="6"
                                align="center"
                                valign="bottom"
                                style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 16px;
                  "
                            >
                                SERVICE CENTER DELIVERY CHALLAN
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="6"
                                width="100%"
                                height="30"
                                align="center"
                                valign="top"
                                style="padding: 5px"
                            >
                                <span
                                    style="
                     font-family: Arial, Helvetica, sans-serif;
                     font-weight: bold;
                     font-size: 12px;
                     display: inline-block;
                     "
                                >POORVIKA MOBILES PRIVATE LIMITED<br />
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td
                                height="32"
                                colspan="2"
                                valign="middle"
                                style=" border: 1px solid #000;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  padding-left: 10px;
                  "
                            >
                                SI.NO : ${dcSerialNumber}
                            </td>
                            <td
                                align="center"
                                colspan="2"
                                valign="middle"
                                style=" border: 1px solid #000;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                DELIVERY CHALLAN
                            </td>
                            <td
                                colspan="2"
                                align="center"
                                valign="middle"
                                style=" border: 1px solid #000;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                Date : ${serviceCenter.SendDate}
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6" align="center" valign="top" height="10"></td>
                        </tr>
                        <tr>
                            <td
                                colspan="3"
                                valign="bottom"
                                style="
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 12px;
                  padding: 3px 10px;
                  "
                            >
                                From
                            </td>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  padding: 3px 10px;
                  border-bottom-style: dashed;
                  border-bottom-width: 1px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                ${serviceCenter.from}
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 12px;
                  padding: 3px 10px;
                  "
                            >
                                To
                            </td>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  padding: 3px 10px;
                  border-bottom-style: dashed;
                  border-bottom-width: 1px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                ${serviceCenter.ServiceCenterName}
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 12px;
                  padding: 3px 10px;
                  "
                            >
                                Carried Person Name
                            </td>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  padding: 3px 10px;
                  border-bottom-style: dashed;
                  border-bottom-width: 1px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                ${serviceCenter.carriedPerson}
                            </td>
                        </tr>
                        <tr>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  font-weight: bold;
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 12px;
                  padding: 3px 10px;
                  "
                            >
                                Carried Person Mobile
                            </td>
                            <td
                                colspan="3"
                                valign="middle"
                                style="
                  padding: 3px 10px;
                  border-bottom-style: dashed;
                  border-bottom-width: 1px;
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                ${serviceCenter.mobileNo}
                            </td>
                        </tr>
                        <tr>
                        <td
                            colspan="3"
                            valign="bottom"
                            style="
              font-weight: bold;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 12px;
              padding: 3px 10px;
              "
                        >
                            Remarks
                        </td>
                        <td
                            colspan="3"
                            valign="middle"
                            style="
              padding: 3px 10px;
              border-bottom-style: dashed;
              border-bottom-width: 1px;
              font-family: Arial, Helvetica, sans-serif;
              font-weight: bold;
              font-size: 12px;
              "
                        >
                            ${remarks}
                        </td>
                    </tr>
                        <tr>
                            <td colspan="2" align="center" valign="top">&nbsp;</td>
                            <td colspan="2" align="center" valign="top">&nbsp;</td>
                            <td colspan="2" align="center" valign="top">&nbsp;</td>
                        </tr>
                        <tr>
                            <td colspan="6" align="center" valign="top">
                                <table
                                    width="100%"
                                    cellpadding="0"
                                    cellspacing="0"
                                    style="border: 0px solid rgb(0, 0, 0); height: 95px"
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                width="4%"
                                                height="32"
                                                align="center"
                                                style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                SI.No
                                            </td>
                                            <td
                                                width="13%"
                                                align="center"
                                                style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                Service Type
                                            </td>
                                            <td
                                                width="12%"
                                                align="center"
                                                style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                Brand / Model
                                            </td>
                                            <td
                                                width="15%"
                                                align="center"
                                                style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                IMIEI NO
                                            </td>
                                            <td
                                                width="16%"
                                                align="center"
                                                style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                Problem
                                            </td>
                                            <td
                                                width="18%"
                                                align="center"
                                                style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                Problem Description
                                            </td>
                                        </tr>
                                        ${customerList.map((customer: any = {}) => {
                                          count = count + 1;
                                          return `
                        <tr>
                           <td
                              width="4%"
                              height="32"
                              align="center"
                              style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                              >
                              ${count}
                           </td>
                           <td
                              width="13%"
                              align="center"
                              style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                              >
                              ${customer.typeOfService}
                           </td>
                           <td
                              width="12%"
                              align="center"
                              style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                              >
                              ${customer.itemBrandName} / ${customer.itemName}
                           </td>
                           <td
                              width="15%"
                              align="center"
                              style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                              >
                              ${customer.imei}
                           </td>
                           <td
                              width="16%"
                              align="center"
                              style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                              >
                              ${customer.problem}
                           </td>
                           <td
                              width="18%"
                              align="center"
                              style="
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                              >
                              ${customer.problemDesc}
                           </td>
                        </tr>
                        `;
                                        })}
                                        <tr
                                            style="
                           font-size: 12px;
                           font-family: Arial, Helvetica, sans-serif;
                           "
                                        >
                                            <td
                                                height="30"
                                                colspan="6"
                                                align="right"
                                                style="border-bottom: 1px dashed #000"
                                            ></td>
                                        </tr>
                                        <tr
                                            style="
                           font-size: 12px;
                           font-family: Arial, Helvetica, sans-serif;
                           "
                                        >
                                            <td height="30" colspan="5" align="right">
                                                <span
                                                    style="
                                 font-family: Arial, Helvetica, sans-serif;
                                 font-weight: bold;
                                 font-size: 12px;
                                 "
                                                >TOTAL :
                                                </span>
                                            </td>
                                            <td align="center" style="">
                                                <span
                                                    style="
                                 font-family: Arial, Helvetica, sans-serif;
                                 font-weight: bold;
                                 font-size: 12px;
                                 "
                                                >${customerList.length}</span
                                                >
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td
                                height="55"
                                colspan="6"
                                align="right"
                                valign="top"
                                style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                  "
                            >
                                <table
                                    width="100%"
                                    cellspacing="0"
                                    cellpadding="0"
                                    style="border: 0px solid rgb(0, 0, 0)"
                                >
                                    <tbody>
                                        <tr>
                                            <td
                                                height="48"
                                                align="right"
                                                style="
                              padding-right: 90px;
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              "
                                            >
                                                Received the goods in good condition
                                            </td>
                                            <td
                                                style="
                              padding-left: 90px;
                              font-family: Arial, Helvetica, sans-serif;
                              font-weight: bold;
                              font-size: 12px;
                              ">For POORVIKA MOBILES PRIVATE LIMITED
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td height="50" colspan="3" align="center" valign="top">&nbsp;</td>
                            <td height="50" colspan="3" align="center" valign="top">&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </body>
        </html>`;
  const customersId = customerList.map((customer: any) => customer._id);
  createDeliveryChallanCall({
    idList: customersId,
    fileName: "ServiceCenter_DeliveryChallan",
    html: html,
    branchCode: branchCode
  });
};

export const HoDeliveryChallan = (
  hoDetails: any = {},
  remarks = "",
  dcSerialNumber = "",
  customerList: any = [],
  branchCode = ""
) => {
  let count = 0;
  const html = `
    <html lang="en">
    <head>
        <title></title>
    </head>
    <body>
        <table
            cellpadding="0"
            cellspacing="0"
            style="
           width : 95%;
           max-width: 95%;
           max-height:297mm;
 border: 1px solid rgb(0, 0, 0);
 min-height: 455px;
 margin: 2.5% auto 0;
 border-collapse: collapse;
 "
        >
            <tbody>
                <tr>
                    <td
                        height="30"
                        colspan="6"
                        align="center"
                        valign="bottom"
                        style="
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 16px;
          "
                    >
                        HO DELIVERY CHALLAN
                    </td>
                </tr>
                <tr>
                    <td
                        colspan="6"
                        width="100%"
                        height="30"
                        align="center"
                        valign="top"
                        style="padding: 5px"
                    >
                        <span
                            style="
             font-family: Arial, Helvetica, sans-serif;
             font-weight: bold;
             font-size: 12px;
             display: inline-block;
             "
                        >POORVIKA MOBILES PRIVATE LIMITED<br />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td
                        height="32"
                        colspan="2"
                        valign="middle"
                        style=" border: 1px solid #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          padding-left: 10px;
          "
                    >
                        SI.NO : ${dcSerialNumber}
                    </td>
                    <td
                        align="center"
                        colspan="2"
                        valign="middle"
                        style=" border: 1px solid #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        DELIVERY CHALLAN
                    </td>
                    <td
                        colspan="2"
                        align="center"
                        valign="middle"
                        style=" border: 1px solid #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        Date : ${hoDetails.sendDate}
                    </td>
                </tr>
                <tr>
                    <td colspan="6" align="center" valign="top" height="10"></td>
                </tr>
                <tr>
                    <td
                        colspan="3s"
                        valign="bottom"
                        style="
          font-weight: bold;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 12px;
          padding: 3px 10px;
          "
                    >
                        From
                    </td>
                    <td
                        colspan="3"
                        valign="middle"
                        style="
          padding: 3px 10px;
          border-bottom-style: dashed;
          border-bottom-width: 1px;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        ${hoDetails.from}
                    </td>
                </tr>
                <tr>
                    <td
                        colspan="3"
                        valign="middle"
                        style="
          font-weight: bold;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 12px;
          padding: 3px 10px;
          "
                    >
                        To
                    </td>
                    <td
                        colspan="3"
                        valign="middle"
                        style="
          padding: 3px 10px;
          border-bottom-style: dashed;
          border-bottom-width: 1px;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        ${hoDetails.to}
                    </td>
                </tr>
                <tr>
                        <td
                            colspan="3"
                            valign="bottom"
                            style="
              font-weight: bold;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 12px;
              padding: 3px 10px;
              "
                        >
                            Remarks
                        </td>
                        <td
                            colspan="3"
                            valign="middle"
                            style="
              padding: 3px 10px;
              border-bottom-style: dashed;
              border-bottom-width: 1px;
              font-family: Arial, Helvetica, sans-serif;
              font-weight: bold;
              font-size: 12px;
              "
                        >
                            ${remarks}
                        </td>
                    </tr>
                <tr>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                </tr>
                <tr>
                    <td colspan="6" align="center" valign="top">
                        <table
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            style="border: 0px solid rgb(0, 0, 0); height: 95px"
                        >
                            <tbody>
                                <tr>
                                    <td
                                        width="4%"
                                        height="32"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        SI.No
                                    </td>
                                    <td
                                        width="13%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Service Type
                                    </td>
                                    <td
                                        width="12%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Brand / Model
                                    </td>
                                    <td
                                        width="15%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        IMIEI NO
                                    </td>
                                    <td
                                        width="16%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Problem
                                    </td>
                                    <td
                                        width="18%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Problem Description
                                    </td>
                                </tr>
                                ${customerList.map((customer: any = {}) => {
                                  count = count + 1;
                                  return `
                <tr>
                   <td
                      width="4%"
                      height="32"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${count}
                   </td>
                   <td
                      width="13%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.typeOfService}
                   </td>
                   <td
                      width="12%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.itemBrandName} / ${customer.itemName}
                   </td>
                   <td
                      width="15%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.imei}
                   </td>
                   <td
                      width="16%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.problem}
                   </td>
                   <td
                      width="18%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.problemDesc}
                   </td>
                </tr>
                `;
                                })}
                                <tr
                                    style="
                   font-size: 12px;
                   font-family: Arial, Helvetica, sans-serif;
                   "
                                >
                                    <td
                                        height="30"
                                        colspan="6"
                                        align="right"
                                        style="border-bottom: 1px dashed #000"
                                    ></td>
                                </tr>
                                <tr
                                    style="
                   font-size: 12px;
                   font-family: Arial, Helvetica, sans-serif;
                   "
                                >
                                    <td height="30" colspan="5" align="right">
                                        <span
                                            style="
                         font-family: Arial, Helvetica, sans-serif;
                         font-weight: bold;
                         font-size: 12px;
                         "
                                        >TOTAL :
                                        </span>
                                    </td>
                                    <td align="center" style="">
                                        <span
                                            style="
                         font-family: Arial, Helvetica, sans-serif;
                         font-weight: bold;
                         font-size: 12px;
                         "
                                        >${customerList.length}</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td
                        height="55"
                        colspan="6"
                        align="right"
                        valign="top"
                        style="
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="border: 0px solid rgb(0, 0, 0)"
                        >
                            <tbody>
                                <tr>
                                    <td
                                        height="48"
                                        align="right"
                                        style="
                      padding-right: 90px;
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Received the goods in good condition
                                    </td>
                                    <td
                                        style="
                      padding-left: 90px;
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      ">For POORVIKA MOBILES PRIVATE LIMITED
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height="50" colspan="3" align="center" valign="top">&nbsp;</td>
                    <td height="50" colspan="3" align="center" valign="top">&nbsp;</td>
                </tr>
            </tbody>
        </table>
    </body>
</html> `;
  const customersId = customerList.map((customer: any) => customer._id);
  createDeliveryChallanCall({
    idList: customersId,
    fileName: "HO_DeliveryChallan",
    html: html,
    branchCode: branchCode
  });
};

export const StoreDeliveryChallan = (
  storeDetails: any = {},
  remarks = "",
  dcSerialNumber = "",
  customerList: any = [],
  branchCode = ""
) => {
  let count = 0;
  const html = `
    <html lang="en">
    <head>
        <title></title>
    </head>
    <body>
        <table
            cellpadding="0"
            cellspacing="0"
            style="
            width : 95%;
            max-width: 95%;
            max-height:297mm;
 border: 1px solid rgb(0, 0, 0);
 min-height: 455px;
 margin: 2.5% auto 0;
 border-collapse: collapse;
 "
        >
            <tbody>
                <tr>
                    <td
                        height="30"
                        colspan="6"
                        align="center"
                        valign="bottom"
                        style="
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 16px;
          "
                    >
                        STORE DELIVERY CHALLAN
                    </td>
                </tr>
                <tr>
                    <td
                        colspan="6"
                        width="100%"
                        height="30"
                        align="center"
                        valign="top"
                        style="padding: 5px"
                    >
                        <span
                            style="
             font-family: Arial, Helvetica, sans-serif;
             font-weight: bold;
             font-size: 12px;
             display: inline-block;
             "
                        >POORVIKA MOBILES PRIVATE LIMITED<br />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td
                        height="32"
                        colspan="2"
                        valign="middle"
                        style=" border: 1px solid #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          padding-left: 10px;
          "
                    >
                        SI.NO : ${dcSerialNumber}
                    </td>
                    <td
                        align="center"
                        colspan="2"
                        valign="middle"
                        style=" border: 1px solid #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        DELIVERY CHALLAN
                    </td>
                    <td
                        colspan="2"
                        align="center"
                        valign="middle"
                        style=" border: 1px solid #000;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        Date : ${storeDetails.sendDate}
                    </td>
                </tr>
                <tr>
                    <td colspan="6" align="center" valign="top" height="10"></td>
                </tr>
                <tr>
                    <td
                        colspan="3"
                        valign="bottom"
                        style="
          font-weight: bold;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 12px;
          padding: 3px 10px;
          "
                    >
                        From
                    </td>
                    <td
                        colspan="3"
                        valign="middle"
                        style="
          padding: 3px 10px;
          border-bottom-style: dashed;
          border-bottom-width: 1px;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        ${storeDetails.from}
                    </td>
                </tr>
                <tr>
                    <td
                        colspan="3"
                        valign="middle"
                        style="
          font-weight: bold;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 12px;
          padding: 3px 10px;
          "
                    >
                        To
                    </td>
                    <td
                        colspan="3s"
                        valign="middle"
                        style="
          padding: 3px 10px;
          border-bottom-style: dashed;
          border-bottom-width: 1px;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        ${storeDetails.to}
                    </td>
                </tr>
                <tr>
                        <td
                            colspan="3"
                            valign="bottom"
                            style="
              font-weight: bold;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 12px;
              padding: 3px 10px;
              "
                        >
                            Remarks
                        </td>
                        <td
                            colspan="3"
                            valign="middle"
                            style="
              padding: 3px 10px;
              border-bottom-style: dashed;
              border-bottom-width: 1px;
              font-family: Arial, Helvetica, sans-serif;
              font-weight: bold;
              font-size: 12px;
              "
                        >
                            ${remarks}
                        </td>
                    </tr>
                <tr>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                    <td colspan="2" align="center" valign="top">&nbsp;</td>
                </tr>
                <tr>
                    <td colspan="6" align="center" valign="top">
                        <table
                            width="100%"
                            cellpadding="0"
                            cellspacing="0"
                            style="border: 0px solid rgb(0, 0, 0); height: 95px"
                        >
                            <tbody>
                                <tr>
                                    <td
                                        width="4%"
                                        height="32"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        SI.No
                                    </td>
                                    <td
                                        width="13%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Service Type
                                    </td>
                                    <td
                                        width="12%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Brand / Model
                                    </td>
                                    <td
                                        width="15%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        IMIEI NO
                                    </td>
                                    <td
                                        width="16%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Problem
                                    </td>
                                    <td
                                        width="18%"
                                        align="center"
                                        style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Problem Description
                                    </td>
                                </tr>
                                ${customerList.map((customer: any = {}) => {
                                  count = count + 1;
                                  return `
                <tr>
                   <td
                      width="4%"
                      height="32"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${count}
                   </td>
                   <td
                      width="13%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.typeOfService}
                   </td>
                   <td
                      width="12%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.itemBrandName} / ${customer.itemName}
                   </td>
                   <td
                      width="15%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.imei}
                   </td>
                   <td
                      width="16%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.problem}
                   </td>
                   <td
                      width="18%"
                      align="center"
                      style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                      >
                      ${customer.problemDesc}
                   </td>
                </tr>
                `;
                                })}
                                <tr
                                    style="
                   font-size: 12px;
                   font-family: Arial, Helvetica, sans-serif;
                   "
                                >
                                    <td
                                        height="30"
                                        colspan="6"
                                        align="right"
                                        style="border-bottom: 1px dashed #000"
                                    ></td>
                                </tr>
                                <tr
                                    style="
                   font-size: 12px;
                   font-family: Arial, Helvetica, sans-serif;
                   "
                                >
                                    <td height="30" colspan="5" align="right">
                                        <span
                                            style="
                         font-family: Arial, Helvetica, sans-serif;
                         font-weight: bold;
                         font-size: 12px;
                         "
                                        >TOTAL :
                                        </span>
                                    </td>
                                    <td align="center" style="">
                                        <span
                                            style="
                         font-family: Arial, Helvetica, sans-serif;
                         font-weight: bold;
                         font-size: 12px;
                         "
                                        >${customerList.length}</span
                                        >
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td
                        height="55"
                        colspan="6"
                        align="right"
                        valign="top"
                        style="
          font-family: Arial, Helvetica, sans-serif;
          font-weight: bold;
          font-size: 12px;
          "
                    >
                        <table
                            width="100%"
                            cellspacing="0"
                            cellpadding="0"
                            style="border: 0px solid rgb(0, 0, 0)"
                        >
                            <tbody>
                                <tr>
                                    <td
                                        height="48"
                                        align="right"
                                        style="
                      padding-right: 90px;
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      "
                                    >
                                        Received the goods in good condition
                                    </td>
                                    <td
                                        style="
                      padding-left: 90px;
                      font-family: Arial, Helvetica, sans-serif;
                      font-weight: bold;
                      font-size: 12px;
                      ">For POORVIKA MOBILES PRIVATE LIMITED
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td height="50" colspan="3" align="center" valign="top">&nbsp;</td>
                    <td height="50" colspan="3" align="center" valign="top">&nbsp;</td>
                </tr>
            </tbody>
        </table>
    </body>
</html> `;

  const customersId = customerList.map((customer: any) => customer._id);
  createDeliveryChallanCall({
    idList: customersId,
    fileName: "Store_DeliveryChallan",
    html: html,
    branchCode: branchCode
  });
};
