import React, { useState, useEffect } from "react";
import { _get } from "utils/lodashConst";
import CommonCheck from "./CommonCheck";
import { Transition } from "@headlessui/react";
import { CommonSearchDropdownProps } from "./types";
import { convertToLowerCase, nullToObject } from "utils/validation";
import OutsideClickHandler from "react-outside-click-handler";
import { AdjustmentsIcon, CheckIcon, RefreshIcon } from "@heroicons/react/solid";
import CommonDropDownInput from "Component/CommonTable/CommonTableSortDropdown/CommonDropDownInput";

const FilterSettingsDrop = (props: CommonSearchDropdownProps) => {
  const {
    name = "",
    children = "",
    arrayData = [],
    setShow = true,
    inputClass = "",
    parentClass = "",
    dropOnTop = false,
    prevSelected = false,
    enableCheckbox = false,
    selectorKey = "optionData",
    onClickCheckBox = () => {}, //click
    handleOnChange = () => {}, //click
    handleResetFilter = () => {},
    handleParentDropVal = () => {}
  } = nullToObject(props);

  const [dropShow, setDropShow] = useState(false);
  const [reset, setReset] = useState(false);
  const [searchOptionValue, setSearchOptionValue] = useState<any>("");

  const dropShowHandle = (value = false) => setDropShow(value);

  const handleSelectValue = (valuesData: any) => {
    const { [selectorKey]: valueData = "" } = valuesData;
    if (!enableCheckbox) {
      setDropShow(false);
    }
    if (handleParentDropVal) {
      handleParentDropVal({ ...valuesData, name: name });
    }
    handleOnChange && handleOnChange({ target: { name: name, value: valueData } });
  };

  const handleOutSideClick = () => setDropShow(false);
  const handleColumnReset = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 1500);
    handleResetFilter();
  };

  const handleSearchfilter = (val = "") => {
    setSearchOptionValue(val);
    const ul: any = document && document.getElementById(`drop-${name}`);
    const a: any = ul?.querySelectorAll(".option");
    for (let i = 0; i < a?.length; i++) {
      const txtValue = a[i]?.textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(val?.toUpperCase()) > -1) {
        a[i].parentNode.style.display = "";
      } else {
        a[i].parentNode.style.display = "none";
      }
    }
  };

  const handleClearOptionSearch = () => handleSearchfilter("");

  useEffect(() => () => handleSearchfilter(""), []);
 
  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      <div
        className={`
                ${inputClass ? inputClass : ""}
                `}
      >
        <button
          type="button"
          data-jest-id={"jestDropShowHandle"}
          onClick={() => dropShowHandle(!dropShow)}
          className={`${
            dropShow ? "bg-primary-gradient-rv " : " bg-primary-gradient "
          } flex justify-center items-center w-8 h-8  bg-primary-gradient-hover rounded`}
        >
          {children ? (
            children
          ) : (
            <AdjustmentsIcon className={`${dropShow ? "transform rotate-180" : ""} w-5 h-5 text-white`} />
          )}
        </button>
        {/* click based table column view */}
        {dropShow && (
          <OutsideClickHandler onOutsideClick={handleOutSideClick}>
            <div
              className={` bg-white z-10 shadow-lg absolute right-0 w-60
                            ${dropOnTop ? " bottom-full dropOnTop mb-1 rounded" : " top-full mt-1 rounded"}`}
            >
              <div className="border-bottom bg-gray-50 flex flex-col">
                <div className="flex-col flex cursor-pointer">
                  <div className="flex flex-col p-2 bg-gray-100 relative z-10">
                    <button
                      type="button"
                      data-jest-id={"jestHandleColumnReset"}
                      onClick={handleColumnReset}
                      className=" inline-flex items-center w-full px-2 text-xs capitalize text-gray-700 text-left cursor-pointer"
                    >
                      <RefreshIcon
                        className={`${reset ? " animate-spin " : " "} h-5 w-5 mr-2 text-red-500 transition-all duration-200`}
                      />
                      <span>Reset Columns</span>
                    </button>
                    <CommonDropDownInput
                      autoFocus={true}
                      name={name}
                      value={searchOptionValue}
                      handleSearchfilter={handleSearchfilter}
                      handleClearOptionSearch={handleClearOptionSearch}
                    />
                  </div>

                  <ul id={`drop-${name}`} className="overflow-x-hidden overflow-y-auto max-h-52 ">
                    {(arrayData || []).map((item: any, i: any) => {
                      return (
                        <React.Fragment key={convertToLowerCase(i + "GetData-dtsv")}>
                          <li
                            tabIndex={0}
                            key={`drop-${name}-${1}`}
                            value={item[selectorKey]}
                            className="flex items-center hover:bg-gray-100 cursor-pointer px-2"
                          >
                            <button
                              type="button"
                              className={`inline-flex items-center w-full p-2 text-xs capitalize text-gray-700 text-left cursor-pointer`}
                              onClick={() => handleSelectValue(item)}
                            >
                              {enableCheckbox && (
                                <CommonCheck
                                  label={false}
                                  id={name + "-" + i}
                                  parentClass={"mr-2"}
                                  onChange={onClickCheckBox}
                                  checked={_get(item, "isSelect", false)}
                                />
                              )}
                              {prevSelected && <CheckIcon className="absolute left-1 w-4 h-4 text-gray-400 " />}
                              <span className="option">{item[selectorKey]}</span>
                            </button>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
    </Transition>
  );
};

export default FilterSettingsDrop;
