import { Get } from "ApiProcess/httpRestServices";
import store from "store";
import {
  getUserBasicInfoUsingMobileNumAction,
  pincodeBasicInfoCusDetail,
  updateBasicInfoCus,
  getCustomerEnquiresAction,
  getCustomerTransactionsAction,
  getUserBasicInfoUsingEmailAction,
  mergeRequestCustomerAction
} from "store/action/BasicInfoAction";
import { orgBaseUrl, userBasicDetail } from "ApiProcess/httpRestServices/apiManage";

/**
 * @param  {any} data
 * update userInfo
 */
export const updateBasicInfo = (data: any = {}) => {
  store.dispatch(updateBasicInfoCus(data));
  store.dispatch({
    basicInfoUpdateLoader: true,
    basicInfoUpdateResetData: false,
    type: "BASIC_IFO_UPDATE_LOADER"
  }); //loader
};

/**
 * Set popup to false/ to close popup
 */
export const resetBasicInfoTransactionPopupCall = () => {
  store.dispatch({ type: "SET_TRANSACTION_POPUP", transactionPopup: false });
};

/**
 * @param  {any} data
 * update userInfo
 */
export const updateOldBasicInfo = () => {
  store.dispatch({
    basicInfoUpdateLoader: false,
    basicInfoUpdateResetData: false,
    type: "BASIC_IFO_UPDATE_LOADER"
  }); //loader
};

/**
 *Mobile num use get user details
 */
export const getUserBasicInformationUsingMobileNum = (data: any = {}) => {
  store.dispatch(getUserBasicInfoUsingMobileNumAction(data));
  store.dispatch({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: true }); //loader
};

/**
 * merge request of customer
 */
export const mergeRequestCustomer = (data: any = {}) => {
  store.dispatch(mergeRequestCustomerAction(data)); //dispatch the merge request
  store.dispatch({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: true }); //loader
};

/**
 *Mobile num use get user details
 */
export const getUserBasicInformationUsingEmail = (data: any = {}) => {
  store.dispatch(getUserBasicInfoUsingEmailAction(data));
  store.dispatch({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: true }); //loader
};

/**
 *Get Enquiry details by customer
 */
export const getCustomerEnquires = (data = {}) => {
  store.dispatch(getCustomerEnquiresAction(data));
  store.dispatch({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: true }); //loader
};

/**
 *Get Transaction details by customer
 */
export const getCustomerTransactions = (data = {}) => {
  store.dispatch(getCustomerTransactionsAction(data));
  store.dispatch({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: true }); //loader
};

/**
 * @param  {any} data
 * pincode search
 */
export const getPincodeBasedDataBasiInfo = (data: any = {}) => {
  store.dispatch(pincodeBasicInfoCusDetail(data)); //dispatch the pincode details
  store.dispatch({ type: "BASIC_IFO_PINCODE_LOADER", userInfoPinCodeLoader: true }); //loader
};

/**
 * @param  {any} data
 * pincode search
 */
export const getPinCodeBasedGetStateDetails = (data: any = {}) => {
  return Get(`${orgBaseUrl}${userBasicDetail.areaGet}pincode=${data}`, "areaOrgToken");
  // store.dispatch(pincodeBasicInfoCusDetail(data));//dispatch the pincode details
  // store.dispatch({ type: "BASIC_IFO_PINCODE_LOADER", userInfoPinCodeLoader: true });//loader
};
/**
 * @param  {any} data
 * pincode search
 */
export const clearPincodeDate = () => {
  store.dispatch({ type: "BASIC_IFO_PINCODE_LOADER_DATA", userInfoPinData: [] }); //clear input
};

export const removeAutoFillData = () => {
  //rebove autoFill input file
  store.dispatch({ type: "BASIC_IFO_USER_FIND_INFORMATION", basicInfoUserInformation: null });
};

export const resetCustomerEnquiryList = () => {
  store.dispatch({ type: "BASIC_IFO_ENQUIRES_BY_CUSTOMER_DATA", customerEnquiries: [] });
};

export const resetCustomerTransactionList = () => {
  store.dispatch({ type: "BASIC_IFO_TRANS_BY_CUSTOMER_DATA", customerTransactions: [] });
};

/**
 * * once page is landing remove pervious data
 */
export const resetBasicInformation = () => {
  store.dispatch({ type: "BASIC_INITIAL_STAGE" });
  store.dispatch({ type: "NEW_CALL_PAGE_CUSTOMER_PHONE_NUMBER", newCallPagePhoneNum: "" });
};

export const cusCallType = [
  { id: 1, name: "callType", label: "In Coming", value: true },
  { id: 2, name: "callType", label: "Out Going", value: false }
];

export const basicInfoResetData = () => {
  store.dispatch({ type: "BASIC_INFO_DATA_RESET" });
};

/**
 * remove zero if zero is firt number
 * @param phone
 */
export const phoneNumInitialZeroRemove = (phone = "") => {
  const firstCharacter = phone.charAt(0);
  if (+firstCharacter === 0) {
    return phone.slice(1);
  }
  return phone;
};

/**
 * check zero if zero is firt number
 * @param phone
 */
export const phoneNumInitialZeroCheck = (phone = "") => {
  if (phone === "") {
    return true;
  }
  return +phone.charAt(0) === 0 ? false : true;
};
