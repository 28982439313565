import {
  GET_MAIL_BY_ID,
  RECEIVE_EMAIL_FROM_SERVER,
  SEND_EMAIL_TO_SERVER,
  SYNC_WITH_SERVER,
  GET_MAIL_ENQUIRY_BY_ID,
  DRAFT_EMAIL_TO_SERVER
} from "store/actionTypes/mailType";

/**
 * Send mail
 * @param  {Object} data customer mail data
 */
export const sendEmailToServerAction = (data = {}) => {
  return {
    type: SEND_EMAIL_TO_SERVER,
    data
  };
};

/**
 * draft mail
 * @param {Object} data customer mail data
 */
export const draftEmailToServerAction = (data = {}) => {
  return {
    type: DRAFT_EMAIL_TO_SERVER,
    data
  };
};
/**
 * Receive mail
 */
export const receiveEmailFromServerAction = (data = {}) => {
  return {
    type: RECEIVE_EMAIL_FROM_SERVER,
    data
  };
};

/**
 * Connect to server
 */
export const syncWithServerAction = (data = {}) => {
  return {
    type: SYNC_WITH_SERVER,
    data
  };
};

/**
 * get mail by id
 */
export const getMailByIdAction = (data = {}) => {
  return {
    type: GET_MAIL_BY_ID,
    data
  };
};

/**
 * get mail enquiry for by id
 */
export const getMailEnquiryByIdAction = (data = "") => {
  return {
    type: GET_MAIL_ENQUIRY_BY_ID,
    data
  };
};
