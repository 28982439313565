import { Get, Post, Put } from "ApiProcess/httpRestServices/index";
import { convertToLowerCase, leadingAndTrailingSpaceAvoid, nullToObject } from "utils/validation";
import {
  crmApiBaseUrl,
  orgBaseUrl,
  userBasicDetail,
  cusListPageApi,
  apxBaseUrlPath,
  encodeGetParams,
  pubsubBasePath
} from "../../ApiProcess/httpRestServices/apiManage";

/**
 *Update basic information
 */
export const updateBasicInfoServices = (dataInfo: any = {}) => {
  const {
    data: { genralInfo = {}, langUageInfo = [], locationInfo = {}, alternativeMobileNo = "" }
  } = dataInfo || {};
  const body = {
    ...(locationInfo.pinCode && {
      city: leadingAndTrailingSpaceAvoid(locationInfo.city),
      state: leadingAndTrailingSpaceAvoid(locationInfo.state),
      pincode: leadingAndTrailingSpaceAvoid(locationInfo.pinCode),
      district: leadingAndTrailingSpaceAvoid(locationInfo.district)
    }),
    name: leadingAndTrailingSpaceAvoid(genralInfo.userName),
    type: locationInfo.cusType,
    gender: genralInfo.userGender,
    email: leadingAndTrailingSpaceAvoid(
      convertToLowerCase(genralInfo.email) === "null" ? "" : convertToLowerCase(genralInfo.email)
    ),
    mobile: leadingAndTrailingSpaceAvoid(genralInfo.phoneNumber),
    ...(genralInfo.customerId && { customerId: genralInfo.customerId }),
    language: langUageInfo.map((ele: any) => ele.optionData),
    alt_mobile: leadingAndTrailingSpaceAvoid(genralInfo.whatsapp_num || alternativeMobileNo),
    whatsapp_num: leadingAndTrailingSpaceAvoid(genralInfo.alt_mobile)
  };
  return Post(`${crmApiBaseUrl}${userBasicDetail.updateBasicInfo}`, body, "crmToken");
};

export const updateCustomerDetailsApi = (body: any) => {
  return Post(`${crmApiBaseUrl}${userBasicDetail.updateBasicInfo}`, body, "crmToken");
};

/**
 * update basic info details
 */

export const updateBasicInfoHistory = (dataInfo: any = {}) => {
  return Post(`${crmApiBaseUrl}${userBasicDetail.salesBasicInfoHistoryUpdate}`, { ...dataInfo }, "crmToken");
};

/**
 * Get basic info details
 */

export const fetchBasicInfoHistory = (customerId: any = {}) => {
  const body: any = {};
  return Post(
    `${crmApiBaseUrl}${userBasicDetail.salesBasicInfoHistoryUpdate}?type=GET&customerId=${customerId}`,
    body,
    "crmToken"
  );
};

/**
 *Update basic information
 */
export const updateBasicInfoOfEnquiryService = (data: any) => {
  const {
    data: { enquiryId = "", customerName = "", customerEmail = "", customerMobile = "", customerLanguage = [] } = {}
  } = data || {};
  const body = {
    customerName: customerName === null ? "" : customerName,
    customerEmail: customerEmail === null ? "" : customerEmail,
    customerMobile: customerMobile === null ? "" : customerMobile,
    customerLanguage: customerLanguage.map((ele: any) => ele.optionData)
  };
  return Put(`${crmApiBaseUrl}${userBasicDetail.updateEnquiryCustomerDetails}${enquiryId}`, body, "crmToken");
};

/**
 *Update basic information
 */
export const getLocateBasicInfoServices = (data: any) => {
  const { data: pincode = "" } = nullToObject(data);
  return Get(`${orgBaseUrl}${userBasicDetail.areaGet}pincode=${pincode}`, "areaOrgToken");
};

/**
 * get user details
 */
export const getuserBasicInfoUseingMobileNumServices = (data: any = {}) => {
  const { data: phoneNumber = "" } = data;
  return Get(`${crmApiBaseUrl}${userBasicDetail.getUserDetails}${phoneNumber}?reqType=mobile`, "crmToken");
};

/**
 * get user details
 */
export const getuserBasicInfoUsingEmailServices = (data: any = {}) => {
  const { data: email = "" } = data;
  return Get(`${crmApiBaseUrl}${userBasicDetail.getUserDetails}${email}?reqType=email`, "crmToken");
};

/**
 * get user details
 */
export const getuserBasicInfoUsingCusIdServices = (data: any = {}) => {
  const { data: customerId = "" } = data;
  return Get(`${crmApiBaseUrl}${userBasicDetail.getUserDetails}${customerId}?reqType=customerId`, "crmToken");
};

/**
 * get enquiries by customer details
 */
export const getEnquiresByCustomerServices = (data: any = {}) => {
  const { data: { mobileNo = "", size = "", page = "" } = {} } = data;
  const newObj = {
    size: size,
    page: page,
    reqType: "customerMobile"
  };
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${cusListPageApi.listByCustomer}${mobileNo}?${consvertString}`, "crmToken");
};

/**
 * get transactions by customer details
 */
export const getTransDetailsByCustomerServices = (data: any = {}) => {
  const { data: { mobile = "" } = {} } = data;
  return Get(
    `${apxBaseUrlPath}${cusListPageApi.listTransByCustomer}${mobile}&InvoiceStartDate=0&InvoiceEndDate=0`,
    "apxToken"
  );
};

/**
 * get old transactions by customer details
 */
export const getOldTransDetailsByCustomerServices = (data: any = {}) => {
  const { mobile = "" } = data;
  return Get(`${pubsubBasePath}${cusListPageApi.oldTransHisRecord}${mobile}`, "pimApiToken");
};
