import React from "react";
import { _get } from "utils/lodashConst";
import { tdClass } from "utils/ClassContent";
import NoDataFoundPage from "Component/NoDataFoundPage";
import { dateTimeDisplay } from "utils/validation";

const EnquiryFeedbackTableBody = (props: any = {}) => {
  const { enquiryFeedbacks = [] } = props || {};
  return (
    <tbody>
      {_get(enquiryFeedbacks, "length", 0) > 0 ? (
        <>
          {enquiryFeedbacks.map((ele: any, i: number) => {
            return (
              <tr key={_get(ele, "createdAt", "")}>
                <td className={tdClass}>{dateTimeDisplay(_get(ele, "createdAt", ""))}</td>

                <td className={tdClass}>{_get(ele, "ticketId", "")}</td>

                <td className={tdClass}>{_get(ele, "language", "nill")}</td>

                <td className={tdClass}>{_get(ele, "customerName", "")}</td>

                <td className={tdClass}>{_get(ele, "customerMobileNo", "")}</td>

                <td className={tdClass}>{_get(ele, "customerEmail", "")}</td>

                <td className={tdClass}>{_get(ele, "itemBrandName", "")}</td>

                <td className={tdClass}>{_get(ele, "itemName", "")}</td>

                <td className={tdClass}>{_get(ele, "itemCode", "")}</td>

                <td className={tdClass}>{_get(ele, "enquiryFor", "")}</td>

                <td className={tdClass}>{_get(ele, "employeeId", "")}</td>

                <td className={tdClass}>{_get(ele, "employeeName", "")}</td>

                <td className={tdClass}>{_get(ele, "employeeBranch", "")}</td>

                <td className={tdClass}>{_get(ele, "employeeRole", "")}</td>

                <td className={tdClass}>{_get(ele, "find_it_easy", "")}</td>

                <td className={tdClass}>{_get(ele, "inform_EMI", "")}</td>

                <td className={tdClass}>{_get(ele, "price_offers", "")}</td>

                <td className={tdClass}>{_get(ele, "rating", "")}</td>

                <td className={tdClass}>{_get(ele, "notes", "")}</td>

                <td className={tdClass}>{_get(ele, "status", "")}</td>

                <td className={tdClass}>{_get(ele, "currentStatus", "")}</td>

                <td className={tdClass}>{_get(ele, "notify", "") === "Y" ? "Yes" : "No"}</td>
              </tr>
            );
          })}
        </>
      ) : (
        <NoDataFoundPage colSpan={7} />
      )}
    </tbody>
  );
};

export default EnquiryFeedbackTableBody;
