import { _get } from "utils/lodashConst";

export const initail = {
  isFullView: false
};

export const themeReducer = (state = initail, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "THEME_INITIAL_STAGE": {
      return {
        ...state,
        ...initail
      };
    }

    case "FULL_VIEW": {
      return {
        ...state,
        isFullView: _get(action, "data.isFullView", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
