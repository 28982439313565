import React from "react";
import CommonInput from "../CommonInput";
import { commonMultiChooseProps } from "../types";
import { convertToLowerCase } from "utils/validation";
import { IconError, IconSearchCustomer } from "assets/images";

const CooseLeftSideData = (props: commonMultiChooseProps) => {
  const {
    name = "",
    value = "",
    GetData = [],
    labelLeft = "",
    inputClass = "",
    optionClass = "",
    selectLeftId = "1",
    inputError = false,
    inputvalid = false,
    enableSearch = false,
    inputFieldClass = "",
    inputWithIcon = false,
    inputWithIconClass = "",
    getSelectValues = () => {},
    handleLeftSearch = () => {}
  } = props || {};

  const dataList = (GetData || []).filter((ele: any) => ele.isSelect === false); //only filter show for non-seleted Data

  return (
    <div
      className={`
                    ${inputClass ? inputClass : ""}
                    ${inputWithIcon ? inputWithIcon : ""}
                    relative mb-1 px-4 md:w-1/2 w-full flex flex-col`}
    >
      {labelLeft && (
        <label htmlFor={`multiSelect-${selectLeftId}`} className={`block text-xs input-label mb-1`}>
          {labelLeft}
        </label>
      )}
      {enableSearch && (
        <label className="relative w-full">
          <CommonInput
            name={name}
            value={value}
            setShow={true}
            parentClass={""}
            parentPosition={""}
            placeholder={"Search"}
            onChange={handleLeftSearch}
            inputFieldClass="h-9 rounded-b-none -mb-1"
          />
        </label>
      )}
      <div
        id={`multiSelect-${selectLeftId}`}
        className={`block w-full h-auto rounded select-common px-3 py-2 ${inputError && !inputvalid ? " error pr-4" : ""}
                        ${inputvalid && !inputError ? " input-valid" : ""}${inputFieldClass ? inputFieldClass : ""} ${
                          enableSearch ? " search" : ""
                        }                `}
      >
        {dataList.map((item: any, i: any) => {
          return (
            <button
              type="button"
              onClick={(e) => getSelectValues(e, JSON.stringify(item))}
              key={convertToLowerCase(i + "dataList-efsv")}
              className={optionClass}
            >
              {item.isSelect === false ? item.optionData : null}
            </button>
          );
        })}
      </div>

      {inputError && (
        <i className="inline-block  absolute top-1/2 right-3 transform -translate-y-1/2">
          <IconError />
        </i>
      )}

      {inputWithIcon && (
        <span
          className={`absolute flex justify-center items-center 
                            ${inputWithIconClass ? inputWithIconClass : ""}`}
        >
          <IconSearchCustomer />
        </span>
      )}
    </div>
  );
};

export default CooseLeftSideData;
