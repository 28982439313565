import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { nullToObject } from "utils/validation";
import PageAnimate from "Component/PageAnimate";
import { ImgAvatarMaleSm3 } from "assets/images";
import { ComplainUpdatePopupProps } from "./types";
import ComplainForm from "./InputForm/ComplainForm";
import CommonDropHeading from "Component/CommonDropHeading";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import ComplainInputForm from "./InputForm/ComplainInputForm";
import { onWheel } from "Component/SliderMenu/CommonFunctions";
import BasicInfoView from "Pages/Customers/CallsList/BasicInfoView";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import ComplainStatusTrackingTable from "./ComplainStatusTrackingTable";
import { bg_BasicInfo, bg_EnquiryInfo, inputStylePopup } from "utils/ClassContent";
import { getMailByComplaintIdCall, resetComplaintEnquiryMailCall } from "../commonComplainlist";

const ComplainUpdatePopup = (props: ComplainUpdatePopupProps) => {
  const { setShow = true, isFollowUp = false, closePopup, getData = {}, comListApiReload = () => {} } = props;
  const [editView, setEditView] = useState(false);
  const [columnView, setColumnView] = useState(false);
  const { enquiryFor = "", customerName = "", mailId = "" } = getData;
  const [complainData, setComplainData] = useState(getData);
  const [popParentTabs, setPopParentTabs] = useState("Tab0");
  const [enableViewMode, setEnableViewMode] = useState(true);

  const globalStore = useSelector((state: any) => state || {});
  const { mailReducer: { selectedMailData = {} } = {} } = nullToObject(globalStore);

  const handlePopParentTabs = (value = "") => {
    setPopParentTabs(value);
  };
  /**
   * handle complain data changes
   * @param data
   */
  const handleUpdateDataChange = (data: any = {}) => {
    setComplainData({
      ...complainData,
      ...data
    });
  };

  const handleColumnView = () => {
    setColumnView(!columnView);
  };

  const handleEditView = () => {
    setEditView(!editView);
    setEnableViewMode(!enableViewMode);
  };

  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) {
      closePopup(); //close popUp press esc button
    }
  };

  useEffect(() => {
    setEditView(false);
    setEnableViewMode(true);
  }, [props.enableViewMode]);
  /**
   * @param
   * @returns open or close popup
   */
  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);
  /**
   * user clik escape key close popUp
   */
  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (mailId) {
      getMailByComplaintIdCall(mailId);
    }
    return () => {
      if (mailId) {
        resetComplaintEnquiryMailCall();
      }
    };
  }, [mailId]);

  return (
    <FixedPopupTemplate
      editStatus={editView}
      imgSrc={ImgAvatarMaleSm3}
      columnViewStatus={columnView}
      enableColumnViewOption={true}
      textHeaderHeading={customerName}
      handleEditView={handleEditView}
      handleClosePopup={() => closePopup(false)}
      handleColumnView={handleColumnView}
      textSubHeaderHeading={`Enquiry For : ${enquiryFor}`}
      enableEditOption={complainData.status !== "Closed"}
    >
      <PageAnimate className="h-full">
        <div className="sticky -top-1 bg-white z-20 shadow-md">
          <ScrollMenu scrollContainerClassName={"flex whitespace-nowrap"} onWheel={onWheel}>
            <a
              href="#BasicInfo"
              onClick={() => handlePopParentTabs("BasicInfo")}
              className={`mx-2 ${popParentTabs === "BasicInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         whitespace-nowrap rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer font-medium`}
            >
              Basic Info
            </a>
            <a
              href="#EnquiryInfo"
              onClick={() => handlePopParentTabs("EnquiryInfo")}
              className={`mx-2 ${popParentTabs === "EnquiryInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         whitespace-nowrap rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer`}
            >
              Enquiry Info
            </a>
            <a
              href="#ComplaintStatusHistory"
              onClick={() => handlePopParentTabs("ComplaintStatusHistory")}
              className={`mx-2 ${popParentTabs === "ComplaintStatusHistory" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         whitespace-nowrap rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer`}
            >
              Complaint Status History
            </a>
            <a
              href="#EmployeeDetail"
              onClick={() => handlePopParentTabs("EmployeeDetail")}
              className={`mx-2 ${popParentTabs === "EmployeeDetail" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         whitespace-nowrap rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer`}
            >
              Employee Detail
            </a>
            <a
              href="#ComplianInfo"
              onClick={() => handlePopParentTabs("ComplianInfo")}
              className={`mx-2 ${popParentTabs === "ComplianInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         whitespace-nowrap rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer`}
            >
              Complaint Info
            </a>
          </ScrollMenu>
        </div>
        <div className="flex flex-wrap overflow-y-auto">
          <div
            className={`${columnView ? "flex h-full items-start flex-wrap lg:flex-nowrap" : ""} md:px-2 overflow-y-auto`}
            style={{ maxHeight: "calc(100% - 100px)" }}
          >
            <div className={`${columnView ? " w-full lg:w-7/12" : " "} flex-wrap flex md:px-2`}>
              <CommonDropHeading
                tabActiveDefault={popParentTabs === "BasicInfo" ? true : false}
                heading="Basic Info"
                id="BasicInfo"
                customClass={bg_BasicInfo}
              >
                <BasicInfoView
                  customerData={complainData}
                  handleEditView={handleEditView}
                  enableViewMode={enableViewMode}
                  onChange={handleUpdateDataChange}
                />
              </CommonDropHeading>
              <CommonDropHeading
                tabActiveDefault={popParentTabs === "EnquiryInfo" ? true : false}
                heading="Enquiry Info"
                id="EnquiryInfo"
                customClass={bg_EnquiryInfo}
              >
                <CommonSearchDropdown
                  label={true}
                  setShow={true}
                  mustField={true}
                  value={enquiryFor}
                  name={"enquiryFor"}
                  placeholder={"--None--"}
                  inputWithIconClass="w-10"
                  labelText={"Enquiry For"}
                  labelTextClass={"font-bold"}
                  inputFieldClass="h-9 pr-11"
                  parentClass={inputStylePopup}
                  inputClass="flex justify-end"
                  readOnly={true}
                />
                <ComplainForm
                  setShow={true}
                  editView={editView}
                  isFollowUp={isFollowUp}
                  viewModeData={complainData}
                  handleUpdateDataChange={handleUpdateDataChange}
                  enableViewMode={enableViewMode}
                />
              </CommonDropHeading>

              <ComplainStatusTrackingTable complainData={complainData} transactions={complainData.transactions} />
            </div>
            <div
              className={` ${columnView ? "w-full lg:w-5/12 " : " w-full "} bg-white flex flex-col items-center md:px-2`}
            >
              <ComplainInputForm
                isFollowUp={isFollowUp}
                columnView={columnView}
                selectedTab={popParentTabs}
                viewModeData={complainData}
                enableViewMode={enableViewMode}
                selectedMailData={selectedMailData}
                comListApiReload={comListApiReload}
              />
            </div>
          </div>
        </div>
      </PageAnimate>
    </FixedPopupTemplate>
  );
};

export default ComplainUpdatePopup;
