import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { paymentModeData } from "Component/MockData";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import { convertToLowerCase } from "utils/validation";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

const ViewSalesReturnBasicIformation = (props: any) => {
  const {
    setShow = true,
    viewModeData = {},
    enableViewMode = false,
    passItemGrpName = () => {},
    passDataGenralData = () => {}
  } = props || {};

  const [billNo, setBillNo] = useState("");
  const [cusName, setCusName] = useState("");
  const [itemName, setItemName] = useState("");
  const [billDate, setBillDate] = useState("");
  const [itemCode, setItemCode] = useState("");
  const [branchName, setbranchName] = useState("");
  const [paymentMode, setPaymentMode] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [itemGrupName, setItemGrupName] = useState("");
  const [itemBrandName, setItemBrandName] = useState("");
  const [invoiceAmount, setInvoiceAmount] = useState("");

  const [basiInfoData, setbasiInfoData] = useState({
    billNo: "",
    cusName: "",
    billDate: "",
    itemName: "",
    itemCode: "",
    branchName: "",
    paymentMode: "",
    mobileNumber: "",
    itemGrupName: "",
    itemBrandName: "",
    invoiceAmount: ""
  });

  /**
   * onUpdate main state and error validate
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const userDetails = basiInfoData;

    switch (name) {
      case "cusName":
        userDetails.cusName = value;
        setCusName(value);
        break;
      case "mobileNumber":
        userDetails.mobileNumber = value;
        setMobileNumber(value);
        break;
      case "billDate":
        userDetails.billDate = value;
        setBillDate(value);
        break;
      case "itemName":
        userDetails.itemName = value;
        setItemName(value);
        break;
      case "billNo":
        userDetails.billNo = value;
        setBillNo(value);
        break;
      case "branchName":
        userDetails.branchName = value;
        setbranchName(value);
        break;
      case "paymentMode":
        userDetails.paymentMode = value;
        setPaymentMode(value);
        break;
      case "itemGrupName":
        userDetails.itemGrupName = value;
        setItemGrupName(value);
        passItemGrpName(value);
        break;
      case "itemBrandName":
        userDetails.itemBrandName = value;
        setItemBrandName(value);
        break;
      case "itemCode":
        userDetails.itemCode = value;
        setItemCode(value);
        break;
      case "invoiceAmount":
        userDetails.invoiceAmount = value;
        setInvoiceAmount(value);
        break;
      default:
        break;
    }
    setbasiInfoData(userDetails);
    passDataGenralData(userDetails, true);
  };

  const styleClass = () => {
    return inputStylePopup;
  };

  const handleEnquiryForVal = (data: any) => {
    const { optionData = "" } = data || {};
    onChange({ target: { name: "paymentMode", value: optionData } });
  };

  /**
   * onInput field empty
   */
  const resetInputDat = () => {
    onChange({ target: { name: "billNo", value: "" } });
    onChange({ target: { name: "cusName", value: "" } });
    onChange({ target: { name: "itemName", value: "" } });
    onChange({ target: { name: "billDate", value: "" } });
    onChange({ target: { name: "branchName", value: "" } });
    onChange({ target: { name: "paymentMode", value: "" } });
    onChange({ target: { name: "mobileNumber", value: "" } });
    onChange({ target: { name: "itemGrupName", value: "" } });
    onChange({ target: { name: "itemBrandName", value: "" } });
    onChange({ target: { name: "invoiceAmount", value: "" } });
  };

  useEffect(() => {
    const {
      imeiMobileNo = "",
      billNo: _billNo = "",
      imeiCustomerName = "",
      itemGroupName = "",
      itemCode: _itemCode = "",
      billDate: _billDate = "",
      itemName: _itemName = "",
      branchName: _branchName = "",
      paymentMode: _paymentMode = "",
      itemBrandName: _itemBrandName = "",
      invoiceAmount: _invoiceAmount = ""
    } = viewModeData;

    onChange({ target: { name: "billNo", value: _billNo } });
    onChange({ target: { name: "billDate", value: _billDate } });
    onChange({ target: { name: "itemName", value: _itemName } });
    onChange({ target: { name: "itemCode", value: _itemCode } });
    onChange({ target: { name: "cusName", value: imeiCustomerName } });
    onChange({ target: { name: "branchName", value: _branchName } });
    onChange({ target: { name: "mobileNumber", value: imeiMobileNo } });
    onChange({ target: { name: "paymentMode", value: _paymentMode } });
    onChange({ target: { name: "itemGrupName", value: itemGroupName } });
    onChange({ target: { name: "itemBrandName", value: _itemBrandName } });
    onChange({ target: { name: "invoiceAmount", value: _invoiceAmount } });
    return () => {
      resetInputDat();
    };
  }, [enableViewMode]);

  return (
    <React.Fragment>
      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        value={mobileNumber}
        name={"mobileNumber"}
        inputFieldClass="h-9"
        parentClass={styleClass()}
        labelText={"Mobile Number"}
        labelTextClass={"font-bold"}
        placeholder={"Mobile Number"}
        readOnly={enableViewMode}
      />

      <CommonInput
        value={itemCode}
        name={"itemCode"}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        labelText={"Item Code"}
        parentClass={styleClass()}
        placeholder={"Item Code"}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
      />

      <CommonInput
        setShow={setShow}
        value={branchName}
        inputError={false}
        onChange={onChange}
        name={"branchName"}
        inputFieldClass="h-9"
        labelText={"Branch Name"}
        parentClass={styleClass()}
        placeholder={"Branch Name"}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
      />

      <CommonInput
        value={cusName}
        name={"cusName"}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        parentClass={styleClass()}
        labelText={"Customer Name"}
        labelTextClass={"font-bold"}
        placeholder={"Customer Name"}
        readOnly={enableViewMode}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        value={itemGrupName}
        name={"itemGrupName"}
        inputFieldClass="h-9"
        labelText={"Product"}
        placeholder={"Product"}
        parentClass={styleClass()}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        labelText={"Brand"}
        onChange={onChange}
        placeholder={"Brand"}
        value={itemBrandName}
        inputFieldClass="h-9"
        name={"itemBrandName"}
        parentClass={styleClass()}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
      />

      <CommonInput
        value={itemName}
        name={"itemName"}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        labelText={"Model"}
        placeholder={"Model"}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
        parentClass={styleClass()}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        value={convertToLowerCase(invoiceAmount)}
        inputFieldClass="h-9"
        name={"invoiceAmount"}
        parentClass={styleClass()}
        labelText={"Product value"}
        labelTextClass={"font-bold"}
        placeholder={"Product value"}
        readOnly={enableViewMode}
      />

      <CommonInput
        value={billNo}
        name={"billNo"}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        labelText={"Bill No"}
        inputFieldClass="h-9"
        placeholder={"Bill No"}
        parentClass={styleClass()}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
      />

      <CommonInput
        value={billDate}
        name={"billDate"}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        labelText={"Bill Date"}
        placeholderText={billDate}
        enableDatePicker={true}
        isIconLabel={true}
        placeholder={"Bill Date"}
        parentClass={styleClass()}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
        enableViewMode={enableViewMode}
        inputWithIcon={billDate ? false : true}
      />

      <CommonSearchDropdown
        setShow={setShow}
        value={paymentMode}
        name={"paymentMode"}
        inputWithIcon={false}
        placeholder={"--None--"}
        arrayData={paymentModeData}
        inputWithIconClass="w-10"
        parentClass={styleClass()}
        labelText={"Payment Mode"}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        readOnly={enableViewMode}
        handleParentDropVal={handleEnquiryForVal}
      />
    </React.Fragment>
  );
};
export default ViewSalesReturnBasicIformation;
