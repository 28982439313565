import React from "react";
import { TableClass, tdClass } from "utils/ClassContent";
import { convertToLowerCase, formatIndianCurrency, nullToArray, nullToString } from "utils/validation";

type EmiBankDetailsProps = {
  emiTableData?: any;
  showEmiMoreDetailsClick?: (e?: any) => void;
};

const EmiTable = (props: EmiBankDetailsProps) => {
  const thClass =
    "py-2 px-4 bg-blueGray-50 text-blueGray-500 align-middle h-10 border border-solid border-blueGray-100 text-xs font-semibold text-left";
  const { showEmiMoreDetailsClick = () => {}, emiTableData = {} } = props || {};
  const sortEmiTenture: any = (a: any, b: any) => Number(a?.tenure) - Number(b?.tenure);
  return (
    <div className="block w-full overflow-x-auto">
      <table className={TableClass}>
        <thead>
          <tr className="whitespace-nowrap">
            <td style={{ minWidth: "100px" }} className={thClass}>
              <span>{"TENURE IN MONTHS"}</span>
            </td>
            <td style={{ minWidth: "100px" }} className={thClass}>
              <span>{"EMI MONTHLY INSTALLMENT (₹)"}</span>
            </td>
            <td style={{ minWidth: "100px" }} className={thClass}>
              <span>{"BANK INTEREST P/A (%)"}</span>
            </td>
            <td style={{ minWidth: "100px" }} className={thClass}>
              <span>{"INTEREST PAY TO BANK (₹)"}</span>
            </td>
            <td style={{ minWidth: "100px" }} className={thClass}>
              <span>{"TOTAL AMOUNT (₹)"}</span>
            </td>
          </tr>
        </thead>
        <tbody>
          {nullToArray(emiTableData?.tenureDetails)
            ?.sort(sortEmiTenture)
            ?.map((ele: any, i: number) => {
              if (ele?.tenure !== "1") {
                return (
                  <tr key={convertToLowerCase(i + "renderBank-dgd")}>
                    <td className={tdClass}>{ele?.tenure}</td>
                    <td className={tdClass}>{formatIndianCurrency(ele?.monthlyInstallment, 0)}</td>
                    <td className={tdClass}>{nullToString(ele?.rateOfInterest)}</td>
                    <td className={tdClass}>{formatIndianCurrency(ele?.totalInterestPayToBank, 0)}</td>
                    <td className={tdClass}>
                      <div className="text-center block text-xs ml-2">
                        {formatIndianCurrency(ele?.totalEMIAmountPayable, 0)}
                      </div>
                      <div className="text-center block text-xs ml-2">
                        <button
                          type="button"
                          onClick={() => showEmiMoreDetailsClick({ ...ele, bank: emiTableData?.name })}
                        >
                          <span className="text-blue-500">More Details</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
        </tbody>
      </table>
    </div>
  );
};

export default EmiTable;
