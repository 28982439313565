import { _get } from "utils/lodashConst";

export const customerDetailsInital = {
  couponDetails: {},
  userPurchaseHistory: [],
  loaderSalesOffer: false,
  newCallPagePhoneNum: ""
};

export const newCallPagecustomerDetailsReducer = (state = customerDetailsInital, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "CUS_DETAISL_REDUCER_INITIAL": {
      return {
        ...state,
        ...customerDetailsInital
      };
    }

    //sales information store
    case "SALES_INFORMATON_COUPON_DETAILS": {
      return {
        ...state,
        couponDetails: _get(action, "couponDetails", "")
      };
    }

    //new call page enter phone number show here
    case "NEW_CALL_PAGE_CUSTOMER_PHONE_NUMBER": {
      return {
        ...state,
        newCallPagePhoneNum: _get(action, "newCallPagePhoneNum", "")
      };
    }

    //last FIVE year history
    case "USER_POORVIKA_PURCHASE_HISTORY": {
      return {
        ...state,
        userPurchaseHistory: _get(action, "userPurchaseHistory", "")
      };
    }

    default:
      return {
        ...state
      };
  }
};
