export const GET_REGISTER_USER_DETAILS = "GET_REGISTER_USER_DETAILS"; //get user details
export const GET_CUS_LOCATION_BASED_PINCODE = "GET_CUS_LOCATION_BASED_PINCODE";
export const GET_BASIC_INFORMATION_CUSTOMER = "GET_BASIC_INFORMATION_CUSTOMER"; //MOBILE NUMBER USE GET DEAILS
export const GET_BASIC_INFORMATION_USING_EMAIL = "GET_BASIC_INFORMATION_USING_EMAIL"; //Email USE GET DEAILS
export const GET_BASIC_INFORMATION_USING_CUSTOMER_ID = "GET_BASIC_INFORMATION_USING_CUSTOMER_ID"; //Email USE GET DEAILS
export const GET_BASIC_INFORMATION_FROM_ENQUIRY_POPUP = "GET_BASIC_INFORMATION_FROM_ENQUIRY_POPUP";
export const GET_ENQUIRY_INFORMATION_BY_CUSTOMER = "GET_ENQUIRY_INFORMATION_BY_CUSTOMER"; //MOBILE NUMBER USE GET DEAILS
export const GET_TRANS_INFORMATION_BY_CUSTOMER = "GET_TRANS_INFORMATION_BY_CUSTOMER"; //MOBILE NUMBER USE GET DEAILS
export const UPDATE_BASIC_INFORMATION_CUSTOMER = "UPDATE_BASIC_INFORMATION_CUSTOMER";
export const UPDATE_BASIC_INFORMATION_FROM_POPUP = "UPDATE_BASIC_INFORMATION_FROM_POPUP";
export const GET_CONTACT_CUSTOMER_DETAILS_DATA = "GET_CONTACT_CUSTOMER_DETAILS_DATA"; //get cusdetails
export const MERGE_REQUEST_NEW_CUSTOMER = "MERGE_REQUEST_NEW_CUSTOMER"; // merge request new customer
export const UPDATE_ENQUIRY_BASIC_CUSTOMER_INFO = "UPDATE_ENQUIRY_BASIC_CUSTOMER_INFO"; // update enq customer info
