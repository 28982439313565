import * as emiTypes from "../actionTypes/emiTypes";
import { emiDetailsGetActionType } from "Pages/NewCall/EnquiryFor/SalesEnquiry/SalesEmi/types";

//tokenGet
export const getTypesenseAccessTokenAction = () => {
  return {
    type: emiTypes.TYPESENSE_ACCESS_TOKEN
  };
};

//get emiProductDetails
export const emiProductDatagetAction = (modalName: string | number) => {
  return {
    type: emiTypes.GET_EMI_PRODUCT_DATA,
    modalName
  };
};

//get banck anf offer details
export const emiDetailsGetAction = (data: emiDetailsGetActionType) => {
  const { special_price = 0, price = 0, sku = "" } = data || {};
  const newObj = {
    skuId: sku,
    productPrice: special_price ? special_price : price
  };
  if (data.isOnlineEmi) {
    newObj.productPrice = data.onlinPriceAmt;
  }
  return {
    type: emiTypes.GET_EMI_PRODUCT_DETAILS,
    newObj
  };
};
