import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";

import { inputStylePopup } from "utils/ClassContent";
import { salesReturnMock } from "Component/MockData";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { convertToLowerCase, nullToObject } from "utils/validation";
import {
  checkSRNorNot,
  getServiceCenterList,
  getServiceCenterToken,
  removeSalesreturnAutoFill,
  updatesalesreturnDataViaEdit
} from "../commonSalesReturn";
import EnquiryForRemark from "../EnquiryForRemark";
import SourseSubCategory from "./SourceReturnSubCategory";
import SalesReturnLoader from "../CreateSalesReturn/SalesReturnLoader";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

type SalesReturnsFormProps = {
  setShow?: boolean;
  editView?: boolean;
  viewModeData?: any;
  handleEditView?: any;
  enableViewMode?: boolean;
  enquiryForValue?: string;
  children?: any;
  setEnableViewMode?: any;
  handleCustomerStatus?: any;
};

const ViewSalesReturn = (props: SalesReturnsFormProps) => {
  const {
    children,
    setShow = true,
    editView = false,
    viewModeData = {},
    handleCustomerStatus = () => {},
    enquiryForValue = "",
    enableViewMode = false,
    handleEditView = () => {}
  } = nullToObject(props);

  const { status = "" } = viewModeData;
  const enbleUpdateButton = convertToLowerCase(status) === convertToLowerCase("Closed") ? true : false;

  // Approved Rejected

  const globalStore = useSelector((state: any) => state) || {};
  const { salesReturn: { salesReturnSuccessCode = false, serviceCenterDataList = [] } = {} } =
    nullToObject(globalStore);

  const [loader, setLoader] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [remarkData, setRemarkData] = useState("");
  const [srSubCategoryState, setsrSubCategory] = useState({});
  const [passRefunDataState, setpassRefunData] = useState({});
  const [passImeiNumberState, setpassImeiNumber] = useState({});
  const [passDoaDetailsState, setpassDoaDetails] = useState({});
  const [replacePassdataState, setreplacePassdata] = useState({});
  const [showSRSubCategory, setShowSRSubCategory] = useState(false);
  const [passDataGenralDataState, setpassDataGenralData] = useState({});
  const [passSalesReturnDataState, setpassSalesReturnData] = useState({});

  const onChange = (event: any = {}) => {
    const { target: { value = "" } = {} } = event || {};
    setRemarkData(value);
  };

  const handleButtonStateChange = (item: any) => {
    //sub categorry update
    const { optionData = "" } = item;
    optionData !== "" ? setShowSRSubCategory(true) : setShowSRSubCategory(false);
    setInputValue(optionData);
    const newObj = {
      srSubCategory: optionData
    };
    setsrSubCategory(newObj);
  };

  const clearInput = (event: any = {}) => {};

  const passDataGenralData = (data = {}) => {
    //passing sucess
    setpassDataGenralData(data);
  };

  const passSalesReturnData = (data = {}) => {
    //passing sucess
    setpassSalesReturnData(data);
  };

  const passDoaDetails = (data = {}) => {
    //pass sucessfull
    setpassDoaDetails(data);
  };

  const replacePassdata = (data = {}) => {
    //pass sucessfull
    setreplacePassdata(data);
  };

  const passRefunData = (data = {}) => {
    //pass sucessfull
    setpassRefunData(data);
  };
  const passImeiNumber = (data = {}) => {
    //pass sucessfull
    setpassImeiNumber(data);
  };

  const proceedToStoreData = async () => {
    const newObj = {
      originalElement: viewModeData,
      passRefunData: passRefunDataState,
      srSubCategory: srSubCategoryState,
      passDoaDetails: passDoaDetailsState,
      passImeiNumber: passImeiNumberState,
      replacePassdata: replacePassdataState,
      passDataGenralData: passDataGenralDataState,
      passSalesReturnData: passSalesReturnDataState,
      enquireFor: { remarkData: remarkData, enquireFor: enquiryForValue }
    };
    setLoader(true);
    const response = await updatesalesreturnDataViaEdit(newObj); //pass field information
    setLoader(false);
    const { data: { status: updateStatus = 0, message = "" } = {} } = nullToObject(response);
    if (updateStatus === 200) {
      handleEditView();
      handleCustomerStatus();
      successToast(message);
    } else {
      failToast(message);
    }
  };

  const resetAllField = () => {
    setInputValue("");
    setRemarkData("");
    setsrSubCategory({});
    setpassRefunData({});
    setpassImeiNumber({});
    setpassDoaDetails({});
    setreplacePassdata({});
    setpassDataGenralData({});
    setpassSalesReturnData({});
    setShowSRSubCategory(false);
  };

  const onsalesReturnUpdate = () => {
    const srSubCategory = _get(srSubCategoryState, "srSubCategory", "");
    const srnValueCheck = checkSRNorNot(srSubCategory);
    const srnValue = _get(passSalesReturnDataState, "srnValue", "");
    const reasonforSalesReturn = _get(passSalesReturnDataState, "reasonforSalesReturn", "");
    const refundEmpId = _get(passRefunDataState, "refundEmpId", "");
    const refundRequestAmt = _get(passRefunDataState, "refundRequestAmt", "");

    if (showSRSubCategory && enquiryForValue !== "") {
      if (reasonforSalesReturn) {
        //select any reson
        if (reasonforSalesReturn !== "Refund") {
          if (!srnValueCheck) {
            //not choose srn call api
            proceedToStoreData(); //api process
          } else if (srnValueCheck && srnValue !== "") {
            //choosed srn but srn value is not given
            proceedToStoreData(); //api process
          } else {
            failToast("Please fill SRN value");
          }
        } else {
          if (refundEmpId) {
            if (refundRequestAmt) {
              proceedToStoreData(); //api process
            } else {
              failToast("Refund Request Amount is Must for proceed Refund");
            }
          } else {
            failToast("Employee Id is Must for proceed Refund");
          }
        }
      } else {
        failToast("please select reason for return");
      }
    } else {
      failToast("Please fill all input value/correct input data");
    }
  };

  useEffect(() => {
    if (salesReturnSuccessCode) {
      //after success sales return remove input
      resetAllField(); //clear state
      removeSalesreturnAutoFill();
      handleButtonStateChange({ optionData: "" });
    }
    return () => {
      resetAllField();
    };
  }, [salesReturnSuccessCode]);

  useEffect(() => {
    if (!window.sessionStorage.getItem("serviceCenterToken")) {
      getServiceCenterToken(); //service center token get apo call
    }
    if (serviceCenterDataList.length === 0) {
      getServiceCenterList(); //get service center list
    }
  }, []);

  useEffect(() => {
    if (enableViewMode === true) {
      const { remarks = "" } = viewModeData;
      const selectedValue = { optionData: enquiryForValue };
      handleButtonStateChange(selectedValue);
      setRemarkData(remarks);
    }
  }, [enableViewMode]);

  return (
    <React.Fragment>
      <SalesReturnLoader loader={loader} globalSate={globalStore} />
      <CommonSearchDropdown
        label={true}
        setShow={setShow}
        value={inputValue}
        removeCursor={true}
        readOnly={true}
        inputWithIcon={false}
        name={"SRSubCategory"}
        placeholder={"--None--"}
        arrayData={salesReturnMock}
        inputWithIconClass="w-10"
        labelText={"Sub Category"}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        handleParentDropVal={handleButtonStateChange}
        clearInput={clearInput}
        parentClass={inputStylePopup}
      />
      {showSRSubCategory === true && (
        <>
          <SourseSubCategory
            editView={false}
            globalStore={globalStore}
            viewModeData={viewModeData}
            setShow={showSRSubCategory}
            passRefunData={passRefunData}
            passImeiNumber={passImeiNumber}
            passDoaDetails={passDoaDetails}
            enableViewMode={enableViewMode}
            replacePassdata={replacePassdata}
            srSubCategoryState={srSubCategoryState}
            passDataGenralData={passDataGenralData}
            passSalesReturnData={passSalesReturnData}
          />
          <EnquiryForRemark
            name={remarkData}
            value={remarkData}
            onChange={onChange}
            editView={editView}
            readOnly={enableViewMode}
          />
          {!enableViewMode && !enbleUpdateButton && (
            <div className="bg-white sticky bottom-0 py-5 flex flex-col items-center justify-center w-full">
              <div>
                <button
                  type="button"
                  onClick={onsalesReturnUpdate}
                  className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient my-2"
                >
                  {"Update"}
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {children}
    </React.Fragment>
  );
};
export default ViewSalesReturn;
