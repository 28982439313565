import store from "store";
import { Get } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { crmApiBaseUrl, dashBoardApi, encodeGetParams, rolesAndPagesApi } from "ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * get user roles details
 */
export const getUserRolesDetailsApiService = (obj: any = {}) => {
  const queryString = encodeGetParams(obj);
  return Get(`${crmApiBaseUrl}${rolesAndPagesApi.rolesGet}${queryString}`, "crmToken");
};

/**
 * dashBoard followUp to call list page
 */
export const followupToCallMoveDispatch = (data = {}) => {
  store.dispatch({ type: "DASH_BOARD_TO_CUSTOMER_LIST", dashBoardToFollowUpCallList: data }); //loader
};

/**
 *Update basic information
 */
export const getDashBoardEnquireGraphCall = (userSearch: any = {}) => {
  const { endDate = null, startDate = null } = userSearch;
  const newObj = {
    type: "all",
    resultType: "details",
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${dashBoardApi.graph}?${consvertString}`, "crmToken");
};

/**
 * get dashBoard overView list
 */
export const getOverViewDetailsService = (obj = {}) => {
  return Get(`${crmApiBaseUrl}${dashBoardApi.overView}`, "crmToken");
};

/**
 * get dashBoard overView list
 */
export const getOverViewCrmUserDetails = (obj = {}) => {
  return Get(`${crmApiBaseUrl}${dashBoardApi.overallUser}`, "crmToken");
};
