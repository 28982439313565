import store from "store";
import { moment } from "utils/momentConst";
import { Get } from "ApiProcess/httpRestServices";
import { uploadFileSizeCheck } from "utils/projectHelper";
import { leadingAndTrailingSpaceAvoid } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { centralInventoryApi, pincodeApi } from "ApiProcess/httpRestServices/apiManage";
import { getCustomerTransactionsAction } from "store/action/BasicInfoAction";
import * as duplicateInvImeiType from "store/actionTypes/duplicateInvImeiType";
import { pincodeFetchActionPass, storeLocateFetchAction } from "store/action/storeLocateAction";
import { servicesCenterAction, servicesCenterTokenAction } from "store/action/serviceCenterAction";
import { ImeinumApiCallAction, ImeinumBasedTransactionHistory } from "store/action/salessReturnAction";
import { DuplicateImeinumApiCallAction, DuplicateStatusUpdateListAction } from "store/action/duplicateInvAction";
import {
  sendGeneralInfoMailAction,
  sendServiceCenterSMSAction,
  setCustomerServiceDetails
} from "store/action/customerDetailServiceAction";

const imageUploadArrayGet = (data: any = {}) => {
  const { imageInfo = [] } = data;
  return imageInfo;
};

export const fetchPincodeValue = (pincodeNumber: any) => {
  store.dispatch(pincodeFetchActionPass(pincodeNumber));
  store.dispatch({ type: "PINCODE_LOADER", loaderPincode: true });
};

//store location get
export const fetchStoreValue = () => {
  store.dispatch({ type: "PINCODE_LOADER", loaderPincode: true });
  store.dispatch(storeLocateFetchAction());
};

/**
 * Set popup to true/ to show popup
 */
export const setShowDetailsServicePopupCall = () => {
  store.dispatch({ type: "SET_TRANSACTION_POPUP", transactionPopup: true });
};

export const emptyStoreLocateValue = () => {
  store.dispatch({ type: "STORE_LOCATE_DETAILS", storeLocateDetails: [] });
};

export const emptyAllStoreLocateValue = () => {
  store.dispatch({ type: "STORE_INITIAL_STAGE" });
};

/**
 * @param  {number} imeiNumber
 * loader enable
 * dispatch the imei number action
 */
export const imeiNumApiCall = (imeiNumber = "") => {
  store.dispatch({ type: "GET_DETAILS_BASED_IMEI_BASED_LOADER", imeiNumLoader: true });
  store.dispatch(ImeinumApiCallAction(imeiNumber)); //dispatch the imei number action
};

/**
 * @param  {number} imeiNumber
 * loader enable
 * dispatch the imei number action
 */
export const imeinumBasedTransactionHistoryCall = (imeiNumber = "") => {
  const paramsObj = { imeiNum: imeiNumber, enquiryFor: "Service" };
  store.dispatch(ImeinumBasedTransactionHistory(paramsObj)); //dispatch the imei based get transActionList
  store.dispatch({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: true });
};

/**
 * Remove imei data's from store
 */
export const resetIMEIAutoFill = () => {
  store.dispatch({ type: "GET_DETAILS_BASED_IMEI_BASED_DATA", imeiNumbasedUserData: [] }); //remove imei data
  store.dispatch({ type: "SET_SAVE_SERVICE_SUCCESS_CODE", successCode: false }); //sucess code update
};

/**
 * @param  {object} customer details
 * dispatch customer service information
 */
export const saveCustomerServiceData = (data: object = {}) => {
  const fileUploadSize = uploadFileSizeCheck(imageUploadArrayGet(data));
  if (fileUploadSize) {
    store.dispatch({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: true });
    store.dispatch(setCustomerServiceDetails(data)); //dispatch customer service information
    store.dispatch({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_DETAILS", invoiceBasedDetails: [] });
  } else {
    failToast("Please upload file below 30MB");
  }
};

/**
 * loader enable
 * get services center list
 */
export const getServiceCenterList = () => {
  store.dispatch({ type: "SERVICE_CENTER_GET_LOADER", servicesCenterLoader: true }); //remove sales return autofill
  store.dispatch(servicesCenterAction()); //get services center list
};

/**
 * Send sms
 */
export const sendServiceCenterSMSCall = (data: any = {}) => {
  store.dispatch(sendServiceCenterSMSAction(data));
  store.dispatch({ type: "SMS_SERVICE_LOADER", smsServiceLoader: true });
};

/**
 * loader enable
 * get services center list
 */
export const getServiceCenterToken = () => {
  store.dispatch({ type: "SERVICE_CENTER_TOKEN_GET_LOADER", servicesCenterTokenLoader: true }); //remove sales return autofill
  store.dispatch(servicesCenterTokenAction()); //get services center list
};

/**
 *  @param  { number } imeiNumber
 * loader enable
 * dispatch the imei number action
 */
export const duplicateInvImeiNumApiCall = (dupimeiNumber: number) => {
  store.dispatch(DuplicateImeinumApiCallAction(dupimeiNumber)); //dispatch the imei number action
  store.dispatch({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_DATA, dupimeiNumber: true });
};

/**
 * Remove imei data's from store
 */
export const duplicateInvImeiData = () => {
  store.dispatch({ type: duplicateInvImeiType.DUP_GET_DETAILS_BASED_IMEI_BASED_DATA, imeiNumbasedUserData: [] }); //remove imei data
};

/**
 *Get Transaction details by customer
 */
export const getCustomerTransactions = (data = {}) => {
  store.dispatch(getCustomerTransactionsAction(data));
  store.dispatch({ type: "BASIC_IFO_USER_FIND_LOADER", basicInfoUserFind: true }); //loader
};

/**
 * Set popup to false/ to close popup
 */
export const resetTransactionPopupCall = () => {
  store.dispatch({ type: "SET_TRANSACTION_POPUP", transactionPopup: false });
};

/**
 * duplicateInvStatusUpdateList from store
 */
export const duplicateInvStatusUpdateList = (obj: any) => {
  store.dispatch(DuplicateStatusUpdateListAction(obj)); //
  store.dispatch({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_BASED_DATA, statusUpdateListLoader: true });
};

/**
 * Remove duplicateInvStatusUpdateList from store
 */
export const duplicateInvStatusUpdateRemove = () => {
  store.dispatch({ type: duplicateInvImeiType.DUP_GET_STATUS_LIST_BASED_DATA, dupImeiServiceUpdatedData: [] }); //remove dupImeiServiceUpdatedData data
};

/**
 * Send attachemts on mail
 */
export const sendEmailAttachmentsCall = (data: any = {}) => {
  store.dispatch({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: false });
  store.dispatch({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: true }); //send email loader
  store.dispatch(sendGeneralInfoMailAction(data));
};

/**
 * Calculate the age of device
 * @param start current date
 * @returns difference of current date and start date
 */
export const ageOfDeviceCalculation = (start: any = "") => {
  const current = moment().startOf("day");
  try {
    return moment.duration(current.diff(start.toString())).asDays();
  } catch {
    return "";
  }
};

/**
 * mobile number based history
 * @param mobileNumber string
 */
export const handleMobileNumBasedHistorySearch = (mobileNumber: any = "") => {
  getCustomerTransactions({ mobile: leadingAndTrailingSpaceAvoid(mobileNumber) }); //api call
  store.dispatch({ type: "GET_USER_DETAILS_BASED_INVOICE_DATE_DETAILS", invoiceBasedDetails: [] });
};

/**
 * reset transaction history invoice details
 */
export const resetTransHistoryInvoceDetails = (isSalesSubmit: any = false) => {
  if (isSalesSubmit) {
    store.dispatch({ type: "CLEAR_TRANSACTION_HISTORY_DATA", payload: { groupInvoiceBasedDetailsResponse: {} } });
  } else {
    store.dispatch({
      type: "CLEAR_TRANSACTION_HISTORY_DATA",
      payload: { invoiceBasedDetails: {}, groupInvoiceBasedDetails: {} }
    });
  }
};

export const fetchStoreDetailsBasedPince = (pinCode: any = "") => {
  return Get(`${centralInventoryApi}${pincodeApi.storeLocationApi}/${pinCode}`, "centralInventory");
};
