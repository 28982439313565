import * as basicInfoType from "../actionTypes/basicInfoType";

/**
 * update basic information
 */
export const updateBasicInfoCus = (data: any = {}) => {
  return {
    type: basicInfoType.UPDATE_BASIC_INFORMATION_CUSTOMER,
    data
  };
};

/**
 * update basic information
 */
export const updateBasicInfoFromPopupAction = (data: any = {}) => {
  return {
    type: basicInfoType.UPDATE_BASIC_INFORMATION_FROM_POPUP,
    data
  };
};

/**
 * update enquiry basic information
 */
export const updateEnquiryBasicCustomerInfoAction = (data: any = {}) => {
  return {
    type: basicInfoType.UPDATE_ENQUIRY_BASIC_CUSTOMER_INFO,
    data
  };
};

/**
 * update basic information
 */
export const getUserBasicInfoUsingMobileNumAction = (data: any = {}) => {
  return {
    type: basicInfoType.GET_BASIC_INFORMATION_CUSTOMER,
    data
  };
};

/**
 * update basic information
 */
export const getUserBasicInfoUsingEmailAction = (data: any = {}) => {
  return {
    type: basicInfoType.GET_BASIC_INFORMATION_USING_EMAIL,
    data
  };
};

/**
 * update basic information
 */
export const getUserBasicInfoUsingCusIdAction = (data: any = {}) => {
  return {
    type: basicInfoType.GET_BASIC_INFORMATION_USING_CUSTOMER_ID,
    data
  };
};

/**
 * update basic information
 */
export const getUserBasicInfoFromEnquiryPopupAction = (data: any = {}) => {
  return {
    type: basicInfoType.GET_BASIC_INFORMATION_FROM_ENQUIRY_POPUP,
    data
  };
};

/**
 * get enquiry list by customer
 */
export const getCustomerEnquiresAction = (data: object) => {
  return {
    type: basicInfoType.GET_ENQUIRY_INFORMATION_BY_CUSTOMER,
    data
  };
};

/**
 * get transaction list by customer
 */
export const getCustomerTransactionsAction = (data: object) => {
  return {
    type: basicInfoType.GET_TRANS_INFORMATION_BY_CUSTOMER,
    data
  };
};

/**
 * get locate details
 */
export const pincodeBasicInfoCusDetail = (data: any = {}) => {
  return {
    type: basicInfoType.GET_CUS_LOCATION_BASED_PINCODE,
    data
  };
};

/**
 * merge request customer
 */
export const mergeRequestCustomerAction = (data: any = {}) => {
  return {
    type: basicInfoType.MERGE_REQUEST_NEW_CUSTOMER,
    data
  };
};
