import NoDataFoundPage from "Component/NoDataFoundPage";
import { TableClass, tdClassStyle2, tdHighLight, thClassStyle2 } from "utils/ClassContent";
import { convertToLowerCase, dateTimeDisplay, nullToObject } from "utils/validation";
import { EnquiryDetailsTableProps } from "./types";

// sakes and sales retun
const EnquiryDetailsTable = (props: EnquiryDetailsTableProps) => {
  const {
    cusTransHistory = [],
    getModalStatus = false,
    modalFullscreen = false,
    openUserQnquireDetails = () => {}
  } = nullToObject(props);

  return (
    <div
      className="flex flex-col"
      style={{
        minHeight: "250px",
        height: getModalStatus || modalFullscreen ? "100%" : "250px",
        maxHeight: "calc(100vh - 48px)"
      }}
    >
      <div className="block flex-grow w-full overflow-x-auto relative">
        <table className={TableClass}>
          <thead>
            <tr className="">
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Enquiry</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Subcategory</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Status</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Sub Status</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Created By</span>
              </th>
              <th style={{ zIndex: 1 }} className={thClassStyle2 + " sticky -top-px "}>
                <span> Created Date</span>
              </th>
            </tr>
          </thead>

          <tbody>
            {cusTransHistory.length ? (
              cusTransHistory.map((enquiry: any, i: number) => {
                const {
                  enquiryFor = "",
                  subCategory = "",
                  status = "",
                  currentStatus = "",
                  createdBy: { date = "", name = "", branch = "" } = {}
                } = enquiry;
                return (
                  <tr key={convertToLowerCase(i + "cusTransHistory-dfd")}>
                    <td className={tdClassStyle2} onClick={() => openUserQnquireDetails(enquiry)}>
                      <button type={"button"} className={`${tdHighLight}`}>
                        <span>{enquiryFor}</span>
                      </button>
                    </td>

                    <td className={tdClassStyle2}>{subCategory}</td>

                    <td className={tdClassStyle2}>{status}</td>

                    <td className={tdClassStyle2}>{currentStatus}</td>

                    <td className={tdClassStyle2}>
                      {name}-{branch}
                    </td>

                    <td className={tdClassStyle2}>{dateTimeDisplay(date)}</td>
                  </tr>
                );
              })
            ) : (
              <NoDataFoundPage colSpan={6} />
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EnquiryDetailsTable;
