import { Transition } from "@headlessui/react";
import { _get } from "utils/lodashConst";
import { IconSearch } from "assets/images";
import LazyImage from "Component/LazyImage";
import { XIcon } from "@heroicons/react/outline";
import React, { Fragment, useState } from "react";
import { GlobalPRoductSearchProps } from "./types";
import OutsideClickHandler from "react-outside-click-handler";
import { convertToLowerCase, nullToArray, nullToObject } from "utils/validation";

const GlobalPRoductSearch = (props: GlobalPRoductSearchProps) => {
  const {
    name = "",
    value = "",
    inputId = "",
    children = "",
    setShow = true,
    inputClass = "",
    parentClass = "",
    readOnly = false,
    dropDownData = [],
    dropOnTop = false,
    inputError = false,
    inputvalid = false,
    dropIconClass = "",
    disabledDrop = false,
    inputFieldClass = "",
    inputWithIcon = false,
    clearInput = () => {}, //click
    handleOnChange = () => {}, //click
    iconClickAction = () => {},
    handleChooseProduct = () => {}, //click
    placeholder = "Search for Products, Brands, Offers..."
  } = nullToObject(props);

  const listStyle = "px-3 py-2";
  const [dropShow, setDropShow] = useState(false);

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearInput && clearInput(event);
    handleOnChange && handleOnChange(event);
    setDropShow(true);
  };

  const handleOutSideClick = () => {
    setDropShow(false);
  };

  const handleInputValueReset = () => {
    setDropShow(true);
    clearInput && clearInput({ target: { name: name, value: "" } });
  };

  const result = _get(dropDownData, "[0].hits", []);
  const result1 = _get(dropDownData, "[1].hits", []);
  const resultData = [...result1, ...result];

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      <div
        className={`
                ${inputClass ? inputClass : ""}
                ${inputWithIcon ? inputWithIcon : ""} relative`}
      >
        <input
          name={name}
          type="text"
          id={inputId}
          autoCorrect="off"
          autoComplete="off"
          spellCheck={false}
          readOnly={readOnly}
          disabled={disabledDrop}
          value={value ? value : ""}
          placeholder={placeholder ? placeholder : ""}
          data-jest-id={"jestHandleInputValueGlobalProduct"}
          onClick={readOnly ? () => {} : () => setDropShow(true)}
          onChange={readOnly === true ? () => {} : handleInputValue}
          className={`overflow-hidden overflow-ellipsis block w-60 rounded input-common pl-3 pr-7 py-2 text-sm ${
            inputError && !inputvalid ? " error pr-10" : ""
          }${inputvalid && !inputError ? " input-valid" : ""}${inputFieldClass ? inputFieldClass : ""}${
            inputWithIcon ? " pr-20" : ""
          }`}
        />
        {/* icon */}
        {value !== "" ? (
          <button
            type="button"
            onClick={handleInputValueReset}
            data-jest-id={"jestHandleInputValueResetGlobalProduct"}
            className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute top-1/2 input-icon-color-fill cursor-pointer right-3 transform -translate-y-1/2
                        ${iconClickAction ? "cursor-pointer" : ""}`}
          >
            <XIcon className="w-5 h-5" />
          </button>
        ) : (
          <i
            className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute input-icon-color-fill top-1/2 right-3 transform -translate-y-1/2 ${
                          iconClickAction ? "cursor-pointer" : ""
                        }`}
          >
            <IconSearch />
          </i>
        )}

        {/* dropDown data */}
        {dropShow && (
          <OutsideClickHandler onOutsideClick={handleOutSideClick}>
            <div
              className={`dropdown  overflow-x-hidden overflow-y-auto bg-white z-10  max-h-60 absolute  left-0 right-0 
                            ${(inputvalid && !inputError) || dropShow ? " input-valid" : ""}${
                              dropOnTop ? " bottom-full dropOnTop -mb-1 rounded-t" : " top-full -mt-1 rounded-b"
                            }`}
            >
              <ul className="h-full">
                {nullToArray(resultData)?.length > 0 && (
                  <>
                    {resultData.map((item: any, i: any) => {
                      return (
                        <li key={convertToLowerCase(i + "resultData-dgd")}>
                          <button
                            onClick={() => {
                              handleChooseProduct(item);
                              handleOutSideClick();
                            }}
                            data-jest-id={"jestHandleChooseProduct"}
                            type="button"
                            className={`${listStyle} w-full text-left flex items-center`}
                          >
                            {" "}
                            <div className="w-12 h-12 mr-2">
                              <LazyImage
                                width={48}
                                height={48}
                                alt={_get(item, "document.name", "")}
                                src={_get(item, "document.image", "")}
                              />
                            </div>
                            <div className="" style={{ width: "calc(100% - 40px)" }}>
                              <p className="text-black text-sm  w-full line-clamp-2">
                                {_get(item, "document.keyword", "")}
                                {_get(item, "document.name", "")}
                              </p>
                              <p className="text-sm">
                                in
                                <strong
                                  className="text-blue-500 text-xs cursor-pointer hover:underline
                                                                                    overflow-hidden overflow-ellipsis whitespace-nowrap"
                                >
                                  {" " + _get(item, "document.parant_category", "")}
                                </strong>
                              </p>
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </>
                )}
              </ul>
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {children}
    </Transition>
  );
};

export default GlobalPRoductSearch;
