export const ENTER_KEY_CODE = 13;
export const DOWN_ARROW_KEY_CODE = 40;
export const UP_ARROW_KEY_CODE = 38;
export const ESCAPE_KEY_CODE = 27;

export const DropDownKeyboardControl = (ListArray = []) => {
  const listItems = ListArray;
  const listItemIds = [];
  const focusNextListItem = (direction) => {
    const activeElementId = document.activeElement.dataset.id;
    const currentActiveElementIndex = listItemIds.indexOf(activeElementId);
    if (direction === DOWN_ARROW_KEY_CODE) {
      const currentActiveElementIsNotLastItem = currentActiveElementIndex < listItemIds.length - 1;
      if (currentActiveElementIsNotLastItem) {
        const nextListItemId = listItemIds[currentActiveElementIndex + 1];
        document.querySelector(`[data-id="${nextListItemId}"]`).focus();
      }
    } else if (direction === UP_ARROW_KEY_CODE) {
      const currentActiveElementIsNotFirstItem = currentActiveElementIndex > 0;
      if (currentActiveElementIsNotFirstItem) {
        const nextListItemId = listItemIds[currentActiveElementIndex - 1];
        document.querySelector(`[data-id="${nextListItemId}"]`).focus();
      }
    }
  };
  listItems.forEach((item) => listItemIds.push(item.dataset.id));
  listItems.forEach((item) => {
    item.addEventListener("keydown", (e) => {
      switch (e.keyCode) {
        case ENTER_KEY_CODE:
          item.click();
          return;

        case DOWN_ARROW_KEY_CODE:
          focusNextListItem(DOWN_ARROW_KEY_CODE);
          return;

        case UP_ARROW_KEY_CODE:
          focusNextListItem(UP_ARROW_KEY_CODE);
          return;

        case ESCAPE_KEY_CODE:
          return;

        default:
          return;
      }
    });
  });
  const newObj = { undefined };
  return () => newObj;
};
