import React, { useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import EditableLable from "../EditableLable";
import { Transition } from "@headlessui/react";
import CommonCheck from "Component/Form/CommonCheck";
import OutsideClickHandler from "react-outside-click-handler";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { convertToLowerCase, nullToArray, nullToObject } from "utils/validation";
import CommonDropDownInput from "../CommonTableSortDropdown/CommonDropDownInput";

const MultiDropDownNew = (props: any = {}) => {
  const {
    name = "",
    labeName = "",
    arrayData = [],
    isReset = false,
    parentClass = "",
    mustField = false,
    noSelected = false,
    isSelectAll = true,
    inputFieldClass = "",
    customWidthClass = "",
    defaultSelected = [],
    enableCheckbox = true,
    editableLabel = false,
    isOKButtonEnable = true,
    value: defaultValue = "",
    bindKeyName = "itemName",
    filterKeyName = "isCheck",
    DropDownParentClass = "",
    filterArrayPass = () => {}
  } = nullToObject(props);

  const [searchOptionValue, setSearchOptionValue] = useState<any>("");
  const [isEnableSelectAll, setIsEnableSelectAll] = useState<any>(false);
  const [enableDropDown, setEnableDropDown] = useState<any>(false);
  const [stateManage, setStateManage] = useState<any>({
    name: "",
    filterArray: [],
    originalArray: [],
    defaultData: "No Selected"
  });
  const { originalArray = [], filterArray = [], defaultData = "" } = stateManage;
  const activeColorHandle = defaultData === "" || defaultData === "All" || defaultData === "No Selected" ? false : true;

  const onOutsideClick = () => {
    setEnableDropDown(false);
  };

  /**
   * dropdown open and close handle
   * @param event
   */
  const handleMoreDrop = (event: any = {}) => {
    event.preventDefault && event.preventDefault();
    setEnableDropDown(!enableDropDown);
  };

  /**
   * clear search dropDown
   */
  const clearSearch = (event: any = {}) => {
    const trueHandle = [...originalArray].map((ele: any) => {
      ele[filterKeyName] = false;
      return ele;
    });
    filterArrayPass({
      name: name,
      filterArray: [],
      originalArray: trueHandle
    });
    const newObj = {
      name: "",
      filterArray: [],
      defaultData: _get(event.value, "length", 0) > 0 ? event.value : "All",
      originalArray: trueHandle
    };
    setStateManage(newObj);
    setIsEnableSelectAll(false);
  };

  /**
   * dropdown auto complete search
   * @param event
   */
  const handleSearchfilter = (event: any = {}) => {
    const { target: { value = "" } = {} } = nullToObject(event);
    setSearchOptionValue(value);
    const div: any = document && document.getElementById(`drop-${name}`);
    const a: any = div?.querySelectorAll(".option");
    for (let i = 0; i < a?.length; i++) {
      const txtValue = a[i]?.textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(value?.toUpperCase()) > -1) {
        a[i].parentNode.style.display = "";
      } else {
        a[i].parentNode.style.display = "none";
      }
    }
  };

  const handleClearOptionSearch = () => {
    handleSearchfilter({});
  };

  const selectAllHandle = (toggle: boolean) => {
    if (toggle) {
      // make every object value true
      const trueHandle = [...originalArray].map((ele: any) => {
        ele[filterKeyName] = true;
        return ele;
      });

      // make filter all value
      const filterName = [...originalArray]
        .map((ele: any) => {
          return _get(ele, [bindKeyName], undefined);
        })
        .filter((ele: any) => ele !== undefined);

      const newObj: any = {
        ...stateManage,
        originalArray: trueHandle,
        filterArray: filterName
      };

      setStateManage(newObj);
    } else {
      // make every object value false
      const trueHandle = [...originalArray].map((ele: any) => {
        ele[filterKeyName] = false;
        return ele;
      });

      // make filter all value
      const newObj = {
        name: name,
        filterArray: [],
        originalArray: trueHandle
      };
      setStateManage(newObj);
    }

    setIsEnableSelectAll(toggle);
  };

  const commonMultiSearch = (array: any = [], names = "", _id = "") => {
    let newArray = array;
    const findIndex = newArray.findIndex((ele: any) => _get(ele, "_id", "") === _id);

    if (convertToLowerCase(names) !== convertToLowerCase("clear")) {
      if (enableCheckbox) {
        newArray[findIndex] = {
          ...newArray[findIndex],
          [filterKeyName]: newArray[findIndex][filterKeyName] ? false : true
        };
      } else {
        //checkBox is not enable select one
        newArray = newArray.map((ele: any) => {
          if (_get(ele, "_id", "") === _id) {
            ele[filterKeyName] = true;
          } else {
            ele[filterKeyName] = false;
          }
          return ele;
        });
      }
    } else {
      //clear all selected
      newArray = newArray.map((ele: any) => {
        ele[filterKeyName] = false;
        return ele;
      });
    }

    return newArray;
  };

  const selectOption = (nameData = "", obj: any = {}) => {
    const { _id = "" } = obj;
    const newArray: any = [...originalArray];
    const newArrays = commonMultiSearch(newArray, nameData, _id); //toggle doing

    const filterName = [...newArrays]
      .map((ele: any) => {
        if (ele[filterKeyName]) {
          return ele[bindKeyName];
        }
        return undefined;
      })
      .filter((ele: any) => ele !== undefined);

    const newObj: any = {
      ...stateManage,
      originalArray: newArrays,
      filterArray: filterName
    };
    setStateManage(newObj);
    const checkAllValueTrue = newArrays.every((ele: any) => ele[filterKeyName] === true);
    setIsEnableSelectAll(checkAllValueTrue); //select all checkBox handle
    if (!enableCheckbox) {
      //if select one option colose dropDOwn
      onOutsideClick();
    }
    if (!isOKButtonEnable) {
      filterArrayPass(newObj);
    }
  };

  const getFilterName = (originalArrays = []) => {
    const allLengthCheck =
      _get(originalArrays, "length", 0) > 0
        ? originalArrays
            .map((ele: any) => {
              if (ele[filterKeyName] === true) {
                return ele[bindKeyName];
              }
              return undefined;
            })
            .filter((ele: any) => ele !== undefined)
        : "All";

    return _get(allLengthCheck, "length", 0) > 0 ? allLengthCheck : noSelected ? "No Selected" : "All";
  };

  const onBlurHandle = () => {
    onOutsideClick(); // close dropDown

    const newObj = {
      ...stateManage,
      defaultData: getFilterName(originalArray)
    };
    setStateManage(newObj);

    filterArrayPass({
      name: name,
      filterArray: filterArray,
      originalArray: originalArray
    });
  };

  useEffect(() => {
    const newObj = {
      ...stateManage,
      originalArray: arrayData,
      defaultData: _get(defaultSelected, "length", 0) > 0 ? defaultSelected : getFilterName(arrayData)
    };
    setStateManage(newObj);
  }, [arrayData]);

  useEffect(() => {
    if (isReset) {
      setSearchOptionValue("");
      clearSearch({ value: defaultSelected });
    }
  }, [isReset]);

  return (
    <div className={` ${DropDownParentClass ? DropDownParentClass : " "} flex flex-col LabelParent`}>
      {labeName ? (
        <label htmlFor="" className="text-xs mb-0.5 LabelName font-bold">
          {labeName} {mustField && <sup style={{ color: "red" }}>*</sup>}
        </label>
      ) : null}
      {editableLabel ? <EditableLable /> : null}
      <OutsideClickHandler onOutsideClick={onOutsideClick}>
        <div className={` ${parentClass ? parentClass : " inline-block "} tableOption relative`}>
          <div
            title={defaultData}
            className={`tabledrop ${customWidthClass ? customWidthClass : " md:w-40 "} relative inline-block`}
          >
            <input
              type="text"
              id={name}
              readOnly={true}
              onClick={handleMoreDrop}
              value={enableCheckbox ? defaultData : defaultValue}
              className={` ${inputFieldClass ? inputFieldClass : " h-8 "}  ${
                activeColorHandle ? " border-green-500 " : ""
              } border-gray-300 border
                                sort block rounded input-common pl-3 text-xs pr-7 py-1 overflow-hidden overflow-ellipsis cursor-pointer`}
            />

            {!activeColorHandle ? (
              <button
                type="button"
                onClick={handleMoreDrop}
                data-jest-id={"jestHandleMoreDrop"}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 flex justify-center items-center"
              >
                <label htmlFor={name}>
                  <ChevronDownIcon
                    className={`${enableDropDown ? " transform rotate-180" : ""} w-4 h-4 text-gray-500 cursor-pointer`}
                  />
                </label>
              </button>
            ) : (
              <button
                type="button"
                onClick={clearSearch}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 flex justify-center items-center"
              >
                <XIcon className="w-4 h-4 text-gray-500 hover:text-red-500 cursor-pointer" />
              </button>
            )}
          </div>

          <Transition
            as={React.Fragment}
            show={enableDropDown}
            leave="transition ease-in duration-75"
            leaveTo="transform opacity-0 scale-95"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leaveFrom="transform opacity-100 scale-100"
          >
            <div
              className={`dropdown bg-white z-10 absolute  left-0 right-0 
                            input-valid top-full -mt-1 rounded-b`}
            >
              {nullToArray(originalArray)?.length > 5 && (
                <CommonDropDownInput
                  autoFocus={true}
                  name={name}
                  value={searchOptionValue}
                  handleSearchfilter={handleSearchfilter}
                  handleClearOptionSearch={handleClearOptionSearch}
                />
              )}
              <ul id={`drop-${name}`} className="h-full overflow-x-hidden overflow-y-auto max-h-60 relative">
                <li className="bg-yellow-50">
                  {isSelectAll && enableCheckbox && (
                    <label
                      data-jest-id={"jestSelectAllHandle"}
                      className=" flex items-center px-4 py-2 w-full text-left cursor-pointer text-red-500"
                      onClick={(e) => selectAllHandle(isEnableSelectAll ? false : true)}
                    >
                      <CommonCheck
                        label={false}
                        parentClass={"mr-2 red"}
                        checked={isEnableSelectAll}
                        id={convertToLowerCase(name + "-tets")}
                      />
                      <span className="option">{"Select all"}</span>
                    </label>
                  )}
                </li>
                {nullToArray(originalArray).map((item: any, i: number) => {
                  return (
                    <React.Fragment key={convertToLowerCase(i + "originalArray-dgcd")}>
                      <li data-jest-id={"jestMakeEventData"} onClick={(e) => selectOption(name, item)}>
                        <button
                          type="button"
                          title={item[bindKeyName]}
                          className=" flex items-center px-4 py-2 w-full text-left cursor-pointer"
                        >
                          {enableCheckbox && (
                            <CommonCheck
                              label={false}
                              parentClass={"mr-2"}
                              checked={item[filterKeyName]}
                              id={convertToLowerCase(name + "-" + i)}
                            />
                          )}
                          <span className="option">{item[bindKeyName]}</span>
                        </button>
                      </li>
                    </React.Fragment>
                  );
                })}
                {isOKButtonEnable ? (
                  <button
                    type="button"
                    onClick={onBlurHandle}
                    className={`h-7 text-red-100 transition-colors duration-150 bg-red-700 
                                            rounded-none focus:shadow-outline font-bold hover:bg-red-800 sticky bottom-0 w-full`}
                  >
                    Ok
                  </button>
                ) : null}
              </ul>
            </div>
          </Transition>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default MultiDropDownNew;
