import * as storeLocateType from "../actionTypes/storeLocateType";

/**
 * @param  {Number} data;
 *
 */
export const pincodeFetchActionPass = (data: any = {}) => {
  return {
    type: storeLocateType.FETCH_PINCODE_STORE,
    data
  };
};

/**
 * storeLocate details get
 *
 */
export const storeLocateFetchAction = () => {
  return {
    type: storeLocateType.FETCH_STORE_LOCATE_DETAILS
  };
};
