import { _get } from "utils/lodashConst";

type salesReducerTypes = {
  stockDetails?: any;
  moveToTabOne?: boolean;
  nonDealingListData?: any;
  deliveryType?: Array<any>;
  isSpecificProduct?: Array<any>;
  nonDealModelLoader?: boolean;
  salesReportMailLoader?: boolean;
  salesReportMailSendSuccess?: boolean;
};

export const salesInitState = {
  stockDetails: {},
  deliveryType: [],
  moveToTabOne: false,
  nonDealingListData: {},
  isSpecificProduct: [],
  nonDealModelLoader: false,
  salesReportMailLoader: false,
  salesReportMailSendSuccess: false
};

export const salesReducer = (state = salesInitState, action: salesReducerTypes = {}) => {
  switch (_get(action, "type")) {
    //set to initial state
    case "SET_INIT_STATE": {
      return {
        ...state,
        ...salesInitState
      };
    }

    case "MOVE_TO_ENQUIRY_T0_TAB_ONE": {
      return {
        ...state,
        moveToTabOne: action.moveToTabOne
      };
    }

    case "CHECK_PRODUCT_ARRAY_HAVE_SPECIFIC_DATA": {
      return {
        ...state,
        isSpecificProduct: action.isSpecificProduct
      };
    }

    //stock data
    case "PRODUCT_STOCK_DETAILS_DATA": {
      return {
        ...state,
        stockDetails: action.stockDetails
      };
    }

    //sales mail loader
    case "SET_SALES_REPORT_MAIL_LOADER": {
      return {
        ...state,
        salesReportMailLoader: action.salesReportMailLoader
      };
    }

    //sales mail loader
    case "SET_SALES_REPORT_MAIL_SUCCESS": {
      return {
        ...state,
        salesReportMailSendSuccess: action.salesReportMailSendSuccess
      };
    }

    //list
    case "NON_DEALING_LIST_DATA": {
      return {
        ...state,
        nonDealingListData: action.nonDealingListData
      };
    }

    //loader
    case "NON_DEALINING_MODAL_LOADER": {
      return {
        ...state,
        nonDealModelLoader: action.nonDealModelLoader
      };
    }

    //pincode based delivery type
    case "PIN_CODE_ITEM_CODE_BASED_DELIVERY_DATA": {
      return {
        ...state,
        deliveryType: action.deliveryType
      };
    }

    default:
      return {
        ...state
      };
  }
};
