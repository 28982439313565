import React from "react";
import { _get } from "utils/lodashConst";
import { bg_JobSheet } from "utils/ClassContent";
import CommonDropHeading from "Component/CommonDropHeading";

/**
 * Service Center Drop Heading
 * @param props props
 * @returns Shows service center info on enquiry popup
 */
const JobSheetDropHeading = (props: any = {}) => {
  const { createInputFields = () => {}, customerData = {}, popParentTabs = "" } = props || {};

  return (
    <CommonDropHeading
      id="JobSheet"
      heading="Job Sheet"
      customClass={bg_JobSheet}
      tabActiveDefault={popParentTabs === "JobSheet" ? true : false}
    >
      {createInputFields("jobsheetNo", "jobsheetNo", customerData["jobSheet"])}
      {createInputFields("jobsheet Date", "jobsheetDate", customerData["jobSheet"])}
      {createInputFields("jobSheet Status", "jobSheetStatus", customerData["jobSheet"])}
      {_get(customerData["jobSheet"], "pendingStatus", "") &&
        createInputFields("Pending Status", "pendingStatus", customerData["jobSheet"])}
      {_get(customerData["jobSheet"], "ProceedWith", "") &&
        createInputFields("Proceed With", "ProceedWith", customerData["jobSheet"])}
      {_get(customerData["jobSheet"], "model", "") && createInputFields("Model", "model", customerData["jobSheet"])}
      {_get(customerData["jobSheet"], "newImei", "") &&
        createInputFields("New Imei", "newImei", customerData["jobSheet"])}
    </CommonDropHeading>
  );
};

export default JobSheetDropHeading;
