import React, { useState, useEffect } from "react";
import { SalesImeiTransactionHistoryTableProps } from "./types";
import { convertToLowerCase, nullToObject, trimCharacters } from "utils/validation";
import { thClassStyle2, tdClassStyle2, TableClass } from "utils/ClassContent";
import { findWithCommaData, findWithOutCommaData } from "../commonSalesReturn";
import { resetTransHistoryInvoceDetails } from "../../ServiceEnquiry/commonServiceEnquiry";

const SalesEnqImeiTransactionHistoryTable = (props: SalesImeiTransactionHistoryTableProps) => {
  const {
    globalStore = {},
    name = "GetImei",
    enableRadio = false,
    getModalStatus = false,
    seletedImeiDataHandler = () => {}
  } = nullToObject(props);

  const [tableData, setTableData] = useState<any>([]);
  const { salesReturn: { groupInvoiceBasedDetails = {} } = {} } = nullToObject(globalStore);

  const passImeiDetailsElement = (index: any) => {
    const cloneTableData: any = [...tableData];
    cloneTableData[index].isChecked = !cloneTableData[index].isChecked;
    setTableData(cloneTableData);
  };

  const addOnClickHandler = () => {
    seletedImeiDataHandler(tableData);
    setTableData([]);
    resetTransHistoryInvoceDetails();
  };

  useEffect(() => {
    const userDetails: any = Object.values(groupInvoiceBasedDetails).flat();
    const singleImei = findWithOutCommaData(userDetails);
    const multipleImeiInsingleData = findWithCommaData(userDetails); //newObject constrt and id is added
    const imeiTransactionsNewArray: any = [...singleImei, ...multipleImeiInsingleData];
    setTableData(imeiTransactionsNewArray);
  }, [groupInvoiceBasedDetails]);

  const lableClassName = "block cursor-pointer";
  return (
    <div>
      <div
        className="block w-full overflow-x-auto relative max-h-screen "
        style={{ minHeight: "250px", height: getModalStatus ? "100%" : "250px" }}
      >
        <table className={TableClass}>
          <thead>
            <tr className="">
              {enableRadio && <th className={thClassStyle2} />}
              <th className={thClassStyle2}>Model</th>
              <th className={thClassStyle2}>Imei</th>
              <th className={thClassStyle2}>Brand</th>
              <th className={thClassStyle2}>Product</th>
              <th className={thClassStyle2}>Rate</th>
            </tr>
          </thead>
          <tbody>
            {tableData.length > 0 &&
              tableData.map((item: any, i: number) => {
                const { ITEM_NAME = "" } = item || {};
                const splits = ITEM_NAME.split(":");
                const { 0: ItemName = "", 1: Brand = "", 2: Model = "" } = splits || [];
                return (
                  <tr
                    key={convertToLowerCase(i + "imeiTransactionsNewArray-fsvx")}
                    className={enableRadio ? "cursor-pointer hover:bg-yellow-50" : ""}
                  >
                    {enableRadio && (
                      <td className={tdClassStyle2}>
                        <div className="cursor-pointer justify-center flex items-center w-6 h-6">
                          <input
                            type="checkbox"
                            name="ImeiDetails"
                            id={`${name}-${item.id}`}
                            checked={item.isChecked === true}
                            data-jest-id={"jestPassImeiDetailsElement"}
                            onChange={() => {
                              passImeiDetailsElement(i);
                            }}
                            className="focus:ring-yellow-500  ring-yellow-500 cursor-pointer h-4 w-4 text-yellow-600 border-gray-300"
                          />
                        </div>
                      </td>
                    )}

                    <td className={tdClassStyle2}>
                      <label className={lableClassName} htmlFor={enableRadio ? `${name}-${item.id}` : ""}>
                        <span title={ItemName}>{trimCharacters(ItemName, 26)}</span>
                      </label>
                    </td>
                    <td className={tdClassStyle2}>
                      <label className={lableClassName} htmlFor={enableRadio ? `${name}-${item.id}` : ""}>
                        {item.ITEM_SERIAL_NOS}
                      </label>
                    </td>
                    <td className={tdClassStyle2}>
                      <label className={lableClassName} htmlFor={enableRadio ? `${name}-${item.id}` : ""}>
                        {Brand}
                      </label>
                    </td>
                    <td className={tdClassStyle2}>
                      <label className={lableClassName} htmlFor={enableRadio ? `${name}-${item.id}` : ""}>
                        {Model}
                      </label>
                    </td>
                    <td className={tdClassStyle2}>
                      <label className={lableClassName} htmlFor={enableRadio ? `${name}-${item.id}` : ""}>
                        {Math.round(item.ITEM_GROS_RATE)}
                      </label>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <div className="flex flex-col sm:flex-row items-center justify-center w-full">
        <button
          type="button"
          onClick={() => addOnClickHandler()}
          className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
        >
          ADD
        </button>
      </div>
    </div>
  );
};

export default SalesEnqImeiTransactionHistoryTable;
