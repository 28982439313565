import { Delete, Get, Post } from "../../ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart, nullToObject } from "utils/validation";
import { crmApiBaseUrl, encodeGetParams, scoreCardList } from "../../ApiProcess/httpRestServices/apiManage";

/**
 * create service center api
 * @param obj
 */
export const scoreCardDeleteService = (obj: any = {}) => {
  const { data = "" } = nullToObject(obj);
  return Delete(`${crmApiBaseUrl}${scoreCardList.create}/${data}?`, "crmToken");
};

/**
 * score card lock service
 * @param obj
 */
export const scoreCardLockService = (obj: any = {}) => {
  const { data = {} } = nullToObject(obj);
  const { id = "", locked = 0 } = data;
  const queryString = encodeGetParams({ locked: locked });
  return Post(`${crmApiBaseUrl}${scoreCardList.locked}${id}?${queryString}`, {}, "crmToken");
};

/**
 * score card save response
 */
export const scoreCardRespCreateService = (obj: any = {}) => {
  const { data: { data = "", _id = "" } = {} } = nullToObject(obj);
  const reqObj = { ...data, isQuery: "t" };
  return _id
    ? Post(`${crmApiBaseUrl}${scoreCardList.scorecardresponse}/${_id}`, reqObj, "crmToken")
    : Post(`${crmApiBaseUrl}${scoreCardList.scorecardresponse}`, reqObj, "crmToken");
};

const commonData = (data = {}) => {
  const queryStr = {};
  Object.keys(data).forEach((itm: any = "") => {
    if (data[itm]) {
      queryStr[itm] = data[itm];
    }
  });
  return encodeGetParams(queryStr);
};

/**
 * @param  {object} obj;
 * get General
 */
export const getDashboardQuestionAnalysis = (data = {}) => {
  const queryString = commonData(data);
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardQuestionAnalysis}?${queryString}`, "crmToken");
};

/**
 * @param  {object} obj;
 * get General
 */
export const getDashboardQuestionAnalysisById = (id = "") => {
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardQuestionAnalysis}/${id}`, "crmToken");
};

/**
 * Team Lead's Dashboard
 */
export const getTeamDashboard = (payload: any) => {
  const { page = 1, size = 10, search = "", endDate = "", startDate = "" } = payload || {};
  const newObj = {
    page,
    size,
    search,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.teamDashboard}?${convertString}`, "crmToken");
};

/**
 * get result acceptance
 */
export const getDashboardResultAcceptance = (userSearch: any = {}) => {
  const { page = 1, size = 10, search = "", endDate = "", startDate = "", userId = [] } = userSearch || {};
  const newObj = {
    page,
    size,
    search,
    userId,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.dashboardResultAcceptance}?${convertString}`, "crmToken");
};

/**
 * Get Evaluator Audits
 */
export const getEvaluatorAudits = (evaluatorId: any, payload: any = {}) => {
  const { page = 1, size = 10, search = "", endDate = "", startDate = "", scoreCardId = [] } = payload || {};
  const newObj = {
    page,
    size,
    search,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    evaluatorId,
    scoreCardId
  };
  const convertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${scoreCardList.evaluatorAudits}?${convertString}`, "crmToken");
};
