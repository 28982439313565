import { _get } from "utils/lodashConst";

type salesReturnInitType = {
  serviceCenterList?: Array<any>;
};

export const serviceCentersInit = {
  serviceCenterList: [],
  serviceCenterListAll: [],
  serviceCenterLoader: false,
  serviceCenterRefresh: false,
  serviceCenterSuccess: false,
  serviceCenterMailLoader: false,
  serviceCenterCreateLoader: false,
  serviceCenterMailSendSuccess: false
};

export const serviceCenterReducer = (state = serviceCentersInit, action: salesReturnInitType = {}) => {
  switch (_get(action, "type")) {
    // Get service centers initial data
    case "GET_SERVICE_CENTERS_DATA_INITIAL": {
      return {
        ...state,
        ...serviceCentersInit
      };
    }
    // Get all the service centers data
    case "GET_SERVICE_CENTERS_DATA": {
      return {
        ...state,
        serviceCenterList: _get(action, "serviceCentersData", false)
      };
    }
    // Get all the service centers data
    case "SERVICE_CENTER_LIST_DATA": {
      return {
        ...state,
        serviceCenterListAll: _get(action, "serviceCenterListAll", [])
      };
    }

    case "GET_SERVICE_CENTER_LIST_LOADER": {
      return {
        ...state,
        serviceCenterLoader: _get(action, "serviceCenterLoader", false)
      };
    }
    case "SERVICE_CENTER_REFRESH": {
      return {
        ...state,
        serviceCenterRefresh: _get(action, "serviceCenterRefresh", false)
      };
    }

    //sales mail loader
    case "SET_SERVICE_CENTER_MAIL_LOADER": {
      return {
        ...state,
        serviceCenterMailLoader: _get(action, "serviceCenterMailLoader", false)
      };
    }

    //sales mail loader
    case "SET_SERVICE_CENTER_MAIL_SUCCESS": {
      return {
        ...state,
        serviceCenterMailSendSuccess: _get(action, "serviceCenterMailSendSuccess", false)
      };
    }

    case "SERVICE_CENTER_CREATE_SUCCESS": {
      return {
        ...state,
        serviceCenterSuccess: _get(action, "serviceCenterSuccess", false)
      };
    }

    case "SERVICE_CENTER_CREATE_LOADER": {
      return {
        ...state,
        serviceCenterCreateLoader: _get(action, "serviceCenterCreateLoader", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
