import React, { useEffect } from "react";
import "./assets/scss/main.css";
import keyCloakCorp from "keyCloakCorp";
import { encryptData } from "utils/secureFile";
import ScreenRouting from "Component/ScreenRouting";
import ToastContainer from "Component/ToastContainer";
import NetworkDetector from "Component/NetworkDetector";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Route, Routes } from "react-router-dom";
import LoginPanel from "Pages/PublicComponent/Login";
import IdleTimeOut from "Component/ScreenRouting/IdleTimeOut";
import Loader from "Component/Loader";

const App = () => {
  const sessionData: any = sessionStorage.getItem("ssologin");
  const ssologin = JSON.parse(sessionData || "{}");

  useEffect(() => {
    encryptData("onlineStatus", true);
  }, []);

  return (
    <React.Fragment>
      <IdleTimeOut />
      <Routes>
        <Route path="/" element={<LoginPanel />} />
      </Routes>
      {ssologin?.IsSSOAllow ? (
        <ReactKeycloakProvider
          authClient={keyCloakCorp(ssologin?.isStoreUser)}
          initOptions={{
            onLoad: "check-sso",
            silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html"
          }}
          LoadingComponent={<Loader />}
        >
          <ToastContainer />
          <ScreenRouting />
          <NetworkDetector />
        </ReactKeycloakProvider>
      ) : (
        <>
          <ToastContainer />
          <ScreenRouting />
          <NetworkDetector />
        </>
      )}
    </React.Fragment>
  );
};

export default App;
