export const envPath = process.env.REACT_APP_ENV; //env oc3 pincode
export const offerKey = process.env.REACT_APP_OFFER_KEY; //get offer key
export const imageBasePath = "https://img.poorvika.com/"; //poorvika image basePath
export const couponApi = process.env.REACT_APP_COUPON_API; //sales couponApi
export const mapApiKey = process.env.REACT_APP_MAP_API_KEY; //map key
export const urlShortUrl = process.env.REACT_APP_URL_SHORT; //url short call
export const orgBaseUrl = process.env.REACT_APP_ORG_API_URL; //org base url
export const OMS_Secret = process.env.REACT_APP_OMS_SECRET; // OMS_SECRET
export const apxBaseUrlPath = process.env.REACT_APP_APX_URL; //apx base url
export const eToeBaseUrl = process.env.REACT_APP_E2E_API_URL; //e2e baseUrl
export const secretKeyData = process.env.REACT_APP_SECRET_KEY; //secretKEY for decrypt and encrypt
export const productDetailsGet = process.env.REACT_APP_PIM_API; //get full product details
export const omsBasePath = process.env.REACT_APP_OMS_BASE_PATH; //oms basePath
export const crmApiBaseUrl = process.env.REACT_APP_CRM_BASE_URL; //crm project base url
export const OMS_AccessId = process.env.REACT_APP_OMS_ACCESS_ID; // OMS_AccessId
export const ecomBasePath = process.env.REACT_APP_ECOM_BASE_PATH; //ecom basePath
export const bitlyBaseUrl = process.env.REACT_APP_BITLY_BASE_URL; //bitly base url
export const transHistory = process.env.REACT_APP_INVOICE_HISTORY; //tranactionHistory
export const pinePgBaseUrl = process.env.REACT_APP_PINEPG_API_URL; //pinePg base url
export const bitlyAuthToken = process.env.REACT_APP_BITLY_URL_AUTH; //bitly auth token
export const urlShortToken = process.env.REACT_APP_URL_SHORT_TOKEN; //url short token
export const goLangApiPath = process.env.REACT_APP_GO_LANG_API_URL; //go Language basePath
export const pubsubBasePath = process.env.REACT_APP_PUBSUB_BASE_URL; // pubsub base path
export const typesenceBasePath = process.env.REACT_APP_TYPE_SENSE_01; //typeSense api
export const pimNewApi = process.env.REACT_APP_PIM_NEW_BASE_PATH; //pim new api
export const typesenceTokenKey = process.env.REACT_APP_TYPE_SENSE_KEY; //typeSense key
export const whatsAppBaseUrl = process.env.REACT_APP_WHATSAPP_BASE_URL; // whatsapp base url
export const infoBipBasePath = process.env.REACT_APP_API_INFO_BIP_PATH; //infoBip api base url
export const pincodeApiBasePath = process.env.REACT_APP_PINCODE_API_URL; //env oc3 pincode
export const crmApiProdBaseUrl = process.env.REACT_APP_PROD_CRM_BASE_URL; //crm prod base url
export const poorvikaBasePath = process.env.REACT_APP_API_POORVIKA_BASE_PATH; //poorvika site basePath
export const matomoTrackingId: any = process.env.REACT_APP_MATOMO_TRACKING_ID; // api service center prod base path
export const crmSalesIncentiveUrl = process.env.REACT_APP_SALES_INCENTIVE_URL; //crm project base url
export const crmAnalyticsBaseUrl: any = process.env.REACT_APP_ANALYTICS_BASE_URL; // api service center prod base path
export const typesenceOutDatedBasePath = process.env.REACT_APP_TYPE_SENSE_OUT_DATED; //typeSense api
export const centralInventoryApi = process.env.REACT_APP_CENTRAL_INVENTORY_API_PATH; //centralInventoryApi
export const offerProjectBasePath = process.env.REACT_APP_URL_OFFER_PROJECT_BASE_URL; //OFFER project base path
export const omsGetDetailsBasedPhoneNumber = process.env.REACT_APP_NEW_OMS_BASE_PATH; //token
export const serviceCenterProdBasePath = process.env.REACT_APP_SERVICE_CENTER_PROD_PATH; // api service center prod base path
export const centralInventoryToken = process.env.REACT_APP_CENTRAL_INVENTORY_API_TOKEN; //centralInventoryToken
export const typesenceOutDatedDeleteToken = process.env.REACT_APP_TYPE_SENSE_OUT_DATED_TOKEN_ADMIN; //token
export const typesenceOutDatedPostToken = process.env.REACT_APP_TYPE_SENSE_OUT_DATED_TOKEN_SEARCH; //token

/**
 * set defaultValue
 */
export const staticData = {
  pinCode: 6,
  MobileNo: 10,
  imeilength: 30,
  thirtyMbHandle: 31457280
};

// /**
//  * @param  {object} params;
//  * Api request params convert to queryParams
//  */
export const encodeGetParams = (params: any = {}) => {
  return Object.entries(params)
    .map((key: any) =>
      key
        .map((k: any) => {
          const enCodeData = typeof k === "object" ? JSON.stringify(k) : k;
          return encodeURIComponent(enCodeData);
        })
        .join("=")
    )
    .join("&");
};

export const notifyMeApi = {
  notifyMe: "v1/notifyMe/all", //url shorter call
  forceNotify: "v1/notifyMe/forceNotify", //forceNotify
  notifyUpdate: "v1/notifyMe/notiFyUpdate", //update
  notifySync: "v1/notifyMe/notifyStockSync", //notifySync
  uploadnotifyMeRecords: "v1/notifyMe/download", //upload excel file
  notifyToAllItemCode: "v1/notifyMe/notifyToAllItemCode", //send to all
  inventoryStockGet: "api/v1/deliverytype/stock?channel=Shop&item_code=" //get inventory stock details
};

export const commonUrl = {
  urlShort: "v4/shorten", //url shorter call
  getAppVersion: "v1/common/getAppVersion", //sales update status list
  moileAppVersionUpdate: "v1/common/update-app-version"
};

export const feedback = {
  getFeedback: "v1/feedback", //get all feedbacks
  reportMail: "v1/feedback/exportToMail",
  postFeedbackData: "v1/feedback/download"
};

export const whatsapp = {
  sendMessageTemplete: "v1/whatsapp/message" //send message
};

export const download = {
  getProductExcelInfo: "v1/downloads"
};

export const ChatAnalyticsApi = {
  getChatAnalytics: "v1/whatsappweb/chat/reports",
  getChatAgentTimeAnalytics: "v1/whatsappweb/report/overall"
};

export const whatsAppChat = {
  userContactDetails: "v1/customers",
  cannedResponse: "v1/cannedresponse",
  chatUploadFile: "v1/whatsappweb/file",
  updateChatEnd: "v1/whatsappweb/update",
  postPrivateNotes: "v1/whatsappweb/note",
  chatUserList: "v1/whatsappweb/getmessage/",
  postWhatsappMessage: "v1/whatsappweb/send",
  onlineChatAgentList: "v1/whatsappweb/users",
  chatQueueCount: "v1/whatsappweb/queue/count",
  updateAgentStatusBreak: "v1/whatsappweb/break",
  updateAgentStatus: "v1/whatsappweb/onlinestatus",
  assignChatToAgent: "v1/whatsappweb/users/assign",
  updateAgentEndChatstatus: "v1/whatsappweb/close",
  updateAgentOpenChatstatus: "v1/whatsappweb/open",
  getAgentCustomrList: "v1/whatsappweb/master-list",
  queueChatUserList: "v1/whatsappweb/getallmessage/",
  userWhatsappChatHistory: "v1/whatsappweb/getmessage",
  chatCapacityCountUpdate: "v1/whatsappweb/users/setting",
  userPrvWhatsappChatHistory: "v1/whatsappweb/getprevmessage",
  userWhatsappChatHistory30days: "v1/whatsappweb/chat/reports/message"
};

export const pincodeApi = {
  pincode: "api/whlocation/storebypin",
  storeDetails: "api/oc3/branchapi.php",
  storeLocationApi: "api/whlocation/storebypin"
};

export const emiApi = {
  produuct: "api/v1/typesence", //product and sku id get fom here
  tokenAccecc: "api/v1/auth/token", //access token
  pinePgEmiCalculate: "v1/common/emi-calculator", //pinePge emi calCulator
  productDetails: "collections/products/documents/search?", //type sense api
  offlineProductEmi: "v2/emicalc",
  productEmi: "v2/emicalc/emi"
};

export const customerInfo = {
  validateOtp: "v1/common/verify-otp", // validate otp
  gstDetails: "v1/common/gstNo-search", // get gst details
  saveCustomer: "v1/customers/details", // save customer
  updateAddress: "v1/customers/address",
  verifyMobileEmail: "v1/common/send-otp", // verify mobile
  mergeRequest: "v1/customers/details/merge", // merge request
  getCustomerDetails: "v1/customers/details/",
  customerList: "v1/customers/details/orders?",
  getProductUrl: "/api/apxapi/GetItemModelInfo", // get products
  getCustomerAddress: "v1/customers/addresses/",
  getPaymentMethod: "v2/getPaymentMethods",
  updatePaymentStatus: "v1/customers/details/order/status",
  cancelOrder: "v1/customers/details/order/cancel",
  resendPaymentOrder: "v1/customers/details/order/resend",
  saveOrderMethod: "v1/customers/details/createOrder",
  uploadCustomerList: "v1/customers/details/orders/download?",
  validateCoupon: "/offers/coupons/validateCoupon",
  getCheckCoupon: "/coupons/couponsForCheckout"
};

export const netCoreMail = {
  mailById: "v1/mail/",
  sendMail: "v1/mail/send",
  draftMail: "v1/mail/draft",
  getMails: "v1/mail/getMails",
  getAutoFills: "v1/mail/getAutoFills",
  updateEnquiry: "v1/mail/updateEnquiry",
  checkPopServer: "v1/mail/checkPopServer",
  checkSmtpServer: "v1/mail/checkSmtpServer",
  syncWithServer: "v1/mail/connectToServer"
};

export const loginApi = {
  editUser: "v1/users/", //register get call
  ssonPage: "v1/auth/sson", //single sign on page
  loginPage: "v1/auth/login", //product and sku id get fom here
  showRoomList: "v1/showroom", //showRoom list
  getRegisterUser: "v1/users?", //get register details
  register: "v1/auth/register", //new user register
  getRegisteredUser: "v1/users/", //register get call
  registerSatusChange: "v1/users/", //register get call
  showRoomListSync: "v1/showroom/sync", //showRoom sync list
  showRoomListSearch: "v1/showroom/list", //showRoom list search
  userSessionList: "v1/auth/sessionList",
  resetPassword: "v1/auth/reset-password",
  sesstionUdate: "v1/auth/session-update", //sesstion api token update
  changePassword: "v1/auth/change-password",
  forgotPassword: "v1/auth/forgot-password",
  userPasswordReset: "v1/auth/reset-password" //user forget password manually reset
};

export const userBasicDetail = {
  customerDetails: "v1/customers", //customer details
  getUserDetails: "v1/customers/", //get user details using phone number
  updateBasicInfo: "v1/customers", //update customers
  addtionalInfoAdded: "v1/customers", //update customers
  areaGet: "api/oc3/pincodeapilist.php?", //get state details
  updateEnquiryCustomerDetails: "v1/enquires/customer/", //update enquiry customer details
  salesBasicInfoHistoryUpdate: "v1/customers/update-history" //history update details
};

export const salesReturn = {
  slaseRetunData: "v1/enquires/salesReturns", //get only sales return data
  convertTosrn: "v1/enquires/salesReturns/convert/", //convert approval request to srn convert
  userTransDataDetails: "v1/enquires/customer/transactions?", //imei and mobile num based get user trans history
  uploadSlaseRetunData: "v1/enquires/salesReturns/download", //upload only sales return data
  imeiDetail: "api/apxapi/GetInvoiceLastTransactionDetailsUsingSerialNumber?CompanyCode=PMPL&SerialNumber="
};

export const duplicateInv = {
  dupInvResendSMS: "v1/enquires/services/resendSms",
  dupInvRemarkUpdate: "v1/enquires/services/remarkStatusUpdate",
  invNoAndInvDate: "api/apxapi/GetInvoiceDetails?CompanyCode=PMPL&",
  getDupInvServiceSmsHistory: "v1/enquires/services/serviceSmsHistory",
  imeiDetail: "api/apxapi/GetInvoiceLastTransactionDetailsUsingSerialNumber?CompanyCode=PMPL&SerialNumber="
};

export const orderTracking = {
  tokenAccecc: "auth/v1/auth/token", //access token
  courierTracking: "courier/trackinginfo", // courier tracking
  omsDetails: "fetchorderdetail/producthistory", //order details
  orderIdDetail: "order/v1/orderproductdetails/",
  omsDetailsBsedPhoneNumberApi: "api/v1/myorder/history"
};

export const offerProjectEndPath = {
  emiOffer: "offers/nbfc/showFinance/",
  alloffers: "offers/ruleEngine/offers/",
  filterOffer: "offers/ruleEngine/getOffers",
  filterOptionOffer: "offers/ruleEngine/filters"
};

export const complain = {
  complain: "v1/enquires/complains?"
};

export const changePasswordApi = {
  changePassword: "v1/auth/change-password",
  forgotPassword: "v1/auth/forgot-password"
};

export const cusListPageApi = {
  //enquires get list
  deleteCusData: "v1/",
  cusList: "v1/enquires/all",
  uploadCusList: "v1/enquires/download",
  enquiriesById: "v1/enquires/all/",
  listByCustomer: "v1/enquires/customer/",
  oldTransHisRecord: "v2/salesforce/crm?customer_mobile=",
  listTransByCustomer: "api/apxapi/GetCustomerInvoiceList?CompanyCode=PMPL&MobileNo="
  // 9884415664
};

export const missedListPageApi = {
  //enquires get list
  deleteCusData: "v1/",
  missedList: "v1/missed/all",
  enquiriesById: "v1/enquires/all/",
  syncTime: "v1/missed/last_synced",
  syncData: "v1/missed/sync_orders",
  getOrderDetail: "v1/missed/order",
  missedStatus: "v1/missed/get_status",
  uploadMissedList: "v1/missed/download",
  listByCustomer: "v1/enquires/customer/",
  listTransByCustomer: "api/apxapi/GetCustomerInvoiceList?CompanyCode=PMPL&MobileNo=",
  missedOrdersStatus: "v1/common/static-data?type=missedOrderStatus"
  // 9884415664
};

export const comListPageApi = {
  //get complain list
  comList: "v1/enquires/complains",
  empDetail: "api/oc3/staffdetails?emplid",
  updateComplain: "v1/enquires/complains/",
  attachEmail: "v1/enquires/complains/export",
  uploadComList: "v1/enquires/complains/download?",
  followUpCustomerDetails: "v1/enquires/my-followups"
};

export const cartList = {
  //cart details
  delete: "v1/cart",
  update: "v1/cart",
  getAllCarts: "v1/cart?",
  uploadAllCarts: "v1/cart/download?",
  deleteMulti: "v1/cart/multi"
};

export const AnonymCartList = {
  //anonym carts details
  delete: "v1/anonymCart",
  update: "v1/anonymCart",
  getAllCarts: "v1/anonymCart?",
  deleteMulti: "v1/anonymCart/multi",
  uploadAllCarts: "v1/anonymCart/download?"
};

export const wishList = {
  //wishlist details
  delete: "v1/wishList",
  update: "v1/wishList",
  getAllwishList: "v1/wishList?",
  deleteMulti: "v1/wishList/multi",
  uploadAllwishList: "v1/wishList/download?"
};

export const customerOrders = {
  //wishlist details
  delete: "v1/order",
  update: "v1/order",
  rePublish: "v2/republish",
  getAllOrders: "v1/order?",
  customerOrder: "v1/customers/orders",
  uploadAllOrders: "v1/order/download?",
  paymentDetails: "v1/paymentDetails",
  createpaymentDetails: "v1/paymentDetails/create"
};

export const advocatePageApi = {
  advocate: "v1/advocate",
  uploadAdvocate: "v1/advocate/download"
};

export const servicesCenterList = {
  delete: "v1/servicecenter",
  create: "v1/servicecenter/create",
  update: "v1/servicecenter/update",
  servicesToken: "auth/v1/auth/token",
  attachEmail: "v1/servicecenter/export", // send email report
  serviceCenterListCrm: "v1/servicecenter/get",
  serviceCenterList: "v1/servicecenter/get/public",
  serviceCenterRefresh: "v1/servicecenter/refresh",
  serviceCenterAllList: "v1/servicecenter/get/public",
  uploadserviceCenterListCrm: "v1/servicecenter/download",
  uploadserviceCenterList: "v1/servicecenter/upload"
};

export const scoreCardList = {
  create: "v1/scorecard",
  serviceCenterList: "v1/scorecard",
  locked: "v1/scorecard/update/lock/",
  scorecardresponse: "v1/scorecardresponse",
  dashboardKudos: "v1/scorecardresponse/dashboard/kudos",
  dashboardAgent: "v1/scorecardresponse/dashboard/agent",
  dashboardGeneral: "v1/scorecardresponse/dashboard/general",
  scorecardEvaluator: "v1/scorecardresponse/evaluator/list",
  dashboardEvalualtor: "v1/scorecardresponse/dashboard/evaluator",
  dashboardNotification: "v1/scorecardresponse/dashboard/notification",
  dashboardQuestionAnalysis: "v1/scorecardresponse/dashboard/questionAnalysis",
  dashboardResultAcceptance: "v1/scorecardresponse/dashboard/resultacceptance",
  dashboardEvaluationPerPeriod: "v1/scorecardresponse/dashboard/evaluationbyperiod",
  team: "v1/scorecard/team",
  teams: "v1/scorecard/teams",
  teamMember: "v1/scorecard/team-members",
  allTeamMember: "v1/scorecard/all-teammembers",
  teamDashboard: "v1/scorecard/team-dashboard",
  evaluatorReport: "v1/scorecardresponse/dashboard/evaluator-reports",
  evaluatorAudits: "v1/scorecardresponse/dashboard/evaluator-audits"
};

export const serviceCalls = {
  getServices: "v1/enquires/services?",
  saveServices: "v1/enquires/services",
  updateServices: "v1/enquires/services",
  dcSeries: "v1/enquires/services/dcSeries", // gets delivery challan series
  attachEmail: "v1/enquires/services/export",
  uploadServices: "v1/enquires/services/download?",
  generalInfoMail: "v1/enquires/services/sendMail",
  sendShowroomSMS: "v1/enquires/services/showroomSMS",
  createDC: "v1/enquires/services/createDeliveryChallan",
  sendWhatsappGeneralMsg: "v1/whatsapp/storeAddressTemplate",
  sendServiceCenterSMS: "v1/enquires/services/serviceCenterSMS",
  imeiDetail: "api/apxapi/GetInvoiceLastTransactionDetailsUsingSerialNumber?CompanyCode=PMPL&SerialNumber="
};

export const onlineEnq = {
  saveOnline: "v1/enquires/onlines",
  getOnline: "v1/enquires/onlines",
  updateOnline: "v1/enquires/onlines",
  reportMail: "v1//enquires/onlines/exportToMail",
  uploadOnlinedataToExcel: "v1/enquires/onlines/download"
};

export const mailCredential = {
  updateCredential: "v1/mailCredentials/",
  saveCredential: "v1/mailCredentials/save",
  getCredential: "v1/mailCredentials/getCredentials"
};

export const dashBoardApi = {
  //dashBoard
  graph: "v1/dashboard/graphs",
  enquires: "v1/dashboard/enquires",
  overallUser: "v1/dashboard/overall-user",
  overView: "v1/dashboard/overall-enquiry",
  getEmpIdBasedEnq: "v1/dashboard/by-user", //table
  downloadEmpIdBasedEnq: "v1/dashboard/by-user/download" //table
};

export const ameyo = {
  callWithUserId: "v1/ameyo/call",
  getCallRecording: "v1/ameyo/recording/get"
};

export const salesTransaction = {
  transInfo: "api/v1/salseforce/crm/info/1"
};

// get user roles
export const rolesAndPagesApi = {
  rolesGet: "v1/roles?", //get userDeails
  statusChange: "v1/roles", //statusChange
  getAllPageDetila: "v1/pages/", //get page details
  createPageDetils: "v1/pages", //create new page
  pageSlugs: "v1/pages/pageSlugs", //get page slug
  permissionUpdate: "v1/permission", //permission update
  getPagePermission: "v1/permission/pageAccess" //get page permission pass role id based
};

export const newCallPage = {
  couponDetail: "v1/common/getTagHash?mobile=" //get user coupon detalis
};

export const campaign = {
  campaignCreate: "v1/campaigns", //campaign
  uploadFile: "v1/campaigns/upload", //upload file,
  uploadBulkCsv: "v1/campaigns/signedUrl",
  sampleFileFile: "v1/campaigns/format/download", //sample formate file,
  campaignExcelDownload: "v1/campaigns/download", //campaign excel download
  orderHistoryToken: " UmUMj1YZkADurQLsHBN9dQ==",
  campaignTransactions: "v1/campaignTransactions", //campaign Groups
  orgToken: "17b8394c-4l2k-4j9p-ad13-78kc2bdb65dk",
  pimapiToken: "246982ced56a4c75998e07acbbd8c4b0"
};

/**
 *product page
 */
export const salesProduct = {
  product: "multi_search", //typesense
  nonDealingDownloadUrl: "multi_search/download", //typesense
  pimVariant: "/api/v1/product", //get full details
  outDatedSearch: "multi_search", //outDated typesense
  relatedProduct: "api/v1/product", //get related product
  shopByCategory: "pim/navigation", //product page
  productDeatails: "pim/product/", //get full details
  salesEndPoint: "v1/enquires/sales", //sales update
  directApiProduct: "api/v1/product/", //proxy
  ticketId: "v1/enquires/all/ticket", //ticket id based get information
  nonDealing: "v1/enquires/sales/outdated", //get outDated api list
  sendEmail: "v1/enquires/sales/sendEmail", // send email report
  multipleProduct: "v1/enquires/sales/many", //sales multiple prodcut added same api
  nlcCategoriesList: "api/v1/categorylist/crm",
  inStockOutStock: "api/v1/deliverytype/stock", //check instock and out stock
  salesUniquesData: "v1/enquires/sales/unique", //unique columns
  stockAvailablity: "api/oc3/stockstatusapioc3", //pin code based stock avalilablity
  deliveryTypeGet: "api/v1/deliverytype/pincode", //get delivery type based on pincode
  missedStatusEndPoint: "v1/missed/transactions", //Missed order update
  uploadSalesEndPoint: "v1/enquires/sales/download", //sales excel update
  salesStatus: "v1/common/static-data?type=salesStatus", //sales update status list
  salesSource: "v1/common/static-data?type=salesSource", //sales update source list
  spendApiPath: "api/v1/salseforce/crm?customer_mobile=", //spend api path
  productDetailSms: "v1/enquires/sales/productDetailSms", //sms
  relatedEnquiryCall: "v1/enquires/sales/relatedProducts", //get recent enquiry details
  productDetailWhatsapp: "v1/whatsapp/salesProductMessage", //sms
  getApxItemModel: "api/oc3/itemmodelapilist.php?item_code=",
  outDatedDelete: "collections/outdated_non_dealing/documents/", //delete outDated model
  productAvailabilityCheck: "api/v1/deliverytype/pincode/address", //pinCode based avilable check
  outDatatedPost: "collections/outdated_non_dealing/documents/import?action=upsert",
  getShowroomPrice: "api/apxapi/GetPriceFromPriceTemplate?CompanyCode=pmpl&PriceEffetiveFrom="
};

/**
 * justDial api
 */
export const justDialApi = {
  justDial: "v1/leads/jd-leads", //table
  touchHandle: "v1/leads/update-jd-leads", //toggle handle
  uplaodJustDialApiData: "v1/leads/download" // excel upload
};

/**
 * search terms
 */
export const searchKeyWordTerms = {
  searchKeyWordTerms: "v1/searchKeyWordTerms/all",
  recentProductSearch: "v1/customerProducts/recent",
  saveLater: "v1/customerProducts/save-later"
};

/**
 * branch assign
 */
export const getAssignBranchDetailsApiPath = {
  getAssignBranchDetailsBasedStaff: "v1/users/list", //get staff details
  getAssignBranchDetails: "v1/showroom/branches/list", //get showroom list
  syncAssignBranchDetails: "v1/showroom/branches/sync" //get showroom list
};

/**
 * pre-register
 */
export const getPreRegisterListOfApiList = {
  getPreRegisterListCall: "v1/preregister", //get preregister details,
  downloadPreRegisterListCall: "v1/preregister/download?" //get preregister details
};

/**
 * enquiryFeedBack
 */
export const enquiryFeedBackApiList = {
  getEnquiryFeedBackListCall: "v1/enquires/customer-feedback", //get FeedBack details,
  downloadEnquiryFeedBackListCall: "v1/enquires/customer-feedback/download?" //get FeedBack details
};

/**
 * Incentive
 */
export const getProdcutIncentiveList = {
  getProductIncentive: "v1/incentive" //get Incentive,
};

/**
 * Print QR code
 */
export const printQRApi = {
  qrPrintdetails: "v1/product-qr", //get printQR
  uploadFile: "v1/product-qr/upload", //upload printQR file
  sampleFileDownload: "v1/product-qr/format/download" //download sample printQR file
};
