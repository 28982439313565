import React, { useEffect, useState } from "react";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { getInstockOutStockDetailsApi } from "../../commonSalesProductDetails";

type inOutStockDetailsProps = {
  pincode?: any;
  item_code?: any;
};

const InOutStockDetails = (props: inOutStockDetailsProps) => {
  const { item_code = "", pincode = "" } = nullToObject(props);

  const [stockDetails, setStockDetails] = useState("");

  const getInstockOutStockData = async (itemCode = "", pinCode = "") => {
    const response = await getInstockOutStockDetailsApi(itemCode, pinCode);
    const { data: { status = 0, data: { stock = 0 } = {} } = {} } = nullToObject(response);
    if (status === 200) {
      if (stock > 0) {
        setStockDetails("In Stock");
      } else {
        setStockDetails("Out Of Stock");
      }
    } else {
      setStockDetails("Api Stock fails");
    }
  };

  //pinCode based get stock details
  useEffect(() => {
    if (pincode && item_code) {
      getInstockOutStockData(item_code, pincode); //update pinCode
    }
  }, [pincode, item_code]);

  return (
    <div className=" flex flex-wrap">
      <span
        className={`${
          convertToLowerCase(stockDetails) !== convertToLowerCase("In Stock") ? " text-red-500 " : " text-green-500 "
        } font-semibold  text-xs`}
      >
        {stockDetails}
      </span>
    </div>
  );
};

export default InOutStockDetails;
