import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ImgAvatarMaleSm3 } from "assets/images";
import { convertToLowerCase } from "utils/validation";
import { SalesEnquiryStatusUpdateProps } from "../types";
import { getMailByIdCall, resetEnquiryMailCall } from "Pages/ServiceCalls/commonServiceCalls";
import { bg_BasicInfo, bg_EnquiryStatusHistory, bg_EnquiryStatusUpdate } from "utils/ClassContent";
import CommonTable from "Component/CommonTable";
import PageAnimate from "Component/PageAnimate";
import SalesOtherViewUpdate from "./SalesOtherViewUpdate";
import CommonDropHeading from "Component/CommonDropHeading";
import SalesOtherViewPopupTap from "./SalesOtherViewPopupTap";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import BasicInfoView from "Pages/Customers/CallsList/BasicInfoView";
import EnquiryStatusTrackingData from "Pages/NewCall/EnquiryFor/SalesReturnsForm/ViewSalesReturn/StatusUpdateSalesReturn/EnquiryStatusTrackingTable/EnquiryStatusTrackingData";

const SalesOtherUpdate = (props: SalesEnquiryStatusUpdateProps) => {
  const { setShow = true, customerData = {}, activeModal = false, closePopup = () => {} } = props;

  const {
    imeiMobileNo = "",
    enquiryFor = "",
    status = "",
    mailId = "",
    customerName = "",
    subCategory = "",
    transactions = []
  } = customerData;

  const globalData = useSelector((state: any) => state) || {};
  const { salesReturn: { imeiNumHistoryDetails = {} } = {} } = globalData;
  const { data: imeiNumHistoryDetailsData = [] } = imeiNumHistoryDetails;
  const [popParentTabs, setPopParentTabs] = useState("BasicInfo");

  const popParentTabsHandle = (value = "") => {
    setPopParentTabs(value);
  };

  //filter mobile number
  const cusTransHistory = imeiNumHistoryDetailsData
    .map((ele: any) => {
      if (ele.imeiMobileNo === imeiMobileNo) {
        return ele;
      }
      return undefined;
    })
    .filter((eleUndefined: any) => eleUndefined !== undefined);

  const [editView, setEditView] = useState(false);
  const [columnView, setColumnView] = useState(false);
  const [enableViewMode, setEnableViewMode] = useState(true);

  const handleEditView = () => {
    setEditView(!editView);
    setEnableViewMode(!enableViewMode);
  };

  const handleColumnView = () => {
    setColumnView(!columnView);
  };

  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);

  useEffect(() => {
    if (mailId) {
      getMailByIdCall(mailId);
    }
    return () => {
      if (mailId) {
        resetEnquiryMailCall();
      }
    };
  }, [mailId]);

  return (
    <React.Fragment>
      {/* loader */}
      <FixedPopupTemplate
        overflowChild={true}
        editStatus={editView}
        imgSrc={ImgAvatarMaleSm3}
        columnViewStatus={columnView}
        enableColumnViewOption={true}
        textHeaderHeading={customerName}
        handleEditView={handleEditView}
        handleClosePopup={() => closePopup(false)}
        handleColumnView={handleColumnView}
        textSubHeaderHeading={`Enquiry For : ${enquiryFor}`}
        enableEditOption={status.toLowerCase() !== "closed"}
      >
        <PageAnimate className="h-full">
          {/* basic data information */}
          <SalesOtherViewPopupTap
            subCategory={subCategory}
            customerData={customerData}
            popParentTabs={popParentTabs}
            popParentTabsHandle={popParentTabsHandle}
            showImeiTrans={cusTransHistory.length >= 1 ? true : false}
          />

          <div
            className={`${
              columnView ? "flex h-full items-start flex-wrap lg:flex-nowrap" : ""
            } overflow-y-auto  h-full md:px-2`}
            style={{ maxHeight: "calc(100% - 100px)" }}
          >
            <div
              className={` ${!activeModal ? "py-5" : "py-1"} ${
                columnView ? " w-full lg:w-7/12 " : " "
              } flex-wrap flex bg-gray-100"  md:px-2`}
            >
              <CommonDropHeading
                customClass={bg_BasicInfo}
                id="BasicInfo"
                heading="Basic Info"
                tabActiveDefault={popParentTabs === "BasicInfo" ? true : false}
              >
                <BasicInfoView
                  isSalesEnquiry={true}
                  customerData={customerData}
                  enableViewMode={enableViewMode}
                  handleEditView={handleEditView}
                />
              </CommonDropHeading>
            </div>

            <div
              className={`${
                columnView ? "w-full lg:w-5/12 " : " w-full pt-4 border-gray-300 border-t-2 border-dashed "
              } bg-white flex flex-col items-center md:px-2`}
            >
              {convertToLowerCase(enquiryFor) === convertToLowerCase("Sales") && (
                <>
                  <div id="EnquiryStatusHistory" className={`w-full flex transition flex-wrap mb-4 h-auto `}>
                    <CommonTable
                      customClass={bg_EnquiryStatusHistory}
                      setShow={true}
                      parentClass={"w-full"}
                      heading={"Enquiry Sales Status History"}
                    >
                      <EnquiryStatusTrackingData
                        columnView={columnView}
                        customerData={customerData}
                        transactions={transactions}
                        showAssign
                      />
                    </CommonTable>
                  </div>
                  <div id="DelaingsStatusUpdate" className={`w-full flex transition flex-wrap mb-4 h-auto `}>
                    <CommonTable
                      customClass={bg_EnquiryStatusUpdate}
                      setShow={true}
                      parentClass={"w-full"}
                      heading={"Sales Other Status"}
                    >
                      <div className={`flex flex-wrap w-full`}>
                        <SalesOtherViewUpdate
                          closePopup={closePopup}
                          columnView={columnView}
                          customerData={customerData}
                        />
                      </div>
                    </CommonTable>
                  </div>
                </>
              )}
            </div>
            <div id="ModalEnquiryForPopup" />
          </div>
        </PageAnimate>
      </FixedPopupTemplate>
    </React.Fragment>
  );
};

export default SalesOtherUpdate;
