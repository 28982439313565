import { IconShoppingTruckDelivery } from "assets/images";
import React from "react";
import { Link } from "react-router-dom";
import { ListClass, SideBarIconClass, MenuName } from "utils/ClassContent";

const PreRegisterMenu = (props: any = {}) => {
  const { pathname = "" } = props;

  return (
    <li className={`${ListClass} ${pathname === "/pre-register" ? " active-menu" : ""}`}>
      <Link to={"/pre-register"} className={`${SideBarIconClass} flex-auto`}>
        <IconShoppingTruckDelivery className={"w-8 h-8 flex-shrink-0 py-1"} />
        <div className={MenuName + " relative"}>
          <span className="text-sm">{"Pre  Register"}</span>
        </div>
      </Link>
    </li>
  );
};
export default PreRegisterMenu;
