import React from "react";
import { dupStatusHistoryTableProps } from "./types";
import CommonImageTag from "Component/Form/CommonImageTag";
import { convertToLowerCase, dateTimeDisplay, imageTypeCheck } from "utils/validation";
import { ImgCsv, ImgDoc, ImgFile, ImgPdf, ImgTxt, ImgXls } from "assets/images";
import { tdClassStyle2, thClass } from "utils/ClassContent";

const DuplicateInvStatusHistory = (props: dupStatusHistoryTableProps) => {
  const { dupImeiServiceUpdatedData = {}, getModalStatus = false } = props || {};

  const { statusHistory = [] } = dupImeiServiceUpdatedData;

  const newArrayImage = statusHistory.map((ele: any) => {
    const assignData = ele.imgUrl ? ele.imgUrl : "";
    const output1 = assignData ? assignData.split(".").pop() : "";
    ele.type = output1;
    return ele;
  });

  const handleDoctypesImage = (doctype = "") => {
    switch (doctype) {
      case "pdf":
        return ImgPdf;
      case "doc":
        return ImgDoc;
      case "xlsx":
        return ImgXls;
      case "xls":
        return ImgXls;
      case "txt":
        return ImgTxt;
      case "csv":
        return ImgCsv;
      default:
        return ImgFile;
    }
  };

  return (
    <div
      className="block w-full overflow-x-auto relative "
      style={{ minHeight: "250px", height: getModalStatus ? "250px" : "100%" }}
    >
      <table className="items-center bg-transparent w-full border-collapse ">
        <thead>
          <tr>
            <th className={thClass}>Date</th>
            <th className={thClass}>Status</th>
            <th className={thClass}>Sub Status</th>
            <th className={thClass}>File</th>
            <th className={thClass}>UpdateBy</th>
            <th className={thClass}>Branch Name</th>
            <th className={thClass}>Remarks</th>
          </tr>
        </thead>
        {newArrayImage.map((ele: any, i: number) => {
          const {
            type = "",
            date = "",
            imgUrl = "",
            status = "",
            updateBy = "",
            branchName = "",
            currentStatus = "",
            remarks = ""
          } = ele;
          return (
            <tbody key={convertToLowerCase(i + "newArrayImage-fsc")}>
              <tr tabIndex={1}>
                <td className={tdClassStyle2}>{dateTimeDisplay(date)}</td>
                <td className={tdClassStyle2}>{status}</td>
                <td className={tdClassStyle2}>{currentStatus}</td>
                {imgUrl ? (
                  <td className={tdClassStyle2}>
                    {imageTypeCheck(type) ? (
                      <CommonImageTag
                        url={imgUrl}
                        imgData={imgUrl}
                        altName={ele.fileName}
                        className={"w-auto h-10 c-primary"}
                      />
                    ) : (
                      <CommonImageTag
                        url={imgUrl}
                        altName={ele.fileName}
                        className={"w-auto h-10 c-primary"}
                        imgData={handleDoctypesImage(type)}
                      />
                    )}
                  </td>
                ) : (
                  <td className={tdClassStyle2}>-</td>
                )}
                <td className={tdClassStyle2}>{updateBy}</td>
                <td className={tdClassStyle2}>{branchName}</td>
                <td className={tdClassStyle2}>{remarks}</td>
              </tr>
            </tbody>
          );
        })}
      </table>
    </div>
  );
};

export default DuplicateInvStatusHistory;
