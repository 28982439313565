/* eslint-disable */
import { PlusIcon } from "@heroicons/react/outline";
import { ImgAvatarFemale1, ImgAvatarFemale2, ImgAvatarFemale3, ImgAvatarFemale4 } from "../assets/images";

export const subCategoryPropsMultiCheck = [
  { _id: 1, isCheck: false, itemName: "Product" },
  { _id: 2, isCheck: false, itemName: "Non-Dealing" },
  { _id: 3, isCheck: false, itemName: "Others" }
];

export const closeData = [
  { id: 1, isSelect: false, optionData: "No response" },
  { id: 2, isSelect: false, optionData: "Not interested" },
  { id: 3, isSelect: false, optionData: "Not enquired" },
  { id: 4, isSelect: false, optionData: "Just enquired" }
];

export const salesOutDatedMultiCheck = [
  { isCheck: false, _id: 7, subStatus: "New" },
  { isCheck: false, _id: 1, subStatus: "Busy" },
  { isCheck: false, _id: 2, subStatus: "Ringing" },
  { isCheck: false, _id: 3, subStatus: "Out of Stock" },
  { isCheck: false, _id: 4, subStatus: "Stopped model" },
  { isCheck: false, _id: 5, subStatus: "Non Dealing model" },
  { isCheck: false, _id: 6, subStatus: "Call Not Connected" },
  { isCheck: false, _id: 8, subStatus: "Plan to Purchase" }
];

export const sllCallsubStatusMultiCheck = [
  { isCheck: false, _id: 1, itemName: "Product" },
  { isCheck: false, _id: 2, itemName: "Non-Dealing" },
  { isCheck: false, _id: 3, itemName: "SRN" },
  { isCheck: false, _id: 4, itemName: "Approval Request" },
  { isCheck: false, _id: 5, itemName: "Product Service" },
  { isCheck: false, _id: 6, itemName: "Service Support" },
  { isCheck: false, _id: 7, itemName: "Duplicate invoice" },
  { isCheck: false, _id: 8, itemName: "General Information" },
  { isCheck: false, _id: 9, itemName: "Others" }
];

export const statusMultiCheck: any = [
  { isCheck: true, _id: 1, itemName: "Open" },
  { isCheck: true, _id: 2, itemName: "Pending" },
  { isCheck: false, _id: 3, itemName: "Closed" }
];

export const solutionMultiCheck: any = [
  { isCheck: false, _id: 1, itemName: "Store" },
  { isCheck: false, _id: 2, itemName: "Service Center" },
  { isCheck: false, _id: 3, itemName: "Other Details" }
];

export const cityMultiCheck: any = [{ isCheck: false, _id: 1, itemName: "Chennai" }];

export const allCallsEnqMultiCheck = [
  { isCheck: false, _id: 1, itemName: "Sales" },
  { isCheck: false, _id: 2, itemName: "Sales Returns" },
  { isCheck: false, _id: 3, itemName: "Complaints" },
  { isCheck: false, _id: 4, itemName: "Service" },
  { isCheck: false, _id: 5, itemName: "Online" }
];

export const MockSearchCustomer = [
  {
    heading: "Laila Jansen",
    address: "Opp Meenakshi College, Kodambakkam, Chennai",
    contactNumber: "12345 66666",
    mail: "Mail",
    userMail: "lailajansen@gmail.com",
    imgUrl: ImgAvatarFemale4
  },
  {
    heading: "Laila Jansen",
    address: "Opp Meenakshi College, Kodambakkam, Chennai",
    contactNumber: "12345 66666",
    mail: "Mail",
    userMail: "lailajansen@gmail.com",
    imgUrl: ImgAvatarFemale3
  },
  {
    heading: "Laila Jansen",
    address: "Opp Meenakshi College, Kodambakkam, Chennai",
    contactNumber: "12345 66666",
    mail: "Mail",
    userMail: "lailajansen@gmail.com",
    imgUrl: ImgAvatarFemale2
  },
  {
    heading: "Laila Jansen",
    address: "Opp Meenakshi College, Kodambakkam, Chennai",
    contactNumber: "12345 66666",
    mail: "Mail",
    userMail: "lailajansen@gmail.com",
    imgUrl: ImgAvatarFemale1
  }
];

export const MockQuickLaunch = [
  {
    listName: "Latest Report",
    listType: "Button"
  },
  {
    listName: "New Call",
    listType: "Link",
    toUrl: "/new-call",
    icons: <PlusIcon />
  },
  {
    listName: "Contact",
    listType: "Link",
    toUrl: "/contacts",
    icons: <PlusIcon />
  }
];

export const MockLanguageData = [
  {
    id: 1,
    isSelect: false,
    optionData: "Tamil",
    optionValue: "Tamil"
  },
  {
    id: 2,
    isSelect: false,
    optionData: "English",
    optionValue: "English"
  },
  {
    id: 3,
    isSelect: false,
    optionData: "Telugu",
    optionValue: "Telugu"
  },
  {
    id: 4,
    isSelect: false,
    optionData: "Hindi",
    optionValue: "Hindi"
  },
  {
    id: 5,
    isSelect: false,
    optionData: "Kannada",
    optionValue: "Kannada"
  },
  {
    id: 6,
    isSelect: false,
    optionData: "Malayalam",
    optionValue: "Malayalam"
  },
  {
    id: 7,
    isSelect: false,
    optionData: "Marathi",
    optionValue: "Marathi"
  },
  {
    id: 8,
    isSelect: false,
    optionData: "Bengali",
    optionValue: "Bengali"
  }
];

export const MockProductData = [
  {
    id: 1,
    isSelect: false,
    optionData: "Mobile",
    optionValue: "Mobile"
  },
  {
    id: 2,
    isSelect: false,
    optionData: "Air Conditioner",
    optionValue: "Air Conditioner"
  },
  {
    id: 3,
    isSelect: false,
    optionData: "Air Cooler",
    optionValue: "Air Cooler"
  },
  {
    id: 4,
    isSelect: false,
    optionData: "Fan",
    optionValue: "Fan"
  },
  {
    id: 5,
    isSelect: false,
    optionData: "Instant Water Heater",
    optionValue: "Instant Water Heater"
  },
  {
    id: 6,
    isSelect: false,
    optionData: "Iron",
    optionValue: "Iron"
  },
  {
    id: 7,
    isSelect: false,
    optionData: "Refrigerator",
    optionValue: "Refrigerator"
  },
  {
    id: 8,
    isSelect: false,
    optionData: "Stabilizer",
    optionValue: "Stabilizer"
  },
  {
    id: 9,
    isSelect: false,
    optionData: "Stand",
    optionValue: "Stand"
  },
  {
    id: 10,
    isSelect: false,
    optionData: "Television",
    optionValue: "Television"
  },
  {
    id: 11,
    isSelect: false,
    optionData: "Tower Fan",
    optionValue: "Tower Fan"
  },
  {
    id: 12,
    isSelect: false,
    optionData: "Washing Machine",
    optionValue: "Washing Machine"
  },
  {
    id: 13,
    isSelect: false,
    optionData: "Water Dispenser",
    optionValue: "Water Dispenser"
  },
  {
    id: 14,
    isSelect: false,
    optionData: "Water Heater",
    optionValue: "Water Heater"
  },
  {
    id: 15,
    isSelect: false,
    optionData: "Water Purifier",
    optionValue: "Water Purifier"
  },
  {
    id: 16,
    isSelect: false,
    optionData: "Stabilizer",
    optionValue: "Stabilizer"
  },
  {
    id: 17,
    isSelect: false,
    optionData: "Air Fryer",
    optionValue: "Air Fryer"
  },
  {
    id: 18,
    isSelect: false,
    optionData: "Bar Blender",
    optionValue: "Bar Blender"
  },
  {
    id: 19,
    isSelect: false,
    optionData: "Chimney",
    optionValue: "Chimney"
  },
  {
    id: 20,
    isSelect: false,
    optionData: "Citrus Juicer",
    optionValue: "Citrus Juicer"
  },
  {
    id: 21,
    isSelect: false,
    optionData: "Coconut Scrapper",
    optionValue: "Coconut Scrapper"
  },
  {
    id: 22,
    isSelect: false,
    optionData: "Coffee Maker",
    optionValue: "Coffee Maker"
  },
  {
    id: 23,
    isSelect: false,
    optionData: "Diswasher",
    optionValue: "Diswasher"
  },
  {
    id: 24,
    isSelect: false,
    optionData: "Electric Kettle",
    optionValue: "Electric Kettle"
  },
  {
    id: 25,
    isSelect: false,
    optionData: "Gas Stove",
    optionValue: "Gas Stove"
  },
  {
    id: 26,
    isSelect: false,
    optionData: "Hand Blender",
    optionValue: "Hand Blender"
  },
  {
    id: 27,
    isSelect: false,
    optionData: "Hand Mixer",
    optionValue: "Hand Mixer"
  },
  {
    id: 28,
    isSelect: false,
    optionData: "Hob",
    optionValue: "Hob"
  },
  {
    id: 29,
    isSelect: false,
    optionData: "Induction Stove",
    optionValue: "Induction Stove"
  },
  {
    id: 30,
    isSelect: false,
    optionData: "Juicer",
    optionValue: "Juicer"
  },
  {
    id: 31,
    isSelect: false,
    optionData: "Microwave",
    optionValue: "Microwave"
  },
  {
    id: 32,
    isSelect: false,
    optionData: "Mixer Grinder",
    optionValue: "Mixer Grinder"
  },
  {
    id: 33,
    isSelect: false,
    optionData: "NonStick Cookware",
    optionValue: "NonStick Cookware"
  },
  {
    id: 34,
    isSelect: false,
    optionData: "Nutriblend",
    optionValue: "Nutriblend"
  },
  {
    id: 35,
    isSelect: false,
    optionData: "OTG",
    optionValue: "OTG"
  },
  {
    id: 36,
    isSelect: false,
    optionData: "Pressure Cooker",
    optionValue: "Pressure Cooker"
  },
  {
    id: 37,
    isSelect: false,
    optionData: "Rice Cooker",
    optionValue: "Rice Cooker"
  },
  {
    id: 38,
    isSelect: false,
    optionData: "Roti Maker",
    optionValue: "Roti Maker"
  },
  {
    id: 39,
    isSelect: false,
    optionData: "Sandwich Maker",
    optionValue: "Sandwich Maker"
  },
  {
    id: 40,
    isSelect: false,
    optionData: "Toaster",
    optionValue: "Toaster"
  },
  {
    id: 41,
    isSelect: false,
    optionData: "Wet Grinder",
    optionValue: "Wet Grinder"
  },
  {
    id: 42,
    isSelect: false,
    optionData: "Acc Mac",
    optionValue: "Acc Mac"
  },
  {
    id: 43,
    isSelect: false,
    optionData: "Acc Pad",
    optionValue: "Acc Pad"
  },
  {
    id: 44,
    isSelect: false,
    optionData: "Acc Phone",
    optionValue: "Acc Phone"
  },
  {
    id: 45,
    isSelect: false,
    optionData: "Audio Cable",
    optionValue: "Audio Cable"
  },
  {
    id: 46,
    isSelect: false,
    optionData: "Back Case",
    optionValue: "Back Case"
  },
  {
    id: 47,
    isSelect: false,
    optionData: "Charger",
    optionValue: "Charger"
  },
  {
    id: 48,
    isSelect: false,
    optionData: "Connector",
    optionValue: "Connector"
  },
  {
    id: 49,
    isSelect: false,
    optionData: "Data Cable",
    optionValue: "Data Cable"
  },
  {
    id: 50,
    isSelect: false,
    optionData: "Flash Gard",
    optionValue: "Flash Gard"
  },
  {
    id: 51,
    isSelect: false,
    optionData: "Head Phone",
    optionValue: "Head Phone"
  },
  {
    id: 52,
    isSelect: false,
    optionData: "Mobile Holder",
    optionValue: "Mobile Holder"
  },
  {
    id: 53,
    isSelect: false,
    optionData: "Pendrive",
    optionValue: "Pendrive"
  },
  {
    id: 54,
    isSelect: false,
    optionData: "Pouch",
    optionValue: "Pouch"
  },
  {
    id: 55,
    isSelect: false,
    optionData: "Protection Pro",
    optionValue: "Protection Pro"
  },
  {
    id: 56,
    isSelect: false,
    optionData: "Screen Guard",
    optionValue: "Screen Guard"
  },
  {
    id: 57,
    isSelect: false,
    optionData: "Selfie Stick",
    optionValue: "Selfie Stick"
  },
  {
    id: 58,
    isSelect: false,
    optionData: "Silicon",
    optionValue: "Silicon"
  },
  {
    id: 59,
    isSelect: false,
    optionData: "Stylus Ball Pen",
    optionValue: "Stylus Ball Pen"
  },
  {
    id: 60,
    isSelect: false,
    optionData: "Watch Strap",
    optionValue: "Watch Strap"
  },
  {
    id: 61,
    isSelect: false,
    optionData: "Wipes",
    optionValue: "Wipes"
  },
  {
    id: 62,
    isSelect: false,
    optionData: "External Storage Device",
    optionValue: "External Storage Device"
  },
  {
    id: 63,
    isSelect: false,
    optionData: "Headphone",
    optionValue: "Headphone"
  },
  {
    id: 64,
    isSelect: false,
    optionData: "KeyBoard &amp; Mouse",
    optionValue: "KeyBoard &amp; Mouse"
  },
  {
    id: 65,
    isSelect: false,
    optionData: "Laptop Stand",
    optionValue: "Laptop Stand"
  },
  {
    id: 66,
    isSelect: false,
    optionData: "Micro Phone",
    optionValue: "Micro Phone"
  },
  {
    id: 67,
    isSelect: false,
    optionData: "Mouse",
    optionValue: "Mouse"
  },
  {
    id: 68,
    isSelect: false,
    optionData: "Speaker",
    optionValue: "Speaker"
  },
  {
    id: 69,
    isSelect: false,
    optionData: "Surge Protector",
    optionValue: "Surge Protector"
  },
  {
    id: 70,
    isSelect: false,
    optionData: "Webcam",
    optionValue: "Webcam"
  },
  {
    id: 71,
    isSelect: false,
    optionData: "Boom Headset",
    optionValue: "Boom Headset"
  },
  {
    id: 72,
    isSelect: false,
    optionData: "BT Speaker",
    optionValue: "BT Speaker"
  },
  {
    id: 73,
    isSelect: false,
    optionData: "Fit Band",
    optionValue: "Fit Band"
  },
  {
    id: 74,
    isSelect: false,
    optionData: "IOT Smart",
    optionValue: "IOT Smart"
  },
  {
    id: 75,
    isSelect: false,
    optionData: "Projector",
    optionValue: "Projector"
  },
  {
    id: 76,
    isSelect: false,
    optionData: "Smart Camera",
    optionValue: "Smart Camera"
  },
  {
    id: 77,
    isSelect: false,
    optionData: "Smart Glasses",
    optionValue: "Smart Glasses"
  },
  {
    id: 78,
    isSelect: false,
    optionData: "Smart lighting",
    optionValue: "Smart lighting"
  },
  {
    id: 79,
    isSelect: false,
    optionData: "Smart Plug",
    optionValue: "Smart Plug"
  },
  {
    id: 80,
    isSelect: false,
    optionData: "Smart Tracker",
    optionValue: "Smart Tracker"
  },
  {
    id: 81,
    isSelect: false,
    optionData: "Smart Tracker Acc",
    optionValue: "Smart Tracker Acc"
  },
  {
    id: 82,
    isSelect: false,
    optionData: "Smart Watch",
    optionValue: "Smart Watch"
  },
  {
    id: 83,
    isSelect: false,
    optionData: "Soundbar",
    optionValue: "Soundbar"
  },
  {
    id: 84,
    isSelect: false,
    optionData: "Electric Toothbrush",
    optionValue: "Electric Toothbrush"
  },
  {
    id: 85,
    isSelect: false,
    optionData: "Mask",
    optionValue: "Mask"
  },
  {
    id: 86,
    isSelect: false,
    optionData: "Sanitizer",
    optionValue: "Sanitizer"
  },
  {
    id: 87,
    isSelect: false,
    optionData: "Accessories",
    optionValue: "Accessories"
  },
  {
    id: 88,
    isSelect: false,
    optionData: "Memory Card",
    optionValue: "Memory Card"
  },
  {
    id: 89,
    isSelect: false,
    optionData: "Neckband BT",
    optionValue: "Neckband BT"
  },
  {
    id: 90,
    isSelect: false,
    optionData: "Power Bank",
    optionValue: "Power Bank"
  },
  {
    id: 91,
    isSelect: false,
    optionData: "True Wireless (TWS)",
    optionValue: "True Wireless (TWS)"
  },
  {
    id: 92,
    isSelect: false,
    optionData: "Dryer",
    optionValue: "Dryer"
  },
  {
    id: 93,
    isSelect: false,
    optionData: "Epilators",
    optionValue: "Epilators"
  },
  {
    id: 94,
    isSelect: false,
    optionData: "Straightener",
    optionValue: "Straightener"
  },
  {
    id: 95,
    isSelect: false,
    optionData: "Trimmer",
    optionValue: "Trimmer"
  }
];

export const MockEnquiryFor = [
  { id: 2, isSelect: false, optionData: "Sales" },
  { id: 3, isSelect: false, optionData: "Service" },
  { id: 4, isSelect: false, optionData: "Complaints" },
  { id: 5, isSelect: false, optionData: "Sales Returns" },
  { id: 7, isSelect: false, optionData: "Online" }
];

export const MockEnquiryForDev = [
  { id: 2, isSelect: false, optionData: "Sales" },
  { id: 3, isSelect: false, optionData: "Service" },
  { id: 4, isSelect: false, optionData: "Complaints" },
  { id: 5, isSelect: false, optionData: "Sales Returns" },
  { id: 7, isSelect: false, optionData: "Online" }
];

export const MockSalesSubCategory = [
  { id: 2, isSelect: false, optionData: "Product" },
  { id: 3, isSelect: false, optionData: "Prebook" },
  { id: 4, isSelect: false, optionData: "Store Location" },
  { id: 5, isSelect: false, optionData: "EMI" },
  {
    id: 6,
    isSelect: false,
    optionData: "Upcoming/Outdated Models/Non dealing Models"
  },
  { id: 8, isSelect: false, optionData: "Exchange" },
  { id: 10, isSelect: false, optionData: "Pre-Register" },
  { id: 11, isSelect: false, optionData: "Others" }
];

export const MockSalesSubCategoryProd = [
  { id: 2, isSelect: false, optionData: "Product" },
  {
    id: 6,
    isSelect: false,
    optionData: "Upcoming/Outdated Models/Non dealing Models"
  },
  { id: 11, isSelect: false, optionData: "Others" }
];

export const MockModeoOfPayment = [
  { id: 2, isSelect: false, optionData: "Cash" },
  { id: 3, isSelect: false, optionData: "Finance" },
  { id: 4, isSelect: false, optionData: "Debit Card" },
  { id: 5, isSelect: false, optionData: "Credit Card" },
  { id: 6, isSelect: false, optionData: "Wallet" },
  { id: 7, isSelect: false, optionData: "Payment gateway" }
];

export const MockFinance = [
  { id: 1, isSelect: false, optionData: "Bajaj" },
  { id: 2, isSelect: false, optionData: "IDFC capital" },
  { id: 3, isSelect: false, optionData: "Tvs" },
  { id: 4, isSelect: false, optionData: "HDB" },
  { id: 5, isSelect: false, optionData: "Home credit" },
  { id: 6, isSelect: false, optionData: "ICICI" },
  { id: 7, isSelect: false, optionData: "Hdfc Paper Finance" }
];

export const MockDebitCard = [
  { id: 1, isSelect: false, optionData: "CITI BANK" },
  { id: 2, isSelect: false, optionData: "HDFC" },
  { id: 3, isSelect: false, optionData: "IDBI" },
  { id: 4, isSelect: false, optionData: "AXIS" },
  { id: 5, isSelect: false, optionData: "SBI" },
  { id: 6, isSelect: false, optionData: "KVB" },
  { id: 7, isSelect: false, optionData: "INDIAN" },
  { id: 8, isSelect: false, optionData: "IOB" },
  { id: 9, isSelect: false, optionData: "ICICI" },
  { id: 10, isSelect: false, optionData: "HSBC" },
  { id: 11, isSelect: false, optionData: "TMB" },
  { id: 12, isSelect: false, optionData: "AMEX" },
  { id: 13, isSelect: false, optionData: "UCO" },
  { id: 14, isSelect: false, optionData: "CANARA" },
  { id: 15, isSelect: false, optionData: "KOTAK" },
  { id: 16, isSelect: false, optionData: "SBP" },
  { id: 17, isSelect: false, optionData: "SBH" },
  { id: 18, isSelect: false, optionData: "PNB" },
  { id: 19, isSelect: false, optionData: "SYNDICATE BANK" },
  { id: 20, isSelect: false, optionData: "CITY UNION BANK" },
  { id: 21, isSelect: false, optionData: "BANK OF BARODA" },
  { id: 22, isSelect: false, optionData: "CORPORATION BANK" },
  { id: 23, isSelect: false, optionData: "SCB" },
  { id: 24, isSelect: false, optionData: "SOUTH INDIAN BANK" },
  { id: 25, isSelect: false, optionData: "SBT BANK" },
  { id: 26, isSelect: false, optionData: "UNION BANK OF INDIA" },
  { id: 27, isSelect: false, optionData: "LAKSHMI VILAS BANK" },
  { id: 28, isSelect: false, optionData: "INDUSIND BANK" },
  { id: 29, isSelect: false, optionData: "CENTRAL BANK" },
  { id: 30, isSelect: false, optionData: "FEDERAL BANK" },
  { id: 31, isSelect: false, optionData: "BOI" },
  { id: 32, isSelect: false, optionData: "YES BANK" },
  { id: 33, isSelect: false, optionData: "RBL BANK" },
  { id: 34, isSelect: false, optionData: "ORIENTAL BANK" }
];

export const MockCreditCard = [
  { id: 1, isSelect: false, optionData: "STATE BANK OF INDIA" },
  { id: 1, isSelect: false, optionData: "CITI BANK" },
  { id: 1, isSelect: false, optionData: "HDFC" },
  { id: 1, isSelect: false, optionData: "IDBI" },
  { id: 1, isSelect: false, optionData: "AXIS" },
  { id: 1, isSelect: false, optionData: "SBI" },
  { id: 1, isSelect: false, optionData: "KVB" },
  { id: 1, isSelect: false, optionData: "INDIAN" },
  { id: 1, isSelect: false, optionData: "IOB" },
  { id: 1, isSelect: false, optionData: "ICICI" },
  { id: 1, isSelect: false, optionData: "HSBC" },
  { id: 1, isSelect: false, optionData: "TMB" },
  { id: 1, isSelect: false, optionData: "AMEX" },
  { id: 1, isSelect: false, optionData: "UCO" },
  { id: 1, isSelect: false, optionData: "CANARA" },
  { id: 1, isSelect: false, optionData: "KOTAK" },
  { id: 1, isSelect: false, optionData: "SBP" },
  { id: 1, isSelect: false, optionData: "SBH" },
  { id: 1, isSelect: false, optionData: "PNB" },
  { id: 1, isSelect: false, optionData: "SYNDICATE BANK" },
  { id: 1, isSelect: false, optionData: "CITY UNION BANK" },
  { id: 1, isSelect: false, optionData: "BANK OF BARODA" },
  { id: 1, isSelect: false, optionData: "CORPORATION BANK" },
  { id: 1, isSelect: false, optionData: "SCB" },
  { id: 1, isSelect: false, optionData: "SOUTH INDIAN BANK" },
  { id: 1, isSelect: false, optionData: "SBT BANK" },
  { id: 1, isSelect: false, optionData: "UNION BANK OF INDIA" },
  { id: 1, isSelect: false, optionData: "LAKSHMI VILAS BANK" },
  { id: 1, isSelect: false, optionData: "INDUSIND BANK" },
  { id: 1, isSelect: false, optionData: "CENTRAL BANK" },
  { id: 1, isSelect: false, optionData: "FEDERAL BANK" },
  { id: 1, isSelect: false, optionData: "BOI" },
  { id: 1, isSelect: false, optionData: "YES BANK" },
  { id: 1, isSelect: false, optionData: "RBL BANK" },
  { id: 1, isSelect: false, optionData: "ORIENTAL BANK" }
];

export const customerServiceOptions = {
  all: { currentStatus: "", action: [] },
  new: {
    currentStatus: "New",
    action: ["Send To Service Center", "Send To Ho"]
  },
  inTransit: {
    currentStatus: "In Transit",
    action: ["Received From Store", "Cancel From HO"]
  },
  receivedFromStore: {
    currentStatus: "Received From Store",
    action: ["Send To Service Center", "Send To Branch"]
  },
  pendinAtSC: {
    currentStatus: "Pending At Service Center",
    action: ["Update Jobsheet", "Cancel From Service"]
  },
  receivedFromSC: {
    currentStatus: "Received From Service Center",
    action: ["Check QC", "Send To Store"]
  },
  sendToStore: {
    currentStatus: "Pending To Send Store",
    action: ["Send To Store"]
  },
  receivedFromHo: {
    currentStatus: "Received From HO",
    action: ["Inform Customer"]
  },
  pendingForDelh: {
    currentStatus: "Pending To Inform Customer",
    action: ["Inform Customer"]
  },
  informedCustomer: {
    currentStatus: "Informed Customer",
    action: ["Deliver To Customer"]
  },
  mobileReturned: { currentStatus: "Mobile Returned", action: [] },
  delivered: { currentStatus: "Delivered", action: [] }
};

export const serviceCallJobSheetOptions = [{ optionData: "Pending" }, { optionData: "Completed" }];

export const serviceCallJobSheetPendingOptions = [
  { optionData: "Service Pending" },
  { optionData: "Replacement Pending" }
];

export const serviceCallProceedWithOptions = [{ optionData: "Replacement" }, { optionData: "Repair" }];

export const MockWallet = [
  { id: 1, isSelect: false, optioData: "Zest money" },
  { id: 1, isSelect: false, optioData: "Amazon pay" },
  { id: 1, isSelect: false, optioData: "PhonePe" },
  { id: 1, isSelect: false, optioData: "Google pay" },
  { id: 1, isSelect: false, optioData: "Paytm" },
  { id: 1, isSelect: false, optioData: "Net banking" }
];

export const PaymentGateway = [
  { id: 1, isSelect: false, optioData: "CC Avenue" },
  { id: 2, isSelect: false, optioData: "RazorPay" },
  { id: 3, isSelect: false, optioData: "Pine PG" }
];

export const MockEnquiryStatus = [
  { id: 1, isSelect: false, optionData: "Open" },
  { id: 2, isSelect: false, optionData: "Pending" },
  { id: 3, isSelect: false, optionData: "Closed" }
];

export const salesReturnMock = [
  { id: 1, isSelect: false, optionData: "SRN" },
  { id: 2, isSelect: false, optionData: "Approval Request" }
];

//slesReturn page
export const availableSubProblem = [
  {
    id: 1,
    isSelect: false,
    optionData: "Mic",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 1,
        id: 101,
        isSelect: false,
        value: "Mic Not Working",
        optionData: "Mic Not Working"
      },
      {
        parentId: 1,
        id: 102,
        isSelect: false,
        value: "Mic low sound",
        optionData: "Mic low sound"
      },
      {
        parentId: 1,
        id: 103,
        isSelect: false,
        value: "Mic Noise issue",
        optionData: "Mic Noise issue"
      }
    ]
  },

  {
    id: 2,
    isSelect: false,
    optionData: "Keypad",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 2,
        id: 201,
        isSelect: false,
        value: "Keypad damage",
        optionData: "Keypad damage"
      },
      {
        parentId: 2,
        id: 202,
        isSelect: false,
        value: "Keypad scratches",
        optionData: "Keypad scratches"
      },
      {
        parentId: 2,
        id: 203,
        isSelect: false,
        value: "keypad fade",
        optionData: "keypad fade"
      },
      {
        parentId: 2,
        id: 204,
        isSelect: false,
        value: "Keypad not working",
        optionData: "Keypad not working"
      }
    ]
  },

  {
    id: 3,
    isSelect: false,
    optionData: "Camera",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 3,
        id: 301,
        isSelect: false,
        value: "Front camera not working",
        optionData: "Front camera not working"
      },
      {
        parentId: 3,
        id: 302,
        isSelect: false,
        value: "Back camera not working",
        optionData: "Back camera not working"
      },
      {
        parentId: 3,
        id: 303,
        isSelect: false,
        value: "Camera lens damage",
        optionData: "Camera lens damage"
      },
      {
        parentId: 3,
        id: 304,
        isSelect: false,
        value: "Camera lens scratches",
        optionData: "Camera lens scratches"
      },
      {
        parentId: 3,
        id: 305,
        isSelect: false,
        value: "Camera image not clear",
        optionData: "Camera image not clear"
      },
      {
        parentId: 3,
        id: 306,
        isSelect: false,
        value: "Camera failed",
        optionData: "Camera failed"
      },
      {
        parentId: 3,
        id: 307,
        isSelect: false,
        value: "Camera not open",
        optionData: "Camera not open"
      },
      {
        parentId: 3,
        id: 308,
        isSelect: false,
        value: "Camera Whicte/Black dot",
        optionData: "Camera Whicte/Black dot"
      },
      {
        parentId: 3,
        id: 309,
        isSelect: false,
        value: "Camera shaking",
        optionData: "Camera shaking"
      },
      {
        parentId: 3,
        id: 310,
        isSelect: false,
        value: "Camera Front/Back Flash light not working",
        optionData: "Camera Front/Back Flash light not working"
      },
      {
        parentId: 3,
        id: 311,
        isSelect: false,
        value: "Camera settings not working",
        optionData: "Camera settings not working"
      },
      {
        parentId: 3,
        id: 312,
        isSelect: false,
        value: "Camera Front/Back Flash light damage",
        optionData: "Camera Front/Back Flash light damage"
      }
    ]
  },

  {
    id: 4,
    isSelect: false,
    optionData: "Speaker",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 4,
        id: 401,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 4,
        id: 402,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 4,
        id: 403,
        isSelect: false,
        value: "Ringtone volume low",
        optionData: "Ringtone volume low"
      },
      {
        parentId: 4,
        id: 404,
        isSelect: false,
        value: "Ringer / Loud Speaker not working",
        optionData: "Ringer / Loud Speaker not working"
      },
      {
        parentId: 4,
        id: 405,
        isSelect: false,
        value: "Less sound from the Ringer",
        optionData: "Less sound from the Ringer"
      },
      {
        parentId: 4,
        id: 406,
        isSelect: false,
        value: "Sound coming from Ringer but with interruption",
        optionData: "Sound coming from Ringer but with interruption"
      },
      {
        parentId: 4,
        id: 407,
        isSelect: false,
        value: "Sound not clear",
        optionData: "Sound not clear"
      }
    ]
  },

  {
    id: 5,
    isSelect: false,
    optionData: "Network",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 5,
        id: 501,
        isSelect: false,
        value: "No network",
        optionData: "No network"
      },
      {
        parentId: 5,
        id: 502,
        isSelect: false,
        value: "Low network",
        optionData: "Low network"
      },
      {
        parentId: 5,
        id: 503,
        isSelect: false,
        value: "3G/4G/5G NOT WORKING",
        optionData: "3G/4G/5G NOT WORKING"
      },
      {
        parentId: 5,
        id: 504,
        isSelect: false,
        value: "Sim 1/Sim 2 network not working",
        optionData: "Sim 1/Sim 2 network not working"
      },
      {
        parentId: 5,
        id: 505,
        isSelect: false,
        value: "Network drops",
        optionData: "Network drops"
      },
      {
        parentId: 5,
        id: 506,
        isSelect: false,
        value: "Data card not working",
        optionData: "Data card not working"
      },
      {
        parentId: 5,
        id: 507,
        isSelect: false,
        value: "Wifi not working",
        optionData: "Wifi not working"
      },
      {
        parentId: 5,
        id: 508,
        isSelect: false,
        value: "Hotspot not working",
        optionData: "Hotspot not working"
      }
    ]
  },

  {
    id: 6,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 6,
        id: 601,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 6,
        id: 602,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 6,
        id: 603,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 6,
        id: 604,
        isSelect: false,
        value: "Application not open ",
        optionData: "Application not open "
      }
    ]
  },

  {
    id: 7,
    isSelect: false,
    optionData: "Phone issue",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 7,
        id: 701,
        isSelect: false,
        value: "Auto call cut",
        optionData: "Auto call cut"
      },
      {
        parentId: 7,
        id: 702,
        isSelect: false,
        value: "Auto disconnect",
        optionData: "Auto disconnect"
      },
      {
        parentId: 7,
        id: 703,
        isSelect: false,
        value: "Contact no not showing ",
        optionData: "Contact no not showing"
      },
      {
        parentId: 7,
        id: 704,
        isSelect: false,
        value: "Dead/No Power On",
        optionData: "Dead/No Power On"
      },
      {
        parentId: 7,
        id: 705,
        isSelect: false,
        value: "Auto on off/restart",
        optionData: "Auto on off/restart"
      },
      {
        parentId: 7,
        id: 706,
        isSelect: false,
        value: "Hanging Issue/Heating Issue",
        optionData: "Hanging Issue/Heating Issue"
      },
      {
        parentId: 7,
        id: 707,
        isSelect: false,
        value: "Airoplane mode not working",
        optionData: "Airoplane mode not working"
      },
      {
        parentId: 7,
        id: 708,
        isSelect: false,
        value: "Application not working",
        optionData: "Application not working"
      }
    ]
  },

  {
    id: 8,
    isSelect: false,
    optionData: "LED Problem",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 8,
        id: 801,
        isSelect: false,
        value: "Display Light not working",
        optionData: "Display Light not working"
      },
      {
        parentId: 8,
        id: 802,
        isSelect: false,
        value: "Backlight Not Working",
        optionData: "Backlight Not Working"
      }
    ]
  },

  {
    id: 9,
    isSelect: false,
    optionData: "Panel issue",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 9,
        id: 901,
        isSelect: false,
        value: "Back door damage",
        optionData: "Back door damage"
      },
      {
        parentId: 9,
        id: 902,
        isSelect: false,
        value: "Back door scratches",
        optionData: "Back door scratches"
      },
      {
        parentId: 9,
        id: 903,
        isSelect: false,
        value: "Front panel scratches",
        optionData: "Front panel scratches"
      },
      {
        parentId: 9,
        id: 904,
        isSelect: false,
        value: "Middle panel damage",
        optionData: "Middle panel damage"
      },
      {
        parentId: 9,
        id: 905,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 9,
        id: 906,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 9,
        id: 907,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 9,
        id: 908,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      },
      {
        parentId: 9,
        id: 909,
        isSelect: false,
        value: "Mobile Full dent",
        optionData: "Mobile Full deat"
      }
    ]
  },

  {
    id: 10,
    isSelect: false,
    optionData: "Auto Rotate",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 10,
        id: 1001,
        isSelect: false,
        value: "Auto rotating issue",
        optionData: "Auto rotating issue"
      }
    ]
  },

  {
    id: 11,
    isSelect: false,
    optionData: "Finger Print",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 11,
        id: 11001,
        isSelect: false,
        value: "Finger print not working",
        optionData: "Finger print not working"
      }
    ]
  },

  {
    id: 13,
    isSelect: false,
    optionData: "Touch Problem",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 13,
        id: 1301,
        isSelect: false,
        value: "Touch Broken",
        optionData: "Touch Broken"
      },
      {
        parentId: 13,
        id: 1302,
        isSelect: false,
        value: "Touch Not Working",
        optionData: "Touch Not Working"
      },
      {
        parentId: 13,
        id: 1303,
        isSelect: false,
        value: "Touch Scratches",
        optionData: "Touch Scratches"
      },
      {
        parentId: 13,
        id: 1304,
        isSelect: false,
        value: "Toch Dent",
        optionData: "Toch Dent"
      },
      {
        parentId: 13,
        id: 1305,
        isSelect: false,
        value: "Touch sensitive low",
        optionData: "Touch sensitive low"
      },
      {
        parentId: 13,
        id: 1306,
        isSelect: false,
        value: "Touch Botom/Upper not working",
        optionData: "Touch Botom/Upper not working"
      },
      {
        parentId: 13,
        id: 1307,
        isSelect: false,
        value: "Touch not fit",
        optionData: "Touch not fit"
      },
      {
        parentId: 13,
        id: 1308,
        isSelect: false,
        value: "Touch Panel issue",
        optionData: "Touch Panel issue"
      }
    ]
  },

  {
    id: 15,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 15,
        id: 1501,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 15,
        id: 1502,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 15,
        id: 1503,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 15,
        id: 1504,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 15,
        id: 1505,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      },
      {
        parentId: 15,
        id: 1506,
        isSelect: false,
        value: "Heating and hanging",
        optionData: "Heating and hanging"
      },
      {
        parentId: 15,
        id: 1507,
        isSelect: false,
        value: "Reverse Charging",
        optionData: "Reverse Charging"
      },
      {
        parentId: 15,
        id: 1508,
        isSelect: false,
        value: "Slow Charging",
        optionData: "Slow Charging"
      },
      {
        parentId: 15,
        id: 1509,
        isSelect: false,
        value: "Auto Charging symbol Issue",
        optionData: "Auto Charging symbol Issue"
      },
      {
        parentId: 15,
        id: 1510,
        isSelect: false,
        value: "Charger Heating",
        optionData: "Charger Heating"
      }
    ]
  },

  {
    id: 17,
    isSelect: false,
    optionData: "Battery problem",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 17,
        id: 1701,
        isSelect: false,
        value: "Battery bludge",
        optionData: "Battery bludge"
      },
      {
        parentId: 17,
        id: 1702,
        isSelect: false,
        value: "Battery backup low ",
        optionData: "Battery backup low"
      },
      {
        parentId: 17,
        id: 1703,
        isSelect: false,
        value: "Battery draining faster",
        optionData: "Battery draining faster"
      },
      {
        parentId: 17,
        id: 1704,
        isSelect: false,
        value: "Battery not fit",
        optionData: "Battery not fit"
      },
      {
        parentId: 17,
        id: 1705,
        isSelect: false,
        value: "Battery not full",
        optionData: "Battery not full"
      },
      {
        parentId: 17,
        id: 1706,
        isSelect: false,
        value: "Battery damage/Pin damage",
        optionData: "Battery damage/Pin damage"
      },
      {
        parentId: 17,
        id: 1707,
        isSelect: false,
        value: "Battery Mismatch",
        optionData: "Battery Mismatch"
      },
      {
        parentId: 17,
        id: 1708,
        isSelect: false,
        value: "Battery not charging",
        optionData: "Battery not charging"
      }
    ]
  },

  {
    id: 18,
    isSelect: false,
    optionData: "Heating & Hanging",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 18,
        id: 1801,
        isSelect: false,
        value: "Over heating",
        optionData: "Over heating"
      },
      {
        parentId: 18,
        id: 1802,
        isSelect: false,
        value: "Logo hanging",
        optionData: "Logo hanging"
      },
      {
        parentId: 18,
        id: 1803,
        isSelect: false,
        value: "Heating Problem",
        optionData: "Heating Problem"
      }
    ]
  },

  {
    id: 19,
    isSelect: false,
    optionData: "SIM Related Issue",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 19,
        id: 1901,
        isSelect: false,
        value: "Sim 1 not working",
        optionData: "Sim 1 not working"
      },
      {
        parentId: 19,
        id: 1902,
        isSelect: false,
        value: "Sim 2 not working",
        optionData: "Sim 2 not working"
      },
      {
        parentId: 19,
        id: 1903,
        isSelect: false,
        value: "1 Sim slot Pin Damage",
        optionData: "1 Sim slot Pin Damage"
      },
      {
        parentId: 19,
        id: 1904,
        isSelect: false,
        value: "2 Sim slot pin Damage",
        optionData: "2 Sim slot pin Damage"
      },
      {
        parentId: 19,
        id: 1905,
        isSelect: false,
        value: "Sim card not detected",
        optionData: "Sim card not detected"
      },
      {
        parentId: 19,
        id: 1906,
        isSelect: false,
        value: "Sim 1/2 Insert sim",
        optionData: "Sim 1/2 Insert sim"
      },
      {
        parentId: 19,
        id: 1907,
        isSelect: false,
        value: "Sim 1/2 Data connection issue",
        optionData: "Sim 1/2 Data connection issue"
      },
      {
        parentId: 19,
        id: 1908,
        isSelect: false,
        value: "Dual Sim not working",
        optionData: "Dual Sim not working"
      }
    ]
  },

  {
    id: 20,
    isSelect: false,
    optionData: "Facelock / Face ID",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 20,
        id: 2001,
        isSelect: false,
        value: "Face lock not working",
        optionData: "Face lock not working"
      }
    ]
  },

  {
    id: 21,
    isSelect: false,
    optionData: "Receiver",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 21,
        id: 2101,
        isSelect: false,
        value: "low receiver volume",
        optionData: "low receiver volume"
      },
      {
        parentId: 21,
        id: 2102,
        isSelect: false,
        value: "Receiver Noice issue",
        optionData: "Receiver Noice issue"
      },
      {
        parentId: 21,
        id: 2103,
        isSelect: false,
        value: "Receiver filter missing",
        optionData: "Receiver filter missing"
      },
      {
        parentId: 21,
        id: 2104,
        isSelect: false,
        value: "Receiver not Working",
        optionData: "Receiver not Working"
      }
    ]
  },

  {
    id: 26,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 26,
        id: 2601,
        isSelect: false,
        value: "Vibration mode not working",
        optionData: "Vibration mode not working"
      },
      {
        parentId: 26,
        id: 2602,
        isSelect: false,
        value: "Silent mode not working",
        optionData: "Silent mode not working"
      },
      {
        parentId: 26,
        id: 2603,
        isSelect: false,
        value: "Volume Up/Down button not working",
        optionData: "Volume Up/Down button not working"
      },
      {
        parentId: 26,
        id: 2604,
        isSelect: false,
        value: "Location option not working",
        optionData: "Location option not working"
      },
      {
        parentId: 26,
        id: 2605,
        isSelect: false,
        value: "Application issue",
        optionData: "Application issue"
      }
    ]
  },

  {
    id: 22,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 21,
        id: 2201,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 22,
        id: 2202,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 22,
        id: 2203,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 22,
        id: 2204,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 22,
        id: 2205,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 22,
        id: 2206,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 22,
        id: 2207,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 22,
        id: 2208,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 23,
    isSelect: false,
    optionData: "Flash Light",
    itemGrupName: "M-Mobile",
    value: [
      {
        parentId: 23,
        id: 2301,
        isSelect: false,
        value: "Flash light not working",
        optionData: "Flash light not working"
      },
      {
        parentId: 23,
        id: 2302,
        isSelect: false,
        value: "Flash light not damage",
        optionData: "Flash light not damage"
      },
      {
        parentId: 23,
        id: 2303,
        isSelect: false,
        value: "Flash light not scratches",
        optionData: "Flash light not scratches"
      }
    ]
  },

  {
    id: 24,
    isSelect: false,
    optionData: "Touch Problem",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 24,
        id: 2401,
        isSelect: false,
        value: "Touch Broken",
        optionData: "Touch Broken"
      },
      {
        parentId: 24,
        id: 2402,
        isSelect: false,
        value: "Touch Not Working",
        optionData: "Touch Not Working"
      },
      {
        parentId: 24,
        id: 2403,
        isSelect: false,
        value: "Touch Scratches",
        optionData: "Touch Scratches"
      },
      {
        parentId: 24,
        id: 2404,
        isSelect: false,
        value: "Toch Dent",
        optionData: "Toch Dent"
      },
      {
        parentId: 24,
        id: 2405,
        isSelect: false,
        value: "Touch sensitive low",
        optionData: "Touch sensitive low"
      },
      {
        parentId: 24,
        id: 2406,
        isSelect: false,
        value: "Touch Botom/Upper not working",
        optionData: "Touch Bottom/Upper not working"
      },
      {
        parentId: 24,
        id: 2407,
        isSelect: false,
        value: "Touch not fit",
        optionData: "Touch not fit"
      },
      {
        parentId: 24,
        id: 2408,
        isSelect: false,
        value: "Touch Panel issue",
        optionData: "Touch Panel issue"
      }
    ]
  },

  {
    id: 25,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 25,
        id: 2501,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 25,
        id: 2502,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 25,
        id: 2503,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 25,
        id: 2504,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 25,
        id: 2505,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 25,
        id: 2506,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 25,
        id: 2507,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 25,
        id: 2508,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 26,
    isSelect: false,
    optionData: "Battery problem",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 26,
        id: 2601,
        isSelect: false,
        value: "Battery buldge",
        optionData: "Battery buldge"
      },
      {
        parentId: 26,
        id: 2602,
        isSelect: false,
        value: "Battery backup low ",
        optionData: "Battery backup low"
      },
      {
        parentId: 26,
        id: 2603,
        isSelect: false,
        value: "Battery draining faster",
        optionData: "Battery draining faster"
      },
      {
        parentId: 26,
        id: 2604,
        isSelect: false,
        value: "Battery not fit",
        optionData: "Battery not fit"
      },
      {
        parentId: 26,
        id: 2605,
        isSelect: false,
        value: "Battery not full",
        optionData: "Battery not full"
      },
      {
        parentId: 26,
        id: 2606,
        isSelect: false,
        value: "Battery damage/Pin damage",
        optionData: "Battery damage/Pin damage"
      },
      {
        parentId: 26,
        id: 2607,
        isSelect: false,
        value: "Battery Mismatch",
        optionData: "Battery Mismatch"
      },
      {
        parentId: 26,
        id: 2608,
        isSelect: false,
        value: "Battery not charging",
        optionData: "Battery not charging"
      }
    ]
  },

  {
    id: 27,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 27,
        id: 2701,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 27,
        id: 2702,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 27,
        id: 2703,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 27,
        id: 2704,
        isSelect: false,
        value: "Application not open ",
        optionData: "Application not open"
      }
    ]
  },

  {
    id: 28,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 28,
        id: 2801,
        isSelect: false,
        value: "Not charging",
        optionData: "Not charging"
      },
      {
        parentId: 28,
        id: 2802,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 28,
        id: 2803,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 28,
        id: 2804,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 28,
        id: 2805,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 28,
        id: 2806,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      }
    ]
  },

  {
    id: 29,
    isSelect: false,
    optionData: "Heating & Hanging",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 29,
        id: 2901,
        isSelect: false,
        value: "Over heating",
        optionData: "Over heating"
      },
      {
        parentId: 29,
        id: 2902,
        isSelect: false,
        value: "Logo hanging",
        optionData: "Logo hanging"
      },
      {
        parentId: 29,
        id: 2903,
        isSelect: false,
        value: "Heating Problem",
        optionData: "Heating Problem"
      }
    ]
  },

  {
    id: 30,
    isSelect: false,
    optionData: "Others Issues",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 30,
        id: 3001,
        isSelect: false,
        value: "Vibration mode not working",
        optionData: "Vibration mode not working"
      },
      {
        parentId: 30,
        id: 3002,
        isSelect: false,
        value: "Silent mode not working",
        optionData: "Silent mode not working"
      },
      {
        parentId: 30,
        id: 3003,
        isSelect: false,
        value: "Volume Up/Down button not working",
        optionData: "Volume Up/Down button not working"
      },
      {
        parentId: 30,
        id: 3004,
        isSelect: false,
        value: "Application issue",
        optionData: "Application issue"
      }
    ]
  },

  {
    id: 31,
    isSelect: false,
    optionData: "Touch Problem",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 31,
        id: 3101,
        isSelect: false,
        value: "Touch Broken",
        optionData: "Touch Broken"
      },
      {
        parentId: 31,
        id: 3102,
        isSelect: false,
        value: "Touch Not Working",
        optionData: "Touch Not Working"
      },
      {
        parentId: 31,
        id: 3103,
        isSelect: false,
        value: "Touch Scratches",
        optionData: "Touch Scratches"
      },
      {
        parentId: 31,
        id: 3104,
        isSelect: false,
        value: "Toch Dent",
        optionData: "Toch Dent"
      },
      {
        parentId: 31,
        id: 3105,
        isSelect: false,
        value: "Touch sensitive low",
        optionData: "Touch sensitive low"
      },
      {
        parentId: 31,
        id: 3106,
        isSelect: false,
        value: "Touch Botom/Upper not working",
        optionData: "Touch Botom/Upper not working"
      },
      {
        parentId: 31,
        id: 3107,
        isSelect: false,
        value: "Touch not fit",
        optionData: "Touch not fit"
      },
      {
        parentId: 31,
        id: 3108,
        isSelect: false,
        value: "Touch Panel issue",
        optionData: "Touch Panel issue"
      }
    ]
  },

  {
    id: 32,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 32,
        id: 3201,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 32,
        id: 3202,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 32,
        id: 3203,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 32,
        id: 3204,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 32,
        id: 3205,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 32,
        id: 3206,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 32,
        id: 3207,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 32,
        id: 3208,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 33,
    isSelect: false,
    optionData: "Battery problem",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 33,
        id: 3301,
        isSelect: false,
        value: "Battery bludge",
        optionData: "Battery bludge"
      },
      {
        parentId: 33,
        id: 3302,
        isSelect: false,
        value: "Battery backup low ",
        optionData: "Battery backup low"
      },
      {
        parentId: 33,
        id: 3303,
        isSelect: false,
        value: "Battery draining faster",
        optionData: "Battery draining faster"
      },
      {
        parentId: 33,
        id: 3304,
        isSelect: false,
        value: "Battery not fit",
        optionData: "Battery not fit"
      },
      {
        parentId: 33,
        id: 3305,
        isSelect: false,
        value: "Battery not full",
        optionData: "Battery not full"
      },
      {
        parentId: 33,
        id: 3306,
        isSelect: false,
        value: "Battery damage/Pin damage",
        optionData: "Battery damage/Pin damage"
      },
      {
        parentId: 33,
        id: 3307,
        isSelect: false,
        value: "Battery Mismatch",
        optionData: "Battery Mismatch"
      },
      {
        parentId: 33,
        id: 3308,
        isSelect: false,
        value: "Battery not charging",
        optionData: "Battery not charging"
      }
    ]
  },

  {
    id: 34,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 34,
        id: 3401,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 34,
        id: 3402,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 34,
        id: 3403,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 34,
        id: 3404,
        isSelect: false,
        value: "Application not open ",
        optionData: "Application not open"
      }
    ]
  },

  {
    id: 35,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 35,
        id: 3501,
        isSelect: false,
        value: "Not charging",
        optionData: "Not charging"
      },
      {
        parentId: 35,
        id: 3501,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 35,
        id: 3501,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 35,
        id: 3501,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 35,
        id: 3501,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 35,
        id: 3501,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      }
    ]
  },

  {
    id: 36,
    isSelect: false,
    optionData: "Heating & Hanging",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 36,
        id: 3601,
        isSelect: false,
        value: "Over heating",
        optionData: "Over heating"
      },
      {
        parentId: 36,
        id: 3602,
        isSelect: false,
        value: "Logo hanging",
        optionData: "Logo hanging"
      },
      {
        parentId: 36,
        id: 3603,
        isSelect: false,
        value: "Heating Problem",
        optionData: "Heating Problem"
      }
    ]
  },

  {
    id: 37,
    isSelect: false,
    optionData: "Others Issues",
    itemGrupName: "M-Apple Watch Band",
    value: [
      {
        parentId: 37,
        id: 3701,
        isSelect: false,
        value: "Vibration mode not working",
        optionData: "Vibration mode not working"
      },
      {
        parentId: 37,
        id: 3702,
        isSelect: false,
        value: "Silent mode not working",
        optionData: "Silent mode not working"
      },
      {
        parentId: 37,
        id: 3703,
        isSelect: false,
        value: "Volume Up/Down button not working",
        optionData: "Volume Up/Down button not working"
      },
      {
        parentId: 37,
        id: 3704,
        isSelect: false,
        value: "Application issue",
        optionData: "Application issue"
      }
    ]
  },

  {
    id: 38,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Bluetooth",
    value: [
      {
        parentId: 38,
        id: 3801,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 38,
        id: 3802,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 38,
        id: 3803,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 38,
        id: 3804,
        isSelect: false,
        value: "Application not open",
        optionData: "Application not open"
      }
    ]
  },

  {
    id: 39,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-Neckband BT",
    value: [
      {
        parentId: 39,
        id: 3901,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 39,
        id: 3902,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 39,
        id: 3903,
        isSelect: false,
        value: "Volume low",
        optionData: "Volume low"
      },
      {
        parentId: 39,
        id: 3904,
        isSelect: false,
        value: "Sound not clear",
        optionData: "Sound not clear"
      },
      {
        parentId: 39,
        id: 3905,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 39,
        id: 3906,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 39,
        id: 3907,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      }
    ]
  },

  {
    id: 40,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-Neckband BT",
    value: [
      {
        parentId: 40,
        id: 4001,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 40,
        id: 4002,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 40,
        id: 4003,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 41,
    isSelect: false,
    optionData: "Volume Key",
    itemGrupName: "M-Neckband BT",
    value: [
      {
        parentId: 41,
        id: 4101,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 41,
        id: 4102,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 41,
        id: 4103,
        isSelect: false,
        value: "Volume button not working",
        optionData: "Volume button not working"
      }
    ]
  },

  {
    id: 42,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Neckband BT",
    value: [
      {
        parentId: 42,
        id: 4201,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 42,
        id: 4202,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 42,
        id: 4203,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 42,
        id: 4204,
        isSelect: false,
        value: "Application not open",
        optionData: "Application not open"
      }
    ]
  },

  {
    id: 43,
    isSelect: false,
    optionData: "USB Pin",
    itemGrupName: "M-Neckband BT",
    value: [
      {
        parentId: 43,
        id: 4301,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 43,
        id: 4302,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 44,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-True Wireless (TWS)",
    value: [
      {
        parentId: 44,
        id: 4401,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 44,
        id: 4402,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 44,
        id: 4403,
        isSelect: false,
        value: "Volume low",
        optionData: "Volume low"
      },
      {
        parentId: 44,
        id: 4404,
        isSelect: false,
        value: "Sound not clear",
        optionData: "Sound not clear"
      },
      {
        parentId: 44,
        id: 4405,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 44,
        id: 4406,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 44,
        id: 4407,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      }
    ]
  },

  {
    id: 45,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-True Wireless (TWS)",
    value: [
      {
        parentId: 45,
        id: 4501,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 45,
        id: 4502,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 45,
        id: 4503,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 46,
    isSelect: false,
    optionData: "Volume Key",
    itemGrupName: "M-True Wireless (TWS)",
    value: [
      {
        parentId: 46,
        id: 4601,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 46,
        id: 4602,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 46,
        id: 4603,
        isSelect: false,
        value: "Volume button not working",
        optionData: "Volume button not working"
      }
    ]
  },

  {
    id: 47,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-True Wireless (TWS)",
    value: [
      {
        parentId: 47,
        id: 4701,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 47,
        id: 4702,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 47,
        id: 4703,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 47,
        id: 4704,
        isSelect: false,
        value: "Application not open",
        optionData: "Application not open"
      }
    ]
  },

  {
    id: 48,
    isSelect: false,
    optionData: "USB Pin",
    itemGrupName: "M-True Wireless (TWS)",
    value: [
      {
        parentId: 48,
        id: 4801,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 48,
        id: 4802,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 49,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-Ear Buds",
    value: [
      {
        parentId: 49,
        id: 4901,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 49,
        id: 4902,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 49,
        id: 4903,
        isSelect: false,
        value: "Volume low",
        optionData: "Volume low"
      },
      {
        parentId: 49,
        id: 4904,
        isSelect: false,
        value: "Sound not clear",
        optionData: "Sound not clear"
      },
      {
        parentId: 49,
        id: 4905,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 49,
        id: 4906,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 49,
        id: 4907,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      }
    ]
  },

  {
    id: 50,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-Ear Buds",
    value: [
      {
        parentId: 50,
        id: 5001,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 50,
        id: 5002,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 50,
        id: 5003,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 51,
    isSelect: false,
    optionData: "Volume Key",
    itemGrupName: "M-Ear Buds",
    value: [
      {
        parentId: 51,
        id: 5101,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      },
      {
        parentId: 51,
        id: 5102,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 51,
        id: 5103,
        isSelect: false,
        value: "Volume button not working",
        optionData: "Volume button not working"
      }
    ]
  },

  {
    id: 52,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Ear Buds",
    value: [
      {
        parentId: 52,
        id: 5201,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 52,
        id: 5202,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 52,
        id: 5203,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      },
      {
        parentId: 52,
        id: 5204,
        isSelect: false,
        value: "Application not open",
        optionData: "Application not open"
      }
    ]
  },

  {
    id: 53,
    isSelect: false,
    optionData: "USB Pin",
    itemGrupName: "M-Ear Buds",
    value: [
      {
        parentId: 53,
        id: 5301,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 53,
        id: 5302,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 54,
    isSelect: false,
    optionData: "Panel",
    itemGrupName: "A-Back Case",
    value: [
      {
        parentId: 54,
        id: 5401,
        isSelect: false,
        value: "Not fit for mobile",
        optionData: "Not fit for mobile"
      }
    ]
  },

  {
    id: 55,
    isSelect: false,
    optionData: "Battery",
    itemGrupName: "A-Battery",
    value: [
      {
        parentId: 55,
        id: 5501,
        isSelect: false,
        value: "Battery buldge",
        optionData: "Battery buldge"
      },
      {
        parentId: 55,
        id: 5502,
        isSelect: false,
        value: "Battery backup low",
        optionData: "Battery backup low"
      },
      {
        parentId: 55,
        id: 5503,
        isSelect: false,
        value: "Battery draining faster",
        optionData: "Battery draining faster"
      },
      {
        parentId: 55,
        id: 5504,
        isSelect: false,
        value: "Battery not fit",
        optionData: "Battery not fit"
      },
      {
        parentId: 55,
        id: 5505,
        isSelect: false,
        value: "Battery % not full",
        optionData: "Battery % not full"
      },
      {
        parentId: 55,
        id: 5506,
        isSelect: false,
        value: "Battery damage/Pin damage",
        optionData: "Battery damage/Pin damage"
      },
      {
        parentId: 55,
        id: 5506,
        isSelect: false,
        value: "Battery Mismatch",
        optionData: "Battery Mismatch"
      },
      {
        parentId: 55,
        id: 5507,
        isSelect: false,
        value: "Battery not charging",
        optionData: "Battery not charging"
      }
    ]
  },

  {
    id: 56,
    isSelect: false,
    optionData: "Charger Issue",
    itemGrupName: "A-Charger",
    value: [
      {
        parentId: 56,
        id: 5601,
        isSelect: false,
        value: "Not charging",
        optionData: "Not charging"
      },
      {
        parentId: 56,
        id: 5602,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 56,
        id: 5603,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 56,
        id: 5604,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 56,
        id: 5605,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 56,
        id: 5606,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      }
    ]
  },

  {
    id: 57,
    isSelect: false,
    optionData: "Charger Issue",
    itemGrupName: "A-Data Cable",
    value: [
      {
        parentId: 57,
        id: 5701,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 57,
        id: 5702,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 57,
        id: 5703,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      }
    ]
  },

  {
    id: 58,
    isSelect: false,
    optionData: "Headset issue",
    itemGrupName: "A-Head Phone",
    value: [
      {
        parentId: 58,
        id: 5801,
        isSelect: false,
        value: "Left side not working",
        optionData: "Left side not working"
      },
      {
        parentId: 58,
        id: 5802,
        isSelect: false,
        value: "Right side not working",
        optionData: "Right side not working"
      },
      {
        parentId: 58,
        id: 5803,
        isSelect: false,
        value: "Mic problem",
        optionData: "Mic problem"
      },
      {
        parentId: 58,
        id: 5804,
        isSelect: false,
        value: "Noise while in call",
        optionData: "Noise while in call"
      },
      {
        parentId: 58,
        id: 5805,
        isSelect: false,
        value: "Answer Button problem",
        optionData: "Answer Button problem"
      },
      {
        parentId: 58,
        id: 5806,
        isSelect: false,
        value: "Wire damage",
        optionData: "Wire damage"
      }
    ]
  },

  {
    id: 59,
    isSelect: false,
    optionData: "Pouch Issue",
    itemGrupName: "A-Pouch",
    value: [
      {
        parentId: 59,
        id: 5901,
        isSelect: false,
        value: "Not fit for mobile",
        optionData: "Not fit for mobile"
      },
      {
        parentId: 59,
        id: 5902,
        isSelect: false,
        value: "Color Fade",
        optionData: "Color Fade"
      },
      {
        parentId: 59,
        id: 5903,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      }
    ]
  },

  {
    id: 60,
    isSelect: false,
    optionData: "Screen Guard",
    itemGrupName: "A-Protection Pro",
    value: [
      {
        parentId: 60,
        id: 6001,
        isSelect: false,
        value: "Not fit for mobile",
        optionData: "Not fit for mobile"
      },
      {
        parentId: 60,
        id: 6002,
        isSelect: false,
        value: "Bubbles form",
        optionData: "Bubbles form"
      },
      {
        parentId: 60,
        id: 6003,
        isSelect: false,
        value: "Damage",
        optionData: "Damage"
      }
    ]
  },

  {
    id: 61,
    isSelect: false,
    optionData: "Screen Guard",
    itemGrupName: "A-Screen Guard",
    value: [
      {
        parentId: 61,
        id: 6101,
        isSelect: false,
        value: "Not fit for mobile",
        optionData: "Not fit for mobile"
      },
      {
        parentId: 61,
        id: 6102,
        isSelect: false,
        value: "Bubbles form",
        optionData: "Bubbles form"
      },
      {
        parentId: 61,
        id: 6103,
        isSelect: false,
        value: "Damage",
        optionData: "Damage"
      }
    ]
  },

  {
    id: 62,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "A-Connector",
    value: [
      {
        parentId: 62,
        id: 6201,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 62,
        id: 6202,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      }
    ]
  },

  {
    id: 63,
    isSelect: false,
    optionData: "Usb Pin",
    itemGrupName: "A-Pendrive",
    value: [
      {
        parentId: 63,
        id: 6301,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 63,
        id: 6302,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      },
      {
        parentId: 63,
        id: 6303,
        isSelect: false,
        value: "Not Connected",
        optionData: "Not Connected"
      }
    ]
  },

  {
    id: 64,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "A-Selfie Stick",
    value: [
      {
        parentId: 64,
        id: 6401,
        isSelect: false,
        value: "Button damage",
        optionData: "Connection Problem"
      },
      {
        parentId: 64,
        id: 6402,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      }
    ]
  },

  {
    id: 65,
    isSelect: false,
    optionData: "Headset issue",
    itemGrupName: "G-Boom Headset",
    value: [
      {
        parentId: 65,
        id: 6501,
        isSelect: false,
        value: "Left side not working",
        optionData: "Left side not working"
      },
      {
        parentId: 65,
        id: 6502,
        isSelect: false,
        value: "Right side not working",
        optionData: "Right side not working"
      },
      {
        parentId: 65,
        id: 6503,
        isSelect: false,
        value: "One side volume low",
        optionData: "One side volume low"
      },
      {
        parentId: 65,
        id: 6504,
        isSelect: false,
        value: "Mic problem",
        optionData: "Mic problem"
      },
      {
        parentId: 65,
        id: 6505,
        isSelect: false,
        value: "Noise while in call",
        optionData: "Noise while in call"
      },
      {
        parentId: 65,
        id: 6506,
        isSelect: false,
        value: "Answer Button problem",
        optionData: "Answer Button problem"
      },
      {
        parentId: 65,
        id: 6507,
        isSelect: false,
        value: "Wire damage",
        optionData: "Wire damage"
      }
    ]
  },

  {
    id: 66,
    isSelect: false,
    optionData: "Speaker Problem",
    itemGrupName: "G-BT Speaker",
    value: [
      {
        parentId: 66,
        id: 6601,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 66,
        id: 6602,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 66,
        id: 6603,
        isSelect: false,
        value: "Ringer / Loud Speaker not working",
        optionData: "Ringer / Loud Speaker not working"
      },
      {
        parentId: 66,
        id: 6604,
        isSelect: false,
        value: "Less sound from the Ringer",
        optionData: "Less sound from the Ringer"
      },
      {
        parentId: 66,
        id: 6605,
        isSelect: false,
        value: "Sound coming from Ringer but with interruption",
        optionData: "Sound coming from Ringer but with interruption"
      },
      {
        parentId: 66,
        id: 6606,
        isSelect: false,
        value: "Sound not clear",
        optionData: "Sound not clear"
      }
    ]
  },

  {
    id: 67,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Ear Buds",
    value: [
      {
        parentId: 67,
        id: 6701,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 67,
        id: 6702,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 67,
        id: 6703,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 68,
    isSelect: false,
    optionData: "Speaker Problem",
    itemGrupName: "G-Soundbar",
    value: [
      {
        parentId: 68,
        id: 6801,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 68,
        id: 6802,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 68,
        id: 6803,
        isSelect: false,
        value: "Ringer / Loud Speaker not working",
        optionData: "Ringer / Loud Speaker not working"
      },
      {
        parentId: 68,
        id: 6804,
        isSelect: false,
        value: "Less sound from the Ringer",
        optionData: "Less sound from the Ringer"
      },
      {
        parentId: 68,
        id: 6805,
        isSelect: false,
        value: "Sound coming from Ringer but with interruption",
        optionData: "Sound coming from Ringer but with interruption"
      },
      {
        parentId: 68,
        id: 6806,
        isSelect: false,
        value: "Sound not clear",
        optionData: "Sound not clear"
      }
    ]
  },

  {
    id: 69,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "G-Soundbar",
    value: [
      {
        parentId: 69,
        id: 6901,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 69,
        id: 6902,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 69,
        id: 6903,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 70,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "G-Fit Band",
    value: [
      {
        parentId: 70,
        id: 7001,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 70,
        id: 7002,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 70,
        id: 7003,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 71,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "G-Smart Watch",
    value: [
      {
        parentId: 71,
        id: 7101,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 71,
        id: 7102,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 71,
        id: 7103,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 71,
        id: 7104,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 71,
        id: 7105,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 71,
        id: 7106,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 71,
        id: 7107,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 71,
        id: 7108,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 72,
    isSelect: false,
    optionData: "Speaker Problem",
    itemGrupName: "G-IOT Speakers",
    value: [
      {
        parentId: 72,
        id: 7201,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 72,
        id: 7202,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      }
    ]
  },

  {
    id: 73,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "G-IOT Speakers",
    value: [
      {
        parentId: 73,
        id: 7301,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 73,
        id: 7302,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 73,
        id: 7303,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 74,
    isSelect: false,
    optionData: "Charger Issue",
    itemGrupName: "G-IOT Speakers",
    value: [
      {
        parentId: 74,
        id: 7401,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 74,
        id: 7402,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 74,
        id: 7403,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      }
    ]
  },

  {
    id: 75,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "G-IOT Smart",
    value: [
      {
        parentId: 75,
        id: 7501,
        isSelect: false,
        value: "Bluetooth not pair",
        optionData: "Bluetooth not pair"
      },
      {
        parentId: 75,
        id: 7502,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 75,
        id: 7503,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 76,
    isSelect: false,
    optionData: "Charger Issue",
    itemGrupName: "G-IOT Smart",
    value: [
      {
        parentId: 76,
        id: 7601,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 76,
        id: 7602,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 76,
        id: 7603,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      }
    ]
  },

  {
    id: 77,
    isSelect: false,
    optionData: "Speaker Problem",
    itemGrupName: "G-Projector",
    value: [
      {
        parentId: 77,
        id: 7701,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 77,
        id: 7702,
        isSelect: false,
        value: "VOLUME LOW",
        optionData: "Volume Low"
      }
    ]
  },

  {
    id: 78,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "G-Projector",
    value: [
      {
        parentId: 78,
        id: 7801,
        isSelect: false,
        value: "Blank Display",
        optionData: "Blank Display"
      }
    ]
  },

  {
    id: 79,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "G-Projector",
    value: [
      {
        parentId: 79,
        id: 7901,
        isSelect: false,
        value: "Application Issue",
        optionData: "Application Issue"
      }
    ]
  },

  {
    id: 80,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "M-Memory Card",
    value: [
      {
        parentId: 80,
        id: 8001,
        isSelect: false,
        value: "Not Reading",
        optionData: "Not Reading"
      },
      {
        parentId: 80,
        id: 8002,
        isSelect: false,
        value: "Card Damage",
        optionData: "Card Damage"
      }
    ]
  },

  {
    id: 81,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Power Bank",
    value: [
      {
        parentId: 81,
        id: 8101,
        isSelect: false,
        value: "Not Charging",
        optionData: "Not Charging"
      },
      {
        parentId: 81,
        id: 8102,
        isSelect: false,
        value: "Charging Pin Damage",
        optionData: "Charging Pin Damage"
      }
    ]
  },

  {
    id: 82,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Wireless Charger",
    value: [
      {
        parentId: 82,
        id: 8201,
        isSelect: false,
        value: "Not Charging",
        optionData: "Not Charging"
      },
      {
        parentId: 82,
        id: 8201,
        isSelect: false,
        value: "Slow Charging",
        optionData: "Slow Charging"
      },
      {
        parentId: 82,
        id: 8201,
        isSelect: false,
        value: "Charging Pin Damage",
        optionData: "Charging Pin Damage"
      },
      {
        parentId: 82,
        id: 8201,
        isSelect: false,
        value: "Charger Heating Issue",
        optionData: "Charger Heating Issue"
      }
    ]
  },

  {
    id: 83,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 83,
        id: 8301,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 83,
        id: 8302,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 83,
        id: 8303,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 83,
        id: 8304,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 83,
        id: 8305,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 83,
        id: 8306,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 83,
        id: 8307,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 83,
        id: 8308,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 84,
    isSelect: false,
    optionData: "Panel issue",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 84,
        id: 8401,
        isSelect: false,
        value: "Back door scratches",
        optionData: "Back door scratches"
      },
      {
        parentId: 84,
        id: 8402,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 84,
        id: 8403,
        isSelect: false,
        value: "Front/Middle/Back panel scratches",
        optionData: "Front/Middle/Back panel scratches"
      },
      {
        parentId: 84,
        id: 8404,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      },
      {
        parentId: 84,
        id: 8405,
        isSelect: false,
        value: "Front/Middle/Back panel damage",
        optionData: "Front/Middle/Back panel damage"
      },
      {
        parentId: 84,
        id: 8406,
        isSelect: false,
        value: "Back door damage",
        optionData: "Back door damage"
      },
      {
        parentId: 84,
        id: 8407,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      }
    ]
  },

  {
    id: 85,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 85,
        id: 8401,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 85,
        id: 8402,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 85,
        id: 8403,
        isSelect: false,
        value: "Volume Low",
        optionData: "Volume Low"
      },
      {
        parentId: 85,
        id: 8404,
        isSelect: false,
        value: "Sound Not Clear",
        optionData: "Sound Not Clear"
      },
      {
        parentId: 85,
        id: 8405,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 85,
        id: 8406,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      },
      {
        parentId: 85,
        id: 8407,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      }
    ]
  },

  {
    id: 86,
    isSelect: false,
    optionData: "LED Problem",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 86,
        id: 8601,
        isSelect: false,
        value: "Display Light not working",
        optionData: "Display Light not working"
      },
      {
        parentId: 86,
        id: 8602,
        isSelect: false,
        value: "Backlight Not Working",
        optionData: "Backlight Not Working"
      },
      {
        parentId: 86,
        id: 8603,
        isSelect: false,
        value: "Left side light not working",
        optionData: "Left side light not working"
      }
    ]
  },

  {
    id: 87,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 87,
        id: 8701,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 87,
        id: 8702,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 87,
        id: 8703,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 88,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 88,
        id: 8801,
        isSelect: false,
        value: "Bluetooth not pairing",
        optionData: "Bluetooth not pairing"
      },
      {
        parentId: 88,
        id: 8802,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 88,
        id: 8803,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 89,
    isSelect: false,
    optionData: "WIFI Issue",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 88,
        id: 8901,
        isSelect: false,
        value: "Wifi not connecting",
        optionData: "Wifi not connecting"
      }
    ]
  },

  {
    id: 90,
    isSelect: false,
    optionData: "Power Issue",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 90,
        id: 9001,
        isSelect: false,
        value: "No power ON",
        optionData: "No power ON"
      },
      {
        parentId: 90,
        id: 9002,
        isSelect: false,
        value: "Dead",
        optionData: "Dead"
      }
    ]
  },

  {
    id: 91,
    isSelect: false,
    optionData: "USB",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 91,
        id: 9101,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 91,
        id: 9102,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      },
      {
        parentId: 91,
        id: 9103,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 92,
    isSelect: false,
    optionData: "Sensor issue",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 92,
        id: 9201,
        isSelect: false,
        value: "Remote Sensor not working",
        optionData: "Remote Sensor not working"
      }
    ]
  },

  {
    id: 93,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 93,
        id: 9301,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 93,
        id: 9302,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      },
      {
        parentId: 93,
        id: 9303,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 93,
        id: 9304,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 93,
        id: 9305,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 93,
        id: 9306,
        isSelect: false,
        value: "Reverse Charging",
        optionData: "Reverse Charging"
      },
      {
        parentId: 93,
        id: 9307,
        isSelect: false,
        value: "Slow Charging",
        optionData: "Slow Charging"
      },
      {
        parentId: 93,
        id: 9306,
        isSelect: false,
        value: "Auto Charging symbol Issue",
        optionData: "Auto Charging symbol Issue"
      },
      {
        parentId: 93,
        id: 9307,
        isSelect: false,
        value: "Charger Heating",
        optionData: "Charger Heating"
      }
    ]
  },

  {
    id: 94,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 94,
        id: 8301,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 94,
        id: 8302,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 94,
        id: 8303,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 94,
        id: 8304,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 94,
        id: 8305,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 94,
        id: 8306,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 94,
        id: 8307,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 94,
        id: 8308,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 95,
    isSelect: false,
    optionData: "Panel issue",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 95,
        id: 9501,
        isSelect: false,
        value: "Back door scratches",
        optionData: "Back door scratches"
      },
      {
        parentId: 95,
        id: 9502,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 95,
        id: 9503,
        isSelect: false,
        value: "Front/Middle/Back panel scratches",
        optionData: "Front/Middle/Back panel scratches"
      },
      {
        parentId: 95,
        id: 9504,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      },
      {
        parentId: 95,
        id: 9505,
        isSelect: false,
        value: "Front/Middle/Back panel damage",
        optionData: "Front/Middle/Back panel damage"
      },
      {
        parentId: 95,
        id: 9506,
        isSelect: false,
        value: "Back door damage",
        optionData: "Back door damage"
      },
      {
        parentId: 95,
        id: 9507,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      }
    ]
  },

  {
    id: 96,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 96,
        id: 9601,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 96,
        id: 9602,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 96,
        id: 9603,
        isSelect: false,
        value: "Volume Low",
        optionData: "Volume Low"
      },
      {
        parentId: 96,
        id: 9604,
        isSelect: false,
        value: "Sound Not Clear",
        optionData: "Sound Not Clear"
      },
      {
        parentId: 96,
        id: 9605,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 96,
        id: 9606,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      },
      {
        parentId: 96,
        id: 9607,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      }
    ]
  },

  {
    id: 97,
    isSelect: false,
    optionData: "LED Problem",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 97,
        id: 9701,
        isSelect: false,
        value: "Display Light not working",
        optionData: "Display Light not working"
      },
      {
        parentId: 97,
        id: 9702,
        isSelect: false,
        value: "Backlight Not Working",
        optionData: "Backlight Not Working"
      },
      {
        parentId: 97,
        id: 9703,
        isSelect: false,
        value: "Left side light not working",
        optionData: "Left side light not working"
      }
    ]
  },

  {
    id: 98,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 98,
        id: 9801,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 98,
        id: 9802,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 98,
        id: 9803,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 99,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 99,
        id: 9901,
        isSelect: false,
        value: "Bluetooth not pairing",
        optionData: "Bluetooth not pairing"
      },
      {
        parentId: 99,
        id: 9902,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 99,
        id: 9903,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 100,
    isSelect: false,
    optionData: "WIFI Issue",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 100,
        id: 10001,
        isSelect: false,
        value: "Wifi not connecting",
        optionData: "Wifi not connecting"
      }
    ]
  },

  {
    id: 101,
    isSelect: false,
    optionData: "Power Issue",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 101,
        id: 10101,
        isSelect: false,
        value: "No power ON",
        optionData: "No power ON"
      },
      {
        parentId: 101,
        id: 10102,
        isSelect: false,
        value: "Dead",
        optionData: "Dead"
      }
    ]
  },

  {
    id: 102,
    isSelect: false,
    optionData: "USB",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 102,
        id: 10201,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 102,
        id: 10202,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      },
      {
        parentId: 102,
        id: 10203,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 103,
    isSelect: false,
    optionData: "Sensor issue",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 103,
        id: 10301,
        isSelect: false,
        value: "Remote Sensor not working",
        optionData: "Remote Sensor not working"
      }
    ]
  },

  {
    id: 104,
    isSelect: false,
    optionData: "Power Cable",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 104,
        id: 10401,
        isSelect: false,
        value: "Power cable damage",
        optionData: "Power cable damage"
      },
      {
        parentId: 104,
        id: 10402,
        isSelect: false,
        value: "Power cable not working",
        optionData: "Power cable not working"
      }
    ]
  },

  {
    id: 105,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 105,
        id: 10501,
        isSelect: false,
        value: "HDMI not support",
        optionData: "HDMI not support"
      },
      {
        parentId: 105,
        id: 10502,
        isSelect: false,
        value: "Lan port not support",
        optionData: "Lan port not support"
      },
      {
        parentId: 105,
        id: 10403,
        isSelect: false,
        value: "SD card not support",
        optionData: "SD card not support"
      }
    ]
  },

  {
    id: 106,
    isSelect: false,
    optionData: "Mic",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 106,
        id: 10601,
        isSelect: false,
        value: "Mic Not Working",
        optionData: "Mic Not Working"
      },
      {
        parentId: 106,
        id: 10602,
        isSelect: false,
        value: "Mic low sound",
        optionData: "Mic low sound"
      },
      {
        parentId: 106,
        id: 10603,
        isSelect: false,
        value: "Mic Noise issue",
        optionData: "Mic Noise issue"
      }
    ]
  },

  {
    id: 107,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-MAC",
    value: [
      {
        parentId: 107,
        id: 10701,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 107,
        id: 10702,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      },
      {
        parentId: 107,
        id: 10703,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 107,
        id: 10704,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 107,
        id: 10705,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 107,
        id: 10706,
        isSelect: false,
        value: "Reverse Charging",
        optionData: "Reverse Charging"
      },
      {
        parentId: 107,
        id: 10707,
        isSelect: false,
        value: "Slow Charging",
        optionData: "Slow Charging"
      },
      {
        parentId: 107,
        id: 10706,
        isSelect: false,
        value: "Auto Charging symbol Issue",
        optionData: "Auto Charging symbol Issue"
      },
      {
        parentId: 107,
        id: 10707,
        isSelect: false,
        value: "Charger Heating",
        optionData: "Charger Heating"
      }
    ]
  },

  {
    id: 108,
    isSelect: false,
    optionData: "Power Cable",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 108,
        id: 10801,
        isSelect: false,
        value: "Power cable damage",
        optionData: "Power cable damage"
      },
      {
        parentId: 108,
        id: 10802,
        isSelect: false,
        value: "Power cable not working",
        optionData: "Power cable not working"
      }
    ]
  },

  {
    id: 109,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 109,
        id: 10901,
        isSelect: false,
        value: "HDMI not support",
        optionData: "HDMI not support"
      },
      {
        parentId: 109,
        id: 10902,
        isSelect: false,
        value: "Lan port not support",
        optionData: "Lan port not support"
      },
      {
        parentId: 109,
        id: 10903,
        isSelect: false,
        value: "SD card not support",
        optionData: "SD card not support"
      }
    ]
  },

  {
    id: 110,
    isSelect: false,
    optionData: "Mic",
    itemGrupName: "M-Laptop",
    value: [
      {
        parentId: 110,
        id: 11001,
        isSelect: false,
        value: "Mic Not Working",
        optionData: "Mic Not Working"
      },
      {
        parentId: 110,
        id: 11002,
        isSelect: false,
        value: "Mic low sound",
        optionData: "Mic low sound"
      },
      {
        parentId: 110,
        id: 11003,
        isSelect: false,
        value: "Mic Noise issue",
        optionData: "Mic Noise issue"
      }
    ]
  },

  {
    id: 111,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 111,
        id: 11101,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 111,
        id: 11102,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 111,
        id: 11103,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 111,
        id: 11104,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 111,
        id: 11105,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 111,
        id: 11106,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 111,
        id: 11107,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 111,
        id: 11108,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },

  {
    id: 112,
    isSelect: false,
    optionData: "Panel issue",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 112,
        id: 11201,
        isSelect: false,
        value: "Back door scratches",
        optionData: "Back door scratches"
      },
      {
        parentId: 112,
        id: 11202,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 112,
        id: 11203,
        isSelect: false,
        value: "Front/Middle/Back panel scratches",
        optionData: "Front/Middle/Back panel scratches"
      },
      {
        parentId: 112,
        id: 11204,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      },
      {
        parentId: 112,
        id: 11205,
        isSelect: false,
        value: "Front/Middle/Back panel damage",
        optionData: "Front/Middle/Back panel damage"
      },
      {
        parentId: 112,
        id: 11206,
        isSelect: false,
        value: "Back door damage",
        optionData: "Back door damage"
      },
      {
        parentId: 112,
        id: 11207,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      }
    ]
  },

  {
    id: 113,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 113,
        id: 11301,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 113,
        id: 11302,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 113,
        id: 11303,
        isSelect: false,
        value: "Volume Low",
        optionData: "Volume Low"
      },
      {
        parentId: 113,
        id: 11304,
        isSelect: false,
        value: "Sound Not Clear",
        optionData: "Sound Not Clear"
      },
      {
        parentId: 113,
        id: 11305,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 113,
        id: 11306,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      },
      {
        parentId: 113,
        id: 11307,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      }
    ]
  },

  {
    id: 114,
    isSelect: false,
    optionData: "LED Problem",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 114,
        id: 11401,
        isSelect: false,
        value: "Display Light not working",
        optionData: "Display Light not working"
      },
      {
        parentId: 114,
        id: 11402,
        isSelect: false,
        value: "Backlight Not Working",
        optionData: "Backlight Not Working"
      },
      {
        parentId: 114,
        id: 11403,
        isSelect: false,
        value: "Left side light not working",
        optionData: "Left side light not working"
      }
    ]
  },

  {
    id: 115,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 115,
        id: 11501,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 115,
        id: 11502,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 115,
        id: 11503,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 116,
    isSelect: false,
    optionData: "Volume Key",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 116,
        id: 11601,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 116,
        id: 11602,
        isSelect: false,
        value: "Volume button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 116,
        id: 11603,
        isSelect: false,
        value: "Volume button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 117,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M_TV",
    value: [
      {
        parentId: 117,
        id: 11701,
        isSelect: false,
        value: "Bluetooth not pairing",
        optionData: "Bluetooth not pairing"
      },
      {
        parentId: 117,
        id: 11702,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 117,
        id: 11703,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 118,
    isSelect: false,
    optionData: "WIFI Issue",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 118,
        id: 11801,
        isSelect: false,
        value: "Wifi not connecting",
        optionData: "Wifi not connecting"
      }
    ]
  },

  {
    id: 119,
    isSelect: false,
    optionData: "Power Issue",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 119,
        id: 11901,
        isSelect: false,
        value: "No power ON",
        optionData: "No power ON"
      },
      {
        parentId: 119,
        id: 11902,
        isSelect: false,
        value: "Dead",
        optionData: "Dead"
      }
    ]
  },

  {
    id: 120,
    isSelect: false,
    optionData: "USB",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 120,
        id: 12001,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 120,
        id: 12002,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      },
      {
        parentId: 120,
        id: 12003,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 121,
    isSelect: false,
    optionData: "Sensor issue",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 121,
        id: 12101,
        isSelect: false,
        value: "Remote Sensor not working",
        optionData: "Remote Sensor not working"
      }
    ]
  },

  {
    id: 122,
    isSelect: false,
    optionData: "Power Cable",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 122,
        id: 12201,
        isSelect: false,
        value: "Power cable damage",
        optionData: "Power cable damage"
      },
      {
        parentId: 122,
        id: 12202,
        isSelect: false,
        value: "Power cable not working",
        optionData: "Power cable not working"
      }
    ]
  },
  {
    id: 123,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 123,
        id: 12301,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 123,
        id: 12302,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 123,
        id: 12303,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 123,
        id: 12304,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 123,
        id: 12305,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 123,
        id: 12306,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 123,
        id: 12307,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 123,
        id: 12308,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      }
    ]
  },
  {
    id: 124,
    isSelect: false,
    optionData: "Panel issue",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 125,
        id: 12501,
        isSelect: false,
        value: "Back door scratches",
        optionData: "Back door scratches"
      },
      {
        parentId: 125,
        id: 12502,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 125,
        id: 12503,
        isSelect: false,
        value: "Front/Middle/Back panel scratches",
        optionData: "Front/Middle/Back panel scratches"
      },
      {
        parentId: 125,
        id: 12504,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      },
      {
        parentId: 125,
        id: 12505,
        isSelect: false,
        value: "Front/Middle/Back panel damage",
        optionData: "Front/Middle/Back panel damage"
      },
      {
        parentId: 125,
        id: 12506,
        isSelect: false,
        value: "Back door damage",
        optionData: "Back door damage"
      },
      {
        parentId: 125,
        id: 12507,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      }
    ]
  },

  {
    id: 126,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 126,
        id: 12601,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 126,
        id: 12602,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 126,
        id: 12603,
        isSelect: false,
        value: "Volume Low",
        optionData: "Volume Low"
      },
      {
        parentId: 126,
        id: 12604,
        isSelect: false,
        value: "Sound Not Clear",
        optionData: "Sound Not Clear"
      },
      {
        parentId: 126,
        id: 12605,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 126,
        id: 12606,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      },
      {
        parentId: 126,
        id: 12607,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      }
    ]
  },

  {
    id: 127,
    isSelect: false,
    optionData: "LED Problem",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 127,
        id: 12701,
        isSelect: false,
        value: "Display Light not working",
        optionData: "Display Light not working"
      },
      {
        parentId: 127,
        id: 12702,
        isSelect: false,
        value: "Backlight Not Working",
        optionData: "Backlight Not Working"
      },
      {
        parentId: 127,
        id: 12703,
        isSelect: false,
        value: "Left side light not working",
        optionData: "Left side light not working"
      }
    ]
  },

  {
    id: 128,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 128,
        id: 12801,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 128,
        id: 12802,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 128,
        id: 12803,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 129,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 129,
        id: 12901,
        isSelect: false,
        value: "Bluetooth not pairing",
        optionData: "Bluetooth not pairing"
      },
      {
        parentId: 129,
        id: 12902,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 129,
        id: 12903,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 130,
    isSelect: false,
    optionData: "WIFI Issue",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 130,
        id: 13001,
        isSelect: false,
        value: "Wifi not connecting",
        optionData: "Wifi not connecting"
      }
    ]
  },

  {
    id: 131,
    isSelect: false,
    optionData: "Power Issue",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 131,
        id: 13101,
        isSelect: false,
        value: "No power ON",
        optionData: "No power ON"
      },
      {
        parentId: 131,
        id: 13102,
        isSelect: false,
        value: "Dead",
        optionData: "Dead"
      }
    ]
  },

  {
    id: 132,
    isSelect: false,
    optionData: "USB",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 132,
        id: 13201,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 132,
        id: 13202,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      },
      {
        parentId: 132,
        id: 13203,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 133,
    isSelect: false,
    optionData: "Sensor issue",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 133,
        id: 13301,
        isSelect: false,
        value: "Remote Sensor not working",
        optionData: "Remote Sensor not working"
      }
    ]
  },

  {
    id: 134,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 134,
        id: 13401,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 134,
        id: 13402,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      },
      {
        parentId: 134,
        id: 13403,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 134,
        id: 13404,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 134,
        id: 13405,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 134,
        id: 13406,
        isSelect: false,
        value: "Reverse Charging",
        optionData: "Reverse Charging"
      },
      {
        parentId: 134,
        id: 13407,
        isSelect: false,
        value: "Slow Charging",
        optionData: "Slow Charging"
      },
      {
        parentId: 134,
        id: 13406,
        isSelect: false,
        value: "Auto Charging symbol Issue",
        optionData: "Auto Charging symbol Issue"
      },
      {
        parentId: 134,
        id: 13407,
        isSelect: false,
        value: "Charger Heating",
        optionData: "Charger Heating"
      }
    ]
  },

  {
    id: 135,
    isSelect: false,
    optionData: "Power Cable",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 135,
        id: 13501,
        isSelect: false,
        value: "Power cable damage",
        optionData: "Power cable damage"
      },
      {
        parentId: 135,
        id: 13502,
        isSelect: false,
        value: "Power cable not working",
        optionData: "Power cable not working"
      }
    ]
  },

  {
    id: 136,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 136,
        id: 13601,
        isSelect: false,
        value: "HDMI not support",
        optionData: "HDMI not support"
      },
      {
        parentId: 136,
        id: 13602,
        isSelect: false,
        value: "Lan port not support",
        optionData: "Lan port not support"
      },
      {
        parentId: 136,
        id: 13603,
        isSelect: false,
        value: "SD card not support",
        optionData: "SD card not support"
      }
    ]
  },

  {
    id: 137,
    isSelect: false,
    optionData: "Mic",
    itemGrupName: "M-Tablet",
    value: [
      {
        parentId: 137,
        id: 13701,
        isSelect: false,
        value: "Mic Not Working",
        optionData: "Mic Not Working"
      },
      {
        parentId: 137,
        id: 13702,
        isSelect: false,
        value: "Mic low sound",
        optionData: "Mic low sound"
      },
      {
        parentId: 137,
        id: 13703,
        isSelect: false,
        value: "Mic Noise issue",
        optionData: "Mic Noise issue"
      }
    ]
  },
  {
    id: 138,
    isSelect: false,
    optionData: "Display Problem",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 138,
        id: 13801,
        isSelect: false,
        value: "White display",
        optionData: "White display"
      },
      {
        parentId: 138,
        id: 13802,
        isSelect: false,
        value: "Blank display",
        optionData: "Blank display"
      },
      {
        parentId: 138,
        id: 13803,
        isSelect: false,
        value: "Display scratches",
        optionData: "Display scratches"
      },
      {
        parentId: 138,
        id: 13804,
        isSelect: false,
        value: "Display broken",
        optionData: "Display broken"
      },
      {
        parentId: 138,
        id: 13805,
        isSelect: false,
        value: "Display Dot/Line",
        optionData: "Display Dot/Line"
      },
      {
        parentId: 138,
        id: 13806,
        isSelect: false,
        value: "Display Logo",
        optionData: "Display Logo"
      },
      {
        parentId: 138,
        id: 13807,
        isSelect: false,
        value: "Display brightness low",
        optionData: "Display brightness low"
      },
      {
        parentId: 138,
        id: 13808,
        isSelect: false,
        value: "Display not fit",
        optionData: "Display not fit"
      },
      {
        parentId: 138,
        id: 13809,
        isSelect: false,
        value: "Black/blue screen of death issue",
        optionData: "Black/blue screen of death issue"
      }
    ]
  },
  {
    id: 139,
    isSelect: false,
    optionData: "Panel issue",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 139,
        id: 13901,
        isSelect: false,
        value: "Back door scratches",
        optionData: "Back door scratches"
      },
      {
        parentId: 139,
        id: 13902,
        isSelect: false,
        value: "Volume button Scratches",
        optionData: "Volume button Scratches"
      },
      {
        parentId: 139,
        id: 13903,
        isSelect: false,
        value: "Front/Middle/Back panel scratches",
        optionData: "Front/Middle/Back panel scratches"
      },
      {
        parentId: 139,
        id: 13904,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      },
      {
        parentId: 139,
        id: 13905,
        isSelect: false,
        value: "Front/Middle/Back panel damage",
        optionData: "Front/Middle/Back panel damage"
      },
      {
        parentId: 139,
        id: 13906,
        isSelect: false,
        value: "Back door damage",
        optionData: "Back door damage"
      },
      {
        parentId: 136,
        id: 13907,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      }
    ]
  },

  {
    id: 140,
    isSelect: false,
    optionData: "Speaker Volume",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 140,
        id: 14001,
        isSelect: false,
        value: "Speaker not working",
        optionData: "Speaker not working"
      },
      {
        parentId: 140,
        id: 14002,
        isSelect: false,
        value: "Speaker noise issue",
        optionData: "Speaker noise issue"
      },
      {
        parentId: 140,
        id: 14003,
        isSelect: false,
        value: "Volume Low",
        optionData: "Volume Low"
      },
      {
        parentId: 140,
        id: 14004,
        isSelect: false,
        value: "Sound Not Clear",
        optionData: "Sound Not Clear"
      },
      {
        parentId: 140,
        id: 14005,
        isSelect: false,
        value: "Inner volume strip not working",
        optionData: "Inner volume strip not working"
      },
      {
        parentId: 140,
        id: 14006,
        isSelect: false,
        value: "Inner volume strip damage",
        optionData: "Inner volume strip damage"
      },
      {
        parentId: 140,
        id: 14007,
        isSelect: false,
        value: "Volume button damage",
        optionData: "Volume button damage"
      }
    ]
  },

  {
    id: 141,
    isSelect: false,
    optionData: "LED Problem",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 141,
        id: 14101,
        isSelect: false,
        value: "Display Light not working",
        optionData: "Display Light not working"
      },
      {
        parentId: 141,
        id: 14102,
        isSelect: false,
        value: "Backlight Not Working",
        optionData: "Backlight Not Working"
      },
      {
        parentId: 141,
        id: 14103,
        isSelect: false,
        value: "Left side light not working",
        optionData: "Left side light not working"
      }
    ]
  },

  {
    id: 142,
    isSelect: false,
    optionData: "Power Key",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 142,
        id: 14201,
        isSelect: false,
        value: "Power button damage",
        optionData: "Power button damage"
      },
      {
        parentId: 142,
        id: 14202,
        isSelect: false,
        value: "Power button not working",
        optionData: "Power button not working"
      },
      {
        parentId: 142,
        id: 14203,
        isSelect: false,
        value: "Power button scratches",
        optionData: "Power button scratches"
      }
    ]
  },

  {
    id: 143,
    isSelect: false,
    optionData: "Bluetooth",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 143,
        id: 14301,
        isSelect: false,
        value: "Bluetooth not pairing",
        optionData: "Bluetooth not pairing"
      },
      {
        parentId: 143,
        id: 14302,
        isSelect: false,
        value: "Auto unpair/disconnected",
        optionData: "Auto unpair/disconnected"
      },
      {
        parentId: 143,
        id: 14303,
        isSelect: false,
        value: "Bluetooth issue",
        optionData: "Bluetooth issue"
      }
    ]
  },

  {
    id: 144,
    isSelect: false,
    optionData: "WIFI Issue",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 144,
        id: 14401,
        isSelect: false,
        value: "Wifi not connecting",
        optionData: "Wifi not connecting"
      },
      {
        parentId: 144,
        id: 14402,
        isSelect: false,
        value: "Wifi automatic disconnecting",
        optionData: "Wifi autmatic disconnecting"
      }
    ]
  },

  {
    id: 145,
    isSelect: false,
    optionData: "Power Issue",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 145,
        id: 14501,
        isSelect: false,
        value: "No power ON",
        optionData: "No power ON"
      },
      {
        parentId: 145,
        id: 14502,
        isSelect: false,
        value: "Dead",
        optionData: "Dead"
      }
    ]
  },

  {
    id: 146,
    isSelect: false,
    optionData: "USB",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 146,
        id: 14601,
        isSelect: false,
        value: "USB Pin Damage",
        optionData: "USB Pin Damage"
      },
      {
        parentId: 146,
        id: 14602,
        isSelect: false,
        value: "USB Pin not working",
        optionData: "USB Pin not working"
      }
    ]
  },

  {
    id: 147,
    isSelect: false,
    optionData: "Sensor issue",
    itemGrupName: "M-pad",
    value: [
      {
        parentId: 147,
        id: 14701,
        isSelect: false,
        value: "Remote Sensor not working",
        optionData: "Remote Sensor not working"
      }
    ]
  },

  {
    id: 148,
    isSelect: false,
    optionData: "Charging Issue",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 148,
        id: 14801,
        isSelect: false,
        value: "Charging pin damage",
        optionData: "Charging pin damage"
      },
      {
        parentId: 148,
        id: 14802,
        isSelect: false,
        value: "Charger damage",
        optionData: "Charger damage"
      },
      {
        parentId: 148,
        id: 14803,
        isSelect: false,
        value: "Data cable damage",
        optionData: "Data cable damage"
      },
      {
        parentId: 148,
        id: 14804,
        isSelect: false,
        value: "Data cable not working",
        optionData: "Data cable not working"
      },
      {
        parentId: 148,
        id: 14805,
        isSelect: false,
        value: "Charger not working",
        optionData: "Charger not working"
      },
      {
        parentId: 148,
        id: 14806,
        isSelect: false,
        value: "Reverse Charging",
        optionData: "Reverse Charging"
      },
      {
        parentId: 148,
        id: 14807,
        isSelect: false,
        value: "Slow Charging",
        optionData: "Slow Charging"
      },
      {
        parentId: 148,
        id: 14806,
        isSelect: false,
        value: "Auto Charging symbol Issue",
        optionData: "Auto Charging symbol Issue"
      },
      {
        parentId: 148,
        id: 14807,
        isSelect: false,
        value: "Charger Heating",
        optionData: "Charger Heating"
      }
    ]
  },

  {
    id: 149,
    isSelect: false,
    optionData: "Power Cable",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 149,
        id: 14901,
        isSelect: false,
        value: "Power cable damage",
        optionData: "Power cable damage"
      },
      {
        parentId: 149,
        id: 14902,
        isSelect: false,
        value: "Power cable not working",
        optionData: "Power cable not working"
      }
    ]
  },

  {
    id: 150,
    isSelect: false,
    optionData: "Other Issue",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 150,
        id: 15001,
        isSelect: false,
        value: "Issue with software download of iPad",
        optionData: "Issue with software download of iPad"
      },
      {
        parentId: 150,
        id: 15002,
        isSelect: false,
        value: "Frozen iPad/unresponsive iPad",
        optionData: "Frozen iPad/unresponsive iPad"
      },
      {
        parentId: 150,
        id: 15003,
        isSelect: false,
        value: "Unable to enter recovery mode",
        optionData: "Unable to enter recovery mode"
      },
      {
        parentId: 150,
        id: 15004,
        isSelect: false,
        value: "iPad stuck in reboot loope",
        optionData: "iPad stuck in reboot loop"
      },
      {
        parentId: 150,
        id: 15005,
        isSelect: false,
        value: "Touch screen of iPad not working",
        optionData: "Touch screen of iPad not working"
      },
      {
        parentId: 150,
        id: 15006,
        isSelect: false,
        value: "iPad and iTunes not syncing",
        optionData: "iPad and iTunes not syncing"
      }
    ]
  },

  {
    id: 151,
    isSelect: false,
    optionData: "Mic",
    itemGrupName: "M-Pad",
    value: [
      {
        parentId: 151,
        id: 15101,
        isSelect: false,
        value: "Mic Not Working",
        optionData: "Mic Not Working"
      },
      {
        parentId: 151,
        id: 15102,
        isSelect: false,
        value: "Mic low sound",
        optionData: "Mic low sound"
      },
      {
        parentId: 151,
        id: 15103,
        isSelect: false,
        value: "Mic Noise issue",
        optionData: "Mic Noise issue"
      }
    ]
  },
  {
    id: 152,
    isSelect: false,
    optionData: "Installation",
    itemGrupName: "HA-Television",
    value: [
      {
        parentId: 152,
        id: 15201,
        isSelect: false,
        value: "Installation Delay",
        optionData: "Installation Delay"
      },
      {
        parentId: 152,
        id: 15202,
        isSelect: false,
        value: "New Installation Booking",
        optionData: "New Installation Booking"
      },
      {
        parentId: 152,
        id: 15203,
        isSelect: false,
        value: "Installation Cancelled",
        optionData: "Installation Cancelled"
      }
    ]
  }
];

//slesReturn page
export const paymentModeData = [{ optionData: "Cash" }, { optionData: "Card" }, { optionData: "Finance" }];

// sales order data
export const paymentMethodData = [
  { optionData: "Net Banking" },
  { optionData: "No Cost EMI/Brand Cashback" },
  { optionData: "Credit/Debit Card" },
  { optionData: "UPI/Google Pay/Wallet" },
  { optionData: "EMI(Credit/Debit/Cardless)" }
];

//salesReturn page
export const reasonforSalesReturnDrop = [
  {
    id: 1,
    isSelect: false,
    optionData: "DOA Ceritificate issued by Service Center"
  },
  { id: 2, isSelect: false, optionData: "Replacement" },
  { id: 3, isSelect: false, optionData: "Refund" }
];

export const reasonforSalesReturnDropOption = [
  { id: 2, isSelect: false, optionData: "Replacement" },
  { id: 3, isSelect: false, optionData: "Refund" }
];

//genderDetails
export const genderDetail = [
  { id: 1, isSelect: false, optionData: "Male" },
  { id: 2, isSelect: false, optionData: "Female" },
  { id: 3, isSelect: false, optionData: "Others" }
];

export const QrTemplates = [
  { id: 1, isSelect: false, optionData: "Tv" },
  { id: 2, isSelect: false, optionData: "Speaker" },
  { id: 3, isSelect: false, optionData: "Gas Stove" },
  { id: 4, isSelect: false, optionData: "Stabilizer" },
  { id: 5, isSelect: false, optionData: "Air Cooler" },
  { id: 6, isSelect: false, optionData: "Water Heater" },
  { id: 7, isSelect: false, optionData: "Vaccum Cleaner" },
  { id: 8, isSelect: false, optionData: "Water Purifier" },
  { id: 9, isSelect: false, optionData: "Hobs & Chimney" },
  { id: 10, isSelect: false, optionData: "Water Dispenser" },
  { id: 11, isSelect: false, optionData: "Microwave & OTG" },
  { id: 12, isSelect: false, optionData: "Kitchen Appliances" }
];

export const mockCusBasicInfoTypes = [
  { id: 4, isSelect: false, optionData: "Customer" },
  { id: 1, isSelect: false, optionData: "Corporate" },
  { id: 2, isSelect: false, optionData: "Staff" },
  { id: 3, isSelect: false, optionData: "Showroom" },
  { id: 5, isSelect: false, optionData: "Dealer" }
];

export const mockComplaintType = [
  { id: 3, isSelect: false, optionData: "Letter" },
  { id: 4, isSelect: false, optionData: "Email" },
  { id: 5, isSelect: false, optionData: "Website" },
  { id: 6, isSelect: false, optionData: "Social Media" },
  { id: 7, isSelect: false, optionData: "Call" }
];

export const mockComplaintTypeLegal = [
  { id: 1, isSelect: false, optionData: "Lawyer Notice" },
  { id: 2, isSelect: false, optionData: "Court Notice" }
];

export const mockComplaintSource = [
  { id: 1, isSelect: false, optionData: "Courier" },
  { id: 2, isSelect: false, optionData: "Consumerfourum.Com" },
  { id: 3, isSelect: false, optionData: "Nationalforum.com" },
  { id: 4, isSelect: false, optionData: "FaceBook" },
  { id: 5, isSelect: false, optionData: "Instagram" },
  { id: 6, isSelect: false, optionData: "Whats App" },
  { id: 7, isSelect: false, optionData: "Call" },
  { id: 8, isSelect: false, optionData: "Twitter" },
  { id: 9, isSelect: false, optionData: "Poorvika.com" },
  { id: 10, isSelect: false, optionData: "Email" },
  { id: 11, isSelect: false, optionData: "Register Post" }
];

export const mockComplaintAbout = [
  { id: 1, isSelect: false, optionData: "Sales" },
  { id: 2, isSelect: false, optionData: "Service" },
  { id: 3, isSelect: false, optionData: "Finance" },
  { id: 4, isSelect: false, optionData: "Insurance" }
];

export const mockComplaintSubCategory = [
  { id: 1, isSelect: false, optionData: "Legal" },
  { id: 1, isSelect: false, optionData: "General" }
];

export const mockComplaintTypeFinance = [
  { id: 1, isSelect: false, optionData: "Cashback" },
  { id: 2, isSelect: false, optionData: "Emi" },
  { id: 3, isSelect: false, optionData: "Swiping" },
  { id: 4, isSelect: false, optionData: "Gst" },
  { id: 5, isSelect: false, optionData: "Payment" }
];

export const mockComplaintTypeSales = [
  { id: 1, isSelect: false, optionData: "Misguide" },
  { id: 2, isSelect: false, optionData: "Combo" },
  { id: 3, isSelect: false, optionData: "Gift" },
  { id: 4, isSelect: false, optionData: "Dissatisfied" },
  { id: 5, isSelect: false, optionData: "Payment misbehaviour" },
  { id: 6, isSelect: false, optionData: "Duplicate Invoice" },
  { id: 7, isSelect: false, optionData: "Price" },
  { id: 8, isSelect: false, optionData: "Delivery" }
];

export const mockComplaintTypeService = [
  { id: 1, isSelect: false, optionData: "Warranty" },
  { id: 2, isSelect: false, optionData: "Out Warranty" }
];

export const mockComplaintTypeInsurance = [
  { id: 1, isSelect: false, optionData: "Claim" },
  { id: 2, isSelect: false, optionData: "Misguide" }
];

export const ServiceSubCategory = [
  { id: 1, isSelect: false, optionData: "Product Service" },
  { id: 2, isSelect: false, optionData: "Service Support" },
  { id: 3, isSelect: false, optionData: "Duplicate invoice" },
  { id: 4, isSelect: false, optionData: "General Information" }
];

export const mockComplaintActionTaken = [
  { id: 1, isSelect: false, optionData: "Discussed" },
  { id: 2, isSelect: false, optionData: "Mailed" },
  { id: 3, isSelect: false, optionData: "Replied" },
  { id: 4, isSelect: false, optionData: "Hearing" }
];

export const mockComplaintConcernType = [
  { id: 1, isSelect: false, optionData: "Customer" },
  { id: 6, isSelect: false, optionData: "Showroom" },
  { id: 2, isSelect: false, optionData: "Brand" },
  { id: 3, isSelect: false, optionData: "Service Center" },
  { id: 4, isSelect: false, optionData: "Advocate" },
  { id: 5, isSelect: false, optionData: "Department" },
  { id: 6, isSelect: false, optionData: "Forum" }
];

export const MockComplainCurrentStatus = [
  { id: 1, isSelect: false, optionData: "Followup" },
  { id: 2, isSelect: false, optionData: "Awaiting Status" },
  { id: 3, isSelect: false, optionData: "To be apear" },
  { id: 4, isSelect: false, optionData: "Approval" },
  { id: 5, isSelect: false, optionData: "Date Bared" },
  { id: 6, isSelect: false, optionData: "Satisfied" },
  { id: 7, isSelect: false, optionData: "Dissatisfied" },
  { id: 8, isSelect: false, optionData: "Order passed" },
  { id: 9, isSelect: false, optionData: "Settled" },
  { id: 10, isSelect: false, optionData: "Ex-party" },
  { id: 11, isSelect: false, optionData: "Settlement" },
  { id: 12, isSelect: false, optionData: "Dismissed" }
];

export const MockComplaintStatusPending = [
  { id: 1, isSelect: false, optionData: "Vakalatnama" },
  { id: 2, isSelect: false, optionData: "Proof Affidavit" },
  { id: 3, isSelect: false, optionData: "For Written Version" },
  {
    id: 4,
    isSelect: false,
    optionData: "Reply Notice sent to Customer / Advocate"
  },
  { id: 5, isSelect: false, optionData: "EA Filed" },
  {
    id: 6,
    isSelect: false,
    optionData: "Got Reply From Brand / Internal Team"
  },
  { id: 7, isSelect: false, optionData: "Mail sent to Advocate" },
  { id: 8, isSelect: false, optionData: "Mail sent to Brand" },
  { id: 9, isSelect: false, optionData: "Mail sent to Showroom" },
  { id: 11, isSelect: false, optionData: "Court Order" },
  { id: 12, isSelect: false, optionData: "Awating for GM Approval" },
  { id: 13, isSelect: false, optionData: "Written Aragument" },
  { id: 14, isSelect: false, optionData: "Awating for payment from brand" },
  {
    id: 15,
    isSelect: false,
    optionData: "Case closed by brand (Awating for closure copy)"
  },
  { id: 16, isSelect: false, optionData: "Spoke to Customer" },
  { id: 17, isSelect: false, optionData: "Spoke to Brand" },
  { id: 18, isSelect: false, optionData: "Spoke to Advocate" }
];
export const MockComplainCurrentStatusGeneralPending = [
  { id: 1, isSelect: false, optionData: "Appeal" },
  { id: 2, isSelect: false, optionData: "Awating for GM Approval" },
  { id: 3, isSelect: false, optionData: "Awating for payment from brand" },
  { id: 4, isSelect: false, optionData: "Case closed by brand (Awating for closure copy)" },
  { id: 5, isSelect: false, optionData: "Court Order" },
  { id: 6, isSelect: false, optionData: "EA Filed" },
  { id: 7, isSelect: false, optionData: "Follow up" },
  { id: 8, isSelect: false, optionData: "For Written Version" },
  { id: 9, isSelect: false, optionData: "Got Reply From Brand / Internal Team" },
  { id: 10, isSelect: false, optionData: "Lok Adalat" },
  { id: 11, isSelect: false, optionData: "Mail sent to Advocate" },
  { id: 12, isSelect: false, optionData: "Mail sent to Brand" },
  { id: 13, isSelect: false, optionData: "Mail sent to internal team" },
  { id: 14, isSelect: false, optionData: "Mail sent to Showroom" },
  { id: 15, isSelect: false, optionData: "Payment Pending from Brand" },
  { id: 16, isSelect: false, optionData: "Proof Affidavit" },
  { id: 17, isSelect: false, optionData: "Reply Notice sent to Customer / Advocate" },
  { id: 18, isSelect: false, optionData: "Spoke Internally" },
  { id: 19, isSelect: false, optionData: "Spoke to Advocate" },
  { id: 20, isSelect: false, optionData: "Spoke to Brand" },
  { id: 21, isSelect: false, optionData: "Spoke to Customer" },
  { id: 22, isSelect: false, optionData: "Spoke with Showroom" },
  { id: 23, isSelect: false, optionData: "Warrant" },
  { id: 24, isSelect: false, optionData: "Written Aragument" },
  { id: 25, isSelect: false, optionData: "Vakalatnama" },
  { id: 26, isSelect: false, optionData: "Mail sent to Customer" },
  { id: 27, isSelect: false, optionData: "Counter" },
  { id: 28, isSelect: false, optionData: "Cheque / DD dispatched" }
];

export const MockComplaintStatusLegalPending = [
  { id: 1, isSelect: false, optionData: "Appeal" },
  { id: 2, isSelect: false, optionData: "Awating for GM Approval" },
  { id: 3, isSelect: false, optionData: "Awating for payment from brand" },
  { id: 4, isSelect: false, optionData: "Case closed by brand (Awating for closure copy)" },
  { id: 5, isSelect: false, optionData: "Court Order" },
  { id: 6, isSelect: false, optionData: "EA Filed" },
  { id: 7, isSelect: false, optionData: "Follow up" },
  { id: 8, isSelect: false, optionData: "For Written Version" },
  { id: 9, isSelect: false, optionData: "Got Reply From Brand / Internal Team" },
  { id: 10, isSelect: false, optionData: "Lok Adalat" },
  { id: 11, isSelect: false, optionData: "Mail sent to Advocate" },
  { id: 12, isSelect: false, optionData: "Mail sent to Brand" },
  { id: 13, isSelect: false, optionData: "Mail sent to internal team" },
  { id: 14, isSelect: false, optionData: "Mail sent to Showroom" },
  { id: 15, isSelect: false, optionData: "Payment Pending from Brand" },
  { id: 16, isSelect: false, optionData: "Proof Affidavit" },
  { id: 17, isSelect: false, optionData: "Reply Notice sent to Customer / Advocate" },
  { id: 18, isSelect: false, optionData: "Spoke Internally" },
  { id: 19, isSelect: false, optionData: "Spoke to Advocate" },
  { id: 20, isSelect: false, optionData: "Spoke to Brand" },
  { id: 21, isSelect: false, optionData: "Spoke to Customer" },
  { id: 22, isSelect: false, optionData: "Spoke with Showroom" },
  { id: 23, isSelect: false, optionData: "Warrant" },
  { id: 24, isSelect: false, optionData: "Written Aragument" },
  { id: 25, isSelect: false, optionData: "Vakalatnama" },
  { id: 26, isSelect: false, optionData: "Vakalatnama" },
  { id: 27, isSelect: false, optionData: "Mail sent to Customer" },
  { id: 28, isSelect: false, optionData: "Counter" },
  { id: 29, isSelect: false, optionData: "Cheque / DD dispatched" }
];
export const MockComplaintStatusGeneralClosed = [
  { id: 1, isSelect: false, optionData: "Not eligible for cash back" },
  { id: 2, isSelect: false, optionData: "Not satisfied" },
  { id: 3, isSelect: false, optionData: "Converted to Advocate/legal notice" },
  { id: 4, isSelect: false, optionData: "Issue resolved" },
  { id: 5, isSelect: false, optionData: "Replacement Approved" },
  { id: 6, isSelect: false, optionData: "No Response from Customer" },
  { id: 7, isSelect: false, optionData: "Order amount settled by Poorvika and brand " },
  { id: 8, isSelect: false, optionData: "Converting from Advocate to court notice" },
  { id: 9, isSelect: false, optionData: "Customer letter converted to advocate or court notice" }
];

export const MockComplaintStatusClosed = [
  { id: 1, isSelect: false, optionData: "Order Amount Settled By Poorvika" },
  { id: 3, isSelect: false, optionData: "Order Amount settled by brand" },
  { id: 1, isSelect: false, optionData: "Case Dismissed" },
  { id: 3, isSelect: false, optionData: "Cheque / DD  Dispatched" },
  { id: 4, isSelect: false, optionData: "Case Diveted & Under taken by brand" },
  {
    id: 5,
    isSelect: false,
    optionData: "No Response from Customer / Advocate"
  },
  { id: 6, isSelect: false, optionData: "Out of court Settlement " },
  { id: 7, isSelect: false, optionData: "Ex- Party Cases" },
  { id: 8, isSelect: false, optionData: "Replacement Approved" },
  { id: 9, isSelect: false, optionData: "Order amount settled by Poorvika and brand " },
  { id: 10, isSelect: false, optionData: "Converting from Advocate to court notice" },
  { id: 11, isSelect: false, optionData: "Customer letter converted to advocate or court notice" }
];

export const MockOnlineOrderSubCategory = [
  { id: 1, isSelect: false, optionData: "Cancel/Refund" },
  { id: 2, isSelect: false, optionData: "Order Status" },
  { id: 3, isSelect: false, optionData: "Offer/Cashback" },
  { id: 4, isSelect: false, optionData: "Payment" },
  { id: 5, isSelect: false, optionData: "Services" }
];

export const MockOnlineOrderCancelOptions = [
  { id: 1, isSelect: false, optionData: "Wrongly Given Cancel Request" },
  { id: 2, isSelect: false, optionData: "Refund Status" },
  { id: 3, isSelect: false, optionData: "Request For Cancel" },
  { id: 4, isSelect: false, optionData: "Customer Not Interested" },
  { id: 5, isSelect: false, optionData: "Customer Out Of Station" },
  { id: 6, isSelect: false, optionData: "Expected Delivery Too Long" },
  { id: 7, isSelect: false, optionData: "Cancel Reason Not Clear" },
  { id: 8, isSelect: false, optionData: "Wrongly Placed Order" },
  { id: 9, isSelect: false, optionData: "Wrongly Commitment of CC" },
  { id: 10, isSelect: false, optionData: "Wrongly Commitment of Chat" },
  {
    id: 11,
    isSelect: false,
    optionData: "Showroom Wrongly Order Place to Customer"
  },
  { id: 12, isSelect: false, optionData: "Order Placed Without Cashback" },
  { id: 13, isSelect: false, optionData: "Double Time Order Placed" }
];

export const MockOnlineOrderStatusOptions = [
  { id: 1, isSelect: false, optionData: "Free Bie Issue" },
  { id: 2, isSelect: false, optionData: "AWB No update" },
  { id: 3, isSelect: false, optionData: "Customer Call Not Pick" },
  { id: 4, isSelect: false, optionData: "Delay Delivery" },
  { id: 5, isSelect: false, optionData: "Delay Order Process" },
  { id: 6, isSelect: false, optionData: "Delivery Charges" },
  { id: 7, isSelect: false, optionData: "Delivery Mode" },
  { id: 8, isSelect: false, optionData: "Delivery Status" },
  { id: 9, isSelect: false, optionData: "Manual Process" },
  { id: 10, isSelect: false, optionData: "No Courier Service" },
  { id: 11, isSelect: false, optionData: "Not Satisfied/Req Replacement" },
  { id: 12, isSelect: false, optionData: "Open Stock" },
  { id: 13, isSelect: false, optionData: "SRN Stock" },
  { id: 14, isSelect: false, optionData: "Server Issues" },
  { id: 15, isSelect: false, optionData: "Pre Book Order" },
  { id: 16, isSelect: false, optionData: "Product Mismatch Delivered" },
  { id: 17, isSelect: false, optionData: "Product Not Delivered" },
  { id: 18, isSelect: false, optionData: "Request for Billing Address Change" },
  { id: 19, isSelect: false, optionData: "Request for Colour Change" },
  { id: 20, isSelect: false, optionData: "Request for Duplicate Invoice" },
  {
    id: 21,
    isSelect: false,
    optionData: "Request For GST & Shipping Address Change"
  },
  { id: 22, isSelect: false, optionData: "Request For GST Correction" },
  { id: 23, isSelect: false, optionData: "Request For GST New" },
  { id: 24, isSelect: false, optionData: "Request for Mobile No Change" },
  { id: 25, isSelect: false, optionData: "Request for Model Change" },
  { id: 26, isSelect: false, optionData: "Request for Name Change" },
  { id: 27, isSelect: false, optionData: "Request For Rapid Delivery" },
  {
    id: 28,
    isSelect: false,
    optionData: "Request for Shipping Address Change"
  }
];

export const MockOnlineOrderOfferOptions = [
  { id: 1, isSelect: false, optionData: "Cash Back Issues" },
  { id: 2, isSelect: false, optionData: "Instant Discount" },
  { id: 3, isSelect: false, optionData: "Coupon Problem" }
];

export const MockOnlineOrderPaymentOptions = [
  { id: 1, isSelect: false, optionData: "Charge Back/Dispute" },
  { id: 2, isSelect: false, optionData: "Delay Payment Received" },
  { id: 3, isSelect: false, optionData: "EMI Querys" },
  { id: 4, isSelect: false, optionData: "Payment Mode Mistake" },
  { id: 5, isSelect: false, optionData: "PINE PG IMEI Mapping Error" }
];

export const MockOnlineOrderServiceOptions = [
  { id: 1, isSelect: false, optionData: "Product Problem" },
  { id: 2, isSelect: false, optionData: "Warranty Issue" },
  { id: 3, isSelect: false, optionData: "Request for Product Return" },
  { id: 4, isSelect: false, optionData: "Damage Product Delivered" }
];

export const MockOnlineOrderStatus = [
  { id: 1, isSelect: false, optionData: "Pending" },
  { id: 2, isSelect: false, optionData: "Closed" }
];

export const MockOnlineOrderStatusPending = [
  { id: 1, isSelect: false, optionData: "Processing" },
  { id: 2, isSelect: false, optionData: "Ready To Ship" },
  { id: 3, isSelect: false, optionData: "Order Picked" },
  { id: 4, isSelect: false, optionData: "Shipped" },
  { id: 5, isSelect: false, optionData: "GST Updation" },
  { id: 6, isSelect: false, optionData: "Address Change" },
  { id: 7, isSelect: false, optionData: "Stock In RTO" },
  { id: 8, isSelect: false, optionData: "Product Not Received" },
  { id: 9, isSelect: false, optionData: "Waiting For SRN" },
  { id: 10, isSelect: false, optionData: "Waiting For Accounts Offer" },
  { id: 11, isSelect: false, optionData: "Waiting For Service Approval" },
  { id: 12, isSelect: false, optionData: "Waiting For Customer Revert" },
  { id: 13, isSelect: false, optionData: "Bank Detail Not Received" },
  { id: 14, isSelect: false, optionData: "Address Change Mail" },
  { id: 15, isSelect: false, optionData: "GST Updation Mail" },
  { id: 16, isSelect: false, optionData: "Customer Call Not Pick Up" },
  { id: 17, isSelect: false, optionData: "Customer Still Not Visit Showroom" },
  { id: 18, isSelect: false, optionData: "Waiting For Pine Labs Reply" },
  { id: 19, isSelect: false, optionData: "Waiting For The Bank Statement" },
  { id: 20, isSelect: false, optionData: "Waiting For Product Image" }
];

export const MockOnlineOrderStatusClosed = [
  { id: 1, isSelect: false, optionData: "Completed" },
  { id: 2, isSelect: false, optionData: "Delivered" }
];

export const MockServiceSupportStatus = [
  { id: 1, isSelect: false, optionData: "Pending" },
  { id: 2, isSelect: false, optionData: "Closed" }
];

export const MockGenaralInfoStatus = [
  { id: 1, isSelect: false, optionData: "Pending" },
  { id: 2, isSelect: false, optionData: "Closed" }
];

export const MockServiceSupportStatusPending = [
  { id: 1, isSelect: false, optionData: "Approved for Replacement by Brand" },
  {
    id: 2,
    isSelect: false,
    optionData: "Approved for Replacement by Poorvika"
  },
  { id: 3, isSelect: false, optionData: "Brand Discussion started" },
  { id: 4, isSelect: false, optionData: "Brand solution received" },
  { id: 5, isSelect: false, optionData: "Mail sent to brand" },
  { id: 6, isSelect: false, optionData: "Payment pending from customer" },
  { id: 7, isSelect: false, optionData: "Product ready at service center" },
  { id: 8, isSelect: false, optionData: "Product Ready at showroom" },
  { id: 9, isSelect: false, optionData: "Refund from Showroom End" },
  { id: 10, isSelect: false, optionData: "Repeated problem" },
  { id: 11, isSelect: false, optionData: "Spoke with customer" },
  { id: 12, isSelect: false, optionData: "Spoke with showroom" },
  { id: 13, isSelect: false, optionData: "TV Installation pending" },
  { id: 14, isSelect: false, optionData: "Waiting for approval from brand" },
  { id: 15, isSelect: false, optionData: "Waiting for approval from customer" },
  {
    id: 16,
    isSelect: false,
    optionData: "Waiting for approval from Internal team"
  },
  {
    id: 17,
    isSelect: false,
    optionData: "Waiting for customer to collect the device"
  },
  { id: 18, isSelect: false, optionData: "Internal team discussion" },
  { id: 19, isSelect: false, optionData: "Spoken with showroom/customer" },
  { id: 20, isSelect: false, optionData: "Mail sent to internal team" },
  { id: 21, isSelect: false, optionData: "Mail sent to customer" },
  { id: 22, isSelect: false, optionData: "Mail sent to showroom" },
  { id: 23, isSelect: false, optionData: "Got Reply from brand/Asc" },
  { id: 24, isSelect: false, optionData: "Got Reply from internal team" }
];

export const MockServiceSupportStatusClosed = [
  { id: 1, isSelect: false, optionData: "Refund done" },
  { id: 2, isSelect: false, optionData: "Replacement done" },
  {
    id: 3,
    isSelect: false,
    optionData: "Customer collected device from branch"
  },
  { id: 4, isSelect: false, optionData: "Customer collected device from ASC" },
  { id: 5, isSelect: false, optionData: "TV Installation done" },
  { id: 6, isSelect: false, optionData: "Issue Closed" },
  { id: 7, isSelect: false, optionData: "No Response from customer" }
];

export const ServiceCenter = [
  { id: 2, isSelect: false, optionData: "APPLE" },
  { id: 3, isSelect: false, optionData: "CELKON" },
  { id: 4, isSelect: false, optionData: "HONOR" },
  { id: 5, isSelect: false, optionData: "HUAWEI" },
  { id: 6, isSelect: false, optionData: "IBALL" },
  { id: 7, isSelect: false, optionData: "ITEL" },
  { id: 8, isSelect: false, optionData: "KARBONN" },
  { id: 9, isSelect: false, optionData: "LAVA" },
  { id: 10, isSelect: false, optionData: "LENOVO" },
  { id: 11, isSelect: false, optionData: "LG" },
  { id: 12, isSelect: false, optionData: "MEIZU" },
  { id: 13, isSelect: false, optionData: "MICROMAX" },
  { id: 14, isSelect: false, optionData: "MOTOROLA" },
  { id: 15, isSelect: false, optionData: "NOKIA" },
  { id: 16, isSelect: false, optionData: "ONE PLUS" },
  { id: 17, isSelect: false, optionData: "OPPO" },
  { id: 18, isSelect: false, optionData: "ORAIMO" },
  { id: 19, isSelect: false, optionData: "PANASONIC" },
  { id: 20, isSelect: false, optionData: "REALME" },
  { id: 21, isSelect: false, optionData: "SAMSUNG" },
  { id: 22, isSelect: false, optionData: "SKULLCANDY" },
  { id: 23, isSelect: false, optionData: "SONY" },
  { id: 24, isSelect: false, optionData: "TECNO" },
  { id: 25, isSelect: false, optionData: "VIVO" },
  { id: 26, isSelect: false, optionData: "XIAOMI" },
  { id: 27, isSelect: false, optionData: "XOLO" },
  { id: 28, isSelect: false, optionData: "ZEBRONICS" }
];
export const MockCurrentStatus = [
  { id: 1, isSelect: false, optionData: "New" },
  { id: 2, isSelect: false, optionData: "No response" },
  { id: 3, isSelect: false, optionData: "Issue Closed" }
];
export const MockGeneralInfoOpenCurrentStatus = [{ id: 1, isSelect: false, optionData: "New" }];
export const MockGeneralInfoPendingCurrentStatus = [
  { id: 1, isSelect: false, optionData: "Waiting for Inform" },
  { id: 2, isSelect: false, optionData: "Need more Clarity" },
  { id: 3, isSelect: false, optionData: "Internal Discussion" }
];
export const MockGeneralInfoClosedCurrentStatus = [{ id: 1, isSelect: false, optionData: "Resolved" }];
export const MockTypeofService = [
  { id: 1, isSelect: false, optionData: "Warranty" },
  { id: 2, isSelect: false, optionData: "Void Warranty" },
  { id: 3, isSelect: false, optionData: "Out of Warranty" }
];
export const MockServiceSolutionForSupport = [
  { id: 1, isSelect: false, optionData: "Visit Store" },
  { id: 2, isSelect: false, optionData: "Visit Service Center" },
  { id: 3, isSelect: false, optionData: "Solution Pending" }
];

export const MockSalesStatus = [
  { id: 1, isSelect: false, optionData: "Pending" },
  { id: 2, isSelect: false, optionData: "Closed" }
];
export const MockSalesStatusPending = [
  {
    _id: 3,
    id: 1,
    isSelect: false,
    isCheck: false,
    optionData: "waiting for approval for Online entry"
  },
  { _id: 4, id: 2, isCheck: false, isSelect: false, optionData: "Waiting for approval from financed" },
  { _id: 5, id: 3, isCheck: false, isSelect: false, optionData: "Waiting For Approval" },
  { _id: 6, id: 4, isCheck: false, isSelect: false, optionData: "Waiting For Approval from Brand" },
  {
    _id: 7,
    id: 5,
    isCheck: false,
    isSelect: false,
    optionData: "SRN will be approved after clearing the Edit amount"
  },
  {
    _id: 8,
    id: 6,
    isCheck: false,
    isSelect: false,
    optionData: "SRN will be approved after clearing the pending SRN product"
  }
];

export const MockSalesStatusClosed = [
  { _id: 1, id: 1, isSelect: false, isCheck: false, optionData: "Approved" },
  { _id: 2, id: 2, isSelect: false, isCheck: false, optionData: "Rejected" }
];

export const rejectClosed = [
  {
    id: 1,
    isSelect: false,
    optionData: "Upload Handset Box Image with IMEI Number"
  },
  { id: 2, isSelect: false, optionData: "Upload Product Problem Images" },
  { id: 3, isSelect: false, optionData: "Upload Bill copy and Product Image" },
  {
    id: 4,
    isSelect: false,
    optionData: "Upload DOA Certificate & Packing images"
  },
  {
    id: 5,
    isSelect: false,
    optionData: "Upload Replacement certificate & Credit Note Images"
  },
  {
    id: 6,
    isSelect: false,
    optionData: "Mismatch in attahced bill copy image"
  },
  {
    id: 7,
    isSelect: false,
    optionData: "Mismatch in attahced handset box IMEI No image"
  },
  {
    id: 8,
    isSelect: false,
    optionData: "Attached image is not clear re-upload the bill copy"
  },
  {
    id: 9,
    isSelect: false,
    optionData: "Attached image is not clear re upload the box IMEI no"
  },
  { id: 10, isSelect: false, optionData: "Call to SRN" },
  {
    id: 11,
    isSelect: false,
    optionData: "Mail Doa certificate , Bill copy & Warranty Transfer form to : doa@poorvika.com"
  },
  {
    id: 12,
    isSelect: false,
    optionData: "Doa certificate Imei Number & DOA Box imei Number is Mismatched"
  },
  {
    id: 13,
    isSelect: false,
    optionData: "Please Update New (Replacement)IMEI Number In OLD Bill Copy"
  },
  { id: 14, isSelect: false, optionData: "SRN Value Mismatch" },
  { id: 15, isSelect: false, optionData: "Images Not Clear" },
  { id: 16, isSelect: false, optionData: "Customer Name & Mobile No Mismatch" },
  { id: 17, isSelect: false, optionData: "Warranty Closed" },
  { id: 18, isSelect: false, optionData: "Kindly update Payment Mode" },
  { id: 19, isSelect: false, optionData: "Kindly upload Job sheet Image" }
];

export const MockSalesStatusApproved = [
  { id: 1, isSelect: false, optionData: "SRN Date" },
  { id: 2, isSelect: false, optionData: "SRN Number" },
  { id: 3, isSelect: false, optionData: "SRN Value" }
];

export const DuplicateInvoiceStatus = [
  { id: 1, isSelect: false, optionData: "Open" },
  { id: 2, isSelect: false, optionData: "Pending" },
  { id: 3, isSelect: false, optionData: "Closed" }
];

export const outDatedStatus = [
  { id: 1, isSelect: false, optionData: "Open" },
  { id: 2, isSelect: false, optionData: "Pending" },
  { id: 3, isSelect: false, optionData: "Closed" }
];

export const DuplicateInvoiceCurrentStatus = [{ id: 1, isSelect: false, optionData: "New" }];

export const MockEnquiry = [
  { id: 1, isSelect: true, optionData: "This week" },
  { id: 2, isSelect: false, optionData: "This Month" },
  { id: 3, isSelect: false, optionData: "Last Month" },
  { id: 4, isSelect: false, optionData: "Custom Date" }
];

export const MockCampaignData = [
  { isSelect: false, label: "ADLD", id: 34 },
  { isSelect: false, label: "BAJAJ", id: 27 },
  { isSelect: false, label: "Comparison", id: 18 },
  { isSelect: false, label: "Comparison 2", id: 28 },
  { isSelect: false, label: "DOA", id: 7 },
  { isSelect: false, label: "EDIT & DELETE", id: 10 },
  { isSelect: false, label: "English Sales Customer Care", id: 2 },
  { isSelect: false, label: "Hindi Sales Customer Care", id: 20 },
  { isSelect: false, label: "Kannada Sales Customer Care", id: 4 },
  { isSelect: false, label: "Tamil Sales Customer Care", id: 31 },
  { isSelect: false, label: "Missed Call", id: 14 },
  { isSelect: false, label: "OnlineInbound", id: 25 },
  { isSelect: false, label: "OutBondMarketing", id: 24 },
  { isSelect: false, label: "Poorvika Dairy", id: 35 },
  { isSelect: false, label: "Sales_Return", id: 17 },
  { isSelect: false, label: "VoiceBlaster_Inbound", id: 8 },
  { isSelect: false, label: "PoorvikaIBCampaign", id: 36 },
  { isSelect: false, label: " Bangalore CC OB", id: 37 }
];

export const PaginationListShowMock = [
  { id: 1, isSelect: true, optionData: 5 },
  { id: 2, isSelect: true, optionData: 10 },
  { id: 3, isSelect: false, optionData: 20 },
  { id: 4, isSelect: false, optionData: 30 },
  { id: 5, isSelect: false, optionData: 50 },
  { id: 6, isSelect: false, optionData: 100 }
];

export const MockCouponStatus = [
  { id: 1, isSelect: false, optionData: "Active" },
  { id: 2, isSelect: false, optionData: "Redeem" },
  { id: 3, isSelect: false, optionData: "Expired" }
];
export const MockStatus = [
  { id: 1, isSelect: false, optionData: "Active" },
  { id: 2, isSelect: false, optionData: "Inactive" }
];
export const MockStatusHistory = [
  {
    fieldName: "test",
    isNewField: true,
    isNew: true,
    oldValue: "-",
    newValue: "tsetstst"
  },
  {
    fieldName: "currentStatus",
    isNewField: false,
    isNew: false,
    oldValue: "Waiting For Approval",
    newValue: "waiting for approval for Online entry"
  },
  {
    fieldName: "transactions",
    isNewField: false,
    isNew: true,
    oldValue: "-",
    newValue: [[Object]]
  },
  {
    fieldName: "updatedBy",
    isNewField: false,
    isNew: false,
    oldValue: { date: "2022-01-19T14:12:12.679Z" },
    newValue: { date: "2022-01-19T14:24:12.679Z" }
  }
];

export const storeNearByShowMock = [
  { id: 1, isSelect: true, optionData: "5km" },
  { id: 2, isSelect: true, optionData: "10km" },
  { id: 3, isSelect: false, optionData: "20km" },
  { id: 4, isSelect: false, optionData: "30km" },
  { id: 5, isSelect: false, optionData: "50km" },
  { id: 6, isSelect: false, optionData: "100km" }
];

export const salesStatusMock = [
  {
    id: 1,
    status: "Open",
    subId: 101,
    subStatus: "Competitor offers"
  },
  {
    id: 1,
    status: "Open",
    subId: 102,
    subStatus: "Just Enq"
  },
  {
    id: 1,
    status: "Open",
    subId: 103,
    subStatus: "Looking for additional offers "
  },
  {
    id: 1,
    status: "Open",
    subId: 104,
    subStatus: "Looking for expired offers"
  },
  {
    id: 1,
    status: "Open",
    subId: 105,
    subStatus: "Plan To Buy In Online"
  },
  {
    id: 1,
    status: "Open",
    subId: 106,
    subStatus: "Plan to Purchase at Store"
  },
  {
    id: 1,
    status: "Open",
    subId: 107,
    subStatus: "Plan to Purchase"
  },

  {
    id: 2,
    status: "Pending",
    subId: 201,
    subStatus: "Follow Up"
  },
  {
    id: 2,
    status: "Pending",
    subId: 202,
    subStatus: "Lead Given To Showroom"
  },
  {
    id: 2,
    status: "Pending",
    subId: 203,
    subStatus: "Number Busy"
  },
  {
    id: 2,
    status: "Pending",
    subId: 204,
    subStatus: "Ringing"
  },
  {
    id: 2,
    status: "Pending",
    subId: 205,
    subStatus: "Switched Off"
  },

  {
    id: 3,
    status: "Closed",
    subId: 301,
    subStatus: "Duplicate Lead"
  },
  {
    id: 3,
    status: "Closed",
    subId: 302,
    subStatus: "No stock "
  },
  {
    id: 3,
    status: "Closed",
    subId: 303,
    subStatus: "EMI Not Eligible"
  },
  {
    id: 3,
    status: "Closed",
    subId: 304,
    subStatus: "Not Interested"
  },
  {
    id: 3,
    status: "Closed",
    subId: 305,
    subStatus: "No Response"
  },
  {
    id: 3,
    status: "Closed",
    subId: 306,
    subStatus: "Purchased At Offline Competitor"
  },
  {
    id: 3,
    status: "Closed",
    subId: 307,
    subStatus: "Purchased At Online Competitor"
  },
  {
    id: 3,
    status: "Closed",
    subId: 308,
    subStatus: "Purchased At Poorvika Offline"
  },
  {
    id: 3,
    status: "Closed",
    subId: 309,
    subStatus: "Purchased at Poorvika Online"
  },
  {
    id: 3,
    status: "Closed",
    subId: 310,
    subStatus: "Do Not Call"
  },
  {
    id: 3,
    status: "Closed",
    subId: 311,
    subStatus: "Plan"
  },
  {
    id: 3,
    status: "Closed",
    subId: 312,
    subStatus: "Converted"
  },
  {
    id: 4,
    status: "Just Enquiry",
    subId: 401,
    subStatus: "Wrong Number"
  }
];

export const salesSourceMock = [
  {
    id: 1,
    status: "Album"
  },
  {
    id: 1,
    status: "Anandhavikatan"
  },
  {
    id: 2,
    status: "Anna Nagar News"
  },
  {
    id: 3,
    status: "Ask Me"
  },
  {
    id: 4,
    status: "Auto"
  },
  {
    id: 5,
    status: "Bag"
  },
  {
    id: 6,
    status: "TVS"
  },
  {
    id: 7,
    status: "HDB"
  },
  {
    id: 8,
    status: "HDFC"
  },
  {
    id: 9,
    status: "ICICI"
  },
  {
    id: 10,
    status: "Bajaj"
  },
  {
    id: 11,
    status: "Barricade"
  },
  {
    id: 12,
    status: "Bill"
  },
  {
    id: 13,
    status: "Bombay Times"
  },
  {
    id: 14,
    status: "Bus"
  },
  {
    id: 15,
    status: "Call Easy"
  },
  {
    id: 16,
    status: "Capital First"
  },
  {
    id: 17,
    status: "Chat Online"
  },
  {
    id: 18,
    status: "Chennai Enquiry"
  },
  {
    id: 19,
    status: "Deccan"
  },
  {
    id: 20,
    status: "Deccan Herald"
  },
  {
    id: 1,
    status: "Dinamani"
  },
  {
    id: 1,
    status: "Dt Next"
  },
  {
    id: 21,
    status: "Echarikka"
  },
  {
    id: 22,
    status: "Economic Times"
  },
  {
    id: 23,
    status: "Ennadu"
  },
  {
    id: 24,
    status: "Facebook"
  },
  {
    id: 25,
    status: "Friends"
  },
  {
    id: 26,
    status: "Get It Now"
  },
  {
    id: 27,
    status: "Google Page"
  },
  {
    id: 28,
    status: "Hand Fan"
  },
  {
    id: 29,
    status: "Hindusthani Times"
  },
  {
    id: 30,
    status: "Home Credit"
  },
  {
    id: 31,
    status: "Hordings"
  },
  {
    id: 32,
    status: "India Mart"
  },
  {
    id: 33,
    status: "Indian Express"
  },
  {
    id: 34,
    status: "Instagram"
  },
  {
    id: 35,
    status: "Just Call"
  },
  {
    id: 36,
    status: "Just Dial"
  },
  {
    id: 37,
    status: "Karan"
  },
  {
    id: 38,
    status: "Kerala Kaumudi"
  },
  {
    id: 39,
    status: "Key Chain"
  },
  {
    id: 40,
    status: "Kulamurasu"
  },
  {
    id: 41,
    status: "Kungumam"
  },
  {
    id: 42,
    status: "Lok Mat"
  },
  {
    id: 43,
    status: "Lok Satta"
  },
  {
    id: 44,
    status: "Others"
  },
  {
    id: 45,
    status: "Maharastra Times"
  },
  {
    id: 46,
    status: "Malaimalar"
  },
  {
    id: 47,
    status: "Malar"
  },
  {
    id: 48,
    status: "Malayala Manorama"
  },
  {
    id: 49,
    status: "Map"
  },
  {
    id: 50,
    status: "Mathrubhumi"
  },
  {
    id: 51,
    status: "MidDay"
  },
  {
    id: 52,
    status: "Mirror-Blr"
  },
  {
    id: 53,
    status: "Missed Call "
  },
  {
    id: 54,
    status: "Mumbai Mirror"
  },
  {
    id: 55,
    status: "Mysooru Mithra"
  },
  {
    id: 56,
    status: "Namnadu"
  },
  {
    id: 57,
    status: "Narathar Reporter"
  },
  {
    id: 58,
    status: "Nav Bharath Times"
  },
  {
    id: 59,
    status: "No Parking"
  },
  {
    id: 60,
    status: "Pamphlet"
  },
  {
    id: 61,
    status: "Pen"
  },
  {
    id: 62,
    status: "PhonePe"
  },
  {
    id: 63,
    status: "Photo"
  },
  {
    id: 64,
    status: "Poorvika Apps"
  },
  {
    id: 65,
    status: "Poorvika calendar"
  },
  {
    id: 66,
    status: "Poorvika contest"
  },
  {
    id: 67,
    status: "Poorvika Dairy"
  },
  {
    id: 68,
    status: "Poorvika Website"
  },
  {
    id: 69,
    status: "Prajavani"
  },
  {
    id: 70,
    status: "Pune Mirror"
  },
  {
    id: 71,
    status: "Radio"
  },
  {
    id: 72,
    status: "Rajasthan Patrika"
  },
  {
    id: 73,
    status: "Regular Customer"
  },
  {
    id: 74,
    status: "Sakal"
  },
  {
    id: 75,
    status: "Sakshi"
  },
  {
    id: 76,
    status: "Sakthi"
  },
  {
    id: 77,
    status: "Showroom"
  },
  {
    id: 78,
    status: "SMS"
  },
  {
    id: 79,
    status: "Social Studio"
  },
  {
    id: 80,
    status: "Star Of Mysore"
  },
  {
    id: 81,
    status: "Tamil Hindu"
  },
  {
    id: 82,
    status: "Tarun Bharath"
  },
  {
    id: 83,
    status: "Thanthi"
  },
  {
    id: 84,
    status: "Ticket"
  },
  {
    id: 85,
    status: "Times Of India"
  },
  {
    id: 86,
    status: "Truecaller"
  },
  {
    id: 87,
    status: "TV Ads"
  },
  {
    id: 88,
    status: "Udayavani"
  },
  {
    id: 89,
    status: "Grabon"
  },
  {
    id: 90,
    status: "Indiastore"
  },
  {
    id: 91,
    status: "Instacred"
  },
  {
    id: 92,
    status: "DuckDuck go"
  },
  {
    id: 93,
    status: "Bing"
  },
  {
    id: 94,
    status: "Yahoo"
  },
  {
    id: 95,
    status: "Becovi"
  },
  {
    id: 96,
    status: "Van"
  },
  {
    id: 97,
    status: "Varthabharati"
  },
  {
    id: 98,
    status: "Vijaya Karnataka"
  },
  {
    id: 99,
    status: "Vijayavani"
  },
  {
    id: 100,
    status: "Visiting Card"
  },
  {
    id: 101,
    status: "Voucher"
  },
  {
    id: 102,
    status: "Wall Paint"
  },
  {
    id: 103,
    status: "Welcome Trichy"
  },
  {
    id: 104,
    status: "whatsapp"
  },
  {
    id: 105,
    status: "Youtube"
  },
  {
    id: 106,
    status: "Zest money"
  },
  {
    id: 107,
    status: "Thanthi - Devathai"
  },
  {
    id: 108,
    status: "Display "
  },
  {
    id: 109,
    status: "Shopping "
  },
  {
    id: 110,
    status: "Video"
  },
  {
    id: 111,
    status: "Kungumam calendar"
  },
  {
    id: 112,
    status: "Kungumam cover"
  },
  {
    id: 113,
    status: "Ipl Card"
  },
  {
    id: 114,
    status: "Invitation"
  },
  {
    id: 115,
    status: "Price baba "
  },
  {
    id: 116,
    status: "Quikr"
  },
  {
    id: 117,
    status: "Sulekha"
  },
  {
    id: 118,
    status: "Yellow pages"
  },
  {
    id: 119,
    status: "Adwords"
  }
];

export const MockBloodGroup = [
  { id: 2, isSelect: false, optionData: "A +" },
  { id: 3, isSelect: false, optionData: "A-" },
  { id: 4, isSelect: false, optionData: "B+" },
  { id: 5, isSelect: false, optionData: "B-" },
  { id: 6, isSelect: false, optionData: "AB+" },
  { id: 7, isSelect: false, optionData: "AB-" },
  { id: 8, isSelect: false, optionData: "O+" },
  { id: 9, isSelect: false, optionData: "O-" }
];

export const MockDeliveryTimeSlot = [
  { id: 1, isSelect: false, optionData: "09Am-12Pm" },
  { id: 2, isSelect: false, optionData: "12Pm-02Pm" },
  { id: 3, isSelect: false, optionData: "02Pm-6Pm" },
  { id: 4, isSelect: false, optionData: "06Pm-9Pm" }
];

export const mockAuditBranches = [
  { id: 1, isSelect: false, optionData: "Sales" },
  { id: 2, isSelect: false, optionData: "Service" },
  { id: 3, isSelect: false, optionData: "Finance" },
  { id: 4, isSelect: false, optionData: "Insurance" }
];

export const assgnedDatamock = [
  { isCheck: false, _id: 1, itemName: "Tamil" },
  { isCheck: false, _id: 2, itemName: "English" },
  { isCheck: false, _id: 3, itemName: "Telugu" },
  { isCheck: false, _id: 4, itemName: "Hindi" },
  { isCheck: false, _id: 5, itemName: "Kannada" },
  { isCheck: false, _id: 6, itemName: "Malayalam" },
  { isCheck: false, _id: 7, itemName: "Marathi" },
  { isCheck: false, _id: 8, itemName: "Bengali" }
];

//state based search use this
export const stateBasedMultiCheck = [
  { isCheck: false, _id: 1, itemName: "Tamil Nadu" },
  { isCheck: false, _id: 2, itemName: "Karnataka" },
  { isCheck: false, _id: 3, itemName: "Pondicherry" },
  { isCheck: false, _id: 4, itemName: "Maharashtra" },
  { isCheck: false, _id: 5, itemName: "Kerala" }
];

export const mockFormInputs = [
  // { id: 0, isSelect: false, optionData: "assign data" },
  { id: 1, isSelect: true, label: "Text", optionData: "text" },
  { id: 2, isSelect: false, label: "Textarea", optionData: "textarea" },
  { id: 3, isSelect: false, label: "Select", optionData: "select" },
  { id: 4, isSelect: false, label: "Checkbox", optionData: "checkbox" },
  { id: 5, isSelect: false, label: "Radio Button", optionData: "radio" },
  { id: 6, isSelect: false, label: "Date", optionData: "date" },
  { id: 6, isSelect: false, label: "Datetime", optionData: "datetime" }
];

export const mockChatFolders = [
  { id: 1, isSelect: true, optionData: "New" },
  // { id: 2, isSelect: false, optionData: "Assign To Me" },
  // { id: 3, isSelect: false, optionData: "All Assigned" },
  { id: 4, isSelect: false, optionData: "Resolved" }
  // { id: 5, isSelect: false, optionData: "Bot Conversation" },
];

export const getAllSubStatus = () => {
  const allStatus = [
    ...[
      ...MockSalesStatusClosed,
      ...MockSalesStatusPending,
      ...MockOnlineOrderStatusPending,
      ...MockOnlineOrderStatusClosed,
      ...salesOutDatedMultiCheck,
      ...MockComplainCurrentStatusGeneralPending,
      ...MockComplaintStatusClosed,
      ...MockComplaintStatusGeneralClosed,
      ...MockComplainCurrentStatus,
      ...MockGeneralInfoPendingCurrentStatus,
      ...MockGeneralInfoClosedCurrentStatus,
      ...MockServiceSupportStatusPending,
      ...MockServiceSupportStatusClosed
    ].map((ele: any) => ele?.optionData),
    ...Object.entries(customerServiceOptions).map((ele: any) => ele[1]?.currentStatus)
  ];
  return allStatus
    .filter(Boolean)
    .sort()
    .map((ele: any, i: number) => {
      return {
        _id: i + 1,
        isCheck: false,
        itemName: ele,
        optionData: ele
      };
    });
};
