import React from "react";
import { Transition } from "@headlessui/react";
import EnquiryForRemark from "../../../EnquiryForRemark";
import { headerButton, inputStylePopup } from "utils/ClassContent";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

const EnquiryStatusHistoryView = (props: any) => {
  const { getData = {}, setShow = true, handleClosePopup = () => {} } = props || {};

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0 top-full"
      enterFrom="opacity-0 top-0"
      enterTo="opacity-100 top-full"
      leaveFrom="opacity-10 top-0"
      enter="transition top-0 duration-300"
      leave="transition top-full duration-300"
    >
      <div id="tab5" className={`flex px-2 pt-4 pb-2 justify-center items-center w-full h-full bg-blue-100`}>
        <div className="h-full w-full">
          <div className="flex justify-between items-center bg-blue-400 text-white w-full px-4 py-2 ">
            <h3 className="w-full text-sm font-bold">
              Updated By {getData.actionBy.name} - {getData.actionBy.empId}
            </h3>
            <div className="flex space-x-2 -mr-1">
              <button type="button" className={headerButton} onClick={handleClosePopup}>
                Close
              </button>
            </div>
          </div>
          <div className={`w-full flex historyTab flex-wrap  h-auto`}>
            <CommonSearchDropdown
              label={true}
              name={"status"}
              dropOnTop={false}
              setShow={true}
              labelText={"Status"}
              readOnly={true}
              value={getData.status}
              inputWithIcon={false}
              inputWithIconClass="w-10"
              placeholder={"--None--"}
              labelTextClass={"font-bold"}
              inputFieldClass="h-9 pr-11"
              inputClass="flex justify-end"
              parentClass={inputStylePopup}
            />
            <CommonSearchDropdown
              label={true}
              setShow={true}
              readOnly={true}
              inputWithIcon={false}
              name={"currentStatus"}
              placeholder={"--None--"}
              inputWithIconClass="w-10"
              value={getData.currentStatus}
              labelText={"Sub Status"}
              labelTextClass={"font-bold"}
              inputFieldClass="h-9 pr-11"
              inputClass="flex justify-end"
              parentClass={inputStylePopup}
            />

            <EnquiryForRemark editView={false} name={"remarkValue"} readOnly={true} value={getData.remarks} />
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default EnquiryStatusHistoryView;
