import React, { Fragment } from "react";
import { ImgSms } from "assets/images";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import TimerButton from "Component/TimerButton";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { sendShowroomSMSService } from "Pages/ServiceCalls/commonServiceCalls";
import { tdClass, thClass, TableClass, tdClassStyle2 } from "utils/ClassContent";

const StockTable = (props: any = {}) => {
  const { stockData = [] } = nullToObject(props);

  const globalStore = useSelector((state: any) => state);
  const { basicInfoReducer: { basicInfoUserinfoData: { name = "", mobile = "" } = {} } = {} } =
    nullToObject(globalStore);

  /**
   * Send sms to customer
   */
  const sendSMS = async (addressDetails = "") => {
    const sendData = {
      mobile: mobile, // add customer mobile number
      customerName: name, // add customer name
      addressDetails: addressDetails
    };
    const sendSMSRes = await sendShowroomSMSService(sendData); //Resend Call
    const { status = 0 } = sendSMSRes || {};

    if (status === 200) {
      successToast("SMS sent Successfully");
    } else {
      failToast("Something went wrong");
    }
  };

  return (
    <div className="w-full px-2 pb-5 overflow-auto" style={{ maxHeight: "calc(100Vh - 150px)" }}>
      <table id="EmployeeTable" className={TableClass + `  max-w-3xl `}>
        <thead>
          <tr className="border whitespace-nowrap">
            <th style={{ minWidth: "150px" }} className={thClass + " w-20"}>
              Branch Name
            </th>
            <th className={thClass + " w-20"}>Stock Count</th>
            <th className={thClass}>
              {" "}
              <span className="inline-block w-56">Address</span>
            </th>
            <th className={thClass + " w-20"}>Contact No</th>
            <th className={thClass + " w-20"}>Delivery Charge</th>
            <th style={{ minWidth: "200px" }} className={thClass}>
              2 Hrs Delivery
            </th>
            <th className={thClass + " w-20"}>Regular Delivery</th>
            <th className={thClass + " w-20"}>Near By</th>
            <th className={thClass + " w-20"}>Send SMS</th>
          </tr>
        </thead>

        {_get(stockData, "length", 0) > 0 && (
          <tbody>
            {stockData.filter((stock: any) => +stock?.stockcount >= 0).map((ele: any, i: number) => {
              return (
                <tr key={convertToLowerCase(i + " newArray-dgd")} className="border">
                  {_get(ele, "branchname", "") ? (
                    <>
                      <td className={tdClass}>{_get(ele, "branchname", "")}</td>

                      <td className={tdClass}>{_get(ele, "stockcount", "")}</td>

                      <td className={tdClass} style={{ maxWidth: "800px" }}>
                        <div className="break-words">{_get(ele, "address", "")}</div>
                      </td>

                      <td className={tdClass}>{_get(ele, "primary_contact_mobile", "")}</td>

                      <td className={tdClass}>{_get(ele, "hrs_deliver_charge", "")}</td>

                      <td className={tdClass}>{_get(ele, "expected_hrs_delivery", "")}</td>

                      <td className={tdClass}>{_get(ele, "expected_regular_delivery", "")}</td>

                      <td className={tdClass}>
                        {_get(ele, "distance", 0)} {"Km"}
                      </td>

                      {_get(ele, "primary_contact_mobile", "") && (
                        <td className={tdClassStyle2}>
                          <div className="flex items-center space-x-3 justify-center">
                            <TimerButton
                              bgImage={ImgSms}
                              buttonSizeInPX={"13px"}
                              customClass={"bg-yellow-500"}
                              activeClass={"bg-yellow-600"}
                              btnTitle={"Send Store Location"}
                              buttonColor={"bg-yellow-500 bg-opacity-10"}
                              handleAction={() =>
                                sendSMS(
                                  _get(ele, "branchname", "") + "  " + decodeURIComponent(_get(ele, "address", ""))
                                )
                              }
                            />
                          </div>
                        </td>
                      )}
                    </>
                  ) : null}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>

      {stockData.length === 0 ? <div className="flex justify-center items-center">Out Of Stocks</div> : null}
    </div>
  );
};

export default StockTable;
