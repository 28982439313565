import { Decrypt } from "utils/secureFile";
import { DateTimeFormeterStart, DateTimeFormeterEnd, nullToObject } from "utils/validation";
import { Get, Post } from "ApiProcess/httpRestServices";
import {
  pimNewApi,
  orgBaseUrl,
  customerInfo,
  salesProduct,
  crmApiBaseUrl,
  apxBaseUrlPath,
  encodeGetParams,
  userBasicDetail,
  pubsubBasePath,
  centralInventoryApi
} from "../../ApiProcess/httpRestServices/apiManage";

type gstType = {
  data?: any;
};

/**
 * @param  {object} obj;
 * get Gst info type
 */
export const getGstDetailService = (data: gstType) => {
  const {
    data: { gstin = "" }
  } = data || {};
  const submitData = {
    gstin
  };
  const queryString = encodeGetParams(submitData);
  return Get(`${crmApiBaseUrl}${customerInfo.gstDetails}?${queryString}`, "crmToken");
};

/**
 *Update basic information
 */
export const getLocateBasicInfoService = (data: any) => {
  const { data: pincode = "" } = data || {};
  return Get(`${orgBaseUrl}${userBasicDetail.areaGet}pincode=${pincode}`, "areaOrgToken");
};

/**
 * get product list
 * @param data
 */
export const getProductListService = (data: gstType) => {
  return Get(
    `${apxBaseUrlPath}${customerInfo.getProductUrl}?CompanyCode=pmpl&CreatedOnStartDate=20220101&CreatedOnEndDate=20220331`,
    "apxToken"
  );
};

/**
 * @param  {object} obj;
 * verify mobile data
 */
export const verifyMobileEmailServices = (data: gstType) => {
  const { data: submitData = {} } = data || {};
  return Post(`${crmApiBaseUrl}${customerInfo.verifyMobileEmail}?`, submitData, "crmToken");
};

/**
 * @param  {object} obj;
 * submit customer info
 */
export const submitCustomerInfoServices = (data: gstType) => {
  const { data: submitData = {} } = data || {};
  return Post(`${crmApiBaseUrl}${customerInfo.saveCustomer}`, submitData, "crmToken");
};

/**
 * validate otp
 */

export const validateOtpServices = (data: gstType) => {
  const { data: submitData = {} } = data || {};
  return Post(`${crmApiBaseUrl}${customerInfo.validateOtp}?`, submitData, "crmToken");
};

/**
 * merge request
 */
export const mergeRequestCustomerServices = (data: gstType) => {
  const { data: submitData = {} } = data || {};
  return Post(`${crmApiBaseUrl}${customerInfo.mergeRequest}?`, submitData, "crmToken");
};

/**
 * get customer Details
 */

export const getCustomerDetailServices = (data: gstType) => {
  const { data: { id = "" } = {} } = data || {};
  return Get(`${crmApiBaseUrl}${customerInfo.getCustomerDetails}${id}?`, "crmToken");
};

/**
 * get customer Details
 */

export const getCustomerAddressServices = (id = "") => {
  return Get(`${crmApiBaseUrl}${customerInfo.getCustomerAddress}${id}?`, "crmToken");
};

/**
 * @param  {object} obj;
 * get cuslist data
 */
export const getCustomerListServices = (obj: any = {}) => {
  const { data = {} } = obj;
  const newObj = {};
  Object.keys(data).forEach((itm: any = "") => {
    if (data[itm]) {
      newObj[itm] = data[itm];
    }
  });
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${customerInfo.customerList}${consvertString}`, "crmToken");
};

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const uploadCustomerListData = (obj: any = {}) => {
  const newObj = {
    ...obj,
    startDate: obj.startDate ? DateTimeFormeterStart(obj.startDate) : "",
    endDate: obj.endDate ? DateTimeFormeterEnd(obj.endDate) : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${customerInfo.uploadCustomerList}${consvertString}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const getPaymentMethod = async () => {
  return await Get(`${pubsubBasePath}${customerInfo.getPaymentMethod}`, "NoToken");
};

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const fetchDeliveryTimeDetails = (newObj: any) => {
  const queryString = encodeGetParams(newObj);
  return Get(`${centralInventoryApi}${salesProduct.deliveryTypeGet}?${queryString}`, "getDeliveryTypeToken");
};

/**
 * @param  {object} obj;
 * save order
 */
export const saveOrderService = async ({
  addressData,
  exchangeData,
  customer,
  productData,
  otherInfoData,
  gstData,
  notification,
  deliverData,
  paymentData,
  coupon,
  messageType
}: any = {}) => {
  const { DA: { address_id = "" } = {}, BA: { address_idBA = "" } = {}, type = "" } = addressData;
  const { deliveryType = "" } = deliverData || {};
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { details: [{ employee_branch_code = "" }] = [] } = {} } = nullToObject(userLoginDetails);
  const reqObj = {
    address_id: address_id,
    billing_address_id: type === "Both" ? address_id : address_idBA,
    customer_id: customer.cus_id,
    products: productData.map((item: any = {}) => ({
      product_id: parseInt(item.productId),
      item_code: item.itemCode,
      slug: item.slug,
      quantity: parseInt(item.quantity),
      adld: [],
      content: {
        mode: deliveryType === "home" ? "Regular Delivery" : "Pickup@store",
        charges: "0",
        pickup: deliveryType === "home" ? "" : employee_branch_code
      }
    })),
    coupon: coupon,
    payment_method: paymentData.payment_client,
    code: paymentData.code,
    messageType
  };
  const queryParam = {
    orderId: customer.orderId,
    shop_code: employee_branch_code,
    show_room: deliverData?.showRoom
  };

  const queryString = encodeGetParams(queryParam);
  return await Post(`${crmApiBaseUrl}${customerInfo.saveOrderMethod}?${queryString}`, reqObj, "crmToken");
};

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const updatePaymentStatus = async (externalOrderId = "") => {
  return await Get(
    `${crmApiBaseUrl}${customerInfo.updatePaymentStatus}?externalOrderId=${externalOrderId}`,
    "crmToken"
  );
};

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const cancelOrderService = async (externalOrderId = "") => {
  return await Post(`${crmApiBaseUrl}${customerInfo.cancelOrder}?externalOrderId=${externalOrderId}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * upload cuslist data
 */
export const resendPaymentLinkService = async (_id = "") => {
  return await Post(`${crmApiBaseUrl}${customerInfo.resendPaymentOrder}?orderId=${_id}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * validate coupon
 */
export const validateCouponService = async (data: any = {}) => {
  return await Post(`${pimNewApi}${customerInfo.validateCoupon}`, data, "offerToken");
};

/**
 * get checkout coupon list
 */
export const getCheckoutCouponList = async (data: any = {}) => {
  return await Post(`${pimNewApi}${customerInfo.getCheckCoupon}`, data, "offerToken");
};
