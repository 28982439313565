import React from "react";
import { convertToLowerCase } from "utils/validation";

export const ProductCardTable = (props: any) => {
  const { products } = props;

  return products?.map((product: any, index: any) => {
    return (
      <React.Fragment key={convertToLowerCase("" + index + "products-jhds")}>
        <div className="product-multiField-box">
          {index !== 0 && (
            <div className="btn-Wrapper">
              <p>+</p>
            </div>
          )}
          <div className="product-multiField flex px-4 py-2 flex-col sm:flex-row">
            <div className="inline-flex flex-row lg:pl-0 ">
              <img
                className="h-auto w-28 sm:w-40 object-contain"
                alt={"combo-img" + index}
                src={product?.gallery?.length > 0 ? product?.gallery[0].media.url : ""}
              />
            </div>
            <div className="flex flex-col">
              <p className="font-bold text-sm">{product?.model?.name}</p>
              <div className="variant flex flex-wrap mt-1">
                <div className="flex flex-wrap">
                  <h4 className="mr-2 font-semibold ">Available Models:</h4>
                  {product.addVariant &&
                    product?.addVariant?.map((variantData: any, i: number) => {
                      return (
                        <React.Fragment key={convertToLowerCase(i + "product.addVariants-dhd")}>
                          <p className="flex mx-1">
                            (
                            {Object.keys(variantData["attr"])?.map((varian: any) => {
                              return variantData?.attr[varian] + ",";
                            })}
                            {variantData?.erp_item_code ? variantData?.erp_item_code : "not provided"})
                          </p>
                          {i !== product?.addVariant?.length - 1 && <p> | </p>}
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
              <div className="product-price flex mt-1">
                <h4 className="mr-2 font-semibold">Price:</h4>
                <p className="lineThrough line-through mr-2 text-red-600 font-bold ">{`₹ ${
                  product?.mrpPrice ? product?.mrpPrice : "Price Not Available"
                }`}</p>
                {product?.offerPrice && <p className=" font-bold text-green-600">{`₹ ${product?.offerPrice} `}</p>}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  });
};

export default ProductCardTable;
