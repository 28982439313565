import React from "react";
import { bg_BasicInfo } from "utils/ClassContent";
import CommonDropHeading from "Component/CommonDropHeading";
import BasicInfoView from "Pages/Customers/CallsList/BasicInfoView";

/**
 * Basic Info Drop Heading
 * @param props props
 * @returns Shows basic customer info on enquiry popup
 */
const BasicInfoDropHeading = (props: any = {}) => {
  const { editView = false, customerData = {}, popParentTabs = "", handleEditView = () => {} } = props || {};

  return (
    <CommonDropHeading
      id="BasicInfo"
      heading="Basic Info"
      customClass={bg_BasicInfo}
      tabActiveDefault={popParentTabs === "BasicInfo" ? true : false}
    >
      <BasicInfoView customerData={customerData} enableViewMode={!editView} handleEditView={handleEditView} />
    </CommonDropHeading>
  );
};

export default BasicInfoDropHeading;
