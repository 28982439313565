import { nullToObject } from "utils/validation";
import { Get, Post, withoutTokenPost } from "ApiProcess/httpRestServices";
import {
  crmApiBaseUrl,
  emiApi,
  encodeGetParams,
  offerProjectEndPath,
  pimNewApi,
  pubsubBasePath,
  salesProduct,
  typesenceBasePath
} from "../../ApiProcess/httpRestServices/apiManage";

/**
 *get aceess token
 */
export const typeSenseAccessTokenservices = () => {
  return {};
  // return Get(`${goLangApiPath}${emiApi.tokenAccecc}`, "NoToken");
};

/**
 *EmiProduct details
 *search base get product details
 */
export const getEmiProductServices = (obj: any = {}) => {
  const { modalName = "" } = nullToObject(obj);
  const newObj = {
    searches: [
      {
        q: modalName,
        page: 1,
        per_page: 20,
        max_facet_values: 70,
        collection: "productsnewshop",
        query_by_weights: "4,3,2,1",
        sort_by: "review_count:desc",
        query_by: "name,categories,item_code,sku"
      }
    ]
  };
  return Post(`${typesenceBasePath}${salesProduct.product}`, newObj, "typesenseDetailsToken");
};

/**
 * @param obj online emi payload details
 * @returns online emi response
 */
export const fetchOnlineEmiDetails = (obj: any = {}) => {
  const queryString = encodeGetParams(obj);
  return Get(`${pubsubBasePath}${emiApi.productEmi}?${queryString}`, "crmToken");
};

/**
 *Offline emi offer and bank offer details
 *caluculator
 */

export const fetchOfflineEmiDetails = (obj: any = {}) => {
  return Post(`${pubsubBasePath}${emiApi.offlineProductEmi}`, obj, "crmToken");
};

/**
 *Offline emi offer and bank offer details
 *caluculator
 */

export const fetchSalesOfflineEmiDetails = (obj: any = {}) => {
  const queryString = encodeGetParams(obj);
  return Get(`${pubsubBasePath}${emiApi.productEmi}?${queryString}`, "crmToken");
};

export const offerRuleEngineGetNbfcOffer = (itemNumber: any) => {
  const newObjHeader = {
    locale: "en-IN",
    channel: "SHOP"
  };
  return Get(`${pimNewApi}${offerProjectEndPath.emiOffer}${itemNumber}`, "offerToken", newObjHeader);
};

export const onlineOfferRuleEngineGetNbfcOffer = (itemNumber: any) => {
  const newObjHeader = {
    locale: "en-IN",
    channel: "ONLINE"
  };
  return Get(`${pimNewApi}${offerProjectEndPath.emiOffer}${itemNumber}`, "offerToken", newObjHeader);
};

/**
 *emi offer and bank offer details
 *pinePg caluculator
 */
export const getEmiOfferDetailsServices = (obj: any = {}) => {
  const { newObj: { productPrice = "", skuId = "" } = {} } = nullToObject(obj);
  const addZero = productPrice + "00";
  const data = {
    merchant_data: {
      merchant_id: 22400,
      merchant_access_code: "aa952be5-cb67-45bd-a0ca-1da1d33cf225"
    },
    payment_data: {
      amount_in_paisa: +addZero
    },
    product_details: [
      {
        product_code: skuId,
        product_amount: +addZero
      }
    ]
  };
  //--->url
  //data is ---> bodyContent
  //false normal token is need or not
  // true mean pinePg token set
  return withoutTokenPost(`${crmApiBaseUrl}${emiApi.pinePgEmiCalculate}`, data);
};
