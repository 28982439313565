import { _get } from "utils/lodashConst";

export const commonInitState = {
  newUserPopup: false,
  justDialLeadDetails: {}
};

export const commonChnagesReducer = (state = commonInitState, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "COMMOM_INITIAL_STAGE": {
      return {
        ...state,
        ...commonInitState
      };
    }
    //all reasult data store here
    case "NEW_USER_REGISTER_CREATE": {
      return {
        ...state,
        newUserPopup: _get(action, "newUserPopup", "")
      };
    }

    //justDial lead create
    case "ENQUIRY_CREATE_BASED_ON_LEAD_DETAILS": {
      return {
        ...state,
        justDialLeadDetails: _get(action, "justDialLeadDetails", {})
      };
    }

    default:
      return {
        ...state
      };
  }
};
