import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const positionToast = "bottom-left"; /*position to place */

const toastService = {
  defaultToast: (content = "") =>
    toast(content, {
      autoClose: 2000,
      draggable: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast
    }),

  successToast: (message = "", Id = "") =>
    toast.success(message, {
      toastId: Id,
      autoClose: 2000,
      draggable: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast
    }),

  errorToast: (message = 1, _customId = "") =>
    toast.error(message, {
      autoClose: 2000,
      draggable: true,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: _customId,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast
    }),

  warnToast: (message = "", warnToast = "") =>
    toast.warn(message, {
      autoClose: 2000,
      draggable: true,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: warnToast,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast
    }),

  infoToast: (message = "", _informId = "") =>
    toast.info(message, {
      autoClose: 2000,
      draggable: true,
      closeOnClick: true,
      pauseOnHover: true,
      toastId: _informId,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast
    }),

  networkToast: (message = "", toastId = "") =>
    toast.error(message, {
      draggable: true,
      toastId: toastId,
      autoClose: false,
      pauseOnHover: true,
      closeOnClick: false,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast,
      className: "networkToastError"
    }),

  copyToast: (message = "", toastId = "") =>
    toast.success(message, {
      autoClose: 2000,
      draggable: true,
      toastId: toastId,
      closeOnClick: false,
      pauseOnHover: false,
      progress: undefined,
      hideProgressBar: true,
      position: positionToast
    }),
  dismissToast: (toastId = "") => toast.dismiss(toastId)
};
export default toastService;
