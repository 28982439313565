import React, { useEffect, useState } from "react";
import Loader from "Component/Loader";
import { moment } from "utils/momentConst";
import { Decrypt } from "utils/secureFile";
import { nullToObject } from "utils/validation";
import EvaluatorReportsList from "./EvaluatorReportsList";
import { CALL_ROLES, CHAT_ROLES } from "utils/VariableConstant";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import DatePickerCommon from "Component/CommonTable/CommonStartToEndDatePic/DatePickerCommon";
import { getScoreDataApiCallApiServices } from "../ScorecardEditor/ScorecardEditorPages/CommonScoreCard";
import { getEvaluatorReports } from "../helper";

const EvaluatorReports = (props: any = {}) => {
  // const { scorecardReducer = {} } = globalSate; //globalStore
  const [evaluatorReports, setEvaluatorReports] = useState([]);
  const [scoreCardDropProps, setScoreCardDropProps] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const [userSearch, setUserSearch] = useState({
    page: 1,
    size: 10,
    search: "",
    userId: [],
    endDate: moment(),
    startDate: moment().subtract(6, "d"),
    scoreCardId: []
  });

  const _onDateChange = (event: any = {}) => {
    const { target: { nameEvalue: endDate = "", value: startDate = "" } = {} } = event;
    setUserSearch({ ...userSearch, startDate, endDate });
  };

  const scorecardFilter = (event: any = {}) => {
    const { originalArray = [] } = event;
    const filterCheck = originalArray.filter((ele: any) => ele.isCheck === true);
    const getId = filterCheck.map((ele: any) => ele._id);
    setScoreCardDropProps(originalArray);
    setUserSearch((ev: any = {}) => ({ ...ev, scoreCardId: getId }));
  };

  const getScoreDataApiCall = async (payload) => {
    setLoader(true);
    const response = await getScoreDataApiCallApiServices(payload);
    setLoader(false);
    const { data: { data = {}, status = 0 } = {} } = response;
    if (status === 200) {
      if (Object.keys(data)?.length) {
        if (data?.data?.length) {
          setScoreCardDropProps(data.data);
        } else {
          setScoreCardDropProps([]);
        }
      }
    } else {
    }
  };

  const fetchData = async () => {
    try {
      const response = await getEvaluatorReports(userSearch);
      const { data: { data = {}, status = 0 } = {} } = nullToObject(response);
      if (status === 200) {
        setEvaluatorReports(data);
      } else {
        setEvaluatorReports([]);
      }
    } catch (err) {
      setEvaluatorReports([]);
    }
  };

  useEffect(() => {
    const loginUserDetails: any = Decrypt("userLoginData") || {};
    const {
      data: { roleId = "" }
    } = nullToObject(loginUserDetails);

    const isChat = CHAT_ROLES.includes(parseInt(roleId)),
      isCall = CALL_ROLES.includes(parseInt(roleId));
    getScoreDataApiCall({ type: isChat ? "chat" : isCall ? "call" : "" });
  }, []);

  useEffect(() => {
    fetchData();
  }, [userSearch]);

  return (
    <React.Fragment>
      {loader && <Loader />}
      <div className=" w-full text-gray-800">
        <div className="flex justify-between items-center border-b pb-1">
          <div className="flex justify-between items-center text-base font-bold">
            <h4>Evaluator Reports</h4>
          </div>
          <div className="flex items-center">
            <DatePickerCommon userSearch={userSearch} _onDateChange={_onDateChange} />
            <MultiDropDownNew
              name={"name"}
              bindKeyName={"name"}
              labeName={"Scorecard"}
              isSelectAll={true}
              enableCheckbox={true}
              isOKButtonEnable={true}
              arrayData={scoreCardDropProps}
              filterArrayPass={scorecardFilter}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <EvaluatorReportsList data={evaluatorReports} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default EvaluatorReports;
