import store from "store";
import { Get, Post, Put } from "ApiProcess/httpRestServices";
import { reasonforSalesReturnDrop } from "Component/MockData";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import {
  apxBaseUrlPath,
  salesReturn,
  crmApiBaseUrl,
  encodeGetParams,
  duplicateInv,
  orgBaseUrl,
  comListPageApi
} from "../../ApiProcess/httpRestServices/apiManage";

type imeiType = {
  data?: any;
  imei?: string;
  salesRetunData?: any;
  enquiryFor?: string;
};
/**
 * @param  {object} obj;
 * imei number passed
 */
export const getImeiNumberBasedServices = (obj: imeiType = {}) => {
  const { imei = "" } = obj || {};
  return Get(`${apxBaseUrlPath}${salesReturn.imeiDetail}${imei}`, "apxToken");
};

/**
 *  @param {object} object
 * get employee detail
 */

export const getEmployeeDetailServices = (obj: any = {}) => {
  // console.log("im hero")
  const { data: { empId = 0 } = {} } = obj;
  return Get(`${orgBaseUrl}${comListPageApi.empDetail}=${empId}`, "empoyeeDetailToken");
};
/**
 * @param  {object} obj;
 * imei number passed get Details
 */
export const getImeinumberBasedTransService = (obj: any = {}) => {
  const { imei = {} } = obj || {};
  const { imeiNum = "", enquiryFor = "" } = imei;
  const param = {
    size: 50,
    imei: imeiNum,
    mobileNo: "",
    reqType: "imei-mobile",
    enquiryFor: enquiryFor
  };
  // enquiryFor
  const consvertString = encodeGetParams(param);
  return Get(`${crmApiBaseUrl}${salesReturn.userTransDataDetails}${consvertString}`, "crmToken");
};

/**
 * get sales retun only
 */
export const uploadSalesRetunData = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    search = "",
    status = "",
    endDate = null,
    enquiryFor = "",
    callType = null,
    startDate = null,
    subCategory = "",
    currentStatus = "",
    createdByState = []
  } = obj || {};

  const newObj = {
    page,
    size,
    search,
    enquiryFor,
    subCategory,
    currentStatus,
    createdByState,
    status: status !== "All" ? status : "",
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    callType: callType === true ? true : callType === false ? false : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${salesReturn.uploadSlaseRetunData}?${consvertString}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * services center list token
 */
export const updateSalesRetunStatusServices = (datas: any = {}) => {
  const { data = {} } = datas || {};
  const { customerId, ...restCusId } = data;
  const queryString = encodeGetParams(restCusId);
  return Put(`${crmApiBaseUrl}${salesReturn.slaseRetunData}/${customerId}?${queryString}`, {}, "crmToken");
};

const problemHandleCommon = (arrayData = []) => {
  return arrayData
    .map((ele: any) => {
      if (ele.isSelect === true) {
        return ele.optionData;
      }
      return undefined;
    })
    .filter((ele: any) => ele !== undefined);
};

/**
 * @param  {object} obj;
 * update sales return data details
 */
export const createsalesReturnServices = (salesRetunData: imeiType) => {
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;

  const { basicInfoReducer: { basicInfoUserinfoData: { customerId = "", name = "", mobile = "" } = {} } = {} } =
    storeData;

  const { data = {} } = salesRetunData || {};

  const {
    enquireFor = {},
    passDataGenralData = {},
    passDoaDetails = {},
    passImeiNumber = {},
    passRefunData = {},
    passSalesReturnData = {},
    replacePassdata = {},
    srSubCategory = {}
  } = data;

  const { imeiNumber = "", callType = true } = passImeiNumber;
  const { enquireFor: enquireForValue = "", remarkData = "" } = enquireFor;

  const { srSubCategory: srSubCategoryValue = "" } = srSubCategory;

  const {
    billDate = "",
    billNo = "",
    branchName = "",
    cusName = "",
    invoiceAmount = "",
    itemBrandName = "",
    itemGrupName = "",
    paymentMode = "",
    itemName = "",
    mobileNumber = "",
    itemCode = ""
  } = passDataGenralData;
  //salesReurn data
  const {
    srnValue = "",
    ageOfDevice = "",
    selectedProblem = [],
    problemDescription = "",
    subselectedProblem = [],
    reasonforSalesReturn = ""
  } = passSalesReturnData;

  //DOA data
  const {
    doaJobSheetDate = "",
    doaServiceCenter = "",
    doaimagRenderArry = [],
    doaCertificateDate = "",
    doaCertificateImei = "",
    doaCertificateNumber = ""
  } = passDoaDetails;

  const {
    relaceEmpId = "",
    relaceEmpName = "",
    relaceProduct = "",
    relaceDepartment = "",
    relaceDesignation = "",
    relaceSellingPrice = "",
    relaceimagRenderArry = []
  } = replacePassdata;

  const {
    refundDepatmet = "",
    refundDesignation = "",
    refundEmpId = "",
    refundEmpName = "",
    refundImages = [],
    refundRequestAmt = ""
  } = passRefunData;

  const problem = problemHandleCommon(selectedProblem);

  const subProblem = problemHandleCommon(subselectedProblem);

  const formData = new FormData();
  const imageArray = [...relaceimagRenderArry, ...doaimagRenderArry, ...refundImages];

  if (imageArray.length > 0) {
    for (const file of imageArray) {
      formData.append("commonImgFile", file.file); // appending every file to formdata
    }
  }

  const reasonReturn: any = reasonforSalesReturnDrop.find((ele: any) => ele.optionData === reasonforSalesReturn);
  const returnId = reasonReturn.id;
  const submitData = {
    cusId: customerId,
    billNo: billNo,
    imei: imeiNumber,
    problem: problem,
    callType: callType,
    itemCode: itemCode,
    billDate: billDate,
    itemName: itemName,
    srnValue: srnValue,
    remarks: remarkData,
    customerName: name,
    deviceAge: ageOfDevice,
    branchName: branchName,
    paymentMode: paymentMode,
    enquiryFor: enquireForValue,
    itemGroupName: itemGrupName,
    itemBrandName: itemBrandName,
    invoiceAmount: invoiceAmount,
    subCategory: srSubCategoryValue,
    problemCategory: subProblem,
    problemDesc: problemDescription,
    imeiCustomerName: cusName,
    imeiMobileNo: mobileNumber,
    mobileNo: mobile === null ? "" : mobile,
    leadDetails: justDialLeadDetails, //justDial lead create
    reasonForSalesReturn: {
      reason: reasonforSalesReturn,
      doaCertificateDate: returnId === 1 ? doaCertificateDate : "",
      doaCertificateNo: returnId === 1 ? doaCertificateNumber : "",
      doaJobSheetDate: returnId === 1 ? doaJobSheetDate : "",
      doaServiceCenter: returnId === 1 ? doaServiceCenter : "",
      doaIMEINo: returnId === 1 ? doaCertificateImei : "",

      replacedProduct: returnId === 2 ? relaceProduct : "",
      replacedSellPrice: returnId === 2 ? relaceSellingPrice : "",
      employeeId: returnId === 2 ? relaceEmpId : returnId === 3 ? refundEmpId : "",
      employeeName: returnId === 2 ? relaceEmpName : returnId === 3 ? refundEmpName : "",
      employeeDept: returnId === 2 ? relaceDepartment : returnId === 3 ? refundDepatmet : "",
      employeeDesignation: returnId === 2 ? relaceDesignation : returnId === 3 ? refundDesignation : "",
      refundReqAmt: returnId === 3 ? refundRequestAmt : ""
    },
    isQuery: "t"
  };
  const queryString = encodeGetParams(submitData);
  return Post(`${crmApiBaseUrl}${salesReturn.slaseRetunData}?${queryString}`, formData, "crmToken");
};

/**
 * @param  {object} obj;
 * services center list token
 */
export const updateSalesRetunStatusEditServices = (datas: any = {}) => {
  const { data = {} } = datas || {};
  const { originalElement: { _id = "", mobileNo = "", customerName = "" } = {} } = data;

  const {
    enquireFor = {},
    passDataGenralData = {},
    passDoaDetails = {},
    passImeiNumber = {},
    passRefunData = {},
    passSalesReturnData = {},
    replacePassdata = {},
    srSubCategory = {}
  } = data;

  const { imeiNumber = "" } = passImeiNumber;
  const { remarkData = "" } = enquireFor;

  const { srSubCategory: srSubCategoryValue = "" } = srSubCategory;

  const {
    billDate = "",
    billNo = "",
    branchName = "",
    cusName = "",
    invoiceAmount = "",
    itemBrandName = "",
    itemGrupName = "",
    paymentMode = "",
    itemName = "",
    mobileNumber = "",
    itemCode = ""
  } = passDataGenralData;
  //salesReurn data
  const {
    srnValue = "",
    ageOfDevice = "",
    selectedProblem = [],
    problemDescription = "",
    subselectedProblem = [],
    reasonforSalesReturn = ""
  } = passSalesReturnData;

  //DOA data
  const {
    doaJobSheetDate = "",
    doaServiceCenter = "",
    doaimagRenderArry = [],
    doaCertificateDate = "",
    doaCertificateImei = "",
    doaCertificateNumber = ""
  } = passDoaDetails;

  const {
    relaceEmpId = "",
    relaceEmpName = "",
    relaceProduct = "",
    relaceDepartment = "",
    relaceDesignation = "",
    relaceSellingPrice = "",
    relaceimagRenderArry = []
  } = replacePassdata;

  const {
    refundDepatmet = "",
    refundDesignation = "",
    refundEmpId = "",
    refundEmpName = "",
    refundImages = [],
    refundRequestAmt = ""
  } = passRefunData;

  const problem = problemHandleCommon(selectedProblem);

  const subProblem = problemHandleCommon(subselectedProblem);

  const formData = new FormData();
  const imageArray = [...relaceimagRenderArry, ...doaimagRenderArry, ...refundImages];

  if (imageArray.length > 0) {
    for (const file of imageArray) {
      formData.append("commonImgFile", file.file); // appending every file to formdata
    }
  }

  const reasonReturn: any = reasonforSalesReturnDrop.find((ele: any) => ele.optionData === reasonforSalesReturn);
  const returnId = reasonReturn.id || "";
  const submitData = {
    billNo: billNo,
    imei: imeiNumber,
    problem: problem,
    mobileNo: mobileNo,
    itemCode: itemCode,
    billDate: billDate,
    itemName: itemName,
    srnValue: srnValue,
    remarks: remarkData,
    deviceAge: ageOfDevice,
    branchName: branchName,
    paymentMode: paymentMode,
    imeiCustomerName: cusName,
    customerName: customerName,
    imeiMobileNo: mobileNumber,
    enquiryFor: "Sales Returns",
    itemGroupName: itemGrupName,
    problemCategory: subProblem,
    itemBrandName: itemBrandName,
    invoiceAmount: invoiceAmount,
    subCategory: srSubCategoryValue,
    problemDesc: problemDescription,
    reasonForSalesReturn: {
      reason: reasonforSalesReturn,
      doaCertificateDate: returnId === 1 ? doaCertificateDate : "",
      doaCertificateNo: returnId === 1 ? doaCertificateNumber : "",
      doaJobSheetDate: returnId === 1 ? doaJobSheetDate : "",
      doaServiceCenter: returnId === 1 ? doaServiceCenter : "",
      doaIMEINo: returnId === 1 ? doaCertificateImei : "",

      replacedProduct: returnId === 2 ? relaceProduct : "",
      replacedSellPrice: returnId === 2 ? relaceSellingPrice : "",
      employeeId: returnId === 2 ? relaceEmpId : returnId === 3 ? refundEmpId : "",
      employeeName: returnId === 2 ? relaceEmpName : returnId === 3 ? refundEmpName : "",
      employeeDept: returnId === 2 ? relaceDepartment : returnId === 3 ? refundDepatmet : "",
      employeeDesignation: returnId === 2 ? relaceDesignation : returnId === 3 ? refundDesignation : "",
      refundReqAmt: returnId === 3 ? refundRequestAmt : ""
    },
    isQuery: "t"
  };
  const queryString = encodeGetParams(submitData);
  return Put(`${crmApiBaseUrl}${salesReturn.slaseRetunData}/${_id}?${queryString}`, formData, "crmToken");
};

/**
 * get transactions by customer details for using Duplicate Inv
 */
export const historyTransactionBasedgetUserDetails = (user: any = {}) => {
  const { data: { inVoiceNo = "", inVoiceDate = "" } = {} } = user;
  return Get(
    `${apxBaseUrlPath}${duplicateInv.invNoAndInvDate}Invoice_No=${inVoiceNo}&Invoice_Date=${inVoiceDate}`,
    "apxToken"
  );
};
