import * as ameyoType from "../actionTypes/ameyoType";
import { put, call, takeLatest } from "redux-saga/effects";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { initiateAmeyoCallServices, getCallRecordingServices } from "utils/services/ameyoService";

/**
 * @param  {object} obj
 * get details data from IMEI number based data
 */
function* initiateAmeyoCall(obj = {}): any {
  try {
    const response = yield call(initiateAmeyoCallServices, obj);
    const { data: { message = "", status = 0 } = {} } = response;
    if (status === 201) {
      successToast(message);
      yield put({ type: "AMEYO_CALL_LOADER", ameyoLoader: false });
    } else {
      yield put({ type: "AMEYO_CALL_LOADER", ameyoLoader: false });
      failToast(message);
    }
  } catch (error) {
    yield put({ type: "AMEYO_CALL_LOADER", ameyoLoader: false });
    failToast("Something went wrong");
  }
}

/**
 * @param  {object} obj
 * get Call recording data
 */
function* getCallRecordingCall(obj = {}): any {
  try {
    const response = yield call(getCallRecordingServices, obj);
    const { data: { data = {}, message = "", status = 0 } = {} } = response;
    if (status === 200 && data) {
      successToast(message || "Please wait while downloading recording ...");
      yield put({ type: "GET_CALL_RECORDING_LOADER", callRecordingLoader: false });
      yield put({ type: "GET_CALL_RECORDING_DATA", callRecordingData: data });
    } else {
      yield put({ type: "GET_CALL_RECORDING_DATA", callRecordingData: {} });
      yield put({ type: "GET_CALL_RECORDING_LOADER", callRecordingLoader: false });
      failToast("No recording found for this update");
    }
  } catch (error) {
    yield put({ type: "GET_CALL_RECORDING_DATA", callRecordingData: {} });
    yield put({ type: "GET_CALL_RECORDING_LOADER", callRecordingLoader: false });
    failToast("Something went wrong");
  }
}

export default function* ameyoSaga() {
  yield takeLatest(ameyoType.AMEYO_CALL_ACTION, initiateAmeyoCall);
  yield takeLatest(ameyoType.GET_CALL_RECORDING_ACTION, getCallRecordingCall);
}
