import { _get } from "utils/lodashConst";
import { Decrypt, moveToLogOut } from "utils/secureFile";
import { nullToArray, nullToObject } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";

export const pageNumber = {
  Home: 1,
  New_Call: 2,
  Call_History: 3,
  Call_History_All_Calls: 4,
  Call_History_All_Follow: 5,
  Employee_Enquiry_Details: 6,
  Sales_Return: 7,
  Sales: 8,
  Sales_Sales_Enquiries: 9,
  Sales_Follow_Up: 10,
  Service: 11,
  Service_Product_Service: 12,
  Service_Product_Service_ALL: 13,
  Service_Product_Service_New: 14,
  Service_Product_Service_In_Transit: 15,
  Service_Product_Service_Received_From_Store: 16,
  Service_Product_Service_Pending_At_Service_Center: 17,
  Service_Product_Service_Pending_To_Send_Store: 18,
  Service_Product_Service_Received_From_HO: 19,
  Service_Product_Service_Pending_To_Inform_Customer: 20,
  Service_Product_Service_Informed_Customer: 21,
  Service_Product_Service_Delivered: 22,
  Service_Service_Support: 23,
  Service_Duplicate_Invoice: 24,
  Service_General_Information: 25,
  Complaints: 26,
  Complaints_Evaluate_Complaints: 27,
  Complaints_Followup_and_Closures: 28,
  Complaints_Advocates: 29,
  Non_ealing: 30,
  Campaigns: 31,
  Online_Queries: 32,
  All_Orders: 33,
  All_Orders_All_Orders: 34,
  Just_Dial: 35,
  SERVICE_CENTERS: 36,
  Mail_Box: 37,
  Feedback_Contest: 38,
  Customer_Info: 39,
  Quality_Scorecard: 40,
  Quality_Scorecard_Dashboard: 41,
  Quality_Scorecard_Dashboard_Global_Dashboard: 42,
  Quality_Scorecard_Dashboard_Manger_Dashboard: 43,
  Quality_Scorecard_Dashboard_TeamLead_Dashboard: 44,
  Quality_Scorecard_Dashboard_Agent_Dashboard: 45,
  Quality_Scorecard_Dashboard_Evaluator_Dashboard: 46,
  Quality_Scorecard_Scorecard_Editor: 47,
  Quality_Scorecard_Scorecard_Editor_Calls_Scorecard: 48,
  Quality_Scorecard_Scorecard_Editor_Chat_Scorecard: 49,
  Quality_Scorecard_Audit_Scorecard: 50,
  Quality_Scorecard_Audit_Scorecard_Scorecard_List: 51,
  Quality_Scorecard_Reports: 52,
  Quality_Scorecard_Reports_General_Reports: 53,
  Quality_Scorecard_Reports_Audit_History: 54,
  Quality_Scorecard_Reports_Evaluations: 55,
  Quality_Scorecard_Reports_Question_Analysis: 56,
  Quality_Scorecard_Reports_Evaluations_Per_Period: 57,
  Quality_Scorecard_Reports_Result_Acceptance_By_Agent: 58,
  Quality_Scorecard_Reports_Kudos_By_Staff: 59,
  Transactions: 60,
  Campaign_Transactions: 61,
  Contacts: 62,
  Contacts_Contacts: 63,
  Super_Access: 64,
  Super_Access_Users: 65,
  Super_Access_Setting: 66,
  Chat: 67,
  Chat_Dashboard: 68,
  Chat_Team_Inbox: 69,
  Chat_User: 70,
  Chat_Analytics: 71,
  Chat_Admin_Settings: 72,
  AMEYO_CALL: 73,
  ONLINE_PRICE_DETAILS: 74,
  OFFLINE_PRICE_DETAILS: 75,
  SALES_SMS_HANDLE: 76,
  SERVICE_MAIL_HANDLE: 77,
  COMPLAIN_SMS_HANDLE: 78,
  ONLINE_MAIL_HANDLE: 79,
  SERVICE_CENTER_MAIL_HANDLE: 80,
  CHECK_QC: 81,
  OFFER_AND_PRICE_DETAILS: 82,
  ONLINE_OFFER_HANDLE: 83,
  OFFLINE_OFFER_HANDLE: 84,
  BOTH_ONLINE_AND_OFFLINE_OFFER_HANDLE: 85,
  ALL_ORDERS_PS_ORDERS: 86,
  ALL_ORDERS_CART: 87,
  ALL_ORDERS_WISH_LIST: 88,
  NOTIFY_ME: 89,
  ALL_ORDERS_ANONYMOUS_CART: 90,
  CAMPAIGNS_CAMPAIGNS: 91,
  PRODUCT_QR_GENERATOR: 92,
  DOWNLOAD: 93,
  Service_Product_Service_Received_From_Service_Center: 94,
  SALES_RETURN_DOWNLOAD: 95,
  SALES_SALES_ENQUIRIES_EXCEL_DOWNLOAD: 96,
  ALL_CALLS_EXCEL_DOWNLOAD: 97,
  FOLLOW_UP_EXCEL_DOWNLOAD: 98,
  SALES_FOLLOW_UP_EXCEL_DOWNLOAD: 99,
  ENQUIRY_DETAILS_EXCEL_DOWNLOAD: 100,
  NON_DEALING_EXCEL_DOWNLOAD: 101,
  ONLINE_QUERIES_EXCEL_DOWNLOAD: 102,
  JUST_DIAL_EXCEL_DOWNLOAD: 103,
  SERVICE_CENTERS_EXCEL_DOWNLOAD: 104,
  NOTIFY_ME_EXCEL_DOWNLOAD: 105,
  FEEDBACK_CONTEST_EXCEL_DOWNLOAD: 106,
  SALES_ORDER_EXCEL_DOWNLOAD: 107,
  SERVICE_SUPPORT_EXCEL_DOWNLOAD: 108,
  SERVICE_GENERAL_INFORMATION_EXCEL_DOWNLOAD: 109,
  SERVICE_DUPLICATE_INVOICE_EXCEL_DOWNLOAD: 110,
  ALL_PRODUCT_SERVICE_EXCEL_DOWNLOAD: 111,
  PRODUCT_SERVICE_NEW_EXCEL_DOWNLOAD: 112,
  PRODUCT_SERVICE_IN_TRANSIT_EXCEL_DOWNLOAD: 113,
  RECEIVED_FROM_STORE_EXCEL_DOWNLOAD: 114,
  PENDING_AT_SERVICE_CENTER_EXCEL_DOWNLOAD: 115,
  RECEIVED_FROM_SERVICE_CENTER_EXCEL_DOWNLOAD: 116,
  PENDING_TO_SEND_STORE_EXCEL_DOWNLOAD: 117,
  SERVICE_RECEIVED_FROM_HO_EXCEL_DOWNLOAD: 118,
  PENDING_TO_INFORM_CUSTOMER_EXCEL_DOWNLOAD: 119,
  INFORMED_CUSTOMER_EXCEL_DOWNLOAD: 120,
  PRODUCT_SERVICE_DELIVERED_EXCEL_DOWNLOAD: 121,
  EVALUATE_COMPLAINTS_EXCEL_DOWNLOAD: 122,
  FOLLOWUP_COMPLAINTS_EXCEL_DOWNLOAD: 123,
  ADVOCATE_LISTS_EXCEL_DOWNLOAD: 124,
  MISSED_ORDERS_LIST_EXCEL_DOWNLOAD: 125,
  PS_ORDERS_LIST_EXCEL_DOWNLOAD: 126,
  CART_ORDERS_LIST_EXCEL_DOWNLOAD: 127,
  ANONYM_CART_LIST_EXCEL_DOWNLOAD: 128,
  CAMPAIGNS_EXCEL_DOWNLOAD: 129,
  RECENTLY_VIEWED: 130,
  ANONYMOUS_VIEW: 131,
  OFFLINE_WHATSAPP_NOTIFY: 132,
  SEARCH_KEYWORD: 133,
  RECENT_PRODUCT_SEARCH: 134,
  SAVE_LATER: 135,
  PRE_REGISTER_BOOKING: 136,
  PRE_REGISTER_EXCEL_DOWNLOAD: 137,
  ENQUIRY_FEEDBACK: 138,
  ENQUIRY_FEEDBACK_EXCEL_DOWNLOAD: 139,
  SALES_ORDER: 140,
  CHAT_ARCHIVES: 141,
  CHAT_BOT_CONVERSATION: 142,
  CHAT_TRAFFIC: 143,
  MY_INBOX_SORT_TOGGLE: 144,
  MY_TEAM_SORT_TOGGLE: 145,
  BOT_CONV_SORT_TOGGLE: 146,
  CHAT_TRAFFIC_SORT_TOGGLE: 147,
  EVALUATOR_REPORTS: 148,
  TEAM_HIGHER_HIERARCHY: 149,
  CHAT_HIGHER_HIERARCHY: 150,
  CALLS_HIGHER_HIERARCHY: 151,
  TL_HIGHER_HIERARCHY: 152,
  RECEIVED_FROM_STORE_ACTION: 153,
  BASIC_INFO_HISTORY: 154,
  MIS_DASHBOARD: 155,
  Coupon_Creation: 156,
  CUST_MOBILE_AND_EMAIL_ID: 157,
  QR_TABLE: 158,
  MOBILE_APP_VERSION: 159,
  USER_ROLE_ACCESS: 160,
  PAGE_CREATION: 161,
  RESET_PASSWORD: 162
};

export const superAdminPermission = ["1"]; //super admin
export const marketingPermission = ["1", "10"]; //super admin
export const salesReturnPermission = ["1", "6", "9"]; //for salesReturn permission
export const salesPermission = ["1", "5", "11", "12", "13"]; //sales permission
export const servicePermission = ["1", "2", "4", "6", "8"]; //for service permission
export const Allpermission = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25"
]; //for all permission

// no permisstion alert
export const noEditPagePermisston = (msg = "") => {
  failToast(`No ${msg} permisstion`);
};

const pagePermissionCall = () => {
  const userPageAccessData = Decrypt("userPageAccessData");
  if (!userPageAccessData) {
    failToast("Page Permission Api Fail");
    setTimeout(() => {
      moveToLogOut();
    }, 1500);
    return null;
  }
  return userPageAccessData;
};

/**
 * pass page number
 * get asscess for this page
 * @param {*} pageNum
 * @returns boolean
 */
export const userRolePermission = (pageNum = []) => {
  if (sessionStorage.getItem("userLoginData") !== null) {
    const pageAccess = pagePermissionCall();
    const userDetails = window.sessionStorage.getItem("userLoginData") && Decrypt("userLoginData");
    if ("roleId" in _get(userDetails, "data", {})) {
      if (pageNum[0] === 6) {
        //home page or emplyee landing page
        return true;
      }

      const { data: { roleId = 0 } = {} } = nullToObject(userDetails);
      if ("" + roleId === "1") {
        //super admin have all rights to view all page
        return true;
      } else {
        const getPagePermistionId = nullToArray(pageAccess)
          .map((ele) => {
            if (_get(ele, "read", false) === true) {
              return _get(ele, "pageId", 0);
            }
            return undefined;
          })
          .filter((ele) => ele !== undefined);
        const difference = getPagePermistionId.filter((x) => (pageNum || []).some((ele) => "" + ele === "" + x));
        const pagePermisstion = nullToArray(difference)?.length === 0 ? false : true;
        if (!pagePermisstion) {
          //false means no permisstion toast added
          noEditPagePermisston("Read");
        }
        return pagePermisstion;
      }
    }
    return false;
  }
  return false;
};

//side menu heading
export const tabBasedAccess = (pageId = 0) => {
  const userDetails = Decrypt("userLoginData");

  if ("roleId" in _get(userDetails, "data", {})) {
    const pageAccess = pagePermissionCall();
    const { data: { roleId = 0 } = {} } = nullToObject(userDetails);
    if (pageId === 6) {
      //home page or employee landing page
      return true;
    }

    if ("" + roleId === "1") {
      //super admin have all rights to view all page
      return true;
    } else {
      const res = nullToArray(pageAccess).find((ele) => _get(ele, "pageId", 0) === pageId);
      const { read = false } = nullToObject(res);
      return read;
    }
  }
  return false;
};

//side menu heading
export const tabBasedGetFullAccess = (pageId = 0) => {
  const userDetails = Decrypt("userLoginData");

  if ("roleId" in _get(userDetails, "data", {})) {
    const pageAccess = pagePermissionCall();
    const { data: { roleId = 0 } = {} } = nullToObject(userDetails);
    if ("" + roleId === "1") {
      //super admin have all rights to view all page
      return {
        pageSlug: "",
        pageId: 0,
        create: true,
        read: true,
        write: true,
        del: true,
        status: true,
        role: 1
      };
    } else {
      return nullToArray(pageAccess).find((ele) => _get(ele, "pageId", 0) === pageId) || {};
    }
  }
  return {};
};

//rounding page number pass from routing
//set in sesstion storage(product page)
export const pageAccessFind = (getKeyValue = "read") => {
  const userLoginDetails = Decrypt("userLoginData");
  const pageId = Decrypt("pageNumberBasedRouting");
  const pageAccess = pagePermissionCall();

  const { data: { roleId = "" } = {} } = nullToObject(userLoginDetails);
  if ("" + roleId === "1") {
    //super admin have all rights to view all page
    return true;
  } else {
    const res = nullToArray(pageAccess).find((ele) => _get(ele, "pageId", 0) === pageId[0]);
    const respose = nullToObject(res);
    return respose[getKeyValue] || false;
  }
};

//rounding
export const pageObjectFind = () => {
  const superAdmin = {
    pageSlug: "",
    pageId: 0,
    create: true,
    read: true,
    write: true,
    del: true,
    status: true,
    role: 1
  };
  const userLoginDetails = Decrypt("userLoginData");
  const pageId = Decrypt("pageNumberBasedRouting");
  const pageAccess = pagePermissionCall();

  const { data: { roleId = "" } = {} } = nullToObject(userLoginDetails);
  if ("" + roleId === "1") {
    return superAdmin;
  } else {
    const res = nullToArray(pageAccess).find((ele) => _get(ele, "pageId", 0) === pageId[0]) || {};
    return nullToObject(res);
  }
};

//pass page id and get access details
export const getManualPageAccess = (pageNum = 0) => {
  const superAdmin = {
    pageSlug: "",
    pageId: 0,
    create: true,
    read: true,
    write: true,
    del: true,
    status: true,
    role: 1
  };
  const userLoginDetails = Decrypt("userLoginData");
  const pageAccess = pagePermissionCall();

  const { data: { roleId = "" } = {} } = nullToObject(userLoginDetails);
  if ("" + roleId === "1") {
    return superAdmin;
  } else {
    const res = nullToArray(pageAccess).find((ele) => _get(ele, "pageId", 0) === pageNum);
    return nullToObject(res);
  }
};
