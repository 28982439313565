import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { PartyInputDetailProps } from "../types";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { btn_plus, inputStylePopup, btn_delete } from "utils/ClassContent";
import BasicInfoImei from "Pages/NewCall/EnquiryFor/Complains/Iemi/BasicIformation/BasicInfoImei";

const PartyInputDetail = (props: PartyInputDetailProps) => {
  const {
    handlePartyCb = () => { },
    party = [] as object[],
    partyError = [] as object[],
    enableViewMode = false
  } = props;

  const [complaintData, setComplaintData] = useState({
    party: [] as any[]
  });

  /**
   * on name,email,address,mobile change
   */
  const onChangeObject = (val: object = {}, switchValue = "", key = 0) => {
    const value = _get(val, "value", "");
    const name = _get(val, "name", "");

    const complainParty = complaintData.party;
    complainParty[key][name] = value;
    handlePartyCb(complainParty);
  };

  /**
   * handle new party add
   */
  const handlePartyAdd = (index: number) => {
    //validate each entry in prev obj
    const individualComplainData = complaintData.party[index];
    if (
      individualComplainData &&
      (!individualComplainData.name ||
        !individualComplainData.email ||
        !individualComplainData.address ||
        !individualComplainData.mobile)
    ) {
      failToast("There is no party " + (index + 1) + " in the form.");
      return;
    }
    const complainParty = complaintData.party;
    complainParty.push({ name: "", mobile: "", address: "", email: "" });
    handlePartyCb(complainParty);

    // push new error object
    // const error = partyError;
    // error.push({ name: false, address: false, mobile: false, email: false });
    // setPartyError(error);
  };

  /**
   * Handle party delete
   * @param index
   */
  const handlePartyDelete = (index: number) => {
    const complainParty = complaintData.party;
    complainParty.splice(index, 1);
    handlePartyCb(complainParty);
  };

  useEffect(() => {
    setComplaintData({
      ...complaintData,
      party: party
    });
  }, [party]);

  return (
    <>
      {complaintData?.party?.length
        ? complaintData?.party?.map((itm, index) => (
          <>
            <BasicInfoImei
              basicInfo={itm}
              errorObj={partyError[index]}
              inputStyle={inputStylePopup}
              handelChange={onChangeObject}
              enableViewMode={enableViewMode}
              objectKey={{ value: "party", key: index }}
              title={"Party " + (index + 1) + " Details"}
            />

            {!enableViewMode && complaintData.party.length - 1 === index && (
              <div className="flex flex-row justify-end w-full mr-2">
                <button
                  type="button"
                  title="Delete this Party"
                  onClick={() => handlePartyDelete(index)}
                  className={btn_delete}
                >
                  <TrashIcon className="w-5 h-5" />
                </button>
                <button type="button" title="Add new Party" onClick={() => handlePartyAdd(index)} className={btn_plus}>
                  <PlusIcon className="w-5 h-5" />
                </button>
              </div>
            )}
          </>
        ))
        : !enableViewMode && (
          <div className=" flex mb-4 ml-4" style={{ width: "100%" }}>
            <h2>Party Details</h2>
            <div
              title="Add new Party"
              onClick={() => handlePartyAdd(0)}
              className="w-5 h-2 ml-2 text-green-900 top-4 cursor-pointer"
            >
              <PlusIcon />
            </div>
          </div>
        )
      }
    </>
  );
};
export default PartyInputDetail;
