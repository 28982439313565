import React, { useState, useEffect } from "react";
import { nullToObject } from "utils/validation";
import { ChevronDownIcon } from "@heroicons/react/outline";

type CommonDropHeadingProps = {
  id?: string;
  heading?: any;
  children?: any;
  className?: any;
  additionalELe?: any;
  customClass?: string;
  tabActiveDefault?: boolean;
};

const CommonDropHeading = (props: CommonDropHeadingProps) => {
  const {
    id = "",
    heading = "",
    children = "",
    className = "",
    customClass = "",
    additionalELe = "",
    tabActiveDefault = true
  } = nullToObject(props);

  const [commonDropHeading, setCommonDropHeading] = useState({
    tabActive: true
  });

  const { tabActive = true } = commonDropHeading;

  const tapActiveManage = () => setCommonDropHeading({
    ...commonDropHeading,
    tabActive: !tabActive
  });

  useEffect(() => setCommonDropHeading({
    ...commonDropHeading,
    tabActive: tabActiveDefault
  }), [tabActiveDefault]);

  return (
    <div id={id} className={`${className ? className : " "}  w-full mt-2 mb-3 mx-auto relative`}>
      <div className={` rounded-t mb-0  border-0 `}>
        <div
          onClick={tapActiveManage}
          className={`flex items-center text-sm font-bold ${customClass ? customClass : " bg-gray-200"} py-2 px-3 cursor-pointer text-gray-800`}
        >
          <span
            className={`inline-block w-5 h-5 mr-1 transition-all
                        ${tabActive ? "rotate-0" : "transform -rotate-90"}`}
          >
            <ChevronDownIcon className="w-5 h-5" />
          </span>
          <h3>{heading}</h3>
          {additionalELe}
        </div>
      </div>
      <div className={`animateHeight ${tabActive ? "h-auto" : " activeHeight  overflow-hidden "} flex flex-wrap pt-1`}>
        {children}
      </div>
    </div>
  );
};

export default CommonDropHeading;
