import History from "Component/History";
import { nullToObject } from "utils/validation";
import { encryptData } from "utils/secureFile";
import * as loginTypes from "../actionTypes/loginTypes";
import { envPath } from "ApiProcess/httpRestServices/apiManage";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import { getUserIdBasedPagePermisstionDetails } from "Pages/Setting/UserRoleTable/helperUserRole";
import {
  ssonloginService,
  getRegisterUserDetailService,
  registerUserStatusChangesServices
} from "utils/services/loginServices";

/**
 * @param  {object} obj
 * add new member
 */
function* ssonloginCall(obj = {}): any {
  try {
    const response = yield call(ssonloginService, obj);
    const { data = {}, data: { message = "", status = 0 } = {} } = response;
    if (status === 200) {
      //for developPurpose store loging user details
      encryptData("userLoginData", data);
      const { data: { roleId = 0 } = {} } = nullToObject(data);
      const pageAccessResponse = yield call(getUserIdBasedPagePermisstionDetails, { roleId });
      const { data: { data: pageAccessData = [] } = {} } = nullToObject(pageAccessResponse);
      encryptData("userPageAccessData", pageAccessData);
      if (envPath === "dev") {
        localStorage.setItem("userLoginData", JSON.stringify(data));
        localStorage.setItem("userPageAccessData", JSON.stringify(pageAccessData));
      }
      History.push(roleId === 1 ? "/home" : "/emp-enquiry-details");
      yield all([
        put({ type: "LOGIN_INITIAL_LOADER", loginLoader: false }) //loaderOff
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "LOGIN_INITIAL_LOADER", loginLoader: false }) //loaderOff
      ]);
    }
  } catch (error: any) {
    failToast("Something went wrong");
    yield put({ type: "LOGIN_INITIAL_LOADER", loginLoader: false }); //load teams page on success of api call
  }
}

/**
 * get register user details
 */
function* getRegisterUserListSaga(obj = {}): any {
  try {
    const response = yield call(getRegisterUserDetailService, obj);
    const { data: { message = "", data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "GET_REGISTER_USER_INITIAL_LOADER", getRegisterUserLoader: false }), //loaderOff
        put({ type: "GET_REGISTER_USER_DETAILS_DATA", getRegisterUserDetailsData: data }) //loaderOff
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "GET_REGISTER_USER_INITIAL_LOADER", getRegisterUserLoader: false }), //loaderOff
        put({ type: "GET_REGISTER_USER_DETAILS_DATA", getRegisterUserDetailsData: {} }) //loaderOff
      ]);
    }
  } catch (error: any) {
    yield all([
      put({ type: "GET_REGISTER_USER_INITIAL_LOADER", getRegisterUserLoader: false }), //loaderOff
      put({ type: "GET_REGISTER_USER_DETAILS_DATA", getRegisterUserDetailsData: {} }) //loaderOff
    ]);
  }
}

/**
 * regiter user active and inactive changes
 */
function* registerStatusChangeSaga(obj = {}): any {
  try {
    const response = yield call(registerUserStatusChangesServices, obj);
    const { data: { message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      successToast(message);
      yield all([
        put({ type: "RELOAD_USER_GET_LIST_DATA", reloadUserGetRegisterData: true }),
        put({ type: "REGISTER_USER_STATUS_CHANGE_LOADER", registerUsrStatusChangeloader: false }) //loader_on
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "RELOAD_USER_GET_LIST_DATA", reloadUserGetRegisterData: null }),
        put({ type: "REGISTER_USER_STATUS_CHANGE_LOADER", registerUsrStatusChangeloader: false }) //loader_on
      ]);
    }
  } catch (error: any) {
    yield all([
      put({ type: "RELOAD_USER_GET_LIST_DATA", reloadUserGetRegisterData: null }),
      put({ type: "REGISTER_USER_STATUS_CHANGE_LOADER", registerUsrStatusChangeloader: false }) //loader_on
    ]);
  }
}

export default function* loginSaga() {
  yield takeLatest(loginTypes.DO_SSON_LOGIN, ssonloginCall);
  yield takeLatest(loginTypes.GET_REGISTER_USER_DETAILS, getRegisterUserListSaga); //register create
  yield takeLatest(loginTypes.USER_STATUS_ACTIVE_INACTIVE_CHNAGES, registerStatusChangeSaga); //register changes saga
}
