import React, { useState } from "react";
import { useSelector } from "react-redux";
import { SendToSRNUpdateProps } from "./types";
import { reasonforSalesReturnDrop } from "Component/MockData";
import ConvertSRNLoader from "./ConvertSRNLoader";
import RefundFormUpdate from "./RefundFormUpdate";
import DoaDetailsFormUpdate from "./DoaDetailsFormUpdate";
import ReplacementFormUpdate from "./ReplacementFormUpdate";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

const ConvertToSRN = (props: SendToSRNUpdateProps) => {
  const {
    viewModeData = {}, //user details
    srSubCategoryState = {},
    convertTosrnLoader = false, //loader
    passRefundDetails = () => {}
  } = props;

  const [constractNewObj, setConstractNewObj] = useState({
    resonForSRN: 0,
    passRefunData: {}, //refund
    passDoaDetails: {}, //DOA
    replacePassdata: {}, //replace
    reasonforSalesReturn: ""
  });
  const globalStore = useSelector((state: any) => state) || {};
  const { srSubCategory = "" } = srSubCategoryState;
  const convertLowerCase = srSubCategory.toLowerCase(); //convet lowercase
  const { imei = "" } = viewModeData;

  const resonForSRNReturn = (event: any = {}) => {
    const { id = 0, optionData = "" } = event;
    const newObj = {
      ...constractNewObj,
      resonForSRN: id,
      reasonforSalesReturn: optionData
    };
    setConstractNewObj(newObj);
    passRefundDetails(newObj); //pass Details
  };

  /**
   * update refund details
   * @param event {object}
   */
  const passRefunData = (event: any = {}) => {
    const newObj = {
      ...constractNewObj,
      passRefunData: event
    };
    setConstractNewObj(newObj);
    passRefundDetails(newObj); //pass Details to parent
  };

  /**
   * update DOA details
   * @param event {object}
   */
  const passDoaDetails = (event: any = {}) => {
    const newObj = {
      ...constractNewObj,
      passDoaDetails: event
    };
    setConstractNewObj(newObj);
    passRefundDetails(newObj); //pass Details to parent
  };

  /**
   * update replace details
   * @param event {object}
   */
  const replacePassdata = (event: any = {}) => {
    const newObj = {
      ...constractNewObj,
      replacePassdata: event
    };
    setConstractNewObj(newObj);
    passRefundDetails(newObj); //pass Details to parent
  };

  return (
    <React.Fragment>
      {/* loader */}
      <ConvertSRNLoader convertTosrnLoader={convertTosrnLoader} />

      <div
        className="flex flex-wrap justify-between overflow-x-hidden overscroll-y-auto flex-auto"
        style={{ maxHeight: "600px", minHeight: "220px" }}
      >
        <CommonSearchDropdown
          setShow={true}
          mustField={true}
          inputWithIcon={false}
          placeholder={"--None--"}
          inputWithIconClass="w-10"
          parentClass={"mb-1 w-full mt-1 px-4"}
          labelTextClass={"font-bold"}
          inputFieldClass="h-9 pr-11"
          name={"ReasonforSalesReturn"}
          inputClass="flex justify-end"
          arrayData={reasonforSalesReturnDrop}
          clearInput={() => {}}
          labelText={"Reason for Sales Return"}
          handleParentDropVal={resonForSRNReturn}
          value={constractNewObj.reasonforSalesReturn}
        />

        {constractNewObj.resonForSRN === 1 && (
          <DoaDetailsFormUpdate
            imeiNumber={imei}
            genralInfo={viewModeData}
            globalStore={globalStore}
            passDoaDetails={passDoaDetails}
          />
        )}

        {constractNewObj.resonForSRN === 2 && (
          <ReplacementFormUpdate
            imeiNumber={imei}
            genralInfo={viewModeData}
            globalStore={globalStore}
            fromPage={"convertToSRN"}
            replacePassdata={replacePassdata}
            convertLowerCase={convertLowerCase}
            srSubCategoryState={srSubCategoryState}
          />
        )}

        {constractNewObj.resonForSRN === 3 && (
          <RefundFormUpdate
            imeiNumber={imei}
            genralInfo={viewModeData}
            globalStore={globalStore}
            passRefunData={passRefunData}
            convertLowerCase={convertLowerCase}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ConvertToSRN;
