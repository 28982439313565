import { moment } from "utils/momentConst";
import {
  mockComplaintTypeService,
  mockComplaintTypeSales,
  mockComplaintTypeFinance,
  mockComplaintTypeInsurance,
  mockComplaintActionTaken,
  mockComplaintConcernType
} from "Component/MockData";
import { _get } from "utils/lodashConst";
import { complainAboutProps } from "../types";
import React, { useEffect, useState } from "react";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import { alphaOnly, dateToString, numOnlyAllow } from "utils/validation";
import { staticData } from "ApiProcess/httpRestServices/apiManage";

const ComplainAboutDisplay = (props: complainAboutProps) => {
  const {
    complainAbout = "",
    isFollowUp = false,
    complainData = {},
    columnView = false,
    enableViewMode = false
  } = props;

  const mockData = {
    mockComplaintActionTaken: mockComplaintActionTaken,
    mockComplaintConcernType: mockComplaintConcernType
  };
  const [listValue, setListValue] = useState<any>([]);
  const [typeOfIssue, setTypeOfIssue] = useState(complainData.typeOfIssue);
  const [actionTaken, setActionTaken] = useState(complainData.actionTaken);

  /**
   * filter mock data
   * @returns
   */
  const filterMockData = () => {
    switch (complainAbout) {
      case "Sales":
        return mockComplaintTypeSales;
      case "Insurance":
        return mockComplaintTypeInsurance;
      case "Finance":
        return mockComplaintTypeFinance;
      case "Service":
        return mockComplaintTypeService;
      default:
        return [];
    }
  };

  /**
   * handle type of issue
   * @param item
   */
  const handleOptionChange = (item: any = {}) => {
    const { optionData = "" } = item;
    props.handleChange("typeOfIssue", optionData);
    setTypeOfIssue(optionData);
  };

  /**
   * handle action change
   * @param item
   */
  const handleActionTakenChange = (item: any = {}) => {
    const { optionData = "" } = item;
    props.handleChange("actionTaken", optionData);
    setActionTaken(optionData);
  };

  /**
   * handle concern type
   * @param item
   */
  const handleConcernTypeChange = (item: any = {}) => {
    const { optionData = "" } = item;
    props.handleChange("concernType", optionData);
  };

  /**
   * handle all field change
   * @param event
   */
  const handleFieldChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    props.handleChange(name, value);
  };

  useEffect(() => {
    setListValue(filterMockData());
  }, [complainAbout]);

  useEffect(() => {
    const { typeOfIssue: toi = "", actionTaken: at = "" } = complainData;
    setTypeOfIssue(toi);
    setActionTaken(at);
  }, [complainData]);
  return (
    <React.Fragment>
      <CommonSearchDropdown
        label={true}
        setShow={true}
        value={typeOfIssue}
        name={"typeOfIssue"}
        arrayData={listValue}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        readOnly={enableViewMode}
        labelText={"Type Of Issue"}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        clearInput={() => {}}
        handleParentDropVal={handleOptionChange}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        value={actionTaken}
        name={"actionTaken"}
        readOnly={enableViewMode}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        labelText={"Action Taken"}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        arrayData={mockData.mockComplaintActionTaken}
        handleParentDropVal={handleActionTakenChange}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      {(isFollowUp || complainData.actionTaken === "Hearing") && (
        <CommonInput
          name={"hearingDate"}
          setShow={true}
          inputError={false}
          inputWithIcon={true}
          inputFieldClass="h-9"
          enableDatePicker={true}
          isIconLabel={true}
          readOnly={enableViewMode}
          labelTextClass={"font-bold"}
          onChange={handleFieldChange}
          labelText={"Hearing/Last Date"}
          maxDate={moment().add(4, "M").toDate()}
          placeholderText={"" + dateToString(complainData.hearingDate)}
          value={complainData.hearingDate && new Date(complainData.hearingDate)}
          parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
        />
      )}

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        value={complainData.concernType}
        name={"concerntype"}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        readOnly={enableViewMode}
        labelText={"Concern Type"}
        labelTextClass={"font-bold"}
        inputFieldClass="h-9 pr-11"
        inputClass="flex justify-end"
        arrayData={mockData.mockComplaintConcernType}
        handleParentDropVal={handleConcernTypeChange}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonInput
        mustField={true}
        setShow={true}
        inputError={false}
        enableDatePicker={false}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        onChange={handleFieldChange}
        name={"nameOfThePerson"}
        labelTextClass={"font-bold"}
        labelText={"Name of The person"}
        placeholder={"Name of The person"}
        value={complainData.nameOfThePerson}
        onKeyPress={alphaOnly}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonInput
        mustField={false}
        setShow={true}
        inputError={false}
        name={"contactNumber"}
        maxLength={staticData.MobileNo}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        onChange={handleFieldChange}
        labelText={"Contact Number"}
        placeholder={"Contact Number"}
        labelTextClass={"font-bold"}
        enableDatePicker={false}
        value={complainData.contactNumber}
        onKeyPress={numOnlyAllow}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonInput
        setShow={true}
        name={"emailId"}
        mustField={false}
        inputError={false}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        labelText={"Email Id"}
        placeholder={"Email Id"}
        enableDatePicker={false}
        labelTextClass={"font-bold"}
        value={complainData.emailId}
        onChange={handleFieldChange}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />
    </React.Fragment>
  );
};

export default ComplainAboutDisplay;
