import ModalFixed3 from "Component/Modal/ModalFixed3";
import React, { useEffect, useState } from "react";
import ModalFixed from "../Modal/ModalFixed";
import ModalFixed2 from "../Modal/ModalFixed2";
import TableTemplate from "./TableTemplate";

type CommonTableProps = {
  id?: string;
  children?: any;
  enquires?: any;
  heading?: string;
  customClass?: any;
  setShow?: boolean;
  columnView?: boolean;
  enableResize?: boolean;
  parentClass?: string;
  handleFullView?: any;
  getModalStatus?: any;
  enableModal?: any;
  enableClose?: boolean;
  enableDrop?: boolean;
  handleTableClose?: any;
  hideExpansionMob?: boolean;
  handleModalFullscreen?: any;
};
const CommonTable = (props: CommonTableProps) => {
  const {
    customClass = "",
    setShow = true,
    children,
    parentClass = "",
    enableClose = false,
    enableResize = true,
    enableDrop = true,
    heading = "",
    columnView = false,
    handleModalFullscreen,
    enableModal = 1,
    id = "",
    handleTableClose = () => {},
    getModalStatus = () => {},
    hideExpansionMob = false
  } = props || {};

  const [showModal, setShowModal] = useState(false);
  const [tabs, setTabs] = useState(true);

  const tabHandle = (value = false) => {
    setTabs(value);
  };

  const handleFullView = () => {
    setShowModal(!showModal);
    setTabs(true);
    getModalStatus(true);
    typeof handleModalFullscreen === "function" && handleModalFullscreen(showModal);
  };

  // escape function added to close popup
  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) {
      setShowModal(false);
      setTabs(true);
    }
  };

  /**
   * user clik escape key close popUp
   */
  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  /**
   * hide the expansion of details in mobile view
   */
  useEffect(() => {
    if (hideExpansionMob) {
      setTabs(false);
    } else {
      setTabs(true);
    }
  }, [hideExpansionMob]);

  return showModal ? (
    <React.Fragment>
      {enableModal === 1 && (
        <ModalFixed>
          <div className="absolute bg-black opacity-80 inset-0 z-0 next-w-full" />
          <TableTemplate
            id={id}
            tabs={tabs}
            setShow={setShow}
            heading={heading}
            showModal={showModal}
            tabHandle={tabHandle}
            columnView={columnView}
            enableDrop={enableDrop}
            enableClose={enableClose}
            customClass={customClass}
            parentClass={parentClass}
            enableResize={enableResize}
            handleFullView={handleFullView}
            handleTableClose={handleTableClose}
          >
            {children}
          </TableTemplate>
        </ModalFixed>
      )}
      {enableModal === 2 && (
        <ModalFixed2>
          <div className="absolute bg-black opacity-80 inset-0 z-0 next-w-full" />
          <TableTemplate
            id={id}
            tabs={tabs}
            setShow={setShow}
            heading={heading}
            showModal={showModal}
            tabHandle={tabHandle}
            columnView={columnView}
            enableDrop={enableDrop}
            enableClose={enableClose}
            customClass={customClass}
            parentClass={parentClass}
            enableResize={enableResize}
            handleFullView={handleFullView}
            handleTableClose={handleTableClose}
          >
            {children}
          </TableTemplate>
        </ModalFixed2>
      )}
      {enableModal === 3 && (
        <ModalFixed3>
          <div className="absolute bg-black opacity-80 inset-0 z-0 next-w-full" />
          <TableTemplate
            id={id}
            tabs={tabs}
            setShow={setShow}
            heading={heading}
            showModal={showModal}
            tabHandle={tabHandle}
            columnView={columnView}
            enableDrop={enableDrop}
            enableClose={enableClose}
            customClass={customClass}
            parentClass={parentClass}
            enableResize={enableResize}
            handleFullView={handleFullView}
            handleTableClose={handleTableClose}
          >
            {children}
          </TableTemplate>
        </ModalFixed3>
      )}
    </React.Fragment>
  ) : (
    <TableTemplate
      id={id}
      tabs={tabs}
      setShow={setShow}
      heading={heading}
      showModal={showModal}
      tabHandle={tabHandle}
      columnView={columnView}
      enableDrop={enableDrop}
      enableClose={enableClose}
      customClass={customClass}
      parentClass={parentClass}
      enableResize={enableResize}
      handleFullView={handleFullView}
      handleTableClose={handleTableClose}
    >
      {children}
    </TableTemplate>
  );
};

export default CommonTable;
