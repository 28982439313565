import { _get } from "utils/lodashConst";
import * as cusListTypes from "../actionTypes/cusListTypes";

export const customerDetailsInit = {
  dcSerialNo: 0,
  customersDetails: {},
  smsServiceLoader: false,
  storeEmployeeDetails: {},
  customerServiceLoader: false,
  deliveryChallanLoader: false,
  whatsappServiceLoader: false,
  saveServiceCallSuccessCode: false,
  customerServiceEmailLoader: false,
  updateServiceCallSuccessCode: false
};

export const serviceCallDetailsReducer = (state = customerDetailsInit, action: any = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "CUSTOMER_INITIAL_STAGE": {
      return {
        ...state,
        ...customerDetailsInit
      };
    }

    // Get all the customer service details
    case "SET_CUSTOMER_SERVICE_DETAILS": {
      return {
        ...state,
        customersDetails: _get(action, "customersDetails", {})
      };
    }

    // Changes customer service loader
    case "SET_CUSTOMER_SERVICE_LOADER": {
      return {
        ...state,
        customerServiceLoader: _get(action, "customerServiceLoader", false)
      };
    }

    // Changes succes code on , saving customer details successfully
    case "SET_SAVE_SERVICE_SUCCESS_CODE": {
      return {
        ...state,
        saveServiceCallSuccessCode: _get(action, "successCode", false)
      };
    }

    // Changes succes code on , updating customer details successfully
    case "SET_UPDATE_SERVICE_SUCCESS_CODE": {
      return {
        ...state,
        updateServiceCallSuccessCode: _get(action, "successCode", false)
      };
    }

    // Changes succes code on , updating customer details successfully
    case "SET_EMPLOYEE_DETAILS": {
      return {
        ...state,
        storeEmployeeDetails: _get(action, "employeeDetails", {})
      };
    }

    // Changes succes code on , updating customer details successfully
    case "SET_DC_SERIAL_NO": {
      return {
        ...state,
        dcSerialNo: _get(action, "serialNo", {})
      };
    }

    // set delivery challan loader
    case "SET_DELIVERY_CHALLAN_LOADER": {
      return {
        ...state,
        deliveryChallanLoader: _get(action, "deliveryChallanLoader", false)
      };
    }

    case "IS_DELIVERY_CHALLAN": {
      return {
        ...state,
        isDeliveryChallanDone: _get(action, "isDeliveryChallanDone", false)
      };
    }

    // changes sms send loader
    case "SMS_SERVICE_LOADER": {
      return {
        ...state,
        smsServiceLoader: _get(action, "smsServiceLoader", false)
      };
    }

    // whatsapp message send status
    case cusListTypes.REQUEST_TO_SEND_WHATSAPP_MSG: {
      return {
        ...state,
        whatsappServiceLoader: true
      };
    }

    case cusListTypes.WHATSAPP_MESSAGE_SENT_FAILURE:
    case cusListTypes.WHATSAPP_MESSAGE_SENT_SUCCESSFULLY: {
      return {
        ...state,
        whatsappServiceLoader: _get(action, "whatsappServiceLoader", false)
      };
    }

    // changes email send loader
    case "CUSTOMER_SERVICE_EMAIL_SEND_LOADER": {
      return {
        ...state,
        customerServiceEmailLoader: _get(action, "customerServiceEmailLoader", false)
      };
    }
    // update succescode for send email
    case "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS": {
      return {
        ...state,
        customerServiceEmailSuccess: _get(action, "customerServiceEmailSuccess", false)
      };
    }
    default:
      return {
        ...state
      };
  }
};
