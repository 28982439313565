import React from "react";
import { moment } from "utils/momentConst";
import { thClass, tdClass, TableClass } from "utils/ClassContent";

import "./styles.scss";
import { getChannelNames } from "./EffectivePriceView/OfferCommon";

const EmiOffer = (props: any = {}) => {
  const { emi_details } = props;
  return (
    <div className="flex flex-col p-2">
      <div className="font-bold text-sm mt-2 mb-1">
        <span>{emi_details._id}</span>
      </div>
      <div className="w-full bg-gray-50">
        <table className={TableClass}>
          <thead>
            <tr>
              <th className={thClass}>Scheme</th>
              <th className={thClass}>
                <div>Advance EMI</div>
                <div>(EMI X Months)</div>
              </th>
              <th className={thClass}>
                <div>Remaining EMI</div>
                <div>(EMI X Months)</div>
              </th>
              <th className={thClass}>Processing Fee</th>
              <th className={thClass}>Down Payment</th>
              <th className={thClass}>Overall Loan Amount</th>
              <th className={thClass}>Channel</th>
              <th className={thClass}>Period</th>
            </tr>
          </thead>

          <tbody>
            {emi_details?.schemas?.map((emi: any) => {
              return (
                <tr key={emi?._id}>
                  <td className={tdClass}>{emi?.schemeName}</td>
                  <td className={tdClass}>{`${emi?.emiPerMonth}  X  ${emi?.initalEmiMonths}`}</td>
                  <td className={tdClass}>{`${emi?.emiPerMonth}  X  ${emi.totalEmiMonths - emi?.initalEmiMonths}`}</td>
                  <td className={tdClass}>{emi?.processingFees}</td>
                  <td className={tdClass}>{emi?.processingFees + emi?.emiPerMonth * emi?.initalEmiMonths}</td>
                  <td className={tdClass}>{emi?.totalEmiAmt}</td>
                  <td className={tdClass}>{getChannelNames(emi?.channel)}</td>
                  <td className={tdClass} style={{ minWidth: "140px" }}>
                    <div className="flex flex-col">
                      <span className="flex-1"> {moment(emi?.startDate).format("MMM Do YY H:mm")}</span>
                      <span className="flex-1 justify-center align-middle">{" - "}</span>
                      <span className="flex-1"> {moment(emi?.endDate).format("MMM Do YY H:mm")}</span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EmiOffer;
