import store from "store";
import { Get, Post } from "ApiProcess/httpRestServices";
import { convertToLowerCase, DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { crmApiBaseUrl, encodeGetParams, justDialApi } from "ApiProcess/httpRestServices/apiManage";

/**
 * justDial api
 */
export const initializeJustDialApiCall = (userSearchData: any = {}) => {
  const { size = "", search = "", status = "", page = "", endDate = "", startDate = "" } = userSearchData;

  const newObj = {
    page,
    size,
    search,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    status: convertToLowerCase(status) === convertToLowerCase("all") ? "" : status
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${justDialApi.justDial}?${queryString}`, "crmToken");
};

/**
 * justDial api upload excel document
 */
export const uploadJustDialTableData = (userSearchData: any = {}) => {
  const { size = "", search = "", status = "", page = "", endDate = "", startDate = "" } = userSearchData;

  const newObj = {
    page,
    size,
    search,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    status: convertToLowerCase(status) === convertToLowerCase("all") ? "" : status
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${justDialApi.uplaodJustDialApiData}?${queryString}`, {}, "crmToken");
};

/**
 * lead details store
 */
export const justDialDetailsStore = (ele: any = "") => {
  const { _id = "", leadid = "", mobile = "", source = "" } = ele;
  const newObj = {
    leadId: _id,
    mobile: mobile, // mantatory
    source: source,
    leadSrcId: leadid
  };
  store.dispatch({ type: "ENQUIRY_CREATE_BASED_ON_LEAD_DETAILS", justDialLeadDetails: newObj });
};
