import React from "react";
import { tdClass, tdHighLight } from "utils/ClassContent";
import { convertToLowerCase } from "utils/validation";

const TLHierarchyListBody = (props: any = {}) => {
  const { handleTeamMember = () => {}, data = [], type = "" } = props;

  return (
    <React.Fragment>
      {data.map((item: any = {}, i = 0) => (
        <tr key={convertToLowerCase(i + "userServiceCenter-tendk")}>
          <td className={tdClass}>
            <button
              onClick={() => (type !== "member" ? handleTeamMember(item) : () => false)}
              type={"button"}
              className={`${tdHighLight}`}
            >
              <span>{item.name}</span>
            </button>
          </td>
          {type !== "member" && <td className={tdClass + " text-center "}>{item.memberCount - 1 || 0}</td>}
          <td className={tdClass + " text-center "}>{item.avgScore || 0}%</td>
          <td className={tdClass + " text-center "}>{item.scoreCount || 0}</td>
          <td className={tdClass + " text-center "}>{item.failAllCount || 0}</td>
        </tr>
      ))}
      {data.length === 0 && <tr className="text-center">No member</tr>}
    </React.Fragment>
  );
};

export default TLHierarchyListBody;
