import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { Decrypt } from "utils/secureFile";
import Loader from "Component/Loader";
import { btn_refresh } from "utils/ClassContent";
import RegisterPriceMode from "./RegisterPriceMode";
import CommonInput from "Component/Form/CommonInput";
import CommonCheck from "Component/Form/CommonCheck";
import { RefreshIcon } from "@heroicons/react/outline";
import CommonRadioBox from "Component/Form/CommonRadioBox";
import {
  allowedRoles,
  mockLocation,
  roleTypeArray,
  dialedFromData,
  UserWorkingType,
  initialPriceMode
} from "./helperRegister";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import { getEmpDetailsForReplaceMent } from "../NewCall/EnquiryFor/SalesReturnsForm/commonSalesReturn";
import {
  emptyValidate,
  validateForm,
  allowOnlyChar,
  nullToObject,
  convertToLowerCase,
  numOnlyAllow
} from "utils/validation";

type RigisterInputProps = {
  globalStore?: any;
  editUser?: boolean;
  stateRegister?: any;
  refreshBtn?: boolean;
  editUserDetails?: any;
  nlcCategorieList?: any;
  getRolesDetails?: any;
  getShowRoomSearch?: (e?: any) => void;
  passDataGenralData?: (e?: any) => void;
  passOriginalArray?: (e?: any) => void;
  passDataGenralData1?: (e?: any) => void;
};

const RegisterInput = (props: RigisterInputProps) => {
  const {
    editUser = false,
    globalStore = {},
    getRolesDetails = {},
    stateRegister = {},
    refreshBtn = false,
    editUserDetails = {},
    nlcCategorieList = [],
    passOriginalArray = () => {},
    getShowRoomSearch = () => {},
    passDataGenralData = () => {}, //click,
    passDataGenralData1 = () => {} //click,
  } = nullToObject(props);
  const { branchList = [] } = stateRegister;

  const [getAllowAccess, setAllowAccess] = useState("web");

  const { rolesAndPagesTypesReducer = {} } = nullToObject(globalStore);
  const { employeeDetails = {}, getEmpDetailsLoader = false } = rolesAndPagesTypesReducer;
  const { userRoles = [] } = getRolesDetails;

  const userLoginDetails = Decrypt("userLoginData");
  const { data: { roleId = "", role: roleName = "" } = {} } = nullToObject(userLoginDetails);

  const isSuperAdmin = roleName === "SUPER_ADMIN";
  const userDetailsData = userRoles
    ?.map((ele: any) => {
      return {
        ...ele,
        optionData: ele?.name
      };
    })
    .filter((role: any) => {
      return roleId === 22 ? role.roleId !== 1 && role.roleId !== 2 : role.roleId >= roleId;
    });

  const [empId, setEmpId] = useState("");
  const [userName, setName] = useState("");
  const [userRole, setUserRole] = useState("");
  const [expiryAt, setExpiryAt] = useState(72);
  const [getLocation, setLocation] = useState("20");
  const [dialedFrom, setDialedFrom] = useState("Cloud");
  const [passWord, setPassWord] = useState("Poorvika@123");
  const [priceMode, setPriceMode] = useState(initialPriceMode);
  const [excludeLocation, setExcludeLocation] = useState(false);
  const [roleType, setRoleType] = useState("Expert");
  const [isAgent, setAgent] = useState(false);
  const { nlc = 0 } = priceMode;
  const [genralInfo, setGenralInfo] = useState({
    empId: "",
    roleType: "",
    userRole: "",
    userName: "",
    getLocation: "",
    allowedRadius: "20",
    allowedOnlyFrom: "web",
    passWord: "Poorvika@123",
    expiryAt: 72,
    allowExcludeLocation: false,
    dialedFrom: "cloud",
    priceMode: initialPriceMode
  });

  const [errinputPass, seterrinputPass] = useState({
    empId: false,
    passWord: true,
    roleType: false,
    userRole: false,
    userName: false,
    expiryAt: true,
    phoneNumber: true,
    allowedRadius: true,
    allowedOnlyFrom: true
  });

  /**
   * @param event
   */
  const branchListHandle = (event: any = {}) => {
    const { originalArray = [] } = nullToObject(event);
    passOriginalArray(originalArray);
  };

  /**
   * onUpdate main state and error validate
   */
  const onChange = (event: any = {}) => {
    const errors = errinputPass;
    const userDetails = genralInfo;
    const { value = "", name = "", optionData = "", checked = false } = _get(event, "target", {});
    switch (name) {
      case "userName":
        errors.userName = emptyValidate(value);
        userDetails.userName = value;
        setName(value);
        break;
      case "empId":
        errors.empId = emptyValidate(value);
        userDetails.empId = value;
        setEmpId(value);
        !editUser && setName("");
        break;
      case "passWord":
        errors.passWord = emptyValidate(value);
        userDetails.passWord = value;
        setPassWord(value);
        break;
      case "userRole":
        errors.userRole = emptyValidate(value);
        userDetails.userRole = value;
        setUserRole(value);
        if (value === "Showroom Staff") {
          userDetails.allowedOnlyFrom = "mobile-app";
          setAllowAccess("mobile-app");
        }
        if (value === "Store Management") {
          userDetails.allowedOnlyFrom = "both";
          setAllowAccess("both");
        }
        break;
      case "getLocation":
        errors.userRole = emptyValidate(value);
        userDetails.userRole = value;
        setLocation(value);
        break;
      case "roleType":
        errors.roleType = emptyValidate(value);
        userDetails.roleType = value;
        setRoleType(value);
        break;
      case "AllowAccess":
        userDetails.allowedOnlyFrom = value;
        setAllowAccess(value);
        break;
      case "Radius":
        userDetails.allowedRadius = value;
        setLocation(optionData || 20);
        break;
      case "DialedFrom":
        userDetails.dialedFrom = value;
        setDialedFrom(optionData);
        break;
      case "excludeLocation":
        userDetails.allowExcludeLocation = checked;
        setExcludeLocation(checked);
        break;
      case "priceMode":
        userDetails.priceMode = value;
        break;
      case "expiryAt":
        const expiry = value ? +value : null;
        errors.expiryAt = emptyValidate("" + value) && expiry >= 1;
        userDetails.expiryAt = expiry;
        setExpiryAt(expiry);
        break;
      default:
        break;
    }
    seterrinputPass(errors);
    setGenralInfo(userDetails);
    passDataGenralData(userDetails, validateForm(errinputPass));
  };

  const clearInput = (name = "") => {
    onChange({ target: { name: name, value: "" } });
  };

  const selectRole = (data: any = {}) => {
    onChange({ target: { name: "userRole", value: _get(data, "optionData", "") } });
  };

  const handleRadius = (data: any = {}) => {
    onChange({
      target: { name: "Radius", value: _get(data, "filterArray[0]", ""), optionData: _get(data, "filterArray[0]", "") }
    });
  };

  const handleDialedFrom = (data: any = {}) => {
    onChange({
      target: {
        name: "DialedFrom",
        optionData: _get(data, "filterArray[0]", ""),
        value: convertToLowerCase(_get(data, "filterArray[0]", ""))
      }
    });
  };

  const onPriceModeChange = (event: any = {}) => {
    const { name = "", checked = false } = _get(event, "target", {});
    const payload = { ...priceMode, [name]: checked ? 1 : 0 };
    const userDetails = {
      ...genralInfo,
      priceMode: payload
    };
    setPriceMode(payload);
    setGenralInfo(userDetails);
    passDataGenralData(userDetails, true);
  };

  /**
   * filter array
   * @param e object
   */
  const multiSelectBrand = (e) => {
    passDataGenralData1(e?.filterArray);
  };

  useEffect(() => {
    if (!editUser) {
      const { employee_name = "", employee_designation = "" } = employeeDetails;
      onChange({ target: { name: "userName", value: employee_name } });
      onChange({ target: { name: "AllowAccess", value: "web" } });
      if (allowedRoles.includes(employee_designation)) {
        onChange({ target: { name: "AllowAccess", value: "both" } });
      }
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (!editUser && empId === "") {
      //emp search is empty ,input box will clear
      onChange({ target: { name: "userName", value: "" } });
      onChange({ target: { name: "AllowAccess", value: "web" } });
    }
  }, [empId]);

  useEffect(() => {
    if (editUser) {
      const {
        role = "",
        name = "",
        expiryAt: userExpiryAt = 72,
        dialedFrom: userDialedFrom = "",
        empId: userEmpId = "",
        roleType: roleTy = "",
        allowedOnlyFrom = "",
        allowedRadius = "",
        allowExcludeLocation = false,
        pricingDetails: priceModeData = initialPriceMode
      } = editUserDetails;
      setPriceMode({ ...initialPriceMode, ...(priceModeData && priceModeData) });
      const userRoleFind = userRoles?.find((ele: any) => ele.slug === role);
      onChange({ target: { name: "priceMode", value: priceModeData } });
      onChange({ target: { name: "userName", value: name } });
      onChange({ target: { name: "expiryAt", value: userExpiryAt } });
      onChange({ target: { name: "empId", value: userEmpId } });
      onChange({ target: { name: "AllowAccess", value: allowedOnlyFrom } });
      onChange({ target: { name: "roleType", value: !roleTy ? "Expert" : roleTy } });
      onChange({ target: { name: "userRole", value: _get(userRoleFind, "name", "") } });
      onChange({ target: { name: "excludeLocation", checked: allowExcludeLocation === 1 } });
      onChange({ target: { name: "Radius", value: allowedRadius || 20, optionData: allowedRadius || 20 } });
      onChange({
        target: {
          name: "DialedFrom",
          value: userDialedFrom,
          optionData: userDialedFrom.charAt(0).toUpperCase() + userDialedFrom.slice(1)
        }
      });
      setGenralInfo({ ...genralInfo, priceMode: priceModeData });
    }
  }, [editUserDetails, userRoles]);

  useEffect(() => {
    // 👇️ don't run on initial render
    if (userRole && userRole.indexOf("Chat") > -1) {
      setAgent(true);
    } else {
      setAgent(false);
      seterrinputPass({ ...errinputPass, roleType: true });
    }
  }, [userRole]);

  return (
    <Fragment>
      {getEmpDetailsLoader && <Loader />}
      <div className=" register-form overflow-y-auto pt-6 flex-grow">
        <div className="flex flex-wrap px-2 max-h-full">
          <CommonInput
            value={empId}
            name={"empId"}
            mustField={true}
            inputError={false}
            readOnly={editUser}
            onChange={onChange}
            inputFieldClass="h-9"
            labelTextClass={"font-bold"}
            labelText={"Employee Id"}
            parentClass={"mb-1 w-full mt-1 px-4"}
            onBlur={() => empId && getEmpDetailsForReplaceMent(empId, true)}
          />

          <CommonInput
            mustField={true}
            value={userName}
            name={"userName"}
            inputError={false}
            labelText={"Name"}
            onChange={onChange}
            inputFieldClass="h-9"
            labelTextClass={"font-bold"}
            parentClass={"mb-1 w-full mt-1 px-4"}
            onInput={allowOnlyChar}
          />

          <CommonInput
            mustField={true}
            value={passWord}
            name={"passWord"}
            inputError={false}
            onChange={onChange}
            readOnly={editUser}
            inputFieldClass="h-9"
            labelText={"Password"}
            labelTextClass={"font-bold"}
            inputClass="flex justify-end"
            parentClass={"mb-1 w-full mt-1 px-4"}
          />

          <CommonSearchDropdown
            label={true}
            setShow={true}
            value={userRole}
            mustField={true}
            name={"userRole"}
            inputError={false}
            inputvalid={false}
            labelText={"Role"}
            inputWithIcon={false}
            arrayData={userDetailsData}
            inputFieldClass="h-9 pr-16"
            labelTextClass={"font-bold"}
            inputClass="flex justify-end"
            handleParentDropVal={selectRole}
            parentClass={"mb-1 w-full mt-1 px-4"}
            clearInput={() => clearInput("userRole")}
          />
          {isAgent && (
            <div className="inline-flex mb-1 w-full mt-1 px-4 w-44">
              <label className={`block text-sm font-bold mt-4 mb-2`}>
                Role Type
                <sup style={{ color: "red" }}>*</sup>
              </label>
              {roleTypeArray.map((itm: any, index) => (
                <CommonRadioBox
                  id={itm}
                  value={itm}
                  label={true}
                  name="roleType"
                  labelText={itm}
                  onChange={onChange}
                  checked={roleType === itm}
                  key={convertToLowerCase(index + "roleType")}
                  parentClass={`inline-flex p-1.5 pl-0 w-36`}
                />
              ))}
            </div>
          )}
          <div className=" mb-1 w-full mt-1 px-4 text-sm flex flex-grow">
            <MultiDropDownNew
              noSelected={true}
              name={"branchList"}
              inputFieldClass="h-9"
              arrayData={branchList}
              parentClass={"w-full"}
              labeName={"Store Assign"}
              customWidthClass={"w-full"}
              DropDownParentClass={"w-full"}
              filterArrayPass={branchListHandle}
            />
            <button
              type="button"
              title={"Sync Showroom"}
              className={`mt-5 ${btn_refresh}`}
              onClick={getShowRoomSearch}
              data-jest-id={"jestHandleSync"}
            >
              <RefreshIcon
                className={`${refreshBtn ? " animate-spin " : " "} h-5 w-5 stroke-current transition-all duration-200`}
              />
            </button>
          </div>
          <CommonCheck
            onChange={onChange}
            id={"excludeLocation"}
            name="excludeLocation"
            checked={excludeLocation}
            labelText={"Allow all location access"}
            parentClass={"inline-flex mb-3 w-full mt-3 px-4 w-44"}
          />
          <div className="form-group relative mb-2 sm:mb-2 mt-2 sm:px-4">
            <label htmlFor="" className="block text-xs input-label mb-1 font-bold">
              {"Allow Access"}
            </label>
            <div className="flex">
              {UserWorkingType.map((itm: any, index) => (
                <CommonRadioBox
                  label={true}
                  value={itm.value}
                  name="AllowAccess"
                  isDisable={false}
                  onChange={onChange}
                  id={index + itm.value}
                  labelText={itm.labelText}
                  checked={getAllowAccess === itm.value}
                  parentClass={`form-check p-1.5 pl-0 w-28`}
                  key={convertToLowerCase(index + "allowAccess-te-ndk")}
                />
              ))}
            </div>
          </div>

          {/* price mode */}
          {/* mode only visible super admin*/}
          {getAllowAccess !== "web" && isSuperAdmin && (
            <>
              <RegisterPriceMode priceMode={priceMode} roleId={roleId} onPriceModeChange={onPriceModeChange} />
              {/* nlc enable */}
              {nlc === 1 ? (
                <MultiDropDownNew
                  isReset={false}
                  isSelectAll={false}
                  enableCheckbox={true}
                  parentClass={"w-full"}
                  name={"itemBrandName"}
                  isOKButtonEnable={false}
                  labeName={"NLC categories"}
                  customWidthClass={"w-full"}
                  arrayData={nlcCategorieList}
                  filterArrayPass={multiSelectBrand}
                  DropDownParentClass={" w-1/2 px-4 mb-3"}
                />
              ) : null}
            </>
          )}

          {getAllowAccess !== "web" ? (
            <MultiDropDownNew
              isReset={false}
              isSelectAll={true}
              value={getLocation}
              enableCheckbox={false}
              name={"itemBrandName"}
              isOKButtonEnable={false}
              arrayData={mockLocation}
              filterArrayPass={handleRadius}
              labeName={"Allow Location Radius (Meters)"}
              parentClass={"w-full"}
              customWidthClass={"w-full"}
              DropDownParentClass={" w-1/2 px-4 mb-3"}
            />
          ) : null}

          <CommonInput
            mustField={true}
            value={expiryAt}
            name={"expiryAt"}
            inputError={false}
            onChange={onChange}
            inputFieldClass="h-9"
            labelText={"Expiry At"}
            readOnly={!isSuperAdmin}
            isExcludeTextNumField={true}
            labelTextClass={"font-bold"}
            parentClass={"mb-1 w-1/2 mb-3 px-4"}
            onKeyPress={numOnlyAllow}
          />
          <MultiDropDownNew
            isReset={false}
            isSelectAll={true}
            value={dialedFrom}
            enableCheckbox={false}
            name={"itemBrandName"}
            isOKButtonEnable={false}
            labeName={"Dialed From"}
            arrayData={dialedFromData}
            parentClass={"w-full"}
            customWidthClass={"w-full"}
            DropDownParentClass={" w-1/2 px-4 mb-3"}
            filterArrayPass={handleDialedFrom}
          />
          <div className="h-72" />
        </div>
      </div>
    </Fragment>
  );
};

export default RegisterInput;
