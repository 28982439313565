import { createStore, applyMiddleware } from "redux";
import rootSaga from "./sagas";
import reduxSaga from "redux-saga";
import rootReducer from "./reducer";

const reduxSagaMiddleware = reduxSaga();
const storeConfig: any = [reduxSagaMiddleware];
const store = createStore(rootReducer, applyMiddleware(...storeConfig));
reduxSagaMiddleware.run(rootSaga);
export default store;
