import { _get } from "utils/lodashConst";

export const storeInitState = {
  storeLocateData: [],
  loaderPincode: false,
  storeLocateDetails: []
};

export const storeLoacate = (state = storeInitState, action = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "STORE_INITIAL_STAGE": {
      return {
        ...state,
        ...storeInitState
      };
    }
    //all reasult data store here
    case "PINCODE_LOADER": {
      return {
        ...state,
        loaderPincode: _get(action, "loaderPincode", "")
      };
    }
    case "STORE_LOCATE_DETAILS": {
      return {
        ...state,
        storeLocateDetails: _get(action, "storeLocateDetails", "")
      };
    }
    case "STORE_LOCATE_DATA": {
      return {
        ...state,
        storeLocateData: _get(action, "storeLocateData", "")
      };
    }
    default:
      return {
        ...state
      };
  }
};
