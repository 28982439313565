import React from "react";

import { poorvikaLoginHeaderProps } from "./types";
import { ImgLogo, ImgPlaceholderLogo } from "assets/images";

const PoorvikaLoginHeader = (props: poorvikaLoginHeaderProps) => {
  const { fromPage = "" } = props || {};

  const pageHeading = (str = "") => {
    switch (str) {
      case "LoginPanel":
        return "Please log in to your account";
      case "ForgetPassword":
        return "Please verify your email";
      case "CreatePassword":
        return "Please create your password";
      default:
        return str;
    }
  };

  return (
    <div className="text-center w-full">
      <img
        src={ImgLogo}
        width={240}
        height={62}
        alt={"logo"}
        className={"w-60 mb-3 sm:mb-7 mx-auto"}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ImgPlaceholderLogo;
        }}
      />
      <h1 className="sm:text-2xl text-xl font-semibold text-black sm:mb-4 mb-2">{"Poorvika"}</h1>
      <span className="text-light font-semibold block mb-5 ">{pageHeading(fromPage)}</span>
    </div>
  );
};

export default PoorvikaLoginHeader;
