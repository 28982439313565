import React from "react";
import CommonInputSearch from "Component/Form/CommonInputSearch";
import { _get } from "utils/lodashConst";
import { toLocaleNumber } from "./OfferCommon";

interface ProductOfferProp {
  exchangeOffers: [];
  setExchangeOffers: any;
  product: any;
}

const ExchangeOffers = (props: ProductOfferProp) => {
  const { exchangeOffers, setExchangeOffers } = props;

  const handleOfferAction = (offerId: string, value: string, offerType: string) => {
    const checked_offer: any = exchangeOffers.map((item: any) => {
      if (item.offerId === offerId) {
        item[value] = !item[value];

        if (value === "showTc") {
          item.showBan = false;
        } else if (value === "showBan") {
          item.showTc = false;
        }
      }
      return item;
    });

    setExchangeOffers(checked_offer);
  };

  const handleOfferTradeIn = (event: any, offerId: any, tradeValues: any = []) => {
    const { value = "" } = _get(event, "target", {});

    const inputValue = Number(value);

    const traded_offer: any = exchangeOffers.map((item: any) => {
      if (item.offerId === offerId) {
        item.deviceVal = inputValue;

        if (tradeValues.length > 0) {
          const matchedTradeIn = tradeValues.find(
            (tradeIn: any) => inputValue >= tradeIn.startVal && inputValue <= tradeIn.endVal
          );

          if (matchedTradeIn) {
            if (item.exchangeBonus > 0) {
              if (matchedTradeIn.discountAmount !== 0) {
                item.exchangeVal = item.exchangeBonus;
                item.maxBenefites = item.exchangeBonus + inputValue;
              } else {
                item.maxBenefites = matchedTradeIn.discountAmount + inputValue;
                item.exchangeVal = matchedTradeIn.discountAmount;
              }
            } else {
              item.maxBenefites = matchedTradeIn.discountAmount + inputValue;
              item.exchangeVal = matchedTradeIn.discountAmount;
            }
          } else if (item.exchangeBonus > 0) {
            item.exchangeVal = item.exchangeBonus;
            item.maxBenefites = item.exchangeBonus + inputValue;
          }
        } else {
          if (item.exchangeBonus > 0) {
            item.exchangeVal = item.exchangeBonus;
            item.maxBenefites = item.exchangeBonus + inputValue;
          } else {
            item.maxBenefites = inputValue;
            item.exchangeVal = 0;
          }
        }
      }
      return item;
    });
    setExchangeOffers(traded_offer);
  };

  const handleKeyPress = (event: any) => {
    if (
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "ArrowLeft" ||
      event.key === "ArrowRight"
    ) {
      return;
    }
    const redx: any = /[0-9]/;
    if (!redx.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <>
      {exchangeOffers.map((offer: any) => {
        return (
          <div className="flex flex-col text-sm justify-between" key={offer?.offerId}>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between flex-wrap align-middle items-center p-2 font-bold text-gray-800">
                <div className="flex flex-row align-middle items-center justify-start flex-wrap">
                  <span className="p-1">
                    <input
                      disabled={offer?.offerSelection === "mandatory"}
                      name={offer.offerId}
                      checked={offer.checked}
                      id={offer.offerId}
                      onChange={() => handleOfferAction(offer.offerId, "checked", "other")}
                      type="checkbox"
                    />
                  </span>
                  <span className="p-1"> {`${offer?.offerName} (${offer?.sponsorName})`}</span>

                  <button
                    type="button"
                    className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showTc", "other")}
                  >{`T&C`}</button>
                  <button
                    type="button"
                    className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showBan", "other")}
                  >{`Details`}</button>

                  {offer.offerType === "TradeIn and Exchange" && (
                    <div className="flex items-center w-full">
                      <div className="offer-tc offer-name-items trade-in pl-1 ">
                        <CommonInputSearch
                          label={false}
                          name={"search"}
                          inputWithIcon={true}
                          placeholder="Search..."
                          searchUserDetaisl={(e: any) => handleOfferTradeIn(e, offer.offerId, offer?.tradeValues)}
                          onKeyDown={(event: any) => handleKeyPress(event)}
                          onChange={(e: any) => handleOfferTradeIn(e, offer.offerId, offer?.tradeValues)}
                          handleSearchClear={(event: any) => (event.value = "")}
                          parentClass={" md_search_View_active active "}
                        />
                      </div>
                      {offer.deviceVal > 0 && (
                        <span className="offer-tc offer-name-items trade-in pl-1 mt-5 ml-2 text-sm text-blue-600">
                          {`${toLocaleNumber(offer.deviceVal)} + (Bonus) ${toLocaleNumber(offer.exchangeVal)}`}
                        </span>
                      )}
                    </div>
                  )}
                </div>

                <div className="flex flex-row align-middle text-green-500">
                  {offer.offerType !== "Offer Text" && offer.offerType !== "Standard EMI" && (
                    <span className={!offer.checked ? "line-through" : ""}> {toLocaleNumber(offer?.maxBenefites)}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              {offer.showTc && <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }} />}
              {offer.showBan && (
                <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }} />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ExchangeOffers;
