import { put, call, takeLatest, all } from "redux-saga/effects";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { getSalesTransactionSTXNService } from "utils/services/transactionsService";

/**
 * @param  {object} obj
 * nonDeling list get
 */
function* getSalesTransactionSaga(obj = {}): any {
  try {
    const response = yield call(getSalesTransactionSTXNService, obj);
    const { data: { status = 0, data = {} } = {} } = response || {};
    if (status === 200) {
      yield all([
        put({ type: "SET_SALES_TRANSACTION_LIST", salesTransactionList: data }), //userData
        put({ type: "SET_SALES_TRANSACTION_LOADER", salesTransactionLoader: false }) //loaderOff
      ]);
    } else {
      yield all([
        put({ type: "SET_SALES_TRANSACTION_LIST", salesTransactionList: [] }), //userData
        put({ type: "SET_SALES_TRANSACTION_LOADER", salesTransactionLoader: false }) //loaderOff
      ]);
      failToast("Response error in getting Transaction List");
    }
  } catch (error) {
    yield all([
      put({ type: "SET_SALES_TRANSACTION_LIST", salesTransactionList: [] }), //userData
      put({ type: "SET_SALES_TRANSACTION_LOADER", salesTransactionLoader: false }) //loaderOff
    ]);
    failToast("Error in getting Transaction List");
  }
}

export default function* salesTransactionSaga() {
  yield takeLatest("GET_SALES_TRANSACTION", getSalesTransactionSaga);
}
