import { Get, Post } from "ApiProcess/httpRestServices";
import { ameyo, crmApiBaseUrl, encodeGetParams } from "../../ApiProcess/httpRestServices/apiManage";

type ameyoType = {
  data?: any;
};

/**
 * @param  {object} obj;
 * ameyo api call
 */
export const initiateAmeyoCallServices = (data: ameyoType) => {
  const { data: submitData = {} } = data;
  const queryString = encodeGetParams(submitData);
  return Post(`${crmApiBaseUrl}${ameyo.callWithUserId}?${queryString}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * get Call recording
 */
export const getCallRecordingServices = (data: ameyoType) => {
  const {
    data: { mobileNo = "", updatedDate = "" }
  } = data;
  const queryString = encodeGetParams({ mobileNo, updatedDate });
  return Get(`${crmApiBaseUrl}${ameyo.getCallRecording}?${queryString}`, "crmToken");
};
