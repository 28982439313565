import React from "react";
import { _get } from "utils/lodashConst";
import { XIcon } from "@heroicons/react/outline";
import CommonImageTag from "Component/Form/CommonImageTag";
import { ImgCsv, ImgDoc, ImgFile, ImgPdf, ImgTxt, ImgXls } from "assets/images";
import { convertToLowerCase, getDocType, imageTypeCheck } from "utils/validation";

const ImageUploadReturnSmallSize = (props: any = {}) => {
  const {
    imagRenderArry = [],
    enableViewMode = false,
    onClosImage = () => {},
    imageSize = "custom",
    controllImageCloseIcon = false
  } = props;
  const handleDoctypesImage = (doctype = "") => {
    switch (doctype) {
      case "pdf":
        return ImgPdf;
      case "doc":
        return ImgDoc;
      case "xlsx":
        return ImgXls;
      case "xls":
        return ImgXls;
      case "txt":
        return ImgTxt;
      case "csv":
        return ImgCsv;
      default:
        return ImgFile;
    }
  };

  const isShowImageCloseIcon = (obj: any) => {
    return controllImageCloseIcon ? !enableViewMode && _get(obj, "isEditable", false) : !enableViewMode;
  };

  return (
    <div className="w-full flex px-3 max-w-full">
      {imagRenderArry.map((ele: any, i: number) => {
        return (
          <React.Fragment key={convertToLowerCase(i + "imagRenderArry-fsvx")}>
            <div
              style={{ maxWidth: `calc(94%/${imagRenderArry.length})` }}
              className={`${imageSize ? " h-7 " : " h-28"}  relative m-1 mb-0 rounded`}
            >
              {isShowImageCloseIcon(ele) && (
                <span
                  onClick={() => onClosImage(i)}
                  className={`cursor-pointer inline-block shadow-lg drop-shadow-sm absolute -top-1 -right-1 rounded-full  bg-gray-700 text-white
                                            ${imageSize ? "w-3 h-3 p-0.5 " : " w-5 h-5 p-1"}hover:bg-red-400 transition-all`}
                >
                  <XIcon className=" w-full h-full" />
                </span>
              )}

              {imageTypeCheck(ele.type) ? (
                <a
                  href={ele.url}
                  target="_blank"
                  className={` ${imageSize ? " h-7 s " : " h-28 w-28 "} inline-block border rounded-sm`}
                  rel="noreferrer"
                >
                  <img
                    title={ele.fileName}
                    src={ele.url}
                    alt={ele.fileName}
                    className="w-full h-full object-contain object-center p-1"
                  />
                </a>
              ) : (
                <a
                  className={`bg-white rounded-sm inline-block border-gray-200 border ${imageSize ? " w-full h-7" : " w-28 "}`}
                  href={ele.url}
                  target="_blank"
                  rel="noreferrer"
                  title={ele.fileName}
                >
                  <div
                    className={` ${imageSize ? " row " : " flex-col "} flex justify-between items-center text-center w-full h-full`}
                  >
                    {!imageSize && (
                      <span className="text-sm block w-full c-primary uppercase font-bold mb-2">
                        {getDocType(ele.type)}
                      </span>
                    )}
                    <CommonImageTag
                      altName={ele.fileName}
                      className={`${imageSize ? " w-7 h-7 object-contain object-center p-1 " : " h-10 w-auto "}   c-primary`}
                      imgData={handleDoctypesImage(getDocType(ele.type))}
                    />
                    <div
                      className={`overflow-hidden overflow-ellipsis whitespace-nowrap w-full  ${
                        imageSize ? "  text-xxs pr-1 flex-grow flex-shrink" : " mt-2 text-sm "
                      } `}
                    >
                      {ele.fileName}
                    </div>
                  </div>
                </a>
              )}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
export default ImageUploadReturnSmallSize;
