import React, { useState } from "react";
import { complainAStatusProps } from "../types";
import { inputStylePopup } from "utils/ClassContent";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import CustomerCallType from "Pages/NewCall/EnquiryFor/CustomerCallType";
import {
  MockSalesStatus,
  MockComplaintStatusClosed,
  MockComplaintStatusPending,
  MockComplaintStatusLegalPending,
  MockComplaintStatusGeneralClosed,
  MockComplainCurrentStatusGeneralPending
} from "Component/MockData";

const ComplainStatusInput = (props: complainAStatusProps) => {
  const { handleChange = () => {}, complainData = {}, columnView = false } = props;

  const mockData = {
    MockSalesStatus: MockSalesStatus,
    MockComplaintStatusClosed: MockComplaintStatusClosed,
    MockComplaintStatusPending: MockComplaintStatusPending,
    CurrentStatusGeneralClosed: MockComplaintStatusGeneralClosed,
    MockComplaintStatusLegalPending: MockComplaintStatusLegalPending,
    CurrentStatusGeneralPending: MockComplainCurrentStatusGeneralPending
  };
  const [status, setStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [callType, setCallType] = useState<any>(null); //call type handle

  const handleStatusChange = (item: any = {}) => {
    const { optionData = "" } = item;
    handleChange("status", optionData);
    setStatus(optionData);
    setCurrentStatus("");
  };
  /**
 * @param item
  handle current status change
  */
  const handleCurrentStatusChange = (item: any = {}) => {
    const { optionData = "" } = item;
    handleChange("currentStatus", optionData);
    setCurrentStatus(optionData);
  };
  /**
   * get current status list
   */
  const getCurrentStatusList = () => {
    return status === "Pending"
      ? complainData.subCategory === "General"
        ? mockData.CurrentStatusGeneralPending
        : complainData.subCategory === "Legal"
          ? mockData.MockComplaintStatusLegalPending
          : mockData.MockComplaintStatusPending
      : complainData.subCategory === "General"
        ? mockData.CurrentStatusGeneralClosed
        : mockData.MockComplaintStatusClosed;
  };
  /**
   * call type change
   * false out,true mean incoming
   */
  const callTypeHandle = (value = false) => {
    handleChange("callType", value); //pass to parrent
    setCallType(value);
  };

  return (
    <React.Fragment>
      <CommonSearchDropdown
        label={true}
        setShow={true}
        value={status}
        name={"status"}
        mustField={true}
        labelText={"Status"}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        inputFieldClass="h-9 pr-11"
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        arrayData={mockData.MockSalesStatus}
        handleParentDropVal={handleStatusChange}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />

      <CommonSearchDropdown
        label={true}
        setShow={true}
        mustField={true}
        name={"typeOfIssue"}
        value={currentStatus}
        placeholder={"--None--"}
        inputWithIconClass="w-10"
        inputFieldClass="h-9 pr-11"
        labelText={"Sub Status"}
        labelTextClass={"font-bold"}
        inputClass="flex justify-end"
        arrayData={getCurrentStatusList()}
        handleParentDropVal={handleCurrentStatusChange}
        parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
      />
      <CustomerCallType
        inModelView={true}
        callType={callType}
        columnView={columnView}
        callTypeHandle={callTypeHandle}
      />
    </React.Fragment>
  );
};

export default ComplainStatusInput;
