export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const GET_CUSTOMER_SERVICE_LIST = "GET_CUSTOMER_SERVICE_LIST"; // Get customer service details
export const DELETE_CUSTOMER_LIST = "DELETE_CUSTOMER_LIST"; //delete
export const UPDATE_SUPPORT_STATUS = "UPDATE_SUPPORT_STATUS"; // Get customer service details
export const UPDATE_CUSTOMER_INFO = "UPDATE_CUSTOMER_INFO";
export const SAVE_SERVICE_DETAILS = "SAVE_CUSTOMER_SERVICE_DETAILS"; // Save service details
export const UPDATE_SERVICE_DETAILS = "UPDATE_CUSTOMER_SERVICE_DETAILS"; // Update Service details
export const CUSTOMER_SERVICE_EMAIL_SEND = "CUSTOMER_SERVICE_EMAIL_SEND"; // send customer service list on email
export const SEND_GENERAL_INFO_MAIL = "SEND_GENERAL_INFO_MAIL"; // send contents on email
export const GET_EMPLOYEE_DETAILS_FOR_SERVICE = "GET_EMPLOYEE_DETAILS_FOR_SERVICE"; // get employee details
export const CREATE_DELIVERY_CHALLAN = "CREATE_DELIVERY_CHALLAN"; // creates delivery challan
export const GET_DC_SERIAL = "GET_DC_SERIAL"; // creates delivery challan
export const SEND_SERVICE_CENTER_SMS = "SEND_SERVICE_CENTER_SMS";
export const SEND_SHOW_ROOM_SMS = "SEND_SHOW_ROOM_SMS";
export const GET_ALL_CALL_BY_ID = "GET_ALL_CALL_BY_ID"; // get all calls by id
export const GET_CUSTOMER_LIST_BY_TICKET_ID = "GET_CUSTOMER_LIST_BY_TICKET_ID";
export const REQUEST_TO_SEND_WHATSAPP_MSG = "REQUEST_TO_SEND_WHATSAPP_MSG";
export const WHATSAPP_MESSAGE_SENT_SUCCESSFULLY = "WHATSAPP_MESSAGE_SENT_SUCCESSFULLY";
export const WHATSAPP_MESSAGE_SENT_FAILURE = "WHATSAPP_MESSAGE_SENT_FAILURE";
