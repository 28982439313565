import React, { useEffect, useState } from "react";
import { IconMoodCry, IconMoodHappy, IconMoodHappyNormal, IconMoodSad } from "assets/images";
import { StarIcon } from "@heroicons/react/solid";

const TargetInfoTrip = (props: any) => {
  const { targetValue = 80, scoreVlaue = 50, totalScore = 0, excellent = 95, worst = 50 } = props;

  const [bgcolor, setBgColor] = useState("yellow");
  const [showTarget, setShowTarget] = useState({
    excellentTarget: true,
    aboveTarget: false,
    belowTarget: false,
    worstTarget: false
  });

  const { excellentTarget, aboveTarget, belowTarget, worstTarget } = showTarget;
  useEffect(() => {
    if (scoreVlaue >= excellent) {
      setBgColor("blue");
      setShowTarget({
        ...showTarget,
        excellentTarget: true,
        belowTarget: false,
        aboveTarget: false,
        worstTarget: false
      });
    } else if (scoreVlaue >= targetValue && scoreVlaue < excellent) {
      setBgColor("green");
      setShowTarget({
        ...showTarget,
        excellentTarget: false,
        aboveTarget: true,
        belowTarget: false,
        worstTarget: false
      });
    } else if (worst < scoreVlaue) {
      setBgColor("yellow");
      setShowTarget({
        ...showTarget,
        excellentTarget: false,
        aboveTarget: false,
        belowTarget: true,
        worstTarget: false
      });
    } else {
      setBgColor("red");
      setShowTarget({
        ...showTarget,
        excellentTarget: false,
        aboveTarget: false,
        belowTarget: false,
        worstTarget: true
      });
    }
  }, [scoreVlaue]);

  return (
    <div className="w-full">
      <div
        className={`bg-${
          bgcolor ? bgcolor : ""
        }-500 mx-auto justify-between space-x-4  flex text-white max-w-5xl m-2 rounded-md px-4 py-1`}
      >
        <div className="flex items-center relative">
          {excellentTarget && (
            <React.Fragment>
              <IconMoodHappy className="w-8 h-8" />
              <StarIcon className="w-8 h-8 text-yellow-300 mx-1" />
              <span>Above Target</span>
            </React.Fragment>
          )}
          {aboveTarget && (
            <React.Fragment>
              <IconMoodHappyNormal className="w-8 h-8 mr-1" /> <span>Above Target</span>
            </React.Fragment>
          )}
          {belowTarget && (
            <React.Fragment>
              {" "}
              <IconMoodSad className="w-8 h-8 mr-1" /> <span>Below Target</span>
            </React.Fragment>
          )}
          {worstTarget && (
            <React.Fragment>
              {" "}
              <IconMoodCry className="w-8 h-8 mr-1" /> <span>Below Target</span>
            </React.Fragment>
          )}
        </div>
        <div className="flex flex-col text-center">
          <span className="text-white text-opacity-85">Numeric</span>
          <strong className="font-bold text-sm">{totalScore}</strong>
        </div>
        <div className="flex flex-col text-center">
          <span className="text-white text-opacity-85">Percentage</span>
          <strong className="font-bold text-sm">{scoreVlaue}%</strong>
        </div>
      </div>
    </div>
  );
};

export default TargetInfoTrip;
