import Keycloak from "keycloak-js";

const {
  REACT_APP_SSO_URL = "",
  REACT_APP_STORE_SSO_URL,
  REACT_APP_SSO_REALM = "",
  REACT_APP_SSO_CLIENT = ""
} = process.env;

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
const keyCloakCorp = (flag: any) => {
  return new Keycloak({
    url: flag ? REACT_APP_STORE_SSO_URL : REACT_APP_SSO_URL,
    realm: REACT_APP_SSO_REALM,
    clientId: REACT_APP_SSO_CLIENT
  });
};

export default keyCloakCorp;
