import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { dOADetailFormProps } from "./types";
import { inputStyle } from "utils/ClassContent";
import { getDateConvert, nullToObject } from "utils/validation";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import ImageUploadReturn from "Component/ImageUploadReturn";
import FormFile from "Component/Form/FormFile";
import CommonInput from "Component/Form/CommonInput";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

const DoaDetailsFormUpdate = (props: dOADetailFormProps) => {
  const { setShow = true, genralInfo = {}, imeiNumber = "", passDoaDetails = () => {} } = props || {};

  const globalStore = useSelector((state: any) => state);
  const { salesReturn: { serviceCenterDataList = [] } = {} } = nullToObject(globalStore);
  const { itemBrandName = "" } = genralInfo;

  const [imagRenderArry, setImagRenderArry] = useState([]);
  const [serviceDatListOriginal, setServiceDatListOriginal] = useState([]);
  const serviceDatList: any = [];

  const [doaJobSheetDate, setDdoaJobSheetDate] = useState("");
  const [doaServiceCenter, setDoaServiceCenter] = useState("");
  const [doaCertificateDate, setDoaCertificateDate] = useState("");
  const [doaCertificateImei, setDoaCertificateImei] = useState("");
  const [doaCertificateNumber, setDoaCertificateDNumber] = useState("");

  const [DoaDetailFormData, setDoaDetailFormData] = useState({
    doaJobSheetDate: "",
    doaServiceCenter: "",
    doaimagRenderArry: [],
    doaCertificateDate: "",
    doaCertificateImei: "",
    doaCertificateNumber: ""
  });

  const onImageChange = (event: any = {}) => {
    if (_get(event, "target.files", [])?.length > 0) {
      const file = _get(event, "target.files[0]", {});
      const { name = "", type = "" } = file;

      const newObj = {
        file: file,
        fileName: name,
        url: URL.createObjectURL(file), //conevrt blob formate
        type: type
      };
      const newArray: any = [...imagRenderArry, newObj];
      setImagRenderArry(newArray);
      const newObjct = {
        ...DoaDetailFormData,
        doaimagRenderArry: newArray //appent image
      };
      setDoaDetailFormData(newObjct);
      passDoaDetails(newObjct, true); //pass information here to parent index
    }
  };

  const onClosImage = (index: number) => {
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    setImagRenderArry(newArray);
    const newObjct = {
      ...DoaDetailFormData,
      doaimagRenderArry: newArray //appent image
    };
    setDoaDetailFormData(newObjct);
    passDoaDetails(newObjct, true); //pass information here to parent index
  };

  /**
   * onUpdate main state and error validate
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const userDetails = DoaDetailFormData;
    switch (name) {
      case "doaCertificateDate":
        userDetails.doaCertificateDate = value;
        setDoaCertificateDate(value);
        break;
      case "doaJobSheetDate":
        userDetails.doaJobSheetDate = value;
        setDdoaJobSheetDate(value);
        break;
      case "doaServiceCenter":
        userDetails.doaServiceCenter = value;
        setDoaServiceCenter(value);
        break;
      case "doaCertificateImei":
        userDetails.doaCertificateImei = value;
        setDoaCertificateImei(value);
        break;
      case "doaCertificateNumber":
        userDetails.doaCertificateNumber = value;
        setDoaCertificateDNumber(value);
        break;
      default:
        break;
    }
    setDoaDetailFormData(userDetails);
    passDoaDetails(userDetails, true);
  };

  const dynamicClass = () => {
    return inputStyle;
  };

  const handleParentDropVal = (data: any) => {
    const { name = "" } = data || {};
    onChange({ target: { name: "doaServiceCenter", value: name } });
  };

  useEffect(() => {
    if (itemBrandName) {
      const brandName = itemBrandName.toLowerCase();
      const filterBrandName = serviceCenterDataList.filter(
        (ele: any) => ele.brand.toLowerCase() === brandName.toLowerCase()
      );
      setServiceDatListOriginal(filterBrandName);
    } else {
      setServiceDatListOriginal([]);
    }
  }, [itemBrandName]);

  useEffect(() => {
    if (imeiNumber) {
      onChange({ target: { name: "doaCertificateImei", value: imeiNumber } });
    } else {
      onChange({ target: { name: "doaCertificateImei", value: "" } });
    }
  }, [imeiNumber]);

  const displyServiceList = serviceDatList.length > 0 ? serviceDatList : serviceDatListOriginal;

  return (
    <React.Fragment>
      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        genralInfo={genralInfo}
        enableDatePicker={true}
        isIconLabel={true}
        checkCurrentData={true}
        name={"doaCertificateDate"}
        parentClass={dynamicClass()}
        labelTextClass={"font-bold"}
        labelText={"DOA Certificate Date"}
        placeholderText={getDateConvert(doaCertificateDate)}
        value={doaCertificateDate && new Date(doaCertificateDate)}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        value={doaCertificateNumber}
        parentClass={dynamicClass()}
        name={"doaCertificateNumber"}
        labelText={"DOA Certificate Number"}
        placeholder={"DOA Certificate Number"}
      />

      <CommonInput
        maxLength={staticData.imeilength}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        value={doaCertificateImei}
        name={"doaCertificateImei"}
        parentClass={dynamicClass()}
        labelTextClass={"font-bold"}
        labelText={"DOA IMEI Number"}
        placeholder={"DOA IMEI Number"}
      />

      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputWithIcon={true}
        inputFieldClass="h-9"
        enableDatePicker={true}
        isIconLabel={true}
        name={"doaJobSheetDate"}
        labelText={"Job Sheet Date"}
        labelTextClass={"font-bold"}
        parentClass={dynamicClass()}
        placeholderText={getDateConvert(doaJobSheetDate)}
        value={doaJobSheetDate && new Date(doaJobSheetDate)}
      />

      {displyServiceList.length !== 0 ? (
        <CommonSearchDropdown
          setShow={setShow}
          inputError={false}
          inputFieldClass="h-9"
          value={doaServiceCenter}
          name={"doaServiceCenter"}
          arrayData={displyServiceList}
          labelText={"Service Center"}
          labelTextClass={"font-bold"}
          parentClass={dynamicClass()}
          placeholder={"Search service center"}
          handleParentDropVal={handleParentDropVal}
          // clearInput={clearInput}
        />
      ) : (
        <CommonInput
          setShow={setShow}
          inputError={false}
          onChange={onChange}
          inputFieldClass="h-9"
          value={doaServiceCenter}
          name={"doaServiceCenter"}
          labelText={"Service Center"}
          labelTextClass={"font-bold"}
          parentClass={dynamicClass()}
          placeholder={"Service Center"}
        />
      )}

      <Fragment>
        <FormFile
          type="file"
          label={true}
          multiple={true}
          labelText="Attach Image"
          onChange={onImageChange}
          labelTextClass={"font-bold"}
          parentClass={" mb-1 w-full md:w-1/2 mt-1 px-4"}
          accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .odt, .txt, .xlsx, .xls"
        />
        {imagRenderArry.length >= 1 && (
          <div className="w-full flex flex-wrap justify-center px-3">
            <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
          </div>
        )}
      </Fragment>
    </React.Fragment>
  );
};
export default DoaDetailsFormUpdate;
