import store from "store";
import { Get, Put } from "ApiProcess/httpRestServices";
import { getMailByIdAction } from "store/action/mailAction";
import { validateObjectWithMessage } from "utils/validation";
import { getComplainById } from "utils/services/comListService";
import { customerServiceEmailSend } from "store/action/comListAction";
import { comListPageApi, crmApiBaseUrl, encodeGetParams, orgBaseUrl } from "ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * get cuslist data
 */
export const getcomListApiServices = (obj: any = {}) => {
  const { callType = null } = obj;
  const newObj = {
    ...obj,
    callType: callType === null ? "" : callType
  };
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${comListPageApi.comList}?${consvertString}`, "crmToken");
};

/**
 * @param id
 * loader enable
 * get mail based on id
 */
export const getMailByComplaintIdCall = (id = "") => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(getMailByIdAction(id));
};

export const resetComplaintEnquiryMailCall = () => {
  store.dispatch({ type: "SET_SELECTED_MAIL", selectedMailData: {} });
};

/**
 * @param id
 * @param data
 * return store action dispatch
 */
export const updateComplainDataById = (id = "", data: any = {}) => {
  //search cus based search
  const {
    enquiryFor = "",
    complainType = "",
    complainAbout = "",
    subCategory = "",
    complainSource = "",
    complainRecieveDate = "",
    imei = "",
    mobileNo = "",
    branchName = "",
    branchCode = "",
    customerName = "",
    productName = "",
    itemBrandName = "",
    itemModelName = "",
    invoiceAmount = "",
    InvolvedEmpContactNo = "",
    staffStatement = "",
    caseNumber = "",
    internalDecision = "",
    party = [],
    status = "",
    currentStatus = "",
    remarks = "",
    InvolvedEmpId = "",
    InvolvedEmpName = "",
    billNo = "",
    billDate = "",
    documentDate = "",
    complaintantExpectation = "",
    complaintantAdvocateDetails = {},
    typeOfIssue = "",
    actionTaken = "",
    concernType = "",
    contactNumber = "",
    nameOfThePerson = "",
    emailId = "",
    additionalRemarks = "",
    additionalAttachments = "",
    hearingDate = "",
    nextFollowUpDate = "",
    callType = true,
    customers: {
      name = "",
      type = "",
      city = "",
      state = "",
      email = "",
      mobile = "",
      gender = "",
      pincode = "",
      district = "",
      language = []
    } = {}
  } = data || {};

  const reqObj: any = {
    imei: imei,
    basicInfo: {
      name,
      type,
      city,
      state,
      email,
      mobile,
      gender,
      pincode,
      district,
      language
    },
    party: party,
    billNo: billNo,
    remarks: remarks,
    emailId: emailId,
    callType: callType,
    mobileNo: mobileNo,
    billDate: billDate,
    branchCode: branchCode,
    enquiryFor: enquiryFor,
    caseNumber: caseNumber,
    branchName: branchName,
    typeOfIssue: typeOfIssue,
    actionTaken: actionTaken,
    concernType: concernType,
    subCategory: subCategory,
    productName: productName,
    customerName: customerName,
    complainType: complainType,
    documentDate: documentDate,
    complainAbout: complainAbout,
    contactNumber: contactNumber,
    itemModelName: itemModelName,
    invoiceAmount: invoiceAmount,
    InvolvedEmpId: InvolvedEmpId,
    itemBrandName: itemBrandName,
    staffStatement: staffStatement,
    complainSource: complainSource,
    nameOfThePerson: nameOfThePerson,
    InvolvedEmpName: InvolvedEmpName,
    internalDecision: internalDecision,
    additionalRemarks: additionalRemarks,
    complainRecieveDate: complainRecieveDate,
    InvolvedEmpContactNo: InvolvedEmpContactNo,
    additionalAttachments: additionalAttachments,
    ...(hearingDate && { hearingDate: hearingDate }),
    complaintantExpectation: complaintantExpectation,
    complaintantAdvocateDetails: complaintantAdvocateDetails,
    ...(nextFollowUpDate && { nextFollowUpDate: nextFollowUpDate }),
    ...(status && { status: status }),
    ...(currentStatus && { currentStatus: currentStatus })
  };
  const formData = new FormData();
  const imageArray = reqObj.additionalAttachments;

  if (imageArray.length > 0) {
    for (const file of imageArray) {
      formData.append("additionalAttachments", file.file); // appending every file to formdata
    }
  }
  delete reqObj.additionalAttachments;
  reqObj.isQuery = "t";
  const convertString = encodeGetParams(reqObj);
  return Put(`${crmApiBaseUrl}${comListPageApi.updateComplain}${id}?${convertString}`, formData, "crmToken");
  // store.dispatch(updateComplainData(id, reqObj));
  // store.dispatch({ type: "GET_COM_LIST_LOADER", comListgetLoader: true });
};

/**
 *  @param {object} object
 * get employee detail
 */

export const getEmployeeDetailApiServices = (empId = "") => {
  return Get(`${orgBaseUrl}${comListPageApi.empDetail}=${empId}`, "empoyeeDetailToken");
};

/**
 * @param data
 * @returns form validation for submit
 */
export const validateComplainEvaluate = (data: any = {}) => {
  const {
    status = "",
    emailId = "",
    actionTaken = "",
    concernType = "",
    currentStatus = "",
    nameOfThePerson = "",
    nextFollowUpDate = "",
    additionalRemarks = ""
  } = data;
  return validateObjectWithMessage({
    status: status,
    concernType: concernType,
    actionTaken: actionTaken,
    currentStatus: currentStatus,
    nameOfThePerson: nameOfThePerson,
    additionalRemarks: additionalRemarks,
    ...(status === "Pending" && { nextFollowUpDate: nextFollowUpDate }),
    ...(emailId && { emailId: emailId })
  });
};

/**
 * Send attachemts on mail
 */
export const sendEmailAttachments = (data: any = {}) => {
  store.dispatch({
    type: "COMPLAIN_SEND_EMAIL_SUCCESS",
    complainEmailSuccess: false
  });
  store.dispatch({
    type: "COMPLAIN_EMAIL_SEND_LOADER",
    complainEmailLoader: true
  }); //send email loader
  store.dispatch(customerServiceEmailSend(data));
};

/**
 * reset gst address list
 */
export const resetGstAddress = () => {
  store.dispatch({ type: "GET_GST_DETAIL_DATA", gstDetailsData: {} });
};

export const filterComplainEmail = [
  "biCustomerName",
  "biCustomerMobile",
  "biCustomerEmail",
  "biCustomerLanguage",
  "biCustomerGender",
  "biCustomerType",
  "biCustomerPincode",
  "biCustomerArea",
  "biCustomerDistrict",
  "biCustomerState",
  "createdBy",
  "CallType",
  "enquiryFor",
  "subCategory",
  "complainType",
  "complainAbout",
  "complainSource",
  "imei",
  "customerName",
  "mobileNo",
  "branchName",
  "productName",
  "itemCode",
  "itemBrandName",
  "itemModelName",
  "invoiceAmount",
  "billNo",
  "billDate",
  "complainRecieveDate",
  "documentDate",
  "document",
  "complaintantExpectation",
  "complaintantAdvocateDetails",
  "complaintantAdvocateMobile",
  "complaintantAdvocateAddress",
  "complaintantAdvocateEmail",
  "party",
  "currentStatus",
  "status",
  "remarks",
  "createdByName",
  "createdByEmpId",
  "createdByDate",
  "updatedByName",
  "updatedByEmpId",
  "updatedByBranch",
  "updatedByDate",
  "createdAt",
  "updatedAt",
  "InvolvedEmpContactNo",
  "InvolvedEmpName",
  "InvolvedEmpId",
  "actionTaken",
  "caseNumber",
  "concernType",
  "contactNumber",
  "emailId",
  "hearingDate",
  "internalDecision",
  "nameOfThePerson",
  "nextFollowUpDate",
  "typeOfIssue"
];

/**
 * get complain By id
 */
export const getComplainsById = async (id: any = "") => {
  const response: any = await getComplainById(id);
  const { data: { data = [] } = {} } = response;
  return data;
};
