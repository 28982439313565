import * as loginTypes from "../actionTypes/loginTypes";

/**
 * @param  {Object} data={}
 */
export const createLoginAction = (data = {}) => {
  return {
    type: loginTypes.DO_ACTION_LOGIN,
    data
  };
};
/**
 * @param token
 */
export const singleSignOnActionCall = (token = "", user = {}, isStore = undefined) => {
  return {
    type: loginTypes.DO_SSON_LOGIN,
    data: { token, user, isStore }
  };
};

/**
 * getRegisterUserDetails
 */
export const getRegisterUserDetailsAction = (data: any = {}) => {
  return {
    type: loginTypes.GET_REGISTER_USER_DETAILS,
    data
  };
};

/**
 * getRegisterUserDetails
 */
export const registerStatusActiveInactiveChangeAction = (data: any = {}) => {
  return {
    type: loginTypes.USER_STATUS_ACTIVE_INACTIVE_CHNAGES,
    data
  };
};
