import { useSelector } from "react-redux";
import { _get, _isEmpty } from "utils/lodashConst";

import { staticData } from "ApiProcess/httpRestServices/apiManage";
import React, { Fragment, useEffect, useState } from "react";
import { convertToLowerCase, getDateConvert, nullToObject, numOnlyAllow } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { inputStyle, inputStylePopup } from "utils/ClassContent";
import { MockServiceSolutionForSupport } from "Component/MockData";
import { CustomerSolutionInfoPropsType } from "../ServiceCallsTypes";
import { fetchPincodeValue, getServiceCenterList, getServiceCenterToken } from "../commonServiceCalls";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import CommonInput from "Component/Form/CommonInput";
import CommonTextarea from "Component/Form/CommonTextarea";

/**
 * Show's solution fields
 * @param props props
 * @returns Solution fields
 */
const CustomerSolutionInfo = (props: CustomerSolutionInfoPropsType = {}) => {
  const { solutionData = {}, customerData = {}, editView = false, passSolutionInfo = () => {} } = props || {};

  const [brand, setBrand] = useState("");
  const [reason, setReason] = useState("");
  const [pincode, setPincode] = useState("");
  const [service, setService] = useState([]);
  const [onPass, setOnPass] = useState(false);
  const [solution, setSolution] = useState("");
  const [phone1, setPhone1] = useState("");
  const [phone2, setPhone2] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [branchName, setBranchName] = useState("");
  const [jobSheetNo, setJobSheetNo] = useState("");
  const [jobSheetDate, setJobSheetDate] = useState("");
  const [storeAddress, setStoreAddress] = useState("");
  const [contactperson, setContactperson] = useState("");
  const [storeContactNo, setStoreContactNo] = useState("");
  const [branchNameList, setBranchNameList] = useState([]);
  const [editedSolution, setEditedSolution] = useState(false);
  const [serviceCenterNames, setServiceCenterNames] = useState([]);
  const [serviceCenterField, setServiceCenterField] = useState("");
  const [serviceCenterAddress, setServiceCenterAddress] = useState("");

  const globalStore = useSelector((state: any) => state || {});
  const { storeLoacate: { loaderPincode = false, storeLocateDetails = [] } = {} } = nullToObject(globalStore);
  const { salesReturn: { serviceCenterDataList = [] } = {} } = nullToObject(globalStore); // Getting service center details from store

  /**
   * Set and validate dropdown values
   * @param data Selected drop down option
   */
  const handleEnquiryForVal = (data: any = {}) => {
    setEditedSolution(true);
    const selectedOption = _get(data, "optionData", "");
    const addressL1 = _get(data, "addressLine1", "");
    const addressL2 = _get(data, "addressLine2", "");
    const addressL3 = _get(data, "addressLine3", "");
    const state = _get(data, "state", "");
    setPhone1(_get(data, "phone1", ""));
    setPhone2(_get(data, "phone2", ""));
    const address = addressL1 + addressL2 + addressL3 + "," + state;
    setServiceCenterAddress(address);
    setServiceCenterField(selectedOption);
  };

  /**
   * Set and validate dropdown values
   * @param data Selected drop down option
   */
  const handleStoreDropDown = (data: any = {}) => {
    setEditedSolution(true);
    const selectedOption = _get(data, "optionData", "");
    setBranchName(selectedOption);
    const selectedStore = storeLocateDetails.find((store: any) => store.branch === selectedOption);
    setStoreContactNo(selectedStore?.mobileno);
    setStoreAddress(decodeURIComponent(selectedStore?.sh_address.replace(/\+/g, "%20")));
  };

  const handleStoreLocation = (event: any = {}) => {
    event.preventDefault();
    const pin = pincode;
    if (pin.length >= 5) {
      fetchPincodeValue(pin); //api call
    } else {
      //below 5 char throw error
      failToast("Please Enter Valid Pincode");
    }
  };

  /**
   * Handle's service center name search
   * @param event event
   */
  const clearInput = (event: any) => {
    const { value = "" } = _get(event, "target", {});
    const dataFilterResult = serviceCenterNames.filter((e: any) => {
      return convertToLowerCase(e.optionData).includes(value.toLowerCase());
    });
    setService(dataFilterResult);
    onChange(event);
  };

  /**
   * Handle's field values change
   * @param event event
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    setEditedSolution(true);
    switch (name) {
      case "solution":
        setSolution(value);
        break;
      case "reason":
        setReason(value);
        break;
      case "serviceCenter":
        setServiceCenterField(value);
        break;
      case "serviceCenterAddress":
        setServiceCenterAddress(value);
        break;
      case "jobsheetNo":
        setJobSheetNo(value);
        break;
      case "jobsheetDate":
        setJobSheetDate(value);
        break;
      case "contactPerson":
        setContactperson(value);
        break;
      case "contactNo":
        setContactNo(value);
        break;
      case "phone1":
        setPhone1(value);
        break;
      case "phone2":
        setPhone2(value);
        break;
      case "pincode":
        setPincode(value);
        break;
      case "branchName":
        setBranchName(value);
        break;
      case "storeContactNo":
        setStoreContactNo(value);
        break;
      case "storeAddress":
        setStoreAddress(value);
        break;
      default:
        break;
    }
    setOnPass(true);
  };

  // Set service center list
  useEffect(() => {
    getServiceCenterToken();
    getServiceCenterList();
  }, []);

  // Update existing values to the fields
  useEffect(() => {
    setReason(_get(solutionData, "reason", ""));
    setSolution(_get(solutionData, "solution", ""));
    setBrand(_get(customerData, "itemBrandName", ""));
    setContactNo(_get(solutionData, "contactNo", ""));
    setPhone1(_get(solutionData, "phone1", ""));
    setPhone2(_get(solutionData, "phone2", ""));
    setBranchName(_get(solutionData, "branchName", ""));
    setJobSheetNo(_get(solutionData, "jobsheetNo", ""));
    const date = _get(solutionData, "jobsheetDate", "");
    setContactperson(_get(solutionData, "contactPerson", ""));
    setStoreAddress(_get(solutionData, "storeAddress", ""));
    setJobSheetDate(date === "" ? date : getDateConvert(date));
    setStoreContactNo(_get(solutionData, "storeContactNo", ""));
    setServiceCenterField(_get(solutionData, "serviceCenter", ""));
    setServiceCenterAddress(_get(solutionData, "serviceCenterAddress", ""));
  }, [solutionData]);

  useEffect(() => {
    const filterByBrand = serviceCenterDataList.filter((item: any) =>
      convertToLowerCase(item.brand).includes(brand.toLowerCase())
    );
    const hasBrandValue = _isEmpty(filterByBrand) ? serviceCenterDataList : filterByBrand; // filtered service center based on field
    setServiceCenterNames(hasBrandValue);
    setService(hasBrandValue);
  }, [brand, serviceCenterDataList]);

  // Pass updated values
  useEffect(() => {
    if (onPass) {
      if (solution === "Visit Store") {
        const newObj = {
          solution: solution,
          branchName: branchName,
          storeAddress: storeAddress,
          storeContactNo: storeContactNo
        };
        passSolutionInfo({ solution: newObj }, editedSolution);
      } else if (solution === "Visit Service Center") {
        const newObj = {
          solution: solution,
          phone1: phone1,
          phone2: phone2,
          jobsheetNo: jobSheetNo,
          jobsheetDate: jobSheetDate,
          contactPerson: contactperson,
          serviceCenter: serviceCenterField,
          serviceCenterAddress: serviceCenterAddress
        };
        passSolutionInfo({ solution: newObj }, editedSolution);
      } else if (solution === "Solution Pending") {
        const newObj = {
          reason: reason,
          solution: solution
        };
        passSolutionInfo({ solution: newObj }, editedSolution);
      }
      setOnPass(false);
    }
  }, [onPass]);

  useEffect(() => {
    setBranchNameList(
      storeLocateDetails.map((store: any) => {
        return { optionData: store?.branch };
      })
    );
  }, [storeLocateDetails]);

  return (
    <React.Fragment>
      {editView ? (
        <CommonSearchDropdown
          label={true}
          inputId="tab4"
          mustField={true}
          value={solution}
          name={"solution"}
          inputWithIcon={false}
          labelText={"Solution"}
          handleOnChange={onChange}
          inputWithIconClass="w-10"
          defaltSeleted={"--None--"}
          labelTextClass={"font-bold"}
          inputFieldClass="h-9 pr-11"
          inputClass="flex justify-end"
          parentClass={inputStylePopup}
          arrayData={MockServiceSolutionForSupport}
        />
      ) : (
        <CommonInput
          inputId="tab4"
          value={solution}
          mustField={true}
          placeholder={""}
          name={"solution"}
          inputError={false}
          onChange={onChange}
          inputFieldClass="h-9"
          readOnly={!editView}
          labelText={"Solution"}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
        />
      )}

      {solution === "Solution Pending" && (
        <CommonTextarea
          setShow={true}
          name="reason"
          value={reason}
          onChange={onChange}
          labelText={"Reason"}
          readOnly={!editView}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
        />
      )}

      {solution === "Visit Service Center" && (
        <Fragment>
          {editView ? (
            <CommonSearchDropdown
              label={true}
              mustField={true}
              arrayData={service}
              inputWithIcon={false}
              name={"serviceCenter"}
              handleOnChange={onChange}
              inputWithIconClass="w-10"
              defaltSeleted={"--None--"}
              value={serviceCenterField}
              labelText={"Service Center"}
              labelTextClass={"font-bold"}
              inputFieldClass="h-9 pr-11"
              inputClass="flex justify-end"
              parentClass={inputStylePopup + " z-20 "}
              handleParentDropVal={handleEnquiryForVal}
              clearInput={clearInput}
            />
          ) : (
            <CommonInput
              placeholder={""}
              mustField={true}
              inputError={false}
              onChange={onChange}
              readOnly={!editView}
              inputFieldClass="h-9"
              name={"serviceCenter"}
              value={serviceCenterField}
              labelText={"Service Center"}
              labelTextClass={"font-bold"}
              parentClass={inputStylePopup}
            />
          )}
          <CommonTextarea
            setShow={true}
            mustField={true}
            inputError={false}
            inputFieldClass=""
            onChange={onChange}
            readOnly={!editView}
            parentClass={inputStyle}
            value={serviceCenterAddress}
            labelTextClass={"font-bold"}
            placeholder={"Enter address"}
            name={"serviceCenterAddress"}
            labelText={"Service Center Address"}
          />
          <CommonInput
            maxLength={staticData.MobileNo}
            mustField={false}
            value={contactNo}
            inputError={false}
            name={"contactNo"}
            onChange={onChange}
            readOnly={!editView}
            inputFieldClass="h-9"
            labelText={"Contact No"}
            placeholder={"Mobile No"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
            onKeyPress={numOnlyAllow}
          />
          <CommonInput
            mustField={true}
            value={jobSheetNo}
            inputError={false}
            name={"jobsheetNo"}
            onChange={onChange}
            readOnly={!editView}
            inputFieldClass="h-9"
            labelText={"Job Sheet No"}
            placeholder={"JobSheet No"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
          />
          <CommonInput
            parentPosition=" z-10 "
            inputError={false}
            onChange={onChange}
            readOnly={!editView}
            value={jobSheetDate}
            inputWithIcon={true}
            inputClass={"w-full "}
            name={"jobsheetDate"}
            inputFieldClass="h-9 w-full"
            enableDatePicker={true}
            isIconLabel={true}
            labelText={"Job Sheet Date"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
            placeholderText={jobSheetDate}
          />
          <CommonInput
            mustField={true}
            inputError={false}
            onChange={onChange}
            placeholder={"Name"}
            readOnly={!editView}
            value={contactperson}
            name={"contactPerson"}
            inputFieldClass="h-9"
            labelText={"Contact Person"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
          />
          <CommonInput
            maxLength={staticData.MobileNo}
            mustField={false}
            value={phone1}
            inputError={false}
            name={"phone1"}
            onChange={onChange}
            readOnly={!editView}
            inputFieldClass="h-9"
            labelText={"Phone1"}
            placeholder={"Mobile No"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
            onKeyPress={numOnlyAllow}
          />
          <CommonInput
            maxLength={staticData.MobileNo}
            mustField={false}
            value={phone2}
            inputError={false}
            name={"phone2"}
            onChange={onChange}
            readOnly={!editView}
            inputFieldClass="h-9"
            labelText={"Phone2"}
            placeholder={"Mobile No"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
            onKeyPress={numOnlyAllow}
          />
        </Fragment>
      )}

      {solution === "Visit Store" && (
        <Fragment>
          {editView && (
            <CommonInput
              setShow={true}
              value={pincode}
              name={"pincode"}
              mustField={true}
              inputError={false}
              onChange={onChange}
              inputWithIcon={true}
              buttonType={"submit"}
              inputFieldClass="h-9"
              maxLength={staticData.pinCode}
              labelText={"Store Location"}
              labelTextClass={"font-bold"}
              parentClass={inputStylePopup}
              placeholder={"Enter Pincode"}
              responseLoader={loaderPincode}
              inputWithIconClass={"input-icon-btn"}
              iconClickAction={handleStoreLocation}
              onKeyPress={numOnlyAllow}
            />
          )}

          {editView ? (
            <CommonSearchDropdown
              label={true}
              mustField={true}
              value={branchName}
              name={"branchName"}
              inputWithIcon={false}
              arrayData={branchNameList}
              handleOnChange={onChange}
              inputWithIconClass="w-10"
              labelText={"Branch Name"}
              defaltSeleted={"--None--"}
              labelTextClass={"font-bold"}
              inputFieldClass="h-9 pr-11"
              inputClass="flex justify-end"
              parentClass={inputStylePopup}
              handleParentDropVal={handleStoreDropDown}
            />
          ) : (
            <CommonInput
              mustField={true}
              placeholder={""}
              value={branchName}
              inputError={false}
              name={"branchName"}
              readOnly={!editView}
              inputFieldClass="h-9"
              labelText={"Branch Name"}
              labelTextClass={"font-bold"}
              parentClass={inputStylePopup}
            />
          )}

          <CommonInput
            placeholder={""}
            mustField={true}
            inputError={false}
            onChange={onChange}
            readOnly={!editView}
            inputFieldClass="h-9"
            value={storeContactNo}
            name={"storeContactNo"}
            labelText={"Store Contact No"}
            labelTextClass={"font-bold"}
            parentClass={inputStylePopup}
          />

          <CommonTextarea
            setShow={true}
            mustField={true}
            inputError={false}
            inputFieldClass=""
            onChange={onChange}
            readOnly={!editView}
            value={storeAddress}
            name={"storeAddress"}
            parentClass={inputStylePopup}
            labelTextClass={"font-bold"}
            placeholder={"Enter address"}
            labelText={"Store Address"}
          />
        </Fragment>
      )}
    </React.Fragment>
  );
};

export default CustomerSolutionInfo;
