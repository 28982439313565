import React from "react";
import UAParser from "ua-parser-js";
import "./react-multi-carousel.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const ReactMultiCarousel = (props: any) => {
  const { children, deviceType = "desktop" } = props;

  const responsive = {
    desktop: {
      breakpoint: { max: 30000, min: 1024 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1023, min: 769 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <Carousel
      ssr={false} // means to render carousel on server-side.
      infinite={true}
      draggable={true}
      showDots={false}
      swipeable={false}
      autoPlay={false}
      autoPlaySpeed={1000}
      keyBoardControl={true}
      responsive={responsive}
      deviceType={deviceType}
      transitionDuration={500}
      customTransition="all .5"
      containerClass="carousel-container"
      dotListClass="custom-dot-list-style"
      itemClass="carousel-item-padding-40-px"
    >
      {children}
    </Carousel>
  );
};
ReactMultiCarousel.getInitialProps = ({ req }: any) => {
  let userAgent;
  if (req) {
    userAgent = req.headers["user-agent"];
  } else {
    userAgent = navigator && navigator.userAgent;
  }
  const parser = new UAParser();
  parser.setUA(userAgent);
  const result = parser.getResult();
  const deviceType = (result.device && result.device.type) || "desktop";
  return { deviceType };
};
export default ReactMultiCarousel;
