import { useIdleTimer } from "react-idle-timer";
import { moveToLogOut } from "utils/secureFile";

function IdleTimeOut() {
  const onIdle = () => {
    if (sessionStorage.getItem("userLoginData")) {
      alert("Your current session has timeout , Please login again.");
      moveToLogOut();
    }
  };

  useIdleTimer({
    onIdle,
    timeout: 1500 * 60 * 1000,
    throttle: 2000
  });

  return null;
}
export default IdleTimeOut;
