import { _get } from "utils/lodashConst";

type mailCredentialReducerTypes = {
  mailCredentialData?: any;
  mailCredentialLoader?: boolean;
  saveMailCredentialSuccessCode?: boolean;
  updatedMailCredentialSuccessCode?: boolean;
};

export const mailCredentialInitState = {
  mailCredentialData: {},
  mailCredentialLoader: false,
  saveMailCredentialSuccessCode: false,
  updatedMailCredentialSuccessCode: false
};

export const mailCredentialReducer = (state = mailCredentialInitState, action: mailCredentialReducerTypes = {}) => {
  switch (_get(action, "type")) {
    //set to initial state
    case "SET_INIT_STATE": {
      return {
        ...state,
        ...mailCredentialInitState
      };
    }

    case "SET_MAIL_CREDENTIAL_LIST": {
      return {
        ...state,
        mailCredentialData: _get(action, "mailCredentialData", [])
      };
    }

    case "SET_MAIL_CREDENTIAL_LOADER": {
      return {
        ...state,
        mailCredentialLoader: _get(action, "mailCredentialLoader", false)
      };
    }

    case "SET_SAVED_MAIL_CREDENTIAL_SUCCESS_CODE": {
      return {
        ...state,
        saveMailCredentialSuccessCode: _get(action, "saveMailCredentialSuccessCode", false)
      };
    }

    case "SET_UPDATED_MAIL_CREDENTIAL_SUCCESS_CODE": {
      return {
        ...state,
        updatedMailCredentialSuccessCode: _get(action, "updatedMailCredentialSuccessCode", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
