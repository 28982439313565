import { useCallback, useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakInstance } from "keycloak-js";
import _has from "lodash/has";
/**
 * Returns the auth info and some auth strategies.
 *
 */
export const useAuth = () => {
  const { keycloak, initialized } = useKeycloak<KeycloakInstance>();

  const [user, setUser] = useState({});
  // fetch user profile
  useEffect(() => {
    if (!initialized) {
      return;
    }

    const fetchUserInfo = async () => {
      try {
        const userProfile = await keycloak?.loadUserProfile();
        setUser({ ...userProfile, fullName: `${userProfile?.firstName} ${userProfile?.lastName}` });
      } catch (err) {
        alert("You don't have permission");
      }
    };

    if (keycloak?.authenticated) {
      fetchUserInfo();
    }
  }, [keycloak?.authenticated]);

  return {
    isAuthenticated: !!keycloak?.authenticated,
    initialized,
    meta: {
      keycloak
    },
    token: keycloak?.token,
    user,
    roles: keycloak?.realmAccess,
    login: useCallback(() => {
      keycloak?.login();
    }, [keycloak]),
    logout: useCallback(() => {
      return new Promise<boolean>((resolve, reject) => {
        keycloak?.logout()
          .then(() => resolve(true))
          .catch((error) => reject(error));
      });
    }, [keycloak]),
    accessPermission: _has(keycloak?.resourceAccess, "CRM"),
    register: useCallback(() => {
      keycloak?.register();
    }, [keycloak])
  };
};

export default {
  useAuth
};
