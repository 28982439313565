import React, { useEffect, useState } from "react";
import { IconSearch } from "assets/images";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { nullToObject, nullToString } from "utils/validation";

const CommonInputSearch = (props: any) => {
  const {
    name = "",
    value = "",
    inputId = "",
    type = "text",
    label = true,
    maxLength = 0,
    labelText = "",
    setShow = true,
    labelTextClass,
    iconTitle = "",
    autoFocus = "",
    IconErrorClass,
    inputClass = "",
    readOnly = false,
    placeholder = "",
    parentClass = "",
    onBlur = () => {},
    mustField = false,
    onPaste = () => {},
    onInput = () => {},
    inputvalid = false,
    inputError = false,
    parentPosition = "",
    inputFieldClass = "",
    onChange = () => {}, //click
    onKeyPress = () => {}, //click
    inputWithIcon = false,
    inputWithIconClass = "",
    clearSearchInput = () => {},
    searchUserDetaisl = () => {},
    iconComponent = <IconSearch />,
    isReset = false,
    ...rest
  } = nullToObject(props);

  const [valueInput, setValueInput] = useState("");

  const handleOnchange = (event: any = {}) => {
    event?.preventDefault && event.preventDefault();
    const { name: aliceName = "", value: aliceValue = "" } = event.target;
    setValueInput(aliceValue);
    onChange({
      ...event,
      target: {
        name: aliceName,
        value: aliceValue
      }
    });
  };

  const onBlurHandle = (event: any = {}) => {
    event?.preventDefault && event.preventDefault();
    const { value: aliceValue = "", name: aliceName = "" } = nullToObject(event.target);
    setValueInput(aliceValue);
    onChange({
      ...event,
      target: {
        name: aliceName,
        value: aliceValue
      }
    });
    onBlur(event);
  };

  //clear input
  const handleSearchEmpty = () => {
    const event = { target: { name: name, value: "" } };
    clearSearchInput(event);
  };

  const userInut = (event: any = {}) => {
    event?.preventDefault && event.preventDefault();
    const { value: aliceValue = "", name: aliceName = "" } = nullToObject(event?.target);
    searchUserDetaisl({
      target: {
        name: aliceName ? aliceName : name,
        value: aliceValue ? aliceValue : valueInput
      }
    });
  };

  //enter key press hid api call
  //not an enter key oather function work au usual
  const onKeyPressHandle = (e: any) => {
    if (e.key === "Enter") {
      userInut(e);
    } else {
      onKeyPress && onKeyPress();
    }
  };

  useEffect(() => {
    if (isReset) setValueInput("");
  }, [isReset]);

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  useEffect(() => {
    if (autoFocus) {
      const inputEle = document.querySelector(`[name=${name}]`) as HTMLElement | null;
      inputEle?.focus();
    }
  }, [autoFocus]);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group ${parentPosition ? parentPosition : "relative"} ${parentClass ? parentClass : ""}`}
    >
      {/* label print */}
      {label && (
        <div className="flex">
          <label
            htmlFor=""
            className={` text-xs mb-0.5 LabelName font-bold 
          ${labelTextClass ? labelTextClass : ""}
          ${inputError && !inputvalid ? " text-error" : " text-valid"}`}
          >
            {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
          </label>
        </div>
      )}

      <div className={` ${inputClass ? inputClass : ""}${inputWithIcon ? inputWithIcon : ""} relative `}>
        <input
          id={inputId}
          name={name}
          type={type}
          readOnly={readOnly}
          autoCorrect="off"
          autoComplete="off"
          spellCheck={false}
          onKeyPress={onKeyPressHandle}
          value={nullToString(valueInput)}
          data-jest-id={"inputFindForJest"}
          onPaste={onPaste ? onPaste : null}
          onInput={onInput ? onInput : null}
          onBlur={onBlur ? onBlurHandle : null}
          maxLength={maxLength ? maxLength : 100000}
          placeholder={placeholder ? placeholder : ""}
          onChange={readOnly ? () => {} : (e) => handleOnchange(e)}
          className={`block w-60 rounded input-common pl-3 py-1 h-8 text-sm pr-8  ${inputFieldClass ? inputFieldClass : ""}`}
          {...rest}
        />

        {nullToString(value) !== "" ? (
          <button
            type="button"
            onClick={handleSearchEmpty}
            data-jest-id={"jestHandleSearchEmpty"}
            className=" w-5 h-5 right-10 top-1/2 transform -translate-y-1/2 absolute"
          >
            <XIcon className="text-gray-500 hover:text-red-500 w-full h-full" />
          </button>
        ) : null}
        <button
          title={iconTitle}
          type={"submit"}
          onClick={userInut}
          className={`w-8 absolute flex justify-center items-center cursor-pointer
              top-px bottom-px right-px input-group-icon rounded ${inputWithIconClass ? inputWithIconClass : ""} ${
                nullToString(value) !== "" ? " bg-primary-gradient text-white" : " text-gray-400"
              }`}
        >
          {/* dynamic icon */}
          {React.cloneElement(iconComponent)}
        </button>
      </div>
    </Transition>
  );
};

export default CommonInputSearch;
