import React from "react";
import { nullToObject } from "utils/validation";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const NoDataFoundPage = (props: any) => {
  const { colSpan = "" } = nullToObject(props);
  return (
    <tr>
      <td
        colSpan={colSpan ? colSpan : ""}
        className="p-3 text-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-gray-400"
      >
        <div className="flex items-center justify-center">
          <ExclamationCircleIcon className="w-4 h-4 mr-1" />
          {"No Data Found"}
        </div>
      </td>
    </tr>
  );
};
export default NoDataFoundPage;
