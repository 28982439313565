import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { onWheel } from "Component/SliderMenu/CommonFunctions";

const OnlineEnquiryPopupTab = (props: any = {}) => {
  const { mailId = "", popParentTabs = "", popParentTabsHandle = () => {} } = props || {};

  return (
    <div className="sticky -top-1 bg-white z-20 shadow-md">
      <ScrollMenu scrollContainerClassName={"flex white whitespace-nowrap"} onWheel={onWheel}>
        <a
          href="#BasicInfo"
          onClick={() => popParentTabsHandle("BasicInfo")}
          data-jest-id={"jestPopParentTabsHandleOnline1"}
          className={`mx-2 ${popParentTabs === "BasicInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
        >
          Basic Info
        </a>
        <a
          href="#OrderInfo"
          onClick={() => popParentTabsHandle("OrderInfo")}
          data-jest-id={"jestPopParentTabsHandleOnline2"}
          className={`mx-2 ${popParentTabs === "OrderInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
        >
          Order Info
        </a>
        {mailId && (
          <a
            href="#EnquiryFromMail"
            onClick={() => popParentTabsHandle("EnquiryFromMail")}
            data-jest-id={"jestPopParentTabsHandleOnline3"}
            className={`mx-2 ${popParentTabs === "EnquiryFromMail" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
          >
            Enquiry From Mail
          </a>
        )}
        <a
          href="#EnquiryStatusHistory"
          onClick={() => popParentTabsHandle("EnquiryStatusHistory")}
          data-jest-id={"jestPopParentTabsHandleOnline4"}
          className={`mx-2 ${popParentTabs === "EnquiryStatusHistory" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
        >
          Enquiry Status History
        </a>
        <a
          href="#OnlineStatusUpdate"
          onClick={() => popParentTabsHandle("OnlineStatusUpdate")}
          data-jest-id={"jestPopParentTabsHandleOnline5"}
          className={`mx-2 ${popParentTabs === "OnlineStatusUpdate" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
        >
          Online Status Update
        </a>
      </ScrollMenu>
    </div>
  );
};

export default OnlineEnquiryPopupTab;
