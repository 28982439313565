import * as scorecardType from "../actionTypes/scorecardType";
import { put, call, takeLatest, all } from "redux-saga/effects";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import {
  scoreCardLockService,
  scoreCardDeleteService,
  scoreCardRespCreateService
} from "utils/services/scorecardService";

/**
 * service center delete
 */
function* sendCenterDelete(obj: object = {}): any {
  try {
    const response = yield call(scoreCardDeleteService, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 200) {
      successToast(message || "Service center deleted successfully");
      yield all([
        put({ type: "SCORECARD_CREATE_SUCCESS", scoreCardSuccess: true }),
        put({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "SCORECARD_CREATE_SUCCESS", scoreCardSuccess: false }),
        put({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: false })
      ]);
    }
  } catch (error) {
    failToast("something went wrong");
    yield all([
      put({ type: "SCORECARD_CREATE_SUCCESS", scoreCardSuccess: false }),
      put({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: false })
    ]);
  }
}

/**
 * service center delete
 */
function* sendCenterLock(obj: object = {}): any {
  try {
    const response = yield call(scoreCardLockService, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 201) {
      successToast(message || "Scorecard updated successfully");
      yield all([
        put({ type: "SCORECARD_LOCK_SUCCESS", lockScoreCardSuccess: true }),
        put({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "SCORECARD_LOCK_SUCCESS", lockScoreCardSuccess: false }),
        put({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: false })
      ]);
    }
  } catch (error) {
    failToast("something went wrong");
    yield all([
      put({ type: "SCORECARD_LOCK_SUCCESS", lockScoreCardSuccess: false }),
      put({ type: "SCORECARD_CREATE_LOADER", scoreCardLoader: false })
    ]);
  }
}

/**
 * create ScoreCard Response
 */
function* createScoreCardResponse(obj: object = {}): any {
  try {
    const response = yield call(scoreCardRespCreateService, obj);
    const {
      data: { message = "", status = 0 }
    } = response || {};
    if (status === 201) {
      successToast(message || "Score card response updated successfully");
      yield all([
        put({ type: "SCORECARDRESP_CREATE_SUCCESS", scoreCardRespSuccess: true }),
        put({ type: "GET_SCORECARD_RESP_LOADER", scoreCardRespLoader: false })
      ]);
    } else {
      failToast(message);
      yield all([
        put({ type: "SCORECARDRESP_CREATE_SUCCESS", scoreCardRespSuccess: false }),
        put({ type: "GET_SCORECARD_RESP_LOADER", scoreCardRespLoader: false })
      ]);
    }
  } catch (error) {
    failToast("something went wrong");
    yield all([
      put({ type: "SCORECARDRESP_CREATE_SUCCESS", scoreCardRespSuccess: false }),
      put({ type: "GET_SCORECARD_RESP_LOADER", scoreCardRespLoader: false })
    ]);
  }
}

export default function* scoreCardSaga() {
  yield takeLatest(scorecardType.SCORECARD_LOCK, sendCenterLock);
  yield takeLatest(scorecardType.SCORECARD_DELETE, sendCenterDelete);
  yield takeLatest(scorecardType.SCORECARD_RESPONSE, createScoreCardResponse);
}
