import React, { useState } from "react";
import { _get } from "utils/lodashConst";
import MultiSelectDropdown from "./MultiSelectDropdown";
import { convertToLowerCase, nullToObject } from "utils/validation";

const DropDownForMultiFilter = (props: any = {}) => {
  const {
    name = "",
    labeName = "",
    arrayData = [],
    disabled = false,
    readOnly = false,
    parentClass = "",
    inputErrorMsg = "",
    mustField = false,
    noSelected = false,
    inputFieldClass = "",
    customWidthClass = "",
    enableCheckbox = true,
    bindKeyName = "itemName",
    DropDownParentClass = "",
    isEnableSelectAll = false,
    filterKeyName = "isCheck",
    filterArrayPass = () => {},
    handleDropDownClick = () => {}
  } = nullToObject(props);
  //Current Status make array

  const [multiFilterHandle, setMultiFilterHandle] = useState({
    selectAllToggle: false
  });

  const { selectAllToggle = false } = multiFilterHandle;
  /**
   * @param array all array list
   * @param name clear or not find
   * @param _id unique id
   */
  const commonMultiSearch = (array: any = [], names = "", _id = "") => {
    let newArray = array;
    const findIndex = newArray.findIndex((ele: any) => _get(ele, "_id", "") === _id);

    if (convertToLowerCase(names) !== convertToLowerCase("clear")) {
      if (enableCheckbox) {
        newArray[findIndex] = {
          ...newArray[findIndex],
          [filterKeyName]: newArray[findIndex][filterKeyName] ? false : true
        };
      } else {
        //checkBox is not enable select one
        newArray = newArray.map((ele: any) => {
          if (_get(ele, "_id", "") === _id) {
            ele[filterKeyName] = true;
          } else {
            ele[filterKeyName] = false;
          }
          return ele;
        });
      }
    } else {
      //clear all selected
      newArray = newArray.map((ele: any) => {
        ele[filterKeyName] = false;
        return ele;
      });
    }

    return newArray;
  };

  const handleOnchange = (event: any = {}) => {
    const { target: { value = {}, name: nameData = "" } = {} } = nullToObject(event);
    const { _id = "", itemName = "" } = value;
    const newArray: any = [...arrayData];
    const newArrays = commonMultiSearch(newArray, nameData, _id); //toggle doing

    const filterArray = newArrays
      .map((ele: any) => {
        if (_get(ele, [filterKeyName], false) === true) {
          return _get(ele, [bindKeyName], undefined);
        }
        return undefined;
      })
      .filter((ele: any) => ele !== undefined);

    const newFilterObj = {
      originalArray: newArrays,
      filterArray: enableCheckbox ? filterArray : [itemName],
      name: convertToLowerCase(nameData) === convertToLowerCase("clear") ? name : nameData
    };
    filterArrayPass(newFilterObj);
    const checkAllValueTrue = newArrays.every((ele: any) => ele[filterKeyName] === true);

    //select all toggle handle
    if (selectAllToggle !== checkAllValueTrue) {
      //condition helps to unwanted data binding
      setMultiFilterHandle({
        ...multiFilterHandle,
        selectAllToggle: checkAllValueTrue
      });
    }
  };

  //is select all toggle handle
  const selectAllHandle = (toggle = false, toggleNames = "", event: any = {}) => {
    if (toggle) {
      // make every object value true
      const trueHandle = [...arrayData].map((ele: any) => {
        ele[filterKeyName] = true;
        return ele;
      });

      // make filter all value
      const filterName = [...arrayData]
        .map((ele: any) => {
          return _get(ele, [bindKeyName], undefined);
        })
        .filter((ele: any) => ele !== undefined);

      const newFilterObj = {
        filterArray: filterName,
        originalArray: trueHandle,
        name: convertToLowerCase(toggleNames) === convertToLowerCase("clear") ? name : toggleNames
      };
      filterArrayPass(newFilterObj);
    } else {
      // make every object value false
      const trueHandle = [...arrayData].map((ele: any) => {
        ele[filterKeyName] = false;
        return ele;
      });

      // make filter all value
      const newFilterObj = {
        originalArray: trueHandle,
        filterArray: [],
        name: convertToLowerCase(toggleNames) === convertToLowerCase("clear") ? name : toggleNames
      };
      filterArrayPass(newFilterObj);
    }

    setMultiFilterHandle({
      ...multiFilterHandle,
      selectAllToggle: toggle
    });
  };

  return (
    <React.Fragment>
      {/* role */}
      <div className={` ${DropDownParentClass ? DropDownParentClass : " "} flex flex-col LabelParent`}>
        {labeName ? (
          <label htmlFor="" className={` ${inputErrorMsg ? " text-error" : ""} text-xs mb-0.5 LabelName font-bold`}>
            {labeName} {mustField && <sup style={{ color: "red" }}>*</sup>}
          </label>
        ) : null}
        <MultiSelectDropdown
          name={name}
          disabled={disabled}
          noSelected={noSelected}
          bindKeyName={bindKeyName}
          parentClass={parentClass}
          arrayData={[...arrayData]}
          handleOnchange={handleOnchange}
          enableCheckbox={enableCheckbox}
          inputFieldClass={inputFieldClass}
          selectAllToggle={selectAllToggle}
          selectAllHandle={selectAllHandle}
          customWidthClass={customWidthClass}
          isEnableSelectAll={isEnableSelectAll}
          handleDropDownClick={handleDropDownClick}
          enableDropDown={_get(arrayData, "length", 0) > 0 ? readOnly : false}
        />
        {inputErrorMsg && (
          <span className={`absolute top-full right-4 whitespace-nowrap error-msg text-xs`}>{inputErrorMsg}</span>
        )}
      </div>
    </React.Fragment>
  );
};

export default DropDownForMultiFilter;
