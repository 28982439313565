import { _get } from "utils/lodashConst";
import ProductCard from "./ProductDetails";
import { Transition } from "@headlessui/react";
import { TrackingDetailsProps } from "../types";
import BillingAddress from "./BillingAddress";
import React, { useEffect, useState } from "react";
import ShippingAddress from "./ShippingAddress";
import TrackingBorderData from "./TrackingBorderData";
import { ExclamationCircleIcon } from "@heroicons/react/outline";

const TrackingDetails = (props: TrackingDetailsProps) => {
  const {
    setShow = true,
    maxHeight = "",
    enableModal = false,
    handleBack = () => {},
    orderTrackingUserData = {}
  } = props || {};

  const [productCardDatas, setProductCardDatas] = useState({});
  const [trackingBorderDatas, setTrackingBorderDatas] = useState({});
  const [billingAddressCardDatas, setBillingAddressCardDatas] = useState({});
  const [shippingAddressCardDatas, setShippingAddressCardDatas] = useState({});

  useEffect(() => {
    const {
      order_id = "",
      created_date = "",
      itemCount = "",
      shipping_method = "",
      payment_name = "",
      shipping_name = "",
      payment_address = "",
      shipping_address = "",
      payment_method = "",
      payment_country = "",
      shipping_country = "",
      total = "",
      shipping_mobile_number = "",
      phone_number = ""
    } = _get(orderTrackingUserData, "_id", {});

    const productDetails = _get(orderTrackingUserData, "product_detail", []);

    if (Object.keys(orderTrackingUserData)?.length > 0) {
      // TrackingBorderData
      setTrackingBorderDatas({
        ...trackingBorderDatas,
        order_id: order_id,
        created_date: created_date
      });

      // ProductCard
      setProductCardDatas({
        ...productCardDatas,
        total: total,
        itemCount: itemCount,
        shipping_method: shipping_method,
        productDetails: productDetails
      });

      // ShippingAddressCard
      setShippingAddressCardDatas({
        ...shippingAddressCardDatas,
        shipping_name: shipping_name,
        shipping_mobile_number: shipping_mobile_number,
        shipping_country: shipping_country,
        shipping_address: shipping_address
      });

      // BillingAddress
      setBillingAddressCardDatas({
        ...billingAddressCardDatas,
        payment_name: payment_name,
        phone_number: phone_number,
        payment_method: payment_method,
        payment_country: payment_country,
        payment_address: payment_address
      });
    }
  }, [orderTrackingUserData]);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      id="TrackingDetails"
      className={` ${enableModal ? " " : "h-screen"}`}
    >
      {Object.keys(orderTrackingUserData)?.length !== 0 ? (
        <div className={`mb-10`}>
          <div className="w-full">
            <div className={enableModal ? " pb-10" : "px-4 py-1"}>
              <TrackingBorderData
                handleBack={handleBack}
                enableModal={enableModal}
                trackingBorderDatas={trackingBorderDatas}
              />
              <div
                className={`${enableModal ? " flex flex-col xl:flex-row overflow-auto h-full pb-10 " : "w-full"}`}
                style={{ maxHeight: `calc(100vh - ${enableModal ? maxHeight : "100%"})` }}
              >
                <div className="flex w-full flex-wrap">
                  <ProductCard enableModal={enableModal} productCardDatas={productCardDatas} />
                </div>
                <div
                  className={`flex flex-wrap w-full px-2
                                    ${enableModal ? "flex-col " : " border shadow"} rounded-sm`}
                >
                  {enableModal ? (
                    <div className="pt-6">
                      <ShippingAddress shippingAddressCardDatas={shippingAddressCardDatas} />
                      <BillingAddress billingAddressCardDatas={billingAddressCardDatas} />
                    </div>
                  ) : (
                    <>
                      <ShippingAddress shippingAddressCardDatas={shippingAddressCardDatas} />
                      <BillingAddress billingAddressCardDatas={billingAddressCardDatas} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <TrackingBorderData enableModal={true} handleBack={handleBack} trackingBorderDatas={{}} />
          <div className="flex justify-center items-center h-full">
            <ExclamationCircleIcon className="w-5 h-5 mr-1" />
            <span>Order Id is missing please contact support</span>
          </div>
        </>
      )}
    </Transition>
  );
};

export default TrackingDetails;
