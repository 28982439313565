import React, { useState } from "react";
import { nullToObject } from "utils/validation";
import { TimerButtonProps } from "./types";

const TimerButton = (props: TimerButtonProps) => {
  const {
    bgImage = 32000,
    time = "",
    btnTitle = "",
    activeClass = "",
    customClass = "",
    buttonSizeInPX = "14px",
    handleAction = () => { },
    buttonColor = "bg-yellow-600"
  } = nullToObject(props);

  const [timer, setTimer] = useState(true);
  const [timerActive, setTimerActive] = useState(true);

  const handleActiveTimer = () => {
    if (timer) {
      handleAction();
      setTimer(false);
      setTimerActive(false);
      setTimeout(() => {
        setTimerActive(true);
        setTimer(true);
      }, time);
    }
  };

  return (
    <div
      onClick={handleActiveTimer}
      data-jest-id={"jestHandleActiveTimer"}
      className={`flex justify-center items-center ${!timerActive ? "cursor-wait" : " cursor-pointer"}`}
    >
      <button
        title={btnTitle}
        type="button"
        style={{ fontSize: buttonSizeInPX, backgroundImage: `url(${bgImage})` }}
        className={`appIcon ${!timerActive ? `${activeClass} active cursor-wait` : " "} ${customClass ? customClass : ""
          }
        `}
      >
        <div className={`overlay ${buttonColor}`} />
        <div style={{ backgroundImage: `url(${bgImage})` }} className="base-image" />
        <div className="timerImg fill">
          <div className="half-circle-left-mask">
            <div className="half-circle-left" />
          </div>
          <div className="half-circle-right-mask">
            <div className="half-circle-right" />
          </div>
        </div>
      </button>
    </div>
  );
};

export default TimerButton;
