import { useEffect } from "react";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const events = ["click", "keyup"];

export default function useGlobalDOMEvents(props: any) {
  const { trackEvent } = useMatomo();

  const handleEvent = (event: any) => {
    const { type = "", key = "", code = "", target: { localName = "", innerText = "" } = {} } = event;
    const eventObj: any = {
      category: window.location.href,
      action: type
    };
    if (type === "keyup") {
      eventObj.name = code;
      eventObj.value = key;
    } else {
      eventObj.name = localName;
      eventObj.value = innerText;
    }
    trackEvent(eventObj);
  };

  useEffect(() => {
    for (const event of events) {
      window.addEventListener(event, handleEvent, false);
    }
    return () => {
      for (const event of events) {
        window.removeEventListener(event, handleEvent, false);
      }
    };
  }, []);
}
