import React from "react";
import { useSelector } from "react-redux";
import Loader from "Component/Loader";
import { nullToObject } from "utils/validation";

const SalesProductLoader = (props: any = {}) => {
  const {
    loader = false,
    salesLoader = false,
    statusLoader = false,
    relatedEnquiryLoad = false,
    getProductDetailLoader = false
  } = props;

  const globalStore = useSelector((states: any) => states || {});
  const { salesReducer: { salesReportMailLoader = false } = {} } = nullToObject(globalStore);
  const { basicInfoReducer: { basicInfoUpdateLoader = false } = {} } = globalStore || {};

  return (
    <React.Fragment>
      {(statusLoader || getProductDetailLoader) && <Loader />}
      {(relatedEnquiryLoad || salesLoader) && <Loader />}
      {(salesReportMailLoader || loader) && <Loader />}
      {basicInfoUpdateLoader && <Loader />}
    </React.Fragment>
  );
};

export default SalesProductLoader;
