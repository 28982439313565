import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { dOADetailFormProps } from "./types";
import { inputStylePopup } from "utils/ClassContent";
import { staticData } from "ApiProcess/httpRestServices/apiManage";
import { convertToLowerCase, getDateConvert, nullToObject } from "utils/validation";
import ImageUploadReturn from "Component/ImageUploadReturn";
import FormFile from "Component/Form/FormFile";
import CommonInput from "Component/Form/CommonInput";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";

const DOADetailForm = (props: dOADetailFormProps) => {
  const {
    setShow = true,
    genralInfo = {},
    viewModeData = {},
    enableViewMode = false,
    passDoaDetails = () => {}
  } = props || {};

  const globalStore = useSelector((state: any) => state) || {};
  const { salesReturn: { serviceCenterDataList = [] } = {} } = nullToObject(globalStore);
  const { itemBrandName = "" } = genralInfo;

  const [imagRenderArry, setImagRenderArry] = useState([]);
  const [serviceDatListOriginal, setServiceDatListOriginal] = useState([]);
  const [serviceDatList, setServiceDatList] = useState([]);

  const [doaJobSheetDate, setDdoaJobSheetDate] = useState("");
  const [doaServiceCenter, setDoaServiceCenter] = useState("");
  const [doaCertificateDate, setDoaCertificateDate] = useState("");
  const [doaCertificateImei, setDoaCertificateImei] = useState("");
  const [doaCertificateNumber, setDoaCertificateDNumber] = useState("");
  const { reasonForSalesReturn: { commonImgFile = [] } = {} } = viewModeData;

  const [DoaDetailFormData, setDoaDetailFormData] = useState({
    doaJobSheetDate: "",
    doaServiceCenter: "",
    doaimagRenderArry: [],
    doaCertificateDate: "",
    doaCertificateImei: "",
    doaCertificateNumber: ""
  });

  const onImageChange = (event: any = {}) => {
    if (_get(event, "target.files", [])?.length > 0) {
      const file = _get(event, "target.files[0]", {});
      const { name = "", type = "" } = file;

      const newObj = {
        file: file,
        fileName: name,
        url: URL.createObjectURL(file), //conevrt blob formate
        type: type
      };
      const newArray: any = [...imagRenderArry, newObj];
      setImagRenderArry(newArray);
      const newObjct = {
        ...DoaDetailFormData,
        doaimagRenderArry: newArray //appent image
      };
      setDoaDetailFormData(newObjct);
      passDoaDetails(newObjct, true); //pass information here to parent index
    }
  };

  const onClosImage = (index: number) => {
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    setImagRenderArry(newArray);
    const newObjct = {
      ...DoaDetailFormData,
      doaimagRenderArry: newArray //appent image
    };
    setDoaDetailFormData(newObjct);
    passDoaDetails(newObjct, true); //pass information here to parent index
  };

  /**
   * onUpdate main state and error validate
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const userDetails = DoaDetailFormData;
    switch (name) {
      case "doaCertificateDate":
        userDetails.doaCertificateDate = value;
        setDoaCertificateDate(value);
        break;
      case "doaJobSheetDate":
        userDetails.doaJobSheetDate = value;
        setDdoaJobSheetDate(value);
        break;
      case "doaServiceCenter":
        userDetails.doaServiceCenter = value;
        setDoaServiceCenter(value);
        break;
      case "doaCertificateImei":
        userDetails.doaCertificateImei = value;
        setDoaCertificateImei(value);
        break;
      case "doaCertificateNumber":
        userDetails.doaCertificateNumber = value;
        setDoaCertificateDNumber(value);
        break;
      default:
        break;
    }
    setDoaDetailFormData(userDetails);
    passDoaDetails(userDetails, true);
  };

  const dynamicClass = () => {
    return inputStylePopup;
  };

  const handleParentDropVal = (data: any) => {
    const { name = "" } = data || {};
    onChange({ target: { name: "doaServiceCenter", value: name } });
  };

  const clearInput = (data: any) => {
    const { target: { value = "" } = {} } = data || {};
    onChange(data);
    const dataFilterResult = serviceDatListOriginal.filter((e: any) => {
      return convertToLowerCase(e.optionData).includes(value.toLowerCase());
    });
    setServiceDatList(dataFilterResult);
  };

  const findInValiteDateFormate = (value: any = "", date = "") => {
    if (value === "Invalid date") {
      return date;
    }
    return value;
  };

  useEffect(() => {
    const {
      reasonForSalesReturn: {
        doaIMEINo = "",
        doaCertificateNo = "",
        doaJobSheetDate: _doaJobSheetDate = "",
        doaServiceCenter: _doaServiceCenter = "",
        doaCertificateDate: _doaCertificateDate = ""
      } = {}
    } = viewModeData;
    onChange({ target: { name: "doaCertificateImei", value: doaIMEINo } });
    onChange({ target: { name: "doaServiceCenter", value: _doaServiceCenter } });
    onChange({ target: { name: "doaCertificateNumber", value: doaCertificateNo } });
    onChange({
      target: {
        name: "doaJobSheetDate",
        value: findInValiteDateFormate(getDateConvert(_doaJobSheetDate), _doaJobSheetDate)
      }
    });
    onChange({
      target: {
        name: "doaCertificateDate",
        value: findInValiteDateFormate(getDateConvert(_doaCertificateDate), _doaCertificateDate)
      }
    });
  }, [enableViewMode]);

  useEffect(() => {
    if (itemBrandName) {
      const filterBrandName = serviceCenterDataList.filter(
        (ele: any) => convertToLowerCase(ele.brand) === convertToLowerCase(itemBrandName)
      );
      setServiceDatListOriginal(filterBrandName);
    } else {
      setServiceDatListOriginal([]);
    }
  }, [itemBrandName, serviceCenterDataList]);

  const displyServiceList = serviceDatList.length > 0 ? serviceDatList : serviceDatListOriginal;

  return (
    <React.Fragment>
      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        inputFieldClass="h-9"
        name={"doaCertificateDate"}
        parentClass={dynamicClass()}
        labelTextClass={"font-bold"}
        readOnly={enableViewMode}
        enableDatePicker={true}
        isIconLabel={true}
        labelText={"DOA Certificate Date"}
        placeholderText={doaCertificateDate}
        onChange={!enableViewMode ? onChange : null}
        value={doaCertificateDate && new Date(doaCertificateDate)}
      />

      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        inputFieldClass="h-9"
        labelTextClass={"font-bold"}
        value={doaCertificateNumber}
        parentClass={dynamicClass()}
        name={"doaCertificateNumber"}
        readOnly={enableViewMode}
        labelText={"DOA Certificate Number"}
        onChange={!enableViewMode ? onChange : null}
      />

      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        value={doaCertificateImei}
        name={"doaCertificateImei"}
        parentClass={dynamicClass()}
        labelTextClass={"font-bold"}
        labelText={"DOA IMEI Number"}
        maxLength={staticData.imeilength}
        onChange={!enableViewMode ? onChange : null}
      />

      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        inputWithIcon={true}
        inputFieldClass="h-9"
        enableDatePicker={true}
        isIconLabel={true}
        name={"doaJobSheetDate"}
        labelText={"Job Sheet Date"}
        labelTextClass={"font-bold"}
        parentClass={dynamicClass()}
        readOnly={enableViewMode}
        placeholderText={doaJobSheetDate}
        onChange={!enableViewMode ? onChange : null}
        value={doaJobSheetDate && new Date(doaJobSheetDate)}
      />

      <CommonSearchDropdown
        setShow={setShow}
        inputError={false}
        inputFieldClass="h-9"
        value={doaServiceCenter}
        clearInput={clearInput}
        name={"doaServiceCenter"}
        arrayData={displyServiceList}
        labelText={"Service Center"}
        labelTextClass={"font-bold"}
        parentClass={dynamicClass()}
        readOnly={enableViewMode}
        placeholder={"Search service center"}
        handleParentDropVal={handleParentDropVal}
      />

      {!enableViewMode ? (
        <Fragment>
          <FormFile
            type="file"
            label={true}
            onChange={onImageChange}
            labelText="Attach Image"
            labelTextClass={"font-bold"}
            parentClass={" mb-1 w-full mt-2 sm:px-4 "}
            accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .odt, .txt, .xlsx, .xls"
          />
          {imagRenderArry.length >= 1 && (
            <div className="w-full flex flex-wrap justify-center px-3">
              <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
            </div>
          )}

          {commonImgFile.length >= 1 && (
            <div className="w-full flex flex-wrap justify-center px-3">
              <ImageUploadReturn
                onClosImage={onClosImage}
                imagRenderArry={commonImgFile}
                enableViewMode={enableViewMode}
              />
            </div>
          )}
        </Fragment>
      ) : (
        commonImgFile.length >= 1 && (
          <div className="w-full flex flex-wrap justify-center px-3">
            <ImageUploadReturn
              onClosImage={onClosImage}
              imagRenderArry={commonImgFile}
              enableViewMode={enableViewMode}
            />
          </div>
        )
      )}
    </React.Fragment>
  );
};
export default DOADetailForm;
