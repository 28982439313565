import React, { useState, useEffect } from "react";
import { _get } from "utils/lodashConst";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import CheckProblems from "./CheckProblems";
import ActionPopup from "Component/ActionPopup";
import { ImgAvatarMaleSm3 } from "assets/images";
import ModalFixed from "Component/Modal/ModalFixed";
import CommonInput from "Component/Form/CommonInput";
import { ProductService } from "utils/VariableConstant";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { customerServiceOptions } from "Component/MockData";
import { updateServiceCallDetails } from "../commonServiceCalls";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { headerButton, inputStylePopup } from "utils/ClassContent";

/**
 * Show's check qc page
 * @param props props
 * @returns Check QC page
 */
const CheckQC = (props: any = {}) => {
  const location: any = useLocation();
  const { state = {} } = nullToObject(location);

  const {
    fromHo = false,
    problem: problemList = [],
    jobSheet: userJobsheet = {},
    serviceCenter: userServiceCenter = {}
  } = nullToObject(state);
  const navigate = useNavigate();

  const [back, setBack] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [activePopup, setActivePopup] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [tabActive, setTabActive] = useState({
    tab1: true,
    tab2: false,
    tab3: false,
    tab4: false
  });

  const { tab1, tab2, tab3, tab4 } = tabActive;
  const checkedProblemList: any = [];

  /**
   * Create's input fields using param conditions
   * @param label label for input field
   * @param item inxed for selected value
   * @param userObject customer object
   * @returns Input field
   */
  const createInputFields = (label = "", item = "", userObject: object = {}) => {
    return (
      <CommonInput
        name={item}
        readOnly={true}
        labelText={label}
        inputError={false}
        inputFieldClass="h-30"
        value={userObject[item]}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
    );
  };

  /**
   * Add checked item to list
   * @param label label of selected checkbox
   * @param checkedValue value of checkbox (checkedin/checkedout)
   */
  const getCheckedItem = (label: never, checkedValue = false) => {
    if (checkedValue) {
      checkedProblemList.push(label);
    } else {
      const index = checkedProblemList.findIndex((item: any) => item === label);
      checkedProblemList.splice(index, 1);
    }
  };

  // Update call details and close the qc page
  const updateCallDetails = () => {
    const isAllChecked =
      userJobsheet["ProceedWith"] === "Replacement"
        ? checkedProblemList.includes("Proceed with Replacement")
        : problemList.every((ele: any) => checkedProblemList.includes(ele));
    if (!isAllChecked) {
      setActivePopup(true);
    } else {
      setUserDetails({
        ...userDetails,
        callType: false,
        statusRemarks: "Completed QC",
        currentStatus: fromHo
          ? customerServiceOptions.sendToStore.currentStatus
          : customerServiceOptions.pendingForDelh.currentStatus
      });
      setUpdateStatus(true);
    }
  };

  // Handle Alert Ok action
  const handleOkAction = () => {
    setUserDetails({
      ...userDetails,
      callType: false,
      statusRemarks: "From QC",
      currentStatus: customerServiceOptions.receivedFromStore.currentStatus
    });
    setActivePopup(false);
    setUpdateStatus(true);
  };

  // Close the check qc page
  const closePage = () => {
    setBack(true);
  };

  // Set current customer details
  useEffect(() => {
    setUserDetails(state);
  }, [state]);

  // Update service call status
  useEffect(() => {
    if (updateStatus) {
      updateServiceCallDetails(userDetails);
      setUpdateStatus(false);
      setBack(true);
    }
  }, [updateStatus]);

  const bckToPage = () => {
    navigate("/service-calls-received-from-service-center", {
      state: {
        subCategory: ProductService,
        currentStatus: [customerServiceOptions.receivedFromSC.currentStatus],
        subMenu: "Received From Service Center"
      }
    });
    return null;
  };

  if (back) {
    <>{bckToPage()}</>;
  }

  return (
    <div>
      <div className="flex items-center justify-between text-sm bg-primary-gradient px-3 py-2">
        <div className="flex items-center">
          <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
            <img
              alt=""
              loading="lazy"
              src={ImgAvatarMaleSm3}
              className="object-cover bg-white w-full h-full rounded-full"
            />
            <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
          </div>
          <div className="">
            <p className="font-semibold text-sm text-white">{state?.cusName}</p>
            <p className="text-sm text-white ">Enquiry For : {_get(userDetails, "enquiryFor", "")}</p>
          </div>
        </div>

        <div className="flex justify-center items-center">
          <button className={headerButton} type="button" onClick={closePage}>
            Close
          </button>
        </div>
      </div>

      <div className="w-full shadow-lg bg-white overflow-hidden">
        <div
          onClick={() => setTabActive({ ...tabActive, tab1: !tab1 })}
          className="flex items-center mt-2 text-sm bg-gray-200 p-3 cursor-pointer"
          data-jest-id={"jestSetTabActive"}
        >
          <span
            className={`inline-block w-5 h-5 mr-1 transition-all
                        ${tab1 ? "rotate-0" : "transform -rotate-90"}`}
          >
            <ChevronDownIcon className="w-5 h-5" />
          </span>
          <h3>Enquiry Information</h3>
        </div>

        <div
          id="tab1"
          className={` ${
            tab1 ? "h-auto" : "h-0 opacity-0 overflow-hidden "
          } items-center text-sm px-3 py-2 transition-all flex flex-wrap`}
        >
          {/* creats input fields for each data */}
          {createInputFields("enquiryFor", "enquiryFor", userDetails)}
          {createInputFields("subCategory", "subCategory", userDetails)}
          {createInputFields("imei", "imei", userDetails)}
          {createInputFields("mobileNo", "mobileNo", userDetails)}
          {createInputFields("customerName", "imeiCustomerName", userDetails)}
          {createInputFields("branchName", "branchName", userDetails)}
          {createInputFields("Product", "itemGroupName", userDetails)}
          {createInputFields("Brand", "itemBrandName", userDetails)}
          {createInputFields("Model", "itemName", userDetails)}
          {createInputFields("invoiceAmount", "invoiceAmount", userDetails)}
          {createInputFields("billNo", "billNo", userDetails)}
          {createInputFields("billDate", "billDate", userDetails)}
          {createInputFields("problemCategory", "problemCategory", userDetails)}
          {createInputFields("problem", "problem", userDetails)}
          {createInputFields("problemDesc", "problemDesc", userDetails)}
          {createInputFields("deviceAge", "deviceAge", userDetails)}
          {createInputFields("typeOfService", "typeOfService", userDetails)}
          {createInputFields("status", "status", userDetails)}
          {createInputFields("currentStatus", "currentStatus", userDetails)}
          {createInputFields("remarks", "remarks", userDetails)}
        </div>

        <div
          id="tab2"
          onClick={() => setTabActive({ ...tabActive, tab2: !tab2 })}
          data-jest-id={"jestSetTabActive2"}
          className="flex items-center mt-2 text-sm bg-gray-200 p-3 cursor-pointer"
        >
          <span
            className={`inline-block w-5 h-5 mr-1 transition-all
                        ${tab2 ? "rotate-0" : "transform -rotate-90"}`}
          >
            <ChevronDownIcon className="w-5 h-5" />
          </span>
          <h3>SERVICE CENTER DETAILS</h3>
        </div>

        <div
          id="tab3"
          className={` ${
            tab2 ? "h-auto" : "h-0 opacity-0 overflow-hidden"
          } items-center text-sm px-3 py-2 transition-all flex flex-wrap`}
        >
          {Object.keys(userServiceCenter).map((item: any, i: number) => (
            <CommonInput
              name={item}
              readOnly={true}
              labelText={item}
              inputError={false}
              inputFieldClass="h-30"
              labelTextClass={"font-bold"}
              parentClass={inputStylePopup}
              value={userServiceCenter[item]}
              key={convertToLowerCase(i + "userServiceCenter-tendk")}
            />
          ))}
        </div>

        <div
          id="tab3"
          onClick={() => setTabActive({ ...tabActive, tab3: !tab3 })}
          className="flex items-center mt-2 text-sm bg-gray-200 p-3 cursor-pointer"
          data-jest-id={"jestSetTabActive3"}
        >
          <span
            className={`inline-block w-5 h-5 mr-1 transition-all
                        ${tab3 ? "rotate-0" : "transform -rotate-90"}`}
          >
            <ChevronDownIcon className="w-5 h-5" />
          </span>
          <h3>JOBSHEET DETAILS</h3>
        </div>

        <div
          className={` ${
            tab3 ? "h-auto" : "h-0 opacity-0 overflow-hidden"
          } items-center text-sm px-3 py-2 transition-all flex flex-wrap`}
        >
          {Object.keys(userJobsheet).map((item: any, i: number) => (
            <CommonInput
              name={item}
              readOnly={true}
              labelText={item}
              inputError={false}
              inputFieldClass="h-30"
              value={userJobsheet[item]}
              labelTextClass={"font-bold"}
              parentClass={inputStylePopup}
              key={convertToLowerCase(i + "userJobsheet-tendk")}
            />
          ))}
        </div>

        <div
          id="tab4"
          onClick={() => setTabActive({ ...tabActive, tab4: !tab4 })}
          className="flex items-center mt-2 text-sm bg-gray-200 p-3 cursor-pointer"
          data-jest-id={"jestSetTabActive4"}
        >
          <span
            className={`inline-block w-5 h-5 mr-1 transition-all
                        ${tab4 ? "rotate-0" : "transform -rotate-90"}`}
          >
            <ChevronDownIcon className="w-5 h-5" />
          </span>
          <h3>MOBILE RECEIVED DETAILS</h3>
        </div>

        <div
          id="tab5"
          className={` ${
            tab4 ? "h-auto" : "h-0 opacity-0 overflow-hidden"
          } items-center text-sm px-3 py-2 transition-all flex flex-wrap`}
        >
          <h3>Fixed Problems</h3>

          {userJobsheet["ProceedWith"] !== "Replacement" &&
            Object.keys(problemList).map((item: any, i: number) => (
              <CheckProblems
                label={problemList[item]}
                onChecked={getCheckedItem}
                key={convertToLowerCase(i + "problemList-tendk")}
              />
            ))}

          {userJobsheet["ProceedWith"] === "Replacement" && (
            <CheckProblems label="Proceed with Replacement" onChecked={getCheckedItem} />
          )}
        </div>

        <div className="mt-8 w-full h-20 flex items-center justify-center bg-white shadow">
          <button
            type="button"
            onClick={closePage}
            className="bg-white mx-1 w-40 h-10 shadow text-center rounded hover:bg-gray-50
                hover:border-red-500 hover:shadow-lg border border-red-600"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={updateCallDetails}
            data-jest-id={"jestUpdateCallDetails"}
            className="w-40 h-10 rounded bg-primary-gradient-hover bg-primary-gradient mx-1 hover:shadow-lg"
          >
            Update
          </button>
        </div>
      </div>

      {activePopup && (
        <ModalFixed>
          <ActionPopup
            enableDesc={true}
            customWidth={"auto"}
            handleActionText={"Ok"}
            actionHeading={"Alert"}
            handleAction={handleOkAction}
            handleCancel={() => setActivePopup(false)}
            actionDescription={"All Fixes were not addressed , Do you want to move to store!"}
          />
        </ModalFixed>
      )}
    </div>
  );
};

export default CheckQC;
