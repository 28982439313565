import PageAnimate from "./PageAnimate";
import { ImgAvatarMaleSm3 } from "assets/images";
import React, { Fragment, useEffect } from "react";
import { MenuAlt1Icon, MenuIcon } from "@heroicons/react/outline";
import { headerButton, headerButtonEdit, headerIconButtonSm } from "utils/ClassContent";
import { convertToTitleCase, nullToObject } from "utils/validation";

type FixedPopupTemplateProps = {
  children?: any;
  imgSrc?: string;
  editStatus?: any;
  isMobile?: boolean;
  overflowChild?: any;
  handleEditView?: any;
  enableEditOption?: any;
  handleColumnView?: any;
  columnViewStatus?: any;
  handleClosePopup?: any;
  newButtonComponent?: any;
  textHeaderHeading?: string;
  enableColumnViewOption?: any;
  textSubHeaderHeading?: string;
};

const FixedPopupTemplate = (props: FixedPopupTemplateProps) => {
  const {
    children,
    imgSrc = "",
    isMobile = false,
    editStatus = false,
    overflowChild = false,
    textHeaderHeading = "",
    newButtonComponent = " ",
    columnViewStatus = false,
    enableEditOption = false,
    textSubHeaderHeading = "",
    handleEditView = () => { },
    handleColumnView = () => { },
    handleClosePopup = () => { },
    enableColumnViewOption = false
  } = nullToObject(props);

  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) handleClosePopup(false);
  };

  /**
   * user clik escape key close popUp
   */
  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <Fragment>
      <PageAnimate className="absolute bg-black opacity-80 inset-0 z-0 next-w-full" />
      <PageAnimate
        leaveTo="transition-all duration-100 opacity-0"
        enterFrom="transition-all duration-100 opacity-100 "
        enterTo="transition-all duration-100 opacity-100 "
        leaveFrom="transition-all duration-100 opacity-1 "
        enter="transition-all duration-100 opacity-1 "
        leave="transition-all duration-100 opacity-100 "
      >
        <div className="popup_dataView w-full h-full flex-1 relative z-1 flex flex-col bg-white transform -translate-y-1/2 top-1/2">
          <div
            className={`flex justify-between text-sm border border-red-500 bg-primary-gradient ${isMobile ? "px-1 py-1" : "px-3 py-2 items-center"
              }`}
          >
            <div className="flex items-center">
              {imgSrc && (
                <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
                  <img
                    className="object-cover bg-white w-full h-full rounded-full"
                    src={imgSrc ? imgSrc : ImgAvatarMaleSm3}
                    alt=""
                    loading="lazy"
                  />
                  <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                </div>
              )}
              <div className="">
                <p className="font-semibold text-sm text-white">{convertToTitleCase(textHeaderHeading)}</p>
                {textSubHeaderHeading && <p className="text-sm text-white">{textSubHeaderHeading}</p>}
              </div>
            </div>
            <div
              className={`flex ${isMobile ? "justify-start items-start space-x-1" : "justify-center items-center space-x-2"
                }`}
            >
              {newButtonComponent ? newButtonComponent : null}
              {enableEditOption && (
                <button className={headerButtonEdit} type="button" onClick={handleEditView}>
                  {editStatus ? <>Cancel</> : <>Edit</>}
                </button>
              )}

              {enableColumnViewOption && (
                <button type="button" className={headerIconButtonSm} onClick={handleColumnView}>
                  {columnViewStatus ? <MenuIcon className="w-6 h-6" /> : <MenuAlt1Icon className="w-6 h-6" />}
                </button>
              )}
              <button className={headerButton} type="button" onClick={() => handleClosePopup(false)}>
                Close
              </button>
            </div>
          </div>
          <div
            id="FixedPopup"
            className={`${overflowChild ? " overflow-hidden " : "overflow-y-auto"} w-full h-full flex flex-col`}
          >
            {children}
          </div>
        </div>
      </PageAnimate>
    </Fragment>
  );
};

export default FixedPopupTemplate;
