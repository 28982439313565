import React from "react";
import { _isEmpty } from "utils/lodashConst";
import ComboFreeDisplay from "../ComboFreeDisplay";
import { toLocaleNumber } from "./OfferCommon";

interface ProductOfferProp {
  otherOffers: [];
  setOtherOffers: any;
  product: any;
}
const OtherOffers = (props: ProductOfferProp) => {
  const { otherOffers, setOtherOffers, product } = props;

  const handleOfferAction = (offerId: string, value: string) => {
    const checked_offer: any = otherOffers.map((item: any) => {
      if (item.offerId === offerId) {
        item[value] = !item[value];

        if (value === "showTc") {
          item.showBan = false;
          item.showOffer = false;
        } else if (value === "showBan") {
          item.showTc = false;
          item.showOffer = false;
        } else if (value === "showOffer") {
          item.showTc = false;
          item.showBan = false;
        }
      }
      return item;
    });
    setOtherOffers(checked_offer);
  };

  const handleCheckboxClick = (offer: any) => {
    // if the item is exclusive and all other items are unchecked
    if (offer.exclusivity === "Yes") {
      const offers = otherOffers.map((item: any) => {
        if (item.offerId !== offer.offerId && item.offerType === offer.offerType && item.checked) {
          item.checked = !item.checked;
        } else if (item.offerId === offer.offerId) {
          item.checked = !item.checked;
        }
        return item;
      });

      setOtherOffers(offers);
    } else {
      setOtherOffers((prevOffers: any) =>
        prevOffers.map((item: any) => {
          if (item.offerId === offer.offerId) {
            item.checked = !item.checked;
          }
          return item;
        })
      );
    }
  };

  return (
    <>
      {otherOffers.map((offer: any) => {
        return (
          <div className="flex flex-col text-sm justify-between" key={offer?.offerId}>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between flex-wrap align-middle items-center p-2 font-bold text-gray-800">
                <div className="flex flex-row align-middle items-center justify-start flex-wrap">
                  <span className="p-1">
                    <input
                      disabled={offer?.offerSelection === "mandatory"}
                      name={offer.offerId}
                      checked={offer.checked}
                      id={offer.offerId}
                      onChange={() => handleCheckboxClick(offer)}
                      type="checkbox"
                    />
                  </span>
                  <span className="p-1"> {`${offer?.offerName} (${offer?.sponsorName})`}</span>

                  <button
                    type="button"
                    className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showTc")}
                  >{`T&C`}</button>
                  <button
                    type="button"
                    className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showBan")}
                  >{`Details`}</button>
                  {(!_isEmpty(offer?.comboOffers?.[product?.erp_item_code]) ||
                    !_isEmpty(offer?.freebies?.[product?.erp_item_code])) && (
                    <button
                      type="button"
                      className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                      onClick={() => handleOfferAction(offer.offerId, "showOffer")}
                    >{`+Show Products`}</button>
                  )}
                </div>

                <div className="flex flex-row align-middle text-green-500">
                  {offer.offerType !== "Offer Text" && offer.offerType !== "Standard EMI" && (
                    <span className={!offer.checked ? "line-through" : ""}>{toLocaleNumber(offer?.maxBenefites)}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              {offer.showTc && <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }} />}
              {offer.showBan && (
                <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }} />
              )}
              {offer.showOffer && (
                <div className="offer-expanded">
                  {!_isEmpty(offer.comboOffers) && (
                    <ComboFreeDisplay
                      maxBenefites={offer?.maxBenefites}
                      isCombo={true}
                      offerId={offer._id}
                      comboFreeOffers={otherOffers}
                      setComboFreeOffers={setOtherOffers}
                      offers={offer.comboOffers[product?.erp_item_code]}
                    />
                  )}

                  {!_isEmpty(offer.freebies) && (
                    <ComboFreeDisplay
                      maxBenefites={offer?.maxBenefites}
                      isCombo={false}
                      offerId={offer._id}
                      comboFreeOffers={otherOffers}
                      setComboFreeOffers={setOtherOffers}
                      offers={offer.freebies[product?.erp_item_code]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OtherOffers;
