import React from "react";
import { formatIndianCurrency } from "utils/validation";

type EmiMoreDetailsProps = {
  sp?: any;
  max?: any;
  name?: any;
  isOffLine?: any;
  moreDetailsData?: any;
};

const EmiMoreDetails = (props: EmiMoreDetailsProps) => {
  const { name, moreDetailsData } = props;
  const { bank = '', cashbackRemark = '', loanAmount = '', interestDiscount = '', instantDiscount = '', productAmount = 0,
    tenure = 0, totalEMIAmountPayable = '', rateOfInterest = '', interestChargedByBank = '', totalCashbackOffered = '', additionalCashback = "" } = moreDetailsData;

  return (
    <div className="bg-white px-4 pt-2 pb-3">
      <h2 className="text-lg text-black text-center mb-3 font-semibold">EMI Details</h2>
      <div className="border border-gray-500 p-4">
        <div>
          <p className="font-semibold text-black">{name}</p>
        </div>
        <div className="flex flex-row justify-between border-b py-2 border-gray-100 text-sm">
          <div>
            <p className="text-black">Bank </p>
          </div>
          <div>
            <p className="text-black">{bank}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b py-2 border-gray-100 text-sm">
          <div>
            <p className="text-black">Product Amount</p>
          </div>
          <div>
            <p className="text-black">{formatIndianCurrency(productAmount, 0)}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b py-2 border-gray-100 text-sm">
          <div>
            <p className="text-black">Instant Discount</p>
          </div>
          <div>
            <p className="text-black">- {formatIndianCurrency(instantDiscount, 0)}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b py-1 border-gray-100">
          <div>
            <p className="text-black">Bank Interest Rate</p>
          </div>
          <div><p className="text-black">{rateOfInterest}</p></div>
        </div>
        <div className="flex flex-row justify-between border-b py-2 border-gray-100 text-sm">
          <div>
            <p className="text-black">Interest Discount</p>
          </div>
          <div>
            <p className="text-black">- {formatIndianCurrency(interestDiscount, 0)}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b py-1 border-gray-100">
          <div>
            <p className="text-black">Interest (Charge by Bank)</p>
          </div>
          <div>
            <p className="text-black">{formatIndianCurrency(interestChargedByBank, 0)}</p>
          </div>
        </div>
        <div className="flex flex-row justify-between border-b py-1 border-gray-100">
          <div>
            <p className="text-black">Loan Amount</p>
          </div>
          <div>
            <p className="text-black">{formatIndianCurrency(loanAmount, 0)}</p>
          </div>
        </div>
        {totalCashbackOffered ? (
          <div className="flex flex-row justify-between border-b py-1 border-gray-100">
            <div>
              <p className="text-black">Instant EMI / Cash Back</p>
            </div>
            <div>{formatIndianCurrency(totalCashbackOffered, 0)}</div>
          </div>
        ) : (
          additionalCashback && (
            <div className="flex justify-between mt-3">
              <div>
                <p className="text-black">Post Cash Back</p>
              </div>
              <div>
                <p className="text-black">{formatIndianCurrency(additionalCashback, 0)}</p>
              </div>
            </div>
          )
        )}
        <div className="flex flex-row justify-between">
          <div>
            <p className="text-black">Total EMI for {tenure} Months</p>
          </div>
          <div>{formatIndianCurrency(totalEMIAmountPayable, 0)}</div>
        </div>
        <br />
        {cashbackRemark && <div className="flex flex-row justify-between">
          <div>
            <p className="text-black">Cashback Remarks: {cashbackRemark} Months</p>
          </div>
        </div>
        }
      </div>
    </div>
  );
};

export default EmiMoreDetails;
