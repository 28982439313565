import React from "react";
import { ImgPlaceholderLogo } from "assets/images";
import { LazyLoadImage } from "react-lazy-load-image-component";

const LazyImage = (props: any = {}) => {
  const {
    alt = "",
    src = "",
    height = "",
    width = "",
    basePath = "",
    className = " block mx-auto w-full h-full object-contain object-center"
  } = props;

  /**
   * basePath is missing add base path
   */
  const basePathSetup = (pathBase = "", srcImg = "") => {
    if (pathBase) {
      if (srcImg || "".includes(pathBase)) {
        //image src have already base path retun source of image
        return srcImg;
      }
      return pathBase + srcImg; //else concat img basePth and src
    }
    return srcImg;
  };

  return (
    <div>
      <LazyLoadImage
        alt={alt}
        width={width}
        height={height}
        effect={"black-and-white"}
        className={className}
        src={basePathSetup(basePath, src)} // use normal <img> attributes as props
        onError={({ currentTarget }) => {
          currentTarget.onerror = null; // prevents looping
          currentTarget.src = ImgPlaceholderLogo;
        }}
      />
    </div>
  );
};

export default LazyImage;
