import { getPriceValue } from "../../../../LeadHelper";

export const toLocaleNumber = (number: any) => {
  try {
    return `₹${number.toLocaleString("en-IN")}`;
  } catch (error) {
    return `₹0`;
  }
};

export const getChannelNames = (channel: any): string => {
  return channel.map((ch: any) => ch.name).join(", ");
};

export const getProductPrice = (product: any) => {
  let { mrp = [] } = product;
  const [{ price = [] }] = mrp;

  const [prices] = product.prices;
  let { sp = [] } = prices;

  mrp = getPriceValue(price);
  sp = getPriceValue(sp);

  if (sp === 0) sp = mrp;

  return { mrp, sp };
};
