import React, { useEffect, useState } from "react";
import Loader from "Component/Loader";
import { _get } from "utils/lodashConst";
import { useSelector } from "react-redux";
import { nullToObject } from "utils/validation";
import PageAnimate from "Component/PageAnimate";
import CommonTable from "Component/CommonTable";
import { ImgAvatarMaleSm3 } from "assets/images";
import CommonInput from "Component/Form/CommonInput";
import ModalFixed2 from "Component/Modal/ModalFixed2";
import OnlineEnquiryPopupTab from "./OnlineEnquiryPopupTab";
import OnlineEnqStatusUpdate from "./OnlineEnqStatusUpdate";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import { failToast } from "Component/ToastServices/ToastDisplay";
import OnlineEnqTransactionHistory from "./OnlineEnqTransationHistory";
import { getUserPurchesOrderIdApiService } from "../helperOnlineEnquiry";
import OnlineOrderInfoDropHeading from "../DropHeading/OnlineOrderInfoDropHeading";
import OnlineEmailInfoDropHeading from "../DropHeading/OnlineEmailInfoDropHeading";
import TrackingDetails from "Pages/NewCall/EnquiryFor/OrderTracking/TrackingDetails";
import { getMailByIdCall, resetEnquiryMailCall } from "Pages/ServiceCalls/commonServiceCalls";
import BasicInfoDropHeading from "Pages/ServiceCalls/ViewCallInfo/DropHeading/BasicInfoDropHeading";
import { inputStylePopup, bg_EnquiryStatusHistory, bg_EnquiryStatusUpdate } from "utils/ClassContent";

/**
 * Show's  enquiry details in popup
 * @param props props
 * @returns Enquiry for update popup
 */
const OnlineEnquiryPopup = (props: any = {}) => {
  const { closePopup = () => {}, enquiryData = {}, setShow = true, getListApiReload = () => {} } = nullToObject(props);

  const { _id: cusId = "", status = "", mailId = "", enquiryFor = "", customerName = "", orderId = "" } = enquiryData;

  const globalStore = useSelector((state: any) => state || {});
  const { mailReducer: { selectedMailData = {} } = {} } = nullToObject(globalStore);
  const [order_result, set_order_result] = useState<any>([]);
  const [loader, setLoader] = useState(false);

  const [editView, setEditView] = useState(false);
  const [columnView, setColumnView] = useState(false);
  const [trackingPopup, setTrackingPopup] = useState(false);
  const [popParentTabs, setPopParentTabs] = useState("Tab0");

  const popParentTabsHandle = (value = "") => {
    setPopParentTabs(value);
  };

  // Create's input fields using param conditions
  const createInputFields = (label = "", item = "", userObject: object = {}, editData = true) => {
    return (
      <CommonInput
        name={item}
        labelText={label}
        inputError={false}
        inputFieldClass="h-30"
        readOnly={editData}
        value={userObject[item]}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
      />
    );
  };

  const handleColumnView = () => {
    setColumnView(!columnView);
  };

  // change to edit mode
  const handleEditView = () => {
    if (status.toLowerCase().trim() !== "closed") {
      setEditView(!editView);
    } else {
      failToast("Cannot edit the closed enquiry");
    }
  };

  const handleShowOrderDetails = async (ordId) => {
    setLoader(true);
    const response = await getUserPurchesOrderIdApiService({ orderId: orderId || ordId });
    setLoader(false);
    const { data: { success = false, order_result: orderResult = [] } = {} } = response;
    if (success) {
      setTrackingPopup(true);
      set_order_result(orderResult);
    } else {
      set_order_result([]);
    }
  };

  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) {
      closePopup(); //close popUp press esc button
    }
  };

  /**
   * user clik escape key close popUp
   */
  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);

  useEffect(() => {
    if (mailId) {
      getMailByIdCall(mailId);
    }
    return () => {
      if (mailId) {
        resetEnquiryMailCall();
      }
    };
  }, [mailId]);

  return (
    <React.Fragment>
      {loader ? <Loader /> : null}
      <FixedPopupTemplate
        imgSrc={ImgAvatarMaleSm3}
        editStatus={editView}
        enableColumnViewOption={true}
        columnViewStatus={columnView}
        textHeaderHeading={customerName}
        handleEditView={() => handleEditView()}
        handleClosePopup={() => closePopup(false)}
        handleColumnView={handleColumnView}
        textSubHeaderHeading={`Enquiry For : ${enquiryFor}`}
        enableEditOption={status.toLowerCase().trim() !== "closed"}
      >
        <PageAnimate className="h-full">
          <OnlineEnquiryPopupTab
            mailId={mailId}
            popParentTabs={popParentTabs}
            popParentTabsHandle={popParentTabsHandle}
          />

          <div className={`${columnView ? "flex items-start h-full flex-wrap lg:flex-nowrap pb-5" : ""}`}>
            <div className={`${columnView ? " w-full lg:w-7/12" : " "} flex-wrap flex bg-gray-100"`}>
              <BasicInfoDropHeading
                editView={editView}
                customerData={enquiryData}
                popParentTabs={popParentTabs}
                handleEditView={handleEditView}
              />
              <OnlineOrderInfoDropHeading
                enquiryInfo={enquiryData}
                popParentTabs={popParentTabs}
                createInputFields={createInputFields}
                handleShowOrderDetails={handleShowOrderDetails}
              />
              {mailId && (
                <OnlineEmailInfoDropHeading selectedMailData={selectedMailData} popParentTabs={popParentTabs} />
              )}

              {/*Show order details based onorder id */}
              {trackingPopup && (
                <ModalFixed2>
                  <div className="flex flex-col h-full overflow-auto w-full bg-white">
                    <TrackingDetails
                      enableModal={true}
                      orderTrackingUserData={_get(order_result, "[0]", {})}
                      handleBack={() => setTrackingPopup(false)}
                    />
                  </div>
                </ModalFixed2>
              )}

              <CommonTable
                setShow={true}
                parentClass={"w-full"}
                columnView={columnView}
                customClass={bg_EnquiryStatusHistory}
                id="EnquiryStatusHistory"
                heading={"Enquiry Status History"}
              >
                <OnlineEnqTransactionHistory enquiryData={enquiryData} />
              </CommonTable>
              <CommonTable
                setShow={true}
                parentClass={"w-full"}
                columnView={columnView}
                customClass={bg_EnquiryStatusUpdate}
                id="OnlineStatusUpdate"
                heading={" Online Status Update "}
              >
                <div className="w-full border-dashed flex transition flex-wrap mb-4 h-auto mt-2">
                  <OnlineEnqStatusUpdate
                    _id={cusId}
                    enquiryFor={enquiryFor}
                    columnView={columnView}
                    customerData={enquiryData}
                    selectedMailData={selectedMailData}
                    getListApiReload={getListApiReload}
                  />
                </div>
              </CommonTable>
            </div>
          </div>
        </PageAnimate>
      </FixedPopupTemplate>
      <div id="ModalEnquiryForPopup" />
    </React.Fragment>
  );
};

export default OnlineEnquiryPopup;
