import isEmpty from "lodash/isEmpty";
import React, { useState } from "react";
import { convertToLowerCase } from "utils/validation";
import Loader from "Component/Loader";
import EffectivePriceView from "./EffectivePriceView";
import "./styles.scss";
import EmiOffer from "./EmiOffer";
import OfferItems from "./OfferItems";
import GroupOffer from "../ProductOffersGroup";

const ProductOffers = (props: any) => {
  const { offerList, offerGroup, emiList, product, offerDetLoaders } = props;

  const [viewType, setViewType] = useState(0);

  return (
    <div className=" w-full">
      {offerDetLoaders && (
        <div className="inline-flex items-center">
          <Loader />
        </div>
      )}
      <div className="text-right mr-5">
        <button
          type="button"
          onClick={() => setViewType(0)}
          className={`text-sm font-semibold cursor-pointer ${viewType === 0 ? "text-blue-500 " : " text-gray-500"}`}
        >{` Group View`}</button>{" "}
        |
        <button
          type="button"
          onClick={() => setViewType(1)}
          className={`text-sm font-semibold ml-1 cursor-pointer ${
            viewType === 1 ? "text-blue-500 " : " text-gray-500"
          }`}
        >
          Standard View
        </button>
      </div>
      {viewType === 1 ? (
        <>
          {offerGroup?.map((offers: any, index: number) => {
            return (
              <>
                <GroupOffer offers={offers} index={index} />
                {offerGroup.length !== index + 1 && <span className=" text-center w-full block mt-3">OR</span>}
              </>
            );
          })}
          {offerList?.map((offer: any, index: number) => {
            return (
              <OfferItems
                key={convertToLowerCase(index + "offerList")}
                offer_details={offer}
                index={index}
                total_count={offerList.length}
                product={product}
              />
            );
          })}
        </>
      ) : (
        <EffectivePriceView product={product} offerList={offerList} />
      )}

      {!isEmpty(emiList) ? (
        <div className="px-4 py-2">
          <div className="flex justify-between font-semibold text-white px-2 py-1 text bg-primary-gradient ">
            <span>EMI Details</span>
            <span />
          </div>
          <div className="offer-content overflow-x-auto">
            {emiList?.map((emi: any, index: number) => {
              return <EmiOffer key={convertToLowerCase(index + "emiList")} emi_details={emi} />;
            })}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductOffers;
