import React, { useState, Fragment, useEffect } from "react";
import { pageNumber } from "helperRole";
import { TableColumnHider } from "utils/TableHeaderHider";
import CommonInputSearch from "Component/Form/CommonInputSearch";
import { dateTimeDisplay, nullToObject } from "utils/validation";
import { btn_edit, btn_plus, btn_refresh } from "utils/ClassContent";
import TableColumnSetting from "Component/CommonTable/TableColumnSetting";
import MailReportAndSetting from "Pages/ServiceCalls/MailReport/MailReportAndSetting";
import { RefreshIcon, PlusIcon, InformationCircleIcon, MinusIcon } from "@heroicons/react/outline";
import ExcelDownload from "Component/ExcelDownload";
import ExcelUpload from "Component/ExcelUpload";
import CommonFilterDatePicker from "Component/Form/CommonFilterDatePicker";

type CommonLayoutHeaderProps = {
  onBlur?: any;
  heading?: any;
  isSalesMenu?: any;
  tableId?: string;
  syncBtnTitle?: any;
  handleSearch?: any;
  handleBtnPlus?: any;
  isFilterExpand?: any;
  enableDatePicker?: any;
  lastSyncTime?: string;
  BtnPlustitle?: string;
  enquirySearch?: string;
  Enablesearch?: boolean;
  searchUserDetais?: any;
  handleExcelUpload?: any;
  enableBtnPlus?: boolean;
  handleSearchClear?: any;
  enableSyncBtn?: boolean;
  handleSyncProps?: any;
  MailReportSearch?: string;
  handleExcelDownload?: any;
  handlePlusBtnFilter?: any;
  enableMailReport?: boolean;
  enableExcelUpload?: boolean;
  SyncAnimationState?: boolean;
  tableColumnSettingId?: string;
  enableExcelDownload?: boolean;
  AdditionalButtonComponents?: any;
  enableTableColumnSettingReact?: any;
  enableTableColumnSetting?: boolean;
  children?: any;
  _onDateChange?: Function;
};

const CommonLayoutHeader = (props: CommonLayoutHeaderProps = {}) => {
  const {
    heading = "",
    tableId = "",
    syncBtnTitle = "",
    onBlur = () => {},
    BtnPlustitle = "",
    lastSyncTime = "",
    enquirySearch = "",
    isSalesMenu = false,
    Enablesearch = false,
    enableBtnPlus = false,
    MailReportSearch = "",
    enableDatePicker = false,
    enableSyncBtn = false,
    isFilterExpand = false,
    enableMailReport = false,
    handleSearch = () => {},
    handleBtnPlus = () => {},
    tableColumnSettingId = "",
    enableExcelUpload = false,
    SyncAnimationState = false,
    enableExcelDownload = false,
    searchUserDetais = () => {},
    handleSearchClear = () => {},
    handleSyncProps = () => {},
    handlePlusBtnFilter = () => {},
    AdditionalButtonComponents = "",
    handleExcelDownload = () => {},
    handleExcelUpload = () => {},
    _onDateChange = () => {},
    enableTableColumnSetting = false,
    enableTableColumnSettingReact = "",
    children
  } = nullToObject(props);

  const [refresh, setRefresh] = useState(false);
  const [CommonLayout, setCommonLayout] = useState({
    tableSetting: false,
    tableSettingShow: false
  });

  const { tableSetting, tableSettingShow } = CommonLayout;

  const handleSync = () => {
    handleSyncProps();
  };

  /**
   * handle table setting
   */
  const handleTableSetting = () => {
    setCommonLayout({
      ...CommonLayout,
      tableSetting: true
    });
  };
  /**
   * handle table setting show
   */
  const handleTableSettingShow = () => {
    setCommonLayout({
      ...CommonLayout,
      tableSetting: !tableSetting
    });
  };

  const setTableSetting = (e: any) => {
    setCommonLayout({
      ...CommonLayout,
      tableSetting: e
    });
  };

  useEffect(() => {
    setTimeout(() => {
      if (tableSetting && !tableSettingShow) {
        setCommonLayout({
          ...CommonLayout,
          tableSettingShow: true
        });
        TableColumnHider(tableColumnSettingId, tableId);
      }
    }, 200);
  }, [tableSetting]);

  useEffect(() => {
    setRefresh(SyncAnimationState);
  }, [SyncAnimationState]);

  return (
    <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center sm:py-2 px-2 border-b mb-1">
      <h3 className="font-semibold text-base text-gray-700 flex-1 flex items-center justify-between">
        <div className="flex justify-center items-center">
          {heading}
          {/* lastSyncTime display */}
          {isSalesMenu && (
            <button
              type="button"
              className={btn_edit + "w-6 h-6"}
              title={"Sales Enquiries Filter"}
              data-jest-id={"jestHandleBtnPlus"}
              onClick={handlePlusBtnFilter}
            >
              {isFilterExpand ? <MinusIcon className="w-4 h-4 " /> : <PlusIcon className="w-4 h-4 " />}
            </button>
          )}
        </div>
        {lastSyncTime ? (
          <>
            {"-"}{" "}
            <div className="flex text-blue-600 justify-center items-center">
              <InformationCircleIcon className="w-4 h-4 mr-0.5 ml-1" />
              <span className="text-xs mr-1 leading-tight h-4">Last Synced :</span>
              <span className="text-xs leading-tight h-4 ">{dateTimeDisplay(lastSyncTime)}</span>
            </div>
          </>
        ) : null}
        {children}
      </h3>

      <div className="sortedDetails sm:w-3/4 lg:w-2/3 flex justify-between items-center mb-1">
        <p className="text-xs w-auto flex items-center h-full mt-1" />
        <div className="w-full lg:w-1/2 flex-1 flex justify-end items-center mt-1">
          {(isSalesMenu || enableDatePicker) && (
            <div className="flex flex-row ml-2">
              <CommonFilterDatePicker
                handleDateSelect={(eve) => {
                  _onDateChange(eve);
                }}
              />
            </div>
          )}
          {Enablesearch ? (
            <div className="lg:w-60 relative w-full ml-2">
              <CommonInputSearch
                label={false}
                name={"search"}
                inputWithIcon={true}
                placeholder="Search..."
                value={enquirySearch}
                searchUserDetaisl={searchUserDetais}
                onChange={handleSearch}
                onBlur={onBlur}
                onKeyDown={(e: any) => e.key === "Enter" && handleSearch(e)}
                clearSearchInput={handleSearchClear}
                parentClass={" md_search_View_active active "}
              />
            </div>
          ) : null}

          {AdditionalButtonComponents}

          {/* sync button */}
          {enableSyncBtn ? (
            <button
              type="button"
              title={syncBtnTitle}
              className={btn_refresh}
              onClick={handleSync}
              data-jest-id={"jestHandleSync"}
            >
              <RefreshIcon
                className={`${refresh ? " animate-spin " : " "} h-5 w-5 stroke-current transition-all duration-200`}
              />
            </button>
          ) : null}

          {enableBtnPlus && (
            <button
              type="button"
              className={btn_plus}
              title={BtnPlustitle}
              data-jest-id={"jestHandleBtnPlus"}
              onClick={() => handleBtnPlus(true)}
            >
              <PlusIcon className="w-5 h-5 " />
            </button>
          )}

          {/* permisstion for mail send */}
          {enableMailReport ? (
            <MailReportAndSetting userSearch={MailReportSearch} permisstion={pageNumber.SERVICE_CENTER_MAIL_HANDLE} />
          ) : null}

          {enableExcelDownload ? <ExcelDownload triggerUploadExcel={handleExcelDownload} /> : null}
          {enableExcelUpload ? <ExcelUpload triggerUploadExcel={handleExcelUpload} /> : null}

          {/* click based table column view */}
          {enableTableColumnSetting ? (
            <TableColumnSetting
              id={tableColumnSettingId}
              tableId={tableId}
              Fragment={Fragment}
              tableSetting={tableSetting}
              tableSettingShow={tableSettingShow}
              setTableSetting={(e: any) => setTableSetting(e)}
              handleTableSetting={handleTableSetting}
              handleTableSettingShow={handleTableSettingShow}
            />
          ) : null}
          {enableTableColumnSettingReact ? enableTableColumnSettingReact : null}
        </div>
      </div>
    </div>
  );
};

export default CommonLayoutHeader;
