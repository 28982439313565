import AuditEdit from "./AuditEdit";
import Loader from "Component/Loader";
import { useSelector } from "react-redux";
import { moment } from "utils/momentConst";
import { Decrypt } from "utils/secureFile";
import TicketDetails from "./TicketDetails";
import PageAnimate from "Component/PageAnimate";
import AuditFormChildOne from "./AuditFormChildOne";
import ModalFixed from "Component/Modal/ModalFixed";
import CommonInput from "Component/Form/CommonInput";
import { StyleFormTextarea } from "utils/ClassContent";
import KudosButton from "Pages/AuditReports/KudosButton";
import AuditFormGroupsTable from "./AuditFormGroupsTable";
import CommonRadioBox from "Component/Form/CommonRadioBox";
import CommonTextarea from "Component/Form/CommonTextarea";
import React, { useEffect, useRef, useState } from "react";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import TargetInfoTrip from "Pages/AuditReports/TargetInfoTrip";
import ResponseHistory from "./CommonComponents/ResponseHistory";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { ImgAvatarMaleSm3, ImgBgCallCenter } from "assets/images";
import SubmitInfoButton from "./CommonComponents/SubmitInfoButton";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import CommonSingleDatePicker from "Component/CommonTable/CommonSingleDatePicker";
import { convertToLowerCase, nullToObject, nullToString } from "utils/validation";
import { submitScoreCardResponse } from "Pages/QualityScorecard/ScorecardEditor/ScorecardEditorPages/CommonScoreCard";

const AuditForm = (props: any = {}) => {
  const {
    response = {},
    edit: editd = true,
    viewCurrent = false,
    activeResponse = {},
    activeScoreCard = {},
    handlePopupClose = {},
    isAuditHistory = false,
    handleRevision = () => { },
    handleViewCurrent = () => { },
    employeeDetail: empDetail = {}
  } = props || {};

  const globalSate = useSelector((state: any) => state);
  const { scorecardReducer: { scoreCardRespLoader = false } = {} } = globalSate;
  const dateTimeRef = useRef<HTMLSpanElement>(null);
  const [kudos, setKudos] = useState(false);
  const [causes, setCauses] = useState("");
  const [ticketId, setTicket] = useState("");
  const [enquiryType, setEnquiryType] = useState("");
  const [viewerType, setViewerType] = useState("");
  const [subCauses, setSubCauses] = useState("");
  const [currentId, setCurrentId] = useState("");
  const [showSubCauses, setShowSubCauses] = useState(true);
  const [comments, setComments] = useState("");
  const [currentdynamicFields, setDynamicFields] = useState<any>({});
  const [currentTime, setCurrentTime] = useState(moment().format("HH:mm"));
  const [edit, setEdit] = useState(editd);
  const auditEdit = false;
  const { children = "" } = props;

  /**
   * conver into hms
   * @param value
   * @returns
   */
  const convertHMS = (value: any) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours: any = Math.floor(sec / 3600); // get hours
    let minutes: any = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds: any = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  };
  // get total marks of section
  const getTotalMarks = (group: any = "") => {
    const questMarks = [] as object[];
    const { questions: quests = [] } = activeScoreCard;
    quests.forEach((itm: any = {}) => {
      if ((group === "" || itm.group === group) && !response[itm.sn]?.notApplicable) questMarks.push(itm);
    });
    return questMarks;
  };
  /**
   * filter section a/c to froup
   * @param group
   */
  const filterSection = (group: any = "") => {
    const filteredObj = [] as object[];
    // check fail all section
    let failAll = false;
    Object.keys(response).forEach((itm) => {
      failAll = response[itm].failAll || failAll;
    });
    if (failAll) {
      return [];
    }
    const failSection = [] as any[];
    Object.keys(response).forEach((itm) => {
      if (response[itm].failSection) failSection.push(response[itm].group);
    });
    Object.keys(response).forEach((itm) => {
      if (group === "" && !response[itm].notApplicable && failSection.indexOf(response[itm].group) === -1) {
        filteredObj.push(response[itm]);
      }
      if (
        group &&
        !response[itm].notApplicable &&
        failSection.indexOf(group) === -1 &&
        (group === "" || response[itm].group === group)
      ) {
        filteredObj.push(response[itm]);
      }
    });
    return filteredObj;
  };
  /**
   * get total marks section wise
   * @param group
   */
  const getTotalMarksObtained = (group: any = "") => {
    let total = 0;
    const filteredSection = filterSection(group);
    filteredSection.forEach((element: any = {}) => {
      total += element.marks || 0;
    });
    return total;
  };
  // get total percentage section wise
  const getTotalPercentage = (group: any = "") => {
    const totalSectionMarks = getTotalMarksObtained(group) || 0;
    let total = 0;
    const sectionTotalArr = getTotalMarks(group);
    sectionTotalArr.forEach((element: any = {}) => {
      total += element.maxScore || 0;
    });
    return total === 0 ? 0 : ((totalSectionMarks / total) * 100).toFixed(2);
  };

  const getTotalSectionMarks = (group: any = "") => {
    let total = 0;
    const sectionTotalArr = getTotalMarks(group);
    sectionTotalArr.forEach((element: any = {}) => {
      total += element.maxScore;
    });
    return total;
  };
  // handle text fields
  const handleDynamicFields = (events: any = {}, type = "") => {
    const { optionData = "", value = "", name = "", filterArray = [] } = events;
    switch (type) {
      case "select":
        setDynamicFields((ev: any = {}) => ({ ...ev, [name]: optionData }));
        break;
      case "radio":
        setDynamicFields((ev: any = {}) => ({ ...ev, [name]: value }));
        break;
      case "checkbox":
        setDynamicFields((ev: any = {}) => ({ ...ev, [name]: filterArray }));
        break;
      default:
        const { target: { name: nameA = "", value: valueA = "" } = {} } = events;
        setDynamicFields((ev: any = {}) => ({ ...ev, [nameA]: valueA }));
    }
  };
  // get options list
  const getOptionsList = (itm: any = [], fields = "") => {
    const options = [] as object[];
    (itm || []).forEach((item: any = "", index = 0) => {
      options.push({
        id: index + 1,
        _id: item + index + 1,
        isCheck: fields ? (fields?.indexOf(item) > -1 ? true : false) : false,
        optionData: item,
        optionValue: item,
        itemName: item
      });
    });
    return options;
  };

  // get causes and sub causes data
  const getCausesList = (itm: any = []) => {
    const options = [] as object[];
    itm.forEach((item: any = "", index = 0) => {
      options.push({
        id: index + 1,
        isSelect: false,
        optionData: item.causes,
        optionValue: item.causes
      });
    });
    return options;
  };

  const onChangeCommentsHandler = (e: any) => setComments(nullToString(e?.target?.value));

  // get causes and sub causes data
  const getSubCausesList = (itm = [], value = "") => {
    const options = [] as object[];
    itm.forEach((item: any = "", index = 0) => {
      if (item.causes === value) {
        if (!item.showSubCauses) {
          setShowSubCauses(false);
        }
        item.subCauses.map((subCause: any = {}) => {
          subCause.status &&
            options.push({
              id: index + 1,
              isSelect: false,
              optionData: subCause.label,
              optionValue: subCause.label
            });
          return subCause;
        });
      }
    });
    return options;
  };
  const handleSubmit = (isRequestClick = false, statusParams = "") => {
    const responseObj = [] as object[];
    Object.keys(response).forEach((itm: any = {}) => {
      responseObj.push({
        sn: itm,
        marks: response[itm].marks,
        group: response[itm].group,
        questionId: response[itm].id || response[itm].questionId,
        comment: response[itm].comment,
        failAll: response[itm].failAll,
        selected: response[itm].selected,
        failSection: response[itm].failSection,
        notApplicable: response[itm].notApplicable
      });
    });
    let total = 0;
    const sectionTotalArr = getTotalMarks("");
    sectionTotalArr.forEach((element: any = {}) => {
      total += element.maxScore;
    });
    const requestObj = {
      employeeDetail: typeof employeeDetail === "object" ? employeeDetail : {},
      scoreCardId: activeScoreCard._id,
      eventDate: moment().format("DD/MM/YYYY"),
      eventTime: currentTime,
      eventDUration: dateTimeRef.current?.id,
      monitor: "t",
      causes: causes,
      subcauses: subCauses,
      cardType: "t",
      reference: "t",
      category: "t",
      chatDate: "t",
      medium: "t",
      productEnquiry: "t",
      query: "t",
      source: "t",
      ticketId: ticketId,
      enquiryType: enquiryType,
      responses: responseObj,
      totalScore: total,
      scoreAggregated: getTotalMarksObtained(),
      scorePercentage: getTotalPercentage(),
      comments: [comments],
      kudosGiven: kudos,
      dynamicFields: currentdynamicFields
    };
    if (currentId) {
      const { comments: comment = [], eventDUration = 0 } = activeResponse;
      const { employeeDetail: employeeDetails = [], status = "" } = activeResponse;
      requestObj["eventDUration"] = eventDUration;
      requestObj["employeeDetail"] = employeeDetails;
      requestObj["status"] = !isRequestClick ? (status === "request1:1" ? "reviewed" : "accepted") : "request1:1";
      requestObj["status"] = statusParams || requestObj["status"];
      requestObj["comments"] = comments ? [...comment, comments] : comment;
    }
    const { questions: quests = [] } = activeScoreCard;
    if (quests.length && responseObj.length !== quests.length) {
      return failToast("Fill the incomplete questionnaire");
    }
    submitScoreCardResponse(requestObj, currentId);
  };
  useEffect(() => {
    setCurrentTime(moment().format("HH:mm"));
    let tempTimer = 0;
    const secondsTimer = setInterval(() => {
      if (dateTimeRef.current) {
        tempTimer += 1;
        dateTimeRef.current.innerText = convertHMS(tempTimer).toString();
        dateTimeRef.current.id = tempTimer.toString();
      }
    }, 1000);
    return () => clearInterval(secondsTimer);
  }, []);

  useEffect(() => {
    const {
      _id = "",
      ticketId: ticket = "",
      employeeDetail: { empId: agentEmp = "" } = {},
      createdBy: { empId: createdEmp = "" } = {},
      enquiryType: enqr = "",
      dynamicFields: dynamicFieldObj = "{}",
      status = "",
      kudosGiven = false,
      causes: cause = "",
      subcauses: subcause = ""
    } = activeResponse;
    setCurrentId(_id);
    if (
      !(
        (viewerType === "auditor" && status === "request1:1") ||
        (viewerType === "agent" && status === "reviewed") ||
        (viewerType === "agent" && (status === "pending" || status === "viewed")) ||
        (viewerType === "" && !status)
      )
    ) {
      setEdit(false);
    }
    const { data: { empId = "" } = {} } = Decrypt("userLoginData") || {};
    if (_id) {
      setDynamicFields(typeof dynamicFieldObj === "string" ? JSON.parse(dynamicFieldObj) : dynamicFieldObj);
      setCauses(cause);
      setSubCauses(subcause);
      setTicket(ticket);
      setEnquiryType(enqr);
      setKudos(kudosGiven);
      setViewerType(empId === createdEmp ? "auditor" : empId === agentEmp ? "agent" : "");
      if (empId === agentEmp) {
        setEdit(false);
      } else if (viewerType === "auditor" && status === "request1:1") {
        setEdit(true);
      } else if (viewerType === "agent" && status === "reviewed") {
        setEdit(false);
      } else if (viewerType === "agent" && (status === "pending" || status === "viewed")) {
        setEdit(false);
      }
    }
  }, [JSON.stringify(activeResponse)]);
  const {
    createdAt = "",
    eventTime = "",
    employeeDetail = empDetail,
    transactions = [],
    createdBy = {}
  } = activeResponse;
  const {
    groups = [],
    dynamicFields = [],
    settings: { causesList = [], autoPopulateDateTime = true, showCausesAndSubCauses = true } = {}
  } = activeScoreCard;
  return (
    <ModalFixed>
      {scoreCardRespLoader && <Loader />}
      <FixedPopupTemplate
        handleClosePopup={() => handlePopupClose(false)}
        textHeaderHeading="Agent Name"
        imgSrc={ImgAvatarMaleSm3}
        textSubHeaderHeading={"Service call"}
      >
        <PageAnimate>
          <div className=" w-full text-gray-800 ">
            <div className="flex flex-col">
              <div
                className=" p-2 border-b px-4 h-32 flex justify-between items-center bg-cover bg-overlay"
                style={{
                  background: `url(${ImgBgCallCenter}) no-repeat center center`,
                  backgroundSize: "cover"
                }}
              >
                <div className="relative h-4 z-20"> </div>
                <div className="flex items-center z-10" />
              </div>
              <div className=" flex flex-wrap mb-5">
                <AuditFormChildOne kudos={kudos} employeeDetail={employeeDetail} />
                <div className="lg:w-2/4 xl:w-4/6 px-2 ">
                  <div className="flex shadow-lg  rounded h-full">
                    <div className="flex max-w-4xl  h-full flex-wrap text-sm p-3 whitespace-nowrap s">
                      <TicketDetails
                        ticketId={ticketId}
                        setTicket={setTicket}
                        enquiryType={enquiryType}
                        setEnquiryType={setEnquiryType}
                      />
                      <div className="flex items-center md:w-1/2 px-2 py-1">
                        <strong className="w-32 flex-shrink-0 text-gray-500">Event Date</strong>{" "}
                        <span className="overflow-hidden overflow-ellipsis">
                          {autoPopulateDateTime &&
                            (createdAt ? moment(createdAt).format("DD/MM/YYYY") : moment().format("DD/MM/YYYY"))}
                        </span>
                      </div>
                      <div className="flex items-center md:w-1/2 px-2 py-1">
                        <strong className="w-32 flex-shrink-0 text-gray-500">Event</strong>{" "}
                        <span className="overflow-hidden overflow-ellipsis">
                          {autoPopulateDateTime && (eventTime ? eventTime : currentTime)}
                        </span>
                      </div>
                      <div className="flex items-center md:w-1/2 px-2 py-1">
                        <strong className="w-32 flex-shrink-0 text-gray-500">Event Duration </strong>
                        {activeResponse?.eventDUration ? (
                          <span className="overflow-hidden overflow-ellipsis">
                            {convertHMS(activeResponse?.eventDUration || 0)}
                          </span>
                        ) : (
                          <span className="overflow-hidden overflow-ellipsis" ref={dateTimeRef} />
                        )}
                      </div>
                      <div className="flex items-center md:w-1/2 px-2 py-1">
                        <strong className="w-32 flex-shrink-0 text-gray-500">Monitor</strong>
                        <span className="overflow-hidden overflow-ellipsis">Sathish Kumar</span>
                      </div>
                      {showCausesAndSubCauses && (
                        <>
                          {" "}
                          <div className="flex items-center md:w-1/2 px-2 py-1">
                            <strong className="w-32 flex-shrink-0 text-gray-500">Causes</strong>{" "}
                            <span className="w-full">
                              <CommonSearchDropdown
                                label={false}
                                setShow={true}
                                value={causes}
                                name={"cause"}
                                readOnly={!edit}
                                placeholder={""}
                                inputWithIconClass="w-10"
                                inputFieldClass=" h-8 pr-11"
                                labelTextClass={"font-bold"}
                                inputClass="flex justify-end"
                                clearInput={(data: any = {}) => {
                                  setCauses("");
                                }}
                                arrayData={getCausesList(causesList)}
                                parentClass={" w-full my-1 px-0 transparent_drop2"}
                                handleParentDropVal={(data: any = {}) => {
                                  setCauses(data.optionData);
                                }}
                              />
                            </span>
                          </div>
                          {causes && showSubCauses && (
                            <div className="flex items-center md:w-1/2 px-2 py-1">
                              <strong className="w-32 flex-shrink-0 text-gray-500">Sub Causes</strong>
                              <span className="w-full">
                                <CommonSearchDropdown
                                  label={false}
                                  setShow={true}
                                  readOnly={!edit}
                                  placeholder={""}
                                  value={subCauses}
                                  name={"subCause"}
                                  inputWithIconClass="w-10"
                                  inputFieldClass="h-8 pr-11"
                                  labelTextClass={"font-bold"}
                                  inputClass="flex justify-end"
                                  arrayData={getSubCausesList(causesList, causes)}
                                  clearInput={(data: any = {}) => {
                                    setSubCauses("");
                                  }}
                                  parentClass={" w-full my-1 px-0 transparent_drop2"}
                                  handleParentDropVal={(data: any = {}) => {
                                    setSubCauses(data.optionData);
                                  }}
                                />
                              </span>
                            </div>
                          )}
                        </>
                      )}
                      {dynamicFields.map((item: any = {}, i = 0) => {
                        return (
                          <div
                            key={convertToLowerCase(i + "dynamicFields-tendk")}
                            className="flex items-center md:w-1/2 px-2 py-1"
                          >
                            <strong className="w-32 flex-shrink-0 text-gray-500">{item.headingValue}</strong>
                            <div className="overflow-ellipsis w-full flex flex-col gap-2">
                              {item.optionType === "select" && (
                                <CommonSearchDropdown
                                  label={false}
                                  setShow={true}
                                  readOnly={!edit}
                                  dropOnTop={true}
                                  inputWithIconClass="w-10"
                                  inputFieldClass="h-8 pr-11"
                                  labelTextClass={"font-bold"}
                                  inputClass="flex justify-end"
                                  arrayData={getOptionsList(item?.extraParams)}
                                  clearInput={(data: any = {}) => {
                                    handleDynamicFields(
                                      { ...data, optionData: "", name: item.headingValue.replace(" ", "") },
                                      item.optionType
                                    );
                                  }}
                                  name={item.headingValue.replace(" ", "")}
                                  parentClass={" w-full my-1 px-0 transparent_drop2"}
                                  value={currentdynamicFields[item.headingValue.replace(" ", "")]}
                                  handleParentDropVal={(data: any = {}) =>
                                    handleDynamicFields(
                                      { ...data, name: item.headingValue.replace(" ", "") },
                                      item.optionType
                                    )
                                  }
                                />
                              )}
                              {item.optionType === "text" && (
                                <CommonInput
                                  label={false}
                                  setShow={true}
                                  readOnly={!edit}
                                  parentClass={"w-full"}
                                  labelTextClass={"font-bold"}
                                  inputFieldClass="h-7 w-full text-xs"
                                  name={item.headingValue.replace(" ", "")}
                                  value={currentdynamicFields[item.headingValue.replace(" ", "")]}
                                  onChange={(events: any = {}) => handleDynamicFields(events, item.optionType)}
                                />
                              )}
                              {(item.optionType === "date" || item.optionType === "datetime") && (
                                <div>
                                  {isAuditHistory ? (
                                    <CommonInput
                                      label={false}
                                      setShow={true}
                                      readOnly={!edit}
                                      parentClass={"w-full"}
                                      labelTextClass={"font-bold"}
                                      inputFieldClass="h-7 w-full text-xs"
                                      name={item.headingValue.replace(" ", "")}
                                      onChange={(events: any = {}) => handleDynamicFields(events, item.optionType)}
                                      value={moment(currentdynamicFields[item.headingValue.replace(" ", "")]).format(
                                        item.optionType === "date" ? "D MMM, YYYY" : "D MMM, YYYY h:mm A"
                                      )}
                                    />
                                  ) : (
                                    <CommonSingleDatePicker
                                      id="datefield"
                                      isFilterTime={true}
                                      timeIntervals={1}
                                      placeholder={"Select date"}
                                      name={item.headingValue.replace(" ", "")}
                                      showTimeSelect={!(item.optionType === "date")}
                                      startDate={currentdynamicFields[item.headingValue.replace(" ", "")]}
                                      dateFormat={item.optionType === "date" ? "d MMM, yyyy" : "d MMM, yyyy"}
                                      parentClass={
                                        !(item.optionType === "date") ? "datePickerWithTime h-auto" : "h-auto"
                                      }
                                      dateChange={(events: any = {}) =>
                                        handleDynamicFields(
                                          { target: { name: item.headingValue.replace(" ", ""), value: events } },
                                          item.optionType
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              )}
                              {item.optionType === "textarea" && (
                                <CommonTextarea
                                  setShow={true}
                                  readOnly={!edit}
                                  parentClass={"h-auto"}
                                  labelTextClass={"font-bold"}
                                  inputClass="flex justify-end"
                                  inputFieldClass=" h-14 capitalize"
                                  name={item.headingValue.replace(" ", "")}
                                  value={currentdynamicFields[item.headingValue.replace(" ", "")]}
                                  onChange={(events: any = {}) => handleDynamicFields(events, item.optionType)}
                                />
                              )}
                              {item.optionType === "radio" &&
                                item?.extraParams.map((rdio: any = {}, ind: any = 0) => (
                                  <CommonRadioBox
                                    key={convertToLowerCase(ind + "extraParams1-tendk")}
                                    label={true}
                                    id={"radiobox" + rdio}
                                    name={"radiobox" + rdio}
                                    checked={currentdynamicFields[item.headingValue.replace(" ", "")] === rdio}
                                    onChange={(events: any = {}) =>
                                      handleDynamicFields(
                                        { name: item.headingValue.replace(" ", ""), value: rdio },
                                        item.optionType
                                      )
                                    }
                                    labelText={rdio}
                                  />
                                ))}
                              {item.optionType === "checkbox" && (
                                <MultiDropDownNew
                                  isSelectAll={true}
                                  enableCheckbox={true}
                                  isOKButtonEnable={false}
                                  readOnly={!edit}
                                  // labelText={item.headingValue}
                                  inputClass="flex justify-end"
                                  parentClass="w-full"
                                  customWidthClass={"w-full"}
                                  arrayData={getOptionsList(
                                    item?.extraParams,
                                    currentdynamicFields[item.headingValue.replace(" ", "")]
                                  )}
                                  name={item.headingValue.replace(" ", "")}
                                  filterArrayPass={(data: any = {}) =>
                                    handleDynamicFields(
                                      { ...data, name: item.headingValue.replace(" ", "") },
                                      item.optionType
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {React.cloneElement(children, { edit: edit })}
            <div className=" bg-gray-100 px-2 pt-3 pb-10">
              <div className="max-w-7xl mx-auto flex w-full justify-center">
                <div className="w-1/2 px-2">
                  <h4 className="text-sm font-bold my-2">Score Info</h4>
                  <TargetInfoTrip scoreVlaue={getTotalPercentage()} totalScore={getTotalMarksObtained()} />
                  <AuditFormGroupsTable
                    groups={groups}
                    getTotalSectionMarks={getTotalSectionMarks}
                    getTotalMarksObtained={getTotalMarksObtained}
                    getTotalPercentage={getTotalPercentage}
                  />
                  {auditEdit ? (
                    <AuditEdit comments={comments} edit={edit} setComments={setComments} />
                  ) : (
                    <div className="">
                      {(activeResponse.comments || [])?.length > 0 && (
                        <div>
                          <h4 className="text-sm font-bold my-2">Comment History</h4>
                          {(activeResponse.comments || []).map((itm: any = "", ins = 0) => (
                            <p key={convertToLowerCase(ins + "activeResponse-tendk")} className="text-gray-500 text-sm">
                              <div dangerouslySetInnerHTML={{ __html: itm }} />
                            </p>
                          ))}
                        </div>
                      )}
                      <h4 className="text-sm font-bold my-2">Comments</h4>
                      <p className="text-gray-600">
                        <CommonTextarea
                          setShow={true}
                          name="comments"
                          value={comments}
                          labelText={""}
                          onChange={onChangeCommentsHandler}
                          labelTextClass={"font-bold"}
                          parentClass={StyleFormTextarea()}
                        />
                      </p>
                      <div className="flex mt-2">
                        <KudosButton
                          animateID={"kudoStar"}
                          setKudos={(e: any) => setKudos(!edit ? kudos : !kudos)}
                          kudos={kudos}
                        />
                      </div>
                    </div>
                  )}
                  {!viewCurrent && (
                    <SubmitInfoButton
                      handleSubmit={handleSubmit}
                      viewerType={viewerType}
                      status={activeResponse.status}
                    />
                  )}
                  <ResponseHistory
                    handleRevision={handleRevision}
                    viewCurrent={viewCurrent}
                    handleViewCurrent={handleViewCurrent}
                    transactions={transactions}
                    createdBy={createdBy}
                  />
                </div>
              </div>
            </div>
          </div>
        </PageAnimate>
      </FixedPopupTemplate>
    </ModalFixed>
  );
};
export default AuditForm;
