import React, { useEffect, useState } from "react";
import Loader from "Component/Loader";
import { useSelector } from "react-redux";
import { Decrypt } from "utils/secureFile";
import ScoreCardTable from "./ScoreCardTable";
import AuditForm from "Pages/AuditReports/AuditForm";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { getScoreCardServiceByIdServiceCall } from "./ScorecardEditor/ScorecardEditorPages/CommonScoreCard";

const ScoreCardForm = (props: any = {}) => {
  const { scoreId = "", handleClose = () => {}, isRequest1N1 = false } = props;
  const globalSate = useSelector((state: any) => state) || {};
  const { scorecardReducer = {} } = globalSate; //globalStore
  const { scoreCardData = {}, scoreCardRespSuccess = false } = scorecardReducer;

  const [activeResponse, setActiveResponse] = useState({});
  const [activeScoreCard, setActiveScoreCard] = useState<any>({});
  const [response, setResponse] = useState({});
  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [autoFill, setAutoFill] = useState(false);
  const [auditState, setAuditState] = useState(false);

  /**
   * handle Score
   */
  const handleScoreChange = (item: any = {}, selected = "", quest: any = {}) => {
    if (!edit) {
      failToast("Unable to perform action because this scorecard is in evaluation");
      return;
    }
    const { sn = 0, weight = 0, notApplicable = [], failAll = [], failSection = [], group = "" } = quest;
    let tempResp = response;
    tempResp = tempResp[sn] || {
      marks: 0,
      selected: "",
      group: "",
      notApplicable: false,
      failAll: false,
      failSection: false
    };
    tempResp["marks"] = parseFloat(weight) * parseFloat(item["weightage"]);
    tempResp["id"] = quest["_id"];
    tempResp["failAll"] = false;
    tempResp["failSection"] = false;
    tempResp["notApplicable"] = false;
    if (notApplicable.indexOf(selected) > -1) {
      tempResp["notApplicable"] = true;
    } else if (failAll.indexOf(selected) > -1) {
      tempResp["failAll"] = true;
    } else if (failSection.indexOf(selected) > -1) {
      tempResp["failSection"] = true;
    }
    tempResp["selected"] = selected;
    tempResp["group"] = group;
    setResponse({ ...response, [sn]: tempResp });
    setTimeout(() => {
      setTotalScore(checkTotalScore({ ...response, [sn]: tempResp }));
    }, 10);
  };

  /**
   * handle causes and comments
   */
  const handleCausesAndComments = (event: any = {}, sn: any = 1) => {
    const { target: { value = "" } = {} } = event;
    let tempResp = response;
    tempResp = tempResp[sn] || { marks: 0, selected: "", comment: "", group: "", failAll: false, failSection: false };
    tempResp["comment"] = value;
    setResponse({ ...response, [sn]: tempResp });
  };

  /** check fail All */
  const checkFailAll = (resp: any) => {
    // if (!settings.failAllResToZero) {
    //     return false;
    // }
    let failAll = false;
    Object.keys(resp).forEach((itm: any = {}) => {
      if (resp[itm].failAll) {
        failAll = true;
      }
    });
    return failAll;
  };

  /** check fail All */
  const checkSectionFail = (resp: any) => {
    const sectionFail = [] as string[];
    Object.keys(resp).forEach((itm: any = {}) => {
      if (resp[itm].failSection) {
        sectionFail.push(resp[itm].group);
      }
    });
    return sectionFail;
  };

  /**
   * check total score
   * @returns total score
   */
  const checkTotalScore = (resp: any = {}) => {
    let total = 0;
    if (checkFailAll(resp)) {
      return 0;
    }
    const sectionFail = checkSectionFail(resp);
    Object.keys(resp).forEach((itm: any = {}) => {
      total += sectionFail.indexOf(resp[itm].group) > -1 ? 0 : parseFloat(resp[itm].marks);
    });
    return total;
  };

  /**
   * get max score
   */
  const getMaxScore = (options = {}, weight = "") => {
    let max = 0;
    let option = { text: "" };
    Object.keys(options).forEach((itm) => {
      if (max <= parseFloat(options[itm].weightage) * parseFloat(weight)) {
        max = parseFloat(options[itm].weightage) * parseFloat(weight);
        option = { ...options[itm], text: itm };
      }
    });
    return { max, option };
  };

  /**
   * get options list
   */
  const getOptionsList = (itm: any = []) => {
    const options = [] as object[];
    itm.forEach((item: any = "", index = 0) => {
      options.push({
        id: index + 1,
        isSelect: false,
        optionData: item,
        optionValue: item
      });
    });
    return options;
  };
  /**
   * handle auto fil
   */
  const handleAutoFill = () => {
    if (autoFill) {
      setAutoFill(false);
      setResponse({});
      return;
    }
    const { questions: question = [] } = activeScoreCard;
    setAutoFill(!autoFill);
    const tempResp = {};
    if (question && question.length) {
      question.forEach((item: any = {}) => {
        // calculate max score with score
        const selectedOption = getMaxScore(item.options, item.weight);
        const {
          option: { text = "" }
        } = selectedOption;
        const {
          _id: questionId = "",
          sn = 0,
          weight = 0,
          notApplicable = [],
          failAll = [],
          failSection = [],
          group = ""
        } = item;
        const respRes = { marks: 0, selected: "", group: "", notApplicable: false, failAll: false, failSection: false };
        respRes["marks"] = parseFloat(weight) * parseFloat(selectedOption?.option["weightage"]);
        respRes["id"] = questionId;
        respRes["notApplicable"] = false;

        if (notApplicable.indexOf(text) > -1) {
          respRes["notApplicable"] = true;
        } else if (failAll.indexOf(text) > -1) {
          respRes["failAll"] = true;
        } else if (failSection.indexOf(text) > -1) {
          respRes["failSection"] = true;
        }
        respRes["selected"] = text;
        respRes["group"] = group;
        tempResp[sn] = respRes;
      });
      setResponse(tempResp);
      setTimeout(() => {
        setTotalScore(checkTotalScore(tempResp));
      }, 10);
    }
  };

  const getScoreCardResponseByIdApiCall = async (scoreIds) => {
    setLoader(true);
    const response = await getScoreCardServiceByIdServiceCall(scoreIds);
    setLoader(false);

    const { data: { data = {}, status = 0 } = {} } = response || {};
    if (status === 200) {
      const { _id = "", employeeDetail: { empId: agentEmp = "" } = {}, scoreCardDetail = {} } = data;
      const { data: { empId = "" } = {} } = Decrypt("userLoginData");
      if (_id && scoreIds) {
        setActiveResponse({ ...data, scoreCardDetail: {} });
        setActiveScoreCard(scoreCardDetail);
        setEdit(true);
        const { responses = {} } = data;
        // set response
        const respObj = {};
        responses.forEach((itm: any = {}) => {
          respObj[itm.sn] = itm;
        });
        setResponse(respObj);
        setTimeout(() => {
          setAuditState(true);
        }, 20);
        setTotalScore(checkTotalScore(respObj));
        if (empId === agentEmp) {
          setEdit(false);
        }
      }
    }
  };

  useEffect(() => {
    if (scoreId) {
      getScoreCardResponseByIdApiCall(scoreId);
    }
  }, [scoreId]);

  useEffect(() => {
    const { _id = "" } = scoreCardData;
    if (_id) {
      setActiveScoreCard(scoreCardData);
      setEdit(true);
      setTimeout(() => {
        setAuditState(true);
      }, 10);
      setTotalScore(checkTotalScore(response));
    }
  }, [scoreCardData]);

  useEffect(() => {
    scoreCardRespSuccess && handleClose(false);
  }, [scoreCardRespSuccess]);

  return (
    <>
      {loader && <Loader />}
      <AuditForm
        edit={edit}
        response={response}
        isRequest1N1={isRequest1N1}
        totalScore={totalScore}
        employeeDetail={auditState}
        activeScoreCard={activeScoreCard}
        activeResponse={activeResponse}
        handlePopupClose={() => handleClose(false)}
      >
        <ScoreCardTable
          activeScoreCard={activeScoreCard}
          edit={edit}
          autoFill={autoFill}
          handleAutoFill={handleAutoFill}
          response={response}
          handleCausesAndComments={handleCausesAndComments}
          getOptionsList={getOptionsList}
          handleScoreChange={handleScoreChange}
        />
      </AuditForm>
    </>
  );
};

export default ScoreCardForm;
