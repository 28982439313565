import React, { Fragment } from "react";
import "../../assets/scss/checbox.css";
import { commonCheckProps } from "./types";
import { nullToObject } from "utils/validation";
import { Transition } from "@headlessui/react";

const CommonCheck = (props: commonCheckProps) => {
  const {
    id = 0,
    children,
    name = "",
    value = "",
    labelText = "",
    setShow = true,
    checked = false,
    dataParams = "",
    parentClass = "",
    disabled = false,
    defaultValue = "",
    mustField = false,
    onBlur = () => {},
    customLabel = false,
    onClick = () => {},
    onChange = () => {},
    parentAsLabel = false,
    parentAsLabelClass = ""
  } = nullToObject(props);

  const Checktemplate = () => {
    return (
      <Fragment>
        <Transition
          show={setShow}
          leaveTo="opacity-0"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leaveFrom="opacity-10"
          enter="transition-opacity duration-200"
          leave="transition-opacity duration-200"
          className={`form-check ${parentClass ? parentClass : ""}`}
        >
          <input
            name={name}
            value={value}
            type="checkbox"
            onBlur={onBlur}
            id={`check-${id}`}
            onClick={onClick}
            disabled={disabled}
            defaultValue={defaultValue}
            className="form-check-input"
            data-extra-param={dataParams}
            onChange={disabled ? null : onChange}
            checked={defaultValue ? false : checked}
          />

          {labelText && (
            <label
              htmlFor={disabled ? "" : `check-${id}`}
              className={` ${disabled ? "cursor-not-allowed" : "cursor-pointer "} form-check-label pl-2 text-sm font-normal`}
            >
              {labelText} {mustField && <sup style={{ color: "red" }}>*</sup>}
            </label>
          )}
        </Transition>

        {customLabel && (
          <label
            htmlFor={disabled ? "" : `check-${id}`}
            className={` ${disabled ? "cursor-not-allowed" : "cursor-pointer "} form-check-label cursor-pointer ml-2 text-sm  font-normal`}
          >
            {labelText}
          </label>
        )}
        {children}
      </Fragment>
    );
  };

  return parentAsLabel ? (
    <label
      tabIndex={0}
      role="button"
      htmlFor={`check-${id}`}
      className={`${parentAsLabelClass ? parentAsLabelClass : ""} cursor-pointer`}
    >
      {Checktemplate()}
    </label>
  ) : (
    <React.Fragment>{Checktemplate()}</React.Fragment>
  );
};

export default CommonCheck;
