import React, { useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { RefreshIcon } from "@heroicons/react/solid";
import { AdjustmentsIcon } from "@heroicons/react/outline";
import OutsideClickHandler from "react-outside-click-handler";
import { TableColumnSettingReactProps } from "Pages/Customers/CallsList/CustomerListDisplay/types";

const TableColumnSetting = (props: TableColumnSettingReactProps) => {
  const {
    id = "",
    Fragment,
    tableId = "TableID",
    tableSetting = false,
    tableSettingShow = false,
    setTableSetting = () => {}, //click
    handleTableSetting = () => {}, //click
    handleTableSettingShow = () => {} //click,
  } = props || {};
  const [reset, setReset] = useState(false);
  const [drop, setdrop] = useState(tableSetting);

  const getLocalWebsettings = (tableID = "") => {
    const TableSetting = localStorage.getItem(`Table-${tableID}`);
    return TableSetting ? JSON.parse(TableSetting) : {};
  };

  const colIndex = getLocalWebsettings(tableId);

  const addStyle = () => {
    const styleSheet = document && document.createElement("style");
    styleSheet.setAttribute("id", `table-${tableId}`);
    let style = "";
    for (const key in colIndex) {
      style += `
          #${tableId} tr th:nth-child(${colIndex[key] + 1}), #${tableId} tr td:nth-child(${colIndex[key] + 1}) {
            display:none !important;
        }
        #${id} [data-check-index="${colIndex[key]}"] {
            order:2;
          }
        `;
    }
    styleSheet.innerHTML = style;
    return document && document.head.appendChild(styleSheet);
  };

  const handleColumnReset = () => {
    const styleEle = document && document?.getElementById(`table-${tableId}`);
    styleEle?.remove();
    const checkbox: any = document && document.querySelectorAll(`#${id} input`);
    checkbox &&
      checkbox.forEach((item: any = {}) => {
        item.checked = true;
        item.disabled = false;
      });
    localStorage.removeItem(`Table-${tableId}`);
    setReset(true);
    setTimeout(() => {
      setReset(false);
    }, 1500);
  };

  const handleToggleFillterDrop = () => {
    setdrop(!drop);
  };

  useEffect(() => {
    /* Function call */
    window.onload = function () {
      addStyle();
    };
  }, []);

  useEffect(() => {
    /* Function call */
    addStyle();
  }, [id]);

  return (
    <div className="w-8 h-8 relative ml-2">
      <button
        type="button"
        onClick={
          tableSettingShow
            ? () => {
                handleTableSettingShow();
                handleToggleFillterDrop();
              }
            : () => {
                handleTableSetting();
                handleToggleFillterDrop();
              }
        }
        className={`${
          tableSetting && drop ? "bg-primary-gradient-rv " : " bg-primary-gradient "
        } flex justify-center items-center w-8 h-8  bg-primary-gradient-hover rounded`}
      >
        <AdjustmentsIcon className={`${tableSetting && drop ? "transform rotate-180" : ""} w-5 h-5 text-white`} />
      </button>
      <div style={{ display: `${tableSetting && drop ? "block" : "none"}` }} className="relative inline-block">
        <OutsideClickHandler onOutsideClick={() => setTableSetting(false)}>
          <Transition
            as={Fragment}
            show={true}
            leave="transition ease-in duration-75"
            leaveTo="transform opacity-0 scale-95"
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leaveFrom="transform opacity-100 scale-100"
            className={"w-48 absolute right-0 "}
          >
            <div className="border-bottom bg-gray-50 py-1 px-2 flex flex-col">
              <div className="flex-col flex p-2 cursor-pointer">
                <div className="flex flex-col px-2 py-1 bg-gray-100 relative z-10">
                  <button
                    onClick={handleColumnReset}
                    className=" inline-flex items-center w-full px-2 text-xs capitalize text-gray-700 text-left cursor-pointer"
                    type="button"
                  >
                    <RefreshIcon
                      className={`${
                        reset ? " animate-spin " : " "
                      } h-5 w-5 mr-2 text-red-500 transition-all duration-200`}
                    />
                    <span>Reset Columns</span>
                  </button>
                </div>
                <ul
                  id={id ? id : ""}
                  className="tablesettingDrop py-2 w-48 bg-white rounded-md shadow-xl z-20 text-left"
                />
              </div>
            </div>
          </Transition>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default TableColumnSetting;
