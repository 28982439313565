import { _get } from "utils/lodashConst";

export const scorecardReducerInit = {
  scoreCardData: {},
  scoreCardDataList: {},
  scoreCardRespList: {},
  scoreCardResponse: {},
  scoreCardLoader: false,
  scoreCardSuccess: false,
  scoreCardRespLoader: false,
  scoreCardRespSuccess: false,
  lockScoreCardSuccess: false,
  scoreCardEvalLoader: false,
  scoreCardEditSuccess: false
};

export const scorecardReducer = (state = scorecardReducerInit, action: any = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear

    case "GET_SCORECARD_EVAL_LOADER": {
      return {
        ...state,
        scoreCardEvalLoader: _get(action, "scoreCardEvalLoader", false)
      };
    }
    case "GET_SCORECARD_LIST_LOADER":
    case "SCORECARD_CREATE_LOADER": {
      return {
        ...state,
        scoreCardLoader: _get(action, "scoreCardLoader", false)
      };
    }
    case "SCORECARD_CREATE_SUCCESS": {
      return {
        ...state,
        scoreCardSuccess: _get(action, "scoreCardSuccess", false)
      };
    }
    case "SCORECARDRESP_CREATE_SUCCESS": {
      return {
        ...state,
        scoreCardRespSuccess: _get(action, "scoreCardRespSuccess", false)
      };
    }
    case "SCORECARD_EDIT_SUCCESS": {
      return {
        ...state,
        scoreCardEditSuccess: _get(action, "scoreCardEditSuccess", false)
      };
    }

    case "SCORECARD_LIST_DATA_STORE": {
      return {
        ...state,
        scoreCardDataList: _get(action, "scoreCardDataList", {})
      };
    }

    case "SCORECARD_RESPONSE_DATA": {
      return {
        ...state,
        scoreCardResponse: _get(action, "scoreCardResponse", {})
      };
    }

    case "SCORECARD_LIST_DATA": {
      return {
        ...state,
        scoreCardData: _get(action, "scoreCardData", {})
      };
    }
    case "SCORECARD_RESP_DATA_STORE": {
      return {
        ...state,
        scoreCardRespList: _get(action, "scoreCardRespList", {})
      };
    }

    case "SCORECARD_LOCK_SUCCESS": {
      return {
        ...state,
        lockScoreCardSuccess: _get(action, "lockScoreCardSuccess", false)
      };
    }

    default:
      return {
        ...state
      };
  }
};
