import React, { useState } from "react";
import { nullToObject } from "utils/validation";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";

type readMoreType = {
  content?: string;
  statusId?: any;
  contentLimit?: any;
};
const ReadMore = (props: readMoreType) => {
  const { content = "", contentLimit = 250 } = nullToObject(props);

  const [readMore, setReadMore] = useState(false);

  const readHandle = (value = false) => setReadMore(value);

  return content.length > contentLimit ? (
    <p style={{ minWidth: "200px" }} className="inline-block max-w-2xl break-word py-2">
      <div
        className={` inline-block transition-all duration-150 ease-in-out overflow-hidden 
                        ${readMore ? " height-auto opacity-100 " : " w-0 h-0 opacity-0 leading-none"}`}
      >
        {content}
        <div
          title="less"
          onClick={() => readHandle(!readMore)}
          data-jest-id={"jestReadHandle1"}
          className="inline-flex hover:text-blue-700 transition-all duration-150
                         font-semibold items-center justify-center relative top-px cursor-pointer mx-1 py-.5 text-blue-500 rounded-sm"
        >
          {"["}
          <MinusIcon className="w-3 h-3 mx-px" />
          {"]"}
        </div>
      </div>

      <div
        title="more"
        className={` inline-block  transition-all duration-150 ease-in-out overflow-hidden 
                        ${!readMore ? " height-auto opacity-100 " : " w-0 h-0 opacity-0  leading-none "}`}
      >
        {content.substring(0, contentLimit)}
        {"..."}
        <div
          data-jest-id={"jestReadHandle2"}
          className="inline-flex hover:text-blue-700 transition-all duration-150
                         font-semibold items-center justify-center relative top-px cursor-pointer mx-1 py-.5 text-blue-500 rounded-sm"
          onClick={() => readHandle(!readMore)}
        >
          {"["}
          <PlusIcon className="w-3 h-3 mx-px" />
          {"]"}
        </div>
      </div>
    </p>
  ) : (
    <p style={{ minWidth: "150px" }}> {content} </p>
  );
};
export default ReadMore;
