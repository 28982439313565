import { tdClassStyle2, thClassStyle2 } from "utils/ClassContent";
import { convertToLowerCase } from "utils/validation";

export type AuditFormGroupsTableProps = {
    groups?: any;
    getTotalPercentage?: any;
    getTotalSectionMarks?: any;
    getTotalMarksObtained?: any;
  };

const AuditFormGroupsTable = (props: AuditFormGroupsTableProps = {}) => {
    const { groups = [], getTotalSectionMarks = () => {}, getTotalMarksObtained = () => {}, getTotalPercentage = () => {} } = props;
return (<div className="w-full ">
    <h4 className="text-sm font-bold my-2">Section Totals</h4>
    <div className="overflow-auto w-full">
      <table className={tdClassStyle2 + " w-full "}>
        <thead>
          <tr>
            <th className={thClassStyle2}>Section(s)</th>
            <th className={thClassStyle2}>Total </th>
            <th className={thClassStyle2}>Total Obtained</th>
            <th className={thClassStyle2}>Percentage</th>
          </tr>
        </thead>
        <tbody>
          {groups?.map((itm: any = "", inde = 0) => (
            <tr key={convertToLowerCase(inde + "groups-tendk")}>
              <td className={tdClassStyle2}>{itm}</td>
              <td className={tdClassStyle2}>{getTotalSectionMarks(itm)}</td>
              <td className={tdClassStyle2}>{getTotalMarksObtained(itm)}</td>
              <td className={tdClassStyle2}>{getTotalPercentage(itm)} %</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>);
};

export default AuditFormGroupsTable;
