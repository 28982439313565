import React from "react";
import TableHead from "Component/CommonTable/TableHead";

const TLHierarchyListHeader = (props: any = {}) => {
  const { handleSort = () => {}, type = "team" } = props || {};
  return (
    <thead>
      <tr className="border">
        <TableHead
          enableSort={true}
          isData={false}
          text={type === "team" ? "TL's Name" : "Employee Name"}
          handleSort={(state: any) => handleSort(state, "name")}
        />
        {type !== "member" && (
          <TableHead
            enableSort={true}
            isData={false}
            text={"Members Count"}
            handleSort={(state: any) => handleSort(state, "count")}
          />
        )}
        <TableHead
          enableSort={true}
          isData={false}
          text={"Score %"}
          handleSort={(state: any) => handleSort(state, "avgScore")}
        />
        <TableHead
          enableSort={true}
          isData={false}
          text={"Audit Count"}
          handleSort={(state: any) => handleSort(state, "scoreCount")}
        />
        <TableHead
          enableSort={true}
          isData={false}
          text={"Fatal Count"}
          handleSort={(state: any) => handleSort(state, "failAllCount")}
        />
      </tr>
    </thead>
  );
};

export default TLHierarchyListHeader;
