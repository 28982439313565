import React, { Fragment, useEffect, useState } from "react";
import { getCourierTrackingApiCall, getCourierTrackingInfoApiService } from "../../CommonOrderTracking";
import EcomExpressTrackingTable from "./EcomExpressTrackingTable";
import CommonDropHeading from "Component/CommonDropHeading";
import { convertToLowerCase } from "utils/validation";
import Loader from "Component/Loader";

const EcomExpressTracking = (props: any) => {
  const { awb_no = "" } = props || {};

  const [courierTrackingData, set_courierTrackingData] = useState([]);
  const [loader, setLoader] = useState({});

  const getCourierTrackingApiCall = async (payload) => {
    setLoader(true);
    const response = await getCourierTrackingInfoApiService(payload);
    setLoader(false);

    const { status = 0, data: { trackingData = {} } = {} } = response || {};
    const { response: trackingResponse = [] } = trackingData || {};
    if (status === 200) {
      set_courierTrackingData(trackingResponse);
    } else {
      set_courierTrackingData([]);
    }
  };

  useEffect(() => {
    if (awb_no) {
      getCourierTrackingApiCall(awb_no);
    }
  }, [awb_no]);

  return (
    <Fragment>
      {loader && <Loader />}
      {courierTrackingData.map((eachTrans: any, i = 0) => {
        const { _id = "", tracking_detail = [] } = eachTrans || {};
        return (
          <div key={convertToLowerCase(i + "courierTrackingData-tendk")} className="mb-4">
            <CommonDropHeading
              id={_id}
              customClass={" bg-primary  text-white"}
              heading={<span className="text-sm font-bold">Shipments by date : {_id}</span>}
            >
              <EcomExpressTrackingTable tracking_detail={tracking_detail} />
            </CommonDropHeading>
          </div>
        );
      })}
    </Fragment>
  );
};

export default EcomExpressTracking;
