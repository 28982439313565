import React from "react";
import { nullToObject } from "utils/validation";
import { commonImageTagProps } from "./types";

const CommonImageTag = (props: commonImageTagProps) => {
  const { url = "", imgData = "", altName = "", className = "" } = nullToObject(props);

  const targetUrl = (urlData = "") => {
    if (urlData && typeof urlData === "string") {
      const anchor = document && document.createElement("a");
      anchor.href = urlData;
      anchor.target = "_blank";
      anchor.click();
    }
  };

  return (
    <img
      src={imgData}
      alt={altName}
      className={className}
      data-jest-id={"jestTargetUrl"}
      onClick={() => targetUrl(url)}
    />
  );
};
export default CommonImageTag;
