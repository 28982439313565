import React from "react";
import { nullToObject } from "utils/validation";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/solid";
import { modalFixedParentClass } from "utils/ClassContent";
import { TableTemplateProps } from "./CommonTableSortDropdown/types";
import { ArrowsExpandIcon, ChevronDownIcon } from "@heroicons/react/outline";

const TableTemplate = (props: TableTemplateProps) => {
  const {
    id = "",
    children,
    heading = "",
    tabs = false,
    setShow = true,
    customClass = "",
    parentClass = "",
    showModal = false,
    enableDrop = true,
    columnView = false,
    enableClose = false,
    enableResize = true,
    tabHandle = () => {},
    handleFullView = () => {},
    handleTableClose = () => {}
  } = nullToObject(props);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`bg-blueGray-50 ${parentClass ? parentClass : ""} ${showModal ? modalFixedParentClass : ""}`}
    >
      <div
        id={id}
        className={`${showModal ? " " : "mt-1 "}  ${columnView ? " " : "  "} ${
          !columnView && !showModal ? "  " : " "
        } w-full ${tabs ? " mb-0" : "mb-12"} xl:mb-0 mx-auto relative `}
      >
        <div
          className={`relative h-full min-w-0 break-words bg-white w-full max-h-full ${
            tabs ? " mb-2" : "mb-6"
          } rounded ${showModal ? "" : "shadow-lg"}`}
        >
          <div className={` ${showModal ? "" : " "} rounded-t mb-0  border-0`}>
            <div
              className={`flex justify-between items-center ${
                customClass ? customClass : " bg-yellow-500 text-white"
              }   w-full px-4 py-2 text-gray-800 `}
            >
              <h3 className="w-full text-sm font-bold  ">{heading}</h3>
              <div className="flex space-x-2 -mr-1">
                {enableResize && (
                  <button
                    type="button"
                    title="maximize/minimize"
                    onClick={handleFullView}
                    className={` hover:bg-white hover:text-yellow-500 rounded
                                         w-6 h-6 flex justify-center items-center `}
                  >
                    <ArrowsExpandIcon className="w-5 h-5" />
                  </button>
                )}
                {enableDrop && (
                  <button
                    title="hide/show"
                    type="button"
                    onClick={() => tabHandle(!tabs)}
                    className={` hover:bg-white hover:text-yellow-500 rounded
                                         w-6 h-6 flex justify-center items-center `}
                  >
                    <ChevronDownIcon className={`w-5 h-5 ${!tabs ? "transform rotate-180" : ""} `} />
                  </button>
                )}
                {enableClose && (
                  <button
                    title="hide/show"
                    type="button"
                    onClick={() => handleTableClose(false)}
                    className={` hover:bg-white hover:text-yellow-500 rounded
                                         w-6 h-6 flex justify-center items-center `}
                  >
                    <XIcon className={`w-5 h-5 ${!tabs ? "transform rotate-180" : ""} `} />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={`animateHeight overflow-auto max-h-full ${tabs ? "h-auto " : " activeHeight"}`}>
            {children}
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default TableTemplate;
