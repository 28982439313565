import React, { useEffect, useReducer, useState } from "react";
import { nullToObject } from "utils/validation";
import PaginationControl from "./PaginationControl";

/**
 *  @param  {number} {totalItems} - pass Total Number of Pagination
 *  @param  {number} {currentPage} - currentPage number
 *  @param  {number} {pageSize} - currentPage pageSize
 */
const getPager = (totalItems: any, currentPages: any, pageSizes: any) => {
  // default to first page
  const currentPage = currentPages || 1;
  // default page size is 10
  const pageSize = pageSizes || 10;
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);
  let startPage: any, endPage;
  if (totalPages <= 2) {
    // less than 2 total pages so show all
    startPage = 1;
    endPage = totalPages;
  } else {
    // more than 2 total pages so calculate start and end pages
    if (currentPage <= 2) {
      startPage = 1;
      endPage = 3;
    } else if (currentPage + 1 >= totalPages) {
      startPage = totalPages - 2;
      endPage = totalPages;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }
  }
  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  // create an array of pages to ng-repeat in the pager control
  const pages: any = [...Array(endPage + 1 - startPage).keys()].map((i: any) => startPage + i);

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages
  };
};

const Pagination = (props: any) => {
  const {
    items = 10, //Total Count
    pageSize = 10, //How many data view perPage
    initialPage = 1, //Initial Page
    allTableDetails = {},
    paginationDetails = true,
    onChangePage = () => {},
    pageSizeHandel = () => {}
  } = nullToObject(props);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [pager, setpager] = useState({});

  /**
   * @param  {number} {val} - corrent page number
   */
  const setPage = (page: any) => {
    let pagers: any = pager;
    if (page < 1 || page > pagers.totalPages) {
      return;
    }
    //items is number of page
    //page is current page
    //pageSize is show page size
    pagers = getPager(items, page, pageSize);
    setpager(pagers);
  };

  /**
   * @param  {number} val;
   * user selected pagination value
   */
  const buttonPress = (val: any) => {
    onChangePage(val);
    setPage(val);
  };

  useEffect(() => {
    setPage(initialPage);
    forceUpdate();
  }, [items, initialPage, pageSize]); // total item,page size,initial page any can change will call and reRender

  return (
    <PaginationControl
      pager={pager}
      buttonPress={buttonPress}
      pageSizeHandel={pageSizeHandel}
      allTableDetails={allTableDetails}
      paginationDetails={paginationDetails}
    />
  );
};
export default Pagination;
