import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ImgRocket } from "assets/images";
import { LightBulbIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";
import { convertToLowerCase, nullToObject } from "utils/validation";

export type quickLaunchProps = {
  imgUrl?: any;
  GetData?: any;
  children?: any;
  badgeData?: number;
  iconComponent?: any;
  parentClass?: string;
  enableIcon?: boolean;
  OnclickHandler?: any;
};

const QuickLaunch = (props: quickLaunchProps) => {
  const {
    children,
    GetData = [],
    badgeData = 0,
    parentClass = "",
    enableIcon = false,
    imgUrl = ImgRocket,
    iconComponent = <LightBulbIcon className="w-full h-full" />
  } = nullToObject(props);

  const [quickLaunch, setQuickLaunch] = useState(false);

  const handleQuickLaunch = () => {
    setQuickLaunch(true);
  };

  const handleQuickLaunchClose = () => {
    setQuickLaunch(false);
  };

  return (
    <div
      onClick={handleQuickLaunch}
      className={`${
        quickLaunch ? "active border-blue-200 " : "  border-transparent"
      }  ${parentClass} border quickLaunch fixed bottom-3 right-5 w-
            10 h-10 z-50`}
    >
      <button type="button" className={` w-full h-full relative`}>
        {enableIcon ? (
          { iconComponent }
        ) : (
          <img
            src={imgUrl}
            alt="QuickLaunch"
            className={` transition duration-150 ease-in-out w-full h-full object-contain`}
          />
        )}

        {badgeData > 0 ? (
          <div className=" absolute px-2 -top-3 -right-3 text-center py-0.5 text-sm font-semibold rounded-full bg-blue-200 text-blue-700 ">
            {badgeData}
          </div>
        ) : null}
      </button>

      {quickLaunch && (
        <OutsideClickHandler onOutsideClick={() => handleQuickLaunchClose()}>
          <ul className="absolute bg-white bottom-full w-auto whitespace-nowrap right-0 mr-1 mb-3 border rounded overflow-hidden shadow-lg">
            {GetData.map((item: any, index: any) => {
              const isObject = typeof item === "object";
              const icon = isObject ? item.icons : null;
              const {
                isEnable = true,
                listName = "",
                listType = "Button",
                toUrl = "#",
                OnclickHandler = () => {}
              } = item;
              return isEnable ? (
                <li key={convertToLowerCase(index) + listName} className="list-hover px-2 bg-white hover:shadow">
                  {listType === "Button" ? (
                    <button
                      type="button"
                      onClick={OnclickHandler}
                      className="py-1 text-sm flex justify-between items-center w-full"
                    >
                      <span>{listName}</span>

                      {icon ? (
                        <div className="ml-1 w-3 inline-block c-primary">
                          {React.cloneElement(icon, { className: "w-full h-auto  fill-current" })}
                        </div>
                      ) : null}
                    </button>
                  ) : (
                    toUrl !== "" && (
                      <Link to={toUrl} className="py-1 text-sm flex justify-between items-center w-full">
                        <span>{listName}</span>
                        {icon && (
                          <div className="ml-1 w-3 inline-block c-primary">
                            {React.cloneElement(icon, { className: "w-full h-auto  fill-current" })}
                          </div>
                        )}
                      </Link>
                    )
                  )}
                </li>
              ) : null;
            })}
            {children}
          </ul>
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default QuickLaunch;
