import React from "react";
import CommonTable from "Component/CommonTable";
import ComplainStatusTrackingData from "./ComplainStatusTrackingData";
import { bg_JobSheet } from "utils/ClassContent";

// column view and get status
const ComplainStatusTrackingTable = (props: any = {}) => {
  const {
    complainData: { customers: { mobile = "" } = {} } = {},
    transactions = [],
    columnView = false,
    historyTab = false
  } = props || {};

  /**
   * get modal status
   * @param value
   * @returns
   */
  const getModalStatus1 = (value: boolean) => {
    return value;
  };

  return (
    <div
      className={`w-full flex transition flex-wrap mb-4 ${historyTab && columnView ? "h-0 overflow-hidden transition ease-linear" : "h-auto"} `}
    >
      <CommonTable
        id={"ComplaintStatusHistory"}
        setShow={true}
        parentClass={"w-full"}
        heading={"Complaint Status History"}
        getModalStatus={getModalStatus1}
        customClass={bg_JobSheet}
      >
        <ComplainStatusTrackingData transactions={transactions} phone={mobile} />
      </CommonTable>
    </div>
  );
};

export default ComplainStatusTrackingTable;
