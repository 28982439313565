import { combineReducers } from "redux";
import { emiReducer } from "./emiReducer";
import { mailReducer } from "./mailReducer";
import { salesReducer } from "./salesReducer";
import { loginReducer } from "./loginReducer";
import { ameyoReducer } from "./ameyoReducer";
import { themeReducer } from "./themeReducer";
import { cusListReducer } from "./cusListReducer";
import { comListReducer } from "./comListReducer";
import { storeLoacate } from "./storeLocateReducer";
import { complainReducer } from "./complainReducer";
import { allOrdersReducer } from "./allOrdersReducer";
import { scorecardReducer } from "./scorecardReducer";
import { basicInfoReducer } from "./basicInfoReducer";
import { dashBoardReducer } from "./dashBoardReducer";
import { missedListReducer } from "./missedListReducer";
import { salesReturnReducer } from "./salesReturnReducer";
import { customerInfoReducer } from "./customerInfoReducer";
import { orderTrackingReducer } from "./orderTrackingReducer";
import { serviceCenterReducer } from "./serviceCenterReducer";
import { commonChnagesReducer } from "./commonChnagesReducer";
import { DuplicateInvReducer } from "./duplicateInvImeiReducer";
import { mailCredentialReducer } from "./mailCredentialsReducer";
import { salesTransactionReducer } from "./salesTransactionReducer";
import { serviceCallDetailsReducer } from "./serviceCallDetailsReducer";
import { rolesAndPagesTypesReducer } from "./rolesAndPagesTypesReducer";
import { newCallPagecustomerDetailsReducer } from "./newCallPagecustomerDetailsReducer";

const rootReducer = combineReducers({
  emiReducer: emiReducer, //emi base store
  mailReducer: mailReducer, // mail reducer
  complain: complainReducer, //complain return reducer
  salesReducer: salesReducer, // sales reducer
  themeReducer: themeReducer, //Theme full view
  storeLoacate: storeLoacate, //store locate reducer
  ameyoReducer: ameyoReducer, //ameyo reducer
  loginReducer: loginReducer, //login page handle
  dashBoard: dashBoardReducer, //dashBoard
  cusListReducer: cusListReducer, //GETcUSlIST
  comListReducer: comListReducer, //complaint list
  salesReturn: salesReturnReducer, //sales return reducer
  allOrdersReducer: allOrdersReducer, // all orders reducer
  scorecardReducer: scorecardReducer,
  basicInfoReducer: basicInfoReducer, //gt user basic and update info
  commonReducer: commonChnagesReducer, //IF NEED ANY UN-RELATED(COOOMON FOR ALL) CHANGES STORE HERE
  serviceCenter: serviceCenterReducer, // update service center details
  missedListReducer: missedListReducer,
  customerInfoReducer: customerInfoReducer, // update customer info
  mailCredentialReducer: mailCredentialReducer, // mail credential reducer
  duplicateInvDetailsReducer: DuplicateInvReducer, // get imei base on data
  salesTransactionReducer: salesTransactionReducer, // sales transaction reducer
  orderTrackingDetailsReducer: orderTrackingReducer, // get Order Id base on data
  serviceCallDetailsReducer: serviceCallDetailsReducer, // update customer details
  rolesAndPagesTypesReducer: rolesAndPagesTypesReducer, //USER roles and pages details
  newCallPagecustomerDetails: newCallPagecustomerDetailsReducer //new call page card information details store
});
export default rootReducer;
