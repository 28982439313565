import React, { Suspense } from "react";
import "./index.css";
import App from "./App";
import Store from "./store";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import CustomRouter from "CustomRouter";
import history from "Component/History";
import { nullToObject } from "utils/validation";
import reportWebVitals from "./reportWebVitals";
import { Decrypt, encryptData } from "utils/secureFile";
import { MatomoProvider, createInstance } from "@datapunt/matomo-tracker-react";
import { crmAnalyticsBaseUrl, matomoTrackingId } from "ApiProcess/httpRestServices/apiManage";

const userLoginDetails = Decrypt("userLoginData");
const { data: { empId = "" } = {} } = nullToObject(userLoginDetails);

const instanceObj: any = {
  urlBase: crmAnalyticsBaseUrl,
  siteId: matomoTrackingId
};
if (empId) {
  instanceObj.userId = empId;
  encryptData("matomoIdSet", true);
}
const instance = createInstance(instanceObj);

ReactDOM.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <CustomRouter history={history}>
        <Provider store={Store}>
          <Suspense fallback={"loading"}>
            <App />
          </Suspense>
        </Provider>
      </CustomRouter>
    </MatomoProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
