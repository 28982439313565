import { moment } from "utils/momentConst";
import store from "store";
import { GeneralInfo } from "utils/VariableConstant";
import { Get, Post } from "ApiProcess/httpRestServices";
import { getMailByIdAction } from "store/action/mailAction";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { pincodeFetchActionPass } from "store/action/storeLocateAction";
import { DateTimeFormeterEnd, DateTimeFormeterStart } from "utils/validation";
import { ImeinumBasedTransactionHistory } from "store/action/salessReturnAction";
import { getUserBasicInfoFromEnquiryPopupAction } from "store/action/BasicInfoAction";
import { servicesCenterTokenAction, servicesCenterAction } from "store/action/serviceCenterAction";
import { crmApiBaseUrl, encodeGetParams, serviceCalls } from "ApiProcess/httpRestServices/apiManage";
import {
  updateSupportStatus,
  updateServiceDetails,
  customerServiceEmailSend,
  updateServiceCustomerInfo,
  sendServiceCenterSMSAction,
  createDeliveryChallanAction,
  setCustomerStatusActionCall,
  setCustomerJobsheetActionCall,
  setCustomerServiceCenterActionCall
} from "store/action/customerDetailServiceAction";
/**
 * Get customer service details from server
 * @param obj search details
 * @returns customer service list as response from server
 */
export const getCustomerServiceDetails = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    status = [],
    city = [],
    source = [],
    search = "",
    endDate = "",
    callType = "",
    solution = [],
    startDate = "",
    subCategory = "",
    currentStatus = [],
    createdByState = []
  } = obj || {};

  const newObj = {
    ...(subCategory === GeneralInfo && { source, solution }),
    page,
    size,
    search,
    status,
    callType,
    subCategory,
    currentStatus,
    createdByState,
    branchCity: city,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${serviceCalls.getServices}${queryString}`, "crmToken");
};

/**
 * Get customer service details from server
 * @param obj search details
 * @returns customer service list as response from server
 */
export const uploadCustomerServiceDetails = (obj: any = {}) => {
  const {
    page = 1,
    size = 10,
    status = [],
    search = "",
    endDate = "",
    callType = "",
    startDate = "",
    subCategory = "",
    currentStatus = [],
    createdByState = []
  } = obj || {};

  const newObj = {
    page,
    size,
    search,
    status,
    callType,
    subCategory,
    currentStatus,
    createdByState,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : ""
  };
  const queryString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${serviceCalls.uploadServices}${queryString}`, {}, "crmToken");
};

/**
 * @param  {object} obj;
 * Smart Link passed
 */
export const sendServiceCenterSMSService = async (data: any) => {
  const { mobile = "", customerName = "", addressDetails = "" } = data || {};
  const sendData = {
    mobile: mobile,
    customerName: customerName,
    addressDetails: addressDetails
  };
  return await Post(`${crmApiBaseUrl}${serviceCalls.sendServiceCenterSMS}`, sendData, "crmToken");
};

/**
 * @param  {object} data;
 * Smart Link passed
 */
export const sendShowroomSMSService = async (data: any = {}) => {
  const { mobile = "", customerName = "", addressDetails = "" } = data || {};
  const sendData = {
    mobile: mobile,
    customerName: customerName,
    addressDetails: addressDetails
  };
  return await Post(`${crmApiBaseUrl}${serviceCalls.sendShowroomSMS}`, sendData, "crmToken");
};

/**
 * @param  {number} imeiNumber
 * loader enable
 * dispatch the imei number history action
 */
export const imeiNumHistoryApiCall = (imeiNumber = "") => {
  const paramsObj = { imeiNum: imeiNumber, enquiryFor: "Service" };
  store.dispatch(ImeinumBasedTransactionHistory(paramsObj)); //dispatch the imei based get transActionList
};

export const resetEnquiryMailCall = () => {
  store.dispatch({ type: "SET_SELECTED_MAIL", selectedMailData: {} });
};

/**
 * @param id
 * loader enable
 * get mail based on id
 */
export const getMailByIdCall = (id = "") => {
  store.dispatch({ type: "SET_MAIL_LOADER", mailLoader: true });
  store.dispatch(getMailByIdAction(id));
};

/**
 * Get basic info based on customer Id
 * @param customerId customer id
 */
export const getUserBasicInfoUsingCusIdCall = (customerId = "") => {
  if (customerId) {
    store.dispatch(getUserBasicInfoFromEnquiryPopupAction(customerId));
  } else {
    failToast("customerId is missing");
  }
};

/**
 * Update customer details
 * @param userSearch user search data
 */
export const updateServiceCallDetails = (data = {}) => {
  store.dispatch({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: true });
  store.dispatch(updateServiceDetails(data));
};

/**
 * Get customer details based on user search
 * @param userSearch user search data
 */
export const updateSupportStatusCall = (data = {}) => {
  store.dispatch({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: true });
  store.dispatch(updateSupportStatus(data));
};

/**
 * Get customer details based on user search
 * @param userSearch user search data
 */
export const remarkUpdateSupportStatusCall = () => {
  store.dispatch({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: true });
};

/**
 * Get customer details based on user search
 * @param userSearch user search data
 */
export const updateServiceCustomerInfoCall = (data = {}) => {
  store.dispatch({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: true });
  store.dispatch(updateServiceCustomerInfo(data));
};

export const reloadCusListStausRemove = () => {
  //reloadCusList reload status
  store.dispatch({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: null });
};

/**
 * loader enable
 * get services center list
 */
export const getServiceCenterList = () => {
  store.dispatch({ type: "SERVICE_CENTER_GET_LOADER", servicesCenterLoader: true }); //remove sales return autofill
  store.dispatch(servicesCenterAction()); //get services center list
};

// Get store details , using pincode
export const fetchPincodeValue = (pincodeNumber: any) => {
  store.dispatch(pincodeFetchActionPass(pincodeNumber));
  store.dispatch({ type: "PINCODE_LOADER", loaderPincode: true });
};

/**
 * Get delivery challan serial no
 * @returns dc serial number as response from server
 */
export const getDCSerialNoApiService = (branchCode) => {
  const newObj = {
    branchCode
  };
  const queryString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${serviceCalls.dcSeries}?${queryString}`, "crmToken");
};

// Create delivery challan
export const createDeliveryChallanCall = (newObj: object = {}) => {
  store.dispatch({ type: "SET_DELIVERY_CHALLAN_LOADER", deliveryChallanLoader: true });
  store.dispatch(createDeliveryChallanAction(newObj));
};
export const updateIsDeliveryChallan = (newObj: object = {}) => {
  store.dispatch({ type: "IS_DELIVERY_CHALLAN", isDeliveryChallanDone: false });
};

/**
 * loader enable
 * get services center list
 */
export const getServiceCenterToken = () => {
  store.dispatch({ type: "SERVICE_CENTER_TOKEN_GET_LOADER", servicesCenterTokenLoader: true }); //remove sales return autofill
  store.dispatch(servicesCenterTokenAction()); //get services center list
};

/**
 * Reset update service success code
 */
export const resetUpdateServiceSuccesCode = () => {
  store.dispatch({ type: "SET_UPDATE_SERVICE_SUCCESS_CODE", successCode: false }); //sucess code update
};

/**
 * Reset update enquiry basic info success code
 */
export const resetUpdateEnquiryBasicInfoSuccesCode = () => {
  store.dispatch({ type: "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS", successCode: false }); //sucess code update
};

/**
 * Update the customer details
 */
export const setCustomerStatusApiCall = (data: any = {}) => {
  store.dispatch(setCustomerStatusActionCall(data));
};

/**
 * Send sms
 */
export const sendServiceCenterSMSCall = (data: any = {}) => {
  store.dispatch(sendServiceCenterSMSAction(data));
  store.dispatch({ type: "SMS_SERVICE_LOADER", smsServiceLoader: true });
};

/**
 * Update the customer service center details
 */
export const setCustomerServiceCenterApiCall = (data: any = {}) => {
  store.dispatch(setCustomerServiceCenterActionCall(data));
};

/**
 * Update the customer jobsheet details
 */
export const setCustomerJobsheetApiCall = (data: any = {}) => {
  store.dispatch(setCustomerJobsheetActionCall(data));
};

/**
 * Calculate the age of device
 * @param start current date
 * @returns difference of current date and start date
 */
export const ageOfDeviceCalculation = (start: any = "") => {
  const current = moment().startOf("day");
  if (start) {
    try {
      return moment.duration(current.diff(start.toString())).asDays();
    } catch {
      return "";
    }
  } else {
    return "";
  }
};

/**
 * Send attachemts on mail
 */
export const sendEmailAttachments = (data: any = {}) => {
  store.dispatch({ type: "CUSTOMER_SERVICE_SEND_EMAIL_SUCCESS", customerServiceEmailSuccess: false });
  store.dispatch({ type: "CUSTOMER_SERVICE_EMAIL_SEND_LOADER", customerServiceEmailLoader: true }); //send email loader
  store.dispatch(customerServiceEmailSend(data));
};

export const mailReportFilter = [
  "enquiryFor",
  "subCategory",
  "imei",
  "tat",
  "imeiCustomerName",
  "imeiMobileNo",
  "callType",
  "itemCode",
  "branchName",
  "branchCode",
  "branchState",
  "branchContactNo",
  "product",
  "brand",
  "model",
  "productValue",
  "billNo",
  "billDate",
  "problemCategory",
  "problem",
  "problemDescription",
  "deviceAge",
  "typeOfService",
  "status",
  "currentStatus",
  "remarks",
  "documents",
  "deliveryChallan",
  "lastTransaction",
  "createdBy",
  "createdDate",
  "createdBranch",
  "updatedBy",
  "updatedDate",
  "updatedBranch",
  "solution"
];
