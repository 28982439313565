import store from "store";
import { Get } from "ApiProcess/httpRestServices";
import { ImeinumBasedTransactionHistory } from "store/action/salessReturnAction";
import { crmApiBaseUrl, cusListPageApi } from "ApiProcess/httpRestServices/apiManage";

export const defaultCallState = {
  page: 1,
  size: 10,
  search: "",
  status: "",
  endDate: null,
  enquiryFor: "",
  callType: null,
  startDate: null,
  subCategory: "",
  currentStatus: ""
};

/**
 * @param  {number} imeiNumber
 * loader enable
 * dispatch the imei number history action
 */
export const imeiNumHistoryApiCall = (imeiNumber = "") => {
  store.dispatch(ImeinumBasedTransactionHistory(imeiNumber)); //dispatch the imei based get transActionList
  store.dispatch({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: true });
};

/**
 * get cus list a/c to ticket Id
 */
export const getCuslistByTicketIdApiServices = (ticketId: any = "") => {
  return Get(`${crmApiBaseUrl}${cusListPageApi.cusList}/ticket/${ticketId}`, "crmToken");
};

export const reloadCusListStausRemove = () => {
  //reloadCusList reload status
  store.dispatch({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: null });
};
