import store from "store";
import { timeConverting } from "utils/validation";
import { Get, Post } from "ApiProcess/httpRestServices";
import {
  apxBaseUrlPath,
  complain,
  crmApiBaseUrl,
  encodeGetParams,
  salesReturn
} from "../../ApiProcess/httpRestServices/apiManage";

type imeiType = {
  data?: any;
  imei?: string;
};

/**
 * @param  {object} obj;
 * update complain data
 */
export const updatecomplainServices = (complainData: imeiType) => {
  const storeData: any = store.getState();
  const { commonReducer = {} } = storeData;
  const { justDialLeadDetails = {} } = commonReducer;
  const { basicInfoReducer: { basicInfoUserinfoData: { customerId = "" } = {} } = {} } = storeData;
  const { data = {} } = complainData;
  const {
    imeiNumber = "",
    billNo = "",
    mailId = "",
    callType = true,
    billDate = "",
    mobileNumber = "",
    branchName = "",
    cusName = "",
    itemName = "",
    documentDate = "",
    subCategory = "",
    complainType = "",
    party = [],
    itemBrandName = "",
    itemGrupName = "",
    invoiceAmount = "",
    complaintAbout = "",
    complaintSource = "",
    complainRecieveDate = "",
    complaintantExpectation = "",
    complaintantAdvocateDetails = {},
    documentArr = []
  } = data;

  let submitData = {
    isQuery: "t",
    cusId: customerId,
    enquiryFor: "Complaints",
    imei: imeiNumber,
    billNo: billNo,
    callType: callType,
    leadDetails: justDialLeadDetails, //justDial lead create
    billDate: billDate && timeConverting(billDate),
    mobileNo: mobileNumber,
    branchName: branchName,
    customerName: cusName,
    productName: itemName,
    documentDate: documentDate,
    subCategory: subCategory,
    complainType: complainType,
    party: party,
    itemBrandName: itemBrandName,
    itemModelName: itemGrupName,
    invoiceAmount: invoiceAmount,
    complainAbout: complaintAbout,
    complainSource: complaintSource,
    complainRecieveDate: complainRecieveDate,
    complaintantExpectation: complaintantExpectation,
    complaintantAdvocateDetails: complaintantAdvocateDetails || {}
  };

  if (mailId) {
    submitData = { ...submitData, ...{ mailId: mailId } };
  }

  const formData = new FormData();
  const imageArray = documentArr;

  if (imageArray.length > 0) {
    for (const file of imageArray) {
      formData.append("document", file.file); // appending every file to formdata
    }
  }
  // Object.keys(submitData).forEach((itm:any="") => {
  //   if(itm === "complaintantAdvocateDetails" || itm ==="leadDetails") {
  //     Object.keys(submitData[itm]).forEach((item:any="") => {
  //       formData.append(`${itm}[${item}]`, submitData[itm][item]);
  //     });
  //   } else if(itm === "party") {
  //     submitData[itm].forEach((item:any="") => {
  //       formData.append(`party[]`, JSON.stringify(item));
  //     });
  //   } else {
  //     formData.append(itm, submitData[itm]);
  //   }
  // });

  const queryString = encodeGetParams(submitData);
  return Post(`${crmApiBaseUrl}${complain.complain}${queryString}`, formData, "crmToken");
};

/**
 * @param  {object} obj;
 * imei number passed
 */
export const getImeiNumberBasedServices = (obj: imeiType) => {
  const { imei = "" } = obj;
  return Get(`${apxBaseUrlPath}${salesReturn.imeiDetail}${imei}`, "apxToken");
};
