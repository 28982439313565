import axios from "axios";
import store from "store";
import { _get } from "utils/lodashConst";
import History from "Component/History";
import { convertToLowerCase } from "utils/validation";
import { Decrypt, encryptData } from "utils/secureFile";
import { envPath } from "ApiProcess/httpRestServices/apiManage";
import { typeSenseAccessTokenservices } from "utils/services/emiService";
import { getservicesCenterTokenServices } from "utils/services/serviceCenterServices";
import {
  xApiKey,
  apxToken,
  crmToken,
  extToken,
  oc3Token,
  orgToken,
  pimToken,
  Noheaders,
  offerToken,
  pimApiHeader,
  infoBibToken,
  feedbackToken,
  pinePgTokenSet,
  orgPincodeToken,
  bitlyHeaderToken,
  typesenceProduct,
  orderHistoryToken,
  serviceCenterToken,
  epmloyeDetailToken,
  crmSalesHeaderToken,
  relatedProductToken,
  outDatedProductPost,
  getDeliveryTypeToken,
  outDatedProductSearch,
  crmServiceCenterToken,
  poorvikaProductDetails,
  centralInventoryHeader,
  centralInventorySalesHeader,
  getStockAvaliablityDetailsToken
} from "./httpToken";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { dologinService } from "Pages/PublicComponent/Login/commonLoginPage";

const loginIssue = convertToLowerCase("you are logged in another device.");
/**
 * catch block IF condtion Helps to when JWT token Expire  try to generate new JWT
 * success return Call GET
 * Api fail clr local store and push to login page
 *
 * @param  {string} {url} - Api call url
 * @param  {boolean} {attachToken} - Request Need Header or not ,handle From  Action Created page
 * @return  {object} {data} - when Api success return To Action created page
 * @return  {object} {error} - when Api Fail return To Action created page
 **/
export const Get = async (url = "", attachToken = "NoToken", header = {}): Promise<any> => {
  let headers = {};
  switch (convertToLowerCase(attachToken)) {
    case convertToLowerCase("omsHistoryToken"):
      //order management history
      headers = { ...orderHistoryToken() };
      break;
    case convertToLowerCase("centralInventory"):
      //order management history
      headers = { ...centralInventoryHeader() };
      break;
    case convertToLowerCase("xApiKey"):
      //order management history
      headers = { ...xApiKey() };
      break;
    case convertToLowerCase("centralInventorySalesHeader"):
      //order management history
      headers = { ...centralInventorySalesHeader() };
      break;
    case convertToLowerCase("apxOrgToken"):
      //order management history
      headers = { ...orgToken() };
      break;
    case convertToLowerCase("pimApiToken"):
      //order management history
      headers = { ...pimApiHeader() };
      break;
    case convertToLowerCase("areaOrgToken"):
      //pinePg token added
      headers = { ...orgPincodeToken() };
      break;
    case convertToLowerCase("NoToken"):
      //no Header
      headers = { ...headers };
      break;
    case convertToLowerCase("pimToken"):
      //PIM Header
      headers = { ...pimToken() };
      break;
    case convertToLowerCase("offerToken"):
      //PIM Header
      headers = { ...offerToken(), ...header };
      break;
    case convertToLowerCase("poorvikaProductDetails"):
      //no Header
      headers = { ...poorvikaProductDetails() };
      break;
    case convertToLowerCase("crmToken"):
      //crm application token
      headers = { ...crmToken() };
      break;
    case convertToLowerCase("crmServiceCenter"):
      //crm application token
      headers = { ...crmServiceCenterToken() };
      break;
    case convertToLowerCase("apxToken"):
      //crm application token
      headers = { ...apxToken() };
      break;
    case convertToLowerCase("extToken"):
      headers = { ...extToken() };
      break;
    case convertToLowerCase("gotestoc3api"):
      headers = await oc3Token();
      break;
    case convertToLowerCase("serviceCenterToken"):
      //service center token
      headers = { ...serviceCenterToken() };
      break;
    case convertToLowerCase("empoyeeDetailToken"):
      //oc3 get customer details
      headers = { ...epmloyeDetailToken() };
      break;
    case convertToLowerCase("typesenseDetailsToken"):
      //Typesense token
      headers = { ...typesenceProduct() };
      break;
    case convertToLowerCase("getDeliveryTypeToken"):
      //get delivery type token
      headers = { ...getDeliveryTypeToken() };
      break;
    case convertToLowerCase("getStockAvailablity"):
      //get delivery type token
      headers = { ...getStockAvaliablityDetailsToken() };
      break;
    case convertToLowerCase("feedbackToken"):
      //get feedback type token
      headers = { ...feedbackToken() };
      break;
    default:
      break;
  }

  try {
    return await axios.get(url, { headers });
  } catch (error: any) {
    const { status = 0 } = _get(error, "response", {});

    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("Typesense")) {
      //typesense token expire call token servise
      const tokenResponse: any = typeSenseAccessTokenservices();
      const { data: { success = 0, data: { access_token: accessToken = "" } = {} } = {} } = tokenResponse || {};
      if (success === 1) {
        encryptData("typesenseToken", accessToken);
        return Get(url, "Typesense");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }

    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("crmToken")) {
      //our crm application token expire
      const getLoginDetails = Decrypt("userLoginInputDetails");
      const loginData: any = await dologinService(getLoginDetails);
      const { data: { status: statusCode = 0 } = {}, data = {} } = loginData || {};

      if (statusCode === 200) {
        encryptData("userLoginData", data);
        //for developPurpose store loging user details
        if (envPath === "dev") {
          localStorage.setItem("userLoginData", JSON.stringify(data));
        }
        return Get(url, "crmToken");
      } else if (statusCode === 400 && convertToLowerCase(data?.message) === loginIssue) {
        // encryptData("userLoginData", data);
        // if (envPath === "dev") {
        //   localStorage.setItem("userLoginData", JSON.stringify(data));
        // }
        // return Delete(url, "crmToken");
        failToast(data?.message);
        sessionStorage.clear();
        History.push("/");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }

    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("serviceCenterToken")) {
      //our crm application token expire
      const serviceTokenGet: any = await getservicesCenterTokenServices();
      const { status: statusCode = 0, data: { data: { access_token: serviceCenterTokenData = "" } = {} } = {} } =
        serviceTokenGet || {};
      if (statusCode === 200) {
        encryptData("serviceCenterToken", serviceCenterTokenData);
        //for developPurpose store token value
        if (envPath === "dev") {
          localStorage.setItem("serviceCenterToken", serviceCenterTokenData.toString());
        }
        return Get(url, "serviceCenterToken");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }
    return _get(error, "response", {});
  }
};

/**
 * @param  {string} {url} - Api get call url
 * @param  {object} {params} - passed params is dynamically
 **/
export const Post = async (url = "", params: any = {}, attachToken = "NoToken", header = {}): Promise<any> => {
  let headers = {};
  if (convertToLowerCase(attachToken) === convertToLowerCase("crmSalesToken"))
    headers = { ...crmSalesHeaderToken(), ...header };

  if (convertToLowerCase(attachToken) === convertToLowerCase("NoToken"))
    //no Header
    headers = { ...headers, ...header };

  if (convertToLowerCase(attachToken) === convertToLowerCase("bitlyToken"))
    //no Header
    headers = { ...bitlyHeaderToken() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("crmToken"))
    //crm application token
    headers = { ...crmToken() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("offerToken")) {
    //Offer token
    headers = { ...offerToken(), ...header };
  }

  if (convertToLowerCase(attachToken) === convertToLowerCase("pinePgToken")) headers = { ...pinePgTokenSet() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("infoBipToken")) headers = { ...infoBibToken() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("typesenseDetailsToken"))
    //Typesense token
    headers = { ...typesenceProduct() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("outDatedProduct"))
    //outDatedProduct token
    headers = { ...outDatedProductSearch() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("outDatedProductPost"))
    //outDatedProduct token
    headers = { ...outDatedProductPost() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("relatedProductToken")) {
    //relates product details
    headers = { ...relatedProductToken() };
  }

  try {
    return await axios.post(url, params, {
      headers,
      onUploadProgress: (progressEvent: any = {}) => {
        const { loaded = 0, total = 0 } = progressEvent || {};
        const percentage = Math.floor((loaded * 100) / total);
        if (percentage < 100) {
          if (percentage % 5 === 0) {
            //percentage upload
            store.dispatch({
              type: "FILE_UPLOAD_PERCENTAGE_GET",
              uploadPercentege: percentage
            });
          }
        }
      }
    });
  } catch (error: any) {
    const { status = 0 } = _get(error, "response", {});

    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("crmToken")) {
      //our crm application token expire
      const getLoginDetails = Decrypt("userLoginInputDetails");
      const loginData: any = await dologinService(getLoginDetails);
      const { data: { status: statusCode = 0 } = {}, data = {} } = loginData || {};
      if (statusCode === 200) {
        encryptData("userLoginData", data);
        //for developPurpose store loging user details
        if (envPath === "dev") {
          localStorage.setItem("userLoginData", JSON.stringify(data));
        }
        return Post(url, params, "crmToken");
      } else if (statusCode === 400 && convertToLowerCase(data?.message) === loginIssue) {
        // encryptData("userLoginData", data);
        // if (envPath === "dev") {
        //   localStorage.setItem("userLoginData", JSON.stringify(data));
        // }
        // return Delete(url, "crmToken");
        failToast(data?.message);
        sessionStorage.clear();
        History.push("/");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }
    return _get(error, "response", {});
  }
};

/**
 * @param  {string} {url} - Api get call url
 * @param  {object} {params} - passed params is dynamically
 **/
export const Put = async (url = "", params: any = {}, attachToken = "NoToken"): Promise<any> => {
  let headers = {};

  if (convertToLowerCase(attachToken) === convertToLowerCase("NoToken"))
    //no Header
    headers = { ...headers };

  if (convertToLowerCase(attachToken) === convertToLowerCase("crmToken"))
    //crm application token
    headers = { ...crmToken() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("pinePgToken")) headers = { ...pinePgTokenSet() };

  if (convertToLowerCase(attachToken)) headers = { ...headers };
  try {
    return await axios.put(url, params, { headers });
  } catch (error: any) {
    const { status = 0 } = _get(error, "response", {});

    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("crmToken")) {
      //our crm application token expire
      const getLoginDetails = Decrypt("userLoginInputDetails");
      const loginData: any = await dologinService(getLoginDetails);
      const { data: { status: statusCode = 0 } = {}, data = {} } = loginData || {};
      if (statusCode === 200) {
        encryptData("userLoginData", data);
        //for developPurpose store loging user details
        if (envPath === "dev") {
          localStorage.setItem("userLoginData", JSON.stringify(data));
        }
        return Put(url, params, "crmToken");
      } else if (statusCode === 400 && convertToLowerCase(data?.message) === loginIssue) {
        // encryptData("userLoginData", data);
        // if (envPath === "dev") {
        //   localStorage.setItem("userLoginData", JSON.stringify(data));
        // }
        // return Delete(url, "crmToken");
        failToast(data?.message);
        sessionStorage.clear();
        History.push("/");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }
    return _get(error, "response", {});
  }
};

/**
 * @param  {string} url
 * @param  {boolean} attachToken=""
 * delete call
 */
export const Delete = async (url = "", attachToken = ""): Promise<any> => {
  let headers = {};

  if (convertToLowerCase(attachToken) === convertToLowerCase("crmToken"))
    //crm application token
    headers = { ...crmToken() };

  if (convertToLowerCase(attachToken) === convertToLowerCase("outDatedProductPost"))
    //outDatedProduct token
    headers = { ...outDatedProductPost() };

  try {
    return await axios.delete(url, { headers });
  } catch (error: any) {
    const { status = 0 } = _get(error, "response", {});

    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("crmToken")) {
      //our crm application token expire
      const getLoginDetails = Decrypt("userLoginInputDetails");
      const loginData: any = await dologinService(getLoginDetails);
      const { data: { status: statusCode = 0 } = {}, data = {} } = loginData || {};
      if (statusCode === 200) {
        encryptData("userLoginData", data);
        //for developPurpose store loging user details
        if (envPath === "dev") {
          localStorage.setItem("userLoginData", JSON.stringify(data));
        }
        return Delete(url, "crmToken");
      } else if (statusCode === 400 && convertToLowerCase(data?.message) === loginIssue) {
        // encryptData("userLoginData", data);
        // if (envPath === "dev") {
        //   localStorage.setItem("userLoginData", JSON.stringify(data));
        // }
        // return Delete(url, "crmToken");
        failToast(data?.message);
        sessionStorage.clear();
        History.push("/");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }
    return _get(error, "response", {});
  }
};

/**
 * @param  {string} {url} - Api get call url
 * @param  {object} {params} - passed params is dynamically
 **/
export const Patch = async (url = "", params: any = {}, attachToken = "NoToken"): Promise<any> => {
  let headers = {};

  if (convertToLowerCase(attachToken) === convertToLowerCase("NoToken"))
    //no Header
    headers = { ...headers };

  if (convertToLowerCase(attachToken) === convertToLowerCase("crmToken"))
    //crm application token
    headers = { ...crmToken(), "Access-Control-Allow-Credentials": true };

  try {
    return await axios.patch(url, params, { headers });
  } catch (error: any) {
    const { status = 0 } = _get(error, "response", {});
    if (status === 401 && convertToLowerCase(attachToken) === convertToLowerCase("crmToken")) {
      //our crm application token expire
      const getLoginDetails = Decrypt("userLoginInputDetails");
      const loginData: any = await dologinService(getLoginDetails);
      const { data: { status: statusCode = 0 } = {}, data = {} } = loginData || {};
      if (statusCode === 200) {
        encryptData("userLoginData", data);
        //for developPurpose store loging user details
        if (envPath === "dev") {
          localStorage.setItem("userLoginData", JSON.stringify(data));
        }
        return Post(url, params, "crmToken");
      } else if (statusCode === 400 && convertToLowerCase(data?.message) === loginIssue) {
        // encryptData("userLoginData", data);
        // if (envPath === "dev") {
        //   localStorage.setItem("userLoginData", JSON.stringify(data));
        // }
        // return Delete(url, "crmToken");
        failToast(data?.message);
        sessionStorage.clear();
        History.push("/");
      } else {
        sessionStorage.clear();
        History.push("/");
      }
    }
    return _get(error, "response", {});
  }
};

/**
 * @param url this methos help to call api without header
 */
export const withoutTokenGet = async (url = "", header = {}) => {
  try {
    return await axios.get(url, { headers: Object.keys(header)?.length > 0 ? { ...header } : { ...Noheaders } });
  } catch (error: any) {
    return _get(error, "response", {});
  }
};

/**
 * @param url this methos help to call api without header
 */
export const withoutTokenPost = async (url = "", params: any = {}): Promise<any> => {
  try {
    return await axios.post(url, params, { headers: Noheaders });
  } catch (error: any) {
    return _get(error, "response", {});
  }
};

/**
 * @param url this methos help to call api without header
 */
export const withoutTokenPut = async (url = "", params: any = {}): Promise<any> => {
  try {
    return await axios.put(url, params);
  } catch (error: any) {
    return _get(error, "response", {});
  }
};
