import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import ReadMore from "Component/ReadMore";
import CallTypeBody from "Component/CallTypeBody/CallTypeBody";
import { TableClass, thClass, tdClass } from "utils/ClassContent";
import { GeneralInfo, ServiceSupport } from "utils/VariableConstant";
import { enquiryStatusTrackingTableProps } from "../ServiceCallsTypes";
import ServiceCallTransHistoryView from "./ServiceCallTransHistoryView";
import { convertToLowerCase, dateTimeDisplay, getDateConvert } from "utils/validation";

const ServiceCallTransactionHistory = (props: enquiryStatusTrackingTableProps) => {
  const { customerData = {}, getModalStatus = false, columnView = false } = props || {};

  const generalInfoSubCat = GeneralInfo;
  const serviceSupportSubCat = ServiceSupport;

  const [subCategory, setSubCategory] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [typeOfService, setTypeOfService] = useState("");
  const [seletectElment, setseletectElment] = useState({});
  const [oldStatusView, setOldStatusView] = useState(false);

  //view eyeIcon click
  const handleViewOldStatus = (ele = {}) => {
    setseletectElment(ele);
    setOldStatusView(true);
  };

  useEffect(() => {
    setSubCategory(_get(customerData, "subCategory", ""));
    setTransactions(_get(customerData, "transactions", []));
    setTypeOfService(_get(customerData, "typeOfService", ""));
  }, [customerData]);

  return (
    <Fragment>
      <div
        className="block w-full overflow-x-auto relative "
        style={{ minHeight: "250px", height: getModalStatus ? "250px" : "100%" }}
      >
        <table className={TableClass}>
          <thead>
            <tr>
              <th className={thClass}>Date</th>
              {!(subCategory === "Duplicate invoice" || subCategory === generalInfoSubCat) && (
                <th className={thClass}>Type of Service</th>
              )}
              <th className={thClass}>Status</th>
              <th className={thClass}>Sub Status</th>
              {subCategory === serviceSupportSubCat && <th className={thClass}>FollowUp Date</th>}
              <th className={thClass}>Remarks</th>
              <th className={thClass}>Updated by</th>
            </tr>
          </thead>
          {transactions.map((ele: any, i: number) => {
            const {
              status = "",
              remarks = "",
              currentStatus = "",
              followUpDate = "",
              actionBy: { date = "", name = "", branch = "" } = {}
            } = ele;
            return (
              <tbody key={convertToLowerCase(i + "transactions-fsc")}>
                <tr
                  tabIndex={1}
                  data-jest-id={"#jestHandleViewOldStatus"}
                  onClick={() => handleViewOldStatus(ele)}
                  className="hover:bg-gray-600 hover:bg-opacity-10 cursor-pointer"
                >
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    <div className="flex items-center justify-between">
                      <span className="w-32">{dateTimeDisplay(date)}</span>

                      <span className="mx-1">
                        <CallTypeBody callType={_get(ele, "callType", true)} />
                      </span>
                    </div>
                  </td>
                  {!(subCategory === "Duplicate invoice" || subCategory === generalInfoSubCat) && (
                    <td style={{ minWidth: "160px" }} className={tdClass}>
                      {typeOfService}
                    </td>
                  )}
                  <td style={{ minWidth: "100px" }} className={tdClass}>
                    {status}
                  </td>
                  <td style={{ minWidth: "250px" }} className={tdClass}>
                    {currentStatus}
                  </td>

                  {subCategory === serviceSupportSubCat && (
                    <td style={{ minWidth: "200px" }} className={tdClass}>
                      {followUpDate ? getDateConvert(followUpDate) : followUpDate}
                    </td>
                  )}
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    <ReadMore content={remarks || ""} />
                  </td>
                  <td style={{ minWidth: "200px" }} className={tdClass}>
                    {name}-{branch}
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
      {oldStatusView && (
        <ServiceCallTransHistoryView
          columnView={columnView}
          getData={seletectElment}
          handleClosePopup={() => setOldStatusView(false)}
        />
      )}
    </Fragment>
  );
};

export default ServiceCallTransactionHistory;
