import { toLocaleNumber } from "./OfferCommon";
import React from "react";

interface ProductOfferProp {
  paymentOffers: [];
  setPaymentOffers: any;
  product: any;
}

const PaymentOffers = (props: ProductOfferProp) => {
  const { paymentOffers, setPaymentOffers } = props;

  const handleOfferAction = (offerId: string, value: string, offerType: string) => {
    const checked_offer: any = paymentOffers.map((item: any) => {
      if (item.offerId === offerId) {
        item[value] = !item[value];

        if (value === "showTc") {
          item.showBan = false;
        } else if (value === "showBan") {
          item.showTc = false;
        }
      } else if (value === "checked") item[value] = false;
      return item;
    });

    setPaymentOffers(checked_offer);
  };

  return (
    <>
      {paymentOffers.map((offer: any) => {
        return (
          <div className="flex flex-col" key={offer?.offerId}>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between items-center flex-wrap align-middle p-2 font-bold text-gray-800">
                <div className="flex flex-row items-center align-middle justify-start">
                  <span className="p-1">
                    <input
                      id={"payment_offer"}
                      disabled={offer.offerSelection === "mandatory"}
                      onChange={() => handleOfferAction(offer.offerId, "checked", "payment")}
                      checked={offer.checked}
                      type="radio"
                    />
                  </span>
                  <span className="p-1"> {`${offer?.offerName} (${offer?.sponsorName})`}</span>

                  <span
                    className="text-sm text-blue-500 underline ml-1 cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showTc", "payment")}
                  >{`T&C`}</span>
                  <span
                    className="text-sm text-blue-500 underline ml-1 cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showBan", "payment")}
                  >{`Details`}</span>
                </div>

                <div className="flex align-middle text-green-500">
                  {offer.offerType !== "Offer Text" && offer.offerType !== "Standard EMI" && (
                    <span className={!offer.checked ? "line-through text-gray-500" : ""}>
                      {toLocaleNumber(offer?.maxBenefites)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              {offer.showTc && <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }} />}
              {offer.showBan && (
                <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }} />
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PaymentOffers;
