import React from "react";
import TableHead from "Component/CommonTable/TableHead";

const EvaluatorReportsListHeader = (props: any = {}) => {
  const { handleSort = () => {} } = props || {};
  return (
    <thead>
      <tr className="border">
        <TableHead
          enableSort={true}
          isData={false}
          text={"Evaluator"}
          handleSort={(state: any) => handleSort(state, "name")}
        />
        <TableHead
          enableSort={true}
          isData={false}
          text={"Score %"}
          handleSort={(state: any) => handleSort(state, "avgScore")}
        />
        <TableHead
          enableSort={true}
          isData={false}
          text={"Audit Count"}
          handleSort={(state: any) => handleSort(state, "scoreCount")}
        />
        <TableHead
          enableSort={true}
          isData={false}
          text={"Fatal Count"}
          handleSort={(state: any) => handleSort(state, "failAllCount")}
        />
        <TableHead enableSort={true} isData={false} text={"Commments"} />
      </tr>
    </thead>
  );
};

export default EvaluatorReportsListHeader;
