import React, { Component } from "react";
import "react-dates/initialize";
import { _get } from "utils/lodashConst";
import { moment } from "utils/momentConst";
import "react-dates/lib/css/_datepicker.css";
import { convertToLowerCase } from "utils/validation";
import { DateRangePicker, isInclusivelyAfterDay, isInclusivelyBeforeDay } from "react-dates";

class CommonStartToEndDatePic extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      focused: false,
      error: false,
      showClearDates: false
    };
  }

  /**
   * @param  {boolean} {focusedInput} - corrent page number
   **/
  focusChange = (focusedInput: any = null) => {
    const { onCalendarHandle = false, onOpenCalendar = () => {} } = this.props || {};

    this.setState({ focusedInput }, () => {
      if (this.state.focusedInput === null) {
        onCalendarHandle && onOpenCalendar(false);
        this.setState({ error: true });
      } else {
        onCalendarHandle && onOpenCalendar(true);
        this.setState({ error: false });
      }
    });
  };

  renderMonthElement = ({ month = "", onMonthSelect = () => {}, onYearSelect = () => {} }: any = {}) => {
    let i;
    const years = [];
    for (i = moment().year(); i >= moment().year() - 100; i--) {
      years.push(
        <option value={i} key={`year-${i}`}>
          {i}
        </option>
      );
    }
    return (
      <div className="MultiDate">
        <select
          className="month"
          value={month && month.month()}
          data-jest-id={"jestOnMonthSelect"}
          onChange={(e) => onMonthSelect(month, _get(e, "target.value", ""))}
        >
          {moment.months().map((label, value) => (
            <option value={value} key={convertToLowerCase(value + "months")}>
              {label.substring(0, 3)}
            </option>
          ))}
        </select>

        <select
          className="year"
          value={month && month.year()}
          data-jest-id={"jestOnYearSelect"}
          onChange={(e) => onYearSelect(month, _get(e, "target.value", ""))}
        >
          {years}
        </select>
      </div>
    );
  };

  render() {
    const { focusedInput = "", showClearDates = false }: any = this.state;
    const { onChange, startDate = null, name = "", endDate = null, dashBordMonth = false }: any = this.props;

    return (
      <DateRangePicker
        readOnly={true}
        minimumNights={0}
        numberOfMonths={1}
        focusedInput={focusedInput}
        endDateId="user_end_date_id"
        showClearDates={showClearDates}
        startDateId="user_start_date_id"
        onFocusChange={this.focusChange}
        endDate={endDate ? endDate : null}
        displayFormat={() => "MMM DD,YYYY"}
        endDatePlaceholderText={"End Date"}
        startDatePlaceholderText={"Start Date"}
        startDate={startDate ? startDate : null}
        renderMonthElement={this.renderMonthElement}
        onDatesChange={(fromDate) => onChange({ ...fromDate, name: name })}
        isOutsideRange={(day) => {
          if (dashBordMonth) {
            if (startDate) {
              return (
                !isInclusivelyAfterDay(day, startDate) ||
                isInclusivelyAfterDay(day, moment(startDate).add(90, "days")) ||
                !isInclusivelyBeforeDay(day, moment())
              );
            } else if (endDate) {
              return (
                !isInclusivelyBeforeDay(day, endDate) ||
                isInclusivelyBeforeDay(day, moment(endDate).subtract(90, "days")) ||
                !isInclusivelyBeforeDay(day, moment())
              );
            }
          }
          return !isInclusivelyBeforeDay(day, moment());
        }}
      />
    );
  }
}
export default CommonStartToEndDatePic;
