import * as dashBoardTypes from "../actionTypes/dashBoardTypes";

/**
 *get courier details
 */
export const getCourierTrackingAction = (data = {}) => {
  return {
    type: dashBoardTypes.GET_COURIER_TRACKING_INFO,
    data
  };
};

/**
 *get order details based on order id
 */
export const getPurchesOrderIdDetailsAction = (data = {}) => {
  return {
    type: dashBoardTypes.GET_PURCHASE_ORDER_ID_DETAILS,
    data
  };
};
