import React, { useEffect, useState } from "react";
import StockTable from "../../StockTable";
import ActionPopup from "Component/ActionPopup";
import { _get, _isEmpty } from "utils/lodashConst";
import { StoreGetValue } from "store/StoreGetValue";
import ModalFixed3 from "Component/Modal/ModalFixed3";
import ModalFixed2 from "Component/Modal/ModalFixed2";
import { LocationMarkerIcon } from "@heroicons/react/outline";
import { convertToLowerCase, nullToObject } from "utils/validation";
import EmiBankDetailsPop from "Pages/NewCall/EnquiryFor/SalesEnquiry/SalesEmi/EmiBankDetailsPop";
import EmiMoreDetails from "Pages/NewCall/EnquiryFor/SalesEnquiry/SalesEmi/EmiBankDetailsPop/EmiMoreDetails";
import {
  fetchOnlineEmiDetails,
  fetchSalesOfflineEmiDetails,
  offerRuleEngineGetNbfcOffer,
  onlineOfferRuleEngineGetNbfcOffer
} from "utils/services/emiService";

const ProductEmiAndStock = (props: any = {}) => {
  const {
    actionPopup = false,
    handleEmiPopup = () => {},
    productDetails = {},
    showroomPrice = "",
    sku = ""
  } = nullToObject(props);
  const { prices = {}, mrp = {}, channel_shapes = [], item_code = "" } = nullToObject(productDetails);
  const pine_product_id =
    channel_shapes?.find((element: any) => element.code === "pinepg-product-id" && element.value) || {};
  const { price: max = -1 } = nullToObject(mrp);
  const globalValue = StoreGetValue(); //store value
  const { salesReducer = {} } = globalValue;
  const { stockDetails: stock_data = [] } = salesReducer;
  const { sp = -1 } = nullToObject(prices);
  const stock = 0;

  const [openModal, setOpenModal] = useState(false);
  const [nbfcMinAmout, setnbfcMinAmout] = useState<any>(0);
  const [nbfcTotalData, setNbfcTotalData] = useState<any>([]);
  const [offlineEmiPopup, setOfflineEmiPopup] = useState(false);
  const [offlineEmiTab, setOfflineEmiTab] = useState("tab1");
  const [OnlineEmiTab, setOnlineEmiTab] = useState("tab1");
  const [moreDetailsData, setMoreDetailsData] = useState<any>({});
  const [onlineEmiDetails, setOnlineEmiDetails] = useState<any>({});
  const [offlineEmiDetails, setOfflineEmiDetails] = useState<any>({});
  const [onlinenbfcMinAmout, setOnlineNbfcMinAmout] = useState<any>(0);
  const [onlineNbfcTotalData, setOnlineNbfcTotalData] = useState<any>([]);
  const [showEmiMoreDetailsPopup, setEmiShowMoreDetailsPopup] = useState(false);

  const stockSplice = stock_data;

  const showEmiMoreDetailsClick = (value: any) => {
    setMoreDetailsData(value);
    setEmiShowMoreDetailsPopup(true);
  };

  const { lowestEMIAmount = "", lowestEMIOfferedBy = "" } = onlineEmiDetails;

  const getOnlineEmpiNbfcDetails = async () => {
    const online_nbfcResponse: any = await onlineOfferRuleEngineGetNbfcOffer(item_code);
    // const online_nbfcResponse: any = await offerRuleEngineGetNbfcOffer(item_code);
    if (online_nbfcResponse?.status === 200) {
      if (online_nbfcResponse?.data?.data?.financeModelList?.length) {
        const nbfcCal: any = online_nbfcResponse?.data?.data?.financeModelList
          .map((e: any, i: number) => {
            const { schemas = [], _id = "" } = e;
            return schemas.map((el: any) => {
              const { emiPerMonth = "" } = el;
              return {
                price: emiPerMonth,
                index: i,
                name: _id
              };
            });
          })
          .flat()
          .filter((e: any) => !!e.price)
          .sort((a, b) => (a.price > b.price ? 1 : -1))[0];
        setOnlineNbfcMinAmout(nbfcCal);
        setOnlineNbfcTotalData(online_nbfcResponse?.data?.data?.financeModelList);
      }
    }
  };

  const getOnlineEmidetails = async () => {
    const parameter: any = {
      payment_gateway: "pinepg",
      product_code: sku,
      product_amount: `${Number(showroomPrice) * 100}`
    };
    const respose: any = await fetchOnlineEmiDetails(parameter);
    const { data: { code = 0, data = {} } = {} } = respose;
    if (code === 200) {
      setOnlineEmiDetails(data);
    }
  };

  const getOfflineEmidetails = async () => {
    const parameter: any = {
      payment_gateway: "pinelabs",
      product_code: pine_product_id?.value || "",
      product_amount: `${Number(sp > 0 ? sp : mrp > 0 ? mrp : 0) * 100}`
    };
    const respose: any = await fetchSalesOfflineEmiDetails(parameter);
    const nbfcResponse: any = await offerRuleEngineGetNbfcOffer(item_code);
    if (nbfcResponse?.status === 200) {
      if (nbfcResponse?.data?.data?.financeModelList) {
        const nbfcCal: any = nbfcResponse?.data?.data?.financeModelList
          .map((e: any, i: number) => {
            const { schemas = [], _id = "" } = e;
            return schemas.map((el: any) => {
              const { emiPerMonth = "", ...rest } = el;
              return {
                price: emiPerMonth,
                index: i,
                name: _id,
                ...rest
              };
            });
          })
          .flat()
          .filter((e: any) => !!e.price)
          .sort((a, b) => (a.price > b.price ? 1 : -1))[0];
        setnbfcMinAmout(nbfcCal);
        setNbfcTotalData(nbfcResponse?.data?.data?.financeModelList);
      }
    }
    const { data: { code = 0, data = {} } = {} } = respose;
    if (code === 200) {
      setOfflineEmiDetails(data);
    }
  };

  useEffect(() => {
    if ((productDetails?.channel_shapes || [])?.length) {
      getOfflineEmidetails();
    }
  }, [item_code]);

  useEffect(() => {
    if (sku && showroomPrice) {
      getOnlineEmidetails();
    }
  }, [showroomPrice, sku]);

  useEffect(() => {
    if ((onlineNbfcTotalData || [])?.length === 0 && item_code) {
      getOnlineEmpiNbfcDetails();
    }
  }, [onlineNbfcTotalData, item_code]);

  return (
    <React.Fragment>
      <div className="inline-flex mb-2 justify-center flex-col w-full">
        <div className="w-full flex justify-between gap-4">
          <div className="w-full lg:w-1/2 flex-0 text-xs pl-1 pr-2 sm:pr-5 ">
            {!_isEmpty(onlineEmiDetails) && (
              <div className="flex items-center mt-1 flex-wrap">
                <div className="uppercase font-semibold mr-2 text-xs">ONLINE</div>
                <div className="text-xs text-gray-600 mt-1">
                  <span className="mr-2">
                    {lowestEMIOfferedBy || ""} EMI starting from ₹{lowestEMIAmount}
                  </span>
                  <button
                    type={"button"}
                    onClick={() => {
                      handleEmiPopup(true);
                      setOnlineEmiTab("tab1");
                    }}
                    className="flex-0 rounded py-1 px-2 bg-yellow-500 text-white text-xxs w-8 h-4 inline-flex justify-center items-center"
                  >
                    EMI
                  </button>
                </div>
              </div>
            )}
            {onlinenbfcMinAmout.price > 0 && (
              <div className="flex items-center mt-2 flex-wrap">
                <div className="uppercase font-semibold mr-2 text-xs whitespace-nowrap">ONLINE: (NBFC) </div>
                <div className="text-xs text-gray-600 mt-1">
                  <span className="mr-2">
                    {onlinenbfcMinAmout?.name || ""} EMI starting from ₹{onlinenbfcMinAmout.price}
                  </span>
                  <button
                    type={"button"}
                    onClick={() => {
                      handleEmiPopup(true);
                      setOnlineEmiTab("custom");
                    }}
                    className="flex-0 rounded py-1 px-2 bg-yellow-500 text-white text-xxs w-8 h-4 inline-flex justify-center items-center"
                  >
                    EMI
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="w-full lg:w-1/2 flex-0 text-xs pl-1 pr-2 sm:pr-5 ">
            {!_isEmpty(offlineEmiDetails) && (
              <div className="flex items-center mt-1 flex-wrap">
                <div className="uppercase font-semibold mr-2">OFFLINE: </div>
                <div className="text-xs text-gray-600 mt-1">
                  <span className="mr-2">
                    {offlineEmiDetails?.lowestEMIOfferedBy || ""} EMI starting from ₹
                    {offlineEmiDetails?.lowestEMIAmount || 0}
                  </span>
                  <button
                    type={"button"}
                    onClick={() => {
                      setOfflineEmiPopup(true);
                      setOfflineEmiTab("tab1");
                    }}
                    className="flex-0 rounded py-1 px-2 bg-yellow-500 text-white text-xxs w-8 h-4 inline-flex justify-center items-center"
                  >
                    EMI
                  </button>
                </div>
              </div>
            )}
            {nbfcMinAmout?.price && (
              <div className="flex items-center mt-2 flex-wrap">
                <div className="uppercase font-semibold mr-2 whitespace-nowrap">OFFLINE: (NBFC) </div>
                <div className="text-xs text-gray-600 mt-1">
                  <span className="mr-2">
                    {nbfcMinAmout?.financeName} EMI starting from ₹{nbfcMinAmout?.price}
                  </span>
                  <button
                    type={"button"}
                    onClick={() => {
                      setOfflineEmiPopup(true);
                      setOfflineEmiTab("custom");
                    }}
                    className="flex-0 rounded py-1 px-2 bg-yellow-500 text-white text-xxs w-8 h-4 inline-flex justify-center items-center"
                  >
                    EMI
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {_get(stockSplice, "length", 0) > 0 ? (
          <>
            <div className="flex items-center mb-2 mt-1 ">
              <span className="text-sm ">Nearby stores</span>
              <button
                type={"button"}
                onClick={() => setOpenModal(true)}
                className="mx-1 rounded p-1 bg-yellow-500 text-white w-5 h-5 inline-flex justify-center items-center"
              >
                <LocationMarkerIcon className="w-5 h-5" />
              </button>
            </div>

            <div className="w-full overflow-hidden rounded my-2 text-sm pr-2">
              <div className="border-dashed w-full">
                <table className="border text-xs w-full text-left">
                  <thead className="bg-transparent text-gray-800">
                    <tr>
                      <th className="border bg-gray-50 px-2 py-1">Branch</th>
                      <th className="border bg-gray-50 px-2 py-1 text-center">Stock</th>
                      <th className="border bg-gray-50 px-2 py-1">Near By</th>
                    </tr>
                  </thead>
                  <tbody className=" text-gray-700 font-normal">
                    {(stockSplice || [])
                      .filter((stock: any) => +stock?.stockcount >= 0)
                      .slice(0, 3)
                      .map((ele: any, i: number) => {
                        return (
                          <React.Fragment key={convertToLowerCase(i + "stockSplice-efd")}>
                            <tr>
                              <td className="border bg-transparent px-2 py-1">{_get(ele, "branchname", "")}</td>
                              <td className="border bg-transparent px-2 py-1 text-center">
                                <span className="rounded-full text-white bg-red-600 px-1 py-0.5">
                                  {_get(ele, "stockcount", "")}
                                </span>
                              </td>
                              <td className="border bg-transparent px-2 py-1">
                                {_get(ele, "distance", 0)} {"Km"}
                              </td>
                            </tr>
                          </React.Fragment>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) : null}

        {/* emi start */}
      </div>

      {/* emi popUp */}
      {actionPopup && (
        <ModalFixed2>
          <ActionPopup
            enableHeader={true}
            customWidth={"680px"}
            customMWidth={"680px"}
            enableActionIcon={false}
            handleActionText="Close"
            textHeaderHeading={"Online Emi Offers"}
            handleAction={() => handleEmiPopup(false)}
            isDisbaleOutSideClick={true}
            handleCancel={() => handleEmiPopup(false)}
          >
            <div className="mb-5 inline-flex  w-full">
              <EmiBankDetailsPop
                _activeTab={OnlineEmiTab}
                emiData={onlineEmiDetails}
                nbfcTotalData={onlineNbfcTotalData}
                showEmiMoreDetailsClick={showEmiMoreDetailsClick}
              />
            </div>
          </ActionPopup>
        </ModalFixed2>
      )}

      {offlineEmiPopup && (
        <ModalFixed2>
          <ActionPopup
            enableHeader={true}
            customWidth={"100%"}
            customMWidth={"680px"}
            enableActionIcon={false}
            handleActionText="Close"
            handleAction={() => setOfflineEmiPopup(false)}
            isFullView={true}
            PopupClass={" h-full "}
            isOverFlow={true}
            isDisbaleOutSideClick={true}
            textHeaderHeading={"Offline Emi Offers"}
            handleCancel={() => setOfflineEmiPopup(false)}
          >
            <div className="mb-5 inline-flex w-full">
              <EmiBankDetailsPop
                _activeTab={offlineEmiTab}
                emiData={offlineEmiDetails}
                nbfcTotalData={nbfcTotalData}
                showEmiMoreDetailsClick={showEmiMoreDetailsClick}
              />
            </div>
          </ActionPopup>
        </ModalFixed2>
      )}
      {showEmiMoreDetailsPopup && (
        <ModalFixed3>
          <ActionPopup
            customWidth="600px"
            enableHeader={true}
            enableActionIcon={false}
            handleActionText="Close"
            isDisbaleOutSideClick={false}
            handleAction={() => setEmiShowMoreDetailsPopup(false)}
            handleCancel={() => setEmiShowMoreDetailsPopup(false)}
            textHeaderHeading={"Emi Details"}
          >
            <div className="px-2 pb-2">
              <EmiMoreDetails sp={sp} max={max} name={productDetails.name} moreDetailsData={moreDetailsData} />
            </div>
          </ActionPopup>
        </ModalFixed3>
      )}

      {openModal && (
        <ModalFixed2>
          <ActionPopup
            customWidth="100%"
            enableHeader={true}
            customMWidth="100%"
            enableActionIcon={false}
            handleActionText={""}
            handleCancel={() => setOpenModal(false)}
            textHeaderHeading={`Stock Details ${stock ? `total Availability count - ${stock}` : ""}`}
          >
            <StockTable stockData={stock_data} />
          </ActionPopup>
        </ModalFixed2>
      )}
    </React.Fragment>
  );
};

export default ProductEmiAndStock;
