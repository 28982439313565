import React from "react";
import { Link } from "react-router-dom";
import { ClipboardListIcon } from "@heroicons/react/outline";
import { ListClass, SideBarIconClass, MenuName } from "utils/ClassContent";

const EnquiryFeedBackMenu = (props: any = {}) => {
  const { pathname = "" } = props;

  return (
    <li className={`${ListClass} ${pathname === "/enquiry-feedback" ? " active-menu" : ""}`}>
      <Link to={"/enquiry-feedback"} className={`${SideBarIconClass} flex-auto`}>
        <ClipboardListIcon className={"w-8 h-8 flex-shrink-0 py-1"} />
        <div className={MenuName + " relative"}>
          <span className="text-sm">{"Enquiry Feedback"}</span>
        </div>
      </Link>
    </li>
  );
};
export default EnquiryFeedBackMenu;
