import React, { useState } from "react";
import { useSelector } from "react-redux";
import ModalFixed3 from "Component/Modal/ModalFixed3";
import RelatedProductDetailedView from "../../SalesProductDetails/RelatedProductDetailedView";

const ReleatedProductView = (props: any = {}) => {
  const { customerData = {}, productDetails = {} } = props || {};

  const globalData = useSelector((state: any) => state) || {};
  let { mobileNo = "" } = customerData;
  const [btnPopup, setBtnPopup] = useState("");
  const [openProductModal, setOpenProductModal] = useState(false);
  const [popParentTabs, setPopParentTabs] = useState("pTab0");

  mobileNo = mobileNo || customerData?.customer_details?.telephone;

  const handleOpenProductModal = (val: any = "") => {
    setOpenProductModal(true);
    setBtnPopup(val);
    setPopParentTabs(`pTab${val.replace("btnPopup", "")}`);
  };

  return (
    <React.Fragment>
      <div className="flex flex-wrap border-b pb-1 pt-1.5">
        <button
          type="button"
          onClick={() => handleOpenProductModal(btnPopup === "btnPopup2" ? "" : "btnPopup2")}
          className={`rounded border-2 mx-1 mb-0.5 border-yellow-400 px-1 lg:px-2 h-6 lg:h-8
                                          bg-primary-gradient-hover-c select-none
                                          ${btnPopup === "btnPopup2" ? "bg-primary-gradient bg-primary-gradient-hover" : " text-gray-600 "}`}
        >
          <span className="block text-xs text-center overflow-ellipsis overflow-hidden font-semibold">
            Related Products
          </span>
        </button>

        <button
          type="button"
          onClick={() => handleOpenProductModal(btnPopup === "btnPopup3" ? "" : "btnPopup3")}
          className={`rounded border-2 mx-1 mb-0.5 border-yellow-400 px-1 lg:px-2 h-6 lg:h-8
                                          bg-primary-gradient-hover-c select-none
                                          ${btnPopup === "btnPopup3" ? "bg-primary-gradient bg-primary-gradient-hover" : " text-gray-600 "}`}
        >
          <span className="block text-xs text-center overflow-ellipsis overflow-hidden font-semibold">
            Recent Enquiry
          </span>
        </button>

        <button
          type="button"
          onClick={() => handleOpenProductModal(btnPopup === "btnPopup4" ? "" : "btnPopup4")}
          className={`rounded border-2 mx-1 mb-0.5 border-yellow-400 px-1 lg:px-2 h-6 lg:h-8
                                          bg-primary-gradient-hover-c select-none
                                          ${btnPopup === "btnPopup4" ? "bg-primary-gradient bg-primary-gradient-hover" : " text-gray-600 "}`}
        >
          <span className="block text-xs text-center overflow-ellipsis overflow-hidden font-semibold">Focus Model</span>
        </button>
        <button
          type="button"
          onClick={() => handleOpenProductModal(btnPopup === "btnPopup5" ? "" : "btnPopup5")}
          className={`rounded border-2 mx-1 mb-0.5 border-yellow-400 px-1 lg:px-2 h-6 lg:h-8
                                          bg-primary-gradient-hover-c select-none
                                          ${btnPopup === "btnPopup5" ? "bg-primary-gradient bg-primary-gradient-hover" : " text-gray-600 "}`}
        >
          <span className="block text-xs text-center overflow-ellipsis overflow-hidden font-semibold">Compare</span>
        </button>
        <button
          type="button"
          onClick={() => handleOpenProductModal(btnPopup === "btnPopup6" ? "" : "btnPopup6")}
          className={`rounded border-2 mx-1 mb-0.5 border-yellow-400 px-1 lg:px-2 h-6 lg:h-8
                                          bg-primary-gradient-hover-c select-none
                                          ${btnPopup === "btnPopup6" ? "bg-primary-gradient bg-primary-gradient-hover" : " text-gray-600 "}`}
        >
          <span className="block text-xs text-center overflow-ellipsis overflow-hidden font-semibold">
            AlternateProducts
          </span>
        </button>
      </div>

      {openProductModal && (
        <ModalFixed3>
          <RelatedProductDetailedView
            fromPage="viewPage"
            mobileNo={mobileNo}
            globalData={globalData}
            productDetails={productDetails}
            popParentTabsParent={popParentTabs}
            handleChooseProduct={(e: any) => {}}
            setOpenProductModal={(e: any) => setOpenProductModal(e)}
          />
        </ModalFixed3>
      )}
    </React.Fragment>
  );
};

export default ReleatedProductView;
