import React, { useEffect, useState } from "react";
import CommonInput from "Component/Form/CommonInput";
import RadioCheck from "Component/Form/CommonRadioBox";
import CommonTextarea from "Component/Form/CommonTextarea";
import { convertToLowerCase, numOnlyAllow } from "utils/validation";
import { TableClassCenter, tdClass, thClass } from "utils/ClassContent";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import MultiDropDownNew from "Component/CommonTable/MultiDropDownNew";
import CommonCheck from "Component/Form/CommonCheck";
import { _get } from "utils/lodashConst";

const questionOrder = ["yes", "no", "met", "notMet", "partial", "NA"];

const ScoreCardTable = (props: any) => {
  const tempHide = false;
  const {
    activeScoreCard: activeScoreCrd = {},
    edit = true,
    autoFill = true,
    handleAutoFill = () => {},
    response = [],
    handleCausesAndComments = () => {},
    getOptionsList = () => {},
    handleScoreChange = () => {}
  } = props || {};
  const [activeScoreCard, setActiveScoreCard] = useState<any>({});

  /**
   * get options list
   */
  const getOptionsListMulti = (itm: any = [], comment: any = "") => {
    const options = [] as object[];
    itm.forEach((item: any = "", index = 0) => {
      options.push({
        _id: index + 1,
        isCheck: comment.split(",").indexOf(item) > -1 ? true : false,
        itemName: item
      });
    });
    return options;
  };

  const evaluatorFilter = (event: any = {}, sn: any = "") => {
    const { originalArray = [] } = event;
    const filterCheck = originalArray.filter((ele: any) => ele.isCheck === true);
    const getId = filterCheck.map((ele: any) => ele.itemName);
    handleCausesAndComments({ target: { name: "comment", value: getId.join(",") } }, sn);
  };

  useEffect(() => {
    setActiveScoreCard(activeScoreCrd);
  }, [JSON.stringify(activeScoreCrd)]);

  const { settings = {}, groups = [], questions = [], name: scoreCardName = "" } = activeScoreCard;

  const getComment = (commentType: any, sn: any, item: any) => {
    return (
      <div className="w-full">
        <div className="">
          {commentType === "text" && (
            <CommonTextarea
              label={false}
              value={response[sn]?.comment}
              setShow={true}
              onChange={(event: any = {}) => handleCausesAndComments(event, sn)}
              parentClass={" w-full"}
              placeholder={item.placeholder}
              labelTextClass={"font-bold"}
              inputClass="flex justify-end"
              inputFieldClass="h-10 max-h-64 min-h-textarea-sm"
            />
          )}

          {commentType === "select" && (
            <CommonSearchDropdown
              label={true}
              setShow={true}
              dropOnTop={true}
              inputWithIconClass="w-10"
              inputFieldClass="h-7 pr-11"
              labelTextClass={"font-bold"}
              clearInput={() => handleCausesAndComments({ target: { name: "comment", value: "" } }, sn)}
              inputClass="flex justify-end w-full"
              arrayData={getOptionsList(item?.commentField)}
              name={"comment"}
              parentClass={" w-full my-1 px-0 transparent_drop2"}
              value={response[sn]?.comment}
              handleParentDropVal={(data: any = {}) =>
                handleCausesAndComments({ target: { name: "comment", value: data.optionData } }, sn)
              }
            />
          )}

          {commentType === "multi select" && (
            <MultiDropDownNew
              name={"Comment"}
              isSelectAll={true}
              DropDownParentClass={" w-full "}
              enableCheckbox={true}
              isOKButtonEnable={true}
              parentClass="w-full"
              customWidthClass={"w-full"}
              arrayData={getOptionsListMulti(item?.commentField, response[sn]?.comment)}
              filterArrayPass={(event: any = {}) => evaluatorFilter(event, sn)}
            />
          )}
        </div>
      </div>
    );
  };

  const commonQuestion = (options: any, index: number, sn: any, item: any) => {
    return (
      <td style={{ width: "15%", minWidth: "100px" }} className={tdClass}>
        <div className="flex flex-col p-2">
          {questionOrder.map((questName = "") => {
            return options[questName]?.status ? (
              <RadioCheck
                label={true}
                id={`${questName}-${index}`}
                name={`${questName}-${index}`}
                checked={response[sn]?.selected === questName}
                parentAsLabel={true}
                onChange={() => handleScoreChange(options[questName], questName, item)}
                labelText={questName}
                parentAsLabelClass={"mb-1 w-auto mt-1 pr-4 text-sm"}
              />
            ) : null;
          })}
        </div>
      </td>
    );
  };

  return (
    <div className="pt-0 pb-5">
      <div className="flex justify-between items-center border-b pb-1">
        <div className="flex justify-between items-center text-base font-bold px-4">
          <h4>{scoreCardName}</h4>
        </div>
        <div className="flex justify-between items-center pl-4">
          <div className="flex space-x-2 justify-end p-1">
            <CommonCheck
              label={true}
              // defaultValue={'true'}
              id={`autoFill`}
              name={`autoFill`}
              disabled={!edit}
              checked={autoFill}
              parentAsLabel={true}
              onChange={handleAutoFill}
              labelText={"Auto Fill Options"}
              parentAsLabelClass={"mb-1 w-auto mt-1 pr-4 text-sm"}
            />
          </div>
        </div>
      </div>
      <div className="" style={{ maxHeight: "calc(100% - 100px)" }}>
        <table className={TableClassCenter}>
          <thead>
            <tr>
              <th className={thClass}>Ques.No.</th>
              <th className={thClass}>Question</th>
              <th className={thClass}>Causes and Comments</th>
              <th className={thClass}>Answers</th>
              {!settings.enableBindStory && <th className={thClass}>Score</th>}
            </tr>
          </thead>
          <tbody>
            {questions.map((item: any, index: any) => {
              const { group = "", description = "", hints = "", sn = 1, options = {}, commentType = "" } = item;
              return group === "" ? (
                <tr key={convertToLowerCase(index + "questions-fsvx")}>
                  <td style={{ width: "6%", minWidth: "50px" }} className={tdClass + " text-center"}>
                    <div className="font-bold text-sm">Q{index + 1}</div>
                  </td>
                  <td className={tdClass}>
                    <div className="p-2 text-left">
                      <p>{description}</p>
                      <p className="italic inline-block bg-yellow-100 px-2 py-1 rounded-sm text-gray-800 text-xxs mt-2">
                        {hints}
                      </p>
                    </div>
                  </td>
                  <td style={{ width: "30%", minWidth: "200px" }} className={tdClass}>
                    {getComment(commentType, sn, item)}
                  </td>

                  {commonQuestion(options, index, sn, item)}
                  {!settings.enableBindStory && (
                    <td style={{ width: "8%", minWidth: "120px" }} className={tdClass}>
                      <div className="flex flex-col justify-center align-center text-center">
                        {tempHide ? (
                          <CommonInput
                            label={false}
                            setShow={true}
                            mustField={true}
                            readOnly={true}
                            inputId={`maxScore-${index}`}
                            name={`maxScore-${index}`}
                            inputError={false}
                            inputFieldClass="h-8 text-center"
                            handleOnchangeEvent={() => {}}
                            labelTextClass={"font-bold"}
                            parentClass={"w-16 m-auto"}
                            value={response?.[sn]?.marks}
                            onKeyPress={numOnlyAllow}
                          />
                        ) : null}
                        <div className="block w-60 rounded font-bold input-common px-3 py-1 text-sm h-8 text-center">
                          {response?.[sn]?.marks}
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              ) : null;
            })}
            {groups.map((dta: any, j: any) => {
              return (
                <React.Fragment key={convertToLowerCase(j + "groups-fsvx")}>
                  {!settings.questionNumberView && (
                    <tr className={`${dta ? "bg-blue -600" : ""}text-white font-bold`}>
                      <td colSpan={5} className={tdClass}>
                        <div className="px-4 font-bold capitalize text-sm text-left">{dta}</div>
                      </td>
                    </tr>
                  )}
                  {questions.map((item: any, index: any) => {
                    const { group = "", description = "", hints = "", sn = 1, options = {}, commentType = "" } = item;
                    return group === dta ? (
                      <tr key={convertToLowerCase(index + "questions-fsvx")}>
                        <td style={{ width: "6%", minWidth: "50px" }} className={tdClass + " text-center"}>
                          <div className="font-bold text-sm">Q{index + 1}</div>
                        </td>
                        <td className={tdClass}>
                          <div className="p-2 text-left">
                            <p>{description}</p>
                            <p className="italic inline-block bg-yellow-100 px-2 py-1 rounded-sm text-gray-800 text-xxs mt-2">
                              {hints}
                            </p>
                          </div>
                        </td>
                        <td style={{ width: "30%", minWidth: "200px" }} className={tdClass}>
                          {getComment(commentType, sn, item)}
                        </td>

                        {commonQuestion(options, index, sn, item)}

                        {!settings.enableBindStory && (
                          <td style={{ width: "8%", minWidth: "120px" }} className={tdClass}>
                            <div className="flex flex-col justify-center align-center text-center">
                              <CommonInput
                                label={false}
                                setShow={true}
                                mustField={true}
                                readOnly={true}
                                inputId={`maxScore-${index}`}
                                name={`maxScore-${index}`}
                                inputError={false}
                                inputFieldClass="h-8 text-center"
                                handleOnchangeEvent={() => {}}
                                labelTextClass={"font-bold"}
                                parentClass={"w-16 m-auto"}
                                value={response[sn]?.marks || "0"}
                                onKeyPress={numOnlyAllow}
                              />
                            </div>
                          </td>
                        )}
                      </tr>
                    ) : null;
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ScoreCardTable;
