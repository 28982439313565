import { _get } from "utils/lodashConst";

export const customerInfoInitState = {
  gstDetailLoader: false,
  gstDetailsData: {},
  getProductListLoader: false,
  getProductListData: [],
  userInfoPinDataBA: [],
  userInfoPinCodeBALoader: false,
  userInfoPinDataDA: [],
  userInfoPinCodeDALoader: false,
  verifyInfoData: {},
  verifyLoader: false,
  validateInfoData: {},
  validateLoader: false,
  customerInfoLoader: false,
  customerInfoData: {},
  mergeRequestData: {},
  basicInfoUserinfoEmail: null,
  basicInfoUserinfoMobile: null,
  mergeRequestLoader: false,
  customerDetailLoader: false,
  customerDetailData: {},
  customerListgetLoader: false,
  customerListData: [],
  customerInfoCurrentStep: ""
};

export const customerInfoReducer = (state = customerInfoInitState, action = {}) => {
  switch (_get(action, "type")) {
    //all reasult data store here
    case "GET_CUSTOMER_LIST_LOADER": {
      return {
        ...state,
        customerListgetLoader: _get(action, "customerListgetLoader", false)
      };
    }

    case "STORE_CUSTOMER_LIST_DATA": {
      return {
        ...state,
        customerListData: _get(action, "customerListData", {})
        // comListApiReload: false
      };
    }

    case "GET_GST_DETAIL_LOADER":
      return {
        ...state,
        gstDetailLoader: _get(action, "gstDetailLoader", false)
      };

    case "GET_GST_DETAIL_DATA":
      return {
        ...state,
        gstDetailsData: _get(action, "gstDetailsData", {})
      };

    case "GET_PRODUCT_LIST_LOADER":
      return {
        ...state,
        getProductListLoader: _get(action, "getProductListLoader", false)
      };

    case "GET_PRODUCT_LIST_DATA":
      return {
        ...state,
        getProductListData: _get(action, "getProductListData", [])
      };

    case "BASIC_IFO_PINCODE_BA_LOADER_DATA": {
      return {
        ...state,
        userInfoPinDataBA: _get(action, "userInfoPinDataBA", [])
      };
    }

    case "BASIC_IFO_PINCODE_BA_LOADER": {
      return {
        ...state,
        userInfoPinCodeBALoader: _get(action, "userInfoPinCodeBALoader", false)
      };
    }

    case "BASIC_IFO_PINCODE_DA_LOADER_DATA": {
      return {
        ...state,
        userInfoPinDataDA: _get(action, "userInfoPinDataDA", [])
      };
    }

    case "BASIC_IFO_PINCODE_DA_LOADER": {
      return {
        ...state,
        userInfoPinCodeDALoader: _get(action, "userInfoPinCodeDALoader", false)
      };
    }
    case "VERIFY_INFO_DATA": {
      return {
        ...state,
        verifyInfoData: _get(action, "verifyInfoData", [])
      };
    }

    case "VERIFY_INFO_LOADER": {
      return {
        ...state,
        verifyLoader: _get(action, "verifyLoader", false)
      };
    }

    case "VALIDATE_OTP_DATA": {
      return {
        ...state,
        validateInfoData: _get(action, "validateInfoData", [])
      };
    }

    case "VALIDATE_OTP_LOADER": {
      return {
        ...state,
        validateLoader: _get(action, "validateLoader", false)
      };
    }

    case "CUSTOMER_INFO_SUBMIT_DATA": {
      return {
        ...state,
        customerInfoData: _get(action, "customerInfoData", [])
      };
    }

    case "CUSTOMER_INFO_SUBMIT_LOADER": {
      return {
        ...state,
        customerInfoLoader: _get(action, "customerInfoLoader", false)
      };
    }

    case "CUSTOMER_INFO_USER_FIND_DATA_MOBILE": {
      return {
        ...state,
        basicInfoUserinfoMobile: _get(action, "basicInfoUserinfoMobile", {}),
        mergeRequestData: {}
      };
    }

    case "CUSTOMER_INFO_USER_FIND_DATA_EMAIL": {
      const { basicInfoUserinfoMobile = {} } = state;
      const customerId = _get(basicInfoUserinfoMobile, "customerId", "");
      const emailData = _get(action, "basicInfoUserinfoEmail", null);
      const { customerId: emailCustomerId = "" } = emailData;
      if (customerId && emailCustomerId && customerId !== emailCustomerId) {
        return {
          ...state,
          basicInfoUserinfoEmail: null,
          mergeRequestData: {
            emailCusId: emailCustomerId,
            mobieCusId: customerId
          }
        };
      }
      return {
        ...state,
        basicInfoUserinfoEmail: emailData,
        mergeRequestData: {}
      };
    }

    case "MERGE_REQUEST_CUSTOMER_DATA":
      return {
        ...state,
        basicInfoUserinfoMobile: _get(action, "mergeRequestData", false),
        mergeRequestData: {},
        basicInfoUserinfoEmail: null
      };
    case "MERGE_REQUEST_CUSTOMER_LOADER":
      return {
        ...state,
        mergeRequestLoader: _get(action, "mergeRequestLoader", false)
      };
    case "GET_CUSTOMER_DETAIL_DATA":
      return {
        ...state,
        customerDetailData: _get(action, "customerDetailData", {})
      };
    case "GET_CUSTOMER_DETAIL_LOADER":
      return {
        ...state,
        customerDetailLoader: _get(action, "customerDetailLoader", false)
      };
    case "CUSTOMER_INFO_CURRENT_STEP":
      return {
        ...state,
        customerInfoCurrentStep: _get(action, "customerInfoCurrentStep", "")
      };
    default:
      return {
        ...state
      };
  }
};
