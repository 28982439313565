import React, { useEffect, useState } from "react";
import store from "store";
import { useSelector } from "react-redux";
import { ImgAvatarMaleSm3 } from "assets/images";
import { SalesEnquiryStatusUpdateProps } from "./types";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { getEmiDataBankDetails } from "../../SalesEmi/commonSalesEmi";
import { updateSupportStatus } from "utils/services/customerDetailsServiceServices";
import { getMailByIdCall, resetEnquiryMailCall } from "Pages/ServiceCalls/commonServiceCalls";
import { getShowroomPriceApiCall } from "../SalesProductDetails/commonSalesProductDetails";
import EnquiryStatusTrackingData from "Pages/NewCall/EnquiryFor/SalesReturnsForm/ViewSalesReturn/StatusUpdateSalesReturn/EnquiryStatusTrackingTable/EnquiryStatusTrackingData";
import EnquiryLeadClassificationData from "Pages/NewCall/EnquiryFor/SalesReturnsForm/ViewSalesReturn/StatusUpdateSalesReturn/EnquiryStatusTrackingTable/EnquiryLeadClassificaionData";
import {
  bg_BasicInfo,
  bg_EnquiryFromMail,
  bg_EnquiryInfo,
  bg_EnquiryStatusHistory,
  bg_EnquiryStatusUpdate,
  bg_IMEIandMobile,
  bg_ProductRelatedTable
} from "utils/ClassContent";
import Ripples from "react-ripples";
import PageAnimate from "Component/PageAnimate";
import CommonTable from "Component/CommonTable";
import SalesProductLoader from "../SalesProductLoader";
import SalesStatusUpdate from "../../SalesStatusUpdate";
import ProductRelatedTable from "./ProductRelatedTable";
import { pageNumber, tabBasedAccess } from "helperRole";
import SalesEnquiryPopupTap from "./SalesEnquiryPopupTap";
import CommonDropHeading from "Component/CommonDropHeading";
import OutsideClickHandler from "react-outside-click-handler";
import SalesCustomerBasicInfo from "./SalesCustomerBasicInfo";
import FixedPopupTemplate from "Component/FixedPopupTemplate";
import BasicInfoView from "Pages/Customers/CallsList/BasicInfoView";
import ActionHistory from "Pages/Customers/CallsList/ActionHistory";
import EnquiryFromMail from "Pages/MailBox/ShowMailOnEnquiry/EnquiryFromMail";
import MessageDeliveryHistory from "Pages/ServiceCalls/ViewCallInfo/MessageDeliveryHistory";
import EnquiryDetailsTable from "Pages/NewCall/CustomerDetails/CustomerDetailedTable/EnquiryDetailsTable";
import { getProductDetailsCommon } from "../LeadHelper";
import { TableIcon, XCircleIcon } from "@heroicons/react/outline";

const SalesEnquiryStatusUpdate = (props: SalesEnquiryStatusUpdateProps) => {
  const {
    setShow = true,
    customerData = {},
    activeModal = false,
    closePopup = () => {},
    isShowRoomStaffAndMobView = false,
    getEnquiryInformation = () => {}
  } = nullToObject(props);
  const {
    imeiMobileNo = "",
    enquiryFor = "",
    status = "",
    mailId = "",
    customerName = "",
    leadClassificationHistory = [],
    subCategory = "",
    transactions = [],
    productInfo = {},
    itemCode = "",
    itemBrandName = "",
    itemName = ""
  } = customerData;

  const enableBasicHistory = tabBasedAccess(pageNumber.BASIC_INFO_HISTORY);

  const { slug = "", price = "", sku = "" } = nullToObject(productInfo);

  const globalData = useSelector((state: any) => state) || {};
  const { salesReturn: { imeiNumHistoryDetails = {} } = {}, mailReducer: { selectedMailData = {} } = {} } = globalData;
  const { data: imeiNumHistoryDetailsData = [] } = imeiNumHistoryDetails;

  const [apxProduct, setApxProduct] = useState("");
  const [onStatusSubmit, setOnStatusSubmit] = useState(false);
  const [popParentTabs, setPopParentTabs] = useState(isShowRoomStaffAndMobView ? "EnquiryInfo" : "BasicInfo");
  // const [product, setProduct] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState({
    _id: "",
    status: "",
    remarks: "",
    docFiles: [],
    enquiryFor: "",
    subCategory: "",
    followUpDate: "",
    currentStatus: ""
  });

  //filter mobile number
  const cusTransHistory = imeiNumHistoryDetailsData
    .map((ele: any) => {
      if (ele.imeiMobileNo === imeiMobileNo) {
        return ele;
      }
      return undefined;
    })
    .filter((eleUndefined: any) => eleUndefined !== undefined);

  // const { item_code = "" } = productDetails;
  const [editView, setEditView] = useState(false);
  const [columnView, setColumnView] = useState(false);
  const [getshowHistory, setshowHistory] = useState(false);
  const [enableViewMode, setEnableViewMode] = useState(true);
  const [productDetails, setProductDetails] = useState<any>({});
  const [getProductDetailLoader, setGetProductDetailLoader] = useState<boolean>(false);
  /**
   * set product name
   * @param _prod product name
   */
  const getProduct = (_prod = "") => {
    // setProduct(prod);
  };

  const popParentTabsHandle = (value = "") => {
    setPopParentTabs(value);
  };

  /**
   * Add updated status value
   * @param changedItem changed object
   */
  const handleOnStatusChange = (changedItem: Object = {}) => {
    setUpdatedStatus({ ...updatedStatus, ...changedItem });
    setOnStatusSubmit(true);
  };
  const handleEditView = () => {
    setEditView(!editView);
    setEnableViewMode(!enableViewMode);
  };

  const handleColumnView = () => {
    setColumnView(!columnView);
  };

  /**
   * Get customer details based on user search
   * @param userSearch user search data
   */
  const updateSupportStatusCall = (data = {}) => {
    store.dispatch({ type: "SET_CUSTOMER_SERVICE_LOADER", customerServiceLoader: true });
    store.dispatch(updateSupportStatus(data));
  };

  /**
   * slugIdBased get product details
   * @param slugId
   */
  const getProductgetProductDetailsByCategoryCall = async (slugId = {}) => {
    setGetProductDetailLoader(true); //loader on
    const product = await getProductDetailsCommon({
      shortcode: "",
      channel: "SHOP",
      item_code: slugId,
      num: 5
    });
    setGetProductDetailLoader(false); //loader off
    setProductDetails(product);
  };

  const getShowroomPriceApiCallAction = async (item_codes = "") => {
    const response = (await getShowroomPriceApiCall(item_codes)) || {};
    const { apxValueFind = "" } = nullToObject(response);
    setApxProduct(apxValueFind); //apx name
  };

  // history table show/hide function

  const handleHistoryTable = (_state = false) => {
    setshowHistory(_state);
  };

  // Call update api on submit
  useEffect(() => {
    if (onStatusSubmit) {
      updateSupportStatusCall(updatedStatus);
      setOnStatusSubmit(false);
    }
  }, [onStatusSubmit]);

  useEffect(() => {
    const container = document && document.getElementById("modalFixed");
    if (setShow) {
      container?.classList.add("bigPopup");
    } else {
      container?.classList.remove("bigPopup");
    }
  }, [setShow]);

  useEffect(() => {
    //emi details get
    if (price && sku) {
      getEmiDataBankDetails(productInfo);
    }

    //product info get
    getProductgetProductDetailsByCategoryCall(customerData);
  }, [slug]);

  // get showroom price
  useEffect(() => {
    if (itemCode) {
      getShowroomPriceApiCallAction(itemCode);
    }
  }, [itemCode]);

  useEffect(() => {
    if (mailId) {
      getMailByIdCall(mailId);
    }
    return () => {
      if (mailId) {
        resetEnquiryMailCall();
      }
    };
  }, [mailId]);

  return (
    <React.Fragment>
      {/* loader */}
      <SalesProductLoader getProductDetailLoader={getProductDetailLoader} />

      <FixedPopupTemplate
        isMobile={isShowRoomStaffAndMobView}
        overflowChild={true}
        editStatus={editView}
        imgSrc={ImgAvatarMaleSm3}
        enableColumnViewOption={true}
        columnViewStatus={columnView}
        textHeaderHeading={customerName}
        handleEditView={handleEditView}
        handleClosePopup={() => closePopup(false)}
        handleColumnView={handleColumnView}
        textSubHeaderHeading={`Enquiry For : ${
          isShowRoomStaffAndMobView ? `${enquiryFor}/${itemBrandName}:${itemName}` : enquiryFor
        }`}
        enableEditOption={convertToLowerCase(status) !== convertToLowerCase("closed")}
      >
        <PageAnimate className="h-full">
          {/* basic data information */}
          <SalesEnquiryPopupTap
            subCategory={subCategory}
            customerData={customerData}
            popParentTabs={popParentTabs}
            popParentTabsHandle={popParentTabsHandle}
            showImeiTrans={cusTransHistory.length >= 1 ? true : false}
          />

          <div
            className={`${
              columnView ? "flex h-full items-start flex-wrap lg:flex-nowrap" : ""
            } md:px-4 overflow-y-auto`}
            style={{ maxHeight: "calc(100% - 48px)" }}
          >
            <div
              className={` ${!activeModal ? "py-5" : "py-1 lg:my-2"} ${
                columnView ? " w-full lg:w-7/12" : " "
              } flex-wrap flex bg-gray-100"`}
            >
              <div
                className="group text-sm mr-2 bg-green-500 hover:bg-green-600 font-bold py-1 px-2 text-white rounded inline-flex items-center"
                style={{ width: "12em" }}
              >
                Ticket Id: &nbsp; {customerData?.ticketId || ""}
              </div>
              {((popParentTabs !== "EnquiryInfo" && popParentTabs !== "Product") || !isShowRoomStaffAndMobView) && (
                <CommonDropHeading
                  customClass={bg_BasicInfo}
                  id="BasicInfo"
                  heading="Basic Info"
                  tabActiveDefault={popParentTabs === "BasicInfo" ? true : false}
                  {...(enableBasicHistory && {
                    additionalELe: (
                      <Ripples
                        className="w-6 h-6 ml-2 rounded flex items-center justify-center
                 cursor-pointer hover:shadow-md flex-shrink-0 transition-all duration-150 text-white bg-blue-500 hover:bg-blue-600"
                      >
                        <button onClick={() => handleHistoryTable(true)} className=" w-5 h-5 " type="button">
                          <TableIcon />
                        </button>
                      </Ripples>
                    )
                  })}
                >
                  <BasicInfoView
                    isSalesEnquiry={true}
                    customerData={customerData}
                    enableViewMode={enableViewMode}
                    handleEditView={handleEditView}
                  />
                </CommonDropHeading>
              )}
              {getshowHistory ? (
                <div className=" flex items-center fixed justify-center w-screen top-0 left-0 z-20 bg-black bg-opacity-20 h-screen">
                  <OutsideClickHandler onOutsideClick={() => handleHistoryTable(false)}>
                    <div
                      style={{ minHeight: "320px" }}
                      className=" w-full mx-auto h-auto bg-white relative overflow-auto"
                    >
                      <h2 className="bg-white flex justify-between items-center p-2 font-bold text-sm">
                        <span>Updated History</span>
                        <button
                          className="w-5 h-5 flex justify-center items-center rounded-full text-red-500 hover:text-red-600 duration-150 ease-in-out"
                          onClick={() => handleHistoryTable(false)}
                          type="button"
                        >
                          <XCircleIcon />
                        </button>
                      </h2>
                      <ActionHistory customerId={customerData.customerId} />
                    </div>
                  </OutsideClickHandler>
                </div>
              ) : null}
              {((popParentTabs !== "EnquiryInfo" && popParentTabs !== "Product") || !isShowRoomStaffAndMobView) && (
                <CommonDropHeading
                  customClass={bg_EnquiryInfo}
                  id="EnquiryInfo"
                  heading={"Enquiry Info"}
                  tabActiveDefault={popParentTabs === "EnquiryInfo" ? true : false}
                >
                  {/* enquire information */}
                  <SalesCustomerBasicInfo
                    status={status}
                    editView={editView}
                    closePopup={closePopup}
                    passProduct={getProduct}
                    customerData={customerData}
                    handleEditView={handleEditView}
                    enableViewMode={enableViewMode}
                    getEnquiryInformation={getEnquiryInformation}
                  />
                </CommonDropHeading>
              )}

              <div id="LeadClassificationHistory" className={`w-full flex transition flex-wrap md:mb-4 h-auto `}>
                <CommonTable
                  setShow={true}
                  parentClass={"w-full"}
                  customClass={bg_EnquiryStatusHistory}
                  heading={"Lead Classification History"}
                >
                  <EnquiryLeadClassificationData transactions={leadClassificationHistory} />
                </CommonTable>
              </div>

              {mailId && (
                <CommonDropHeading
                  id="EnquiryFromMail"
                  heading="Enquiry From Mail"
                  customClass={bg_EnquiryFromMail}
                  tabActiveDefault={popParentTabs === "EnquiryFromMail" ? true : false}
                >
                  <EnquiryFromMail customerData={customerData} selectedMailData={selectedMailData} />
                </CommonDropHeading>
              )}

              {cusTransHistory.length >= 1 && (
                <CommonTable
                  id="IMEIandMobile"
                  setShow={true}
                  enableModal={3}
                  parentClass={"w-full"}
                  columnView={columnView}
                  customClass={bg_IMEIandMobile}
                  heading={"IMEI and Mobile Enquiry History"}
                  hideExpansionMob={isShowRoomStaffAndMobView}
                >
                  <EnquiryDetailsTable getModalStatus={true} modalFullscreen={true} cusTransHistory={cusTransHistory} />
                </CommonTable>
              )}
              {(popParentTabs !== "EnquiryInfo" || !isShowRoomStaffAndMobView) && (
                <CommonTable
                  setShow={true}
                  enableModal={3}
                  columnView={true}
                  parentClass={"w-full"}
                  id="Product"
                  customClass={bg_ProductRelatedTable}
                  heading={`Product Related History - Item Code : ${customerData.itemCode}`}
                >
                  <ProductRelatedTable
                    apxProduct={apxProduct}
                    columnView={columnView}
                    customerData={customerData}
                    productDetails={productDetails}
                  />
                </CommonTable>
              )}
            </div>

            <div
              className={`${columnView ? "w-full lg:w-5/12 " : " w-full lg:pt-4"} bg-white flex flex-col items-center`}
            >
              {convertToLowerCase(enquiryFor) === convertToLowerCase("Sales") && (
                <>
                  <div id="EnquiryStatusHistory" className={`w-full flex transition flex-wrap md:mb-4 h-auto `}>
                    <CommonTable
                      setShow={true}
                      parentClass={"w-full"}
                      customClass={bg_EnquiryStatusHistory}
                      heading={"Enquiry Sales Status History"}
                    >
                      <EnquiryStatusTrackingData
                        columnView={columnView}
                        customerData={customerData}
                        transactions={transactions}
                        showAssign
                        isShowRoomStaffAndMobView={isShowRoomStaffAndMobView}
                      />
                    </CommonTable>
                  </div>
                  {(popParentTabs !== "EnquiryInfo" || !isShowRoomStaffAndMobView) && (
                    <div id="MessageDeliveryHistory" className={`w-full flex transition flex-wrap mb-4 h-auto `}>
                      <CommonTable
                        setShow={true}
                        parentClass={"w-full"}
                        customClass={bg_EnquiryStatusHistory}
                        heading={"Message Delivery History"}
                        hideExpansionMob={isShowRoomStaffAndMobView}
                      >
                        <MessageDeliveryHistory customerData={customerData} />
                      </CommonTable>
                    </div>
                  )}
                  <div id="SalesStatusUpdate" className={`w-full flex transition flex-wrap mb-4 h-auto `}>
                    <CommonTable
                      setShow={true}
                      parentClass={"w-full"}
                      customClass={bg_EnquiryStatusUpdate}
                      heading={"Enquiry Sales Status Update"}
                    >
                      <div className={`flex flex-wrap w-full`}>
                        <SalesStatusUpdate
                          closePopup={closePopup}
                          columnView={columnView}
                          customerData={customerData}
                          selectedMailData={selectedMailData}
                          handleChange={handleOnStatusChange}
                          isShowRoomStaffAndMobView={isShowRoomStaffAndMobView}
                        />
                      </div>
                    </CommonTable>
                  </div>
                </>
              )}
            </div>
            <div id="ModalEnquiryForPopup" />
          </div>
        </PageAnimate>
      </FixedPopupTemplate>
    </React.Fragment>
  );
};

export default SalesEnquiryStatusUpdate;
