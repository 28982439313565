import { Decrypt } from "../secureFile";
import { Get, Patch, Post } from "ApiProcess/httpRestServices";
import { failToast } from "Component/ToastServices/ToastDisplay";
import { nullToObject } from "../validation";
import { crmApiBaseUrl, encodeGetParams, loginApi } from "../../ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * login passWord and userName pass
 */

export const ssonloginService = (obj: any) => {
  const { data: { token = "", user = {}, isStore = undefined } = {} } = nullToObject(obj);
  const header: any = {
    "Content-Type": "application/json",
    authorization: `Keyclaok ${token}`,
    ...(isStore !== undefined && { X_IS_STORE: isStore })
  };
  return Post(`${crmApiBaseUrl}${loginApi.ssonPage}`, { user }, "NoToken", header);
};

/**
 * get register user details
 */
export const getRegisterUserDetailService = (obj: any = {}) => {
  const {
    data: {
      page = 1,
      size = 10,
      search = "",
      branch = [],
      role = [],
      allowedRadius = "",
      pricingDetails = "",
      excludeLocation = "",
      ignoreCurrentUserCheck = "",
      checkAllowedEnquires = ""
    } = {}
  } = nullToObject(obj);

  const newObj = {
    page,
    size,
    search,
    branch,
    role: role,
    allowedRadius,
    pricingDetails,
    excludeLocation,
    ignoreCurrentUserCheck: ignoreCurrentUserCheck,
    checkAllowedEnquires: checkAllowedEnquires
  };

  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${loginApi.getRegisterUser}${consvertString}`, "crmToken");
};

/**
 * register status changes
 */
export const registerUserStatusChangesServices = (obj: any = {}) => {
  const { data: { id = "", status = false, role = "" } = {} } = nullToObject(obj);
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { role: loginRole = "" } = {} } = nullToObject(userLoginDetails);

  if (role === "SUPER_ADMIN") {
    if (loginRole === "SUPER_ADMIN") {
      const params = {
        status: !status
      };
      return Patch(`${crmApiBaseUrl}${loginApi.registerSatusChange}${id}`, params, "crmToken");
    } else {
      failToast("need super admin access");
    }
  } else {
    const params = {
      status: !status
    };
    return Patch(`${crmApiBaseUrl}${loginApi.registerSatusChange}${id}`, params, "crmToken");
  }
};

/**
 * regiter status changes
 */
export const getRegisteredUserByIdService = (obj: any = {}) => {
  const { id = "" } = nullToObject(obj);
  return Get(`${crmApiBaseUrl}${loginApi.getRegisteredUser}${id}`, "crmToken");
};
