import "./PercenatgeView.css";
import React, { useEffect, useState } from "react";
import ScoreCardForm from "Pages/QualityScorecard/ScoreCardForm";

const PercenatgeView = (props: any) => {
  const { targetValue = 80, scoreVlaue = 0, excellent = 95, worst = 50, scoreCardId = "" } = props;

  const [bgcolor, setBgColor] = useState("yellow");
  const [openForm, setOpenFOrm] = useState(false);

  /**handle close */
  const handleClose = (state: any = false) => {
    setOpenFOrm(state);
  };

  useEffect(() => {
    if (scoreVlaue >= excellent) {
      setBgColor("blue");
    } else if (scoreVlaue >= targetValue && scoreVlaue < excellent) {
      setBgColor("green");
    } else if (worst < scoreVlaue) {
      setBgColor("yellow");
    } else {
      setBgColor("red");
    }
  }, [scoreVlaue]);

  return (
    <React.Fragment>
      <div className="single-chart w-20 h-20" onClick={() => handleClose(true)}>
        <svg viewBox="0 0 36 36" className={`circular-chart`}>
          <path
            className={`circle-bg text-${bgcolor}-300`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <path
            className={`circle text-${bgcolor}-600`}
            stroke={"currentColor"}
            strokeDasharray={`${scoreVlaue}, 100`}
            d="M18 2.0845
                            a 15.9155 15.9155 0 0 1 0 31.831
                            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" fill="currentColor" className={`percentage text-${bgcolor}-400 font-bold`}>
            {scoreVlaue}%
          </text>
        </svg>
      </div>
      {openForm && <ScoreCardForm scoreId={scoreCardId} handleClose={handleClose} />}
    </React.Fragment>
  );
};

export default PercenatgeView;
