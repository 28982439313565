import React, { useState } from "react";
import { _get } from "utils/lodashConst";
import { Transition } from "@headlessui/react";
import { CommonTableSortDropdownProps } from "./types";
import OutsideClickHandler from "react-outside-click-handler";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { convertToLowerCase, nullToArray, nullToObject } from "utils/validation";
import CommonCheck from "Component/Form/CommonCheck";
import CommonDropDownInput from "./CommonDropDownInput";

const MultiSelectDropdown = (props: CommonTableSortDropdownProps) => {
  const {
    name = "",
    arrayData = [],
    disabled = false,
    parentClass = "",
    noSelected = false,
    inputFieldClass = "",
    customWidthClass = "",
    enableCheckbox = false,
    enableDropDown = false,
    selectAllToggle = false, //is select all toggle
    bindKeyName = "itemName",
    isEnableSelectAll = false, //is enable select all check box
    handleOnchange = () => {},
    selectAllHandle = () => {},
    handleDropDownClick = () => {}
  } = nullToObject(props);

  const [searchOptionValue, setSearchOptionValue] = useState<any>("");

  const allLengthCheck =
    _get(arrayData, "length", 0) > 0
      ? arrayData
          .map((ele: any) => {
            if (_get(ele, "isCheck", false) === true) {
              return ele[bindKeyName];
            }
            return undefined;
          })
          .filter((ele: any) => ele !== undefined)
      : "All";

  let defaultData = _get(allLengthCheck, "length", 0) > 0 ? allLengthCheck : noSelected ? "No Selected" : "All";
  const activeColorHandle = defaultData === "" || defaultData === "All" ? false : true;

  /**
   * dropdown open and close handle
   * @param event
   */
  const handleMoreDrop = (event: any = {}) => {
    event.preventDefault && event.preventDefault();

    if (enableDropDown) {
      //alreay drop dwon is open it call to close dropdown
      handleDropDownClick(name, false);
    } else {
      //open drop down
      handleDropDownClick(name, true);
    }
  };

  /**
   * out side clik hanlde
   */
  const handleOutSideClick = () => {
    defaultData = ""; //empty
    setSearchOptionValue("");
    if (enableDropDown) {
      handleDropDownClick(name, false);
    }
  };

  /**
   * clear search dropDown
   */
  const clearSearch = (event: any = {}) => {
    const newObj = {
      ...event,
      target: {
        name: "clear",
        value: {}
      }
    };
    defaultData = ""; //empty
    setSearchOptionValue("");
    handleOnchange(newObj);
  };

  const makeEventData = (nameData = "", value = {}, event: any = {}, selectAll = false) => {
    const newObj = {
      ...event,
      target: {
        name: nameData,
        value: value,
        selectAllToggle: selectAll
      }
    };

    handleOnchange(newObj);
    defaultData = ""; //empty
    setSearchOptionValue("");
    if (!enableCheckbox) {
      // not check box enable close called
      handleOutSideClick();
    }
  };

  /**
   * dropdown auto complete search
   * @param event
   */
  const handleSearchfilter = (event: any = {}) => {
    const { target: { value = "" } = {} } = event;
    setSearchOptionValue(value);
    const div: any = document && document.getElementById(`drop-${name}`);
    const a: any = div?.querySelectorAll(".option");
    for (let i = 0; i < a?.length; i++) {
      const txtValue = a[i]?.textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(value?.toUpperCase()) > -1) {
        a[i].parentNode.style.display = "";
      } else {
        a[i].parentNode.style.display = "none";
      }
    }
  };

  const handleClearOptionSearch = () => {
    handleSearchfilter({});
  };

  return (
    <OutsideClickHandler onOutsideClick={handleOutSideClick}>
      <div className={` ${parentClass ? parentClass : " inline-block "} tableOption relative`}>
        <div className={`tabledrop ${customWidthClass ? customWidthClass : "md:w-40"} relative inline-block`}>
          <form autoComplete="new-password">
            <input
              type="text"
              id={name}
              readOnly={true}
              disabled={disabled}
              autoComplete="new-password"
              onClick={handleMoreDrop}
              value={defaultData ? defaultData : "All"}
              className={` ${inputFieldClass ? inputFieldClass : " h-8 "}  ${activeColorHandle ? " border-green-500 " : ""} border-gray-300 border
                            sort block rounded input-common pl-3 text-sm  pr-7 py-1 overflow-hidden overflow-ellipsis cursor-pointer`}
            />
          </form>

          {!activeColorHandle ? (
            <button
              type="button"
              onClick={handleMoreDrop}
              data-jest-id={"jestHandleMoreDrop"}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 flex justify-center items-center"
            >
              <label htmlFor={name}>
                <ChevronDownIcon
                  className={`${enableDropDown ? " transform rotate-180" : ""} w-4 h-4 text-gray-500 cursor-pointer`}
                />
              </label>
            </button>
          ) : (
            <button
              type="button"
              onClick={disabled ? () => {} : clearSearch}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 flex justify-center items-center"
            >
              <XIcon className="w-4 h-4 text-gray-500 hover:text-red-500 cursor-pointer" />
            </button>
          )}
        </div>

        <Transition
          as={React.Fragment}
          show={enableDropDown}
          leave="transition ease-in duration-75"
          leaveTo="transform opacity-0 scale-95"
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leaveFrom="transform opacity-100 scale-100"
        >
          <div
            className={`dropdown bg-white z-10 absolute  left-0 right-0 
                            input-valid top-full -mt-1 rounded-b`}
          >
            {nullToArray(arrayData)?.length > 5 && (
              <CommonDropDownInput
                autoFocus={true}
                name={name}
                value={searchOptionValue}
                handleSearchfilter={handleSearchfilter}
                handleClearOptionSearch={handleClearOptionSearch}
              />
            )}
            <ul id={`drop-${name}`} className="h-full overflow-x-hidden overflow-y-auto max-h-60">
              {isEnableSelectAll && enableCheckbox && (
                <li className="bg-yellow-50">
                  <label
                    data-jest-id={"jestSelectAllHandle"}
                    className=" flex items-center px-4 py-2 w-full text-left cursor-pointer text-red-500"
                    onClick={(e) => selectAllHandle(!selectAllToggle, name, e)}
                  >
                    <CommonCheck
                      label={false}
                      parentClass={"mr-2 red"}
                      checked={selectAllToggle}
                      id={convertToLowerCase(name + "-tets")}
                    />
                    <span className="option">{"Select all"}</span>
                  </label>
                </li>
              )}

              {nullToArray(arrayData).map((item: any, i: number) => {
                if (item.exclude) return null;
                return (
                  <React.Fragment key={convertToLowerCase(i + "dropProps-dgd")}>
                    <li
                      data-jest-id={"jestMakeEventData"}
                      onClick={(e) => makeEventData(name, item, e, !selectAllToggle)}
                    >
                      <label
                        title={item[bindKeyName]}
                        htmlFor={"check-" + name + "-" + i}
                        className=" flex items-center px-4 py-2 w-full text-left cursor-pointer"
                      >
                        {enableCheckbox && (
                          <CommonCheck
                            label={false}
                            parentClass={"mr-2"}
                            id={convertToLowerCase(name + "-" + i)}
                            checked={_get(item, "isCheck", false)}
                          />
                        )}
                        <span className="option">{item[bindKeyName]}</span>
                      </label>
                    </li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </Transition>
      </div>
    </OutsideClickHandler>
  );
};

export default MultiSelectDropdown;
