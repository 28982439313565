import { facetBy } from "./FaceCetTypesHandle";
import { _get, _flattenDeep } from "utils/lodashConst";
import { Get, Post, Put, withoutTokenGet } from "ApiProcess/httpRestServices";
import { DateTimeFormeterEnd, DateTimeFormeterStart, nullToObject } from "utils/validation";
import {
  getProdcutIncentiveList,
  salesProduct,
  crmApiBaseUrl,
  encodeGetParams,
  typesenceBasePath,
  offerProjectEndPath,
  pimNewApi,
  crmSalesIncentiveUrl,
  printQRApi
} from "ApiProcess/httpRestServices/apiManage";

// const facetBy = `categories.lvl0,categories.lvl1,categories.lvl2,categories.lvl3,categories.lvl4,brands,ram,stock_status,color,primary_camera,screen_size,os_version,processor`;

/**
 * search product
 */

export const itemCodeBasedProduct = async (itemCode = "") => {
  const reqBody = {
    searches: [
      {
        page: 1,
        q: itemCode,
        per_page: 20,
        query_by: "item_code",
        collection: "productsnewshop",
        sort_by: "_text_match:desc",
        filter_by: `stock_status:["In Stock","Out of Stock"] && variant_status:=1 && sellable:=1`
      }
    ]
  };
  return await Post(`${typesenceBasePath}${salesProduct.product}`, reqBody, "typesenseDetailsToken");
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const salesProductSearchHeaderSearch = async (value = "", page = 1) => {
  const newObj = {
    searches: [
      {
        q: value,
        page: page,
        per_page: 20,
        collection: "productsnewshop",
        query_by_weights: "4,3,2,1",
        sort_by: "_text_match:desc",
        query_by: "name,categories,item_code,sku",
        catagories: `categories:["Mobiles & Accessories"]`,
        filter_by: `stock_status:["In Stock","Out of Stock"] && variant_status:=1 && sellable:=1`
      }
    ]
  };
  return await Post(`${typesenceBasePath}${salesProduct.product}`, newObj, "typesenseDetailsToken");
};

export const fetchAddonsDataApi = (item_code = "") => {
  const newObjHeader = {
    locale: "en-IN",
    channel: "SHOP"
  };
  return withoutTokenGet(`${pimNewApi}${salesProduct.productDeatails}${item_code}/addons`, newObjHeader); //NoToken
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const salesSearchProductSearch = async (value = "") => {
  const newObj = {
    searches: [
      {
        q: value,
        page: 1,
        per_page: 20,
        collection: "productsnewshop",
        query_by_weights: "4,3,2,1",
        sort_by: "_text_match:desc",
        query_by: "name,categories,item_code,sku",
        filter_by: `stock_status:["In Stock","Out of Stock"] && variant_status:=1 && sellable:=1`
      }
    ]
  };
  return await Post(`${typesenceBasePath}${salesProduct.product}`, newObj, "typesenseDetailsToken");
};

/**
 * search product pim
 */

export const getProductDetails = (ele: any = {}) => {
  const { item_code = "", channel = "SHOP" } = ele;
  const newObjHeader = {
    locale: "en-IN",
    channel: channel
  };

  return withoutTokenGet(`${pimNewApi}${salesProduct.productDeatails}${item_code}`, newObjHeader);
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const salesgetOffers = async (filter = {}, erp_item_code = "", channel = "") => {
  return await Post(`${pimNewApi}${offerProjectEndPath.filterOffer}/${erp_item_code}`, { ...filter }, "offerToken", {
    channel
  });
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const salesGetEmi = async (erp_item_code = "", channel = "") => {
  return await Get(`${pimNewApi}${offerProjectEndPath.emiOffer}${erp_item_code}`, "offerToken", { channel });
};

/**
 * @param value search value pass
 * @param page pagination
 */
export const salesGetFilterOption = async (erp_item_code = "", channel = "") => {
  return await Get(`${pimNewApi}${offerProjectEndPath.filterOptionOffer}/${erp_item_code}`, "offerToken", { channel });
};

/**
 * category list
 * @param value search value
 * @param page pagination num
 */
export const salesProductCategoryList = async (
  value = "",
  page = 1,
  arrayFilter = [],
  sortBy = "",
  searchAmount: any = {},
  fromSearch = ""
) => {
  // price search
  const { minAmount = 1, mxAmount = 699999 } = searchAmount;
  let valueCons = "";

  const buildNewArray = arrayFilter
    .map((eles: any) => {
      const ele = nullToObject(eles);
      ele["getSearch"] = _get(ele, "counts", []).some((someFind: any) => someFind.isCheck === true);
      ele["searchBrand"] = ele.counts
        .map((element: any) => {
          if (element.isCheck === true) {
            element.brandName = ele.field_name;
            return element;
          }
          return undefined;
        })
        .filter((removeUndefined: any) => removeUndefined !== undefined);
      return ele;
    })
    .filter((getTrue: any) => getTrue.getSearch === true);

  const nex = _flattenDeep(buildNewArray);
  nex.map((ele: any) => {
    const { field_name = "" } = ele;
    if (field_name === "categories 1" || field_name === "categories 2") {
      ele.field_name = "categories";
    }

    valueCons = valueCons === "" ? `${ele.field_name + ":="}` : `${valueCons} && ${ele.field_name + ":="}`;
    ele.search = ele.searchBrand.map((elem: any) => elem.value);
    valueCons = `${valueCons}${JSON.stringify(ele.search)}`;
    return ele;
  });
  const newObj = {
    searches: [
      {
        page,
        per_page: 20,
        facet_by: facetBy,
        sort_by: `${sortBy}`,
        max_facet_values: 70,
        q: value ? value : "*",
        collection: "productsnewshop",
        query_by_weights: "4,3,2,1",
        query_by: "name,categories,item_code,sku",
        filter_by: `variant_status:=1 && sellable:=1 && special_price:=[${minAmount}..${mxAmount}]${
          valueCons ? " && " + valueCons : ""
        }`
      }
    ]
  };
  return await Post(`${typesenceBasePath}${salesProduct.product}`, newObj, "typesenseDetailsToken");
};

/**
 * facet value get
 */
export const salesProductFacetBy = async () => {
  const newObj = {
    searches: [
      {
        q: "",
        page: 1,
        per_page: 20,
        max_facet_values: 70,
        collection: "productsnewshop",
        query_by_weights: "4,3,2,1",
        facet_by: `${facetBy}`,
        sort_by: "review_count:desc",
        query_by: "name,categories,item_code,sku"
      }
    ]
  };
  return await Post(`${typesenceBasePath}${salesProduct.product}`, newObj, "typesenseDetailsToken");
};

/**
 * advanced search category list get
 */
export const getShopBycategoryDetails = () => {
  return Get(`${pimNewApi}${salesProduct.shopByCategory}`, "poorvikaProductDetails");
};

/**
 * reset filter
 */
export const resetCategoryFilter = (array = []) => {
  return array.map((ele: any, i: number) => {
    ele.counts = _get(ele, "counts", []).map((element: any) => {
      return Object.assign(element, { isCheck: false });
    });
    if (i === 0) {
      ele.isSelect = true;
    } else {
      ele.isSelect = false;
    }
    return ele;
  });
};

/**
 * get sales list
 * @param searchParams object
 */
export const getSalesTableDataList = (searchParams: any = {}) => {
  const { callType = null, endDate = null, startDate = null, minDate, maxDate, followUpDate = {} } = searchParams;
  const payload: any = {};
  if (endDate === null && startDate === null) {
    payload.startDate = minDate ? DateTimeFormeterStart(minDate) : "";
    payload.endDate = maxDate ? DateTimeFormeterEnd(maxDate) : "";
  } else {
    payload.endDate = endDate ? DateTimeFormeterEnd(endDate) : "";
    payload.startDate = startDate ? DateTimeFormeterStart(startDate) : "";
  }

  const newObj = {
    ...searchParams,
    callType: callType !== null ? callType : "",
    ...payload,
    followUpDate: followUpDate.startDate
      ? {
          startDate: DateTimeFormeterStart(followUpDate.startDate),
          endDate: DateTimeFormeterEnd(followUpDate.endDate || followUpDate.startDate)
        }
      : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${salesProduct.salesEndPoint}?${consvertString}`, "crmToken");
};

export const uploadSalesTableDataList = (searchParams: any = {}) => {
  const {
    callType = null,
    endDate = null,
    startDate = null,
    followUpDate = {},
    minDate = null,
    maxDate = null
  } = searchParams;
  const newObj = {
    ...searchParams,
    callType: callType !== null ? callType : "",
    endDate:
      endDate || maxDate || startDate || minDate ? DateTimeFormeterEnd(endDate || maxDate || startDate || minDate) : "",
    startDate: startDate || minDate ? DateTimeFormeterStart(startDate || minDate) : "",
    ...(followUpDate.startDate && {
      followUpDate: {
        startDate: DateTimeFormeterStart(followUpDate.startDate),
        endDate: DateTimeFormeterEnd(followUpDate.endDate || followUpDate.startDate)
      }
    })
  };
  const consvertString = encodeGetParams(newObj);
  return Post(`${crmApiBaseUrl}${salesProduct.uploadSalesEndPoint}?${consvertString}`, {}, "crmToken");
};

const handleCommonArray = (arrayData = []) => {
  return arrayData
    .map((ele: any) => {
      if (_get(ele, "isCheck", false) === true) {
        return _get(ele, "itemName", undefined);
      }
      return undefined;
    })
    .filter((ele: any) => ele !== undefined);
};
/**
 * get sales list
 * @param searchParams object
 */
export const getSalesUniqueData = (column: string, apxArr: any, brandArr: any) => {
  const brandCheck = handleCommonArray(brandArr);

  const apxCheck = handleCommonArray(apxArr);

  const consvertString = encodeGetParams({
    column,
    selectedApx: _get(apxCheck, "length", 0) > 0 ? apxCheck : "",
    selectedBrand: _get(brandCheck, "length", 0) > 0 ? brandCheck : ""
  });

  return Get(`${crmApiBaseUrl}${salesProduct.salesUniquesData}?${consvertString}`, "crmToken");
};

/**
 * update sales update
 * @param updateParams
 */
export const statusUpdateSalesApiCall = (updateParams: any = {}) => {
  const { customerId = "", ...restOperatorData } = updateParams;
  const consvertString = encodeGetParams(restOperatorData);
  return Put(`${crmApiBaseUrl}${salesProduct.salesEndPoint}/${customerId}?${consvertString}`, {}, "crmToken");
};

/**
 * sales update and Source list api call
 */
export const getsalesSourceList = () => {
  return withoutTokenGet(`${crmApiBaseUrl}${salesProduct.salesSource}`);
};

/**
 * sales update and Status list api call
 */
export const getsalesStatusList = () => {
  return withoutTokenGet(`${crmApiBaseUrl}${salesProduct.salesStatus}`);
};

/**
 * global product list search
 */
export const searchProductApiCall = (value = "") => {
  return salesProductSearchHeaderSearch(value) || {}; //api call;
};

/**
 * get Product Detail from PIM
 */
export const getOfferEmiApiCall = (item_code = "", channel = "") => {
  return salesGetEmi(item_code, channel) || {}; //api call;
};

/**
 * get Product Detail from PIM
 */
export const getFilterOptionsApiCall = (item_code = "", channel = "") => {
  return salesGetFilterOption(item_code, channel) || {}; //api call;
};

/**
 * get Product Detail from PIM
 */
export const getFilteredOffersApiCall = (filter = {}, item_code = "", channel = "") => {
  return salesgetOffers(filter, item_code, channel) || {}; //api call;
};

/**
 * sales sms send
 */
export const salesDetailsSmsSend = (bodyData = {}) => {
  return Post(`${crmApiBaseUrl}${salesProduct.productDetailSms}`, bodyData, "crmToken");
};

/**
 * sales sms send
 */
export const salesDetailsWhatsappSend = (bodyData = {}) => {
  return Post(`${crmApiBaseUrl}${salesProduct.productDetailWhatsapp}`, bodyData, "crmToken");
};

/**
 * Incentive against a product
 */
export const getProductIncentive = (payload) => {
  return Post(
    `${crmSalesIncentiveUrl}${getProdcutIncentiveList.getProductIncentive}?isToken=1&isWeb=1`,
    payload,
    "crmSalesToken"
  );
};

/**
 * QR pint list details
 */
export const fetchQRCodePrintDetails = (payload) => {
  const consvertString = encodeGetParams(payload);
  return Get(`${crmApiBaseUrl}${printQRApi.qrPrintdetails}?${consvertString}`, "crmToken");
};

/**
 * Post QR pint details
 */
export const postQRCodePrintDetails = (printInfo) => {
  const {
    itemCode = "",
    SkuCode = "",
    model = "",
    product = "",
    brand = "",
    mrpData = {},
    pricesData = {}
  } = printInfo;
  const payload = {
    itemCode: itemCode,
    productInfo: { sku: SkuCode, model: model, name: product, brand },
    priceInfo: { mrp: mrpData, prices: pricesData },
    actionRequired: false,
    status: true
  };
  return Post(`${crmApiBaseUrl}${printQRApi.qrPrintdetails}`, payload, "crmToken");
};

/**
 * Put QR pint details
 */
export const putQRCodePrintDetails = (productQRId, payload) => {
  return Put(`${crmApiBaseUrl}${printQRApi.qrPrintdetails}/${productQRId}`, payload, "crmToken");
};
