import { _get } from "utils/lodashConst";

type basicInfoTypes = {
  cusListData?: any;
  moveToEnqirePage?: any;
  customerEnquiries?: any;
  enquiryBasicInfo?: object;
  customerTransactions?: any;
  basicInfoUserinfoData?: any;
  basicInfoUserFind?: boolean;
  cusListDataLoader?: boolean;
  userInfoPinData?: Array<any>;
  basicInfoUserInformation?: any;
  basicInfoLocateLoader?: boolean;
  basicInfoUpdateLoader?: boolean;
  userInfoPinCodeLoader?: boolean;
  basicInfoUpdateResetData?: boolean;
  updateEnquiryBasicInfoSuccess?: boolean;
};

export const basicInfoTypesState = {
  cusListData: {},
  userInfoPinData: [],
  enquiryBasicInfo: {},
  customerEnquiries: {},
  moveToEnqirePage: null,
  cusListDataLoader: false,
  customerTransactions: [],
  basicInfoUserFind: false,
  basicInfoUserinfoData: {},
  basicInfoUpdateLoader: false,
  basicInfoLocateLoader: false,
  userInfoPinCodeLoader: false,
  basicInfoUserInformation: null,
  basicInfoUpdateResetData: false,
  updateEnquiryBasicInfoSuccess: false
};

export const basicInfoReducer = (state = basicInfoTypesState, action: basicInfoTypes = {}) => {
  switch (_get(action, "type")) {
    //when page enter all reducer value clear
    case "BASIC_INITIAL_STAGE": {
      return {
        ...state,
        ...basicInfoTypesState
      };
    }

    case "BASIC_IFO_UPDATE_LOADER": {
      //loader
      return {
        ...state,
        basicInfoUpdateLoader: _get(action, "basicInfoUpdateLoader", false),
        basicInfoUpdateResetData: _get(action, "basicInfoUpdateResetData", false)
      };
    }

    case "ENQUIRY_BASIC_IFO_UPDATE_SUCCESS": {
      //loader
      return {
        ...state,
        updateEnquiryBasicInfoSuccess: _get(action, "successCode", false)
      };
    }

    case "BASIC_INFO_DATA_RESET": {
      return {
        ...state,
        basicInfoUpdateResetData: true
      };
    }

    case "MOVE_TO_ENQUIRE_PAGE": {
      //loader MOVE_TO_ENQUIRE_PAGE
      return {
        ...state,
        moveToEnqirePage: action.moveToEnqirePage
      };
    }

    case "BASIC_IFO_LOCATE_LOADER": {
      //loader
      return {
        ...state,
        basicInfoLocateLoader: action.basicInfoLocateLoader
      };
    }

    case "BASIC_IFO_USER_FIND_LOADER": {
      //loader
      return {
        ...state,
        basicInfoUserFind: action.basicInfoUserFind
      };
    }

    case "BASIC_IFO_USER_FIND_INFORMATION": {
      return {
        ...state,
        basicInfoUserInformation: action.basicInfoUserInformation
      };
    }

    case "ENQUIRY_BASIC_INFO": {
      return {
        ...state,
        enquiryBasicInfo: _get(action, "enquiryBasicInfo", {})
      };
    }

    case "BASIC_IFO_USER_FIND_DATA_STORE": {
      return {
        ...state,
        basicInfoUserinfoData: action.basicInfoUserinfoData,
        mergeRequestData: {}
      };
    }

    case "BASIC_IFO_USER_FIND_DATA_EMAIL": {
      const { basicInfoUserinfoData = {} } = state;
      const customerId = _get(basicInfoUserinfoData, "customerId", "");
      const emailData: any = _get(action, "basicInfoUserinfoEmail", {});
      const { customerId: emailCustomerId = "" } = emailData;
      if (customerId && emailCustomerId && customerId !== emailCustomerId) {
        return {
          ...state,
          // basicInfoUserinfoEmail: null,
          mergeRequestData: {
            emailCusId: emailCustomerId,
            mobieCusId: customerId
          }
        };
      }
      return {
        ...state,
        basicInfoUserinfoData: emailData,
        basicInfoUserInformation: emailData,
        mergeRequestData: {}
      };
    }
    case "MERGE_REQUEST_NEW_CUSTOMER_DATA": {
      return {
        ...state,
        basicInfoUserinfoData: _get(action, "mergeRequestData", {}),
        mergeRequestData: {}
        // basicInfoUserinfoEmail: null,
      };
    }

    case "BASIC_IFO_ENQUIRES_BY_CUSTOMER_DATA": {
      return {
        ...state,
        customerEnquiries: action.customerEnquiries
      };
    }

    case "BASIC_IFO_TRANS_BY_CUSTOMER_DATA": {
      return {
        ...state,
        customerTransactions: action.customerTransactions
      };
    }

    case "BASIC_IFO_PINCODE_LOADER_DATA": {
      return {
        ...state,
        userInfoPinData: action.userInfoPinData
      };
    }

    case "BASIC_IFO_PINCODE_LOADER": {
      return {
        ...state,
        userInfoPinCodeLoader: action.userInfoPinCodeLoader
      };
    }

    case "CONTACT_CUSTOMER_LIST_DATA": {
      return {
        ...state,
        cusListData: action.cusListData
      };
    }

    case "CONTACT_CUSTOMER_LIST_DATA_LOADER": {
      return {
        ...state,
        cusListDataLoader: action.cusListDataLoader
      };
    }

    default:
      return {
        ...state
      };
  }
};
