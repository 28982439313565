import { _get } from "utils/lodashConst";

export const ameoInitState = {
  ameyoLoader: false,
  callRecordingData: {},
  callRecordingLoader: false
};

export const ameyoReducer = (state = ameoInitState, action = {}) => {
  switch (_get(action, "type")) {
    case "AMEYO_CALL_LOADER":
      return {
        ...state,
        ameyoLoader: _get(action, "ameyoLoader", false)
      };

    case "GET_CALL_RECORDING_DATA":
      return {
        ...state,
        callRecordingData: _get(action, "callRecordingData", {})
      };

    case "GET_CALL_RECORDING_LOADER":
      return {
        ...state,
        callRecordingLoader: _get(action, "callRecordingLoader", false)
      };

    default:
      return {
        ...state
      };
  }
};
