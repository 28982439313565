import * as cusListTypes from "../actionTypes/cusListTypes";

/**
 * Set customer details
 */
export const setCustomerServiceDetails = (data: object = {}) => {
  return {
    type: cusListTypes.SAVE_SERVICE_DETAILS,
    data
  };
};

/**
 * Update customer details
 */
export const updateServiceDetails = (data: object = {}) => {
  return {
    type: cusListTypes.UPDATE_SERVICE_DETAILS,
    data
  };
};

/**
 * Update customer status
 */
export const updateSupportStatus = (data: object = {}) => {
  return {
    type: cusListTypes.UPDATE_SUPPORT_STATUS,
    data
  };
};

/**
 * Update customer status
 */
export const updateServiceCustomerInfo = (data: object = {}) => {
  return {
    type: cusListTypes.UPDATE_CUSTOMER_INFO,
    data
  };
};

/**
 * Get customer initial data
 */
export const getCustomerInitialData = () => {
  return {
    type: "CUSTOMER_INITIAL_STAGE"
  };
};

/**
 * Set customer details
 */
export const setCustomerStatusActionCall = (data: any) => {
  return {
    type: "SET_CUSTOMER_STATUS",
    data
  };
};

/**
 * Set service center details
 */
export const setCustomerServiceCenterActionCall = (data: any) => {
  return {
    type: "PUSH_SERVICE_CENTER_DETAILS",
    data
  };
};

/**
 * Get employee details
 */
export const createDeliveryChallanAction = (data = {}) => {
  return {
    type: cusListTypes.CREATE_DELIVERY_CHALLAN,
    data
  };
};

/**
 * Get dc derial number details
 */
export const sendServiceCenterSMSAction = (data = {}) => {
  return {
    type: cusListTypes.SEND_SERVICE_CENTER_SMS,
    data
  };
};

/**
 * Send Meassage via Whatsapp
 */
export const sendWhatsappMessage = (data: any) => ({
  type: cusListTypes.REQUEST_TO_SEND_WHATSAPP_MSG,
  data
});

/**
 * Set jobsheet details
 */
export const setCustomerJobsheetActionCall = (data: any) => {
  return {
    type: "PUSH_JOBSHEET_DETAILS",
    data
  };
};
/**
 * send customer service list on email
 */
export const customerServiceEmailSend = (data: any) => {
  return {
    type: cusListTypes.CUSTOMER_SERVICE_EMAIL_SEND,
    data
  };
};

/**
 * send customer service list on email
 */
export const sendGeneralInfoMailAction = (data: any) => {
  return {
    type: cusListTypes.SEND_GENERAL_INFO_MAIL,
    data
  };
};
