import React, { Fragment, useState, useEffect } from "react";
import { _get } from "utils/lodashConst";
import MultipleItems from "./SlickSlider";
import { ImgProductMobile } from "assets/images";
import { headerButton } from "utils/ClassContent";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { nullToObject, paisaToAmount } from "utils/validation";
import { failToast } from "Component/ToastServices/ToastDisplay";
import {
  getAlternateProductDetails,
  getRelatedEnquiryProductApiCall,
  getRelatedProductDetailsCall
} from "../commonSalesProductDetails";
import SalesProductLoader from "../../SalesProductLoader";
import ProductCompare from "../CompareProducts/ProductCompare";
import { getProductDetailsCommon } from "../../LeadHelper";
// const ProductSlider = React.lazy(() => import('./ProductSlider'));
// MultipleItems

const RelatedProductDetailedView = (props: any = {}) => {
  const {
    mobileNo = "",
    fromPage = "",
    pinCode = "",
    globalData = {},
    productDetails = {},
    popParentTabsParent = "",
    setOpenProductModal = () => {},
    handleChooseProduct = () => {}
  } = nullToObject(props);

  const { item_code = "", id = "" } = productDetails;
  const { basicInfoReducer = {} } = globalData;
  const { basicInfoUserinfoData = {} } = basicInfoReducer;

  const { mobile = "" }: any = nullToObject(basicInfoUserinfoData);
  const mobileNumber = fromPage === "viewPage" ? mobileNo : mobile;
  const [similarProduct, setSimilarProduct] = useState([]);
  const [alternateProduct, setAlternateProduct] = useState([]);
  const [relatedEnquiry, setRelatedEnquiry] = useState([]);
  const [relatedEnquiryLoad, setRelatedEnquiryLoad] = useState(false);
  const [tabActive, setTabActive] = useState({
    pTab2: true,
    pTab3: true,
    pTab4: true,
    pTab5: true,
    pTab6: true
  });
  const [popParentTabs, setPopParentTabs] = useState("pTab0");
  const { pTab2, pTab3, pTab4, pTab5, pTab6 } = tabActive;

  const popParentTabsHandle = (value = "") => {
    setPopParentTabs(value);
  };

  // escape function added to close popup
  const escFunction = (event: any = {}) => {
    const { keyCode = 0 } = event;
    if (keyCode === 27) {
      setOpenProductModal(false);
    }
  };

  /**
   * get related product details
   * @param itemCode string
   * @param productId number
   */
  const getRelatedProductDetailsApiCall = async (itemCode = "") => {
    const response = await getRelatedProductDetailsCall(itemCode);
    const { data: { data: { relatedproducts = [] } = {}, status = 0 } = {} } = nullToObject(response);
    if (status === 200) {
      setSimilarProduct(relatedproducts); //update similar product details
    } else {
      setSimilarProduct([]);
    }
  };

  /**
   * get related product details
   * @param itemCode string
   */
  const getAlternateProductDetailsApiCall = async (itemCode = "") => {
    const response = await getAlternateProductDetails(itemCode);
    const {
      data: { data = [], status = 0 }
    } = nullToObject(response);
    if (status === 200) {
      setAlternateProduct(data); //update similar product details
    } else {
      setAlternateProduct([]);
    }
  };

  /**
   * get related product details
   * @param itemCode string
   * @param productId number
   */
  const getRelatedEnquiryProduct = async (mobileNum = "") => {
    setRelatedEnquiryLoad(true); //loader
    const response = (await getRelatedEnquiryProductApiCall(mobileNum)) || {};
    setRelatedEnquiryLoad(false); //loader
    const { data: { message = "", status = 0, data: { relatedProducts = [] } = {} } = {} } = response;
    if (status === 200) {
      setRelatedEnquiry(relatedProducts); //update similar product details
    } else {
      failToast(message);
      setRelatedEnquiry([]);
    }
  };

  const getProductDetailsBySlugIdBased = async (slugId = "") => {
    const resposeData = await getProductDetailsCommon({
      shortcode: "",
      channel: "SHOP",
      item_code: slugId,
      num: 4
    });
    // const { data: { data: resposeData = {} } = {} } = nullToObject(respose);
    const {
      item_code: iCode = "",
      review_count: reviewCount = {},
      rating = 0,
      option_value = "",
      name = "",
      model: { name: modelName = "" } = {},
      id: productId = "",
      mrp: { price = 0 } = {},
      prices: { sp = "" } = {},
      specifications = {},
      availability_stock = ""
    } = resposeData;

    const imgUrl = _get(resposeData, "image.url", "")
      ? _get(resposeData, "image.url", "")
      : _get(resposeData, "variantdetails.image.url", "");
    const offlineSavePrice = price - sp;
    const priceData = price ? price : sp;

    const newObj = {
      name: name,
      slug: slugId,
      model: modelName,
      image: imgUrl,
      price: price,
      rating: rating,
      item_code: iCode,
      product_id: productId,
      special_price: sp,
      review_count: reviewCount,
      option_value: option_value,
      specifications: specifications,
      stock_status: availability_stock,
      special_format: paisaToAmount(sp + "00"),
      price_format: paisaToAmount(priceData + "00"),
      discount: offlineSavePrice ? offlineSavePrice : ""
    };

    const newArrays: any = [...similarProduct];
    newArrays[2] = newObj;
    setSimilarProduct(newArrays);
  };

  /**api/v1/variant
   * get related product details
   */
  useEffect(() => {
    if (item_code) {
      getRelatedProductDetailsApiCall(item_code);
      getAlternateProductDetailsApiCall(item_code);
    }
  }, [item_code, id]);

  useEffect(() => {
    document && document.addEventListener("keydown", escFunction, false);
    return () => {
      document && document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  /**
   * get recent enquiry details
   */
  useEffect(() => {
    getRelatedEnquiryProduct(mobileNumber);
  }, [mobileNumber]);

  useEffect(() => {
    setTimeout(() => {
      setPopParentTabs(popParentTabsParent);
      document.querySelector(`#${popParentTabsParent}`)?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  }, [popParentTabsParent]);

  return (
    <Fragment>
      {/* loader */}
      <SalesProductLoader relatedEnquiryLoad={relatedEnquiryLoad} />

      <div className="absolute bg-black opacity-80 inset-0 z-0 next-w-full" />
      <div className="popup_dataView w-full h-full flex-1 relative z-1 flex flex-col bg-white transform -translate-y-1/2 top-1/2">
        <div className="flex items-center justify-between text-sm bg-primary-gradient px-3 py-2">
          <div className="flex items-center">
            <div className="relative hidden w-8 h-8 mr-3 rounded-full md:block">
              <img
                className="object-cover bg-white w-full h-full rounded-full"
                src={ImgProductMobile}
                alt=""
                loading="lazy"
              />
              <div className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
            </div>
            <div className="">
              <p className="text-sm text-white ">Enquiry For : {"Iphone"}</p>
            </div>
          </div>
          <div className="flex justify-center items-center space-x-2">
            <button className={headerButton} type="button" onClick={() => setOpenProductModal(false)}>
              Close
            </button>
          </div>
        </div>
        {/* Address Popup */}
        <div
          className=" sticky -top-1 bg-white z-20 shadow-md
                     whitespace-nowrap"
        >
          {" "}
          <div className="flex justify-between md:justify-around overflow-x-auto overflow-y-hidden">
            <a
              href="#pTab2"
              onClick={() => popParentTabsHandle("pTab2")}
              className={`${popParentTabs === "pTab2" ? " border-b-2 border-yellow-600 font-bold" : ""}
                        rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                        cursor-pointer`}
            >
              Related Products
            </a>

            <a
              href="#pTab3"
              onClick={() => popParentTabsHandle("pTab3")}
              className={`${popParentTabs === "pTab3" ? " border-b-2 border-yellow-600 font-bold" : ""}
                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                    cursor-pointer`}
            >
              Recent Enquiry
            </a>
            <a
              href="#pTab4"
              onClick={() => popParentTabsHandle("pTab4")}
              className={`${popParentTabs === "pTab4" ? " border-b-2 border-yellow-600 font-bold" : ""}
                        rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                        cursor-pointer`}
            >
              Focus Model
            </a>
            <a
              href="#pTab5"
              onClick={() => popParentTabsHandle("pTab5")}
              className={`${popParentTabs === "pTab5" ? " border-b-2 border-yellow-600 font-bold" : ""}
                        rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                        cursor-pointer`}
            >
              Compare
            </a>
            <a
              href="#pTab6"
              onClick={() => popParentTabsHandle("pTab6")}
              className={`${popParentTabs === "pTab6" ? " border-b-2 border-yellow-600 font-bold" : ""}
                        rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                        cursor-pointer`}
            >
              Alternate Products
            </a>
          </div>
        </div>

        <div className="flex flex-col overflow-y-auto pb-10">
          <div id="pTab2" className="">
            <div className=" w-full mb-3 mx-auto relative ">
              <div className={` rounded-t mb-0  border-0 `}>
                <div
                  onClick={() => setTabActive({ ...tabActive, pTab2: !pTab2 })}
                  className="flex items-center text-sm bg-gray-200 p-3 cursor-pointer"
                >
                  <span
                    className={`inline-block w-5 h-5 mr-1 transition-all
                                        ${pTab2 ? "rotate-0" : "transform -rotate-90"}`}
                  >
                    <ChevronDownIcon className="w-5 h-5" />
                  </span>
                  <h3>Related Products</h3>
                </div>
              </div>
              <div style={{ display: pTab2 ? "" : "none" }} className="contentBody">
                {/* related product */}
                <MultipleItems
                  items={similarProduct}
                  fromPage={"relatedProduct"}
                  handleChooseProduct={handleChooseProduct}
                />
              </div>
            </div>
          </div>
          <div id="pTab3">
            <div className=" w-full mb-3 mx-auto relative ">
              <div className={` rounded-t mb-0  border-0 `}>
                <div
                  onClick={() => setTabActive({ ...tabActive, pTab3: !pTab3 })}
                  className="flex items-center text-sm bg-gray-200 p-3 cursor-pointer"
                >
                  <span
                    className={`inline-block w-5 h-5 mr-1 transition-all
                                        ${pTab3 ? "rotate-0" : "transform -rotate-90"}`}
                  >
                    <ChevronDownIcon className="w-5 h-5" />
                  </span>
                  <h3>Recent Enquiry</h3>
                </div>
              </div>
              <div className={`animateHeight ${pTab3 ? "h-auto" : " activeHeight  overflow-hidden "} flex flex-wrap`} />
              <div style={{ display: pTab3 ? "" : "none" }} className="contentBody">
                {/* relatedEnquiry */}
                <MultipleItems
                  items={relatedEnquiry}
                  fromPage={"relatedEnquiry"}
                  handleChooseProduct={handleChooseProduct}
                />
              </div>
            </div>
          </div>
          <div id="pTab4">
            <div className=" w-full mb-3 mx-auto relative ">
              <div className={` rounded-t mb-0  border-0 `}>
                <div
                  onClick={() => setTabActive({ ...tabActive, pTab4: !pTab4 })}
                  className="flex items-center text-sm bg-gray-200 p-3 cursor-pointer"
                >
                  <span
                    className={`inline-block w-5 h-5 mr-1 transition-all
                                        ${pTab4 ? "rotate-0" : "transform -rotate-90"}`}
                  >
                    <ChevronDownIcon className="w-5 h-5" />
                  </span>
                  <h3>Focus Model</h3>
                </div>
              </div>
              <div style={{ display: pTab4 ? "" : "none" }} className="contentBody">
                {/* focusModel */}
                <MultipleItems
                  items={similarProduct}
                  fromPage={"focusModel"}
                  handleChooseProduct={handleChooseProduct}
                />
              </div>
            </div>
          </div>
          <div id="pTab5">
            <div className=" w-full mb-3 mx-auto relative ">
              <div className={` rounded-t mb-0  border-0 `}>
                <div
                  onClick={() => setTabActive({ ...tabActive, pTab5: !pTab5 })}
                  className="flex items-center text-sm bg-gray-200 p-3 cursor-pointer"
                >
                  <span
                    className={`inline-block w-5 h-5 mr-1 transition-all
                                        ${pTab5 ? "rotate-0" : "transform -rotate-90"}`}
                  >
                    <ChevronDownIcon className="w-5 h-5" />
                  </span>
                  <h3>Compare</h3>
                </div>
              </div>
              <div style={{ display: pTab5 ? "" : "none" }} className="contentBody">
                <ProductCompare
                  pinCode={pinCode}
                  item_code={item_code}
                  productDetails={productDetails}
                  similarProduct={similarProduct}
                  getProductDetailsBySlugIdBased={getProductDetailsBySlugIdBased}
                />
              </div>
            </div>
          </div>
          <div id="pTab6" className="">
            <div className=" w-full mb-3 mx-auto relative ">
              <div className={` rounded-t mb-0  border-0 `}>
                <div
                  onClick={() => setTabActive({ ...tabActive, pTab6: !pTab6 })}
                  className="flex items-center text-sm bg-gray-200 p-3 cursor-pointer"
                >
                  <span
                    className={`inline-block w-5 h-5 mr-1 transition-all
                                        ${pTab2 ? "rotate-0" : "transform -rotate-90"}`}
                  >
                    <ChevronDownIcon className="w-5 h-5" />
                  </span>
                  <h3>Alternate Products</h3>
                </div>
              </div>
              <div style={{ display: pTab6 ? "" : "none" }} className="contentBody">
                {/* related product */}
                <MultipleItems
                  items={alternateProduct}
                  fromPage={"alternateProduct"}
                  handleChooseProduct={handleChooseProduct}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RelatedProductDetailedView;
