import React, { Fragment, useEffect, useState } from "react";
import Loader from "Component/Loader";
import RegisterInput from "./RegisterInput";
import { registerNewUserProps } from "./types";
import { _get, _uniqBy } from "utils/lodashConst";
import AnimateSlideForm from "Component/SliderMenu/AnimateSlideForm";
import { getUserRolesDetailsApiService } from "Pages/DashBoard/commonDashBoard";
import { failToast, infoToast, successToast } from "Component/ToastServices/ToastDisplay";
import { afterSuccessMakeReloadStateOriginal } from "Pages/EmployeeList/commonEmployeeDetailsget";
import { nullToObject, nullToArray, arrayUndefinedRemoved, convertToLowerCase } from "utils/validation";
import {
  getNlcCategoriesList,
  registerUsersService,
  editRegisterUserDetails,
  showRoomListSearchApiCall,
  syncUserBranchUpdateApiCall
} from "./helperRegister";

const commonBrancDetailsNewArr = (data: any = []) => {
  const newArr = data.map((ele: any) => {
    return {
      ...ele,
      isCheck: false,
      itemName: ele.branch
    };
  });
  return _uniqBy(newArr, "shortcode");
};

const RegisterNewUser = (props: registerNewUserProps) => {
  const {
    globalStore = {},
    popupState = false,
    editUserDetails = {}, //edit user details
    handleNewuserPopup = () => {}
  } = nullToObject(props);

  const editUser = Object.keys(editUserDetails)?.length > 0 ? false : true;
  const { branch = [], details = [], nlcCategory = [] } = editUserDetails;
  const [getRolesDetails, set_getRolesDetails] = useState<any>({});
  const [loader, setLoader] = useState(false);

  const [userDeatils, setUserDetails] = useState({});
  const [editLoader, setEditLoader] = useState(false);
  const [validate, setValidate] = useState(false);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [nlcCategorieList, setNlcCategorieList] = useState([]);
  const [popupAnimateParent, setPopupAnimateParent] = useState(false);
  const [stateRegister, setStateRegister] = useState({
    branchList: []
  });

  const { branchList = [] } = stateRegister;

  const passDataGenralData1 = (arr = []) => {
    const findAll = arr.some((ele) => convertToLowerCase(ele) === "all");
    if (findAll) {
      setUserDetails({
        ...userDeatils,
        nlcCategory: ["all"]
      });
    } else {
      setUserDetails({
        ...userDeatils,
        nlcCategory: arr
      });
    }
  };

  const passDataGenralData = (userInformation: any = {}, userValidate = validate) => {
    setValidate(userValidate); //user validation
    setUserDetails(userInformation); //user information
  };

  const stateManage = (name = "", value: any = "") => {
    setStateRegister({
      ...stateRegister,
      [name]: value
    });
  };

  const userRegister = async () => {
    if (validate) {
      const newArr = branchList.map((ele: any) => {
        if (ele.isCheck) {
          return {
            branch: _get(ele, "branch", ""),
            shortcode: _get(ele, "shortcode", "")
          };
        }
        return undefined;
      });
      const arr = arrayUndefinedRemoved(newArr);
      const newArray =
        arr?.length > 0
          ? arr
          : [
              {
                branch: details[0]?.employee_branch,
                shortcode: details[0]?.employee_branch_code
              }
            ];
      //create user
      if (editUser) {
        setEditLoader(true); //loader on
        const res = await registerUsersService({ ...userDeatils, branch: newArray }, getRolesDetails); //create new user
        setEditLoader(false); //loader off
        const { data: { status = 0, message = "" } = {} } = res;
        if (status === 201) {
          handleNewuserPopup();
          successToast(message);
        } else {
          failToast(message);
        }
      } else {
        //edit user
        setEditLoader(true); //loader on
        const editResponse: any = await editRegisterUserDetails(
          {
            ...editUserDetails,
            ...userDeatils,
            branch: newArray
          },
          getRolesDetails
        ); //edit user
        const { data: { message = "", status = 0 } = {} } = editResponse || {};

        if (status === 200) {
          successToast(message);
          afterSuccessMakeReloadStateOriginal(true);
        } else {
          failToast(message);
        }
        setEditLoader(false); //loader on
      }
    } else {
      infoToast("Please fill all input value/correct input data");
    }
  };

  /**
   * branch list get api call
   */
  const getShowRoomSearch = async () => {
    setRefresh(true);
    const response = await syncUserBranchUpdateApiCall();
    setRefresh(false);
    const { data: { status = 0, data = [], message = "" } = {} } = response;
    if (status >= 200 && status <= 300) {
      const Unique: any = commonBrancDetailsNewArr(data);
      stateManage("branchList", Unique);
    } else {
      failToast(message);
      stateManage("branchList", []);
    }
  };

  const getShowRoomSearchList = async () => {
    setEditLoader(true);
    const response = await showRoomListSearchApiCall();
    setEditLoader(false);
    const { data: { status = 0, data = [], message = "" } = {} } = response;
    if (status >= 200 && status <= 300) {
      const Unique: any = commonBrancDetailsNewArr(data);
      stateManage("branchList", Unique);
    } else {
      failToast(message);
      stateManage("branchList", []);
    }
  };

  const passOriginalArray = (arr = []) => {
    stateManage("branchList", arr);
  };

  /**
   * get api nlc list
   */
  const getNlcCategoriesApi = async () => {
    setEditLoader(true);
    const response = await getNlcCategoriesList();
    setEditLoader(false);
    const { data: { status = 0, data = [] } = {} } = response;
    if (status === 200) {
      if (!editUser) {
        const newArr = data.map((ele, i) => {
          return {
            _id: i,
            isCheck: nlcCategory.some((eleCode) => convertToLowerCase(eleCode) === convertToLowerCase(ele?.code)),
            itemName: ele?.code
          };
        });
        setNlcCategorieList(newArr);
      } else {
        const newArr = data.map((ele, i) => {
          return {
            _id: i,
            isCheck: false,
            itemName: ele?.code
          };
        });
        setNlcCategorieList(newArr);
      }
    } else {
      failToast("pim api fail");
      setNlcCategorieList([]);
    }
  };

  const getUserRolesDetailsApiCall = async (payload) => {
    setLoader(true);
    const response = await getUserRolesDetailsApiService(payload);
    setLoader(false);

    const { data: { data = {}, message = "", status = 0 } = {} } = response || {};
    if (status === 200) {
      set_getRolesDetails(data);
    } else {
      set_getRolesDetails({});
      failToast(message);
    }
  };

  useEffect(() => {
    getUserRolesDetailsApiCall({ page: 1, size: 100 }); //api call get employee details
  }, []);

  //user role details get
  useEffect(() => {
    getNlcCategoriesApi();
    getShowRoomSearchList();
  }, []);

  useEffect(() => {
    if (!editUser && _get(branch, "length", 0) > 0 && _get(branchList, "length", 0) > 0) {
      //edit
      const newArr = branchList.map((ele: any) => {
        return {
          ...ele,
          isCheck: branch.some((eleCheck: any) => eleCheck.shortcode === ele.shortcode)
        };
      });
      stateManage("branchList", newArr);
    }
  }, [editUser, nullToArray(branchList)?.length]);

  useEffect(() => {
    if (popupState) {
      setTimeout(() => {
        setPopupAnimateParent(true);
      }, 200);
    }
    return () => {
      setPopupAnimateParent(false);
    };
  }, [popupState]);

  return (
    <Fragment>
      {editLoader || (loader && <Loader />)}

      <AnimateSlideForm
        cancelText="Cancel"
        handleAction={userRegister}
        setShow={popupAnimateParent}
        customChildClass={"max-w-xl"}
        handleClosePopup={handleNewuserPopup}
        actionText={editUser ? "Create User" : "Update User"}
        heading={editUser ? "Register New User" : `${"Edit User"}`}
      >
        <RegisterInput
          editUser={!editUser}
          refreshBtn={refresh}
          globalStore={globalStore}
          getRolesDetails={getRolesDetails}
          stateRegister={stateRegister}
          editUserDetails={editUserDetails}
          nlcCategorieList={nlcCategorieList}
          getShowRoomSearch={getShowRoomSearch}
          passOriginalArray={passOriginalArray}
          passDataGenralData={passDataGenralData}
          passDataGenralData1={passDataGenralData1}
        />
      </AnimateSlideForm>
    </Fragment>
  );
};

export default RegisterNewUser;
