import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { ReplacementFormProps } from "./types";
import { inputStylePopup } from "utils/ClassContent";
import { checkSRNValue } from "../../../commonSalesReturn";
import { infoToast } from "Component/ToastServices/ToastDisplay";
import ImageUploadReturn from "Component/ImageUploadReturn";
import FormFile from "Component/Form/FormFile";
import CommonInput from "Component/Form/CommonInput";

const RefundForm = (props: ReplacementFormProps) => {
  const {
    setShow = true,
    viewModeData = {},
    convertLowerCase = "",
    enableViewMode = false,
    passRefunData = () => {} //click
  } = props || {};
  const { reasonForSalesReturn: { commonImgFile = [] } = {}, invoiceAmount = 0 } = viewModeData;
  // const { salesReturn: { empDetailsBasedEmpId = {} } = {} } = nullToObject(globalStore);
  const invoiceValue = +invoiceAmount;

  const [imagRenderArry, setImagRenderArry] = useState([]);
  const checkApprovalStatus = checkSRNValue(convertLowerCase);

  const [refundEmpId, setRefundEmpId] = useState("");
  const [refundEmpName, setRefundEmpName] = useState("");
  const [refundDepatmet, setRefundDepatmet] = useState("");
  const [refundRequestAmt, setRefundRequestAmt] = useState("");
  const [refundDesignation, setRefundDesignation] = useState("");

  const [refundFormData, setRefundFormData] = useState({
    refundEmpId: "",
    refundImages: [],
    refundEmpName: "",
    refundDepatmet: "",
    refundRequestAmt: "",
    refundDesignation: ""
  });

  const onImageChange = (event: any = {}) => {
    if (_get(event, "target.files", [])?.length > 0) {
      const file = _get(event, "target.files[0]", {});
      const { name = "", type = "" } = file;
      const newObj = {
        file: file,
        fileName: name,
        url: URL.createObjectURL(file), //conevrt blob formate
        type: type
      };
      const newArray: any = [...imagRenderArry, newObj];
      setImagRenderArry(newArray);
      const newObject = {
        ...refundFormData,
        refundImages: newArray
      };
      setRefundFormData(newObject);
      passRefunData(newObject, true); //pass information here to index
    }
  };

  const onClosImage = (index = 0) => {
    //remove image
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    setImagRenderArry(newArray);
    const newObject = {
      ...refundFormData,
      refundImages: newArray
    };
    setRefundFormData(newObject);
    passRefunData(newObject, true); //pass information here to index
  };

  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const refundForm = refundFormData;

    switch (name) {
      case "refundRequestAmt":
        if (invoiceValue >= value) {
          refundForm.refundRequestAmt = value;
          setRefundRequestAmt(value);
        } else {
          infoToast("Refund Request Amount to be equal amout or less then Product value");
        }
        break;
      case "refundEmpId":
        refundForm.refundEmpId = value;
        setRefundEmpId(value);
        // value && getEmpDetailsForReplaceMent(value);//emp id based search details
        break;
      case "refundEmpName":
        refundForm.refundEmpName = value;
        setRefundEmpName(value);
        break;
      case "refundDepatmet":
        refundForm.refundDepatmet = value;
        setRefundDepatmet(value);
        break;
      case "refundDesignation":
        refundForm.refundDesignation = value;
        setRefundDesignation(value);
        break;
      default:
        break;
    }
    setRefundFormData(refundForm);
    passRefunData(refundForm, true); //pass information here to index
  };

  const dynamicClass = () => {
    return inputStylePopup;
  };

  useEffect(() => {
    const {
      reasonForSalesReturn: {
        refundReqAmt: amt = "",
        employeeId: empId = "",
        employeeName: empName = "",
        employeeDept: empDept = "",
        employeeDesignation: empDesignation = ""
      } = {}
    } = viewModeData;
    onChange({ target: { name: "refundRequestAmt", value: amt } });
    onChange({ target: { name: "refundEmpId", value: empId } });
    onChange({ target: { name: "refundEmpName", value: empName } });
    onChange({ target: { name: "refundDepatmet", value: empDept } });
    onChange({ target: { name: "refundDesignation", value: empDesignation } });
  }, [enableViewMode]);

  // useEffect(() => {
  //     if (Object.keys(empDetailsBasedEmpId)?.length > 0) {
  //         const { employee_name = "", employee_department = "", employee_designation = "" } = empDetailsBasedEmpId;
  //         onChange({ target: { name: "refundEmpName", value: employee_name } });
  //         onChange({ target: { name: "refundDepatmet", value: employee_department } });
  //         onChange({ target: { name: "refundDesignation", value: employee_designation } });
  //     }
  // }, [empDetailsBasedEmpId]);

  return (
    <React.Fragment>
      <CommonInput
        placeholder={""}
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        value={refundRequestAmt}
        name={"refundRequestAmt"}
        labelTextClass={"font-bold"}
        parentClass={dynamicClass()}
        labelText={"Refund Request Amount"}
      />
      {checkApprovalStatus && (
        <>
          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            value={refundEmpId}
            name={"refundEmpId"}
            inputFieldClass="h-9"
            labelText={"Employee Id"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />

          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            readOnly={true}
            value={refundEmpName}
            name={"refundEmpName"}
            inputFieldClass="h-9"
            labelText={"Employee Name"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />

          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            readOnly={true}
            inputFieldClass="h-9"
            value={refundDepatmet}
            name={"refundDepatmet"}
            labelText={"Department"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />
          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            onChange={onChange}
            readOnly={true}
            inputFieldClass="h-9"
            value={refundDesignation}
            labelText={"Designation"}
            name={"refundDesignation"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
          />
        </>
      )}

      {!enableViewMode ? (
        <Fragment>
          <FormFile
            type="file"
            label={true}
            onChange={onImageChange}
            labelText="Attach Image"
            labelTextClass={"font-bold"}
            parentClass={" mb-1 w-full mt-2  px-4 "}
            accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .odt, .txt, .xlsx, .xls"
          />
          <div className="w-full flex flex-wrap justify-center px-3">
            {imagRenderArry.length >= 1 && (
              <div className="w-full flex flex-wrap justify-center px-3">
                <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
              </div>
            )}
            <div className="w-full flex flex-wrap justify-center px-3">
              {commonImgFile.length >= 1 && (
                <ImageUploadReturn
                  onClosImage={onClosImage}
                  imagRenderArry={commonImgFile}
                  enableViewMode={enableViewMode}
                />
              )}
            </div>
          </div>
        </Fragment>
      ) : (
        commonImgFile.length >= 1 && (
          <div className="w-full flex flex-wrap justify-center px-3">
            <ImageUploadReturn
              onClosImage={onClosImage}
              imagRenderArry={commonImgFile}
              enableViewMode={enableViewMode}
            />
          </div>
        )
      )}
    </React.Fragment>
  );
};
export default RefundForm;
