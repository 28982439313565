import React, { useState, useEffect } from "react";
import CommonCheck from "./CommonCheck";
import { _get } from "utils/lodashConst";
import { IconSearch } from "assets/images";
import { Transition } from "@headlessui/react";
import { CommonSearchDropdownProps } from "./types";
import { CheckIcon } from "@heroicons/react/solid";
import OutsideClickHandler from "react-outside-click-handler";
import { ChevronDownIcon, XIcon } from "@heroicons/react/outline";
import { convertToLowerCase, nullToObject } from "utils/validation";
import { DropDownKeyboardControl } from "./DropDownKeyboardControl";
import CommonDropDownInput from "Component/CommonTable/CommonTableSortDropdown/CommonDropDownInput";

const CommonSearchDropdown = (props: CommonSearchDropdownProps) => {
  const {
    name = "",
    value = "",
    inputId = "",
    fontSize = "",
    label = true,
    children = "",
    arrayData = [],
    setShow = true,
    labelText = "",
    labelTextClass,
    inputClass = "",
    parentClass = "",
    iconClickAction,
    onBlur = () => {},
    mustField = false,
    readOnly = false,
    dropOnTop = false,
    dropIconClass = "",
    ErrorMsgClass = "",
    inputErrorMsg = "",
    prevSelected = false,
    disabledDrop = false,
    inputFieldClass = "",
    removeCursor = false,
    minSearchCriteria = 5,
    enableCheckbox = false,
    inputWithIcon = false,
    clearInput = () => {}, //click
    inputWithIconClass = "",
    placeholder = "--None--",
    selectorKey = "optionData",
    onPaste = () => {}, //click
    onInput = () => {}, //click
    handleSearchValue = () => {},
    onKeyPress = () => {}, //click
    handleOnChange = () => {}, //click
    onClickCheckBox = () => {}, //click
    iconComponent = <IconSearch />,
    handleParentDropVal = () => {}
  } = nullToObject(props);

  const listStyle = "px-3 py-2";
  const [dropShow, setDropShow] = useState(false);
  const [searchOptionValue, setSearchOptionValue]: any = useState("");

  const handleInputValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    clearInput && clearInput(event);
    handleOnChange && handleOnChange(event);
    setDropShow(true);
  };

  const handleSelectValue = (valuesData: any, index: any) => {
    setSearchOptionValue("");
    const { [selectorKey]: valueData = "" } = valuesData;
    if (!enableCheckbox) {
      setDropShow(false);
    }
    handleOnChange &&
      handleOnChange({
        target: {
          name: name,
          value: valueData,
          seletedObj: arrayData[index]
        }
      });
    if (handleParentDropVal) {
      handleParentDropVal({ ...valuesData, name: name });
    }
  };

  const handleOutSideClick = () => setDropShow(false);

  const handleInputValueReset = () => {
    setDropShow(true);
    clearInput && clearInput({ target: { name: name, value: "" } });
  };

  const handleSearchfilter = (event: any = "") => {
    const { target: { value: val = "" } = {} } = event;
    setSearchOptionValue(val);
    handleSearchValue && handleSearchValue(val); // pass search value
    const div: any = document && document.getElementById(`drop-${name}`);
    const a: any = div?.querySelectorAll(".optionValue");
    for (let i = 0; i < a?.length; i++) {
      const txtValue = a[i]?.textContent || a[i].innerText;
      if (txtValue.toUpperCase().indexOf(val?.toUpperCase()) > -1) {
        a[i].parentNode.style.display = "";
      } else {
        a[i].parentNode.style.display = "none";
      }
    }
  };

  const handleClearOptionSearch = () => handleSearchfilter("");

  useEffect(() => () => handleSearchfilter(""), []);

  useEffect(() => {
    const ListArray: any = document?.querySelectorAll(`#drop-${name} > button`);
    DropDownKeyboardControl(ListArray);
  }, [dropShow]);

  return (
    <Transition
      show={setShow}
      leaveTo="opacity-0"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leaveFrom="opacity-10"
      enter="transition-opacity duration-200"
      leave="transition-opacity duration-200"
      className={`form-group relative ${parentClass ? parentClass : ""}`}
    >
      {label && (
        <div className="flex">
          <label
            className={`text-xs mb-0.5 LabelName font-bold inline-block
                    ${labelTextClass ? labelTextClass : ""}
                    ${inputErrorMsg ? " text-error" : "text-valid"}`}
            htmlFor={inputId}
          >
            {labelText}
            {mustField && <sup style={{ color: "red" }}>*</sup>}
          </label>
        </div>
      )}

      <div
        className={`
                ${inputClass ? inputClass : ""}
                ${inputWithIcon ? inputWithIcon : ""}
                relative`}
      >
        <input
          name={name}
          type="text"
          id={inputId}
          autoCorrect="off"
          autoComplete="off"
          spellCheck={false}
          disabled={disabledDrop}
          value={value ? value : ""}
          onBlur={onBlur ? onBlur : null}
          onPaste={onPaste ? onPaste : null}
          onInput={onInput ? onInput : null}
          readOnly={removeCursor || readOnly}
          placeholder={placeholder ? placeholder : ""}
          onKeyPress={onKeyPress ? onKeyPress : null}
          onFocus={readOnly ? () => {} : () => setDropShow(true)}
          onClick={readOnly ? () => {} : () => setDropShow(true)}
          onChange={readOnly === true ? () => {} : handleInputValue}
          className={`block w-68 rounded input-common pl-3 pr-7 py-2 ${fontSize ? fontSize : " text-sm "}
                    ${inputErrorMsg ? " error pr-10" : "input-valid "} ${
                      inputFieldClass ? inputFieldClass : " "
                    } ${inputWithIcon ? " pr-20" : ""} ${dropShow ? " active " : ""}
                `}
        />
        {!readOnly &&
          (value !== "" ? (
            <button
              type="button"
              onClick={handleInputValueReset}
              className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute top-1/2 input-icon-color-fill hover:text-red-500 cursor-pointer right-2 transform -translate-y-1/2
                        ${iconClickAction ? "cursor-pointer" : ""}`}
            >
              <XIcon className="w-5 h-5" />
            </button>
          ) : (
            <button
              onClick={readOnly ? () => {} : () => setDropShow(true)}
              type="button"
              className={`${dropIconClass ? dropIconClass : ""}
                        inline-block absolute top-1/2 right-3 transform text-gray-400 -translate-y-1/2 ${iconClickAction ? "cursor-pointer" : ""}`}
            >
              <ChevronDownIcon className=" w-4 h-4 " />
            </button>
          ))}

        {inputWithIcon && iconComponent && (
          <span
            onClick={iconClickAction}
            className={`absolute flex justify-center items-center cursor-pointer input-icon-color-fill 
                        top-px bottom-px right-px input-group-icon rounded ${inputWithIconClass ? inputWithIconClass : ""}`}
          >
            {React.cloneElement(iconComponent)}
          </span>
        )}
        {dropShow && (
          <OutsideClickHandler onOutsideClick={handleOutSideClick}>
            <div
              className={`dropdown bg-white z-10 absolute  left-0 right-0 
                            ${!inputErrorMsg || dropShow ? " input-valid" : ""}${
                              dropOnTop ? " bottom-full dropOnTop -mb-1 rounded-t" : " top-full -mt-1 rounded-b"
                            }`}
            >
              {arrayData?.length > minSearchCriteria && (
                <CommonDropDownInput
                  name={name}
                  autoFocus={true}
                  value={searchOptionValue}
                  handleSearchfilter={handleSearchfilter}
                  handleClearOptionSearch={handleClearOptionSearch}
                />
              )}
              <div id={`drop-${name}`} className="h-full overflow-x-hidden flex flex-col overflow-y-auto max-h-52">
                {(arrayData || []).map((item: any, i: any) => {
                  return (
                    <button
                      tabIndex={0}
                      type="button"
                      data-id={`option_${i}`}
                      value={item[selectorKey]}
                      key={convertToLowerCase(i + "GetData-ks")}
                      onClick={() => handleSelectValue(item, i)}
                      className="w-full list_option hover:bg-yellow-50 transition duration-200"
                    >
                      <div className={`${listStyle} w-full text-left flex`}>
                        {enableCheckbox && (
                          <CommonCheck
                            label={false}
                            id={name + "-" + i}
                            parentClass={"mr-2"}
                            onChange={onClickCheckBox}
                            checked={_get(item, "isSelect", false)}
                          />
                        )}
                        {prevSelected && <CheckIcon className="absolute left-1 w-4 h-4 text-gray-400 " />}
                        <span className="optionValue">{item[selectorKey]}</span>
                      </div>
                      {item.optionsubText !== "" && <span className="text-lite flex option">{item.optionsubText}</span>}
                    </button>
                  );
                })}
              </div>
            </div>
          </OutsideClickHandler>
        )}
      </div>
      {/* error msg */}
      {inputErrorMsg && (
        <span className={`absolute -bottom-5 right-5 error-msg text-xs ${inputErrorMsg ? ErrorMsgClass : ""}`}>
          {inputErrorMsg}
        </span>
      )}
      {children}
    </Transition>
  );
};

export default CommonSearchDropdown;
