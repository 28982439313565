import { Get } from "ApiProcess/httpRestServices";
import { centralInventoryApi, pincodeApi, pincodeApiBasePath } from "../../ApiProcess/httpRestServices/apiManage";

/**
 * @param  {object} obj;
 * initial initial params and search params pass
 */
type pincodeStoreType = {
  data?: string;
};
export const pincodeStoreLocate = (obj: pincodeStoreType) => {
  const { data = "" } = obj || {};
  return Get(`${centralInventoryApi}${pincodeApi.pincode}/${data}/50`, "xapikey");
};

export const storeLocateService = () => {
  return Get(`${pincodeApiBasePath}${pincodeApi.storeDetails}`, "NoToken");
};
