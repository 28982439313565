import React, { useEffect, useState } from "react";
import { updateComplainDataById, validateComplainEvaluate } from "../../commonComplainlist";
import { moment } from "utils/momentConst";
import { _get, _isEmpty } from "utils/lodashConst";
import { Decrypt } from "utils/secureFile";
import { complainInputFormProps } from "../types";
import FormFile from "Component/Form/FormFile";
import CommonInput from "Component/Form/CommonInput";
import ComplainStatusInput from "./ComplainStatusInput";
import ComplainAboutDisplay from "./ComplainAboutDisplay";
import CommonTextarea from "Component/Form/CommonTextarea";
import ComplainEmployeeDetail from "./ComplainEmployeeDetail";
import { failToast, successToast } from "Component/ToastServices/ToastDisplay";
import {
  inputStylePopup,
  StyleFormTextarea,
  bg_EnquiryFromMail,
  bg_EmployeeDetail,
  bg_ComplaintInfo
} from "utils/ClassContent";
import { dateToString } from "utils/validation";
import CommonDropHeading from "Component/CommonDropHeading";
import EnquiryMailReply from "Pages/MailBox/ShowMailOnEnquiry/EnquiryMailReply";
import { replyMailTemplate, sendEmailToServerCall } from "Pages/MailBox/commonMailBoxFile";
import ImageUploadReturn from "Component/ImageUploadReturn";
import CommonCheck from "Component/Form/CommonCheck";
import EnquiryFromMail from "Pages/MailBox/ShowMailOnEnquiry/EnquiryFromMail";
import Loader from "Component/Loader";

const ComplainInputForm = (props: complainInputFormProps) => {
  const {
    viewModeData = {},
    selectedTab = "",
    columnView = false,
    enableViewMode = false,
    selectedMailData = {},
    comListApiReload = () => {}
  } = props || {};
  const [loader, setLoader] = useState(false);
  const [mailInfo, setMailInfo] = useState({});
  const [composedHtml, setComposedHtml] = useState("");
  const [isMailReply, setIsMailReply] = useState(false);
  const [imagRenderArry, setImagRenderArry] = useState([]);
  const [sendAttachmants, setSendAttachmants] = useState([]);
  const [additionalRemarks, setAdditionalRemarks] = useState("");
  const [complainData, setComplainData] = useState<any>({});
  const loginUserDetails = Decrypt("userLoginData") || {};
  const { data: { roleId = 0 } = {} } = loginUserDetails || {};
  const restrictedRoles = ["4", "5", "7"];
  const strinConvert = "" + roleId;
  const restrcitStatusRender = restrictedRoles.includes(strinConvert);

  /**
   * handle complain change in input
   * @param key
   * @param value
   */
  const handleComplainChange = (key: any = "", value: any = "") => {
    setComplainData({
      ...complainData,
      [key]: value
    });
  };

  /**
   * handle Field change of complain form
   * @param event
   */
  const handleFieldChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    handleComplainChange(name, value);
  };

  /**
   * handle form submission
   */
  const handleSubmit = async () => {
    let newObj = { ...complainData, additionalRemarks };
    if (newObj.callType === null) {
      failToast("Please select call type");
      return;
    }
    if (isMailReply) {
      newObj = { ...newObj, ...{ additionalRemarks: "Sent Mail" } };
      if (_isEmpty(_get(mailInfo, "to", [])) || _isEmpty(_get(mailInfo, "from", []))) {
        failToast("Please enter From/To email address");
        return;
      }
    }
    const validationString = validateComplainEvaluate(newObj);
    if (!restrcitStatusRender && validationString) {
      failToast("Kindly fill " + validationString);
      return;
    }
    setLoader(true);
    const response = await updateComplainDataById(complainData._id, {
      ...complainData,
      ...newObj
    });
    setLoader(false);

    const { data: { data: status = 0, message = "" } = {} } = response || {};
    if (status === 200) {
      comListApiReload();
      successToast(message);
    } else {
      failToast(message);
    }

    if (isMailReply) {
      sendReplyMail();
    }
  };

  // Send reply mail to enquiry mail
  const sendReplyMail = () => {
    const currentMailData = _get(selectedMailData, "mailData", {});
    const originalMailHtml = _get(currentMailData, "html", "");
    const originalMailFrom = _get(currentMailData, "from", []);
    const originalMailText = _get(currentMailData, "text", "");
    const originalMailDate = _get(currentMailData, "date", "");
    const originalMailSubject = _get(currentMailData, "subject", "");
    const originalMailContent = originalMailHtml ? originalMailHtml : originalMailText;

    const originalMailReplyFormat = replyMailTemplate(originalMailDate, originalMailFrom, originalMailContent); // gets original mail in reply format

    const sendMailContent = composedHtml + originalMailReplyFormat;

    const mailData = {
      from: _get(mailInfo, "from", []).pop(),
      cc: _get(mailInfo, "cc", []),
      bcc: _get(mailInfo, "bcc", []),
      toEmail: _get(mailInfo, "to", []),
      subject: originalMailSubject,
      content: sendMailContent,
      attachments: sendAttachmants
    };
    sendEmailToServerCall(mailData);
  };

  const passValue = (htmlValue = "") => {
    setComposedHtml(htmlValue);
  };

  const passAttachmants = (attachmants: any = []) => {
    setSendAttachmants(attachmants);
  };

  const passMailInfo = (val: Object = {}) => {
    setMailInfo(val);
  };

  const onChangeAdditionalHandler = (e: any = {}) => {
    const { name = "", value = "" } = e?.target;
    setComplainData({
      ...complainData,
      [name]: value
    });
    setAdditionalRemarks(value);
  };

  /**
   * hande empoyee data
   * @param employeeDetail
   */
  const handleEmployeeFetch = (employeeDetail: any = {}) => {
    setComplainData({
      ...complainData,
      ...employeeDetail
    });
  };

  /**
   * handle additional file changes
   * @param event
   */
  const onImageChange = (event: any = {}) => {
    if (_get(event, "target.files", [])?.length > 0) {
      const file = _get(event, "target.files[0]", {});
      const { name = "", type = "" } = file;
      const newObj = {
        file,
        fileName: name,
        type,
        isEditable: true,
        url: URL.createObjectURL(file) //conevrt blob formate
      };
      const newArray: any = [...imagRenderArry, newObj];
      setImagRenderArry(newArray);
      const newComplainData = {
        ...complainData,
        additionalAttachments: newArray //appent image
      };
      setComplainData(newComplainData);
    }
  };

  const onClosImage = (index: number) => {
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    setImagRenderArry(newArray);
    const newComplainData = {
      ...complainData,
      additionalAttachments: newArray //appent image
    };
    setComplainData(newComplainData);
  };

  useEffect(() => {
    setComplainData({
      emailId: "",
      callType: null,
      caseNumber: "",
      hearingDate: "",
      typeOfIssue: "",
      actionTaken: "",
      concernType: "",
      InvolvedEmpId: "",
      contactNumber: "",
      staffStatement: "",
      InvolvedEmpName: "",
      nameOfThePerson: "",
      internalDecision: "",
      InvolvedEmpContactNo: "",
      additionalRemarks: "",
      additionalAttachments: [],
      ...viewModeData,
      status: "",
      currentStatus: "",
      nextFollowUpDate: "",
      remarks: ""
    });
  }, [viewModeData]);

  return (
    <>
      {loader && <Loader />}
      <div className="bg-white flex flex-wrap items-center w-full">
        <CommonDropHeading
          tabActiveDefault={selectedTab === "EmployeeDetail" ? true : false}
          id="EmployeeDetail"
          heading="Employee Detail"
          customClass={bg_EmployeeDetail}
        >
          <ComplainEmployeeDetail
            columnView={columnView}
            complainData={complainData}
            enableViewMode={enableViewMode}
            handleChange={handleComplainChange}
            handleEmployeeFetch={handleEmployeeFetch}
          />

          <CommonTextarea
            mustField={false}
            setShow={true}
            name={"staffStatement"}
            labelTextClass={"font-bold"}
            onChange={handleFieldChange}
            readOnly={enableViewMode}
            labelText={"Staff Statement"}
            placeholder={"Staff Statement"}
            value={complainData.staffStatement}
            parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
          />

          <CommonInput
            setShow={true}
            inputError={false}
            name={"caseNumber"}
            inputFieldClass="h-9"
            enableDatePicker={false}
            labelText={"Case Number"}
            readOnly={enableViewMode}
            placeholder={"Case Number"}
            labelTextClass={"font-bold"}
            onChange={handleFieldChange}
            value={complainData.caseNumber}
            parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
          />

          <CommonTextarea
            setShow={true}
            name={"internalDecision"}
            readOnly={enableViewMode}
            onChange={handleFieldChange}
            labelTextClass={"font-bold"}
            labelText={"Internal Decision"}
            placeholder={"Internal Decision"}
            value={complainData.internalDecision}
            parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
          />
        </CommonDropHeading>

        <CommonDropHeading
          tabActiveDefault={selectedTab === "ComplianInfo" ? true : false}
          id="tab4"
          heading="Complaint info"
          customClass={bg_ComplaintInfo}
        >
          <ComplainAboutDisplay
            columnView={columnView}
            complainData={complainData}
            enableViewMode={enableViewMode}
            handleChange={handleComplainChange}
            complainAbout={complainData.complainAbout}
          />
        </CommonDropHeading>
      </div>

      {_get(complainData, "mailId", "") && (
        <CommonDropHeading
          tabActiveDefault={selectedTab === "EnquiryFromMail" ? true : false}
          id="EnquiryFromMail"
          heading="Enquiry From Mail"
          customClass={bg_EnquiryFromMail}
        >
          <EnquiryFromMail customerData={complainData} selectedMailData={selectedMailData} />
        </CommonDropHeading>
      )}

      {viewModeData.status !== "Closed" && !restrcitStatusRender && (
        <div className="w-full border-dashed flex transition flex-wrap h-auto mt-4 mb-4">
          <CommonDropHeading
            tabActiveDefault={true}
            id="ComplaintStatusUpdate"
            heading="Complaint Status Update"
            customClass={bg_EnquiryFromMail}
          >
            <ComplainStatusInput
              columnView={columnView}
              complainData={complainData}
              handleChange={handleComplainChange}
            />

            {complainData.status === "Pending" && (
              <CommonInput
                setShow={true}
                mustField={true}
                maxDate={moment()}
                inputError={false}
                inputWithIcon={true}
                inputFieldClass="h-9"
                enableDatePicker={true}
                isIconLabel={true}
                name={"nextFollowUpDate"}
                minDate={moment().toDate()}
                labelTextClass={"font-bold"}
                onChange={handleFieldChange}
                labelText={"Next Followup Date"}
                value={complainData.nextFollowUpDate && new Date(complainData.nextFollowUpDate)}
                placeholderText={"" + dateToString(complainData.nextFollowUpDate)}
                parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
              />
            )}

            <FormFile
              type="file"
              label={true}
              onChange={onImageChange}
              labelText="Attach Image"
              labelTextClass={"font-bold"}
              parentClass={columnView ? "mb-2 w-full lg:w-2/4 mt-1 px-4" : inputStylePopup}
            />
            {imagRenderArry.length >= 1 && (
              <div className="w-full flex flex-wrap justify-center px-3">
                <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
              </div>
            )}

            {Object.keys(selectedMailData)?.length !== 0 && (
              <CommonCheck
                id="Send Reply Mail"
                labelText="Send Reply Mail"
                checked={isMailReply}
                onChange={() => {
                  setIsMailReply(!isMailReply);
                }}
                parentClass={"mb-2 w-full lg:w-full mt-2 px-4 xl:w-1/2"}
              />
            )}

            {!isMailReply ? (
              <CommonTextarea
                setShow={true}
                mustField={true}
                inputError={false}
                name={"additionalRemarks"}
                labelTextClass={"font-bold"}
                labelText={"Additional Remarks"}
                parentClass={StyleFormTextarea()}
                placeholder={"Additional Remarks"}
                value={additionalRemarks}
                inputFieldClass="h-28 max-h-64"
                onChange={onChangeAdditionalHandler}
              />
            ) : (
              <EnquiryMailReply
                showReplyMail={true}
                passValue={passValue}
                passAttachmants={passAttachmants}
                selectedMailData={selectedMailData}
                passMailInfo={passMailInfo}
              />
            )}
          </CommonDropHeading>
          <div className="py-8 bg-lite-white sticky -bottom-8 flex flex-col items-center justify-center w-full">
            <div>
              <button
                type="button"
                onClick={handleSubmit}
                className="w-40 h-10 rounded my-5 bg-primary-gradient-hover bg-primary-gradient"
              >
                {"Submit"}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ComplainInputForm;
