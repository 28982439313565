import React from "react";
import { Decrypt } from "utils/secureFile";
import { enquiryStatusTrackingTableProps } from "../types";
import { TableClass, tdClass, thClass } from "utils/ClassContent";
import NoDataFoundPage from "Component/NoDataFoundPage";
import {
  convertToLowerCase,
  dateTimeDisplay,
  nullToObject,
  nullToString,
  handleCurrentUser,
  getSortedTrans
} from "utils/validation";

const EnquiryLeadClassificationData = (props: enquiryStatusTrackingTableProps) => {
  const { transactions = [] } = nullToObject(props);
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { empId = "" } = {} } = nullToObject(userLoginDetails);

  const renderData = (data: any = "", className = "") => {
    const renderStr = data ? data.charAt(0).toUpperCase() + data.slice(1) : "nill";
    return <td className={className + tdClass}>{renderStr}</td>;
  };

  return (
    <div
      className="block w-full overflow-x-auto relative"
      style={{
        minHeight: "250px",
        height: "100%"
      }}
    >
      <table className={TableClass}>
        <thead>
          <tr>
            <th className={thClass}>Date</th>
            <th className={thClass}>Lead Classification</th>
            <th className={thClass}>Lead Classification Reason</th>
            <th className={thClass}>Updated by</th>
          </tr>
        </thead>
        {transactions.length ? (
          transactions.sort(getSortedTrans).map((ele: any, i: number) => {
            const {
              leadClassification = "",
              leadClassificationReason = "",
              actionBy: { branch = null, date = "", name = "", empId: employId = "" } = {}
            } = ele;
            return (
              <tbody key={convertToLowerCase(i + "transactions-fsvx")}>
                <tr>
                  <td className={tdClass}>
                    <div className="flex items-center">
                      <span className="">{dateTimeDisplay(date)}</span>
                    </div>
                  </td>
                  {renderData(nullToString(leadClassification), "capitalize ")}
                  {renderData(nullToString(leadClassificationReason))}
                  {renderData(handleCurrentUser(name, branch, employId, empId))}
                </tr>
              </tbody>
            );
          })
        ) : (
          <NoDataFoundPage />
        )}
      </table>
    </div>
  );
};

export default EnquiryLeadClassificationData;
