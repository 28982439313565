import store from "store";
import { Get, Post } from "ApiProcess/httpRestServices";
import { convertToLowerCase, DateTimeFormeterEnd, DateTimeFormeterStart, nullToObject } from "utils/validation";
import { ImeinumBasedTransactionHistory } from "store/action/salessReturnAction";
import { crmApiBaseUrl, cusListPageApi, dashBoardApi, encodeGetParams } from "ApiProcess/httpRestServices/apiManage";
import { updateBasicInfoFromPopupAction, updateEnquiryBasicCustomerInfoAction } from "store/action/BasicInfoAction";
import { Decrypt } from "utils/secureFile";

export const callTypeMyCallAllCallHandle = (roleId = "") => {
  const allCallShow = ["1", "2", "6", "9", "11", "13", "15", "16", "17", "18"];
  return allCallShow.some((ele: any) => ele === convertToLowerCase(roleId));
};

export const defaultCallState = {
  page: 1,
  size: 10,
  search: "",
  status: [],
  endDate: null,
  enquiryFor: [],
  callType: null,
  startDate: null,
  subCategory: [],
  currentStatus: []
};

/**
 * @param  {number} imeiNumber
 * loader enable
 * dispatch the imei number history action
 */
export const imeiNumHistoryApiCall = (imeiNumber = "") => {
  store.dispatch(ImeinumBasedTransactionHistory(imeiNumber)); //dispatch the imei based get transActionList
  store.dispatch({ type: "GET_TRANSACTION_BASED_IMEI_BASED_LOADER", imeiNumHistoryLoader: true });
};

/**
 * @param  {object} obj;
 * get cuslist data
 */
export const getCuslistApiServices = (obj: any = {}) => {
  const userLoginDetails = Decrypt("userLoginData");
  const { data: { roleId = 0 } = {} } = nullToObject(userLoginDetails);
  const allCall = callTypeMyCallAllCallHandle(roleId);
  const {
    page = 1,
    size = 10,
    search = "",
    status = "",
    mobileNo = "",
    createdBy = "",
    callType = null,
    endDate = null,
    enquiryFor = "",
    startDate = null,
    subCategory = "",
    currentStatus = ""
  } = obj || {};

  const newObj = {
    page,
    size,
    search,
    mobileNo,
    createdBy,
    enquiryFor,
    subCategory,
    currentStatus,
    status: status !== "All" ? status : "",
    ...(!allCall && { pageType: "MY_CALLS" }),
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    callType: callType === true ? true : callType === false ? false : ""
  };
  const consvertString = encodeGetParams(newObj);
  return Get(`${crmApiBaseUrl}${cusListPageApi.cusList}?${consvertString}`, "crmToken");
};

export const getCustpendingFollowUpDetails = async (userSearch: any = {}) => {
  //search cus based search
  const {
    page = 1,
    size = 10,
    status = "",
    endDate = "",
    startDate = "",
    enquiryFor = "",
    callType = null,
    subCategory = "",
    currentStatus = ""
  } = userSearch;
  const newObj = {
    page,
    size,
    status,
    enquiryFor,
    subCategory,
    currentStatus,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    callType: callType === true ? true : callType === false ? false : ""
  };
  const queryString = encodeGetParams(newObj);
  return await Get(`${crmApiBaseUrl}${dashBoardApi.getEmpIdBasedEnq}?${queryString}`, "crmToken");
};

export const uploadCustpendingFollowUpDetails = async (userSearch: any = {}) => {
  //search cus based search
  const {
    page = 1,
    size = 10,
    subCategory = "",
    enquiryFor = "",
    endDate = "",
    startDate = "",
    currentStatus = "",
    callType = null,
    status = ""
  } = userSearch;
  const newObj = {
    page,
    size,
    status,
    enquiryFor,
    subCategory,
    currentStatus,
    endDate: endDate ? DateTimeFormeterEnd(endDate) : "",
    startDate: startDate ? DateTimeFormeterStart(startDate) : "",
    callType: callType === true ? true : callType === false ? false : ""
  };
  const queryString = encodeGetParams(newObj);
  return await Post(`${crmApiBaseUrl}${dashBoardApi.downloadEmpIdBasedEnq}?${queryString}`, {}, "crmToken");
};

export const updateEnquiryBasicCustomerInfoCall = (data: object = {}) => {
  store.dispatch({ basicInfoUpdateResetData: false, type: "BASIC_IFO_UPDATE_LOADER" });
  store.dispatch(updateEnquiryBasicCustomerInfoAction(data));
};

export const updateBasicInfoFromEnquiryCall = (data: object = {}) => {
  store.dispatch({ basicInfoUpdateLoader: false, basicInfoUpdateResetData: false, type: "BASIC_IFO_UPDATE_LOADER" });
  store.dispatch(updateBasicInfoFromPopupAction(data));
};

export const resetUserBasicInfoDataCall = (data: object = {}) => {
  store.dispatch({ type: "ENQUIRY_BASIC_INFO", enquiryBasicInfo: data });
};

export const reloadCusListStausRemove = (value: any = null) => {
  //reloadCusList reload status
  store.dispatch({ type: "RELOAD_CUS_LIST_API_CALL", cusListApiReload: value });
};

/**
 * @param  {object} obj;
 * get cuslist data
 */
export const getAllCallByIdApiServices = (id: any = "") => {
  return Get(`${crmApiBaseUrl}${cusListPageApi.cusList}/${id}`, "crmToken");
};
