import * as salesReturnType from "../actionTypes/salesReturnType";

/**
 * @param  {object} data;
 * imei num based get details
 */
export const ImeinumApiCallAction = (imeiNumber = "") => {
  return {
    type: salesReturnType.IMEI_NUMBER_BASED_GET_DETAILS,
    imei: imeiNumber
  };
};

/**
 * @param  {object} data;
 * imei num based get details
 */
export const ImeinumBasedTransactionHistory = (imeiNumber = {}) => {
  return {
    type: salesReturnType.IMEI_NUMBER_BASED_GET_TRANSACTION_DETAILS,
    imei: imeiNumber
  };
};

/**
 * @param  {object} data;
 * update status
 */
export const updateSalesReturnDataAction = (data: any = {}) => {
  return {
    type: salesReturnType.UPDATE_SALES_RETURN_DETAILS,
    data
  };
};

/**
 * update status
 */
export const updateServiceStatusApiAction = (data = {}) => {
  return {
    type: salesReturnType.UPDATE_SALES_RETURN_STATUS,
    data
  };
};

/**
 * GET emp details
 */
export const getEmpleeDetailsBasedEmpId = (data = {}) => {
  return {
    type: salesReturnType.GET_EMP_DETAILS_AND_DATA,
    data
  };
};

/**
 * @param  {object} data;
 * update the sales return
 */
export const updatesalesreturnDataViaEditAction = (data: any = {}) => {
  return {
    type: salesReturnType.UPDATE_SALES_RETURN_DETAILS_EDIT_DATA,
    data
  };
};

/**
 * @param  {object} data;
 * get invoice and date based burchecs details
 */
export const getUserDetailsBasedOnInvoiceAndDate = (data: any = {}) => {
  return {
    type: salesReturnType.GET_USER_IMEI_AND_DETAIL_BASED_INVOICE_DATE,
    data
  };
};
