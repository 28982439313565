import React from "react";
import { _get } from "utils/lodashConst";
import { ImgOffline } from "assets/images";
import { convertToLowerCase, nullToObject, paisaToAmount } from "utils/validation";

const OnlinePriceDetailsData = (props: any = {}) => {
  //priceDetails - online product details
  const { productDetails = {}, priceDetails = {} } = nullToObject(props);

  const price =
    _get(productDetails, "prices.sp", 0) > 0
      ? _get(productDetails, "prices.sp", 0)
      : _get(productDetails, "mrp.price", 0);
  const showroomPrice =
    _get(priceDetails, "prices.sp", 0) > 0 ? _get(priceDetails, "prices.sp", 0) : _get(priceDetails, "mrp.price", 0); // Online Price

  const splPrice = price > 0 ? +price - showroomPrice : 0;
  const offlineDiscount = splPrice > 0 ? (splPrice / price) * 100 : 0;
  return (
    <div className="w-1/2 text-xs pr-2 sm:pr-5 flex-grow">
      <div className="flex flex-col border-r border-black border-opacity-5">
        <div className="flex items-center justify-center sm:justify-start text-xs w-full mb-2">
          <div className="w-8 h-8 inline-block">
            <img src={ImgOffline} alt="online" />
          </div>
          <div className="flex flex-col justify-center ml-3 ">
            <span className="font-bold uppercase text-gray-900">ONLINE</span>

            {showroomPrice !== 0 && (
              <React.Fragment>
                <span className="text-green-500 font-bold text-sm">
                  {"₹ "}
                  {paisaToAmount(showroomPrice + "00")}
                </span>

                <span
                  className={`${
                    convertToLowerCase(_get(productDetails, "availability_stock", "")) ===
                    convertToLowerCase("Out Of Stock")
                      ? " text-red-500 "
                      : " text-green-500 "
                  } font-semibold `}
                >
                  {_get(productDetails, "availability_stock", "")}
                </span>
              </React.Fragment>
            )}
          </div>
        </div>

        {/* offline save price */}
        {splPrice > 0 ? (
          <div className="mb-2 flex items-center justify-center sm:justify-start flex-wrap">
            <span className="inline-block text-sm text-gray-600">Save :</span>
            <div className="flex text-green-500 font-bold text-sm">
              <span className=" ml-1 ">₹{paisaToAmount(splPrice + "00")}</span>
              {offlineDiscount && <div className=" font-bold text-sm ml-2">({offlineDiscount.toFixed(2)}%)</div>}
            </div>
          </div>
        ) : (
          <div className="flex mt-1" />
        )}
        {priceDetails?.mrpArray?.[0]?.pre_order && (
          <span
            className="group text-sm mr-2 bg-green-500 hover:bg-green-600 font-bold py-1 px-2 text-white rounded inline-flex items-center"
            style={{ width: "8em" }}
          >
            Pre Order: {"₹ "}
            {paisaToAmount((priceDetails?.mrpArray?.[0]?.preorder_amount || "") + "00")}
          </span>
        )}
      </div>
    </div>
  );
};

export default OnlinePriceDetailsData;
