import store from "store";
import { nullToObject } from "./validation";

// sidebar style
export const MenuIcon = "w-8 h-8 flex-shrink-0";
export const MenuIconSm = "w-8 h-8 flex-shrink-0 py-1";
export const subMenuIcon = "w-8 h-5 flex-shrink-0 mx-1";
export const subMenuClass = "sub-menu whitespace-nowrap w-full space-y-1";
export const MenuName = "ml-2 flex-auto text-left overflow-ellipsis overflow-hidden text-sm font-semibold menu_name";
export const SideBarIconClass = "SideBarIcon w-full flex justify-center px-2 py-1 items-center whitespace-nowrap";
export const DropArrow = "dropArrow w-5 h-5 text-white opacity-60 mr-1 flex-shrink-0 hover:opacity-100 transition-all";
export const ListClass =
  "list_style w-full flex flex-col cursor-pointer relative items-start border-b border-solid border-opacity-10 border-yellow-600";

// customer detailed cards styles

export const InfoDetail = " whitespace-nowrap mr-4 font-bold text-sm ";
export const cardInfo = " mr-5 font-semibold text-xs text-gray-800 capitalize ";
export const bottomCard = " flex justify-between px-5 text-xs text-gray-600 py-2 ";
export const cardInner = " h-10 bg-gray-100 flex items-center justify-between text-gray-400";
export const parentClass = " bg-white mx-auto rounded-lg overflow-hidden shadow-md transition cursor-pointer w-full ";
export const roundedIcon = ` ml-4 mr-2 border-2 w-8 h-8 flex-shrink-0 rounded-full flex justify-center items-center text-gray-400 text-opacity-50
 border-gray-400 border-opacity-50`;

// form style start
// customer detailed view start
export const inputStyleFull = " mb-1 w-full mt-2 sm:px-4";
export const inputStyle = " mb-1 w-full md:w-1/2 mt-1 px-4";
export const inputReadView = " h-7 p-0 input-common-none bg-transparent ";
export const textareaStyle = " mb-4 sm:mb-8 w-full lg:w-full mt-2 sm:px-4";
export const inputStylePopup = " mb-1 w-full md:w-1/2 mt-1 px-4 xl:w-1/4 ";
export const parentWrapperReadView = " border-gray-200 border-b border-solid ";
export const parentWrapperClass = " relative flex justify-between items-center ";
export const labelTextClass = " inline-block w-40 text-sm h-auto -mb-1 text-xs ";
export const modalFixedParentClass =
  " popup_dataView relative z-1 mx-auto h-full max-h-full flex mt-4 bg-white transform -translate-y-1/2 top-1/2 ";

// header button design
export const headerIconButtonSm = ` border-1 border ease-linear transition-all duration-150 hover:bg-white hover:text-yellow-500 rounded text-white 
w-6 h-6 flex justify-center items-center `;
export const headerButton = ` text-white text-xs font-bold px-3 py-1 rounded ease-linear transition-all duration-150 border border-white hover:bg-white hover:text-yellow-500`;
export const headerButtonEdit = ` text-white text-xs font-bold px-3 py-1 rounded ease-linear transition-all duration-150 border border-white hover:bg-white hover:text-yellow-500`;
export const headerButtonBack = ` flex-grow-0 ml-auto text-white text-xs font-bold px-3 py-1 h-7 mb-2 rounded ease-linear
 transition-all duration-150 border border-blue-500 bg-blue-500`;
// Action button style
export const actionPopupBtnStyle = ` capitalize mb-2 md:mb-0 border px-5 py-2 text-sm shadow-sm font-bold tracking-wider rounded hover:shadow-lg `;
export const actionPopupBtnStyleWhite = `${actionPopupBtnStyle} bg-white border-white-500 hover:bg-gray-100 text-gray-600`;
export const actionPopupBtnStyleRed = `${actionPopupBtnStyle}  bg-gradient-to-r from-red-500 to-red-600 hover:to-red-500 hover:from-red-600 text-white`;
export const actionPopupBtnStyleGreen = `${actionPopupBtnStyle} bg-gradient-to-r from-green-500 to-green-600 hover:to-green-500 hover:from-green-600 text-white`;
//tables

export const thSticky = ` sticky -top-px z-1 `;
export const TableClass = `w-full border-collapse table_custom`;
export const TableClassCenter = `${TableClass} text-center`;
export const tdClassStyle2 = ` text-gray-600 text-xs py-1 px-3 align-top`;
export const thClass = `capitalize py-2 px-4 bg-blueGray-50 text-blueGray-500 align-middle h-10 border border-solid border-blueGray-100 text-xs font-semibold text-left whitespace-nowrap`;
export const tdClass = `  text-xs h-10 border-l border-r border-t border-b px-4`;
export const thClassStyle2 = ` bg-blueGray-50 align-middle border-b border-solid border-blueGray-50 py-2 px-3 text-xs capitalize text-left
 whitespace-nowrap font-semibold  `;

export const thClassMob = `capitalize py-1 px-1 bg-blueGray-50 text-blueGray-500 align-middle h-10 border border-solid border-blueGray-100 text-xs font-semibold text-left whitespace-nowrap`;
export const tdClassMob = ` border-t-0 border-l-0 border-r-0 text-xs h-10 border-l border-r border-t border-b px-2`;
export const tdHighLightMob = "text-left w-full bg-blueGray-50 text-blue-500 align-middle text-xs font-semibold py-2";

export const tdHighLight = "text-left w-full px-1 bg-blueGray-50 text-blue-500 align-middle text-xs font-semibold";
// customer detailed view end
export const StyleFormTextarea = () => {
  const globalStore = store.getState() || {};
  const { themeReducer: { isFullView = false } = {} } = nullToObject(globalStore);
  if (isFullView) {
    return " mb-4 sm:mb-4 w-full lg:w-1/2 xl:w-1/2 mt-2 px-4 ";
  }
  return "  mb-4 sm:mb-4 w-full lg:w-full mt-2 px-4 ";
};

export const StyleForminput = () => {
  const globalStore = store.getState() || {};
  const { themeReducer: { isFullView = false } = {} } = nullToObject(globalStore);
  if (isFullView) {
    return `${inputStyle} xl:w-1/4`;
  }
  return inputStyle;
};
export const IsFullView = () => {
  const globalStore = store.getState() || {};
  const { themeReducer: { isFullView = false } = {} } = nullToObject(globalStore);
  if (isFullView) {
    return true;
  }
  return false;
};
export const StyleForminputFileReader = () => {
  const globalStore = store.getState() || {};
  const { themeReducer: { isFullView = false } = {} } = nullToObject(globalStore);
  if (isFullView) {
    return " mb-2 w-full lg:w-full xl:w-full mt-2 px-4";
  }
  return " mb-2 w-full lg:w-full mt-2 px-4";
};
// form style end

export const bg_BasicInfo = " bg-yellow-100 border border-yellow-400 ";
export const bg_TransactionHistory = " bg-yellow-100 border border-yellow-500 ";
export const bg_EnquiryInfo = " bg-green-100 border border-green-400 ";
export const bg_ProductRelatedTable = " bg-purple-200 border border-purple-400 ";
export const bg_EnquiryStatusHistory = "  bg-blue-200 border border-blue-400 ";
export const bg_ServiceCenter = "  bg-blue-200 border border-blue-400 ";
export const bg_EmployeeDetail = " bg-blue-200 border border-blue-400 ";
export const bg_EnquiryStatusUpdate = " bg-pink-200 border border-pink-400 ";
export const bg_EnquiryFromMail = " bg-red-200 border border-red-400 ";
export const Bg_BillingAddress = " bg-red-200 border border-red-400 ";
export const bg_IMEIandMobile = " bg-gray-200 border border-gray-400 ";
export const bg_JobSheet = " bg-pink-100 border border-pink-500 ";
export const bg_ComplaintInfo = " bg-red-200 border border-red-400 ";
export const bg_ProblemDetails = " bg-red-200 border border-red-400 ";
export const bg_GeneralInfo = " bg-green-100 border border-green-400 ";
export const bg_DuplicateInvoice = " bg-purple-200 border border-purple-400 ";
export const bg_OrderInfo = " bg-purple-200 border border-purple-400 ";

// squre button style
export const btn_size = ` w-8 h-8 flex-shrink-0 `;
export const btn_plus = ` ${btn_size} text-sm border bg-gradient-to-r from-green-500 to-green-600 hover:to-green-500 hover:from-green-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_refresh = ` ${btn_size} text-sm border bg-gradient-to-r from-blue-500 to-blue-600 hover:to-blue-500 hover:from-blue-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_mail = ` ${btn_size} text-sm border bg-gradient-to-r from-red-500 to-red-600 hover:to-red-500 hover:from-red-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_edit = `flex-shrink-0 text-sm border bg-gradient-to-r from-green-500 to-green-600 hover:to-green-500 hover:from-green-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_cancel = `flex-shrink-0  text-sm border bg-gradient-to-r from-gray-400 to-gray-500 hover:to-gray-400 hover:from-gray-500 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_reload = `flex-shrink-0  text-sm border bg-gradient-to-r from-blue-400 to-blue-500 hover:to-blue-400 hover:from-blue-500 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_delete = `flex-shrink-0  text-sm border bg-gradient-to-r from-red-500 to-red-600 hover:to-red-500 hover:from-red-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_view = `text-sm border bg-gradient-to-r from-yellow-500 to-yellow-600 hover:to-yellow-500 hover:from-yellow-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_share = ` flex-shrink-0  text-sm border bg-gradient-to-r from-blue-500 to-blue-600 hover:to-blue-500 hover:from-blue-600 text-white
 transition-all flex items-center justify-center rounded p-1 ml-2 `;
export const btn_download = ` flex-shrink-0 text-sm border bg-gradient-to-r from-indigo-500 to-indigo-600 hover:to-indigo-500 hover:from-indigo-600 text-white
 transition-all flex items-center justify-center rounded p-1`;
export const btn_upload = ` flex-shrink-0 text-sm border bg-gradient-to-r from-purple-500 to-purple-600 hover:to-purple-500 hover:from-purple-600 text-white
 transition-all flex items-center justify-center rounded p-1 cursor-pointer`;
export const btn_create = ` flex-shrink-0  text-sm border bg-gradient-to-r from-blue-500 to-blue-600 hover:to-blue-500 hover:from-blue-600 text-white
 transition-all flex items-center justify-center rounded p-1`;
export const btn_outline = ` flex-shrink-0 text-sm border border-gray-300 text-gray-400 transition-all flex items-center justify-center rounded p-1 `;
export const btn_outline_active = ` flex-shrink-0 text-sm border border-blue-500 bg-blue-500 text-white transition-all flex items-center justify-center px-2 py-1 `;
