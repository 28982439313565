import React, { Fragment, useEffect, useState } from "react";
import { _get } from "utils/lodashConst";
import { replacementFormProps } from "./types";
import { inputStylePopup } from "utils/ClassContent";
import { checkSRNValue } from "../../../commonSalesReturn";
import ImageUploadReturn from "Component/ImageUploadReturn";
import FormFile from "Component/Form/FormFile";
import CommonInput from "Component/Form/CommonInput";

const ReplacementForm = (props: replacementFormProps) => {
  const {
    setShow = true,
    viewModeData = {},
    convertLowerCase = "",
    enableViewMode = false,
    replacePassdata = () => {}
  } = props || {};

  const checkApprovalStatus = checkSRNValue(convertLowerCase);
  const { reasonForSalesReturn: { commonImgFile = [] } = {} } = viewModeData;

  const [relaceEmpId, setRelaceEmpId] = useState("");
  const [relaceEmpName, setRelaceEmpName] = useState("");
  const [imagRenderArry, setImagRenderArry] = useState([]);
  const [relaceDepartment, setRelaceDepartment] = useState("");
  const [relaceDesignation, setRelaceDesignation] = useState("");

  const [relaceFormData, setRelaceFormData] = useState({
    relaceEmpId: "",
    relaceEmpName: "",
    relaceProduct: "",
    relaceDepartment: "",
    relaceDesignation: "",
    relaceSellingPrice: "",
    relaceimagRenderArry: []
  });

  const onImageChange = (event: any = {}) => {
    if (_get(event, "target.files", [])?.length > 0) {
      const file = _get(event, "target.files[0]", {});
      const { name = "", type = "" } = file;
      const newObj = {
        file: file,
        fileName: name,
        type: type,
        isEditable: true,
        url: URL.createObjectURL(file) //conevrt blob formate
      };
      const newArray: any = [...imagRenderArry, newObj];
      const newObject = {
        ...relaceFormData,
        relaceimagRenderArry: newArray
      };
      setImagRenderArry(newArray);
      setRelaceFormData(newObject);
      replacePassdata(newObject, true); //pass data here to parent index
    }
  };

  const onClosImage = (index = 0) => {
    //remove image
    const newArray: any = [...imagRenderArry];
    newArray.splice(index, 1);
    const newObject = {
      ...relaceFormData,
      relaceimagRenderArry: newArray
    };
    setImagRenderArry(newArray);
    setRelaceFormData(newObject);
    replacePassdata(newObject, true); //pass data here to parent index
  };

  /**
   * onUpdate main state and error validate
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    const relaceForm = relaceFormData;
    switch (name) {
      case "relaceEmpId":
        relaceForm.relaceEmpId = value;
        setRelaceEmpId(value);
        // value && getEmpDetailsForReplaceMent(value);//emp id based search details
        break;
      case "relaceEmpName":
        relaceForm.relaceEmpName = value;
        setRelaceEmpName(value);
        break;
      case "relaceDepartment":
        relaceForm.relaceDepartment = value;
        setRelaceDepartment(value);
        break;
      case "relaceDesignation":
        relaceForm.relaceDesignation = value;
        setRelaceDesignation(value);
        break;
      default:
        break;
    }
    setRelaceFormData(relaceForm);
    replacePassdata(relaceForm, true); //pass data here to parent index
  };

  const dynamicClass = () => {
    return inputStylePopup;
  };

  useEffect(() => {
    const {
      reasonForSalesReturn: {
        replacedProduct = "",
        replacedSellPrice = "",
        employeeId = "",
        employeeName = "",
        employeeDept = "",
        employeeDesignation = ""
      } = {}
    } = viewModeData;
    onChange({ target: { name: "relaceEmpId", value: employeeId } });
    onChange({ target: { name: "relaceEmpName", value: employeeName } });
    onChange({ target: { name: "relaceDepartment", value: employeeDept } });
    onChange({ target: { name: "relaceProduct", value: replacedProduct } });
    onChange({ target: { name: "relaceSellingPrice", value: replacedSellPrice } });
    onChange({ target: { name: "relaceDesignation", value: employeeDesignation } });
  }, [enableViewMode]);

  return (
    <React.Fragment>
      {checkApprovalStatus && (
        <>
          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            value={relaceEmpId}
            name={"relaceEmpId"}
            inputFieldClass="h-9"
            labelText={"Employee Id"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
            onChange={!enableViewMode ? onChange : null}
          />

          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            readOnly={true}
            value={relaceEmpName}
            inputFieldClass="h-9"
            name={"relaceEmpName"}
            labelText={"Employee Name"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
            onChange={!enableViewMode ? onChange : null}
          />

          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            readOnly={true}
            inputFieldClass="h-9"
            labelText={"Department"}
            name={"relaceDepartment"}
            value={relaceDepartment}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
            onChange={!enableViewMode ? onChange : null}
          />

          <CommonInput
            placeholder={""}
            setShow={setShow}
            inputError={false}
            readOnly={true}
            inputFieldClass="h-9"
            labelText={"Designation"}
            value={relaceDesignation}
            name={"relaceDesignation"}
            labelTextClass={"font-bold"}
            parentClass={dynamicClass()}
            onChange={!enableViewMode ? onChange : null}
          />
        </>
      )}

      {!enableViewMode ? (
        <Fragment>
          <FormFile
            type="file"
            label={true}
            onChange={onImageChange}
            labelText="Attach Image"
            labelTextClass={"font-bold"}
            parentClass={" mb-1 w-full mt-2 sm:px-4 "}
            accept=".jpg, .jpeg, .png, .doc, .docx, .pdf, .odt, .txt, .xlsx, .xls"
          />
          {imagRenderArry.length >= 1 && (
            <div className="w-full flex flex-wrap justify-center px-3">
              <ImageUploadReturn onClosImage={onClosImage} imagRenderArry={imagRenderArry} />
            </div>
          )}

          {commonImgFile.length >= 1 && (
            <div className="w-full flex flex-wrap justify-center px-3">
              <ImageUploadReturn
                onClosImage={false && onClosImage}
                isDisableImageClose={true}
                imagRenderArry={commonImgFile}
                enableViewMode={enableViewMode}
              />
            </div>
          )}
        </Fragment>
      ) : (
        commonImgFile.length >= 1 && (
          <div className="w-full flex flex-wrap justify-center px-3">
            <ImageUploadReturn
              onClosImage={onClosImage}
              imagRenderArry={commonImgFile}
              enableViewMode={enableViewMode}
            />
          </div>
        )
      )}
    </React.Fragment>
  );
};
export default ReplacementForm;
