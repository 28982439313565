import React from "react";
import SalesOthers from "../../../SalesOthers";

const SalesOtherViewUpdate = (props: any = {}) => {
  const { customerData = {}, columnView = false, closePopup = () => {} } = props;

  return (
    <SalesOthers
      fromPage="edit"
      inModelView={true}
      closePopup={closePopup}
      columnView={columnView}
      customerData={customerData}
    />
  );
};

export default SalesOtherViewUpdate;
