import React from "react";
import { _isEmpty } from "utils/lodashConst";

import { GeneralInfo } from "utils/VariableConstant";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { onWheel } from "Component/SliderMenu/CommonFunctions";

const EnquiryForServicePopupTap = (props: any = {}) => {
  const {
    showImeiTrans,
    subCategory = "",
    customerData = {},
    popParentTabs = "",
    popParentTabsHandle = () => {},
    serviceExistingEnquiries = []
  } = props || {};

  return (
    <div className="sticky -top-1 bg-white z-20 shadow-md">
      <ScrollMenu scrollContainerClassName={"flex white whitespace-nowrap"} onWheel={onWheel}>
        <a
          href="#BasicInfo"
          onClick={() => popParentTabsHandle("BasicInfo")}
          data-jest-id={"jestPopParentTabsHandle"}
          className={`mx-2 ${popParentTabs === "BasicInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                         rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                        cursor-pointer font-medium`}
        >
          Basic Info
        </a>

        {subCategory !== GeneralInfo && (
          <a
            href="#CustomerInfo"
            onClick={() => popParentTabsHandle("CustomerInfo")}
            className={`mx-2 ${popParentTabs === "CustomerInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                            rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                            cursor-pointer`}
          >
            Customer Info
          </a>
        )}

        {subCategory === GeneralInfo && (
          <a
            href="#GeneralInfo"
            onClick={() => popParentTabsHandle("GeneralInfo")}
            data-jest-id={"jestPopParentTabsHandle2"}
            className={`mx-2 ${popParentTabs === "GeneralInfo" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
          >
            General Info
          </a>
        )}

        {subCategory !== "Duplicate invoice" && subCategory !== GeneralInfo && (
          <a
            href="#ProblemDetails"
            onClick={() => popParentTabsHandle("ProblemDetails")}
            className={`mx-2 ${popParentTabs === "ProblemDetails" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
          >
            Problem Details
          </a>
        )}

        {!_isEmpty(customerData["serviceCenter"]) && (
          <a
            href="#ServiceCenter"
            onClick={() => popParentTabsHandle("ServiceCenter")}
            className={`mx-2 ${popParentTabs === "ServiceCenter" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                    rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                    cursor-pointer`}
          >
            Service Center
          </a>
        )}

        {!_isEmpty(customerData["jobSheet"]) && (
          <a
            href="#JobSheet"
            onClick={() => popParentTabsHandle("JobSheet")}
            data-jest-id={"jestPopParentTabsHandle5"}
            className={`mx-2 ${popParentTabs === "JobSheet" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
          >
            Job Sheet
          </a>
        )}

        {subCategory !== "Duplicate invoice" &&
          subCategory !== GeneralInfo &&
          showImeiTrans &&
          serviceExistingEnquiries.length >= 1 && (
            <a
              href="#IMEIandMobile"
              onClick={() => popParentTabsHandle("IMEIandMobile")}
              className={`mx-2 ${popParentTabs === "IMEIandMobile" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
            >
              IMEI and Mobile Enquiry History
            </a>
          )}

        {subCategory !== "Duplicate invoice" && (
          <a
            href="#EnquiryStatusHistory"
            onClick={() => popParentTabsHandle("EnquiryStatusHistory")}
            className={`mx-2 ${
              popParentTabs === "EnquiryStatusHistory" ? " border-b-2 border-yellow-600 font-bold" : ""
            }
                                rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                cursor-pointer`}
          >
            Enquiry Status History
          </a>
        )}

        {subCategory === "Duplicate invoice" && (
          <a
            href="#DuplicateInvoice"
            onClick={() => popParentTabsHandle("DuplicateInvoice")}
            data-jest-id={"jestPopParentTabsHandle3"}
            className={`mx-2 ${popParentTabs === "DuplicateInvoice" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                    rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                    cursor-pointer`}
          >
            Duplicate Invoice Status History
          </a>
        )}
        {subCategory === "Duplicate invoice" && (
          <a
            href="#DuplicateInvoiceSms"
            onClick={() => popParentTabsHandle("DuplicateInvoiceSms")}
            data-jest-id={"jestPopParentTabsHandle4"}
            className={`mx-2 ${popParentTabs === "DuplicateInvoiceSms" ? " border-b-2 border-yellow-600 font-bold" : ""}
                                    rounded-t-sm sm:text-center px-2 py-2 h-9 flex items-center justify-center text-sm
                                    cursor-pointer`}
          >
            Duplicate Invoice Sms Link
          </a>
        )}
      </ScrollMenu>
    </div>
  );
};

export default EnquiryForServicePopupTap;
