// service
export const ProductService = "Product Service";
export const ServiceSupport = "Service Support";
export const GeneralInfo = "General Information";
export const DuplicateInvioce = "Duplicate Invoice";
export const CHAT_ROLES = [12, 13, 15, 16, 19];
export const CALL_ROLES = [5, 11, 17, 18, 23];

export const apxPoductDetails = [
  "M-True Wireless (TWS)",
  "G-Smart Watch",
  "A-Charger",
  "M-Neckband BT",
  "A-Head Phone",
  "A-Cable",
  "M-Power Bank",
  "G-Boom Headset",
  "G-IOT Smart",
  "R-Data Card",
  "G-BT Speaker"
];

export const priceModeData = [
  { _id: "fsp", itemName: "FSP", isCheck: false },
  { _id: "cp", itemName: "CP", isCheck: false },
  { _id: "nlc", itemName: "NLC", isCheck: false },
  { _id: "comp", itemName: "COMP", isCheck: false }
];

export const isSameDayDelivery = (deliveryType: any) => {
  let array = [];
  (deliveryType || []).forEach((ele: any) => {
    if (ele?.mode.includes("Today") || ele?.mode.includes("Sameday Delivery")) {
      array = [...array, "Today"];
    }
    if (ele?.mode.includes("2 hours Delivery")) {
      array = [...array, "2Hours"];
    }
  });
  return [array.length > 0, array];
};
export const poorvikaUrl = "https://www.poorvika.com/";
