import React from "react";

import ComboFreeDisplay from "../ComboFreeDisplay";
import { _isEmpty } from "utils/lodashConst";

interface ProductOfferProp {
  additionalOffers: [];
  setAdditionalOffers: any;
  product: any;
}
const AdditionalOffers = (props: ProductOfferProp) => {
  const { additionalOffers, setAdditionalOffers, product } = props;

  const handleOfferAction = (offerId: string, value: string, offerType: string) => {
    const checked_offer: any = additionalOffers.map((item: any) => {
      if (item.offerId === offerId) {
        item[value] = !item[value];

        if (value === "showTc") {
          item.showBan = false;
          item.showOffer = false;
        } else if (value === "showBan") {
          item.showTc = false;
          item.showOffer = false;
        } else if (value === "showOffer") {
          item.showTc = false;
          item.showBan = false;
        }
      }
      return item;
    });
    setAdditionalOffers(checked_offer);
  };

  return (
    <>
      {additionalOffers.map((offer: any) => {
        return (
          <div className="flex flex-col text-sm justify-between" key={offer?.offerId}>
            <div className="flex flex-col">
              <div className="flex flex-row justify-between flex-wrap align-middle items-center p-2 font-bold text-gray-800">
                <div className="flex flex-row align-middle items-center justify-start flex-wrap">
                  <span className="p-1">
                    <input
                      name={offer.offerId}
                      checked={offer.checked}
                      id={offer.offerId}
                      disabled={offer.offerSelection === "mandatory"}
                      onChange={() => handleOfferAction(offer.offerId, "checked", "other")}
                      type="checkbox"
                    />
                  </span>
                  <span className="p-1"> {`${offer?.offerName} (${offer?.sponsorName})`}</span>

                  <button
                    type="button"
                    className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showTc", "other")}
                  >{`T&C`}</button>
                  <button
                    type="button"
                    className="text-sm text-blue-500 underline ml-1 font-bold cursor-pointer hover:text-blue-600"
                    onClick={() => handleOfferAction(offer.offerId, "showBan", "other")}
                  >{`Details`}</button>
                </div>

                <div className="flex flex-row align-middle text-green-500">
                  {offer.offerType !== "Offer Text" && offer.offerType !== "Standard EMI" && (
                    <span className={!offer.checked ? "line-through" : ""}>₹ {offer?.maxBenefites || 0}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              {offer.showTc && <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.termsConditions }} />}
              {offer.showBan && (
                <div className="show-tc" dangerouslySetInnerHTML={{ __html: offer?.bannerDiscription }} />
              )}
              {offer.showOffer && (
                <div className="offer-expanded">
                  {!_isEmpty(offer.comboOffers) && (
                    <ComboFreeDisplay
                      maxBenefites={offer?.maxBenefites}
                      isCombo={true}
                      offerId={offer._id}
                      comboFreeOffers={additionalOffers}
                      setComboFreeOffers={setAdditionalOffers}
                      offers={offer.comboOffers[product?.erp_item_code]}
                    />
                  )}

                  {!_isEmpty(offer.freebies) && (
                    <ComboFreeDisplay
                      maxBenefites={offer?.maxBenefites}
                      isCombo={true}
                      offerId={offer._id}
                      comboFreeOffers={additionalOffers}
                      setComboFreeOffers={setAdditionalOffers}
                      offers={offer.freebies[product?.erp_item_code]}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default AdditionalOffers;
