import React, { useEffect, useState } from "react";
import { alphaOnly } from "utils/validation";
import CommonInput from "Component/Form/CommonInput";
import { _get, _flattenDeep } from "utils/lodashConst";
import CommonTextarea from "Component/Form/CommonTextarea";
import CommonMultiChoose from "Component/Form/CommonMultiChoose";
import { CustomerProblemInfoPropsType } from "../ServiceCallsTypes";
import CommonSearchDropdown from "Component/Form/CommonSearchDropdown";
import { inputStylePopup, StyleFormTextarea } from "utils/ClassContent";
import { availableSubProblem, MockTypeofService } from "Component/MockData";

/**
 * Show's problem , problem category fields
 * @param props props
 * @returns Problem category fields
 */
const CustomerProblemInfo = (props: CustomerProblemInfoPropsType = {}) => {
  const { children = "", customerData = {}, itemName = "", editView = false, passProblemInfo = () => {} } = props || {};

  const { problemCategory = [], problem = [] } = customerData;
  const [onPass, setOnPass] = useState(false);
  const [subProblem, setSubProblem] = useState([]);
  const [typeofService, setTypeofService] = useState("");
  const [problemDescription, setProblemDescription] = useState("");
  const [selectedProblem, setSelectedProblem] = useState(availableSubProblem);

  /**
   * Update selected field state
   * @param event event for selected input field
   */
  const onChange = (event: any = {}) => {
    const { value = "", name = "" } = _get(event, "target", {});
    switch (name) {
      case "problemDescription":
        setProblemDescription(value);
        break;
      case "typeofService":
        setTypeofService(value);
        break;
      default:
        break;
    }
    setOnPass(true);
  };

  const commonProblem = (newArray = []) => {
    return newArray
      .map((ele: any) => {
        // map problem
        if (ele.isSelect === true) {
          return ele.optionData;
        }
        return undefined;
      })
      .filter((ele: any) => ele !== undefined);
  };

  /**
   * Update the selected status of problem
   * @param data selected problem from multi choose
   */
  const choosedProblem = (data: object = {}) => {
    const problemId = _get(data, "id", 0);
    const newArray: any = [...selectedProblem];
    const findIndex = newArray.findIndex((ele: any) => ele.id === problemId);
    newArray[findIndex] = {
      ...newArray[findIndex],
      isSelect: !newArray[findIndex]?.isSelect,
      value: newArray[findIndex].value.map((ele: any) => {
        return {
          ...ele,
          isSelect: false
        };
      })
    };
    const filterSeletctedValue = newArray.filter((ele: any) => ele.isSelect);
    const mapValueData: any = filterSeletctedValue.map((ele: any) => {
      return ele.value;
    });
    setSelectedProblem(newArray);

    const problemSelected = commonProblem(newArray);
    passProblemInfo({ problemCategory: problemSelected });
    setSubProblem(_flattenDeep(mapValueData));
  };

  /**
   * Update the selected status of problem category
   * @param data selected problem category from multi choose
   */
  const subProblemHandle = (data: object = {}) => {
    const parentId = _get(data, "parentId", 0);
    const problemId = _get(data, "id", 0);
    const newArray: any = [...selectedProblem];
    const findObj: any = newArray.find((ele: any) => ele.id === parentId);
    findObj.value.map((ele: any) => {
      if (ele.id === problemId) {
        ele.isSelect = !ele.isSelect;
      }
      return ele;
    });
    const newseletectParentArray = [...selectedProblem];
    const findIndex = newArray.findIndex((ele: any) => ele.id === findObj.id);
    newseletectParentArray[findIndex] = findObj;
    const filterSeletcedValue = newseletectParentArray.filter((ele: any) => ele.isSelect);
    const mapValueData: any = filterSeletcedValue.map((ele: any) => {
      return ele.value;
    });
    setSelectedProblem(newArray);
    const subProblemSelected = commonProblem(mapValueData);
    passProblemInfo({ problem: subProblemSelected });
    setSubProblem(_flattenDeep(mapValueData));
  };

  useEffect(() => {
    setTypeofService(_get(customerData, "typeOfService", ""));
    setProblemDescription(_get(customerData, "problemDesc", ""));
  }, [customerData]);

  /**
   * product name based problem display
   */
  useEffect(() => {
    let otherProblemArray: any[] = [];
    if (itemName) {
      otherProblemArray = [
        {
          id: 1111111111,
          isSelect: false,
          optionData: "Other",
          itemGrupName: "Other",
          value: [{ parentId: 1111111111, id: 11111111101, isSelect: false, value: "Other", optionData: "Other" }]
        }
      ];
    }
    const findProblem: any = availableSubProblem.filter((ele: any) => ele.itemGrupName === itemName) || [];
    const newArray = findProblem.length >= 1 ? [...findProblem] : [...otherProblemArray];
    /**
     * problem find
     */
    const result: any = newArray.map((ele: any) => {
      ele.isSelect = problemCategory.some((eleProbelm: any) => ele.optionData === eleProbelm);
      return ele;
    });

    const filterSeletcedValue = result.filter((ele: any) => ele.isSelect === true);
    const subProblemResult: any = _flattenDeep(filterSeletcedValue.map((ele: any) => _get(ele, "value", [])));
    /**
     * sub problem find
     */
    const resultsubProblem: any = subProblemResult.map((ele: any) => {
      ele.isSelect = problem.some((eleProbelm: any) => ele.optionData === eleProbelm);
      return ele;
    });

    setSelectedProblem(result);
    setSubProblem(resultsubProblem);
  }, [itemName]);

  useEffect(() => {
    if (onPass) {
      const newObj = {
        problemDesc: problemDescription,
        typeOfService: typeofService
      };
      passProblemInfo(newObj);
      setOnPass(false);
    }
  }, [onPass]);

  return (
    <React.Fragment>
      {editView ? (
        <CommonMultiChoose
          mustField={true}
          inputError={false}
          inputClass="w-full"
          inputFieldClass="h-9"
          labelRight={"Choosen"}
          labelLeft={"Available"}
          labelTextClass={"font-bold"}
          editProblem={problemCategory}
          labelText={"Problem Category"}
          chooseLangage={choosedProblem}
          seletedLanuage={selectedProblem}
          deselectLanguage={choosedProblem}
          parentClass={" xl:w-1/2 "}
        />
      ) : (
        <CommonInput
          readOnly={true}
          mustField={true}
          placeholder={""}
          inputError={false}
          inputFieldClass="h-9"
          value={(problemCategory || []).map((itm: any) => itm).join(",")}
          name={"problemCategory"}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          labelText={"Problem Category"}
        />
      )}

      {editView ? (
        <CommonMultiChoose
          mustField={true}
          inputError={false}
          inputClass="w-full"
          labelText={"Problem"}
          inputFieldClass="h-9"
          editSubProblem={problem}
          seletedLanuage={subProblem}
          labelTextClass={"font-bold"}
          labelRight={"Choosen Options"}
          labelLeft={"Available Options"}
          chooseLangage={subProblemHandle}
          deselectLanguage={subProblemHandle}
          parentClass={" xl:w-1/2"}
        />
      ) : (
        <CommonInput
          readOnly={true}
          value={(problem || []).map((itm: any) => itm).join(",")}
          placeholder={""}
          name={"Problem"}
          mustField={true}
          inputError={false}
          labelText={"Problem"}
          inputFieldClass="h-9"
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
        />
      )}

      {editView ? (
        <CommonTextarea
          setShow={true}
          onChange={onChange}
          name="problemDescription"
          value={problemDescription}
          labelTextClass={"font-bold"}
          labelText={"Problem Description"}
          parentClass={StyleFormTextarea()}
        />
      ) : (
        <CommonInput
          readOnly={true}
          placeholder={""}
          mustField={true}
          inputError={false}
          inputFieldClass="h-9"
          value={problemDescription}
          name={"problemDescription"}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          labelText={"Problem Description"}
        />
      )}

      {editView ? (
        <CommonSearchDropdown
          label={true}
          mustField={true}
          inputWithIcon={false}
          value={typeofService}
          name={"typeofService"}
          onKeyPress={alphaOnly}
          handleOnChange={onChange}
          inputWithIconClass="w-10"
          defaltSeleted={"--None--"}
          inputFieldClass="h-9 pr-11"
          labelTextClass={"font-bold"}
          arrayData={MockTypeofService}
          inputClass="flex justify-end"
          labelText={"Type of Service"}
          parentClass={inputStylePopup}
        />
      ) : (
        <CommonInput
          placeholder={""}
          readOnly={true}
          mustField={true}
          inputError={false}
          value={typeofService}
          inputFieldClass="h-9"
          name={"typeofService"}
          onKeyPress={alphaOnly}
          labelTextClass={"font-bold"}
          parentClass={inputStylePopup}
          labelText={"Type of Service"}
        />
      )}
      {children}
    </React.Fragment>
  );
};

export default CustomerProblemInfo;
