import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { callRecordingProps } from "./types";

import { getRecordingData, resetCallRecording } from "./CommonAmoyo";
import { DownloadIcon } from "@heroicons/react/outline";
import Loader from "Component/Loader";

const CallRecording = (props: callRecordingProps) => {
  const { phone = "", updatedDate = "", recordingUrl = "" } = props || {};

  const [downloadUrl, setDownloadUrl] = useState("");
  const globalSate = useSelector((state: any) => state);
  const { ameyoReducer = {} } = globalSate; //globalStore
  const { callRecordingLoader = false, callRecordingData = {} } = ameyoReducer;

  /**
   * handle download click
   */
  const handleClick = () => {
    getRecordingData({
      mobileNo: phone,
      updatedDate: updatedDate
    });
  };

  useEffect(() => {
    recordingUrl && setDownloadUrl(recordingUrl);
  }, [recordingUrl]);

  /**
   * generete download link and download
   */
  useEffect(() => {
    const { url = "" } = callRecordingData || {};
    if (url) {
      setDownloadUrl(url.replace(/[\\]/g, "").replace(/["]/g, ""));
      setTimeout(() => {
        resetCallRecording();
      }, 100);
    }
  }, [callRecordingData]);

  return (
    <React.Fragment>
      {callRecordingLoader && <Loader />}
      {!downloadUrl ? (
        <div onClick={handleClick}>
          <DownloadIcon className="w-6 h-6 mx-auto cursor-pointer bg-yellow-500 hover:bg-yellow-600 p-1 rounded text-white" />
        </div>
      ) : (
        <a href={downloadUrl} target="_blank" id={phone + updatedDate} download rel="noreferrer">
          <DownloadIcon className="w-6 h-6 mx-auto cursor-pointer bg-green-500 hover:bg-green-600 p-1 rounded text-white" />
        </a>
      )}
    </React.Fragment>
  );
};

export default CallRecording;
