import { Get, Post } from "ApiProcess/httpRestServices";
import { transHistory, salesTransaction } from "../../ApiProcess/httpRestServices/apiManage";

/**
 * Get sales transaction details from server
 * @returns sales transaction list as response from server
 */
export const getSalesTransactionService = (obj: any = {}) => {
  return Get(`${transHistory}${salesTransaction.transInfo}`, "");
};

export const getSalesTransactionSTXNService = (obj: any = {}) => {
  const { data = {} } = obj || {};
  return Post("https://dev-stxn.poorvika.com/api/v1/transaction", data, "");
};
