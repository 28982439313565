export const GET_SERVICES_CENTER_LIST = "GET_SERVICES_CENTER_LIST";
export const GET_EMP_DETAILS_AND_DATA = "GET_EMP_DETAILS_AND_DATA";
export const GET_SERVICES_CENTER_TOKEN = "GET_SERVICES_CENTER_TOKEN";
export const UPDATE_SALES_RETURN_STATUS = "UPDATE_SALES_RETURN_STATUS";
export const GET_SALES_RETURN_LIST_ONLY = "GET_SALES_RETURN_LIST_ONLY"; //get sales retun list
export const UPDATE_SALES_RETURN_DETAILS = "UPDATE_SALES_RETURN_DETAILS";
export const IMEI_NUMBER_BASED_GET_DETAILS = "IMEI_NUMBER_BASED_GET_DETAILS";
export const UPDATE_SALES_RETURN_DETAILS_EDIT_DATA = "UPDATE_SALES_RETURN_DETAILS_EDIT_DATA";
export const GET_USER_IMEI_AND_DETAIL_BASED_INVOICE_DATE = "GET_USER_IMEI_AND_DETAIL_BASED_INVOICE_DATE"; //get user details
export const IMEI_NUMBER_BASED_GET_TRANSACTION_DETAILS = "IMEI_NUMBER_BASED_GET_TRANSACTION_DETAILS";
export const GET_SERVICE_CENTER_LIST = "GET_SERVICE_CENTER_LIST";
export const GET_SERVICE_CENTER_REFRESH = "GET_SERVICE_CENTER_REFRESH";
export const SERVICE_CENTER_SEND_REPORT_EMAIL = "SERVICE_CENTER_SEND_REPORT_EMAIL";
export const SERVICE_CENTER_CREATE = "SERVICE_CENTER_CREATE";
export const SERVICE_CENTER_DELETE = "SERVICE_CENTER_DELETE";
