import React from "react";
import { sourceBasicInfoProps } from "./types";
import { inputStylePopup } from "utils/ClassContent";
import CommonInput from "Component/Form/CommonInput";
import { convertToLowerCase, dateToString, numOnlyAllow } from "utils/validation";
import { staticData } from "ApiProcess/httpRestServices/apiManage";

const SourceBasicInfo = (props: sourceBasicInfoProps) => {
  const { setShow = true, enableViewMode = true, viewModeData = {}, onChange = () => {} } = props;
  return (
    <React.Fragment>
      <CommonInput
        setShow={setShow}
        inputError={false}
        name={"mobileNo"}
        maxLength={staticData.MobileNo}
        onChange={onChange}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        labelText={"Mobile Number"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={viewModeData.mobileNo}
        placeholder={"Mobile Number"}
        onKeyPress={numOnlyAllow}
      />

      <CommonInput
        setShow={setShow}
        onChange={onChange}
        inputError={false}
        name={"branchName"}
        inputFieldClass="h-9"
        labelText={"Branch Name"}
        readOnly={enableViewMode}
        placeholder={"Branch Name"}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={viewModeData.branchName}
      />

      <CommonInput
        setShow={setShow}
        onChange={onChange}
        inputError={false}
        name={"customerName"}
        inputFieldClass="h-9"
        readOnly={enableViewMode}
        labelText={"Customer Name"}
        labelTextClass={"font-bold"}
        placeholder={"Customer Name"}
        parentClass={inputStylePopup}
        value={viewModeData.customerName}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        labelText={"Product"}
        name={"itemModelName"}
        placeholder={"Product"}
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={viewModeData.itemModelName}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        labelText={"Brand"}
        inputFieldClass="h-9"
        placeholder={"Brand"}
        name={"itemBrandName"}
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={viewModeData.itemBrandName}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        name={"productName"}
        inputFieldClass="h-9"
        labelText={"Item Name"}
        placeholder={"Item Name"}
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        value={viewModeData.productName}
      />

      <CommonInput
        setShow={setShow}
        inputError={false}
        onChange={onChange}
        inputFieldClass="h-9"
        name={"invoiceAmount"}
        readOnly={enableViewMode}
        labelText={"Product value"}
        parentClass={inputStylePopup}
        labelTextClass={"font-bold"}
        placeholder={"Product value"}
        value={convertToLowerCase(viewModeData.invoiceAmount)}
      />

      <CommonInput
        name={"billNo"}
        onChange={onChange}
        setShow={setShow}
        inputError={false}
        labelText={"Bill No"}
        inputFieldClass="h-9"
        placeholder={"Bill No"}
        readOnly={enableViewMode}
        value={viewModeData.billNo}
        parentClass={inputStylePopup}
        labelTextClass={"font-bold"}
      />

      <CommonInput
        name={"billDate"}
        onChange={onChange}
        setShow={setShow}
        inputError={false}
        inputFieldClass="h-9"
        labelText={"Bill Date"}
        enableDatePicker={true}
        isIconLabel={true}
        readOnly={enableViewMode}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        placeholderText={"" + dateToString(viewModeData.billDate)}
        value={viewModeData.billDate && new Date(viewModeData.billDate)}
      />

      <CommonInput
        setShow={setShow}
        onChange={onChange}
        inputError={false}
        name={"documentDate"}
        inputFieldClass="h-9"
        enableDatePicker={true}
        readOnly={enableViewMode}
        labelText={"Document Date"}
        isIconLabel={true}
        labelTextClass={"font-bold"}
        parentClass={inputStylePopup}
        placeholderText={"" + dateToString(viewModeData.documentDate)}
        value={viewModeData.documentDate && new Date(viewModeData.documentDate)}
      />
    </React.Fragment>
  );
};
export default SourceBasicInfo;
