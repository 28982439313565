import React from "react";
import { btn_upload } from "utils/ClassContent";
import { IconAnimateLoading } from "assets/images";
import { UploadIcon } from "@heroicons/react/outline";

const ExcelUpload = ({ triggerUploadExcel = () => {}, isUploading = false }) => (
    <React.Fragment>
      <label htmlFor="ExcelUpload" title="Upload CSV file" className={btn_upload + " w-8 h-8 ml-2 "}>
        {!isUploading ? (
          <UploadIcon className="w-5 h-5" />
        ) : (
          <IconAnimateLoading className=" w-6 h-6 text-indigo-200 " />
        )}
      </label>
      <input
        className="absolute w-0 h-0 overflow-hidden opacity-0"
        type="file"
        name="files"
        accept="text/csv"
        id="ExcelUpload"
        onChange={triggerUploadExcel}
      />
    </React.Fragment>
  );
export default ExcelUpload;
